const Toggle = ({ isAllowed, setIsAllowed, setIsExpanded, unSelectAll }) => {
  return (
    <div class="form-check form-switch">
      <label className="switch" style={{ marginBottom: "0rem" }}>
        <input
          type="checkbox"
          checked={isAllowed}
          id="flexSwitchCheckDefault"
          onChange={(event) => {
            setIsAllowed(event.target.checked);
            setIsExpanded(true);
            if (!event.target.checked) {
              unSelectAll();
            }
          }}
        />
        <div className={`slider round ${isAllowed ? "active" : ""}`}>
          <span className="on">Allowed</span>
          <span className="off">Restricted</span>
        </div>
      </label>
    </div>
  );
};
export default Toggle;
