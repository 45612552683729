import { useTranslation } from "react-i18next";
import StorageContext from "context";
import React, { useState, useContext, useEffect } from "react";
import Swal from "sweetalert2";
import EndCustomerTab from "./End Customer Tab/endCustomerTab";
import TicketsTab from "./Ticket Tabs/ticketsTab";
import IBTab from "./IB Tab/ibTab";
import ContactsTab from "./Contacts Tab/contactsTab";
import Loading from "views/super-admin/pre-loader/loader";
import ErrorModel from "views/super-admin/Ticket/components/errormodel";
import accountService from "services/accountService";
import CloseIcon from "@mui/icons-material/Close";
const AccountDetailModal = (props) => {
  const contextStore = useContext(StorageContext);
  const { t } = useTranslation();
  const [active, setActive] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorpopup, setErrorPopup] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Something went Wrong !");

  useEffect(() => {
    if (
      props.data.customer_type == "reseller" ||
      props.data.customer_type == "reseller+customer"
    ) {
      setActive("endCustomer");
    } else {
      setActive("ib");
    }
  }, [props.data]);

  const getAccountDetail = async (customer_code) => {
    setIsLoading(true);
    await accountService
      .getAccountDetail(contextStore.token(), customer_code)
      .then((res) => {
        if (!!res.data.data) {
          props.setAccountDetailData(res.data.data);
        } else {
          Swal.fire({
            text: `Data is not available for ${props.data.reseller_name}.`,
            icon: "warning",
            showConfirmButton: false,
            showCancelButton: true,
            cancelButtonText: "Close",
            customClass: {
              cancelButton: "cancelBtn",
              confirmButton: "confirmBtn",
            },
          }).then((res) => {
            if (res.isConfirmed) {
              props.setShow(false);
            }
          });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsLoading(false);
      });
  };
  const camelCase = (str) => {
    return str[0].toUpperCase() + str.slice(1);
  };
  const handleClose = async () => {
    await Swal.fire({
      title: "Are you sure?",
      text: "Any changes made will not be saved.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      customClass: {
        cancelButton: "cancelBtn",
        confirmButton: "confirmBtn",
      },
    }).then((res) => {
      if (res.isConfirmed) {
        props.setShow(false);
      }
    });
  };
  return (
    <React.Fragment>
      <div
        class="modal show bd-example-modal-lg"
        id="account-detail-modal"
        style={{ display: "block" }} /*onClick={()=> closeModal()}*/
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <Loading
          loading={isLoading}
          background="rgba(0, 0, 0, 0.40)"
          loaderColor="rgb(248, 153, 3)"
        />
        <div class="modal-dialog modal-xl accountDetail">
          <div
            class="modal-content"
            id="content-area"
            style={{ height: "95vh" }}
          >
            <div
              className="modal-header mt-4"
              style={{ alignItems: "flex-start" }}
            >
              <div>
                <p className="modal-title company_name">
                  {" "}
                  {props.data.customer_name}
                </p>
                <p
                  className="company_type"
                  onClick={() => {
                    setIsLoading(true);
                    getAccountDetail(props.data.reseller_code);
                  }}
                >
                  {" "}
                  {props.data.reseller_name}
                </p>
              </div>
              <div className="d-flex">
                <CloseIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => handleClose()}
                />
              </div>
            </div>
            <div class="modal-body" style={{ paddingTop: "1rem" }}>
              <div className="row">
                <div className="col-6">
                  <div className="row">
                    <div className="col">
                      <span className="text_bold">
                        {t("account.country")}:{" "}
                      </span>
                      <span className="text_bold">
                        {props.data.de_entity_country}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <span className="text_bold">
                        {t("account.company_code")}:{" "}
                      </span>
                      <span className="text_bold">
                        {props.data.customer_code}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <span className="text_bold">
                        {t("account.contact")}:{" "}
                      </span>
                      <span className="text_bold">
                        {props.data.ops_contact}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <span className="text_bold">{t("account.email")}: </span>
                      <span className="text_bold">
                        {props.data.customer_email}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <span className="text_bold">{t("account.phone")}: </span>
                      <span className="text_bold">
                        {props.data.customer_phone}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <span className="text_bold">
                        {t("account.language")}:{" "}
                      </span>
                      <span className="text_bold">
                        {props.data.customer_lang}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <span className="text_bold">
                        {t("account.portal_code")}:{" "}
                      </span>
                      <span className="text_bold">
                        {props.data.portal_code}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-6">
                  <div className="row">
                    <div className="col d-flex justify-content-center">
                      <span className="text_bold">
                        {props.data.customer_type == "customer" ? (
                          <span
                            style={{
                              color: "green",
                              fontSize: "1.25rem",
                              marginTop: "-1.5rem",
                              display: "block",
                            }}
                          >
                            End Customer
                          </span>
                        ) : (
                          <span
                            style={{
                              color: "red",
                              fontSize: "1.25rem",
                              marginTop: "-1.5rem",
                              display: "block",
                            }}
                          >
                            Reseller
                          </span>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row pt-2">
                <div class="col-md-12">
                  <div style={{ position: "relative" }} className="test">
                    <div class="btnstyle">
                      <div class="btn-group">
                        {props.data.customer_type != "customer" && (
                          <a
                            href="#"
                            className={`btn tab_button ${
                              active == "endCustomer" ? "bactive" : ""
                            }`}
                            onClick={() => {
                              setActive("endCustomer");
                            }}
                          >
                            {t("account.account_detail_tab.end_customer")}
                          </a>
                        )}
                        <a
                          href="#"
                          className={`btn tab_button ${
                            active == "ib" ? "bactive" : ""
                          }`}
                          onClick={() => {
                            setActive("ib");
                          }}
                        >
                          {t("account.account_detail_tab.install_base")}
                        </a>
                        <a
                          href="#"
                          className={`btn tab_button ${
                            active == "inventory" ? "bactive" : ""
                          }`}
                          onClick={() => {
                            setActive("inventory");
                          }}
                        >
                          {t("account.account_detail_tab.inventory")}
                        </a>
                        <a
                          href="#"
                          className={`btn tab_button ${
                            active == "tickets" ? "bactive" : ""
                          }`}
                          onClick={() => {
                            setActive("tickets");
                          }}
                        >
                          {t("account.account_detail_tab.tickets")}
                        </a>
                        <a
                          href="#"
                          className={`btn tab_button ${
                            active == "contacts" ? "bactive" : ""
                          }`}
                          onClick={() => {
                            setActive("contacts");
                          }}
                        >
                          {t("account.account_detail_tab.contacts")}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                {active == "ib" && (
                  <IBTab
                    data={props.data}
                    setIsLoading={setIsLoading}
                    setErrorPopup={setErrorPopup}
                    setErrorMsg={setErrorMsg}
                  ></IBTab>
                )}
                {active == "tickets" && (
                  <TicketsTab
                    data={props.data}
                    setIsLoading={setIsLoading}
                    setErrorPopup={setErrorPopup}
                    setErrorMsg={setErrorMsg}
                    isReseller={
                      props.data.customer_type == "reseller" ||
                      props.data.customer_type == "reseller+customer"
                        ? true
                        : false
                    }
                  ></TicketsTab>
                )}
                {active == "endCustomer" && (
                  <EndCustomerTab
                    data={props.data}
                    setIsLoading={setIsLoading}
                    setErrorPopup={setErrorPopup}
                    setErrorMsg={setErrorMsg}
                  ></EndCustomerTab>
                )}
                {active == "contacts" && (
                  <ContactsTab
                    data={props.data}
                    setIsLoading={setIsLoading}
                    setErrorPopup={setErrorPopup}
                    setErrorMsg={setErrorMsg}
                  ></ContactsTab>
                )}
              </div>
            </div>
          </div>
        </div>
        {errorpopup && (
          <ErrorModel
            show={errorpopup}
            setShow={setErrorPopup}
            errorMsg={errorMsg}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default AccountDetailModal;
