// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-bg {
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0, 0.3);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}
.loader-bg h3 {
  position: absolute;
  color: #fe8600;
  font-size: 20px;
}
.loader {
  width: 6em;
  height: 6em;
  font-size: 22px;
  box-sizing: border-box;
  border-top: 0.3em solid #505050;
  border-radius: 50%;
  position: absolute;
  animation: rotating 2s ease-in-out infinite;
  --direction: 1;
}
.loader span {
  position: absolute;
  color: #fe8600;
  width: inherit;
  height: inherit;
  text-align: center;
  line-height: 10em;
  font-family: sans-serif;
  animation: rotating 2s linear infinite;
}
.loader::before,
.loader::after {
  content: "";
  position: absolute;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  box-sizing: border-box;
  top: -0.2em;
}
.loader::before {
  border-top: 0.3em solid #fe8600;
  transform: rotate(120deg);
}
.loader::after {
  border-top: 0.3em solid #fe8600;
  transform: rotate(240deg);
}
@keyframes rotating {
  50% {
    transform: rotate(calc(180deg));
  }
  100% {
    transform: rotate(calc(360deg));
  }
}
`, "",{"version":3,"sources":["webpack://./src/views/super-admin/pre-loader/loader.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,6BAA6B;EAC7B,eAAe;EACf,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;AACb;AACA;EACE,kBAAkB;EAClB,cAAc;EACd,eAAe;AACjB;AACA;EACE,UAAU;EACV,WAAW;EACX,eAAe;EACf,sBAAsB;EACtB,+BAA+B;EAC/B,kBAAkB;EAClB,kBAAkB;EAClB,2CAA2C;EAC3C,cAAc;AAChB;AACA;EACE,kBAAkB;EAClB,cAAc;EACd,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,iBAAiB;EACjB,uBAAuB;EACvB,sCAAsC;AACxC;AACA;;EAEE,WAAW;EACX,kBAAkB;EAClB,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,sBAAsB;EACtB,WAAW;AACb;AACA;EACE,+BAA+B;EAC/B,yBAAyB;AAC3B;AACA;EACE,+BAA+B;EAC/B,yBAAyB;AAC3B;AACA;EACE;IACE,+BAA+B;EACjC;EACA;IACE,+BAA+B;EACjC;AACF","sourcesContent":[".loader-bg {\n  width: 100%;\n  height: 100%;\n  background: rgb(0, 0, 0, 0.3);\n  position: fixed;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 99;\n}\n.loader-bg h3 {\n  position: absolute;\n  color: #fe8600;\n  font-size: 20px;\n}\n.loader {\n  width: 6em;\n  height: 6em;\n  font-size: 22px;\n  box-sizing: border-box;\n  border-top: 0.3em solid #505050;\n  border-radius: 50%;\n  position: absolute;\n  animation: rotating 2s ease-in-out infinite;\n  --direction: 1;\n}\n.loader span {\n  position: absolute;\n  color: #fe8600;\n  width: inherit;\n  height: inherit;\n  text-align: center;\n  line-height: 10em;\n  font-family: sans-serif;\n  animation: rotating 2s linear infinite;\n}\n.loader::before,\n.loader::after {\n  content: \"\";\n  position: absolute;\n  width: inherit;\n  height: inherit;\n  border-radius: 50%;\n  box-sizing: border-box;\n  top: -0.2em;\n}\n.loader::before {\n  border-top: 0.3em solid #fe8600;\n  transform: rotate(120deg);\n}\n.loader::after {\n  border-top: 0.3em solid #fe8600;\n  transform: rotate(240deg);\n}\n@keyframes rotating {\n  50% {\n    transform: rotate(calc(180deg));\n  }\n  100% {\n    transform: rotate(calc(360deg));\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
