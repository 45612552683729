import { validateEmail } from "common/helpers";
import { XError } from "components/common";
import StorageContext from "context";
import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import RoleModel from "services/roleServices";
import UserModal from "services/userServices";
import Swal from "sweetalert2";
import { LogoutOnSessionExpire } from "common/helpers";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

function App({ token, data, show, setShow, retriveCurrentData }) {
  const contextStore = React.useContext(StorageContext);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  //   const modalRef = useRef(null);
  const [roles, setRoles] = useState([]);
  const [countries, setCountries] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [positions, setPositions] = useState([]);
  const [deparments, setDepartments] = useState([]);

  const [state, setState] = useState({
    name: "",
    email: "",
    country: "",
    role: "",
    uuid: "",
    status: "",
    primary_num: "",
    secondry_num: "",
    dept: "",
    position: "",
  });

  const [error, setError] = useState({
    name: "",
    email: "",
    country: "",
    role: "",
    primary_num: "",
    secondry_num: "",
    dept: "",
    position: "",
  });
  const { t } = useTranslation();

  useEffect(() => {
    setIsOpen(show);
    getRoles();

    getCountries();

    UserModal.getPosition(token).then((res) => setPositions(res.data.data));

    UserModal.getDepartment(token).then((res) => setDepartments(res.data.data));

    setState({
      name: data.name,
      email: data.email,
      country: data.country.id,
      role: data.role.id,
      uuid: data.uuid,
      status: data.status,
      primary_num: data.primary_num,
      secondry_num: data.secondry_num,
      dept: data.dept,
      position: data.position,
    });

    //retriveCurrentData();

    return () => {};
  }, [show]);

  const getCountries = () => {
    RoleModel.countryService(token, true)
      .then((payload) => {
        setCountries(payload.data.data);
      })
      .catch((err) => {
        LogoutOnSessionExpire(err);
        /*redirect to 500 page */
      });
  };

  const getRoles = async () => {
    await RoleModel.roleAndPermission(token)
      .then((res) => {
        setRoles(res.data.data);
      })
      .catch((err) => {
        LogoutOnSessionExpire(err);
        /*redirect to 500 page */
      });
  };

  //   function openModal() {
  //     setIsOpen(true);
  //   }

  function closeModal() {
    setIsOpen(false);
    setShow(false);
  }
  const checkValidation = () => {
    let error = {
      name: "",
      email: "",
      country: "",
      role: "",
      primary_num: "",
      secondry_num: "",
      dept: "",
      position: "",
    };

    const { name, email, country, role, primary_num, dept, position } = state;

    let flag = true;

    if (name === "" || name === null || name === undefined) {
      error.name = "The name field is required.";
      flag = false;
    }

    if (email === "" || email === null || email === undefined) {
      error.email = "The email field is required.";
      flag = false;
    } else {
      if (!validateEmail(email)) {
        error.email = "The email must be a valid email address.";
        flag = false;
      }
    }

    if (country === "" || country === null || country === undefined) {
      error.country = "The country field is required.";
      flag = false;
    }

    if (role === "" || role === null || role === undefined) {
      error.role = "The role field is required.";
      flag = false;
    }
    if (
      primary_num === "" ||
      primary_num === null ||
      primary_num === undefined
    ) {
      error.primary_num = "The primary number field is required.";
      flag = false;
    }

    if (dept === "" || dept === null || dept === undefined) {
      error.dept = "The department field is required.";
      flag = false;
    }
    if (position === "" || position === null || position === undefined) {
      error.position = "The position field is required.";
      flag = false;
    }

    setError({ ...error });
    return flag;
  };

  const resendEmail = async () => {
    setIsLoading(true);

    await UserModal.resend(token, state)
      .then(async (res) => {
        setIsLoading(false);
        closeModal();
        Swal.fire(t("email_sent"));
      })
      .catch((err) => {
        LogoutOnSessionExpire(err);
        setIsLoading(false);
      });
  };

  const onSubmit = async () => {
    setIsLoading(true);

    if (checkValidation()) {
      await UserModal.update(token, state)
        .then(async (res) => {
          setIsLoading(false);
          closeModal();

          retriveCurrentData();
          Swal.fire(t("userm.user_updated"));
        })
        .catch((err) => {
          LogoutOnSessionExpire(err);
          setIsLoading(false);
          let error = {
            name: "",
            email: "",
            country: "",
            role: "",
            primary_num: "",
            dept: "",
            position: "",
          };
          const errors = err?.response?.data?.errors;

          if (
            errors?.name !== undefined ||
            errors?.name !== "" ||
            errors?.name !== null
          ) {
            error.name = errors.name;
          }
          if (
            errors?.email !== undefined ||
            errors?.email !== "" ||
            errors?.email !== null
          ) {
            error.email = errors.email;
          }
          if (
            errors?.country !== undefined ||
            errors?.country !== "" ||
            errors?.country !== null
          ) {
            error.country = errors.country;
          }
          if (
            errors?.role !== undefined ||
            errors?.role !== "" ||
            errors?.role !== null
          ) {
            error.role = errors.role;
          }
          if (
            errors?.primary_num !== undefined ||
            errors?.primary_num !== "" ||
            errors?.primary_num !== null
          ) {
            error.primary_num = errors.primary_num;
          }
          if (
            errors?.dept !== undefined ||
            errors?.dept !== "" ||
            errors?.dept !== null
          ) {
            error.dept = errors.dept;
          }
          if (
            errors?.position !== undefined ||
            errors?.position !== "" ||
            errors?.position !== null
          ) {
            error.position = errors.position;
          }

          setError({ ...error });
        });
    } else {
      setIsLoading(false);
    }
  };

  const onChangeStatus = async () => {
    const status = state.status === 1 ? 2 : 1;

    await UserModal.changeStatus(token, { status, uuid: state.uuid })
      .then(async (res) => {
        closeModal();

        retriveCurrentData();
      })
      .catch((err) => {
        LogoutOnSessionExpire(err);
        // redirect to 500 page
      });
  };

  const onDelete = async () => {
    // closeModal();
    Swal.fire({
      title: t("common.are_you_sure"),
      text: t("common.you_wont_be_able_to_revert_this"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("common.yes_delete_it"),
      customClass: {
        cancelButton: "cancelBtn",
        confirmButton: "confirmBtn",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        await UserModal.destroy(token, { uuid: state.uuid })
          .then(async (res) => {
            retriveCurrentData();
          })
          .catch((err) => {
            LogoutOnSessionExpire(err);
            // redirect to 500 page
          });
      }
    });
  };

  const getStatus = () => {
    let button = "";
    switch (state.status) {
      case 1:
        button = (
          <button
            type="button"
            style={{ marginRight: "1rem" }}
            onClick={() => onChangeStatus()}
            className="btn btn-outline-primary1 mr_1"
          >
            {" "}
            {t("userm.inactive")}
          </button>
        );
        break;
      case 2:
        button = (
          <button
            type="button"
            style={{ marginRight: "1rem" }}
            onClick={() => onChangeStatus()}
            className="btn btn-outline-primary1 mr_1"
          >
            {" "}
            {t("userm.active")}
          </button>
        );
        break;
      default:
        button = null;
    }

    return button;
  };

  return (
    <div>
      <Modal isOpen={modalIsOpen} style={customStyles} ariaHideApp={false}>
        <div className="modal-header mt-4">
          <h3 className="modal-title"> {t("userm.update_user")}</h3>
          <div
            className="close_icon"
            data-bs-dismiss="modal"
            onClick={() => closeModal()}
          >
            <CloseIcon style={{ cursor: "pointer" }} fontSize="large" />
          </div>
        </div>
        <div className="modal-body">
          <div className="card pt-5">
            <div
              className="card-body"
              style={{ padding: "0px", overflow: "hidden" }}
            >
              <div className="basic-form">
                <form>
                  <div className="row">
                    <div className="mb-3 col-md-6 mt-2313">
                      <label className="form-label">
                        {" "}
                        {t("userm.name")}
                        <span className="text-danger"> *</span>
                      </label>
                      <input
                        type="text"
                        defaultValue={state.name}
                        className="form-control"
                        onChange={(event) =>
                          setState({ ...state, name: event.target.value })
                        }
                        placeholder={t("userm.name")}
                      />
                      <XError message={error.name} />
                    </div>
                    <div className="mb-3 col-md-6 mt-2313">
                      <label className="form-label">
                        {" "}
                        {t("userm.username_email")}
                        <span className="text-danger"> *</span>
                      </label>
                      <input
                        type="text"
                        defaultValue={state.email}
                        className="form-control"
                        onChange={(event) =>
                          setState({ ...state, email: event.target.value })
                        }
                        placeholder="jennifersmith@mail.com"
                      />
                      <XError message={error.email} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-3 col-md-6 mt-2313">
                      <label className="form-label">
                        {" "}
                        {t("userm.primary_num")}
                        <span className="text-danger"> *</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={state.primary_num}
                        onChange={(event) =>
                          setState({
                            ...state,
                            primary_num: event.target.value,
                          })
                        }
                        placeholder={t("userm.primary_num")}
                      />
                      <XError message={error.primary_num} />
                    </div>
                    <div className="mb-3 col-md-6 mt-2313">
                      <label className="form-label">
                        {" "}
                        {t("userm.secondry_num")}{" "}
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={state.secondry_num}
                        onChange={(event) =>
                          setState({
                            ...state,
                            secondry_num: event.target.value,
                          })
                        }
                        placeholder={t("userm.secondry_num")}
                      />
                      <XError message={error.secondry_num} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-3 col-md-6 mt-2313">
                      <label className="form-label">
                        {" "}
                        {t("userm.department")}
                        <span className="text-danger"> *</span>
                      </label>
                      <select
                        id="inputState"
                        value={state.dept}
                        onChange={(event) =>
                          setState({ ...state, dept: event.target.value })
                        }
                        className="nice-select  default- form-control wide"
                      >
                        <option>{t("userm.choose")}...</option>
                        {deparments &&
                          deparments.map((dept) => {
                            return (
                              <option value={dept.id} key={dept.id}>
                                {dept.name}{" "}
                              </option>
                            );
                          })}
                      </select>
                      <XError message={error.dept} />
                    </div>
                    <div className="mb-3 col-md-6 mt-2313">
                      <label className="form-label">
                        {" "}
                        {t("userm.position")}{" "}
                        <span className="text-danger"> *</span>{" "}
                      </label>
                      <select
                        id="inputState"
                        value={state.position}
                        onChange={(event) =>
                          setState({ ...state, position: event.target.value })
                        }
                        className="nice-select  default- form-control wide"
                      >
                        <option>{t("userm.choose")}...</option>
                        {positions &&
                          positions.map((position) => {
                            return (
                              <option value={position.id} key={position.id}>
                                {position.name}{" "}
                              </option>
                            );
                          })}
                      </select>
                      <XError message={error.position} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-3 col-md-6 mt-2313">
                      <label className="form-label">
                        {" "}
                        {t("userm.country")}
                        <span className="text-danger"> *</span>
                      </label>
                      <select
                        value={state.country}
                        onChange={(event) =>
                          setState({ ...state, country: event.target.value })
                        }
                        className="nice-select  default- form-control wide"
                      >
                        {countries &&
                          countries.map((country) => {
                            return (
                              <option value={country.id} key={country.id}>
                                {country.name}{" "}
                              </option>
                            );
                          })}
                      </select>
                      <XError message={error.country} />
                    </div>
                    <div className="mb-3 col-md-6 mt-2313">
                      <label className="form-label">
                        {" "}
                        {t("userm.role")}
                        <span className="text-danger"> *</span>
                      </label>
                      <select
                        value={state.role}
                        onChange={(event) =>
                          setState({ ...state, role: event.target.value })
                        }
                        className="default-select form-control wide"
                      >
                        {roles &&
                          roles.map((role) => {
                            return (
                              <option value={role.id} key={role.id}>
                                {role.name}{" "}
                              </option>
                            );
                          })}
                      </select>
                      <XError message={error.role} />
                    </div>
                  </div>

                  <div
                    className="toolbar toolbar-bottom mt-5 d-flex"
                    role="toolbar"
                  >
                    {getStatus()}
                    <button
                      type="button"
                      onClick={() => onDelete()}
                      style={{ marginRight: "1rem", marginBottom: "0.25rem" }}
                      className="btn btn-danger mr_1"
                    >
                      {" "}
                      {t("common.delete")}
                    </button>
                    {data.status === 0 && (
                      <button
                        onClick={() => resendEmail()}
                        style={{ marginRight: "1rem", marginBottom: "0.25rem" }}
                        type="button"
                        className="btn btn-outline-primary mr_1"
                      >
                        {" "}
                        {t("userm.resend")}{" "}
                      </button>
                    )}
                    <button
                      onClick={() => closeModal()}
                      style={{ marginRight: "1rem", marginBottom: "0.25rem" }}
                      type="button"
                      className="btn btn-outline-primary mr_1"
                    >
                      {" "}
                      {t("common.cancel")}{" "}
                    </button>
                    {isLoading ? (
                      <button type="button" className="btn btn-primary">
                        {" "}
                        {t("common.loading")}...{" "}
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={() => onSubmit()}
                        className="btn btn-primary"
                        style={{ marginBottom: "0.25rem" }}
                      >
                        {" "}
                        {t("common.submit")}{" "}
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default App;
