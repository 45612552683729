import { LogoutOnSessionExpire } from "common/helpers";
import AuthContext from "context";
import React, { useContext, useEffect, useRef, useState } from "react"; 
import Common from "services/commonService";
import RoleModel from "services/roleServices";
import Layout from "views/super-admin/Layouts";
import Loading from "views/super-admin/pre-loader/loader";
import Navigation from "../Component/Navigation";
import FiberMapModal from "./modals/FiberMapModal";
import "./network.css";
import NetworkNavigation from "./networkNavigation"; 
import { useTranslation } from "react-i18next";

export default function Fibermap() {
  const authContext = useContext(AuthContext);
  const [dataCenter, setDataCenter] = useState([]);
  const [currentDataCenter, setCurrentDataCenter] = useState([]);
  const [countryName, setCountryName] = useState("Country");
  const [country, setCountry] = useState(0);
  const [activeTab, setActiveTab] = useState();
  const initialMount = useRef(true);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [counter, setCounter] = useState(1);
  const [fileExists, setFileExists] = useState(false);
  const [i, setI] = useState(0);
  const { t } = useTranslation();
  const [prevdisable, setPrevDisable] = useState(false);
  const [nextdisable, setNextDisable] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [openAddModal, setOpenAddModel] = useState(false);
  const [countries, setCountries] = useState([]);
  const [imageStatus, setImageStatus] = useState(false); 
  useEffect(() => {
    getData();

    if (
      authContext?.getAuth?.role?.space === 3 ||
      authContext?.getAuth?.role?.m_e === 3 ||
      authContext?.getAuth?.role?.network === 3
    ) {
      setIsReadOnly(false);
    }

    if (initialMount.current === false) {
      selectDataCenterFloor(authContext.getNetworkDataCenter);
    }

    getAllDataCenter();
  }, []);

  const getData = async () => {
    await RoleModel.countryService(authContext.token()).then((res) => {
      setCountries(res.data.data);
    }).catch((err) => {
      LogoutOnSessionExpire(err); 
    }).finally(() => {
      setIsLoading(false);
    });
  };

  const getAllDataCenter = async () => {
    setCountryName("Country");
    AllCounter(authContext.getNetworkDataCenter, 1);

    await RoleModel.dataCenter(authContext.token()).then((res) => {
      setDataCenter(res.data.data.filter((data) => data.dc_type_id === 1));

      if (res.data.data.length > 0) { 
        if (imageStatus === true) { 
          selectDataCenterFloor(authContext.getNetworkDataCenter);
        }
        if (initialMount.current) { 
          if (authContext.getNetworkDataCenter.length === 0) {
            authContext.setNetworkDataCenter(res.data.data[0]);
          }
          setActiveTab(authContext.getNetworkDataCenter.id);
          selectDataCenterFloor(authContext.getNetworkDataCenter);
          setCountry(authContext.getNetworkDataCenter.country_id);
          initialMount.current = false;
          AllCounter(authContext.getNetworkDataCenter, 1);
        }

        // selectDataCenterFloor(authContext.getNetworkDataCenter);
      }
    });
  };

  const getDataCenterById = async (e) => {
    setCountryName(e.name);
    setIsLoading(true);
    await RoleModel.dataCenterByCountryId(authContext.token(), e).then(
      (res) => {
        //await networkServices.getNetworkDevices(authContext.token(),e).then((res) => {
        setDataCenter(res.data.data);
        if (res.data.data.length > 0) {
          selectDataCenterFloor(res.data.data[0]);
          AllCounter(res.data.data[0], 1);
        }
      }
    ).catch((err) => {
      LogoutOnSessionExpire(err); 
    }).finally(() => setIsLoading(false));
  };

  const AllCounter = async (e, k) => {
    let filePath = "";
    let date = new Date().getSeconds(); 
    if (e.country_id !== "" && e.id !== "") {
      filePath = `${authContext.getAssetPath}/images/${e.country_id}/${e.id}/fibermap/${k}.png?q=${date}`;
    } else {
      filePath = "Data Not Available";
    }
    await fetch(filePath, { method: "HEAD" }).then((res) => {
      if (res.ok) {
        setI(k);
        AllCounter(e, k + 1);
      } else {
        if (`${k}.png` === "1.png") {
          setI(0);
        }
        return "not found";
      }
    });
  };
  const selectDataCenterFloor = async (e, c = counter) => {
    setIsLoading(true)
    authContext.setNetworkDataCenter(e);
    let date = new Date().getSeconds();
    let filePath = "";

    if (e.country_id !== "" && e.id !== "") {
      filePath = `${authContext.getAssetPath}/images/${e.country_id}/${e.id}/fibermap/${c}.png?q=${date}`;
    } else {
      filePath = "Data Not Available";
    }
    return await fetch(filePath, { method: "HEAD" }).then((res) => {
      if (res.ok) {
        setImage(filePath);
        setCurrentDataCenter(e);
        setTitle(e.name);

        setFileExists(true);
      } else {
        setTitle(e.name);
        setCurrentDataCenter(e);
        setFileExists(false);
      }
      
    }).catch((err) => {
      LogoutOnSessionExpire(err); 
    }).finally(() => setIsLoading(false));
  };

  const renderCountry = () => {
    return (
      countries.length &&
      countries.map((data, i) => {
        return (
          <a
            href="#cont"
            className="dropdown-item"
            key={i}
            onClick={() => {
              getDataCenterById(data);
            }}
          >
            {data.name}{" "}
          </a>
        );
      })
    );
  };
  const nextImage = (count) => {
    if (count !== i) {
      setCounter(++count);
      selectDataCenterFloor(currentDataCenter, count);
    } else {
      setNextDisable(true);
      setPrevDisable(false);
    }
  };
  const prevImage = (count) => {
    if (count !== 1) {
      setCounter(--count);
      selectDataCenterFloor(currentDataCenter, count);
    } else {
      setPrevDisable(true);
      setNextDisable(false);
    }
  };

  return (
    <React.Fragment>
      <Loading
        loading={isLoading}
        background="rgba(0, 0, 0, 0.4)"
        loaderColor="rgb(248, 153, 3)"
      />
      <Layout>
        <div className="content-body">
          <Navigation />
          <div className="container-fluid">
            <hr className="main" />
            <div className="row">
              <div className="col-xl-1" style={{ width: "12%" }}>
                <div className="leftside">
                  <NetworkNavigation />
                </div>
              </div>
              <div className="col-lg-11" style={{ width: "88%" }}>
                <div id="pro">
                  <div className="profile-tab">
                    <div className="custom-tab-1">
                      <div className="main_scrll">
                        <div className="btn_ul">
                          <ul className="nav nav-tabs mb-3">
                            <li className="nav-item">
                              {" "}
                              <button
                                className="btn btn-secondary"
                                style={{ width: "100%" }}
                                onClick={getAllDataCenter}
                              >
                                {" "}
                                {t("network.global")}{" "}
                              </button>
                            </li>
                            <li className="nav-item">
                              <div className="btn-group" role="group">
                                <button
                                  type="button"
                                  className="btn btn-light dropdown-toggle"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  style={{ width: "100%" }}
                                >
                                  {" "}
                                  {countryName}{" "}
                                </button>
                                <div
                                  className="dropdown-menu"
                                  style={{ margin: "0px" }}
                                >
                                  {renderCountry()}
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="outer-div-setting">
                          <div className="ul_scrll">
                            <ul className="nav nav-tabs mb-3">
                              {dataCenter &&
                                dataCenter.map((data, index) => {
                                  if (
                                    authContext.getNetworkDataCenter &&
                                    authContext.getNetworkDataCenter.id ===
                                      data.id
                                  ) {
                                    return (
                                      <li
                                        className={
                                          index === 0 ? "nav-item" : "nav-item"
                                        }
                                        key={index}
                                      >
                                        <a
                                          href="#dsf"
                                          onClick={() => {
                                            selectDataCenterFloor(
                                              data,
                                              counter
                                            );
                                            AllCounter(data, 1);
                                          }}
                                          style={{ cursor: "pointer" }}
                                          className="nav-link active show"
                                        >
                                          <img
                                            alt=""
                                            className="down setting_down"
                                            src="\images\downward-arrow.png"
                                          />
                                          {data.name}
                                        </a>
                                      </li>
                                    );
                                  } else {
                                    return (
                                      <li
                                        className={
                                          index === 0 ? "nav-item" : "nav-item"
                                        }
                                        key={index}
                                      >
                                        <a
                                          href="#ds"
                                          onClick={() => {
                                            selectDataCenterFloor(
                                              data,
                                              counter
                                            );
                                            AllCounter(data, 1);
                                          }}
                                          style={{ cursor: "pointer" }}
                                          className="nav-link"
                                        >
                                          {" "}
                                          {data.name}{" "}
                                        </a>
                                      </li>
                                    );
                                  }
                                })}
                            </ul>
                          </div>
                          <div>
                            <span>
                              <i>
                              {t(
                                  "network.select_a_country_to_view_partner_data_center"
                                )} 
                              </i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-3">
                    <div className="col-12 col-lg-7">
                      <div className="map_pic h-100">
                        <h6
                          style={{
                            fontFamily: "DM Sans",
                            fontWeight: "600",
                            fontSize: "1.25rem",
                          }}
                        >
                          {title}
                        </h6>
                      </div>
                    </div>
                    <div className="col-12 col-lg-3">
                      <div
                        className="d-flex align-items-center justify-content-between"
                        style={{ position: "relative" }}
                      >
                        <button
                          onClick={() => setOpenAddModel(true)}
                          className="btn btn-upload-img"
                        >
                          {t("network.upload_image")}
                        </button>

                        {fileExists ? (
                          <div className="pages_count">
                            {t("network.page")} {counter} {t("network.of")} {i}{" "}
                            {prevdisable ? (
                              <span style={{ fontSize: "1.25rem" }}>
                                &laquo;
                              </span>
                            ) : (
                              <span
                                onClick={() => prevImage(counter)}
                                style={{
                                  fontSize: "1.25rem",
                                  cursor: "pointer",
                                }}
                              >
                                &laquo;
                              </span>
                            )}
                            {nextdisable ? (
                              <span style={{ fontSize: "1.25rem" }}>
                                &nbsp;&raquo;
                              </span>
                            ) : (
                              <span
                                onClick={() => nextImage(counter)}
                                style={{
                                  fontSize: "1.25rem",
                                  cursor: "pointer",
                                }}
                              >
                                &nbsp;&raquo;
                              </span>
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-12 col-lg-12 mb-5">
                      {fileExists ? (
                        <img
                          alt=""
                          src={image}
                          style={{ width: "90%" }}
                          width="100%"
                          height="100%"
                        />
                      ) : (
                        <h2 style={{ textAlign: "center" }}>
                          {t("network.data_not_available")}
                        </h2>
                      )}
                    </div>
                  </div>
                  {openAddModal === true ? (
                    <FiberMapModal
                      closeModal={setOpenAddModel}
                      counter={counter}
                      i={i}
                      prevdisable={prevdisable}
                      nextdisable={nextdisable}
                      prevImage={prevImage}
                      nextImage={nextImage}
                      fileExists={fileExists}
                      setImage={setImage}
                      refresh={getAllDataCenter}
                      setImageStatus={setImageStatus}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  );
}
