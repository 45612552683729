import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";
import Power from "services/powerDistribution";
import AuthContext from "context";
import "../style.css";
import { LogoutOnSessionExpire } from 'common/helpers';
import { useTranslation } from "react-i18next";

const HIGHLIGHTEDCOLOR = "red";
const DEFAULTCOLOR = "#393939";

const Overview = ({ activeTab, setIsLoading, setLastActivity }) => {
  const incrementStep = process.env.REACT_APP_POWER_DIST_PAGINATION_OFFSET;
  const authContext = useContext(AuthContext);
  const getDataCenter = authContext.getDataCenters
    ? authContext.getDataCenters[0].id
    : 0;
 // const activeDataCenter = activeTab ? activeTab : getDataCenter || 0;

  const activeDataCenter = activeTab
    ? activeTab
    : authContext.getNetworkDataCenter?.id;
  const [showAllChecked, setShowAllChecked] = useState(true);
  const { t } = useTranslation();
  // Generator State
  const [nextGenerator, setNextGenerator] = useState(0);
  const [fetchingGenerator, setFetchingGenerator] = useState(false);
  const [hasMoreGenerator, setHasMoreGenerator] = useState(false);
  const [state, setState] = useState([]);

  // Transformer State
  const [nextTransformer, setNextTransformer] = useState(0);
  const [fetchingTransformer, setFetchingTransformer] = useState(false);
  const [hasMoreTransformer, setHasMoreTransformer] = useState(false);
  const [transformer, setTransformer] = useState([]);

  // UPS State
  const [nextUPS, setNextUPS] = useState(0);
  const [fetchingUPS, setFetchingUPS] = useState(false);
  const [hasMoreUPS, setHasMoreUPS] = useState(false);
  const [ups, setUps] = useState([]);

  //RPP State
  const [nextRPP, setNextRPP] = useState(0);
  const [fetchingRPP, setFetchingRPP] = useState(false);
  const [hasMoreRPP, setHasMoreRPP] = useState(false);
  const [rpps, setRpp] = useState([]);

  // Breaker State
  const [nextBreaker, setNextBreaker] = useState(0);
  const [fetchingBreaker, setFetchingBreaker] = useState(false);
  const [hasMoreBreaker, setHasMoreBreaker] = useState(false);
  const [breaker, setBreaker] = useState([]);

  const [transformerIds, setTransformerEffectedIds] = useState([]);
  const [upsIds, setUpsEffectedIds] = useState([]);
  const [rpsIds, setRpsEffectedIds] = useState([]);
  const [selectedGeneratorId, setGeneratorId] = useState(0);

  const breaksRef = useRef();
  const rppsRef = useRef();
  const upsRef = useRef();
  const transformerRef = useRef();
  const generatorRef = useRef();

  useEffect(() => {
    showAll();

    return () => {
      setHasMoreRPP(false);
    };
  }, [activeTab]);

    

    useEffect(() => {
        if(nextGenerator > 0){

            fetchGenerator();
        }
    },[nextGenerator]);

    useEffect(() => {
        if(nextTransformer > 0){

            fetchTransformer();
        }
    },[nextTransformer]);

    useEffect(() => {
        if(nextUPS > 0){

            fetchUPS();
        }
    },[nextUPS]);

    useEffect(() => {
        if(nextRPP > 0){

            fetchRPP();
        }
    },[nextRPP]);

    useEffect(() => {
        if(nextBreaker > 0){

            fetchBreaker();
        }
    },[nextBreaker]);



    useEffect(() => {
        
        breaksRef.current?.scrollTo(0, 0);
        rppsRef.current?.scrollTo(0, 0);
        upsRef.current?.scrollTo(0, 0);
        transformerRef.current?.scrollTo(0, 0);
        generatorRef.current?.scrollTo(0, 0);

        breaksRef.current?.addEventListener("scroll",handleBreakerscroll);
        rppsRef.current?.addEventListener("scroll",handleRPPScroll);
        upsRef.current?.addEventListener("scroll",handleBreakerscroll);
        transformerRef.current?.addEventListener("scroll",handleBreakerscroll);
        generatorRef.current?.addEventListener("scroll",handleBreakerscroll);

        return () => {
            breaksRef.current?.removeEventListener("scroll",handleBreakerscroll);
            rppsRef.current?.removeEventListener("scroll",handleRPPScroll);
            upsRef.current?.removeEventListener("scroll",handleBreakerscroll);
            transformerRef.current?.removeEventListener("scroll",handleBreakerscroll);
            generatorRef.current?.removeEventListener("scroll",handleBreakerscroll);
        }
    },[]);

    const handleBreakerscroll = () => {  
        const scrollTop = breaksRef.current.scrollTop;
        const scrollHeight = breaksRef.current.scrollHeight;
        const clientHeight = breaksRef.current.clientHeight;
       // console.log(scrollTop + clientHeight+1,scrollHeight)
        if (scrollTop + clientHeight + 1 >= scrollHeight) {
            setNextBreaker(prev =>  Number(prev) + Number(incrementStep));
        }
    }

    const handleRPPScroll = () => {  
        const scrollTop = rppsRef.current.scrollTop;
        const scrollHeight = rppsRef.current.scrollHeight;
        const clientHeight = rppsRef.current.clientHeight;
       // console.log(scrollTop + clientHeight+1,scrollHeight)
        if (scrollTop + clientHeight + 1 >= scrollHeight) {
            setNextRPP(prev =>  Number(prev) + Number(incrementStep));
        }
    }

    const handleUPSScroll = () => {  
        const scrollTop = upsRef.current.scrollTop;
        const scrollHeight = upsRef.current.scrollHeight;
        const clientHeight = upsRef.current.clientHeight;
       // console.log(scrollTop + clientHeight+1,scrollHeight)
        if (scrollTop + clientHeight + 1 >= scrollHeight) {
            setNextUPS(prev =>  Number(prev) + Number(incrementStep));
        }
    }

    const handleTransformerScroll = () => {  
        const scrollTop = transformerRef.current.scrollTop;
        const scrollHeight = transformerRef.current.scrollHeight;
        const clientHeight = transformerRef.current.clientHeight;
       // console.log(scrollTop + clientHeight+1,scrollHeight)
        if (scrollTop + clientHeight + 1 >= scrollHeight) {
            setNextTransformer(prev =>  Number(prev) + Number(incrementStep));
        }
    }

    const handleGeneratorScroll = () => {  
        const scrollTop = generatorRef.current.scrollTop;
        const scrollHeight = generatorRef.current.scrollHeight;
        const clientHeight = generatorRef.current.clientHeight;
       // console.log(scrollTop + clientHeight+1,scrollHeight)
        if (scrollTop + clientHeight + 1 >= scrollHeight) {
            setNextGenerator(prev =>  Number(prev) + Number(incrementStep));
        }
    }















  const fetchGenerator = () => {
    setFetchingGenerator(true);
    let totalCount = state.length;

    Power.getGenerator(authContext.token(), activeDataCenter, nextGenerator)
      .then((res) => {
        totalCount += res.data.data.length;
        setState([...state, ...res.data.data]);
       // setNextGenerator(Number(nextGenerator) + Number(incrementStep));
        if (totalCount === res.data.data.count) {
          setHasMoreGenerator(false);
        }
      })
      .catch((err) => {
        //500 server error
      })
      .finally(() => {
        setFetchingGenerator(false);
      });
  };

  const fetchTransformer = () => {
    setFetchingTransformer(true);
    let transformerEffectedIds = [];
    let totalCount = transformer.length;

    Power.transformer(authContext.token(), activeDataCenter, nextTransformer)
      .then((res) => {
        const newTransformer =
          res.data.data &&
          res.data.data.map((trans) => {
            if (selectedGeneratorId === trans.generator_id) {
              transformerEffectedIds.push(trans.id);
              trans.color = HIGHLIGHTEDCOLOR;
            } else {
              trans.color = DEFAULTCOLOR;
            }
            return trans;
          });
        totalCount += newTransformer.length;
        setTransformerEffectedIds([
          ...transformerEffectedIds,
          ...transformerIds,
        ]);

        setTransformer([...transformer, ...newTransformer]);
        //setNextTransformer(Number(nextTransformer) + Number(incrementStep));
        if (totalCount === res.data.totalRecords) {
          setHasMoreTransformer(false);
        }
      })
      .catch((err) => {
        //500 server error
      })
      .finally(() => {
        setFetchingTransformer(false);
      });
  };

  const fetchUPS = () => {
    setFetchingUPS(true);
    let upsEffectedIds = [];
    let totalCount = ups.length;

    Power.getUPS(authContext.token(), activeDataCenter, nextUPS)
      .then((res) => {
        const newUps =
          res.data.data &&
          res.data.data.map((up) => {
            if (transformerIds.includes(up.transformer_id)) {
              upsEffectedIds.push(up.id);
              up.color = HIGHLIGHTEDCOLOR;
            } else {
              up.color = DEFAULTCOLOR;
            }
            return up;
          });

        totalCount += newUps.length;
        setUpsEffectedIds([...upsEffectedIds, ...upsIds]);
        setUps([...ups, ...newUps]);
       // setNextUPS(Number(nextUPS) + Number(incrementStep));

        if (totalCount === res.data.totalRecords) {
          setHasMoreUPS(false);
        }
      })
      .catch((err) => {
        //500 server error
      })
      .finally(() => {
        setFetchingUPS(false);
      });
  };

  const fetchRPP = () => {
    setFetchingRPP(true);
    let rpsEffectedIds = [];
    let totalCount = rpps.length;

    Power.getRPP(authContext.token(), activeDataCenter, nextRPP)
      .then((res) => {
        const newRpps =
          res.data.data &&
          res.data.data.map((rps) => {
            if (upsIds.includes(rps.ups_id)) {
              rpsEffectedIds.push(rps.id);
              rps.color = HIGHLIGHTEDCOLOR;
            } else {
              rps.color = DEFAULTCOLOR;
            }
            return rps;
          });

        totalCount += newRpps.length;
        setRpsEffectedIds([...rpsEffectedIds, ...rpsIds]);
        setRpp([...rpps, ...newRpps]);
      //  setNextRPP(Number(nextRPP) + Number(incrementStep));
        if (totalCount === res.data.totalRecords) {
          setHasMoreRPP(false);
        }
      })
      .catch((err) => {
        //500 server error
      })
      .finally(() => {
        setFetchingRPP(false);
      });
  };

  const fetchBreaker = () => {
    setFetchingBreaker(true);
    let totalCount = breaker.length;
    Power.getBreaker(authContext.token(), activeDataCenter, nextBreaker)
      .then((res) => {
        let count = 0;
        const newBreaker =
          res.data.data &&
          res.data.data.map((breaks) => {
            if (rpsIds.includes(breaks.upstream_device_id)) {
              breaks.color = HIGHLIGHTEDCOLOR;
            } else {
              breaks.color = DEFAULTCOLOR;
            }
            return breaks;
          });
        totalCount += newBreaker.length;
        setBreaker([...breaker, ...newBreaker]);
        //setNextBreaker(Number(nextBreaker) + Number(incrementStep));
        if (totalCount === res.data.totalRecords) {
          setHasMoreBreaker(false);
        }
      })
      .catch((err) => {
        //500 server error
      })
      .finally(() => {
        setFetchingBreaker(false);
      });
  };

  const reRender = (id) => {
    setGeneratorId(id);
    let transformerEffectedIds = [];
    let upsEffectedIds = [];
    let rpsEffectedIds = [];

    setShowAllChecked(false);
    const data = state.map((s) => {
      if (s.id === id) {
        s.color = HIGHLIGHTEDCOLOR;
      } else {
        s.color = DEFAULTCOLOR;
      }
      return s;
    });

    setState(data);

    const newTransformer = transformer.map((trans) => {
      if (trans.generator_id === id) {
        transformerEffectedIds.push(trans.id);
        trans.color = HIGHLIGHTEDCOLOR;
      } else {
        trans.color = DEFAULTCOLOR;
      }
      return trans;
    });

    setTransformer(newTransformer);

    const newUps = ups.map((up) => {
      if (transformerEffectedIds.includes(up.transformer_id)) {
        upsEffectedIds.push(up.id);
        up.color = HIGHLIGHTEDCOLOR;
      } else {
        up.color = DEFAULTCOLOR;
      }
      return up;
    });

    setUps(newUps);

    const newRpps = rpps.map((rps) => {
      if (upsEffectedIds.includes(rps.ups_id)) {
        rpsEffectedIds.push(rps.id);
        rps.color = HIGHLIGHTEDCOLOR;
      } else {
        rps.color = DEFAULTCOLOR;
      }
      return rps;
    });

    setRpp(newRpps);

    const newBreaker = breaker.map((breaks) => {
      if (rpsEffectedIds.includes(breaks.upstream_device_id)) {
        breaks.color = HIGHLIGHTEDCOLOR;
      } else {
        breaks.color = DEFAULTCOLOR;
      }
      return breaks;
    });

    setBreaker(newBreaker);

    setTransformerEffectedIds(transformerEffectedIds);
    setUpsEffectedIds(upsEffectedIds);
    setRpsEffectedIds(rpsEffectedIds);
  };

  const showAll = async () => {
    setIsLoading(true);
    setShowAllChecked(false);

    await Power.getGenerator(authContext.token(), activeDataCenter)
      .then((res) => {
        const data = res.data.data.map((d) => {
          d.color = DEFAULTCOLOR;
          return d;
        });
        setState(data);

        if (res.data.data.count > incrementStep) {
          setHasMoreGenerator(true);
        }

        if (res.data.lastActivity.length) {
          setLastActivity(res.data.lastActivity[0].date_change);
        }
      })
      .catch((err) => {
        //500 server error
      });
    await Power.transformer(authContext.token(), activeDataCenter)
      .then((res) => {
        setTransformer(res.data.data);
        if (res.data.totalRecords > incrementStep) {
          setHasMoreTransformer(true);
        }
      })
      .catch((err) => {
        //500 server error
      });
    await Power.getUPS(authContext.token(), activeDataCenter)
      .then((res) => {
        setUps(res.data.data);
        if (res.data.totalRecords > incrementStep) {
          setHasMoreUPS(true);
        }
      })
      .catch((err) => {
        //500 server error
      });
    await Power.getBreaker(authContext.token(), activeDataCenter)
      .then((res) => {
        setBreaker(res.data.data);
        if (res.data.totalRecords > incrementStep) {
          setHasMoreBreaker(true);
        }
        if (res.data.totalRecords === 0) {
          setHasMoreBreaker(false);
        }
      })
      .catch((err) => {
        //500 server error
      });

    await Power.getRPP(authContext.token(), activeDataCenter)
      .then((res) => {
        setRpp(res.data.data);

        if (res.data.totalRecords > incrementStep) {
          setHasMoreRPP(true);
        }
      })
      .catch((err) => {
        //500 server error
      });

    setIsLoading(false);
  };
  const onBreakerClick = (obj) => {
    clearToDefault();

    const newBreaker = breaker.map((breaks) => {
      if (obj.id === breaks.id) {
        breaks.color = HIGHLIGHTEDCOLOR;
      } else {
        breaks.color = DEFAULTCOLOR;
      }
      return breaks;
    });

    setBreaker(newBreaker);

    let upsId;

    const newRpps = rpps.map((rps) => {
      if (obj.upstream_device_id === rps.id) {
        upsId = rps.ups_id;
        rps.color = HIGHLIGHTEDCOLOR;
      } else {
        rps.color = DEFAULTCOLOR;
      }
      return rps;
    });

    setRpp(newRpps);

    let transformerId;
    const newUps = ups.map((up) => {
      if (upsId === up.id) {
        transformerId = up.transformer_id;
        up.color = HIGHLIGHTEDCOLOR;
      } else {
        up.color = DEFAULTCOLOR;
      }
      return up;
    });

    setUps(newUps);
    let generatorId;
    const newTransformer = transformer.map((trans) => {
      if (trans.id === transformerId) {
        generatorId = trans.generator_id;
        trans.color = HIGHLIGHTEDCOLOR;
      } else {
        trans.color = DEFAULTCOLOR;
      }
      return trans;
    });

    const data = state.map((s) => {
      if (s.id === generatorId) {
        s.color = HIGHLIGHTEDCOLOR;
      } else {
        s.color = DEFAULTCOLOR;
      }
      return s;
    });

    setState(data);
  };

  const onRppClick = (obj) => {
    clearToDefault();
    setRpsEffectedIds([...rpsIds, obj.id]);
    const newRpps = rpps.map((rps) => {
      if (obj.id === rps.id) {
        rps.color = HIGHLIGHTEDCOLOR;
      } else {
        rps.color = DEFAULTCOLOR;
      }
      return rps;
    });

    setRpp(newRpps);

    const newBreaker = breaker.map((breaks) => {
      if (obj.id === breaks.upstream_device_id) {
        breaks.color = HIGHLIGHTEDCOLOR;
      } else {
        breaks.color = DEFAULTCOLOR;
      }
      return breaks;
    });

    setBreaker(newBreaker);

    let transformerId;

    const newUps = ups.map((up) => {
      if (obj.ups_id === up.id) {
        transformerId = up.transformer_id;
        up.color = HIGHLIGHTEDCOLOR;
      } else {
        up.color = DEFAULTCOLOR;
      }
      return up;
    });

    setUps(newUps);

    let generatorId;
    const newTransformer = transformer.map((trans) => {
      if (trans.id === transformerId) {
        generatorId = trans.generator_id;
        trans.color = HIGHLIGHTEDCOLOR;
      } else {
        trans.color = DEFAULTCOLOR;
      }
      return trans;
    });

    const data = state.map((s) => {
      if (s.id === generatorId) {
        s.color = HIGHLIGHTEDCOLOR;
      } else {
        s.color = DEFAULTCOLOR;
      }
      return s;
    });

    setState(data);
  };

  const onUpsClick = (obj) => {
    clearToDefault();
    let upsEffectedIds = [];
    let rpsEffectedIds = [];

    const newUps = ups.map((up) => {
      if (up.id === obj.id) {
        upsEffectedIds.push(up.id);
        up.color = HIGHLIGHTEDCOLOR;
      } else {
        up.color = DEFAULTCOLOR;
      }
      return up;
    });

    setUps(newUps);

    const newRpps = rpps.map((rps) => {
      if (upsEffectedIds.includes(rps.ups_id)) {
        rpsEffectedIds.push(rps.id);
        rps.color = HIGHLIGHTEDCOLOR;
      } else {
        rps.color = DEFAULTCOLOR;
      }
      return rps;
    });

    setRpp(newRpps);

    const newBreaker = breaker.map((breaks) => {
      if (rpsEffectedIds.includes(breaks.upstream_device_id)) {
        breaks.color = HIGHLIGHTEDCOLOR;
      } else {
        breaks.color = DEFAULTCOLOR;
      }
      return breaks;
    });

    setBreaker(newBreaker);

    let generatorId;
    const newTransformer = transformer.map((trans) => {
      if (trans.id === obj.transformer_id) {
        generatorId = trans.generator_id;
        trans.color = HIGHLIGHTEDCOLOR;
      } else {
        trans.color = DEFAULTCOLOR;
      }
      return trans;
    });

    const data = state.map((s) => {
      if (s.id === generatorId) {
        s.color = HIGHLIGHTEDCOLOR;
      } else {
        s.color = DEFAULTCOLOR;
      }
      return s;
    });

    setState(data);

    setUpsEffectedIds(upsEffectedIds);
    setRpsEffectedIds(rpsEffectedIds);
  };
  const onTransformerClick = (obj) => {
    clearToDefault();
    setGeneratorId(obj.generator_id);
    let transformerEffectedIds = [];
    let upsEffectedIds = [];
    let rpsEffectedIds = [];

    const newTransformer = transformer.map((trans) => {
      if (trans.id === obj.id) {
        transformerEffectedIds.push(trans.id);
        trans.color = HIGHLIGHTEDCOLOR;
      } else {
        trans.color = DEFAULTCOLOR;
      }
      return trans;
    });

    setTransformer(newTransformer);

    const newUps = ups.map((up) => {
      if (transformerEffectedIds.includes(up.transformer_id)) {
        upsEffectedIds.push(up.id);
        up.color = HIGHLIGHTEDCOLOR;
      } else {
        up.color = DEFAULTCOLOR;
      }
      return up;
    });

    setUps(newUps);

    const newRpps = rpps.map((rps) => {
      if (upsEffectedIds.includes(rps.ups_id)) {
        rpsEffectedIds.push(rps.id);
        rps.color = HIGHLIGHTEDCOLOR;
      } else {
        rps.color = DEFAULTCOLOR;
      }
      return rps;
    });

    setRpp(newRpps);
    const newBreaker = breaker.map((breaks) => {
      if (rpsEffectedIds.includes(breaks.upstream_device_id)) {
        breaks.color = HIGHLIGHTEDCOLOR;
      } else {
        breaks.color = DEFAULTCOLOR;
      }
      return breaks;
    });

    setBreaker(newBreaker);

    const data = state.map((s) => {
      if (s.id === obj.generator_id) {
        s.color = HIGHLIGHTEDCOLOR;
      } else {
        s.color = DEFAULTCOLOR;
      }
      return s;
    });

    setState(data);

    setTransformerEffectedIds(transformerEffectedIds);
    setUpsEffectedIds(upsEffectedIds);
    setRpsEffectedIds(rpsEffectedIds);
  };

  const clearToDefault = () => {
    const newState = state.map((s) => {
      s.color = DEFAULTCOLOR;
      return s;
    });

    const newTransformer = transformer.map((s) => {
      s.color = DEFAULTCOLOR;
      return s;
    });

    const newUps = ups.map((s) => {
      s.color = DEFAULTCOLOR;
      return s;
    });
    const newRpps = rpps.map((s) => {
      s.color = DEFAULTCOLOR;
      return s;
    });
    const newBreaker = breaker.map((s) => {
      s.color = DEFAULTCOLOR;
      return s;
    });

    setState(newState);
    setTransformer(newTransformer);
    setUps(ups);
    setRpp(newRpps);
    setBreaker(newBreaker);
    setTransformerEffectedIds([]);
    setUpsEffectedIds([]);
    setRpsEffectedIds([]);
    setGeneratorId(0);
  };

  return (
    <React.Fragment>
      <div className="row my-3">
        <div className="col-12 col-sm-12 col-lg-12">
          <div className="form-check d-flex align-items-center">
            <input
              className="form-check-input"
              type="checkbox"
              defaultChecked={showAllChecked}
              onClick={() => showAll()}
              name="flexRadioDefault"
              id="flexRadioDefault1"
            />
            <label
              className="form-check-label"
              htmlFor="flexRadioDefault1"
              style={{ marginBottom: "0px" }}
            >
              {t(
                "pwrdist.select_all_or_click_on_device_below_to_view_up_and_downstream_components"
              )}
             
            </label>
          </div>
        </div>
      </div>
      <div className="pic_flex_box">
        <div className="row">
          <div
            className="col-12 col-sm-12 col-md-2"
            style={{ width: "19.666667%" }}
          >
            <div className="overview_box">
              <img src="/images/generator.png" alt="a" />
              <div className="overview_div" ref={generatorRef} >
                {state &&
                  state.map((generator) => {
                    return (
                      <p
                        style={{ color: generator.color, cursor: "pointer" }}
                        onClick={() => reRender(generator.id)}
                        key={generator.id}
                      >
                        {generator.name || ""}
                      </p>
                    );
                  })}
                
              </div>
            </div>
          </div>
          <div
            className="col-12 col-sm-12 col-md-2"
            style={{ width: "19.666667%" }}
          >
            <div className="overview_box">
              <img src="/images/transformer.png" alt="a" />
              <div className="overview_div" ref={transformerRef}>
                {transformer &&
                  transformer.map((trans) => {
                    return (
                      <p
                        onClick={() => onTransformerClick(trans)}
                        style={{
                          color: trans?.color || DEFAULTCOLOR,
                          cursor: "pointer",
                        }}
                        key={trans.id}
                      >
                        {trans.name || ""}
                      </p>
                    );
                  })}
                
              </div>
            </div>
          </div>
          <div
            className="col-12 col-sm-12 col-md-2"
            style={{ width: "19.666667%" }}
          >
            <div className="overview_box">
              <img src="/images/ups.png" alt="a" />
              <div className="overview_div" ref={upsRef} >
                {ups &&
                  ups.map((ups) => {
                    return (
                      <p
                        onClick={() => onUpsClick(ups)}
                        style={{
                          color: ups?.color || DEFAULTCOLOR,
                          cursor: "pointer",
                        }}
                        key={ups.id}
                      >
                        {ups.name || ""}
                      </p>
                    );
                  })}
               
              </div>
            </div>
          </div>

          <div
            className="col-12 col-sm-12 col-md-2"
            style={{ width: "19.666667%" }}
          >
            <div className="overview_box">
              <img src="/images/rpp.png" alt="a" />
              <div className="overview_div" ref={rppsRef} >
                {rpps &&
                  rpps.map((rpp) => {
                    return (
                      <p
                        onClick={() => onRppClick(rpp)}
                        style={{
                          color: rpp?.color || DEFAULTCOLOR,
                          cursor: "pointer",
                        }}
                        key={rpp.id}
                      >
                        {rpp.name || ""}
                      </p>
                    );
                  })}
               
              </div>
            </div>
          </div>

          <div
            className="col-12 col-sm-12 col-md-2"
            style={{ width: "19.666667%" }}
          >
            <div className="overview_box">
              <img src="/images/breaker.png" alt="a" />
              <div className="overview_div" ref={breaksRef}>
                {breaker &&
                  breaker.map((br) => {
                    return (
                      <p
                        onClick={() => onBreakerClick(br)}
                        style={{
                          color: br?.color || DEFAULTCOLOR,
                          cursor: "pointer",
                        }}
                        key={br.id}
                      >
                        {br.name || ""}
                      </p>
                    );
                  })}
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Overview;
