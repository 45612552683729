// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `      @media (min-width: 992px){
.modal-dialog.modal-lg.rack_eve {
    max-width: 29rem;
}
}
.modal-dialog.modal-lg.rack_eve .modal-body {
    padding: 0rem 1.125rem 2.5rem 1.125rem !important;
}
.modal-dialog.modal-lg.rack_eve .modal-header{
	padding: 0rem 1.125rem 0rem 1.125rem !important;
}
.modal-dialog.modal-lg.rack_eve .addrack {
    border-radius: 0;
    margin: 2em 0px;
    padding: 10px 0rem;
    font-size: 0.813rem!important;
    font-weight: 600;
    color: #1780df;
}
.modal-dialog.modal-lg.rack_eve .upload {
    background: #EBEBEB;
    padding: 2rem 1rem;
    text-align: center;
    cursor: pointer;
    border-radius: 4px;
}
.upload label{
	margin-bottom: 0px;
	font-size: 0.938rem;
	font-weight: 600;
	cursor: pointer;
}
.ars h5 {
    font-size: 1.25rem;
    font-weight: 600;
    color: #171C3C;
}
.ars p {
    font-size: 1rem;
    font-weight: 500;
    color: #171C3C;
}
.modal-dialog.modal-lg.rack_eve button.btn.btn-danger{
	border-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./src/views/super-admin/Setting/Network/modals/style.css"],"names":[],"mappings":"MAAM;AACN;IACI,gBAAgB;AACpB;AACA;AACA;IACI,iDAAiD;AACrD;AACA;CACC,+CAA+C;AAChD;AACA;IACI,gBAAgB;IAChB,eAAe;IACf,kBAAkB;IAClB,6BAA6B;IAC7B,gBAAgB;IAChB,cAAc;AAClB;AACA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,kBAAkB;AACtB;AACA;CACC,kBAAkB;CAClB,mBAAmB;CACnB,gBAAgB;CAChB,eAAe;AAChB;AACA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;AAClB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;AACA;CACC,kBAAkB;AACnB","sourcesContent":["      @media (min-width: 992px){\n.modal-dialog.modal-lg.rack_eve {\n    max-width: 29rem;\n}\n}\n.modal-dialog.modal-lg.rack_eve .modal-body {\n    padding: 0rem 1.125rem 2.5rem 1.125rem !important;\n}\n.modal-dialog.modal-lg.rack_eve .modal-header{\n\tpadding: 0rem 1.125rem 0rem 1.125rem !important;\n}\n.modal-dialog.modal-lg.rack_eve .addrack {\n    border-radius: 0;\n    margin: 2em 0px;\n    padding: 10px 0rem;\n    font-size: 0.813rem!important;\n    font-weight: 600;\n    color: #1780df;\n}\n.modal-dialog.modal-lg.rack_eve .upload {\n    background: #EBEBEB;\n    padding: 2rem 1rem;\n    text-align: center;\n    cursor: pointer;\n    border-radius: 4px;\n}\n.upload label{\n\tmargin-bottom: 0px;\n\tfont-size: 0.938rem;\n\tfont-weight: 600;\n\tcursor: pointer;\n}\n.ars h5 {\n    font-size: 1.25rem;\n    font-weight: 600;\n    color: #171C3C;\n}\n.ars p {\n    font-size: 1rem;\n    font-weight: 500;\n    color: #171C3C;\n}\n.modal-dialog.modal-lg.rack_eve button.btn.btn-danger{\n\tborder-radius: 4px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
