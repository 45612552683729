import Loading from "views/super-admin/pre-loader/loader";
import Layout from "../../../Layouts";
import Navigation from "../../Component/Navigation";
import DataCenterNav from "../Navigation";
import { useTranslation } from "react-i18next";
import React, { useContext, useEffect, useRef, useState } from "react";
import AuthContext from "context";
import RoleModel from "services/roleServices";
import "./bcm.css";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import BcmExcelUploadModal from "./excelUploadModal";
import bcmService from "services/bcmService";
import moment from "moment";
import Swal from "sweetalert2";

const XLSX = require("xlsx");

const BCM = () => {
  const authContext = useContext(AuthContext);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [dataCenter, setDataCenter] = useState([]);
  const [currentDataCenter, setCurrentDataCenter] = useState([]);
  const [countries, setCountries] = useState([]);
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [countryName, setCountryName] = useState("Country");
  const initialMount = useRef(true);
  const [showExcelUploadModal, setShowExcelUploadModal] = useState(false);
  const [bcmLogs, setBcmLogs] = useState([]);
  const [totalBreakersCount, setTotalBreakersCount] = useState(0);
  const [totalBreakersCurrentCount, setTotalBreakersCurrentCount] = useState(0);
  const [totalBreakersPowerCount, setTotalBreakersPowerCount] = useState(0);
  const [totalBreakersEnergyCount, setTotalBreakersEnergyCount] = useState(0);
  const [lastRefreshTime, setLastRefreshTime] = useState(null);
  const contextStore = useContext(AuthContext);

  useEffect(() => {
    if (initialMount.current === true) {
      initialMount.current = false;
      getData();
      getAllDataCenter();
    }
  }, [authContext.getNetworkDataCenter, triggerRefresh]);

  useEffect(() => {
    setCurrentDataCenter(authContext.getNetworkDataCenter);
  }, [authContext.getNetworkDataCenter]);
  useEffect(() => {
    if (currentDataCenter?.name) {
      getBcmLogs();
    }
  }, [currentDataCenter]);

  useEffect(() => {
    getCount();
  }, [bcmLogs]);

  const getData = async () => {
    await RoleModel.countryService(authContext.token()).then((res) => {
      setCountries(res.data.data);
      setIsLoading(false);
    });
  };

  const getBcmLogs = async () => {
    setIsLoading(true);
    await bcmService
      .getBcmLogs(authContext.token(), currentDataCenter.name)
      .then((res) => {
        setBcmLogs(res.data.data);
        setLastRefreshTime(res.data.last_refresh?.last_refresh);
        setIsLoading(false);
      });
  };
  const getAllDataCenter = async () => {
    setCountryName("Country");
    await RoleModel.dataCenter(authContext.token()).then(async (res) => {
      const newDataCenter = res.data.data.filter(
        (data) => data.dc_type_id === 1
      );

      setDataCenter(newDataCenter);
      // alert(authContext.getNetworkDataCenter.id)
      // if (
      //   authContext.getNetworkDataCenter === undefined ||
      //   authContext.getNetworkDataCenter.length === 0
      // ) {
      authContext.setNetworkDataCenter(newDataCenter[0]);
      selectDataCenterFloor(newDataCenter[0]);
      // }
      // if (res.data.data.length > 0) {
      //   if (initialMount.current) {
      //     selectDataCenterFloor(authContext.getNetworkDataCenter);
      //     initialMount.current = false;
      //   }
      // }
      // if (newDataCenter.length) {
      //   setCurrentDataCenter(
      //     authContext.getNetworkDataCenter.id === undefined
      //       ? newDataCenter[0]
      //       : authContext.getNetworkDataCenter
      //   );
      // }
    });
  };
  const selectDataCenterFloor = async (e) => {
    console.log(e);
    authContext.setNetworkDataCenter(e);
    setCurrentDataCenter(e);
  };
  const getDataCenterById = async (e) => {
    setIsLoading(true);
    setCountryName(e.name);

    await RoleModel.dataCenterByCountryId(authContext.token(), e).then(
      (res) => {
        setDataCenter(res.data.data);
        setIsLoading(false);
        if (res.data.data.length > 0) {
          selectDataCenterFloor(res.data.data[0]);
        }
      }
    );
  };

  const convertDate = (date) => {
    if (
      contextStore?.getAuth &&
      contextStore?.getAuth?.timezone &&
      contextStore?.getAuth?.timezone?.timezone &&
      contextStore?.getAuth?.timezone?.timezone_prefix
    ) {
      const hongKongTime = moment.tz(date, "Asia/Hong_Kong");
      return (
        hongKongTime
          .clone()
          .tz(contextStore?.getAuth?.timezone?.timezone)
          .format("YYYY-MM-DD HH:mm") +
        ` ${contextStore?.getAuth?.timezone?.timezone_prefix}`
      );
    } else {
      return (
        moment(date).tz("Asia/Hong_Kong").format("YYYY-MM-DD HH:mm") + `HKT`
      );
    }
  };
  const renderCountry = () => {
    return (
      countries.length &&
      countries.map((data, i) => {
        return (
          <a
            href="#sae"
            className="dropdown-item"
            key={i}
            onClick={() => {
              getDataCenterById(data);
            }}
          >
            {data.name}{" "}
          </a>
        );
      })
    );
  };

  const handleDownload = (fileName) => {
    Swal.fire({
      text: "Are you sure to download this file?",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Download",
      customClass: {
        cancelButton: "cancelBtn",
        confirmButton: "confirmBtn",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        downloadFile(fileName);
      }
    });
  };

  const downloadFile = async (fileName) => {
    try {
      setIsLoading(true);
      await bcmService
        .downloadBcmMappingFile(authContext.token(), fileName)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName + ".xlsx");
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          setIsLoading(false);
        });
    } catch (error) {
      setIsLoading(false);
      console.error("Error downloading file:", error);
    }
  };

  const getCount = () => {
    if (bcmLogs.length > 0) {
      setTotalBreakersCount(bcmLogs[bcmLogs.length - 1].breakers_count);
      setTotalBreakersCurrentCount(
        bcmLogs[bcmLogs.length - 1].breakers_current_count
      );
      setTotalBreakersPowerCount(
        bcmLogs[bcmLogs.length - 1].breakers_power_count
      );
      setTotalBreakersEnergyCount(
        bcmLogs[bcmLogs.length - 1].breakers_energy_count
      );
    } else {
      setTotalBreakersCount(0);
      setTotalBreakersCurrentCount(0);
      setTotalBreakersPowerCount(0);
      setTotalBreakersEnergyCount(0);
    }
  };
  const renderHtml = () => {
    return (
      <>
        {bcmLogs &&
          bcmLogs.length > 0 &&
          bcmLogs.map((data) => {
            return (
              <tr>
                <td>
                  <FileDownloadIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleDownload(data.file_path);
                    }}
                  ></FileDownloadIcon>
                </td>
                <td>{convertDate(data.uploaded_at)}</td>
                <td>{JSON.parse(data.uploaded_by).name}</td>
                <td>{data.breakers_count}</td>
                <td>{data.breakers_current_count}</td>
                <td>{data.breakers_energy_count}</td>
                <td>{data.breakers_power_count}</td>
              </tr>
            );
          })}
      </>
    );
  };
  return (
    <>
      <Loading
        loading={isLoading}
        background="rgba(0, 0, 0, 0.40)"
        loaderColor="rgb(248, 153, 3)"
      />
      {showExcelUploadModal && (
        <BcmExcelUploadModal
          setShowExcelUploadModal={setShowExcelUploadModal}
          dataCenter={currentDataCenter.name}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          refresh={getBcmLogs}
        />
      )}
      <Layout
        setTriggerRefresh={setTriggerRefresh}
        triggerRefresh={triggerRefresh}
      >
        <div className="content-body">
          <Navigation />
          <hr className="main" />
          <div className="container-fluid pt-0">
            <div className="row">
              <DataCenterNav />
              <div className="col-lg-11">
                <div id="pro">
                  <div className="profile-tab">
                    <div className="custom-tab-1">
                      <div className="main_scrll">
                        <div className="btn_ul">
                          <ul className="nav nav-tabs mb-3">
                            <li className="nav-item">
                              {" "}
                              <button
                                className="btn btn-secondary"
                                style={{
                                  width: "100%",
                                  background:
                                    countryName != "Country"
                                      ? "#EAECF2"
                                      : "#11263C",
                                  color:
                                    countryName != "Country"
                                      ? "#000000"
                                      : "#ffffff",
                                  border:
                                    countryName != "Country" ? "none" : "",
                                }}
                                onClick={getAllDataCenter}
                              >
                                {" "}
                                {t("capacity.global")}{" "}
                              </button>
                            </li>
                            <li className="nav-item">
                              <div className="btn-group" role="group">
                                <button
                                  type="button"
                                  className="btn btn-light dropdown-toggle"
                                  style={{
                                    width: "100%",
                                    background:
                                      countryName == "Country"
                                        ? "#EAECF2"
                                        : "#11263C",
                                    color:
                                      countryName == "Country"
                                        ? "#000000"
                                        : "#ffffff",
                                    border:
                                      countryName == "Country" ? "none" : "",
                                  }}
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  {" "}
                                  {countryName}{" "}
                                </button>
                                <div
                                  className="dropdown-menu"
                                  style={{ margin: "0px" }}
                                >
                                  {renderCountry()}
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="outer-div-setting">
                          <div className="ul_scrll">
                            <ul className="nav nav-tabs custom-scroll-gap">
                              {dataCenter &&
                                dataCenter.map((data, index) => {
                                  if (
                                    authContext.getNetworkDataCenter &&
                                    authContext.getNetworkDataCenter.id ===
                                      data.id
                                  ) {
                                    return (
                                      <li
                                        className={
                                          index === 0 ? "nav-item" : "nav-item"
                                        }
                                        key={index}
                                      >
                                        <a
                                          href="#sd"
                                          onClick={() =>
                                            selectDataCenterFloor(data)
                                          }
                                          style={{ cursor: "pointer" }}
                                          className="nav-link active show"
                                        >
                                          <img
                                            className="down setting_down"
                                            alt="arrow"
                                            src="\images\downward-arrow.png"
                                          />
                                          {data.name}
                                        </a>
                                      </li>
                                    );
                                  } else {
                                    return (
                                      <li
                                        className={
                                          index === 0 ? "nav-item" : "nav-item"
                                        }
                                        key={index}
                                      >
                                        <a
                                          href="#sd"
                                          onClick={() =>
                                            selectDataCenterFloor(data)
                                          }
                                          style={{ cursor: "pointer" }}
                                          className="nav-link"
                                        >
                                          {" "}
                                          {data.name}{" "}
                                        </a>
                                      </li>
                                    );
                                  }
                                })}
                            </ul>
                          </div>
                          <div>
                            <span>
                              <i>
                                {t(
                                  "capacity.select_a_country_to_view_partner_data_center"
                                )}
                              </i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="title">
                    <div className="row">
                      <div className="col-md-4">
                        <h5 className="card-title">
                          {" "}
                          {t("datacenter.bcm_mapping_for")}{" "}
                          {currentDataCenter.name}{" "}
                        </h5>
                        {lastRefreshTime && bcmLogs && bcmLogs.length > 0 && (
                          <h7>Latest update: {convertDate(lastRefreshTime)}</h7>
                        )}
                      </div>
                      <div className="col-md-6">
                        <button
                          style={{
                            whiteSpace: "nowrap",
                            marginRight: "10px",
                            padding: "0 10px 0 10px",
                            color: "#fff",
                            backgroundColor: "#b86d45",
                          }}
                          type="button"
                          onClick={() => {
                            setShowExcelUploadModal(true);
                          }}
                          className="btn btn-outline-primary"
                        >
                          {" "}
                          {t("capacity.excel_upload")}{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div
                      className="user_cards mt-2"
                      style={{ marginBottom: "15px" }}
                    >
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-3">
                          <div className="bcm_total">
                            <h4>Total</h4>
                            <p>{totalBreakersCount}</p>
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-3">
                          <div className="bcm_total_other">
                            <h4>Total-Current</h4>
                            <p>{totalBreakersCurrentCount}</p>
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-3">
                          <div className="bcm_total_other">
                            <h4>Total-Power</h4>
                            <p>{totalBreakersPowerCount}</p>
                          </div>
                        </div>
                        <div className="col-12 col-sm-12 col-md-3">
                          <div className="bcm_total_other">
                            <h4>Total-Energy</h4>
                            <p>{totalBreakersEnergyCount}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card">
                    <div className="card-body" style={{ overflow: "auto" }}>
                      <div className="table-responsive">
                        <table className="table table-responsive-md">
                          <thead>
                            <tr>
                              <th>
                                <strong> Action </strong>
                              </th>
                              <th>
                                <strong> Upload Date </strong>
                              </th>{" "}
                              <th>
                                <strong> Uploaded By </strong>
                              </th>{" "}
                              <th>
                                <strong> # of Records</strong>
                              </th>{" "}
                              <th>
                                <strong> # of Breakers Current</strong>
                              </th>{" "}
                              <th>
                                <strong> # of Breakers Power</strong>
                              </th>{" "}
                              <th>
                                <strong> # of Breakers Energy</strong>
                              </th>{" "}
                            </tr>
                          </thead>
                          <tbody>{renderHtml()}</tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default BCM;
