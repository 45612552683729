import { css } from 'glamor'
import { useState } from 'react'
import Accordion from 'react-bootstrap/Accordion'
import { useAccordionButton } from 'react-bootstrap/AccordionButton'
import { TICKET_BLUE_COLOR, TICKET_DARK_GREY_COLOR } from '../constants'
import ArrowDownIcon from './ArrowDownIcon'
import ArrowUpIcon from './ArrowUpIcon'
import './TicketAccordion.scss'

function TicketAccordion ({ label, active, valid, children, customHeader }) {
  const [collapsed, setCollapsed] = useState(false)
  const eventKey = 0
  const onClickCallback = (key) => {
    if (active) {
      setCollapsed(!collapsed)
    }
  }
  const decoratedOnClick = useAccordionButton(
    eventKey,
    () => onClickCallback && onClickCallback(eventKey)
  )

  const styles = {
    header: {
      position: 'relative',
      borderRadius: '2px !important',
      width: '100%',
      '> button': {
        backgroundColor: valid ? TICKET_BLUE_COLOR : TICKET_DARK_GREY_COLOR
      },
      '> button:not(.collapsed)': {
        backgroundColor: valid ? TICKET_BLUE_COLOR : TICKET_DARK_GREY_COLOR
      }
    },
    body: {
      backgroundColor: '#fff'
    },
    icon: {
      position: 'relative',
      left: '75%'
    },
    label: {
      width: '200px',
      letterSpacing: '1px'
    },
    inactiveHeader: {
      paddingLeft: '0px !important',
      paddingRight: '0px !important',
      paddingTop: '0px !important',
      '> button': {
        borderRadius: '0.75rem',
        fontSize: '16px',
        fontWeight: '700',
        color: '#fff'
      },
      '> button:after': {
        display: 'none'
      }
    }
  }

  const inactiveElement = () => {
    return (
      <div className='create-ticket-accordion-inactive'>
        <Accordion.Header
          as='div'
          {...css(styles.header, styles.inactiveHeader)} onClick={(e) => {
          }}
        >
          {!customHeader && (
            <>
              <div {...css(styles.label)}>{label}</div>
              <div {...css(styles.icon)}>
                <ArrowDownIcon />
              </div>
            </>
          )}
          {customHeader && (
            <>
              {customHeader()}
            </>
          )}

        </Accordion.Header>
      </div>

    )
  }
  return (
    <>
      {active && (
        <Accordion defaultActiveKey='0' className='create-ticket-accordion'>
          <Accordion.Item eventKey='0'>
            <Accordion.Header
              as='div' {...css(styles.header)} onClick={(e) => {
                decoratedOnClick()
              }}
            >
              {!customHeader && (
                <>
                  <div {...css(styles.label)}>{label}</div>
                  <div {...css(styles.icon)}>
                    {collapsed ? (<ArrowDownIcon />) : (<ArrowUpIcon />)}
                  </div>
                </>
              )}
              {customHeader && (
                <>
                  {customHeader()}
                </>
              )}
            </Accordion.Header>
            <Accordion.Body {...css(styles.body)}>
              {children}
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      )}
      {!active && inactiveElement()}
    </>

  )
}

export default TicketAccordion
