import moment from 'moment';
import { useState } from 'react';
import { Form } from 'react-bootstrap';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import './TicketDateTime.scss';

const getClass = (mode) => {
  return `ticket-date-time-mode-${mode}`;
};

const TicketDateTime = ({
  id,
  value,
  active,
  setFieldValue,
  isTopPlacement,
  placeholder,
  compareDate,
  hoursConstraint
}) => {
  const [pickerMode, setPickerMode] = useState(null);
  const renderView = (mode, renderDefault) => {
    setPickerMode(mode);
    return <div className="wrapper">{renderDefault()}</div>;
  };

  return (
    // <>
    //   {active
    //     ? (
    //       <Datetime
    //         onChange={(value) => {
    //           if (value.format) {
    //             setFieldValue(id, value.format())
    //           }
    //         }}
    //         className={isTopPlacement && getClass(pickerMode)}
    //         value={moment(value)}
    //         timeFormat='HH:mm'
    //         dateFormat='DD-MM-YY'
    //         inputProps={{
    //           style: { color: 'black' },
    //           placeholder,
    //           disabled: !active
    //         }}
    //         timeConstraints={{
    //           hours: hoursConstraint,
    //           minutes: { step: 10 }
    //         }}
    //         isValidDate={(current) => {
    //           const today = moment(new Date()).subtract(1, 'day')
    //           if (compareDate) {
    //             return current.isAfter(today) && current.isAfter(moment(compareDate).subtract(1, 'day'))
    //           }
    //           return current.isAfter(today)
    //         }}
    //         renderView={(mode, renderDefault) => renderView(mode, renderDefault)}
    //       />
    //       )
    //     : (
    //       <Form.Group className='d-flex flex-row align-items-center'>
    //         <Form.Control type='email' placeholder={placeholder} disabled />
    //         <div style={{ position: 'relative' }}>
    //           <i className='fas fa-calendar' style={{ position: 'relative', right: '20px', top: '2px' }} />
    //         </div>
    //       </Form.Group>
    //       )}

    // </>
    <>
      <div style={{ position: 'relative' }}>
        <Datetime
          onChange={(value) => {
            if (value.format) {
              setFieldValue(id, value.format());
            }
          }}
          className={isTopPlacement && getClass(pickerMode)}
          value={moment(value)}
          timeFormat="HH:mm"
          dateFormat="DD-MM-YY"
          inputProps={{
            style: { color: 'black' },
            placeholder,
            disabled: !active
          }}
          timeConstraints={{
            hours: hoursConstraint,
            minutes: { step: 10 }
          }}
          isValidDate={(current) => {
            if (compareDate) {
              return (
                 current.isAfter(moment(compareDate).subtract(1, 'day'))
              );
            }
            return current;
          }}
          renderView={(mode, renderDefault) => renderView(mode, renderDefault)}
        />
        <i className="fas fa-calendar" style={{ position: 'absolute', right: '10%', top: '35%' }} />
      </div>
    </>
  );
};

export default TicketDateTime;
