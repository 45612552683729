import { React, useState } from 'react';

const AddLineSiteAccess = ({ setToggleLine, setUpdateTicketDetails, setState }) => {
  const [values, setValues] = useState({
    name: '',
    contact_number: '',
    identification_number: '',
    cargo_use: false,
    visitor_company_name: '',
    vehicle_info: '',
    email: ''
  });
  const saveClose = () => {
    setUpdateTicketDetails((prev) => ({
      ...prev,
      site_access_details: [...prev.site_access_details, { ...values }]
    }));
    setState((prev) => ({
      ...prev,
      visitors: [...prev.visitors, { ...values }]
    }));
    setValues({
      name: '',
      contact_number: '',
      identification_number: '',
      cargo_use: false,
      visitor_company_name: '',
      vehicle_info: '',
      email: ''
    });
    setToggleLine(false);
  };
  const saveNew = () => {
    setUpdateTicketDetails((prev) => ({
      ...prev,
      site_access_details: [...prev.site_access_details, { ...values }]
    }));
    setState((prev) => ({
      ...prev,
      visitors: [...prev.visitors, { ...values }]
    }));
    setValues({
      name: '',
      contact_number: '',
      identification_number: '',
      cargo_use: false,
      visitor_company_name: '',
      vehicle_info: '',
      email: ''
    });
  };

  const discrad = () => {
    setValues({
      name: '',
      contact_number: '',
      identification_number: '',
      cargo_use: false,
      visitor_company_name: '',
      vehicle_info: '',
      email: ''
    });
    setToggleLine(false);
  };
  return (
    <div className="timer-pop">
      <div className="main-body1">
        <div>
          <div className="top-cts">
            <div>Create Site Access Detail</div>
            <div className="close" onClick={() => setToggleLine(false)}>
              X
            </div>
          </div>
          <div className="center-cts">
            <div className="va-details">
              <div className="va-left">
                <div className="va-inner">
                  <div className="text text-1">Visitor Name</div>
                  <div>
                    <input
                      type="text"
                      value={values.name}
                      onChange={(e) => setValues((prev) => ({ ...prev, name: e.target.value }))}
                    />
                  </div>
                </div>
                <div className="va-inner">
                  <div className="text text-1">Visitor Id Number</div>
                  <div>
                    <input
                      type="text"
                      value={values.identification_number}
                      onChange={(e) =>
                        setValues((prev) => ({ ...prev, identification_number: e.target.value }))
                      }
                    />
                  </div>
                </div>

                <div className="va-inner">
                  <div>
                    <div className="text text-1">Contact Number</div>
                  </div>

                  <div>
                    <input
                      type="text"
                      value={values.contact_number}
                      onChange={(e) =>
                        setValues((prev) => ({ ...prev, contact_number: e.target.value }))
                      }
                    />
                  </div>
                </div>
                <div className="va-inner">
                  <div className="text text-1">Cargo E/V Use</div>
                  <div>
                    <input
                      type="checkbox"
                      checked={values.cargo_use}
                      onChange={() =>
                        setValues((prev) => ({ ...prev, cargo_use: !prev.cargo_use }))
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="va-left">
                <div className="va-inner">
                  <div className="text text-1">Vehical Info(Plate No.)</div>
                  <div>
                    <input
                      type="text"
                      value={values.vehicle_info}
                      onChange={(e) =>
                        setValues((prev) => ({ ...prev, vehicle_info: e.target.value }))
                      }
                    />
                  </div>
                </div>
                <div className="va-inner">
                  <div className="text text-1">Visitor Company Name </div>
                  <div>
                    <input
                      type="text"
                      value={values.visitor_company_name}
                      onChange={(e) =>
                        setValues((prev) => ({ ...prev, visitor_company_name: e.target.value }))
                      }
                    />
                  </div>
                </div>

                <div className="va-inner">
                  <div className="text text-1">Visitor Email Address</div>
                  <div>
                    <input
                      type="email"
                      value={values.email}
                      onChange={(e) => setValues((prev) => ({ ...prev, email: e.target.value }))}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bottom-cts">
            <div>
              <button className="Save-btn1" onClick={() => saveClose()}>
                SAVE & CLOSE
              </button>
            </div>
            <div>
              <button className="Save-btn1" onClick={() => saveNew()}>
                SAVE & NEW
              </button>
            </div>
            <div>
              <button className="cancel-btn1" onClick={() => discrad()}>
                DISCARD
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddLineSiteAccess;
