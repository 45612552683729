import React, { useContext, useEffect, useState } from "react";
import AuthContext from "context";
import Layout from "../dashboard-layout";
import Loading from "../pre-loader/loader";
import provisioningService from "services/provisioningService";
import { useTranslation } from "react-i18next";
// import Card from '../Ticket/components/card';
// import { cardData } from '../Dashboard/data';
import Table from "./components/table";
import ProvisioningPop from "./components/provisioningPop";
import Swal from "sweetalert2";
import Card from "./components/card";
import { cardData } from "./components/data";
import { LogoutOnSessionExpire } from "common/helpers";
import ErrorModel from "../Ticket/components/errormodel";
import SerachBox from "./components/searchBox";
const Provisioning = () => {
  const [selectedDataCenter, setSelectedDataCenter] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [currentServiceId, setCurrentServiceId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [state, setState] = useState([]);
  const [provisioningList, setProvisioningList] = useState([]);
  const [ticketData, setticketData] = useState({});
  const [provision, setProvision] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [menu, setMenu] = useState(0);
  const [category, setCategory] = useState("installed_base");
  const [subCategory, setSubCategory] = useState("colocation");
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [total, setTotal] = useState(0);
  const contextStore = useContext(AuthContext);
  const { t } = useTranslation();
  const [errorMsg, setErrorMsg] = useState("Something went Wrong !");
  const [errorpopup, setErrorPopup] = useState(false);
  const [statusList, setStatusList] = useState([]);
  const colocationProductType = [
    {
      name: "All",
      value: "",
    },
    {
      name: "Cabinets",
      value: "CAB",
    },
    {
      name: "Power",
      value: "POW",
    },
    {
      name: "Cages",
      value: "CAG",
    },
    {
      name: "Offices",
      value: "OFC",
    },
  ];
  const interconnectProductType = [
    {
      name: "All",
      value: "",
    },
    {
      name: "Cross Connect",
      value: "CCX",
    },
    {
      name: "Epix",
      value: "IXP",
    },
    {
      name: "Cross Link",
      value: "CCL",
    },
    {
      name: "Direct Link",
      value: "NMS",
    },
    {
      name: "IP Transit",
      value: "MCS601.RC",
    },
  ];
  const status = [
    {
      name: "Open",
      value: "is_open",
    },
    {
      name: "Closed",
      value: "is_closed",
    },
    {
      name: "All",
      value: "",
    },
  ];
  const [productType, setProductType] = useState(colocationProductType);
  const [selectedProduct, setSelectedProductType] = useState(
    colocationProductType[0]
  );
  const [selectedStatus, setSelectedStatus] = useState(status[0]);
  const [query, setQuery] = useState("");
  const [permissions, setPermissions] = useState([]);

  const searchCategory = [
    { name: t(`provisioning.table.title`), field: "name" },
    { name: t(`provisioning.table.customer`), field: "customer" },
    { name: t(`provisioning.table.end_customer`), field: "end_customer" },
    {
      name: t(`provisioning.table.sales_order_item`),
      field: "sale_order_item",
    },
    { name: t(`provisioning.table.product`), field: "product" },
    {
      name: t(`provisioning.table.service_request_date`),
      field: "service_request_date",
    },
    {
      name: t(`provisioning.table.service_commencement_date`),
      field: "service_commencement_date",
    },
    { name: t(`provisioning.table.site`), field: "site" },
    { name: t(`provisioning.table.space_id`), field: "space" },
    {
      name: t(`provisioning.table.related_space_service`),
      field: "related_space_id",
    },
    // { name: t(`provisioning.table.remarks`), field: 'remarks' },
    // { name: t(`provisioning.table.description`), field: 'description' },
    { name: t(`provisioning.table.ban`), field: "ban" },
    {
      name: t(`provisioning.table.third_party_circuit`),
      field: "third_party_circuit",
    },
    { name: t(`provisioning.table.parent_task`), field: "parent_task" },
    {
      name: t(`provisioning.table.customer_service_reference`),
      field: "customer_service_reference",
    },
    {
      name: t(`provisioning.table.legacy_ib_number`),
      field: "legacy_ib_number",
    },
    {
      name: t(`provisioning.table.a_side_service_id`),
      field: "a_side_space_id",
    },
    {
      name: t(`provisioning.table.z_side_service_id`),
      field: "z_side_space_id",
    },
    { name: t(`provisioning.table.assignee`), field: "assignee" },
    { name: t(`provisioning.table.status`), field: "status" },
    { name: t(`provisioning.table.task_type`), field: "task_type" },
  ];

  const columns = [
    {
      name: "name",
      value: t(`provisioning.table.title`),
    },
    {
      name: "customer",
      value: t(`provisioning.table.customer`),
    },
    {
      name: "end_customer",
      value: t(`provisioning.table.end_customer`),
    },
    {
      name: "sale_order_item",
      value: t(`provisioning.table.sales_order_item`),
    },
    {
      name: "product",
      value: t(`provisioning.table.product`),
    },
    {
      name: "service_request_date",
      value: t(`provisioning.table.service_request_date`),
    },
    {
      name: "service_commencement_date",
      value: t(`provisioning.table.service_commencement_date`),
    },
    {
      name: "site",
      value: t(`provisioning.table.site`),
    },
    {
      name: "space",
      value: t(`provisioning.table.space_id`),
    },
    {
      name: "related_space_id",
      value: t(`provisioning.table.related_space_service`),
    },
    {
      name: "remarks",
      value: t(`provisioning.table.remarks`),
    },
    {
      name: "description",
      value: t(`provisioning.table.description`),
    },
    {
      name: "ban",
      value: t(`provisioning.table.ban`),
    },
    {
      name: "third_party_circuit",
      value: t(`provisioning.table.third_party_circuit`),
    },
    { name: "parent_task", value: t(`provisioning.table.parent_task`) },
    {
      name: "customer_service_reference",
      value: t(`provisioning.table.customer_service_reference`),
    },
    {
      name: "legacy_ib_number",
      value: t(`provisioning.table.legacy_ib_number`),
    },
    {
      name: "a_side_space_id",
      value: t(`provisioning.table.a_side_service_id`),
    },
    {
      name: "z_side_space_id",
      value: t(`provisioning.table.z_side_service_id`),
    },
    {
      name: "assignee",
      value: t(`provisioning.table.assignee`),
    },
    {
      name: "status",
      value: t(`provisioning.table.status`),
    },
    {
      name: "task_type",
      value: t(`provisioning.table.task_type`),
    },
  ];

  useEffect(() => {
    if (selectedDataCenter.id) {
      const isPermitted = checkPermission(
        category == "installed_base"
          ? subCategory == "colocation"
            ? 1
            : 2
          : subCategory == "colocation"
          ? 3
          : 4
      );
      if (isPermitted) {
        fetchServices();
      }
    }
  }, [
    selectedDataCenter,
    subCategory,
    category,
    selectedProduct,
    productType,
    pageNumber,
    itemsPerPage,
    selectedStatus,
    query,
  ]);

  useEffect(() => {
    if (category == "installed_base") {
      if (subCategory == "colocation") {
        const isAllowed = checkPermission(1);
        if (!isAllowed) {
          setSubCategory("interconnect");
        }
      }
      if (subCategory == "interconnect") {
        const isAllowed = checkPermission(2);
        if (!isAllowed) {
          setSubCategory("colocation");
        }
      }
    }
    if (category == "provisioning") {
      if (subCategory == "colocation") {
        const isAllowed = checkPermission(3);
        if (!isAllowed) {
          setSubCategory("interconnect");
        }
      }
      if (subCategory == "interconnect") {
        const isAllowed = checkPermission(4);
        if (!isAllowed) {
          setSubCategory("colocation");
        }
      }
    }
  }, [category]);
  useEffect(() => {
    if (selectedDataCenter.id) {
      const isPermitted = checkPermission(
        category == "installed_base"
          ? subCategory == "colocation"
            ? 1
            : 2
          : subCategory == "colocation"
          ? 3
          : 4
      );
      if (isPermitted) {
        fetchServicesChart();
      }
    }
  }, [
    selectedDataCenter,
    subCategory,
    category,
    selectedProduct,
    productType,
    pageNumber,
    itemsPerPage,
    selectedStatus,
  ]);

  useEffect(() => {
    fetchPermissions();
  }, []);

  const transformedData = (array) => {
    const data = array.map((item) => {
      const { roleProvisioningPermissionName, ...rest } = item;
      return { ...rest, name: roleProvisioningPermissionName.name };
    });
    return data;
  };

  const fetchPermissions = async () => {
    await provisioningService
      .getProvisioningPermissions(
        localStorage.getItem("token"),
        contextStore?.getAuth?.role_id
      )
      .then((res) => {
        const data = res.data.provisioningPermission.map((el) => {
          el.roleProvisioningPermission = transformedData(
            el.roleProvisioningPermission
          );
          return el;
        });
        setPermissions(data);
      })
      .catch((err) => {
        LogoutOnSessionExpire(err);
        //500 error page
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const checkPermission = (provisioningType) => {
    return contextStore.getAuth?.role?.role_provisioning.some(
      (item) =>
        item.is_allowed === 1 && item.provisioning_type_id == provisioningType
    );
  };

  const fetchServices = async () => {
    const country = selectedCountry ? selectedCountry.name : "";
    setIsLoading(true);
    let filters = [
      {
        name: "country",
        value: country,
      },
      {
        name: "category",
        value: category,
      },
      {
        name: "Subcategory",
        value: subCategory,
      },
    ];
    if (query) {
      filters.push(query);
    }
    if (selectedDataCenter.id != "all") {
      filters.push({
        name: "site",
        value: selectedDataCenter.name,
      });
    }
    if (selectedProduct.value != "") {
      filters.push({
        name: "product_type",
        value: selectedProduct.value,
      });
    }
    if (selectedStatus.value != "") {
      filters.push({
        name: "status",
        value: selectedStatus.value,
      });
    }
    if (selectedProduct.value == "CCL" || selectedProduct.value == "NMS") {
      const updatedFilter = filters.filter(
        (item) => item.name != "Subcategory"
      );
      filters = updatedFilter;
    }

    const body = {
      page: pageNumber,
      limit: itemsPerPage,
      filters: filters,
    };

    await provisioningService
      .getServices(localStorage.getItem("token"), body)
      .then((res) => {
        if (res?.data?.code !== undefined || res?.data?.error !== undefined) {
          console.log("here");
          setErrorMsg(res.data);
          console.log("here");
          setErrorPopup(true);
          setIsLoading(false);
          //setStatusChange(true)
        } else {
          let servicesData = res.data.data;
          servicesData.rows.map((data) => {
            data.title = menu == 0 ? "IB" : "PP";
            data.assignee =
              data?.assignee === "OPS Portal Bot"
                ? `Ops Portal Bot ${
                    data?.activity_user ? "(" + data?.activity_user + ")" : ""
                  }`
                : data?.assignee;
          });
          setProvisioningList(servicesData);
          setTotal(servicesData.total);
        }
      })
      .catch((err) => {
        LogoutOnSessionExpire(err);
        //500 error page
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const fetchServicesChart = async () => {
    const country = selectedCountry ? selectedCountry.name : "";
    setIsLoading(true);
    const filters = [
      {
        name: "country",
        value: country,
      },
      {
        name: "category",
        value: category,
      },
      {
        name: "Subcategory",
        value: subCategory,
      },
    ];
    if (selectedDataCenter.id != "all") {
      filters.push({
        name: "site",
        value: selectedDataCenter.name,
      });
    }
    const body = {
      filters: filters,
    };

    await provisioningService
      .getServicesChart(localStorage.getItem("token"), body)
      .then((res) => {
        if (res?.data?.code !== undefined || res?.data?.error !== undefined) {
          console.log("here");
          setErrorMsg(res.data);
          console.log("here");
          setErrorPopup(true);
          setIsLoading(false);
          //setStatusChange(true)
        } else {
          console.log("chart data---", res);
          let servicesData = res.data.data;
          setChartData(servicesData);
        }
      })
      .catch((err) => {
        setIsLoading(false);

        // LogoutOnSessionExpire(err);
        //500 error page
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };
  const handlePop = async (data) => {
    setIsLoading(true);
    getPopupDetail(data.id);
  };

  const getPopupDetail = async (tid, isCurrentView) => {
    try {
      let data = await provisioningService.getPopupDetail(
        localStorage.getItem("token"),
        tid
      );
      if (isCurrentView) {
        setCurrentServiceId("");
      }
      getStatus(data?.data?.data?.project_id);
      setticketData(data?.data?.data);
      setProvision(true);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const getStatus = async (projectId) => {
    try {
      await provisioningService
        .getStatus(localStorage.getItem("token"), projectId)
        .then((res) => {
          setStatusList(res?.data?.data);
        });
    } catch (e) {
      setIsLoading(false);
    }
  };
  const closeModal = () => {
    setProvision(false);
  };
  const handleExitbutton = async () => {
    await Swal.fire({
      title: "Are you sure?",
      text: "Any changes made will not be saved.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      customClass: {
        cancelButton: "cancelBtn",
        confirmButton: "confirmBtn",
      },
    }).then((res) => {
      if (res.isConfirmed) {
        closeModal();
      }
    });
  };
  return (
    <React.Fragment>
      <Loading
        loading={isLoading}
        background="rgba(0, 0, 0, 0.4)"
        loaderColor="rgb(248, 153, 3)"
      />
      <Layout
        setSelectedDataCenterForProvisioning={setSelectedDataCenter}
        setSelectedCountryForProvisioning={setSelectedCountry}
      >
        <div className="bg_color_dash">
          <div className="container-fluid pb-5">
            <div className="row pt-0">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 gx-4">
                <div className="row g-2 pt-2">
                  {/* chartData */}
                  <Card
                    cardData={cardData}
                    state={chartData}
                    category={category}
                  />
                </div>
                <div className="row">
                  <div className="invglob">
                    <div className="profile-tab menu_tab_btn">
                      <div className="custom-tab-1">
                        <ul
                          className="nav nav-tabs"
                          style={{
                            flexWrap: "nowrap",
                            overflowX: "auto",
                          }}
                        >
                          <li className="nav-item">
                            <button
                              className={
                                category === "installed_base"
                                  ? "btn btn-secondary"
                                  : "btn btn-light"
                              }
                              onClick={() => {
                                setCategory("installed_base");
                                setProvisioningList([]);
                                setPageNumber(1);
                              }}
                            >
                              {" "}
                              {t("provisioning.install_base")}
                            </button>
                          </li>
                          <li className="nav-item">
                            <button
                              className={
                                category === "provisioning"
                                  ? "btn btn-secondary"
                                  : "btn btn-light"
                              }
                              onClick={() => {
                                setCategory("provisioning");
                                setProvisioningList([]);
                                setPageNumber(1);
                              }}
                            >
                              {" "}
                              {t("provisioning.provision")}
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="invglob">
                    <div className="profile-tab menu_tab_btn">
                      <div className="custom-tab-1">
                        <ul
                          className="nav nav-tabs"
                          style={{
                            flexWrap: "nowrap",
                            overflowX: "auto",
                          }}
                        >
                          {checkPermission(
                            category == "installed_base" ? 1 : 3
                          ) && (
                            <li className="nav-item">
                              <button
                                className={
                                  subCategory === "colocation"
                                    ? "btn btn-secondary"
                                    : "btn btn-light"
                                }
                                onClick={() => {
                                  setSubCategory("colocation");
                                  setProductType(colocationProductType);
                                  setSelectedProductType(
                                    colocationProductType[0]
                                  );
                                  setPageNumber(1);
                                }}
                              >
                                {" "}
                                {t("provisioning.colocation")}
                              </button>
                            </li>
                          )}
                          {checkPermission(
                            category == "installed_base" ? 2 : 4
                          ) && (
                            <li className="nav-item">
                              <button
                                className={
                                  subCategory === "interconnect"
                                    ? "btn btn-secondary"
                                    : "btn btn-light"
                                }
                                onClick={() => {
                                  setSubCategory("interconnect");
                                  setProductType(interconnectProductType);
                                  setSelectedProductType(
                                    interconnectProductType[0]
                                  );
                                  setPageNumber(1);
                                }}
                              >
                                {" "}
                                {t("provisioning.interconnect")}
                              </button>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="row mt-2">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                      <div className="grid_card grid_mr h-100 tckt">
                        <div className="card_head d-flex justify-content-start">
                          <SerachBox
                            searchCategory={searchCategory}
                            query={query}
                            setQuery={setQuery}
                            setIsLoading={setIsLoading}
                            setPageNumber={setPageNumber}
                            setItemsPerPage={setItemsPerPage}
                          ></SerachBox>
                          <div className="txt_card">
                            <ul className="tabs_card">
                              <li className="tc-1 active"></li>
                              <li className="tc-1"></li>
                              <li className="tc-1"></li>

                              <li class="tc-1">
                                <div className="tc_drop1-out">
                                  <div class="tc_drop2">
                                    <div className="inp-out">
                                      <div className="val">
                                        {selectedProduct.name}
                                      </div>
                                      <div className="after"></div>
                                    </div>
                                    <div
                                      className="img-out"
                                      onClick={() => setOpen(!open)}
                                    >
                                      {" "}
                                      <img
                                        alt=""
                                        src="\images\arrow.png"
                                        className="arrow-img"
                                      />
                                    </div>
                                  </div>
                                  {open && (
                                    <div className="tc_drop2-opt">
                                      {productType &&
                                        productType.map((productType) => {
                                          return (
                                            <div
                                              className="tc_opt"
                                              onClick={() => {
                                                setSelectedProductType(
                                                  productType
                                                );
                                                setOpen(false);
                                                setPageNumber(1);
                                              }}
                                            >
                                              {productType.name}
                                            </div>
                                          );
                                        })}{" "}
                                    </div>
                                  )}
                                </div>
                              </li>
                              <li class="tc-1">
                                <div className="tc_drop1-out">
                                  <div class="tc_drop2">
                                    <div className="inp-out">
                                      <div className="val">
                                        {selectedStatus.name}
                                      </div>
                                      <div className="after"></div>
                                    </div>
                                    <div
                                      className="img-out"
                                      onClick={() => setOpen2(!open2)}
                                    >
                                      {" "}
                                      <img
                                        alt=""
                                        src="\images\arrow.png"
                                        className="arrow-img"
                                      />
                                    </div>
                                  </div>
                                  {open2 && (
                                    <div className="tc_drop2-opt">
                                      {state &&
                                        status.map((status) => {
                                          return (
                                            <div
                                              className="tc_opt"
                                              onClick={() => {
                                                setSelectedStatus(status);
                                                setOpen2(false);
                                                setPageNumber(1);
                                              }}
                                            >
                                              {status.name}
                                            </div>
                                          );
                                        })}{" "}
                                    </div>
                                  )}
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>

                        <Table
                          columns={columns}
                          data={provisioningList}
                          setItemsPerPage={setItemsPerPage}
                          setPageNumber={setPageNumber}
                          total={total}
                          pageNumber={pageNumber}
                          handlePop={handlePop}
                        ></Table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {provision && (
          <ProvisioningPop
            ticketData={ticketData}
            handleExitbutton={handleExitbutton}
            provision={provision}
            statusList={statusList}
            handlePop={handlePop}
            refreshData={getPopupDetail}
            setIsLoading={setIsLoading}
            isIB={category == "installed_base" ? true : false}
            currentServiceId={currentServiceId}
            setCurrentServiceId={setCurrentServiceId}
            fetchServices={fetchServices}
            permissions={
              subCategory == "colocation"
                ? permissions.filter(
                    (el) =>
                      el.provisioning_type_id ==
                      (category == "installed_base" ? 1 : 3)
                  )[0]?.roleProvisioningPermission
                : permissions.filter(
                    (el) =>
                      el.provisioning_type_id ==
                      (category == "installed_base" ? 2 : 4)
                  )[0]?.roleProvisioningPermission
            }
          />
        )}
        {errorpopup && (
          <ErrorModel
            show={errorpopup}
            setShow={setErrorPopup}
            errorMsg={errorMsg}
          />
        )}
      </Layout>
    </React.Fragment>
  );
};

export default Provisioning;
