// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings-cross-connect-table-with-data {
  overflow-y: scroll;
  height: 300px;
}

.settings-cross-connect-table-no-data {
  overflow: auto;
  height: 300px;
}
`, "",{"version":3,"sources":["webpack://./src/views/super-admin/Setting/DataCenter/CrossConnect/CrossConnect.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,cAAc;EACd,aAAa;AACf","sourcesContent":[".settings-cross-connect-table-with-data {\n  overflow-y: scroll;\n  height: 300px;\n}\n\n.settings-cross-connect-table-no-data {\n  overflow: auto;\n  height: 300px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
