import React from "react";
import { useTranslation } from "react-i18next";
const InService = ({ index }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div
        style={{
          height: "10px",
          width: "10px",
          borderRadius: "3px",
          marginLeft: ".2rem",
          backgroundColor: "#f89903",
        }}
      ></div>
     {t('dashboard.in_services')}
    </React.Fragment>
  );
};

const Reserved = ({ index }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div
        style={{
          height: "10px",
          width: "10px",
          borderRadius: "3px",
          marginLeft: ".2rem",
          backgroundColor: "#1b70c0",
        }}
      ></div>
       {t('dashboard.reserved')}
    </React.Fragment>
  );
};

const ROFR = ({ index }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div
        style={{
          height: "10px",
          width: "10px",
          borderRadius: "3px",
          marginLeft: ".2rem",
          backgroundColor: "#595959",
        }}
      ></div>
      {t('dashboard.rofr')}
    </React.Fragment>
  );
};

const Blocked = ({ index }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div
        style={{
          height: "10px",
          width: "10px",
          borderRadius: "3px",
          marginLeft: ".2rem",
          backgroundColor: "#000000",
        }}
      ></div>
      {t('dashboard.blocked')}
    </React.Fragment>
  );
};

const Available = ({ index }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div
        style={{
          height: "10px",
          width: "10px",
          borderRadius: "3px",
          marginLeft: ".2rem",
          backgroundColor: "#70ad47",
        }}
      ></div>
      {t('dashboard.available')}
    </React.Fragment>
  );
};
const Unavailable = ({ index }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div
        style={{
          height: "10px",
          width: "10px",
          borderRadius: "3px",
          marginLeft: ".2rem",
          backgroundColor: "#e0e2e5",
        }}
      ></div>
      {t('dashboard.unavailable')}
    </React.Fragment>
  );
};
const S1 = ({ index }) => {
  return (
    <React.Fragment>
      <div
        style={{
          height: "10px",
          width: "10px",
          borderRadius: "3px",
          marginLeft: ".2rem",
          backgroundColor: "#e03138",
        }}
      ></div>
      S1
    </React.Fragment>
  );
};

const S2 = ({ index }) => {
  return (
    <React.Fragment>
      <div
        style={{
          height: "10px",
          width: "10px",
          borderRadius: "3px",
          marginLeft: ".2rem",
          backgroundColor: "#f78600",
        }}
      ></div>
      S2
    </React.Fragment>
  );
};

const S3 = ({ index }) => {
  return (
    <React.Fragment>
      <div
        style={{
          height: "10px",
          width: "10px",
          borderRadius: "3px",
          marginLeft: ".2rem",
          backgroundColor: "#f2dc34",
        }}
      ></div>
      S3
    </React.Fragment>
  );
};

const S4 = ({ index }) => {
  return (
    <React.Fragment>
      <div
        style={{
          height: "10px",
          width: "10px",
          borderRadius: "3px",
          marginLeft: ".2rem",
          backgroundColor: "#40ff00",
        }}
      ></div>
      S4
    </React.Fragment>
  );
};

const P0 = ({ index }) => {
  return (
    <React.Fragment>
      <div
        style={{
          height: "10px",
          width: "10px",
          borderRadius: "3px",
          marginLeft: ".2rem",
          backgroundColor: "#e03138",
        }}
      ></div>
      P0
    </React.Fragment>
  );
};

const P1 = ({ index }) => {
  return (
    <React.Fragment>
      <div
        style={{
          height: "10px",
          width: "10px",
          borderRadius: "3px",
          marginLeft: ".2rem",
          backgroundColor: "#f78600",
        }}
      ></div>
      P1
    </React.Fragment>
  );
};

const P2 = ({ index }) => {
  return (
    <React.Fragment>
      <div
        style={{
          height: "10px",
          width: "10px",
          borderRadius: "3px",
          marginLeft: ".2rem",
          backgroundColor: "#f2dc34",
        }}
      ></div>
      P2
    </React.Fragment>
  );
};

const P3 = ({ index }) => {
  return (
    <React.Fragment>
      <div
        style={{
          height: "10px",
          width: "10px",
          borderRadius: "3px",
          marginLeft: ".2rem",
          backgroundColor: "#40ff00",
        }}
      ></div>
      P3
    </React.Fragment>
  );
};

const I = ({ index }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div
        style={{
          height: "10px",
          width: "10px",
          borderRadius: "3px",
          marginLeft: ".2rem",
          backgroundColor: "#1b70c0",
        }}
      ></div>
       I
    </React.Fragment>
  );
};

const E = ({ index }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div
        style={{
          height: "10px",
          width: "10px",
          borderRadius: "3px",
          marginLeft: ".2rem",
          backgroundColor: "#c2adc2",
        }}
      ></div>
    E
    </React.Fragment>
  );
}; 
/*const Today = ({index}) => {

	return (
		<React.Fragment key={index}>
			<div style={{
			height: "10px",
		    width: "10px",
		    borderRadius: "3px",
			marginLeft:".2rem",
		    backgroundColor: "#e03138"}} ></div>
		    Today
		</React.Fragment>
	);
}*/
const Today = ({ index }) => {
  return (
    <React.Fragment>
      <div
        style={{
          height: "10px",
          width: "10px",
          borderRadius: "3px",
          marginLeft: ".2rem",
          backgroundColor: "#f89903",
        }}
      ></div>
      Count
    </React.Fragment>
  );
};
const Tomorrow = ({ index }) => {

  return (
    <React.Fragment>
      <div
        style={{
          height: "10px",
          width: "10px",
          borderRadius: "3px",
          marginLeft: ".2rem",
          backgroundColor: "#f89903",
        }}
      ></div>
      Tomorrow
    </React.Fragment>
  );
};

const Complete = ({ index }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div
        style={{
          height: "10px",
          width: "10px",
          borderRadius: "3px",
          marginLeft: ".2rem",
          backgroundColor: "#c2adc2",
        }}
      ></div>
    {t('dashboard.complete')}
    </React.Fragment>
  );
};


const BlueColor = ({ index }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div
        style={{
          height: "10px",
          width: "10px",
          borderRadius: "3px",
          marginLeft: ".2rem",
          backgroundColor: "#1b70c0",
        }}
      ></div>
    Provisioning
    </React.Fragment>
  );
};
const BlueColor2 = ({ index }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div
        style={{
          height: "10px",
          width: "10px",
          borderRadius: "3px",
          marginLeft: ".2rem",
          backgroundColor: "#1b70c0",
        }}
      ></div>
    New
    </React.Fragment>
  );
};

const OrangeColor = ({ index }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
    <div
      style={{
        height: "10px",
        width: "10px",
        borderRadius: "3px",
        marginLeft: ".2rem",
        backgroundColor: "#f89903",
      }}
    ></div>
   Acceptance
  </React.Fragment>
  );
};

const GreenColor = ({ index }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div
        style={{
          height: "10px",
          width: "10px",
          borderRadius: "3px",
          marginLeft: ".2rem",
          backgroundColor: "#70ad47",
        }}
      ></div>
      In Service
    </React.Fragment>
  );
};
const GreenColor2 = ({ index }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div
        style={{
          height: "10px",
          width: "10px",
          borderRadius: "3px",
          marginLeft: ".2rem",
          backgroundColor: "#70ad47",
        }}
      ></div>
      In Service (PP)
    </React.Fragment>
  );
};

const YellowColor = ({ index }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
    <div
      style={{
        height: "10px",
        width: "10px",
        borderRadius: "3px",
        marginLeft: ".2rem",
        backgroundColor: "#f2dc34",
      }}
    ></div>
    Pending Change
  </React.Fragment>
  );
};

const YellowColor2 = ({ index }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
    <div
      style={{
        height: "10px",
        width: "10px",
        borderRadius: "3px",
        marginLeft: ".2rem",
        backgroundColor: "#f2dc34",
      }}
    ></div>
    Work In Progress
  </React.Fragment>
  );
};


export {
  InService,
  Reserved,
  ROFR,
  Blocked,
  Available,
  Unavailable,
  S1,
  S2,
  S3,
  S4,
  Today,
  Tomorrow,
  Complete,
  P0,
  P1,
  P2,
  P3,
  I,
  E,
  BlueColor,
  OrangeColor,
  GreenColor,
  YellowColor,
  GreenColor2,
  YellowColor2,
  BlueColor2
};
