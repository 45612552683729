import { Button, Row } from 'react-bootstrap'
import { css } from 'glamor'
import './NewTicketRow.scss'

const NewTicketRow = ({ props, openCreateTicketModal, setActive }) => {
  const { id, name, active } = props

  const styles = {
    button: {
      color: 'black',
      fontSize: '17px !important',
      padding: '0 10px 0 10px !important',
      whiteSpace: 'nowrap',
      display: 'flex',
      justifyContent: 'flex-start'
    }
  }

  return (
    <Row key={id} className='dashboard-ticket-popup-option-row'>
      <Button
        {...css(styles.button)}
        variant='link'
        disabled={!active}
        onClick={() => {
          openCreateTicketModal(props)
          setActive(false)
        }}
      >
        {name}
      </Button>
    </Row>
  )
}

export default NewTicketRow
