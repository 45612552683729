import AuthContext from "context";
import saveAs from "file-saver";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import CapacityService from "services/capacityService";
import DataCenterPerformance from "services/DataCenterPerformance";
import RoleModel from "services/roleServices";
import Swal from "sweetalert2";
import Layout from "../../Layouts";
import Navigation from "../Component/Navigation";
import "./capacityLayout.css";
import "./capacityStyle.css";
import DataCenterNav from "./Navigation";
import { XError } from "components/common";
import { LogoutOnSessionExpire } from "common/helpers";
import Loading from "views/super-admin/pre-loader/loader";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";

const ExcelJS = require("exceljs");
const maxSize = process.env.REACT_APP_CAPACITY_LAYOUT_MAX_FILE_SIZE;
const CapacityLayout = (props) => {
  const authContext = useContext(AuthContext);
  const [dataCenter, setDataCenter] = useState([]);
  const [currentDataCenter, setCurrentDataCenter] = useState([]);
  const [countryName, setCountryName] = useState("Country");
  const initialMount = useRef(true);
  const modalRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [isDataChanged, setDataChanged] = useState(false);

  const [countfile, setCountFile] = useState("");
  const [state, setState] = useState({
    notes: "",
    layout: "",
    image: "",
    uploaded_time: "",
    data_center_id: "",
  });
  const [errors, setError] = useState({
    notes: "",
    layout: "",
    uploaded_time: "",
    data_center_id: "",
  });
  const [secondCount, setSecondCount] = useState(0);
  const [progress, setProgress] = useState(0);
  const [layoutListAll, setLayoutListAll] = useState([]);
  const [display, setDisplay] = useState("");
  const [countries, setCountries] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    getData();
    if (initialMount.current === true) {
      selectDataCenterFloor(authContext.getNetworkDataCenter);

      initialMount.current = false;

      if (
        authContext?.getAuth?.role?.space === 3 ||
        authContext?.getAuth?.role?.m_e === 3 ||
        authContext?.getAuth?.role?.network === 3
      ) {
        setIsReadOnly(false);
      }
    } else {
      setDataChanged(false);
    }
    getAllDataCenter();
    if (
      (dataCenter.length && authContext.getNetworkDataCenter === undefined) ||
      authContext.getNetworkDataCenter.length === 0
    ) {
      authContext.setNetworkDataCenter(dataCenter[0]);
    }

    //getLayoutList();
  }, [isDataChanged]);

  const getData = async () => {
    await RoleModel.countryService(authContext.token())
      .then((res) => {
        setCountries(res.data.data);
      })
      .catch((err) => {
        LogoutOnSessionExpire(err);
      })
      .finally(() => setIsLoading(false));
  };

  const getAllDataCenter = async () => {
    setCountryName("Country");
    await RoleModel.dataCenter(authContext.token()).then((res) => {
      let newDataCenter = res.data.data.filter((data) => data.dc_type_id === 1);

      setDataCenter(newDataCenter);
      //alert(authContext.getNetworkDataCenter.id)
      if (
        authContext.getNetworkDataCenter === undefined ||
        authContext.getNetworkDataCenter.length === 0
      ) {
        authContext.setNetworkDataCenter(newDataCenter[0]);
        selectDataCenterFloor(newDataCenter[0]);
        //getCapacity(newDataCenter[0]);
      }
      if (res.data.data.length > 0) {
        if (initialMount.current) {
          selectDataCenterFloor(authContext.getNetworkDataCenter);
          initialMount.current = false;
        }
      }
      if (newDataCenter.length) {
        setCurrentDataCenter(
          authContext.getNetworkDataCenter.id === undefined
            ? newDataCenter[0]
            : { id: authContext.getNetworkDataCenter.id }
        );
      }
    });
  };

  const getDataCenterById = async (e) => {
    setIsLoading(true);
    setCountryName(e.name);
    await RoleModel.dataCenterByCountryId(authContext.token(), e).then(
      (res) => {
        setDataCenter(res.data.data);
        setIsLoading(false);
        if (res.data.data.length > 0) {
          selectDataCenterFloor(res.data.data[0]);
        }
      }
    );
  };

  const selectDataCenterFloor = async (e) => {
    setIsLoading(true);
    getLayoutList(e.id);
    authContext.setNetworkDataCenter(e);
    setCurrentDataCenter(e);
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 5000);
    return () => clearTimeout(timer);
  };

  const renderCountry = () => {
    return (
      countries.length &&
      countries.map((data, i) => {
        return (
          <a
            href="#sd"
            className="dropdown-item"
            key={i}
            onClick={() => {
              getDataCenterById(data);
            }}
          >
            {data.name}{" "}
          </a>
        );
      })
    );
  };

  const closeModal = () => {
    setState({
      layout: "",
      notes: "",
      uploaded_time: "",
      data_center_id: "",
    });
    setDisplay("");
    setProgress(0);
    setError({
      layout: "",
      notes: "",
      uploaded_time: "",
      data_center_id: "",
    });
    if (modalRef.current) {
      modalRef.current.click();
    }
  };
  const onChangePicture = (e) => {
    if (e.target.files[0]) {
      const fileSize = e.target.files[0].size;
      const size = Math.round(fileSize / 1024);
      if (maxSize >= size) {
        const fileNamearr = e.target.files[0].name.split(".").pop();
        const fileExt = fileNamearr;
        const dispalyName = e.target.files[0].name;
        setDisplay(e.target.files[0].name);
        // console.log(e.target.files[0].name+" ---- extencen  ---"+fileExt);
        if (fileExt === "pdf" || fileExt === "xls" || fileExt === "xlsx") {
          var FR = new FileReader();

          FR.addEventListener("load", function (e) {
            setState({
              ...state,
              layout: e.target.result,
              imageExt: fileExt,
              displayName: dispalyName,
            });
          });
          FR.readAsDataURL(e.target.files[0]);
          setError({ ...errors, layout: " " });
        } else {
          setError({
            ...errors,
            layout: "Invalid file type please select PDF , XLS and XLSX file.",
          });
        }
      } else {
        setError({
          ...errors,
          layout: "File too Big, please select a file less than 10mb",
        });
      }
    }
  };
  const counter = () => {
    setSecondCount(secondCount + 1);
  };
  const checkValidation = () => {
    //  console.log("validation",state);
    let error = {
      layout: "",
      notes: "",
      uploaded_time: "",
      data_center_id: "",
    };

    const { layout, notes, uploaded_time, data_center_id } = state;

    let flag = true;

    if (layout === "" || layout === null || layout === undefined) {
      error.layout = "The File field is required.";
      flag = false;
    }

    setError({ ...error });
    // console.log(errors)
    return flag;
  };
  // uploade layout
  const onSubmit = async () => {
    setIsLoading(true);
    if (checkValidation()) {
      await CapacityService.layoutstore(
        authContext.token(),
        { ...state, data_center_id: currentDataCenter.id },
        {
          onUploadProgress: (progressEvent) => {
            let percentComplete = progressEvent.loaded / progressEvent.total;
            percentComplete = parseInt(percentComplete * 100);
            // console.log(percentComplete);
            setProgress(percentComplete);
          },
        }
      )
        .then(async (res) => {
          // console.log(res.data.data.data_center_id);
          counter();
          //console.log(secondCount);
          getLayoutList(res.data.data.data_center_id);
          setIsLoading(false);
          Swal.fire(t("layouts.file_uploaded"));
          closeModal();
        })
        .catch((err) => {
          LogoutOnSessionExpire(err);
          // console.log(err);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };

  // get data by datacenter id and user id
  const getLayoutList = async (e) => {
    setIsLoading(true);
    await CapacityService.layoutList(authContext.token(), e)
      .then((res) => {
        const layoutdata = res.data.data;
        setCountFile(layoutdata.length);
        setLayoutListAll(layoutdata);
      })
      .catch((err) => {
        LogoutOnSessionExpire(err);
      })
      .finally(() => setIsLoading(false));
  };

  // download file
  const downloadFile = (file, dname) => {
    saveAs(`${authContext.getAssetPath}/images/layout/` + file, dname);
  };

  // delete function
  const deleteFile = async (id) => {
    Swal.fire({
      title: t("common.are_you_sure"),
      text: t("common.you_wont_be_able_to_revert_this"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("common.yes_delete_it"),
      customClass: {
        cancelButton: "cancelBtn",
        confirmButton: "confirmBtn",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        CapacityService.deleteLayoutFile(authContext.token(), {
          id,
          did: currentDataCenter.id,
        })
          .then((res) => {
            Swal.fire("success", t("layouts.deleted"), "success");
            getLayoutList(currentDataCenter.id);
          })
          .catch((err) => {
            LogoutOnSessionExpire(err);

            closeModal();
            Swal.fire("error", t("layouts.something_went_wrong"));
          });
      }
    });
  };

  return (
    <React.Fragment>
      <Loading
        loading={isLoading}
        background="rgba(0, 0, 0, 0.40)"
        loaderColor="rgb(248, 153, 3)"
      />

      <Layout>
        <div className="content-body">
          <Navigation />
          <hr className="main" />
          <div className="container-fluid pt-0">
            <div className="row ">
              <DataCenterNav />
              <div className="col-lg-11">
                <div id="pro">
                  <div className="profile-tab">
                    <div className="custom-tab-1">
                      <div className="main_scrll">
                        <div className="btn_ul">
                          <ul className="nav nav-tabs mb-3">
                            <li className="nav-item">
                              {" "}
                              <button
                                className="btn btn-secondary"
                                style={{ width: "100%" }}
                                onClick={getAllDataCenter}
                              >
                                {" "}
                                {t("layouts.global")}{" "}
                              </button>
                            </li>
                            <li className="nav-item">
                              <div className="btn-group" role="group">
                                <button
                                  type="button"
                                  className="btn btn-light dropdown-toggle"
                                  style={{ width: "100%" }}
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  {" "}
                                  {countryName}{" "}
                                </button>
                                <div
                                  className="dropdown-menu"
                                  style={{ margin: "0px" }}
                                >
                                  {renderCountry()}
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="outer-div-setting">
                          <div className="ul_scrll">
                            <ul className="nav nav-tabs custom-scroll-gap">
                              {dataCenter &&
                                dataCenter.map((data, index) => {
                                  if (
                                    authContext.getNetworkDataCenter &&
                                    authContext.getNetworkDataCenter.id ===
                                      data.id
                                  ) {
                                    return (
                                      <li
                                        className={
                                          index === 0 ? "nav-item" : "nav-item"
                                        }
                                        key={index}
                                      >
                                        <a
                                          href="#sd"
                                          onClick={() =>
                                            selectDataCenterFloor(data)
                                          }
                                          style={{ cursor: "pointer" }}
                                          className="nav-link active show"
                                        >
                                          <img
                                            className="down setting_down"
                                            alt="arrow"
                                            src="\images\downward-arrow.png"
                                          />
                                          {data.name}
                                        </a>
                                      </li>
                                    );
                                  } else {
                                    return (
                                      <li
                                        className={
                                          index === 0 ? "nav-item" : "nav-item"
                                        }
                                        key={index}
                                      >
                                        <a
                                          href="#sd"
                                          onClick={() =>
                                            selectDataCenterFloor(data)
                                          }
                                          style={{ cursor: "pointer" }}
                                          className="nav-link"
                                        >
                                          {" "}
                                          {data.name}{" "}
                                        </a>
                                      </li>
                                    );
                                  }
                                })}
                            </ul>
                          </div>
                          <div>
                            <span>
                              <i>
                                {t(
                                  "layouts.select_a_country_to_view_partner_data_center"
                                )}
                              </i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/*Capacity Block*/}
                  <div className="row">
                    <div>
                      <div className="row gy-4 align-items-center">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                          <div className="card-header" id="header">
                            <div className="d-sm-flex d-block justify-content-between align-items-center">
                              <div className="card-action coin-tabs mt-3 mt-sm-0">
                                {/* <CapacityNavigation/> */}
                                <ul className="nav nav-tabs" role="tablist">
                                  <li className="nav-item gap_s">
                                    <Link
                                      className="nav-link"
                                      id="tab3"
                                      to="/setting-capacity"
                                    >
                                      {" "}
                                      {t("capacity.monthly_utilisation")}
                                    </Link>
                                  </li>
                                  <li className="nav-item gap_s">
                                    <a
                                      href="#sd"
                                      className="nav-link"
                                      id="tab2"
                                      data-bs-toggle="tab"
                                    >
                                      {" "}
                                      {t("capacity.thresholds")}
                                    </a>
                                  </li>
                                  <li className="nav-item gap_s">
                                    <a
                                      href="#sd"
                                      className="nav-link active"
                                      id="tab3"
                                      data-bs-toggle="tab"
                                    >
                                      {t("capacity.layouts")}
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="de_op-232">
                          <div className="row">
                            <div>
                              <a
                                href="#sd"
                                id="addnew"
                                className="btn btn-primary me-3 btn-sm"
                                data-bs-toggle="modal"
                                data-bs-target=".bd-example-modal-lg"
                              >
                                <img
                                  alt=""
                                  src="images/upload-white.svg"
                                  style={{ width: "15px" }}
                                />
                                &nbsp;&nbsp;&nbsp; {t("layouts.upload")}
                              </a>
                              <div
                                className="modal fade bd-example-modal-lg"
                                tabIndex="-1"
                                role="dialog"
                                aria-hidden="true"
                              >
                                <div className="modal-dialog modal-lg">
                                  <div className="modal-content">
                                    <div className="modal-header mt-59">
                                      <h3 className="modal-title">
                                        {t("layouts.upload_file")}
                                      </h3>
                                      <span
                                        data-bs-dismiss="modal"
                                        ref={modalRef}
                                      >
                                        <CloseIcon
                                          style={{ cursor: "pointer" }}
                                        />
                                      </span>
                                    </div>
                                    <div className="modal-body">
                                      <div className="card">
                                        <div
                                          className="card-body"
                                          style={{
                                            padding: "0px",
                                            overflow: "hidden",
                                          }}
                                        >
                                          <div className="basic-form">
                                            <form>
                                              <div className="row">
                                                <div className="col-12 col-sm-12 col-lg-8 mt-2313">
                                                  <p className="fake_label">
                                                    {t("layouts.select_file")}
                                                  </p>
                                                  {/* //value={state?.layout} onChange={event => setState({...state,layout:event.target.value})}Drop your files here or <span> {secondCount} seconds remaining</span>*/}
                                                  <div className="de_drag">
                                                    <div className="mb-3">
                                                      <label
                                                        htmlFor="formFile"
                                                        className="form-label"
                                                      >
                                                        <img
                                                          src="images/image.svg"
                                                          alt=""
                                                        />
                                                        <p className="de_browse">
                                                          {" "}
                                                          <span>
                                                            {t(
                                                              "layouts.browse"
                                                            )}
                                                          </span>
                                                        </p>
                                                        {display ? (
                                                          <h5>{display}</h5>
                                                        ) : (
                                                          ""
                                                        )}
                                                        <p className="de_max_file_s">
                                                          {t(
                                                            "layouts.max_file_size_10_mb"
                                                          )}
                                                        </p>
                                                      </label>
                                                      <input
                                                        className="form-control d-none"
                                                        type="file"
                                                        id="formFile"
                                                        onClick={(e) =>
                                                          (e.target.value = null)
                                                        }
                                                        accept="application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                                        onChange={
                                                          onChangePicture
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="row mb-3">
                                                <div className="col-12 col-sm-12 col-lg-8">
                                                  <div className="de_uploading">
                                                    <label
                                                      htmlFor="exampleFormControlTextarea1"
                                                      className="form-label"
                                                    >
                                                      {t("layouts.uploading")}
                                                    </label>
                                                    <p>
                                                      {progress}%
                                                      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    </p>
                                                    <div className="progress">
                                                      <div
                                                        className="progress-bar"
                                                        role="progressbar"
                                                        style={{
                                                          width: progress + "%",
                                                        }}
                                                        aria-valuenow={progress}
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                      ></div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <XError
                                                  message={errors.layout}
                                                />
                                              </div>
                                              <div className="row">
                                                <div className="mb-3">
                                                  <label
                                                    htmlFor="exampleFormControlTextarea1"
                                                    className="form-label"
                                                  >
                                                    {t("layouts.notes")}
                                                  </label>
                                                  <textarea
                                                    className="form-control"
                                                    value={state.notes}
                                                    onChange={(event) =>
                                                      setState({
                                                        ...state,
                                                        notes:
                                                          event.target.value,
                                                      })
                                                    }
                                                    id="exampleFormControlTextarea1"
                                                    placeholder="Your Notes"
                                                    rows="10"
                                                    style={{ height: "100px" }}
                                                  ></textarea>
                                                </div>
                                                <div className="de-i">
                                                  <img
                                                    src="images/i.png"
                                                    alt=""
                                                    style={{ width: "15px" }}
                                                  />{" "}
                                                  <span>
                                                    {t("layouts.only")}{" "}
                                                    {countfile
                                                      ? 15 - countfile
                                                      : 15}{" "}
                                                    {t(
                                                      "layouts.uploads_remaining"
                                                    )}
                                                  </span>
                                                </div>
                                              </div>

                                              <div
                                                className="toolbar toolbar-bottom mt-51 d-flex justify-content-end"
                                                role="toolbar"
                                              >
                                                <button
                                                  type="button"
                                                  className="btn btn-outline-primary mr_1"
                                                  onClick={closeModal}
                                                >
                                                  {t("common.cancel")}
                                                </button>
                                                {isLoading ? (
                                                  <button
                                                    type="button"
                                                    className="btn btn-primary mr_1"
                                                  >
                                                    {" "}
                                                    {t(
                                                      "common.loading"
                                                    )}...{" "}
                                                  </button>
                                                ) : (
                                                  <button
                                                    type="button"
                                                    onClick={() => onSubmit()}
                                                    className="btn btn-primary mr_1"
                                                  >
                                                    {" "}
                                                    {t("common.save")}{" "}
                                                  </button>
                                                )}
                                                {/* <button type="button" className="btn btn-primary" onClick={() => onSubmit()}>Save</button> */}
                                              </div>
                                            </form>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-12 layout-card-div">
                              <div className="card">
                                <div className="card-body">
                                  <div className="table-responsive overflow-auto">
                                    <table className="table table-responsive-md mt-3">
                                      <thead>
                                        <tr>
                                          <th>
                                            <strong>
                                              {t("layouts.file_name")}
                                            </strong>
                                          </th>
                                          <th>
                                            <strong>
                                              {t("layouts.uploaded_time")}
                                            </strong>
                                          </th>
                                          <th>
                                            <strong>{t("layouts.user")}</strong>
                                          </th>
                                          <th>
                                            <strong>
                                              {t("layouts.notes")}
                                            </strong>
                                          </th>
                                          <th>
                                            <strong></strong>
                                          </th>
                                          <th>
                                            <strong></strong>
                                          </th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {layoutListAll &&
                                          layoutListAll.map((list, index) => (
                                            <tr key={index}>
                                              <td>{list.display_name}</td>
                                              <td>
                                                {moment(
                                                  list.uploaded_time
                                                ).format("YYYY-MM-DD hh:mm A")}
                                              </td>
                                              <td>{list.user.name}</td>
                                              <td>{list.notes}</td>
                                              <td
                                                onClick={() =>
                                                  downloadFile(
                                                    list.file_name,
                                                    list.display_name
                                                  )
                                                }
                                              >
                                                <span className="down_de">
                                                  <img
                                                    src="images/download.svg"
                                                    style={{ width: "12px" }}
                                                    alt=""
                                                  />
                                                  &nbsp;&nbsp;
                                                  {t("layouts.download")}
                                                </span>
                                              </td>
                                              <td
                                                onClick={() =>
                                                  deleteFile(list.id)
                                                }
                                              >
                                                <span className="delete_de">
                                                  <img
                                                    alt=""
                                                    src="images/trash-2.svg"
                                                    style={{ width: "12px" }}
                                                  />
                                                  &nbsp;&nbsp;
                                                  {t("common.delete")}
                                                </span>
                                              </td>
                                            </tr>
                                          ))}
                                        {/* <tr>
																				<td>June-2022-PEK1.pdf</td>
																				<td>20-Jun-2022 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>11:47:00</span></td>
																				<td>Henry Wang</td>
																				<td>Latest update for june</td>
																				<td><span className="down_de"><img src="images/download.svg" style={{ width: "12px" }} />&nbsp;&nbsp;Download</span></td>
																				<td><span className="delete_de"><img src="images/trash-2.svg" style={{ width: "12px" }} />&nbsp;&nbsp;Delete</span></td>
																			</tr>
																			<tr>
																				<td>June-2022-PEK1.pdf</td>
																				<td>20-Jun-2022 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>11:47:00</span></td>
																				<td>Henry Wang</td>
																				<td>Latest update for june</td>
																				<td><span className="down_de"><img src="images/download.svg" style={{ width: "12px" }} />&nbsp;&nbsp;Download</span></td>
																				<td><span className="delete_de"><img src="images/trash-2.svg" style={{ width: "12px" }} />&nbsp;&nbsp;Delete</span></td>
																			</tr>
																			<tr>
																				<td>June-2022-PEK1.pdf</td>
																				<td>20-Jun-2022 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>11:47:00</span></td>
																				<td>Henry Wang</td>
																				<td>Latest update for june</td>
																				<td><span className="down_de"><img src="images/download.svg" style={{ width: "12px" }} />&nbsp;&nbsp;Download</span></td>
																				<td><span className="delete_de"><img src="images/trash-2.svg" style={{ width: "12px" }} />&nbsp;&nbsp;Delete</span></td>
																			</tr> */}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default CapacityLayout;
