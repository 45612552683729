// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ticket-action-buttons {
  padding: 10px;
  border-radius: 5%;
}`, "",{"version":3,"sources":["webpack://./src/views/super-admin/Tickets/SiteAccessTicketForm/RightPart/ActionButtons/ActionButtons.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,iBAAA;AACJ","sourcesContent":[".ticket-action-buttons {\n    padding: 10px;\n    border-radius: 5%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
