import './AddEntity.css';

const AddEntity = ({ label, onClick }) => {
  return (
    <div className="text-right">
      <div className="add_device_btn settings-cross-connect-link-add">
        <a
          href="#sd"
          style={{ cursor: 'pointer' }}
          onClick={onClick}
        >
          <div className="settings-cross-connect-add-button">
            <div className="settings-cross-connect-add-button-div">
              <img
                src="/images/plus-circle-blue.svg"
                alt=""
                style={{ width: '1rem' }}
              />{' '}
            </div>
            <div>&nbsp; {label}</div>
          </div>
        </a>
      </div>
    </div>
  );
};

export default AddEntity;