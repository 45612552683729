import React, { useContext, useEffect, useState, useRef } from "react";
import AuthContext from "context";
import ticketDetails from "services/ticketDetails";
import moment, { duration } from "moment-timezone";
import ErrorModel from "../errormodel";
import Swal from "sweetalert2";
import Loading from "../../../pre-loader/loader";
import TableRows from "../addtable";
import TimerPop from "../../timerPopup";
import { functions } from "lodash";
import { callMsGraph } from "views/super-admin/Auth/graph";
import DateTime from "../shared/DateTimeComp";
import MultiSelectField from "../shared/select";
import createTicketService from "services/createTicketService";
import _ from "lodash";
import { NonEditable, priority } from "../shared/data";
import SingleSelect from "../shared/singleSelect";
// import attach from '../../../../../public/img/attach.svg';
import CreateTicketService from "services/createTicketService";
import AddFollowers from "../addfollowers";
import ticketService from "services/ticketService";
import provisioningService from "services/provisioningService";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useTranslation } from "react-i18next";
import DescriptionAndAssignSection from "./shared/descriptionAndAssignSection";
import FirstRow from "./shared/firstRow";
import SecondRow from "./shared/secondeRow";
import DraftDescription from "./shared/draftDescription";
import LogNoteAndMessage from "../shared/logNote&Message";
import TicketHistory from "../shared/ticketHistory";
import RemoteHandTimesheet from "../shared/remoteHandTimesheet";
import CircularProgress from "@mui/material/CircularProgress";

const RemoteHandPopup = (props) => {
  const { t } = useTranslation();
  const contextStore = useContext(AuthContext);
  const [ticketstatusname, setTicketStatusName] = useState([]);
  const [displaytitle, setDisplayTitle] = useState("");
  const [errorpopup, setErrorPopup] = useState(false);
  const [messagenotes, setMessageNotes] = useState([]);
  const [active, setActive] = useState("log");
  const [statusChange, setStatusChange] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Something went Wrong !");
  const [refreshstatus, setRefreshStatus] = useState(false);
  const [toggleEdit, setToggleEdit] = useState(false);
  const [services, setServices] = useState([]);
  const [allSpace, setAllSpace] = useState([]);
  const [allRooms, setAllRooms] = useState([]);
  const [DesignatedId, setDesignatedId] = useState("");
  const [updateTicketDetails, setUpdateTicketDetails] = useState({
    description: "",
    start_date: "",
    end_date: "",
    service_id: [],
    ticket_sub_type_id: "",
    designated_company_id: "",
    priority_id: "",
    use_remote_hand_pack: "",
  });
  const [clickstatus, setClickStatus] = useState(false);
  const [rowsData, setRowsData] = useState([]);
  const [startTime, setStartTime] = useState(0);
  const [fileData, setFileData] = useState([]);
  const [payload, setPayload] = useState();
  const fileInputRef = useRef(null);
  const [time, setTime] = useState({ hours: 0, minutes: 0, seconds: 0 });
  const [timeVal, setTimeVal] = useState("");
  const [isRunning, setIsRunning] = useState(false);
  const [timeResume, setTimeResume] = useState(true);
  const [timePop, setTimePop] = useState(false);
  const [endTime, setEndTime] = useState(0);
  const [showAttachment, setShowAttachment] = useState([]);
  const [istypetrue, setistypetrue] = useState(true);
  const [assignees, setAssignees] = useState([]);
  const [filteredAssignees, setFilteredAssignees] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [state, setState] = useState({
    ticket_id: "",
    company: "",
    type: "",
    ticket_sub_type_id: "",
    service_id: "",
    priority: "",
    description: "",
    subject: "",
    notes: [],
    ticket_status_id: "",
    ticket_status: "",
    messages: [],
    tickettypeid: "",
    team_id: "",
    assignto: "",
    designatedcompany: "",
    timesheet: [],
    customer_email: "",
    special_visit_name: "",
    service_ids: "",
    space_ids: "",
    site_access_visiting_rooms: "",
    use_remote_hands_pack: false,
    ticket_subtype: "",
    ban: "",
    remaining_hours: "",
    excess_hours: "",
    scheduled_maintenance: "",
    service: "",
    space: "",
    details: "",
    logs: [],
    readiness_id: "",
    assignee: "",
    assignee_id: "",
    assignee_is_current_user: false,
    portal_code: "",
  });
  const [follower, setFollower] = useState([]);
  const [follows, setFollows] = useState(false);
  const [userFollow, setUserFollows] = useState("");
  const [addfollower, setAddFollower] = useState(false);
  const [isUpdateDescriptionList, setIsUpdateDescriptionList] = useState(false);
  const [draftDescription, setDraftDescription] = useState("");
  const [isTicketStatusLoading, setIsTicketStatusLoading] = useState(false);

  useEffect(() => {
    if (toggleEdit) {
      const start = updateTicketDetails.start_date;
      const end = updateTicketDetails.end_date
        ? updateTicketDetails.end_date
        : moment(state.scheduled_maintenance_end).utc().format();
      if (moment(start) > moment(end)) {
        setUpdateTicketDetails((prev) => ({
          ...prev,
          end_date: moment(start),
        }));
      }
    }
  }, [updateTicketDetails.start_date]);
  const handlePrint = () => {
    let followerDiv = document.querySelector("#follwerDropdown");
    followerDiv.classList.remove("dropdown-menu");
    followerDiv.classList.add("follow-dropdown-menu");
    let descriptionDiv = document.querySelector("#descriptionBox");
    descriptionDiv.setAttribute("style", "height:100%;word-break: break-all;");
    html2canvas(document.querySelector("#capture"), {
      windowHeight: document.querySelector("#content-area").scrollHeight + 500,
    }).then((canvas) => {
      var imgWidth = 210;
      var pageHeight = 290;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF("p", "mm");
      var position = 0;
      var pageData = canvas.toDataURL("image/jpeg", 1.0);
      var imgData = encodeURIComponent(pageData);
      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      doc.setLineWidth(5);
      doc.setDrawColor(255, 255, 255);
      doc.rect(0, 0, 210, 295);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;
      }
      window.open(
        doc.output("bloburl", { filename: "new-file.pdf" }),
        "_blank"
      );
      followerDiv.classList.add("dropdown-menu");
      descriptionDiv.setAttribute(
        "style",
        "height:150px;word-break: break-all;"
      );

      // doc.save('file.pdf');
    });
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: "#ff7a00",
      boxShadow: "0 0 0 1px #ff7a00",
      "&:hover": {
        borderColor: "#ff7a00", // Maintain the same border color on hover
        boxShadow: "0 0 0 1px #ff7a00", // Maintain the same box shadow on hover
      },
    }),
  };
  const handleChangeDescription = (e) => {
    let strippedString = e.target.value.replace(/(<([^>]+)>)/gi, "");
    setUpdateTicketDetails((prev) => ({
      ...prev,
      description: strippedString,
    }));
  };
  const getServiceIds = async (companyId, siteId, token) => {
    const getServiceIdsDto = await createTicketService.getServiceIds({
      token: token,
      companyId,
      siteId,
    });
    setServices(
      _.orderBy(getServiceIdsDto.data?.data, ["name"], ["asc"])
        .filter((service) => service.ns_is_remote_hands === false)
        .map((service) => {
          const { id, name } = service;
          return { ...service, label: name, value: id };
        })
    );
  };
  const [ticketTypes, setTicketTypes] = useState([]);
  const getTicketTypes = async () => {
    const getTicketTypesDto = await CreateTicketService.getNsTicketSubtypes(
      contextStore.token()
    );
    const data = getTicketTypesDto.data?.data;
    // console.log(data)
    setTicketTypes(_.orderBy(data, ["name"], ["asc"]));
  };
  useEffect(() => {
    if (!checkPermission("Log Notes") && checkPermission("Send Message")) {
      setActive("message");
    }
    console.log(
      !checkPermission("Log Notes") && !checkPermission("Send Message")
    );
    if (!checkPermission("Log Notes") && !checkPermission("Send Message")) {
      setActive("time");
    }
    if (
      !checkPermission("Log Notes") &&
      !checkPermission("Send Message") &&
      !checkPermission("Modify Timesheet")
    ) {
      setActive("history");
    }
  }, [props.permissions]);
  useEffect(() => {
    getFollowList();
    if (updateTicketDetails.designated_company_id) {
      getServiceIds(
        updateTicketDetails.designated_company_id,
        parseInt(props.ticketdata.operatingsiteid),
        localStorage.getItem("token")
      );
    }
  }, [updateTicketDetails.designated_company_id]);
  useEffect(() => {
    if (props.show) {
      document
        .getElementById("main-model")
        .addEventListener("click", async function (e) {
          if (
            document.getElementById("content-area") != null &&
            document.getElementById("content-area").contains(e.target)
          ) {
            // Clicked in box
          } else {
            // await Swal.fire({
            //   title: 'Are you sure?',
            //   text: 'Any changes made will not be saved.',
            //   icon: 'warning',
            //   showCancelButton: true,
            //   confirmButtonColor: '#3085d6',
            //   cancelButtonColor: '#d33',
            //   confirmButtonText: 'Yes'
            // }).then((res) => {
            //   if (res.isConfirmed) {
            //     closeModal();
            //   }
            // });
            // Clicked outside the box
          }
        });
    }

    if (
      props.ticketdata.messages === null ||
      props.ticketdata.messages === ""
    ) {
      props.ticketdata.messages = "";
    } else {
      props.ticketdata.messages.map((val) => {
        val.type = "message";
        return val;
      });
    }
    if (props.ticketdata.notes === null || props.ticketdata.notes === "") {
      props.ticketdata.notes = "";
    } else {
      props.ticketdata.notes.map((val) => {
        val.type = "note";
        return val;
      });
    }
    if (props.ticketdata.logs === null) {
      props.ticketdata.logs = "";
    } else {
      props.ticketdata.logs.map((val) => {
        val.type = "logs";
        return val;
      });
    }
    let service_id = [];
    let space_id = [];
    let room_ids = [];
    if (
      props.ticketdata.site_access_services !== null &&
      props.ticketdata.site_access_services.length > 0
    ) {
      props.ticketdata.site_access_services.map((data) => {
        service_id.push(data.name);
      });
    }
    if (
      props.ticketdata.site_access_spaces !== null &&
      props.ticketdata.site_access_spaces.length > 0
    ) {
      props.ticketdata.site_access_spaces.map((data) => {
        space_id.push(data.name);
      });
    }
    if (
      props?.ticketdata?.site_access_visiting_rooms != undefined &&
      props?.ticketdata?.site_access_visiting_rooms !== null &&
      props?.ticketdata?.site_access_visiting_rooms.length > 0
    ) {
      props.ticketdata.site_access_visiting_rooms.map((data) => {
        room_ids.push(data.name);
      });
    }
    setState({
      ticket_id: props.ticketdata.id,
      company: props.ticketdata.company,
      type: props.ticketdata.tickettype,
      service_id: props.ticketdata.serviceid,
      priority: props.ticketdata.priority,
      description: props.ticketdata.description,
      subject: props.ticketdata.subject,
      notes: [
        ...props.ticketdata.notes,
        ...props.ticketdata.messages,
        ...props.ticketdata.logs,
      ],
      ticket_status_id: props.ticketdata.ticket_statusid,
      ticket_status: props.ticketdata.ticket_status,
      messages: props.ticketdata.messages,
      tickettypeid: props.ticketdata.tickettypeid,
      team_id: props.ticketdata.teamid,
      assignto: state.assignto ? state.assignto : props.ticketdata.assignto,
      designatedcompany: props.ticketdata.designatedcompany,
      timesheet: props.ticketdata.timesheet,
      customer_email: props.ticketdata.partner_email,
      special_visit_name: props.ticketdata.special_visit_area_name,
      service_ids: service_id.length > 0 ? service_id.toString() : "-",
      space_ids: space_id.length > 0 ? space_id.toString() : "-",
      site_access_visiting_rooms:
        room_ids.length > 0 ? room_ids.toString() : "-",
      ticket_subtype: props.ticketdata.ticket_subtype,
      ban: props.ticketdata.details.ban,
      use_remote_hands_pack: props.ticketdata.details.use_remote_hands_pack,
      remaining_hours: props.ticketdata.details.remaining_hours,
      excess_hours: props.ticketdata.details.excess_hours,
      scheduled_maintenance:
        props.ticketdata.details.requested_service_date_start +
        " - " +
        props.ticketdata.details.requested_service_date_end,
      scheduled_maintenance_start:
        props.ticketdata.details.requested_service_date_start,
      scheduled_maintenance_end:
        props.ticketdata.details.requested_service_date_end,
      service: props.ticketdata.details.service,
      space: props.ticketdata.details.space,
      details: props.ticketdata.details,
      ticket_sub_type_id: parseInt(props.ticketdata.ticket_subtype_id),
      services_id: {
        value: props.ticketdata?.details?.serviceid,

        label: props.ticketdata?.details?.service,
        space: props.ticketdata?.details?.space,
      },
      readiness_id: props.ticketdata.readiness_id,
      assignee: props.ticketdata.assignto,
      assignee_id: props.ticketdata.assignto_id,
      assignee_is_current_user: state.assignee_is_current_user
        ? state.assignee_is_current_user
        : props.ticketdata.assignee_is_current_user,
      portal_code: props.ticketdata.portal_code,
    });
    // if (NonEditable.some((data) => data === props.ticketdata.ticket_status)) {
    //   setistypetrue(false);

    //   setUpdateTicketDetails({
    //     description: props.ticketdata.description
    //   });
    // } else {
    setistypetrue(true);
    setDesignatedId(parseInt(props.ticketdata.designated_company_id));
    setUpdateTicketDetails({
      description: props.ticketdata.description,
      ticket_sub_type_id: parseInt(props.ticketdata.ticket_subtype_id),
      service_id:
        props.ticketdata?.details?.service_id != null
          ? [props.ticketdata?.details?.service_id]
          : null,
      designated_company_id: parseInt(props.ticketdata.designated_company_id),
      priority_id: parseInt(props.ticketdata.priorityid),
      use_remote_hand_pack: props.ticketdata.details.use_remote_hands_pack,
    });
    // }
    // setUpdateTicketDetails({
    //   description: props.ticketdata.description
    // });

    if (props.timeDetails.value) {
      if (props?.timeDetails.is_running) {
        if (props?.timeDetails.total_time > 0) {
          let currdiff = timeDiff(props?.timeDetails.end_time);
          let totalSecs = parseInt(props?.timeDetails.total_time) + currdiff;
          let tval = secondsToHHMMSS(totalSecs);
          setTime({
            hours: parseInt(tval.hh),
            minutes: parseInt(tval.mm),
            seconds: parseInt(tval.ss),
          });
        } else {
          let currdiff = timeDiff(props?.timeDetails.start_time);
          let tval = secondsToHHMMSS(currdiff);
          setTime({
            hours: parseInt(tval.hh),
            minutes: parseInt(tval.mm),
            seconds: parseInt(tval.ss),
          });
        }
        setTimeResume(true);
        setIsRunning(true);
      } else {
        let tval = secondsToHHMMSS(props?.timeDetails.total_time);
        setTime({
          hours: parseInt(tval.hh),
          minutes: parseInt(tval.mm),
          seconds: parseInt(tval.ss),
        });
        setTimeVal(tval.hh + ":" + tval.mm + ":" + tval.ss);
        setTimeResume(false);
        setIsRunning(true);
      }
    }

    setStatusChange(false);
  }, [props.show, statusChange, toggleEdit, props.ticketdata]);
  useEffect(() => {
    getTicketTypes();
    ticketStatus(props?.ticketdata?.teamid, props?.ticketdata?.tickettypeid);
  }, [props.show]);
  useEffect(() => {
    let timerInterval;

    if (isRunning && timeResume) {
      timerInterval = setInterval(() => {
        const newTime = calculateTime(time);
        const timeFormate =
          newTime.hours + ":" + newTime.minutes + ":" + newTime.seconds;
        setTimeVal(() => timeFormate);
        setTime(newTime);
      }, 1000);
    } else {
      clearInterval(timerInterval);
    }

    return () => clearInterval(timerInterval);
  }, [time, isRunning, timeResume]);

  useEffect(() => {
    if (props.ticketdata.details.start_time) {
      // calculateTimeDiff(props.ticketdata.details.start_time);
    }
  }, []);

  function secondsToHHMMSS(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const hh = hours < 10 ? `0${hours}` : hours;
    const mm = minutes < 10 ? `0${minutes}` : minutes;
    const ss =
      remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;

    return { hh, mm, ss };
  }

  const [buttonText, setButtonText] = useState("Follow");
  const [icon, setIcon] = useState(follows ? "fas fa-check" : "black");
  const [buttonStyle, setButtonStyle] = useState({
    color: follows ? "#2cc970" : "black",
  });

  const follow = async () => {
    setIsLoading(true);
    await ticketService
      .subscribe(localStorage.getItem("token"), props.ticketdata.id)
      .then((res) => {
        if (res?.data?.data?.success === undefined) {
          setErrorMsg(res.data);
          setErrorPopup(true);
        } else {
          setFollows(true);
          getFollowList();
        }
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const unfollow = async () => {
    setIsLoading(true);
    await ticketService
      .unsubscribe(localStorage.getItem("token"), props.ticketdata.id)
      .then((res) => {
        if (res?.data?.data?.success === undefined) {
          setErrorMsg(res.data);
          setErrorPopup(true);
        } else {
          setFollows(false);
          getFollowList();
        }
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getFollowList = async () => {
    setIsLoading(true);
    await ticketService
      .getFollower(localStorage.getItem("token"), props.ticketdata.id)
      .then((res) => {
        const userfollow = res.data.data.find(
          (team) => team.name === contextStore.getAuth?.name
        );
        const userExists = Boolean(userfollow);
        if (userExists) {
          setFollows(true);
          setButtonText("Following");
          setIcon("fas fa-check");
          setButtonStyle({ color: "#2cc970" });
        } else {
          setFollows(false);
          setButtonText("Follow");
          setIcon("");
          setButtonStyle({ color: "black" });
        }
        setFollower(res.data.data);
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const delfollower = async (contact_id) => {
    await Swal.fire({
      title: "Are you sure?",
      text: "you want to remove follower.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      customClass: {
        cancelButton: "cancelBtn",
        confirmButton: "confirmBtn",
      },
    }).then((res) => {
      if (res.isConfirmed) {
        ticketService
          .removeFollower(
            localStorage.getItem("token"),
            props.ticketdata.id,
            contact_id
          )
          .then((res) => {
            getFollowList();
          })
          .catch((err) => {
            console.log(err.error.response);
            setErrorMsg(err.error.response.data.error);
            setErrorPopup(true);
          })
          .finally(() => {});
      }
    });
  };
  const readinessChange = async (readiness) => {
    if (getMaintenanceModeStatus()) {
      // console.log("readinessChange",readiness)
      setIsLoading(true);
      await ticketService
        .updateReadiness(
          localStorage.getItem("token"),
          props.ticketdata.id,
          readiness
        )
        .then((res) => {
          if (res?.data?.data?.success === undefined) {
            setErrorMsg(res.data);
            setErrorPopup(true);
          } else {
            updateTicketDetail(false);
          }
        })
        .catch((err) => {
          console.log(err.error.response);
          setErrorMsg(err.error.response.data.error);
          setErrorPopup(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const timeDiff = (dateString) => {
    const currentTime = moment.tz("UTC").format("YYYY-MM-DD HH:mm:ss");

    const targetDate = moment
      .tz(dateString, "Asia/Hong_Kong")
      .utc()
      .format("YYYY-MM-DD HH:mm:ss");
    console.log(currentTime, targetDate);
    const differenceInMilliseconds = moment(currentTime).diff(
      moment(targetDate),
      "seconds"
    );

    //const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
    console.log(differenceInMilliseconds);
    return differenceInMilliseconds;
  };

  const calculateTime = (currentTime) => {
    let { hours, minutes, seconds } = currentTime;

    seconds += 1;
    if (seconds === 60) {
      seconds = 0;
      minutes += 1;
      if (minutes === 60) {
        minutes = 0;
        hours += 1;
      }
    }

    return { hours, minutes, seconds };
  };

  const toggleTimer = async (val) => {
    let data;
    setIsRunning(!isRunning);

    if (val) {
      let currentTime = new Date();
      data = {
        start_time: currentTime,
        isRunning: val,
        ticket_id: props.ticketdata.id,
      };
    } else {
      data = {
        isRunning: val,
        ticket_id: props.ticketdata.id,
      };
    }

    await ticketDetails
      .addTimeSheet(localStorage.getItem("token"), data)
      .then((res) => {})
      .catch((err) => console.log(err));
  };

  const handleStopTimer = () => {
    setTimePop(true);
    setTimeResume(false);
  };

  const handleSaveTimer = async (val) => {
    let duration = val.duration_minutes.split(":");
    let durationVal = parseInt(duration[0]) * 60 + parseInt(duration[1]);
    let options = {
      duration_minutes: durationVal,
      description: val.description,
      ticket_id: props.ticketdata.id,
    };
    setIsLoading(true);
    let token = localStorage.getItem("token");
    await ticketDetails
      .setTimeSheet(token, options)
      .then((res) => {
        setIsLoading(false);
        if (!res?.data) {
          setErrorMsg(res.data);
          setErrorPopup(true);
          resumeTimer(true);
        } else {
          resetTimer();
          toggleTimer(false);
          updateTicketDetail(true);
        }
      })
      .catch((err) => {
        setIsLoading(false);

        setErrorMsg(err.data);
        setErrorPopup(true);
      });
  };

  const deleteSheet = (id) => {
    setIsLoading(true);
    let options = {
      ticketId: props.ticketdata.id,
      sheetId: id,
    };
    let token = localStorage.getItem("token");
    ticketDetails
      .delTimeSheet(token, options)
      .then((res) => {
        if (!res?.data) {
          setIsLoading(false);
          setErrorMsg(res.data);
          setErrorPopup(true);
        } else {
          updateTicketDetail(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);

        setErrorMsg(err.data);
        setErrorPopup(true);
      });
  };
  const resetTimer = () => {
    setTime({ hours: 0, minutes: 0, seconds: 0 });
    setIsRunning(false);
    setTimeResume(true);
    setTimeVal(() => "");
    setTimePop(false);
  };

  const resumeTimer = async (val) => {
    setTimeResume(!timeResume);

    let total_time =
      parseInt(time.hours) * 3600 +
      parseInt(time.minutes) * 60 +
      parseInt(time.seconds);

    let data;
    if (!val) {
      data = {
        isRunning: val,
        ticket_id: props.ticketdata.id,
        total_time: total_time,
      };
    } else {
      let currentTime = new Date();
      data = {
        end_time: currentTime,
        isRunning: val,
        ticket_id: props.ticketdata.id,
      };
    }

    await ticketDetails
      .resumeTimeSheet(localStorage.getItem("token"), data)
      .then((res) => {})
      .catch((err) => {
        console.log(err);
      });
  };
  const handleCancelPOP = () => {
    setTimePop(false);
    setTimeResume(true);
  };

  const updateTicketDetail = async (status) => {
    setIsLoading(true);
    await ticketDetails
      .getTicketDetail(localStorage.getItem("token"), props.ticketdata.id)
      .then((res) => {
        //setTicketData(res?.data?.data);
        if (!res?.data?.data) {
          setErrorPopup(true);
        }

        let ticket_Detail = res?.data?.data;
        if (ticket_Detail.messages === null) {
          ticket_Detail.messages = "";
        } else {
          ticket_Detail.messages.map((val) => {
            val.type = "message";
            return val;
          });
        }
        if (ticket_Detail.notes === null) {
          ticket_Detail.notes = "";
        } else {
          ticket_Detail.notes.map((val) => {
            val.type = "note";
            return val;
          });
        }
        if (ticket_Detail.logs === null) {
          ticket_Detail.logs = "";
        } else {
          ticket_Detail.logs.map((val) => {
            val.type = "logs";
            return val;
          });
        }
        let service_id = [];
        let space_id = [];
        let room_ids = [];
        if (
          ticket_Detail.site_access_services !== null &&
          ticket_Detail.site_access_services.length > 0
        ) {
          ticket_Detail.site_access_services.map((data) => {
            service_id.push(data.name);
          });
        }
        if (
          ticket_Detail.site_access_spaces !== null &&
          ticket_Detail.site_access_spaces.length > 0
        ) {
          ticket_Detail.site_access_spaces.map((data) => {
            space_id.push(data.name);
          });
        }
        if (
          props?.ticketdata?.site_access_visiting_rooms !== null &&
          props?.ticketdata?.site_access_visiting_rooms.length > 0
        ) {
          props.ticketdata.site_access_visiting_rooms.map((data) => {
            room_ids.push(data.name);
          });
        }
        setState({
          ticket_id: ticket_Detail.id,
          company: ticket_Detail.company,
          type: ticket_Detail.tickettype,
          service_id: ticket_Detail.serviceid,
          priority: ticket_Detail.priority,
          description: ticket_Detail.description,
          subject: ticket_Detail.subject,
          notes: [
            ...ticket_Detail.notes,
            ...ticket_Detail.messages,
            ...ticket_Detail.logs,
          ],
          ticket_status_id: ticket_Detail.ticket_statusid,
          ticket_status: ticket_Detail.ticket_status,
          messages: ticket_Detail.messages,
          tickettypeid: ticket_Detail.tickettypeid,
          team_id: ticket_Detail.teamid,
          assignto: ticket_Detail.assignto,
          designatedcompany: ticket_Detail.designatedcompany,
          timesheet: ticket_Detail.timesheet,
          customer_email: ticket_Detail.partner_email,
          special_visit_name: ticket_Detail.special_visit_area_name,
          service_ids: service_id.length > 0 ? service_id.toString() : "-",
          space_ids: space_id.length > 0 ? space_id.toString() : "-",
          site_access_visiting_rooms:
            room_ids.length > 0 ? room_ids.toString() : "-",
          ticket_subtype: ticket_Detail.ticket_subtype,
          ban: ticket_Detail.details.ban,
          use_remote_hands_pack: ticket_Detail.details.use_remote_hands_pack,
          remaining_hours: ticket_Detail.details.remaining_hours,
          excess_hours: ticket_Detail.details.excess_hours,
          scheduled_maintenance:
            ticket_Detail.details.requested_service_date_start +
            " - " +
            ticket_Detail.details.requested_service_date_end,
          scheduled_maintenance_start:
            ticket_Detail.details.requested_service_date_start,
          scheduled_maintenance_end:
            ticket_Detail.details.requested_service_date_end,
          service: ticket_Detail.details.service,
          space: ticket_Detail.details.space,
          details: ticket_Detail.details,
          ticket_sub_type_id: parseInt(props.ticketdata.ticket_subtype_id),
          services_id: {
            value: ticket_Detail?.details?.serviceid,
            label: ticket_Detail?.details?.service,
            space: ticket_Detail?.details?.space,
          },
          readiness_id: ticket_Detail.readiness_id,
          assignee: ticket_Detail.assignto,
          assignee_id: ticket_Detail.assignto_id,
          assignee_is_current_user: ticket_Detail.assignee_is_current_user,
          portal_code: ticket_Detail.portal_code,
        });

        setistypetrue(true);
        setDesignatedId(parseInt(props.ticketdata.designated_company_id));
        setUpdateTicketDetails({
          description: props.ticketdata.description,
          ticket_sub_type_id: parseInt(props.ticketdata.ticket_subtype_id),
          service_id:
            ticket_Detail?.details?.service_id != null
              ? [ticket_Detail?.details?.service_id]
              : null,
          designated_company_id: parseInt(
            props.ticketdata.designated_company_id
          ),
          priority_id: parseInt(props.ticketdata.priorityid),
          use_remote_hand_pack: props.ticketdata.details.use_remote_hands_pack,
        });

        if (status) {
        } else {
          Swal.fire("Updated Succesfully.");
        }
        // if (NonEditable.some((data) => data === props.ticketdata.ticket_status)) {
        //   setistypetrue(true);

        //   setUpdateTicketDetails({
        //     description: props.ticketdata.description
        //   });
        // } else {
        setistypetrue(true);
        setDesignatedId(parseInt(props.ticketdata.designated_company_id));
        setUpdateTicketDetails({
          description: ticket_Detail.ticketdata.description,
          ticket_sub_type_id: parseInt(
            ticket_Detail.ticketdata.ticket_subtype_id
          ),
          service_id: ticket_Detail.ticketdata.serviceid
            ? ticket_Detail.ticketdata.site_access_services.map((data) => ({
                id: data.id,
                label: data.name,
              }))
            : [],
          designated_company_id: parseInt(
            ticket_Detail.ticketdata.designated_company_id
          ),
          priority_id: parseInt(ticket_Detail.ticketdata.priorityid),
          use_remote_hand_pack:
            ticket_Detail.ticketdata.details.use_remote_hands_pack,
        });
        // }

        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  /* If current status is "Scheduled" then user should be allowed to update the 
  staus to previous stages that is "Pending Customer" and "In Progress" */
  const checkIfStatusIsScheduled = (data, current_stage_id) => {
    if (
      (data.stage_id == 26 || data.stage_id == 25) &&
      current_stage_id == 27
    ) {
      return false;
    } else {
      return true;
    }
  };
  const updateStage = async (stageId, selectedkey, data, current_stage_id) => {
    let currentStatus;
    ticketstatusname &&
      ticketstatusname.map((data, key, elements) => {
        if (state.ticket_status_id == data?.stage_id) {
          currentStatus = key;
        }
      });
    if (
      selectedkey <= currentStatus &&
      checkIfStatusIsScheduled(data, current_stage_id)
    ) {
      await Swal.fire("Error", "You can't select the previous stage.");
    } else {
      Swal.fire({
        text: t("common.confirm_status_update"),
        icon: "question",
        showCancelButton: true,
        confirmButtonText: t("common.yes"),
        cancelButtonText: t("common.no"),
        customClass: {
          cancelButton: "cancelBtn",
          confirmButton: "confirmBtn",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          setIsLoading(true);
          await ticketDetails
            .updateStage(
              localStorage.getItem("token"),
              props.ticketdata.id,
              stageId
            )
            .then((res) => {
              if (
                res?.data?.code !== undefined ||
                res?.data?.error !== undefined
              ) {
                setErrorMsg(res.data);
                setErrorPopup(true);
              } else {
                updateTicketDetail(false);
              }
            })

            .catch((err) => {
              console.log(err.error.response);
              setErrorMsg(err.error.response.data.error);
              setErrorPopup(true);
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
      });
    }
  };

  useEffect(() => {
    getAssignees();
  }, []);
  const getAssignees = async () => {
    setIsLoading(true);
    await provisioningService
      .getAssignees(localStorage.getItem("token"))
      .then((res) => {
        if (res?.data?.code !== undefined || res?.data?.error !== undefined) {
          console.log("here");
          setErrorMsg(res.data);
          console.log("here");
          setErrorPopup(true);
          setIsLoading(false);

          //setStatusChange(true)
        } else {
          setAssignees(res.data.data);
          setFilteredAssignees(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const HandleSearch = (val) => {
    setSearchValue(val);
    setFilteredAssignees(filterByValue(assignees, val));
  };
  const handleDiscard = () => {
    setToggleEdit(false);
    setAllSpace([]);
    setUpdateTicketDetails((prev) => ({
      ...prev,
      description: props.ticketdata.description,
    }));
  };
  const filterByValue = (array, value) => {
    return array.filter(
      (data) =>
        JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  };

  const assignTaskTo = async (email) => {
    setIsLoading(true);
    await ticketDetails
      .assignToMe(localStorage.getItem("token"), props.ticketdata.id, {
        email: email,
      })
      .then((res) => {
        console.log("test", res.data);
        if (res?.data?.code !== undefined || res?.data?.error !== undefined) {
          console.log("here");
          setErrorMsg(res.data);
          setErrorPopup(true);
          setIsLoading(false);
        } else {
          updateTicketDetail(false);
          // Swal.fire("Updated Successfully.");
          // props.handleModalPopupOnClick(props.sitename, props.ticketdata.id)
        }
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };
  const unassignTaskTo = async () => {
    setIsLoading(true);
    const assignee = assignees.filter(
      (assignee) => assignee.id === state.assignee_id
    );
    const email = assignee[0].email;
    await ticketDetails
      .unassignFromMe(localStorage.getItem("token"), props.ticketdata.id, {
        email: email,
      })
      .then((res) => {
        if (res?.data?.code !== undefined || res?.data?.error !== undefined) {
          console.log("here");
          setErrorMsg(res.data);
          setErrorPopup(true);
          setIsLoading(false);
        } else {
          updateTicketDetail(false);
          // Swal.fire("Updated Successfully.");
          // props.handleModalPopupOnClick(props.sitename, props.ticketdata.id)
        }
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };

  const sendMessage = async () => {
    setIsLoading(true);
    if (active == "message") {
      await ticketDetails
        .createMessage(localStorage.getItem("token"), props.ticketdata.id, {
          message: message,
          attachments: fileData,
        })
        .then((res) => {
          if (res?.data?.code !== undefined || res?.data?.error !== undefined) {
            console.log("here");
            setErrorMsg(res.data);
            setErrorPopup(true);
            //setStatusChange(true)
          } else {
            // setState({...state, notes:[...state.notes,
            //     {
            //         author_id:"Rolland Wong",
            //         body:"<p>"+message+"</p>",
            //         changedDate:"15-Feb-2023 15:21 HKT",
            //         date:"2023-02-15 07:21:48",
            //         id:331642}]})
            updateTicketDetail(false);
            // Swal.fire("Updated Succesfully.");
            setMessage("");
            setFileData([]);
            setShowAttachment([]);
            //props.handleModalPopupOnClick(props.sitename, props.ticketdata.id)
          }
        })
        .catch((err) => {
          console.log(err.error.response);
          setErrorMsg(err.error.response.data.error);
          setErrorPopup(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      await ticketDetails
        .createNote(localStorage.getItem("token"), props.ticketdata.id, {
          message: message,
          attachments: fileData,
        })
        .then((res) => {
          if (res?.data?.code !== undefined || res?.data?.error !== undefined) {
            console.log("here");
            setErrorMsg(res.data);
            setErrorPopup(true);
            //setStatusChange(true)
          } else {
            updateTicketDetail(false);
            //  Swal.fire("Updated Succesfully.");
            setMessage("");
            setFileData([]);
            setShowAttachment([]);
            // props.handleModalPopupOnClick(props.sitename, props.ticketdata.id)
          }
        })
        .catch((err) => {
          console.log(err.error.response);
          setErrorMsg(err.error.response.data.error);
          setErrorPopup(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const closeModal = () => {
    props.setShow(false);
    if (props.handleClose) {
      props.handleClose();
    }
  };
  const handleExitbutton = async () => {
    await Swal.fire({
      title: "Are you sure?",
      text: "Any changes made will not be saved.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      customClass: {
        cancelButton: "cancelBtn",
        confirmButton: "confirmBtn",
      },
    }).then((res) => {
      if (res.isConfirmed) {
        closeModal();
      }
    });
  };
  const convertDate = (startTime) => {
    let end = moment(new Date());
    var duration = moment.duration(end.diff(startTime));
    var totalTime = duration.asHours();
    if (totalTime > 24) {
      totalTime = Math.floor(duration.asDays()) + " day(s)";
    } else {
      totalTime = Math.floor(totalTime) + " hour(s)";
    }

    return totalTime;
  };
  const ticketStatus = async (id, typeid) => {
    setIsTicketStatusLoading(true);
    await ticketDetails
      .getTicketStatus(localStorage.getItem("token"), id, typeid)
      .then((res) => {
        setDisplayTitle(res?.data?.data[0]?.TicketTypeName);
        setTicketStatusName(res?.data?.data);
        setIsTicketStatusLoading(false);

        // if(res?.data?.ticketType?.name.slice(-1) === "s"){
        //     setDisplayTitle(res?.data?.ticketType?.name.slice(0,-1))
        // }else{
        //     setDisplayTitle(res?.data?.ticketType?.name)
        // }
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
        setIsTicketStatusLoading(false);
      })
      .finally(() => setIsTicketStatusLoading(false));
  };
  const addTableRows = () => {
    const rowsInput = {
      date: "",
      employee: contextStore.getAuth.name,
      desc: "",
      startTime: "",
      endtime: "",
      duration: "",
    };
    setRowsData([...rowsData, rowsInput]);
  };
  const deleteTableRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
  };

  const handleChange = (index, evnt) => {
    let { name, value } = evnt.target;
    if (name === "startTime") {
      setStartTime(value);
    }
    if (name === "endtime") {
      if (value < startTime) {
        setErrorMsg("End time should be greater than Start time.");
        setErrorPopup(true);
        value = "";
      } else {
        setEndTime(value);
      }
    }
    // if(name === 'endtime'){
    //     if(value < startTime){
    //         setEndTime(moment(value).add(24, "hours"));
    //     }
    // }

    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    setRowsData(rowsInput);
  };
  const formattedTime = (inputTotalHours) => {
    const inputTotalSeconds = inputTotalHours * 3600;

    const hours = Math.floor(inputTotalSeconds / 3600);

    const minutes = Math.floor((inputTotalSeconds % 3600) / 60);

    return `${hours ? hours : "00"} : ${minutes ? minutes : "00"} hours`;
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    encodeFileToBase64(file, (err, base64) => {
      setPayload({
        file_name: file.name,
        content: base64,
      });

      attechments({
        file_name: file.name,
        content: base64,
      });
    });
  };

  const attechments = async (imagedata) => {
    setIsLoading(true);

    await ticketDetails
      .addAttechments(
        localStorage.getItem("token"),
        props.ticketdata.id,
        imagedata
      )
      .then((res) => {
        console.log(res);
        if (res?.data?.code !== undefined || res?.data?.error !== undefined) {
          setErrorMsg(res.data);
          setErrorPopup(true);
        } else {
          let Fdata = res.data.data.id;
          if (active === "log" || active === "message") {
            setShowAttachment((prev) => {
              let file = { name: imagedata.file_name, id: res.data.data.id };
              return [...prev, file];
            });
            setFileData([...fileData, Fdata]);
          }
        }
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally((done) => setIsLoading(false));
  };

  const getAttachment = async (attachmentId, fileName) => {
    setIsLoading(true);
    await ticketDetails
      .getAttachment(
        localStorage.getItem("token"),
        props.ticketdata.id,
        attachmentId
      )
      .then((res) => {
        if (res?.data.data === undefined) {
          // console.log("here")
          setErrorPopup(true);
          setErrorMsg(res.data);
          //setStatusChange(true)
        } else {
          // setToggleEdit(false);
          // updateTicketDetail(false);
          handleDownload(res.data.data.data, fileName);
          // props.handleModalPopupOnClick(props.sitename, props.ticketdata.id)
        }
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const encodeFileToBase64 = (file, callback) => {
    const reader = new FileReader();

    reader.onload = () => {
      const base64Data = reader.result.split(",")[1];
      callback(null, base64Data);
    };

    reader.onerror = (error) => {
      callback(error);
    };

    reader.readAsDataURL(file);
  };
  const handleDownload = (base64String, fileName, filetype) => {
    // Convert the base64 string to a Blob
    const byteCharacters = atob(base64String);
    const byteArrays = new Uint8Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteArrays[i] = byteCharacters.charCodeAt(i);
    }
    const blob = new Blob([byteArrays]);

    // Create a URL for the Blob
    const blobUrl = URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement("a");
    link.href = blobUrl;
    link.target = "_blank"; // Open the link in a new tab/window
    link.textContent = "Click here to download"; // Set the link text

    // Append the link to the document
    link.download = fileName;

    link.click();

    URL.revokeObjectURL(blobUrl);

    // const decodedData = atob(base64String);
    // console.log(decodedData)
    // const blob = new Blob([decodedData], { type: 'application/octet-stream' });

    // const downloadUrl = URL.createObjectURL(blob);

    // const link = document.createElement('a');
    // link.href = downloadUrl;
    // link.download = fileName;

    // link.click();

    // URL.revokeObjectURL(downloadUrl);
  };
  const getMaintenanceModeStatus = () => {
    const status = contextStore.getMaintenanceModeStatus;
    if (status && status?.status_current == 3) {
      return false;
    } else {
      return true;
    }
  };
  const checkPermission = (permissionName) => {
    if (permissionName) {
      if (permissionName === "Maintenance Status") {
        return getMaintenanceModeStatus();
      } else {
        const isPermitted = props.permissions.filter(
          (el) => el.name == permissionName && el.is_allowed == 1
        );

        if (isPermitted.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    }
    return false;
  };
  const updateTicket = async () => {
    // console.log(updateTicketDetails);
    setIsLoading(true);
    await ticketDetails
      .updateTicketDetails(
        localStorage.getItem("token"),
        props.ticketdata.id,
        props.ticketdata.tickettypeid,
        updateTicketDetails
      )
      .then((res) => {
        console.log("here", res);
        if (res?.data?.code !== undefined || res?.data?.error !== undefined) {
          // console.log("here")
          setErrorMsg(res.data);
          setErrorPopup(true);

          //setStatusChange(true)
        } else {
          setToggleEdit(false);
          // updateTicketDetail(false);
          props.getTicketDetail(
            props.ticketdata.operatingsite,
            props.ticketdata?.id,
            props.ticketdata?.tickettypeid,
            props.ticketdata?.ticket_sub_type_name,
            props.ticketdata?.tickettype
          );
          Swal.fire({
            title: "Updated Successfully",
            icon: "success",
            customClass: {
              confirmButton: "confirmBtn",
            },
          });
          setIsUpdateDescriptionList(true);

          // props.handleModalPopupOnClick(props.sitename, props.ticketdata.id)
        }
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDelAttach = async (i) => {
    setIsLoading(true);
    try {
      const delData = await ticketDetails.delAttechments(
        localStorage.getItem("token"),
        state.ticket_id,
        i
      );
      if (delData) {
        updateTicketDetail();
        setShowAttachment((prev) => [...prev.filter((data) => data.id !== i)]);
        setFileData((prev) => prev.filter((data) => data !== i));
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const replace = (description) => {
    let result = description.replace(/<div>/g, " \n");
    result = result.replace(/<\/div>/g, "");
    result = result.replace(/<br \/>/g, "\n");
    result = result.replace(/<br>/g, "\n");
    result = result.replace(/&nbsp;/g, " ");
    console.log(result);
    return result;
  };
  const separateArrayDateWise = (history) => {
    const data = history.sort(function (a, b) {
      var c = new Date(a.date);
      var d = new Date(b.date);
      return d - c;
    });
    // Use reduce to separate array based on date
    const separatedByDate = data.reduce((acc, obj) => {
      const date = moment(obj.date).format("MMMM DD, YYYY");
      // If the date key doesn't exist in the accumulator, create an array for it
      if (!acc[date]) {
        acc[date] = [];
      }

      // Push the current object to the array corresponding to its date
      acc[date].push(obj);

      return acc;
    }, {});

    const mappedData = Object.entries(separatedByDate).map(([date, items]) => ({
      date,
      items,
    }));
    return mappedData;
  };
  const getTime = (date) => {
    let result = moment(date).fromNow();
    const now = moment();
    const days = now.diff(date, "days");
    const weeks = now.diff(date, "weeks");
    if (days >= 7) {
      if (days <= 13) {
        result = `a week ago`;
      } else if (days > 13 && days <= 25) {
        result = `${weeks} weeks ago`;
      }
    }
    return result;
  };

  return (
    <>
      {timePop && (
        <TimerPop
          handleCancelPOP={handleCancelPOP}
          handleSaveTimer={handleSaveTimer}
          timeVal={timeVal}
          setTimePop={setTimePop}
        />
      )}
      <div
        class="modal show bd-example-modal-lg"
        id="main-model"
        style={{ display: "block" }} /*onClick={()=> closeModal()}*/
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <Loading
          loading={isLoading}
          background="rgba(0, 0, 0, 0.4)"
          loaderColor="rgb(248, 153, 3)"
        />
        <div class="modal-dialog modal-xl lay-2-3pop" id="capture">
          <div class="modal-content fault-content" id="content-area">
            <FirstRow
              state={state}
              handleChange={readinessChange}
              isEdit={toggleEdit}
              site={props?.sitename || ""}
              checkPermission={checkPermission}
              priority={priority}
              updateTicketDetail={updateTicketDetails}
              isTypeTrue={istypetrue}
              setUpdateTicketDetails={setUpdateTicketDetails}
              handleExit={handleExitbutton}
            ></FirstRow>
            <SecondRow
              title={displaytitle ? displaytitle : ""}
              type={"(Ticket Type - " + state?.type + ")"}
              refresh={updateTicketDetail}
              handlePrint={handlePrint}
              checkPermission={checkPermission}
            ></SecondRow>

            <div>
              <div class="paging_tab">
                {ticketstatusname && !isTicketStatusLoading ? (
                  ticketstatusname.map((data, key, elements) => (
                    // console.log(elements[key].stage_id);
                    <div class="item3" key={key}>
                      <button
                        className={
                          state.ticket_status_id == data?.stage_id
                            ? `btn btn-light tab-button-style tab-btn3 btstyle${key} t-menu-active`
                            : `btn btn-light tab-button-style tab-btn3 btstyle${key}`
                        }
                        onClick={() => {
                          if (
                            checkPermission("Change Ticket Status") &&
                            checkPermission("Maintenance Status")
                          ) {
                            updateStage(
                              data?.stage_id,
                              key,
                              data,
                              state.ticket_status_id
                            );
                          }
                        }}
                      >
                        {data?.stage_name}{" "}
                      </button>
                      {state.ticket_status_id == data?.stage_id ? (
                        <img
                          src={
                            elements[0]?.stage_id == state.ticket_status_id
                              ? ""
                              : "img/second.svg"
                          }
                          alt=""
                          className="imgstyle-ststus"
                        />
                      ) : elements[key - 1]?.stage_id ==
                        state.ticket_status_id ? (
                        <img
                          src="img/first.svg"
                          alt=""
                          className="imgstyle-ststus"
                        />
                      ) : (
                        <img
                          src="img/third.svg"
                          alt=""
                          className="imgstyle-ststus"
                        />
                      )}
                    </div>
                  ))
                ) : (
                  <div className="statusLoadingIndicator">
                    {" "}
                    <CircularProgress
                      size={30}
                      style={{ marginRight: "1rem" }}
                    />{" "}
                    Loading status...
                  </div>
                )}
              </div>
            </div>
            <div class="modal-body" style={{ paddingTop: "1rem" }}>
              <div class="" style={{ height: "cal(100%-0%)" }}>
                <div class="">
                  <div class="main_fault">
                    <div className="card-body" style={{ overflow: "hidden" }}>
                      <div class="card-details">
                        <div class="row">
                          <DescriptionAndAssignSection
                            title={
                              "(#" +
                                state?.ticket_id +
                                ")" +
                                " " +
                                state?.subject || ""
                            }
                            assigneeName={
                              state.assignto
                                ? state.assignto === "OPS Portal Bot"
                                  ? `Ops Portal Bot (${state.activity_user})`
                                  : state.assignto
                                : "N/A"
                            }
                            checkPermission={checkPermission}
                            assigneeIsCurrentUser={
                              state.assignee_is_current_user
                            }
                            assignees={assignees}
                            handleAssign={assignTaskTo}
                            handleUnassign={unassignTaskTo}
                            assigneeId={state.assignee_id}
                            isEdit={toggleEdit}
                            setIsEdit={setToggleEdit}
                            description={
                              toggleEdit
                                ? updateTicketDetails.description.replace(
                                    /(<([^>]+)>)/gi,
                                    ""
                                  )
                                : state?.description.replace(
                                    /(<([^>]+)>)/gi,
                                    ""
                                  ) || ""
                            }
                            handleChangeDescription={handleChangeDescription}
                            handleUpdate={updateTicket}
                            handleDiscard={handleDiscard}
                            draftDescription={draftDescription}
                          ></DescriptionAndAssignSection>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <label
                              style={{ fontSize: "0.875rem", color: "#000000" }}
                            >
                              Customer :
                            </label>
                            <b class="ticket-data2">{state?.company || "-"}</b>
                          </div>
                          <div class="col-md-6">
                            <div className="d-flex">
                              <label
                                style={{
                                  fontSize: "0.875rem",
                                  color: "#000000",
                                }}
                              >
                                Ticket Sub-Type :
                              </label>
                              {toggleEdit &&
                              istypetrue &&
                              checkPermission("Edit Details") &&
                              checkPermission("Maintenance Status") ? (
                                <div>
                                  <div style={{ alignItems: "center" }}>
                                    <div>
                                      <select
                                        style={{
                                          height: "35px",
                                          borderColor: "#ff7a00",
                                          boxShadow: "0 0 0 1px #ff7a00",
                                        }}
                                        defaultValue={parseInt(
                                          state?.ticket_sub_type_id
                                        )}
                                        onChange={(event) =>
                                          setUpdateTicketDetails((prev) => ({
                                            ...prev,
                                            ticket_sub_type_id: parseInt(
                                              event.target.value
                                            ),
                                          }))
                                        }
                                      >
                                        {ticketTypes.map((option) => (
                                          <option
                                            key={option.id}
                                            value={option.id}
                                          >
                                            {option.name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <b class="ticket-data2">
                                  {state?.ticket_subtype || "-"}
                                </b>
                              )}
                            </div>
                          </div>
                          <div class="col-md-6">
                            <label
                              style={{ fontSize: "0.875rem", color: "#000000" }}
                            >
                              Customer Email :
                            </label>
                            <b class="ticket-data2">
                              {state?.customer_email || "-"}
                            </b>
                          </div>
                          <div class="col-md-6">
                            <label
                              style={{ fontSize: "0.875rem", color: "#000000" }}
                            >
                              BAN ID :
                            </label>
                            <b class="ticket-data2">{state?.ban || "-"}</b>
                          </div>
                          <div class="col-md-6">
                            <label
                              style={{ fontSize: "0.875rem", color: "#000000" }}
                            >
                              Designated Company :
                            </label>
                            <b class="ticket-data2">
                              {state?.designatedcompany || "-"}
                            </b>
                          </div>
                          <div class="col-md-4">
                            <label
                              style={{ fontSize: "0.875rem", color: "#000000" }}
                            >
                              Use Remote Hand Pack
                            </label>{" "}
                            <span>
                              {" "}
                              {toggleEdit &&
                              istypetrue &&
                              checkPermission("Edit Details") &&
                              checkPermission("Maintenance Status") ? (
                                <input
                                  type="checkbox"
                                  checked={
                                    updateTicketDetails?.use_remote_hand_pack
                                  }
                                  onClick={() =>
                                    setUpdateTicketDetails((prev) => ({
                                      ...prev,
                                      use_remote_hand_pack: !prev.use_remote_hand_pack,
                                    }))
                                  }
                                />
                              ) : (
                                <input
                                  type="checkbox"
                                  checked={state?.use_remote_hands_pack}
                                  disabled
                                />
                              )}
                              {/* {state?.use_remote_hands_pack ? (
                                <input type="checkbox" checked disabled />
                              ) : (
                                <input type="checkbox" disabled />
                              )} */}
                            </span>
                          </div>
                          {state?.portal_code ? (
                            <div class="col-md-12">
                              <label
                                style={{
                                  fontSize: "0.875rem",
                                  color: "#000000",
                                }}
                              >
                                Portal Code :
                              </label>
                              <b class="ticket-data2">
                                {state?.portal_code || "-"}
                              </b>
                            </div>
                          ) : (
                            " "
                          )}
                          <div class="col-md-12">
                            <div className="d-flex">
                              <div>
                                {" "}
                                <label
                                  style={{
                                    fontSize: "0.875rem",
                                    color: "#000000",
                                  }}
                                >
                                  Service ID :
                                </label>
                              </div>
                              <div>
                                {toggleEdit &&
                                istypetrue &&
                                checkPermission("Edit Details") &&
                                checkPermission("Maintenance Status") ? (
                                  <SingleSelect
                                    customStyles={customStyles}
                                    options={services}
                                    setAllSpace={setAllSpace}
                                    setUpdateTicketDetails={
                                      setUpdateTicketDetails
                                    }
                                    value={state?.services_id}
                                  />
                                ) : (
                                  <b class="ticket-data2">
                                    {state?.service || "-"}
                                  </b>
                                )}
                              </div>
                            </div>
                          </div>
                          <div class="col-md-12">
                            <label
                              style={{ fontSize: "0.875rem", color: "#000000" }}
                            >
                              Space ID :
                            </label>
                            <b class="ticket-data2">
                              {" "}
                              {toggleEdit &&
                              istypetrue &&
                              checkPermission("Edit Details") &&
                              checkPermission("Maintenance Status")
                                ? allSpace.join(",")
                                : state?.space || "-"}
                            </b>
                          </div>
                          <div class="col-md-6">
                            <label
                              style={{ fontSize: "0.875rem", color: "#000000" }}
                            >
                              Remaining Hours :
                            </label>
                            <b class="ticket-data2">
                              {formattedTime(state?.remaining_hours) ||
                                "0 hour(s)"}
                            </b>
                          </div>
                          <div class="col-md-6">
                            <label
                              style={{ fontSize: "0.875rem", color: "#000000" }}
                            >
                              Excess hours :
                            </label>
                            <b class="ticket-data2">
                              {formattedTime(state?.excess_hours) ||
                                "0 hour(s)"}
                            </b>
                          </div>
                          <div class="col-md-12">
                            <div className="d-flex">
                              <label
                                style={{
                                  fontSize: "0.875rem",
                                  color: "#000000",
                                }}
                              >
                                Scheduled Maintenance Start Time :
                              </label>
                              {toggleEdit &&
                              istypetrue &&
                              checkPermission("Edit Details") &&
                              checkPermission("Maintenance Status") ? (
                                <div className="d-flex gap-2">
                                  <DateTime
                                    setUpdateTicketDetails={
                                      setUpdateTicketDetails
                                    }
                                    id={"start"}
                                    value={state?.scheduled_maintenance_start}
                                  />
                                  <DateTime
                                    setUpdateTicketDetails={
                                      setUpdateTicketDetails
                                    }
                                    id={"end"}
                                    value={
                                      updateTicketDetails.end_date
                                        ? updateTicketDetails.end_date
                                        : state?.scheduled_maintenance_end
                                    }
                                    compareDate={
                                      updateTicketDetails.start_date
                                        ? updateTicketDetails.start_date
                                        : state?.scheduled_maintenance_start
                                    }
                                  />
                                </div>
                              ) : (
                                <b class="ticket-data2">
                                  {state?.scheduled_maintenance || "-"}
                                </b>
                              )}
                            </div>
                          </div>
                        </div>
                        <div style={{ marginBottom: "12rem" }}>
                          <div class="row pt-2">
                            <div class="col-md-12">
                              <div style={{ position: "relative" }}>
                                <div class="btnstyle">
                                  <div class="btn-group">
                                    {checkPermission("Log Notes") && (
                                      <a
                                        href="#"
                                        className={`btn msg-button ${
                                          active == "log" ? "bactive" : ""
                                        }`}
                                        onClick={() => {
                                          setActive("log");
                                        }}
                                      >
                                        Log Note
                                      </a>
                                    )}
                                    {checkPermission("Send Message") && (
                                      <a
                                        href="#"
                                        className={`btn msg-button ${
                                          active == "message" ? "bactive" : ""
                                        }`}
                                        onClick={() => {
                                          setActive("message");
                                        }}
                                      >
                                        Send Message
                                      </a>
                                    )}
                                    {checkPermission("Modify Timesheet") && (
                                      <a
                                        href="#"
                                        className={`btn msg-button ${
                                          active == "time" ? "bactive" : ""
                                        }`}
                                        onClick={() => {
                                          setActive("time");
                                        }}
                                      >
                                        Timesheet
                                      </a>
                                    )}

                                    {active == "time" &&
                                      checkPermission("Modify Timesheet") &&
                                      checkPermission("Maintenance Status") && (
                                        <a href="#">
                                          {isRunning ? (
                                            <div className="timer-2">
                                              <div className="btn-grp-time">
                                                <div>
                                                  {" "}
                                                  <button
                                                    className="stop-button"
                                                    onClick={() =>
                                                      handleStopTimer()
                                                    }
                                                  >
                                                    Stop
                                                  </button>
                                                </div>
                                                <div>
                                                  <button
                                                    className="resume-button"
                                                    onClick={() =>
                                                      resumeTimer(!timeResume)
                                                    }
                                                  >
                                                    {timeResume
                                                      ? "Pause"
                                                      : "Resume"}
                                                  </button>
                                                </div>
                                              </div>
                                              <div>
                                                <div className="time-count">
                                                  {String(time.hours).padStart(
                                                    2,
                                                    "0"
                                                  )}
                                                  :
                                                  {String(
                                                    time.minutes
                                                  ).padStart(2, "0")}
                                                  :
                                                  {String(
                                                    time.seconds
                                                  ).padStart(2, "0")}
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            <div className="timer">
                                              <button
                                                className="start-button"
                                                onClick={() =>
                                                  toggleTimer(true)
                                                }
                                              >
                                                Start
                                              </button>
                                            </div>
                                          )}
                                        </a>
                                      )}
                                    <a
                                      href="#"
                                      className={`btn msg-button ${
                                        active == "history" ? "bactive" : ""
                                      }`}
                                      onClick={() => {
                                        setActive("history");
                                      }}
                                    >
                                      History
                                    </a>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "baseline",
                                    }}
                                  >
                                    {checkPermission("Follow") &&
                                      checkPermission("Maintenance Status") && (
                                        <a
                                          href="#"
                                          onClick={follows ? unfollow : follow}
                                          onMouseOver={() => {
                                            setButtonText(
                                              follows ? "Unfollow" : "Follow"
                                            );
                                            setIcon(
                                              follows ? "fas fa-close" : ""
                                            );
                                            setButtonStyle({
                                              color: follows ? "red" : "black",
                                            });
                                          }}
                                          onMouseOut={() => {
                                            setButtonText(
                                              follows ? "Following" : "Follow"
                                            );
                                            setIcon(
                                              follows ? "fas fa-check" : ""
                                            );
                                            setButtonStyle({
                                              color: follows
                                                ? "#2cc970"
                                                : "black",
                                            });
                                          }}
                                        >
                                          <span
                                            className={icon}
                                            style={buttonStyle}
                                          ></span>{" "}
                                          <b style={buttonStyle}>
                                            {buttonText}
                                          </b>
                                        </a>
                                      )}

                                    <ul className="navbar-nav header-right">
                                      <li className="nav-item dropdown header-profile">
                                        <a
                                          className="nav-link"
                                          href="#"
                                          role="button"
                                          data-bs-toggle="dropdown"
                                          style={{
                                            display: "flex",
                                            alignItems: "baseline",
                                          }}
                                        >
                                          <i
                                            className="fas fa-user"
                                            id="don"
                                            style={{ color: "#228683" }}
                                          ></i>{" "}
                                          <span
                                            className="ms-1"
                                            style={{
                                              color: "#228683",
                                              fontWeight: "bold",
                                            }}
                                          >
                                            {follower.length}{" "}
                                          </span>
                                        </a>
                                        <div
                                          className="dropdown-menu dropdown-menu-end"
                                          style={{ width: "max-content" }}
                                          id="follwerDropdown"
                                        >
                                          {checkPermission("Follow") &&
                                            checkPermission(
                                              "Maintenance Status"
                                            ) && (
                                              <a
                                                href="#"
                                                className="dropdown-item ai-icon"
                                                onClick={() =>
                                                  setAddFollower(true)
                                                }
                                              >
                                                <span className="">
                                                  Add Followers{" "}
                                                </span>
                                              </a>
                                            )}
                                          {checkPermission("Follow") &&
                                            checkPermission(
                                              "Maintenance Status"
                                            ) && <hr />}
                                          <table>
                                            <thead>
                                              {follower &&
                                                follower.map((data, key) => (
                                                  <tr className="">
                                                    <th>
                                                      <img
                                                        src="images/Icon.svg"
                                                        style={{
                                                          width: "18px",
                                                          height: "18px",
                                                        }}
                                                      />{" "}
                                                    </th>
                                                    <th>
                                                      <span className="">
                                                        {data.name}{" "}
                                                      </span>{" "}
                                                    </th>
                                                    {/* <th style={{cursor:"pointer"}}> <i className="fas fa-pencil" id="don"></i></th> */}
                                                    {checkPermission(
                                                      "Follow"
                                                    ) &&
                                                      checkPermission(
                                                        "Maintenance Status"
                                                      ) && (
                                                        <th
                                                          style={{
                                                            cursor: "pointer",
                                                          }}
                                                        >
                                                          <img
                                                            src="images/fclose.svg"
                                                            alt=""
                                                            style={{
                                                              width: "12px",
                                                              height: "12px",
                                                            }}
                                                            onClick={() =>
                                                              delfollower(
                                                                data.contact_id
                                                              )
                                                            }
                                                          />
                                                        </th>
                                                      )}
                                                  </tr>
                                                ))}
                                            </thead>
                                          </table>
                                        </div>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                {active != "time" && active != "history" && (
                                  <LogNoteAndMessage
                                    checkPermission={checkPermission}
                                    setMessage={setMessage}
                                    message={message}
                                    handleImageClick={handleImageClick}
                                    fileInputRef={fileInputRef}
                                    handleFileChange={handleFileChange}
                                    sendMessage={sendMessage}
                                    active={active}
                                    history={
                                      active == "message"
                                        ? state?.messages
                                        : state?.notes.length > 0
                                        ? state?.notes.filter(
                                            (el) => el.type == "note"
                                          )
                                        : []
                                    }
                                    separateArrayDateWise={
                                      separateArrayDateWise
                                    }
                                    getTime={getTime}
                                    getAttachment={getAttachment}
                                    handleDelAttach={handleDelAttach}
                                    active={active}
                                  ></LogNoteAndMessage>
                                )}

                                {active == "time" && (
                                  <RemoteHandTimesheet
                                    checkPermission={checkPermission}
                                    timesheet={state.timesheet}
                                    details={state.details}
                                    deleteSheet={deleteSheet}
                                  ></RemoteHandTimesheet>
                                )}

                                {active == "history" && (
                                  <TicketHistory
                                    notes={state.notes}
                                    separateArrayDateWise={
                                      separateArrayDateWise
                                    }
                                    getTime={getTime}
                                    getAttachment={getAttachment}
                                    handleDelAttach={handleDelAttach}
                                  ></TicketHistory>
                                )}
                              </div>
                            </div>
                          </div>
                          {showAttachment.length > 0 && (
                            <div className="show-Attach-outer">
                              {" "}
                              <div className="attach-outer">Attachments</div>
                              <div className="file-name-box">
                                {showAttachment.map((val, index) => {
                                  return (
                                    <div className="down-file" key={index}>
                                      <div>{val.name}</div>{" "}
                                      <div className="down-file-inner">
                                        <div>
                                          <img
                                            src="images/fclose.svg"
                                            onClick={() =>
                                              handleDelAttach(val.id)
                                            }
                                            width={8}
                                            alt=""
                                            style={{ paddingTop: "2px" }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {addfollower && (
              <AddFollowers
                show={addfollower}
                setShow={setAddFollower}
                ticketId={props.ticketdata.id}
                getFollowList={getFollowList}
              />
            )}
            {errorpopup && (
              <ErrorModel
                show={errorpopup}
                setShow={setErrorPopup}
                errorMsg={errorMsg}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default RemoteHandPopup;
