import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Navigation = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const activeClass = (route) => {
    if (location.pathname === route) {
      return "plink active";
    }
    if (typeof route === "object") {
      let classType = "plink";
      route.map((r) => {
        if (location.pathname === r) {
          classType = "plink active";
        }
      });

      return classType;
    }
    return "plink";
  };

  return (
    <div className="col-xl-1">
      <div className="leftside">
        <p>
          <Link
            className={activeClass("/setting-maintenance-ops-portal")}
            to="/setting-maintenance-boss"
          >
            {t("maintenance.ops_portal")}{" "}
          </Link>
        </p>
        <p>
          <Link
            className={activeClass("/setting-maintenance-boss")}
            to="/setting-maintenance-boss"
          >
            {t("maintenance.boss")}{" "}
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Navigation;
