import { axiosWithToken as axios } from "utils/axios";
import { LogoutOnSessionExpire } from "common/helpers";

class TicketServices {
  /*
	|-------------------------------------------------------------------------------
	| 	get Ticket Service
	|-------------------------------------------------------------------------------
	*/
  // async index(token, data, offset) {
  //   return await axios(token).get(
  //     `Ticket?country_id=${data?.country_id || ''}&data_center=${
  //       data?.data_center_id || ''
  //     }&offset=${data?.offset}&tickettypeid=${data?.tickettypeid}&ticketsubtype=${
  //       data?.tsubtype || ''
  //     }&searchVal=${data?.search || ''}&status=${data?.status || ''}`
  //   );
  // }

  async index(token, data, offset) {
    return await axios(token).get(
      `Ticket?country_id=${data?.country_id || ""}&data_center=${
        data?.data_center_id || ""
      }&offset=${data?.offset}&tickettypeid=${
        data?.tickettypeid
      }&ticketsubtype=${data?.tsubtype || ""}&searchVal=${
        data?.search || ""
      }&status=${data?.status || ""}&page=${data?.page || 1}`
    );
  }

  // Get tickets new API
  async getTicketsList(token, body) {
    return await axios(token).post(`getTickets`, body);
  }
  // Get tickets by contact id
  async getTicketsListByContactId(token, contactId, body) {
    return await axios(token).post(
      `getTicketsByContactId?contactId=${contactId}`,
      body
    );
  }
  /*
	|-------------------------------------------------------------------------------
	| 	get Ticket chart data service
	|-------------------------------------------------------------------------------
	*/
  async getChartData(token, data) {
    return await axios(token).get(
      `AllTicketData?country_id=${data?.country_id || ""}&data_center=${
        data?.data_center_id || ""
      }&tickettypeid=${data?.tickettypeid}`
    );
  }

  async getTicketsChartData(token, data) {
    return await axios(token).get(
      `getTicketChart?country_id=${data?.country_id || ""}&data_center=${
        data?.data_center_id || ""
      }&status=${data?.status || ""}&tickettypeid=${data?.tickettypeid}`
    );
  }

  // Get Ticket list for Accounts tab

  async getTicketList(token, query) {
    return await axios(token).get(`getTicketsList?${query}`);
  }

  // Get Ticket types

  async getTicketTypes(token) {
    return await axios(token).get(`getTicketTypes`);
  }

  /// add follower
  async subscribe(token, ticketId) {
    return await axios(token).post(`addFollower?ticketId=` + ticketId);
  }
  // unFollower
  async unsubscribe(token, ticketId) {
    return await axios(token).post(`unsubscribe?ticketId=` + ticketId);
  }
  // Invite Follower
  async inviteFollower(token, ticketId, data) {
    return await axios(token).post(`inviteFollower?ticketId=` + ticketId, data);
  }
  // get Follower
  async getFollower(token, ticketId) {
    return await axios(token).get(`getFollower?ticketId=` + ticketId);
  }
  // Delete Follower
  async removeFollower(token, ticketId, contactId) {
    return await axios(token).post(
      `removeFollower?ticketId=` + ticketId + `&&contactId=` + contactId
    );
  }
  // update Readiness
  async updateReadiness(token, ticketId, readiness) {
    return await axios(token).put(`updateReadiness?ticketId=` + ticketId, {
      readiness: readiness,
    });
  }

  // Get favourites list
  async getFavouritesList(token) {
    return await axios(token).get(`favourites`);
  }

  // Add favourite
  async addFavourite(token, favourite) {
    return await axios(token).post(`favourites`, favourite);
  }

  // Delete favourite
  async deleteFavourite(token, id) {
    return await axios(token).delete(`favourites?id=` + id);
  }

  //save ticket columns
  async ticketcols(token, data) {
    return await axios(token).post(`ticketcols`, data);
  }

  //Get Incident Tickets Overview Data
  async getIncidentTicketsOverview(token, body) {
    return await axios(token).post(`getIncidentTicketsOverview`, body);
  }

  // Get draft description list by ticket id
  async getDraftDescriptionListByTicketId(token, ticketId) {
    return await axios(token).get(`draftDescription?ticket_id=${ticketId}`);
  }

  // Add draft description
  async addDraftDescription(token, body) {
    return await axios(token).post(`draftDescription`, body);
  }

  // Delete draft description
  async deleteDraftDescription(token, id) {
    return await axios(token).delete(`draftDescription?id=${id}`);
  }

  // Update draft description
  async updateDraftDescription(token, id, body) {
    return await axios(token).put(`draftDescription?id=${id}`, body);
  }

  // Update draft description bulk
  async updateDraftDescriptionBulk(token, query, body) {
    return await axios(token).put(`draftDescription?${query}`, body);
  }

  async getTicketPermissions(token, id) {
    return await axios(token).get(`ticketPermission?id=${id}`);
  }
}

export default new TicketServices();
