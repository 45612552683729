import React from 'react'

const ShipmentTicketFormContext = React.createContext({
  formik: {},
  isActive: () => {},
  companies: [],
  countries: [],
  teams: [],
  operatingSites: [],
  customers: [],
  services: []
})

export default ShipmentTicketFormContext
