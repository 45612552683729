// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sortable-list {
  width: 100%;
  background: #ffffff;
  margin-bottom: 2rem;
}
.sortable-list .item:hover {
  width: 100%;
  background: #f5f5f5;
  cursor: pointer;
}

.sortable-list .item {
  display: flex;
  justify-content: space-between;
  list-style: none;
  cursor: move;
  background: #ffffff;
  align-items: center;
  padding: 0.5rem;
  border-top: 1px solid #92979a;
  word-break: break-all;
}

.sortable-list .item:last-child {
  border-bottom: 1px solid #92979a;
}
.item .details {
  display: flex;
  align-items: center;
}

.item .details .draftDescription {
  font-size: 1rem;
  font-weight: 500;
  color: #92979a;
}

.item i {
  color: #474747;
  font-size: 1.13rem;
}

.item.dragging {
  opacity: 0.6;
}

.item.dragging .details,
.item.dragging i {
  opacity: 0.8;
  transform: scale(1.02);
  background: #f0f0f0;
}
`, "",{"version":3,"sources":["webpack://./src/views/super-admin/Ticket/components/Ticket Detail Popup/shared/dragAndDrop.css"],"names":[],"mappings":"AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,gBAAgB;EAChB,YAAY;EACZ,mBAAmB;EACnB,mBAAmB;EACnB,eAAe;EACf,6BAA6B;EAC7B,qBAAqB;AACvB;;AAEA;EACE,gCAAgC;AAClC;AACA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA;;EAEE,YAAY;EACZ,sBAAsB;EACtB,mBAAmB;AACrB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');\n\n.sortable-list {\n  width: 100%;\n  background: #ffffff;\n  margin-bottom: 2rem;\n}\n.sortable-list .item:hover {\n  width: 100%;\n  background: #f5f5f5;\n  cursor: pointer;\n}\n\n.sortable-list .item {\n  display: flex;\n  justify-content: space-between;\n  list-style: none;\n  cursor: move;\n  background: #ffffff;\n  align-items: center;\n  padding: 0.5rem;\n  border-top: 1px solid #92979a;\n  word-break: break-all;\n}\n\n.sortable-list .item:last-child {\n  border-bottom: 1px solid #92979a;\n}\n.item .details {\n  display: flex;\n  align-items: center;\n}\n\n.item .details .draftDescription {\n  font-size: 1rem;\n  font-weight: 500;\n  color: #92979a;\n}\n\n.item i {\n  color: #474747;\n  font-size: 1.13rem;\n}\n\n.item.dragging {\n  opacity: 0.6;\n}\n\n.item.dragging .details,\n.item.dragging i {\n  opacity: 0.8;\n  transform: scale(1.02);\n  background: #f0f0f0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
