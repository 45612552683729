import { axiosWithToken as axios } from 'utils/axios';
import { LogoutOnSessionExpire } from 'common/helpers';

class NetworkServices {

	async getNetworkFunction (token){

		return await axios(token).get('getNetworkFunction');
	}
	async getMake (token){

		return await axios(token).get('getMake');
	}
	async getModel (token, id){

		return await axios(token).get('getModel?make='+id);
	}
	async getDeviceStatus (token){

		return await axios(token).get('getDeviceStatus');
	}
	/*
	|-------------------------------------------------------------------------------
	| 	get Network Service
	|-------------------------------------------------------------------------------
	*/
	async getNetworkDevices (token){

		return await axios(token).get('getNetworkDevices');
	}
    async networkByDataCenterId (token,data){

		return await axios(token).get(`getNetworkDevices?data_center_id=${data.id}`);
	}
	async addNetworkDevice (token,data){

		return await axios(token).post(`CreateNetworkDevice`, data);
	}
	async updateNetworkDevice (token,data){

		return await axios(token).post(`updateNetworkDevices`, data);
	}
	async deleteNetworkDevice (token, data){
		return await axios(token).post(`deleteNetworkDevice`, data);
	}

}

export default new NetworkServices()