// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aws-btn {
  --button-default-height: 54px;
  --button-default-font-size: 16px;
  --button-default-border-radius: 8px;
  --button-horizontal-padding: 40px;
  --button-raise-level: 0px;
  --button-hover-pressure: 0;
  --transform-speed: 0.6s;
  --button-primary-color: #e56e1f;
  --button-primary-color-dark: #a24e16;
  --button-primary-color-light: #ffffff;
  --button-primary-color-hover: #6c3714;
  --button-primary-color-active: #166dba;
  --button-primary-border: 5px solid transparent;
  --button-secondary-color: #ffffff;
  --button-secondary-color-dark: #a3a3a3;
  --button-secondary-color-light: #000000;
  --button-secondary-color-hover: #e1eaf1;
  --button-secondary-color-active: #cfdee9;
  --button-secondary-border: 2px solid #a3a3a3;
  --button-anchor-color: #0e4f88;
  --button-anchor-color-dark: #072743;
  --button-anchor-color-light: #ffffff;
  --button-anchor-color-hover: #0d4a7f;
  --button-anchor-color-active: #0c4271;
  --button-anchor-border: none;
  --button-danger-color: #c42e2e;
  --button-danger-color-dark: #861f1f;
  --button-danger-color-light: #ffffff;
  --button-danger-color-hover: #bc2c2c;
  --button-danger-color-active: #af2929;
  --button-danger-border: none;
}
.aws-btn .aws-btn__wrapper {
  white-space: nowrap;
}`, "",{"version":3,"sources":["webpack://./src/views/super-admin/Tickets/shared/TicketButton/TicketButton.scss"],"names":[],"mappings":"AAAA;EACI,6BAAA;EACA,gCAAA;EACA,mCAAA;EACA,iCAAA;EACA,yBAAA;EACA,0BAAA;EACA,uBAAA;EACA,+BAAA;EACA,oCAAA;EACA,qCAAA;EACA,qCAAA;EACA,sCAAA;EACA,8CAAA;EACA,iCAAA;EACA,sCAAA;EACA,uCAAA;EACA,uCAAA;EACA,wCAAA;EACA,4CAAA;EACA,8BAAA;EACA,mCAAA;EACA,oCAAA;EACA,oCAAA;EACA,qCAAA;EACA,4BAAA;EACA,8BAAA;EACA,mCAAA;EACA,oCAAA;EACA,oCAAA;EACA,qCAAA;EACA,4BAAA;AACJ;AACI;EACI,mBAAA;AACR","sourcesContent":[".aws-btn {\n    --button-default-height: 54px;\n    --button-default-font-size: 16px;\n    --button-default-border-radius: 8px;\n    --button-horizontal-padding: 40px;\n    --button-raise-level: 0px;\n    --button-hover-pressure: 0;\n    --transform-speed: 0.6s;\n    --button-primary-color: #e56e1f;\n    --button-primary-color-dark: #a24e16;\n    --button-primary-color-light: #ffffff;\n    --button-primary-color-hover: #6c3714;\n    --button-primary-color-active: #166dba;\n    --button-primary-border: 5px solid transparent;\n    --button-secondary-color: #ffffff;\n    --button-secondary-color-dark: #a3a3a3;\n    --button-secondary-color-light: #000000;\n    --button-secondary-color-hover: #e1eaf1;\n    --button-secondary-color-active: #cfdee9;\n    --button-secondary-border: 2px solid #a3a3a3;\n    --button-anchor-color: #0e4f88;\n    --button-anchor-color-dark: #072743;\n    --button-anchor-color-light: #ffffff;\n    --button-anchor-color-hover: #0d4a7f;\n    --button-anchor-color-active: #0c4271;\n    --button-anchor-border: none;\n    --button-danger-color: #c42e2e;\n    --button-danger-color-dark: #861f1f;\n    --button-danger-color-light: #ffffff;\n    --button-danger-color-hover: #bc2c2c;\n    --button-danger-color-active: #af2929;\n    --button-danger-border: none;\n\n    .aws-btn__wrapper {\n        white-space: nowrap;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
