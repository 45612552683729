export const subMenu = [
  {
    path: "/setting-cabling-and-patch-nw-rooms",
    title: "NW Rooms",
  },
  /*{
		path:'/setting/cabling-and-patch/cabling',
		title:'Cabling'
	},*/
  {
    path: "/setting-cabling-and-patch-patch-panels",
    title: "Patch Panels",
  },
];
