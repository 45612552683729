import AuthContext from "context";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CreateTicketService from "services/createTicketService";
import roleServices from "services/roleServices";
import Swal from "sweetalert2";
import usePreselectCountry from "./usePreselectCountry";

const useGetCompaniesAndCountries = (
  setLoading,
  setFieldValue,
  countryCode
) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);

  const [companies, setCompanies] = useState([]);
  const [companiesSite, setCompaniesSite] = useState([]);
  const [countries, setCountries] = useState([]);
  const [isCompanyLoading, setIsCompanyLoading] = useState(false);

  usePreselectCountry({ countries, setFieldValue });

  const getCompanies = async () => {
    setCompanies([]);
    try {
      if (setLoading) {
        setLoading(true);
      }
      if (countryCode != undefined) {
        if (countryCode === "global" && countryCode === "") {
          setCompanies([""]);
        } else {
          setIsCompanyLoading(true);
          // console.log("hello---22222",countryCode)
          const data = await CreateTicketService.getCompanies(
            authContext.token(),
            countryCode
          );
          setCompanies(
            data.map((company) => {
              const { id, name, company_code } = company;
              return {
                ...company,
                label: company_code ? `${name} (${company_code})` : name,
                value: id,
              };
            })
          );
          setIsCompanyLoading(false);
        }
      }

      if (setLoading) {
        setLoading(false);
      }
    } catch (err) {
      setIsCompanyLoading(false);
      if (setLoading) {
        setLoading(false);
      }
      // await Swal.fire(t('error'), err.message)
    }
  };
  const getCompaniesSite = async () => {
    try {
      if (setLoading) {
        setLoading(true);
      }
      const data = await CreateTicketService.getCompanieslist(
        authContext.token()
      );
      setCompaniesSite(
        data.data.data.map((company) => {
          const { customer_id, customer_name } = company;
          return {
            ...company,
            label: customer_name,
            value: customer_id,
          };
        })
      );
      if (setLoading) {
        setLoading(false);
      }
    } catch (err) {
      if (setLoading) {
        setLoading(false);
      }
      // await Swal.fire(t('error'), err.message)
    }
  };
  const getAllCountries = async () => {
    try {
      const countriesDto = await roleServices.countryService(
        authContext.token()
      );
      setCountries(
        countriesDto.data.data.map((country) => {
          const { name, country_code } = country;
          return {
            ...country,
            label: name,
            value: country_code,
            country_name: name === "South Korea" ? "Korea" : name,
          };
        })
      );
    } catch (err) {
      // await Swal.fire(t('error'), t('something-went-wrong'))
    }
  };

  useEffect(() => {
    // getCompanies();
    getAllCountries();
  }, []);

  useEffect(() => {
    if (countryCode !== "global" && countryCode !== "") {
      getCompanies();
    }
  }, [countryCode]);

  return {
    companies,
    countries,
    companiesSite,
    isCompanyLoading,
  };
};

export default useGetCompaniesAndCountries;
