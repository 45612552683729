import Table from "./table";
import { useTranslation } from "react-i18next";
import { useEffect, useState, useRef } from "react";
import provisioningService from "services/provisioningService";
import { Button } from "react-bootstrap";
const Attachments = (props) => {
  const { t } = useTranslation();
  const columns = [
    { name: "name", value: t("provisioning.attachments_table.file_name") },
    { name: "assignee", value: t("provisioning.attachments_table.actions") },
  ];
  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    if (props.serviceId) {
      getServiceAttachments();
    }
  }, [props.serviceId]);

  useEffect(() => {
    console.log(props.refreshData);
    if (props.refreshData) {
      getServiceAttachments();
    }
  }, [props.refreshData]);
  const getServiceAttachments = async () => {
    props.setIsLoading(true);
    await provisioningService
      .getServiceAttachments(localStorage.getItem("token"), props.serviceId)
      .then((res) => {
        console.log(res);
        if (res?.data.data === undefined) {
          // console.log("here")
          props.setErrorPopup(true);
          props.setErrorMsg(res.data);
          //setStatusChange(true)
        } else {
          setAttachments(res.data.data);
          props.setIsRefreshAttachments(false);
        }
      })
      .finally(() => {
        props.setIsLoading(false);
      });
  };

  return (
    <>
      {" "}
      {attachments.length > 0 ? (
        <Table
          data={attachments}
          columns={columns}
          handleDownload={props.handleDownload}
          handleDelete={props.handleDelete}
        ></Table>
      ) : (
        <div className="mb-3"></div>
      )}
      {props.permission("Add Attachment") && (
        <div>
          <Button
            variant="text"
            className="add_file"
            onClick={props.handleImageClick}
          >
            + Add new file(s)
          </Button>
          <div style={{ textAlign: "center" }}>
            <input
              type="file"
              style={{ display: "none" }}
              ref={props.fileInputRef}
              onChange={props.handleFileChange}
              multiple
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Attachments;
