import React, { useEffect } from "react";
import Layout from "../Layouts";
import Loading from "views/super-admin/pre-loader/loader";
import { useState } from "react";
import commonService from "services/commonService";
import { LogoutOnSessionExpire } from "common/helpers";
import StorageContext from "context";
import contactService from "services/contactService";
import { useContext } from "react";
import Pagination from "../Ticket/components/shared/usePagination";
import ErrorModel from "../Ticket/components/errormodel";
import ContactDetailModal from "./contactDetailModel";
import "./style.css";
import Swal from "sweetalert2";

const Contacts = () => {
  const contextStore = useContext(StorageContext);
  const [isLoading, setIsLoading] = useState(false);
  const [permission, setPermission] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [state, setState] = useState([]);
  const [total, setTotal] = useState(0);
  const [ascending, setAscending] = useState(true);
  const [ascendingCompany, setAscendingCompany] = useState(true);
  const [ascendingEmail, setAscendingEmail] = useState(true);
  const [ascendingPSA, setAscendingPSA] = useState(true);
  const [ascendingCountry, setAscendingCountry] = useState(true);
  const [ascendingJobPosition, setAscendingJobPosition] = useState(true);
  const [searchVal, setSearchVal] = useState("");
  const [drop, SetDrop] = useState(false);
  const [query, setQuery] = useState("");
  const [drop1Subtype, Setdrop1Subtype] = useState("");
  const [contactDetailData, setContactDetailData] = useState({});
  const [show, setShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Something went Wrong !");
  const [errorpopup, setErrorPopup] = useState(false);
  const [ascendingAccess, setAscendingAccess] = useState(true);
  const [searchquery, setSearchQuery] = useState("");
  const [contactImg, setContactImg] = useState("");
  useEffect(() => {
    if (contextStore?.getPermittedCountry?.length > 0) {
      fetchContact();
    }
    // commonService
    //   .permission()
    //   .then((res) => {
    //     setPermission(res.data.permission);
    //   })
    //   .catch((err) => {
    //     setErrorMsg(err.error.response.data.error);
    //     setErrorPopup(true);
    //   });
  }, [
    pageNumber,
    itemsPerPage,
    query,
    searchquery,
    contextStore?.getPermittedCountry?.length,
  ]);

  const fetchContact = async () => {
    setIsLoading(true);
    setAscending(true);
    let filters = [
      {
        name: query,
        value: searchVal,
      },
    ];
    const body = {
      page: pageNumber,
      limit: itemsPerPage,
      filters: filters,
    };

    if (
      contextStore?.getAuth?.role?.country_id !== 6 &&
      contextStore?.getPermittedCountry?.length > 0
    ) {
      filters.push({
        name: "country",
        value: contextStore?.getPermittedCountry[0]?.name,
      });
    }

    // const paginationQuery = `page=${pageNumber}&pageSize=${itemsPerPage}&type=${query}&searchVal=${searchVal}`;
    await contactService
      .getAllContact(contextStore.token(), body)
      .then((res) => {
        // console.log('Contact---', res.data.data.rows);
        if (res.data.data.rows.length > 0) {
          setTotal(res.data.data.total);
          setState(
            res.data.data.rows.sort((a, b) => (a.name < b.name ? 1 : -1))
          );
        } else {
          setState([]);
        }
      })
      .catch((err) => {
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateNote = async (data, contactId) => {
    setIsLoading(true);
    await contactService
      .updateContact(localStorage.getItem("token"), data, contactId)
      .then((res) => {
        openContactDetailModal(contactId);
        setIsLoading(false);
        Swal.fire("Data has been updated");
      })
      .catch((err) => {
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const HandleSearch = (val) => {
    setSearchVal(() => val);
    if (val.length > 0) {
      SetDrop(true);
    } else {
      // fetchTicket(false, '', '', '', drop2Val);

      SetDrop(false);
      Setdrop1Subtype(() => "");
      setQuery("");
      setPageNumber(1);
      setItemsPerPage(50);
    }
  };

  const HandleDrop1 = (val) => {
    SetDrop(() => false);
    Setdrop1Subtype(() => val);
    setQuery(val);
    setSearchQuery(searchVal);
    setPageNumber(1);
    setItemsPerPage(50);
  };

  const openContactDetailModal = async (cid) => {
    setIsLoading(true);
    await contactService
      .getContactDetails(contextStore.token(), cid)
      .then((res) => {
        if (res.data.success === undefined) {
          // console.log('details', res.data.profile.data);
          setContactDetailData(res.data.data);
          setContactImg(res.data.profile.data);
          setShow(true);
        } else {
          setErrorPopup(true);
          setErrorMsg(res.data);
        }
      })
      .catch((err) => {
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const renderItem = () => {
    return (
      state &&
      state.map((data) => {
        return (
          <tr className="setting-table">
            <td
              style={{ cursor: "pointer" }}
              onClick={() => {
                openContactDetailModal(data.id);
              }}
            >
              {" "}
              {data?.name}{" "}
            </td>
            <td className="center-col">
              {" "}
              {data.parent ? data.parent : "N/A"}{" "}
            </td>
            <td className="center-col">
              {data.country ? data.country : "N/A"}
            </td>
            <td className="center-col">{data.email ? data.email : "N/A"}</td>
            <td className="center-col">
              {data.job_position ? data.job_position : "N/A"}
            </td>

            <td>
              {data.psa &&
                data.psa.map((p, index) => (
                  <span key={index}>
                    {p.name ? p.name : "N/A"}
                    {index < data.psa.length - 1 && ", "}
                  </span>
                ))}
            </td>
            <td>
              {data.access_cards.length > 0 ? data.access_cards.toString() : ""}
            </td>
          </tr>
        );
      })
    );
  };
  return (
    <React.Fragment>
      <Loading
        loading={isLoading}
        background="rgba(0, 0, 0, 0.40)"
        loaderColor="rgb(248, 153, 3)"
      />
      <Layout>
        <div className="content-body" style={{ minHeight: "20rem" }}>
          <hr className="main" />
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-10">
                <div className="tc_drop1-out">
                  <div class="tc_drop1">
                    <div className="inp-out">
                      <input
                        type="text"
                        onChange={(e) => HandleSearch(e.target.value)}
                        value={searchVal}
                      />
                      <div className="after"></div>
                    </div>
                    <div
                      className="img-out"
                      onClick={() => SetDrop(() => !drop)}
                    >
                      {" "}
                      <img
                        alt=""
                        src="\images\arrow.png"
                        className="arrow-img"
                      />
                    </div>
                  </div>
                  {drop ? (
                    <div className="tc_drop1-opt">
                      <div
                        className="tc_opt"
                        onClick={() => HandleDrop1("name")}
                      >
                        Search 'Name' for {searchVal}
                      </div>
                      <div
                        className="tc_opt"
                        onClick={() => HandleDrop1("parent")}
                      >
                        Search 'Company' for {searchVal}
                      </div>
                      {contextStore?.getAuth?.role?.country_id === 6 && (
                        <div
                          className="tc_opt"
                          onClick={() => HandleDrop1("country")}
                        >
                          Search 'Country' for {searchVal}
                        </div>
                      )}
                      <div
                        className="tc_opt"
                        onClick={() => HandleDrop1("email")}
                      >
                        Search 'Email' for {searchVal}
                      </div>
                      <div
                        className="tc_opt"
                        onClick={() => HandleDrop1("job_position")}
                      >
                        Search 'Job Position' for {searchVal}
                      </div>
                      <div
                        className="tc_opt"
                        onClick={() => HandleDrop1("psa")}
                      >
                        Search 'Permanent Site Access' for {searchVal}
                      </div>
                      <div
                        className="tc_opt"
                        onClick={() => HandleDrop1("access_card_number")}
                      >
                        Search 'Access Card Number' for {searchVal}
                      </div>
                      <div
                        className="tc_opt"
                        onClick={() => HandleDrop1("associated_service_id")}
                      >
                        Search 'Associated Service ID' for {searchVal}
                      </div>
                      <div
                        className="tc_opt"
                        onClick={() => HandleDrop1("associated_space_id")}
                      >
                        Search 'Space ID' for {searchVal}
                      </div>
                      <div
                        className="tc_opt"
                        onClick={() => HandleDrop1("associated_csr")}
                      >
                        Search 'Customer Service Reference' for {searchVal}
                      </div>
                      <div
                        className="tc_opt"
                        onClick={() => HandleDrop1("portal_code")}
                      >
                        Search 'Portal Code' for {searchVal}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-2 col-sm-2"></div>
              {show == true ? (
                <ContactDetailModal
                  setShow={setShow}
                  show={show}
                  data={contactDetailData}
                  setIsLoading={setIsLoading}
                  img={contactImg}
                  updateNote={updateNote}
                />
              ) : null}
            </div>
            <div className="col-lg-12 layout-card-div2">
              <div className="card">
                <div className="card-body">
                  <div
                    className="table-responsive"
                    style={{ overflowX: "scroll !important" }}
                  >
                    <table className="table">
                      <thead>
                        <tr>
                          <th
                            onClick={() => {
                              setAscending(!ascending);
                              if (ascending === true) {
                                state.sort((a, b) =>
                                  a.name > b.name ? 1 : -1
                                );
                              }
                              if (ascending === false) {
                                state.sort((a, b) =>
                                  a.name < b.name ? 1 : -1
                                );
                              }
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <strong> Name </strong>
                            <i
                              className={`fa fa-solid fa-sort-${
                                ascending ? "down" : "up"
                              }`}
                            ></i>
                          </th>
                          <th
                            className="center-col"
                            onClick={() => {
                              setAscendingCompany(!ascendingCompany);
                              if (ascendingCompany === true) {
                                state.sort((a, b) =>
                                  a.parent > b.parent ? 1 : -1
                                );
                              }
                              if (ascendingCompany === false) {
                                state.sort((a, b) =>
                                  a.parent < b.parent ? 1 : -1
                                );
                              }
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <strong> Company </strong>
                            <i
                              className={`fa fa-solid fa-sort-${
                                ascendingCompany ? "down" : "up"
                              }`}
                            ></i>
                          </th>
                          <th
                            onClick={() => {
                              setAscendingCountry(!ascendingCountry);
                              if (ascendingCountry === true) {
                                state.sort((a, b) =>
                                  a.country > b.country ? 1 : -1
                                );
                              }
                              if (ascendingCountry === false) {
                                state.sort((a, b) =>
                                  a.country < b.country ? 1 : -1
                                );
                              }
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <strong> Country </strong>
                            <i
                              className={`fa fa-solid fa-sort-${
                                ascendingCountry ? "down" : "up"
                              }`}
                            ></i>
                          </th>
                          <th
                            onClick={() => {
                              setAscendingEmail(!ascendingEmail);
                              if (ascendingEmail === true) {
                                state.sort((a, b) =>
                                  a.email > b.email ? 1 : -1
                                );
                              }
                              if (ascendingEmail === false) {
                                state.sort((a, b) =>
                                  a.email < b.email ? 1 : -1
                                );
                              }
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <strong> Email </strong>
                            <i
                              className={`fa fa-solid fa-sort-${
                                ascendingEmail ? "down" : "up"
                              }`}
                            ></i>
                          </th>
                          <th
                            onClick={() => {
                              setAscendingJobPosition(!ascendingJobPosition);
                              if (ascendingJobPosition === true) {
                                state.sort((a, b) =>
                                  a.job_position > b.job_position ? 1 : -1
                                );
                              }
                              if (ascendingJobPosition === false) {
                                state.sort((a, b) =>
                                  a.job_position < b.job_position ? 1 : -1
                                );
                              }
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <strong> Job Position </strong>
                            <i
                              className={`fa fa-solid fa-sort-${
                                ascendingJobPosition ? "down" : "up"
                              }`}
                            ></i>
                          </th>
                          <th
                            onClick={() => {
                              setAscendingPSA(!ascendingPSA);
                              state.sort((a, b) => {
                                const aPSA = a.psa
                                  ? a.psa.map((p) => p.name).join(", ")
                                  : "";
                                const bPSA = b.psa
                                  ? b.psa.map((p) => p.name).join(", ")
                                  : "";
                                return ascendingPSA
                                  ? aPSA.localeCompare(bPSA)
                                  : bPSA.localeCompare(aPSA);
                              });
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <strong> Permanent Site Access </strong>
                            <i
                              className={`fa fa-solid fa-sort-${
                                ascendingPSA ? "down" : "up"
                              }`}
                            ></i>
                          </th>
                          <th
                            onClick={() => {
                              setAscendingAccess(!ascendingAccess);
                              if (ascendingAccess === true) {
                                state.sort((a, b) =>
                                  a.access_cards > b.access_cards ? 1 : -1
                                );
                              }
                              if (ascendingAccess === false) {
                                state.sort((a, b) =>
                                  a.access_cards < b.access_cards ? 1 : -1
                                );
                              }
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <strong>Access Card Number </strong>
                            <i
                              className={`fa fa-solid fa-sort-${
                                ascendingAccess ? "down" : "up"
                              }`}
                            ></i>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="py-5">{renderItem()}</tbody>
                    </table>
                    <div className="d-flex justify-content-end mt-3">
                      {state && state.length != 0 && (
                        <Pagination
                          total={total}
                          setPageNumber={setPageNumber}
                          setItemsPerPage={setItemsPerPage}
                          pageNumber={pageNumber}
                        ></Pagination>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {errorpopup && (
          <ErrorModel
            show={errorpopup}
            setShow={setErrorPopup}
            errorMsg={errorMsg}
          />
        )}
      </Layout>
    </React.Fragment>
  );
};

export default Contacts;
