import { css } from 'glamor'
import { useState } from 'react'
// import ConfirmDeleteModal from '../../ConfirmDeleteModal'
import { BinIcon, EditUserIcon } from '../Icons'
import { useTranslation } from 'react-i18next'
import ConfirmDeleteModal from '../ConfirmDeleteModal'

const styles = {
  container: {
    // border: '1px solid rgba(0, 0, 0, 0.125)',
    // borderRadius: '5px',
    display: 'flex',
    // justifyContent: 'space-between',
    marginBottom: '10px',
    // fontSize: '14px',
    // overflowX: 'hidden',
    '& > *': {
      marginRight: '12px'
    }
  }
}

const DetailsListRow = ({
  id,
  name,
  weight,
  size,
  handleDelete,
  handleChange
}) => {
  const { t } = useTranslation()
  const [show, setShow] = useState(false)
  return (
    <tr>
      <tr {...css(styles.container)} key={id}>
        <td width='15%' className='d-flex justify-content-sm-between'>
          <BinIcon onClick={() => {
            setShow(true)
          }}
          />
          <EditUserIcon onClick={() => handleChange(id)} />
        </td>

        <td width='35%' style={{ overflowX: 'hidden' }}>{name}</td>
        <td width='15%'>{weight}</td>
        <td width='25%'>{size}</td>
      </tr>
      <ConfirmDeleteModal
        show={show}
        setShow={setShow}
        message={t('create-ticket.site-access.visitor-delete', { name: `"${name}"` })}
        handleClickOk={() => handleDelete(id)}
      />
    </tr>

  )
}

export default DetailsListRow
