import AuthContext from "context";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import Loading from "views/super-admin/pre-loader/loader";
import Activity from "services/activityService";
import RoleModel from "services/roleServices";
import Layout from "../../Layouts";
import Navigation from "../Component/Navigation";
import Result from "./result";
import { LogoutOnSessionExpire } from "common/helpers";
import ReportsNavTab from "./ReportsNavTab";
import "./Reports.css";
import { useTranslation } from "react-i18next";
import SearchBox from "./searchBox";
import DataRangeBox from "./dateRangeBox";
import Pagination from "views/super-admin/Ticket/components/shared/usePagination";
const Reports = (props) => {
  const authContext = useContext(AuthContext);
  const [state, setState] = useState([]);
  const [dataCenter, setDataCenter] = useState([]);
  const [countryName, setCountryName] = useState("Country");
  const [dataCenterName, setDataCenterName] = useState("N/A");
  const [activityLog, setActivityLog] = useState([]);
  const [users, setUsers] = useState([]);
  const [actions, setActions] = useState([]);
  const [entities, setEntities] = useState([]);
  const [showPopup, setShowPopup] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [actionId, setActionId] = useState("");
  const [entityId, setEntityId] = useState("");
  const [userId, setUserId] = useState("");
  const [status, setStatus] = useState("");
  const [siteId, setSiteId] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [total, setTotal] = useState(0);
  const [country, setCountry] = useState("");
  const [isFirstTimeLoading, setIsFirstTimeLoading] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    getData();
    getActivityLogs();
  }, []);
  useEffect(() => {
    if (!isFirstTimeLoading) {
      if (actionId != "") {
        setActionId("");
      } else {
        getActivityLogs();
      }
    }
  }, [entityId]);

  useEffect(() => {
    if (!isFirstTimeLoading) {
      getActivityLogs();
    }
  }, [userId, actionId, status, pageNumber]);

  useEffect(() => {
    if (pageNumber !== 1) {
      setPageNumber(1);
    } else {
      if (!isFirstTimeLoading) {
        getActivityLogs();
      }
    }
  }, [siteId]);

  useEffect(() => {
    if (!!endDate || (endDate == null && startDate == null)) {
      if (pageNumber !== 1) {
        setPageNumber(1);
      } else {
        if (!isFirstTimeLoading) {
          getActivityLogs();
        }
      }
    }
  }, [endDate]);

  useEffect(() => {
    if (countryName == "Country") {
      setSiteId("");
    } else {
      const sites = dataCenter
        .filter((dc) => dc.country_id === country.id)
        .map((dc) => dc.id);
      setSiteId(sites && sites.length > 0 ? sites : "");
    }
  }, [dataCenter]);

  const getData = async () => {
    await RoleModel.countryService(authContext.token())
      .then((res) => setState(res.data.data))
      .catch((err) => LogoutOnSessionExpire(err));
    getAllDataCenter();
    setDataCenterName("N/A");
  };

  const getAllDataCenter = async () => {
    setCountryName("Country");
    setDataCenterName("N/A");
    await RoleModel.dataCenter(authContext.token())
      .then((res) => {
        setDataCenter(res.data.data.filter((data) => data.dc_type_id === 1));
      })
      .catch((err) => LogoutOnSessionExpire(err));
  };

  const getDataCenterById = async (e) => {
    setCountry(e);
    setCountryName(e.name);
    setDataCenterName("N/A");
    authContext.setDataCenterFloor(e);
    await RoleModel.dataCenterByCountryId(authContext.token(), e)
      .then((res) => setDataCenter(res.data.data))
      .catch((err) => LogoutOnSessionExpire(err));
  };

  // useEffect(() => {
  //   if (dataCenter.length == 1) {
  //     setDataCenterName(dataCenter[0].name);
  //   }
  // }, [dataCenter]);

  const getActivityLogs = async () => {
    setIsLoading(true);
    const body = {
      page: pageNumber,
      pageSize: itemsPerPage,
    };
    if (userId != "") {
      body.userId = userId;
    } else {
      delete body.userId;
    }
    if (entityId != "") {
      body.entityId = entityId;
    } else {
      delete body.entityId;
    }
    if (actionId != "") {
      body.actionId = actionId;
    } else {
      delete body.actionId;
    }
    if (status != "") {
      body.status = status;
    } else {
      delete body.status;
    }
    if (siteId != "") {
      body.siteId = siteId;
    } else {
      delete body.siteId;
    }
    if (!!endDate) {
      body.dateRange = {
        startDate: startDate,
        endDate: endDate,
      };
    } else {
      delete body.dateRange;
    }
    await Activity.getSystemActivity(authContext.token(), body)
      .then((res) => {
        res.data.data.map((data) => {
          return (data.show = true);
        });

        setActivityLog(res.data.data);
        setUsers(res.data.userOptions);
        setActions(res.data.actionOptions);
        setEntities(res.data.entityOptions);
        setTotal(res.data.totalRecords);
        setIsFirstTimeLoading(false);
      })
      .catch((err) => LogoutOnSessionExpire(err))
      .finally(() => setIsLoading(false));
  };

  const renderCountry = () => {
    return (
      state &&
      state.map((data) => {
        return (
          <a
            href="#sd"
            className="dropdown-item"
            key={data.id}
            onClick={() => {
              setIsLoading(true);
              getDataCenterById(data);
            }}
          >
            {data.name}{" "}
          </a>
        );
      })
    );
  };

  const renderActivityLog = () => {
    return (
      activityLog &&
      activityLog.map((data, i) => {
        return (
          data.show && (
            <tr key={i}>
              <td>{moment(data?.date_change).format("YYYY-MM-DD hh:mm A")}</td>
              <td>
                {data?.datacenter !== null ? data?.datacenter?.name : "N/A"}
              </td>
              <td>{data?.user?.name}</td>
              <td>{data?.entity_type?.name}</td>
              <td>{data?.action_type?.name}</td>
              <td>
                {data?.entity_type?.is_success}
                {data?.is_success ? (
                  <span className="badge badge-success badge-lg light">
                    Success
                  </span>
                ) : (
                  <span className="badge badge-danger badge-lg light">
                    Failed
                  </span>
                )}
              </td>
              {/* <td> <button className="btn btn-success" id="addneww" data-bs-toggle="modal" data-bs-target=".bd-example-modal-lg" onClick={()=> showResults(data)}> Show Result </button> </td> */}
            </tr>
          )
        );
      })
    );
  };

  return (
    <React.Fragment>
      <Loading
        loading={isLoading}
        background="rgba(0, 0, 0, 0.40)"
        loaderColor="rgb(248, 153, 3)"
      />
      <Layout>
        <div className="content-body reports-block">
          <Navigation />
          <hr className="main" />
          <div className="container-fluid pt-0">
            <div className="settings-reports-layout">
              <div className="col-xl-2 col-lg-2">
                <div className="leftside">
                  <p>
                    {" "}
                    <a href="#sds" className="plink active">
                      {t("reports.system_activity")}
                    </a>{" "}
                  </p>
                </div>
              </div>

              <div className="col-lg-10">
                <div id="pro">
                  <div className="profile-tab">
                    <div className="custom-tab-1">
                      <div className="main_scrll">
                        <div className="btn_ul">
                          <ul className="nav nav-tabs mb-3">
                            <li className="nav-item">
                              {" "}
                              <button
                                className="btn btn-secondary"
                                style={{
                                  width: "100%",
                                  background:
                                    countryName != "Country"
                                      ? "#EAECF2"
                                      : "#11263C",
                                  color:
                                    countryName != "Country"
                                      ? "#000000"
                                      : "#ffffff",
                                  border:
                                    countryName != "Country" ? "none" : "",
                                }}
                                onClick={() => {
                                  getAllDataCenter();
                                }}
                              >
                                {" "}
                                {t("reports.global")}{" "}
                              </button>
                            </li>
                            <li className="nav-item">
                              <div className="btn-group" role="group">
                                <button
                                  type="button"
                                  className="btn btn-light dropdown-toggle"
                                  style={{
                                    width: "100%",
                                    background:
                                      countryName == "Country"
                                        ? "#EAECF2"
                                        : "#11263C",
                                    color:
                                      countryName == "Country"
                                        ? "#000000"
                                        : "#ffffff",
                                    border:
                                      countryName == "Country" ? "none" : "",
                                  }}
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  {" "}
                                  {countryName}{" "}
                                </button>
                                <div
                                  className="dropdown-menu"
                                  style={{ margin: "0px" }}
                                >
                                  {renderCountry()}
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="outer-div-setting">
                          <div className="ul_scrll">
                            <ul className="nav nav-tabs custom-scroll-gap">
                              {dataCenter &&
                                dataCenter.map((data, index) => {
                                  if (
                                    dataCenterName &&
                                    dataCenterName === data.name
                                  ) {
                                    return (
                                      <li
                                        className={
                                          index === 0 ? "nav-item" : "nav-item"
                                        }
                                        key={index}
                                      >
                                        <a
                                          href="#sds"
                                          onClick={() => {
                                            setSiteId([data.id]);
                                            setDataCenterName(data.name);
                                          }}
                                          style={{ cursor: "pointer" }}
                                          className="nav-link active show"
                                        >
                                          <img
                                            alt=""
                                            className="down setting_down"
                                            src="\images\downward-arrow.png"
                                          />
                                          {data.name}
                                        </a>
                                      </li>
                                    );
                                  } else {
                                    return (
                                      <li
                                        className={
                                          index === 0 ? "nav-item" : "nav-item"
                                        }
                                        key={index}
                                      >
                                        <a
                                          href="#sds"
                                          onClick={() => {
                                            setSiteId([data.id]);
                                            setDataCenterName(data.name);
                                          }}
                                          style={{ cursor: "pointer" }}
                                          className="nav-link"
                                        >
                                          {" "}
                                          {data.name}{" "}
                                        </a>
                                      </li>
                                    );
                                  }
                                })}
                              {/* {renderDataCenter()}  */}
                            </ul>
                          </div>
                          <div>
                            <span>
                              <i>
                                {t(
                                  "reports.select_a_country_to_view_partner_data_center"
                                )}
                              </i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="card" style={{ minHeight: "20rem" }}>
                      <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <DataRangeBox
                              startDate={startDate}
                              endDate={endDate}
                              setStartDate={setStartDate}
                              setEndDate={setEndDate}
                            ></DataRangeBox>
                          </div>
                          <div
                            className="d-flex justify-content-between"
                            style={{ gap: "0.5rem" }}
                          >
                            <SearchBox
                              placeholder="User"
                              options={users}
                              setQuery={setUserId}
                              setPageNumber={setPageNumber}
                            ></SearchBox>
                            <SearchBox
                              placeholder="Entity"
                              options={entities}
                              setQuery={setEntityId}
                              setPageNumber={setPageNumber}
                            ></SearchBox>
                            <SearchBox
                              placeholder="Action"
                              options={actions}
                              setQuery={setActionId}
                              setPageNumber={setPageNumber}
                              entityId={entityId}
                            ></SearchBox>
                          </div>
                          <div>
                            <ReportsNavTab
                              setStatus={setStatus}
                              setPageNumber={setPageNumber}
                              status={status}
                            />
                          </div>
                        </div>
                        <div className="table-responsive">
                          <table className="table table-responsive-md">
                            <thead>
                              <tr>
                                <th>
                                  <strong>{t("reports.timestamp")}</strong>
                                </th>
                                <th>
                                  <strong>{t("reports.data_center")}</strong>
                                </th>
                                <th>
                                  <strong>{t("reports.user")}</strong>
                                </th>
                                <th>
                                  <strong> {t("reports.entity")} </strong>
                                </th>
                                <th>
                                  <strong> {t("reports.actions")} </strong>
                                </th>
                                <th>
                                  <strong> {t("reports.result")}</strong>
                                </th>
                              </tr>
                            </thead>
                            <tbody>{renderActivityLog()}</tbody>
                          </table>
                        </div>
                        <div className="d-flex justify-content-end mt-3">
                          {activityLog && activityLog.length > 0 && (
                            <Pagination
                              setItemsPerPage={setItemsPerPage}
                              setPageNumber={setPageNumber}
                              total={total}
                              pageNumber={pageNumber}
                            ></Pagination>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {showPopup && <Result show={true} data={showPopup} />}
      </Layout>
    </React.Fragment>
  );
};

export default Reports;
