import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import maintenanceService from "services/maintenanceService";
import { isDisabled } from "@testing-library/user-event/dist/utils";

const MaintenenceModal = ({
  setShowModal,
  maintenanceModeStatus,
  setIsLoading,
  refresh,
  isLoading,
  getMaintenanceModeStatus,
}) => {
  const { t } = useTranslation();
  const [status, setStatus] = useState(
    !!maintenanceModeStatus &&
      ((maintenanceModeStatus?.current_status_id === 2 &&
        maintenanceModeStatus?.target_status_id === 3) ||
        (maintenanceModeStatus?.current_status_id === 1 &&
          maintenanceModeStatus?.target_status_id === 3) ||
        (maintenanceModeStatus?.current_status_id === 3 &&
          maintenanceModeStatus?.target_status_id === 3))
      ? 1
      : 0
  );

  const isSaveButtonDisabled = () => {
    const currentStatus =
      !!maintenanceModeStatus &&
      ((maintenanceModeStatus?.current_status_id === 2 &&
        maintenanceModeStatus?.target_status_id === 3) ||
        (maintenanceModeStatus?.current_status_id === 1 &&
          maintenanceModeStatus?.target_status_id === 3) ||
        (maintenanceModeStatus?.current_status_id === 3 &&
          maintenanceModeStatus?.target_status_id === 3))
        ? 1
        : 0;
    if (currentStatus == status) {
      return true;
    }
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const handleSave = async () => {
    await Swal.fire({
      // title: "Are you sure?",
      text: `Are you sure to turn Maintenance mode ${status ? "On" : "Off"} ?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      customClass: {
        cancelButton: "cancelBtn",
        confirmButton: "confirmBtn",
      },
    }).then((res) => {
      if (res.isConfirmed) {
        maintenanceModeStatusUpdate();
      }
    });
  };

  const maintenanceModeStatusUpdate = async () => {
    setIsLoading(true);
    const body = {
      target_status_id: status ? 3 : 1,
      status: status,
    };

    console.log(body);
    await maintenanceService
      .maintenanceModeStatusUpdate(localStorage.getItem("token"), body)
      .then((res) => {
        console.log(res.data);
        refresh();
        closeModal();
      })
      .catch((err) => {
        Swal.fire({
          // title: "Are you sure?",
          text: `Failed to turn ${status ? "on" : "off"} the Maintenance Mode.`,
          icon: "error",
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "confirmBtn",
          },
        });
      })
      .finally(() => {
        setIsLoading(false);
        refresh();
        closeModal();
      });
  };
  return (
    <div
      className="modal show bd-example-modal-lg"
      style={{
        display: "block",
        overflow: "auto",
        zIndex: isLoading ? "0" : "1050",
      }}
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        className="modal-dialog modal-lg"
        style={{ display: "flex", alignItems: "center" }}
      >
        <div className="modal-content">
          <div className="modal-header mt-59">
            <h3 className="modal-title"> {t("maintenance.boss")} </h3>
          </div>
          <div className="modal-header mt-59">
            <h3 className="modal-title">
              {" "}
              {t("maintenance.maintenance_mode")}{" "}
            </h3>
            <div
              class="form-check form-switch d-flex align-items-center"
              style={{ paddingLeft: "0px", marginBottom: "0rem" }}
            >
              <label className="switch" style={{ marginBottom: "0rem" }}>
                <input
                  type="checkbox"
                  onChange={(event) => setStatus(event.target.checked)}
                  checked={status}
                  id="flexSwitchCheckDefault"
                  disabled={
                    !!maintenanceModeStatus &&
                    ((maintenanceModeStatus.current_status_id == 2 &&
                      maintenanceModeStatus.target_status_id == 3) ||
                      maintenanceModeStatus.current_status_id == 4)
                  }
                />

                <div
                  className="slider round"
                  style={{
                    opacity:
                      !!maintenanceModeStatus &&
                      ((maintenanceModeStatus.current_status_id == 2 &&
                        maintenanceModeStatus.target_status_id == 3) ||
                        maintenanceModeStatus.current_status_id == 4)
                        ? "0.5"
                        : "1",
                  }}
                >
                  <span className="on" style={{ fontSize: "10px" }}>
                    ON
                  </span>
                  <span className="off">{t("ticket.off")}</span>
                </div>
              </label>
            </div>
          </div>

          {!!maintenanceModeStatus &&
            maintenanceModeStatus.current_status_id == 4 && (
              <div className="data_sync">
                <b>{getMaintenanceModeStatus(maintenanceModeStatus)}</b>
              </div>
            )}

          {!!maintenanceModeStatus &&
            maintenanceModeStatus.current_status_id == 2 &&
            maintenanceModeStatus.target_status_id == 3 && (
              <div className="data_sync">
                <b>Data Sync In Progress....</b>
              </div>
            )}

          <div className="modal-body">
            <div className="card">
              <div
                className="card-body"
                style={{
                  padding: "0px",
                  overflow: "auto",
                  fontSize: "1.25rem",
                  fontWeight: "500",
                  marginTop: "1rem",
                }}
              >
                <div>Once Maintenance mode is on, </div>
                <div className="d-flex">
                  <div class="bullet-icon"></div>
                  <div>Access to functions may be limited.</div>
                </div>
                <div className="d-flex">
                  <div class="bullet-icon"></div>
                  <div>BOSS access is blocked.</div>
                </div>
                <div>When the mode is turned off,</div>
                <div className="d-flex">
                  <div class="bullet-icon"></div>
                  <div>
                    Ops Portal access will be resumed only after data synced
                    back to BOSS.
                  </div>
                </div>
                <div className="d-flex">
                  <div class="bullet-icon"></div>
                  <div>
                    BOSS access will be resumed only after data synced back to
                    BOSS.
                  </div>
                </div>
              </div>
              <div
                className="toolbar toolbar-bottom mt-51 d-flex justify-content-end"
                role="toolbar"
              >
                <button
                  type="button"
                  onClick={handleSave}
                  class="btn me-3 btn-sm save-btn-new"
                  disabled={isSaveButtonDisabled()}
                >
                  Save
                </button>
                <button
                  type="button"
                  onClick={closeModal}
                  class="btn me-3 btn-sm discard-btn-new"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaintenenceModal;
