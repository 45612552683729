import StorageContext from "context";
import React, { useContext, useEffect, useState } from "react";
import Loading from "views/super-admin/pre-loader/loader";
import CommonService from "services/commonService";
import RoleModel from "services/roleServices";
import Layout from "../../Layouts";
import Navigation from "../Component/Navigation";
import CreateRole from "./createRole";
import UpdateRole from "./updateRole";
import { LogoutOnSessionExpire } from "common/helpers";
import { useTranslation } from "react-i18next";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import AssignedUserPopup from "./assignesUserPopup";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import CreateAndUpdateRole from "./createAndUpdateRole";
const Setting = () => {
  const contextStore = useContext(StorageContext);
  const [state, setState] = useState([]);
  const [allRolesData, setAllRolesData] = useState([]);
  const [search, setSearch] = useState("");
  const [ascending, setAscending] = useState(true);
  const [updateData, setUpdateData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [permission, setPermission] = useState([]);
  const [ascendingCountry, setAscendingCountry] = useState(true);
  const [ascendingDcs, setAscendingDcs] = useState(true);
  const [ascendingDataCenter, setAscendingDataCenter] = useState(true);
  const [ascendingSpace, setAscendingSpace] = useState(true);
  const [ascendingTickets, setAscendingTickets] = useState(true);
  const [ascendingProvisioning, setAscendingProvisioning] = useState(true);
  const [ascendingAccounts, setAscendingAccounts] = useState(true);
  const [ascendingMe, setAscendingMe] = useState(true);
  const [ascendingNetwork, setAscendingNetwork] = useState(true);
  const [ascendingUser, setAscendingUser] = useState(true);
  const [ascendingStatus, setAscendingStatus] = useState(true);
  const [ascendingEsg, setAscendingEsg] = useState(true);
  const [ascendingContacts, setAscendingContacts] = useState(true);
  const [ascendingIncidentMode, setAscendingIncidentMode] = useState(true);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const { t } = useTranslation();
  const [viewModal, setViewModal] = useState(false);
  const [roleDetail, setRoleDetail] = useState(null);
  useEffect(() => {
    getData();

    CommonService.permission()
      .then((res) => {
        setPermission(res.data);
      })
      .catch((err) => {
        LogoutOnSessionExpire(err);
      });
  }, []);

  const getData = async () => {
    await RoleModel.roleAndPermission(contextStore.token())
      .then((res) => {
        setState(res.data.data.sort((a, b) => (a.name < b.name ? 1 : -1)));
        setAllRolesData(res.data.data.sort((a, b) => (a.name < b.name ? 1 : -1)))
      })
      .catch((err) => {
        LogoutOnSessionExpire(err);
      })
      .finally(() => setIsLoading(false));
  };

  const filterRoles = async (event) => {
    setSearchQuery(event.target.value);

    // if(selectedRoom != null){
    setIsLoading(true);
    setAscending(true);

    await RoleModel.roleAndPermission(contextStore.token(), event.target.value)
      .then((res) => {
        if (res.data.data.length > 0) {
          setState(res.data.data.sort((a, b) => (a.name < b.name ? 1 : -1)));
        } else {
          setState([]);
        }
      })
      .catch((err) => LogoutOnSessionExpire(err))
      .finally(() => {
        setIsLoading(false);
      });
    // }
  };

  const permissionView = (id) => {
    let status = "";
    switch (id) {
      case 1:
        status = <i className="fas fa-times text-danger"></i>;
        break;
      case 2:
        status = "R";
        break;
      default:
        status = "RW";
        break;
    }
    return status;
  };

  const getRoleDetail = async (id) => {
    setIsLoading(true);
    await RoleModel.getRoleDetail(contextStore.token(), id)
      .then((res) => {
        setRoleDetail(res?.data?.data);
      })
      .catch((err) => {
        LogoutOnSessionExpire(err);
      })
      .finally(() => setIsLoading(false));
  };
  const openEditModel = async (data) => {
    try {
      await getRoleDetail(data.id);
      setShowCreateModal(true);
      setIsEdit(true);
    } catch (error) {
      console.error("Error fetching role detail:", error);
    }
  };
  const openViewModel = (data) => {
    setUpdateData(data);
    setViewModal(true);
  };

  const checkstatus = (status) => {
    let htmlStatus = "";
    switch (status) {
      case 1:
        htmlStatus = (
          <span className="badge badge-success badge-lg light">
            {" "}
            {t("rolepermission.active")}
          </span>
        );
        break;
      default:
        htmlStatus = (
          <span className="badge badge-danger badge-lg light">
            {t("rolepermission.archive")}
          </span>
        );
        break;
    }
    return htmlStatus;
  };

  const dataCenterName = (datacenter) => {
    if (datacenter.length > 0) {
      let dataCenterCombine = "";

      datacenter.forEach((element) => {
        dataCenterCombine += element?.datacenter?.name + ", ";
      });
      dataCenterCombine = dataCenterCombine.replace(/,\s*$/, "");
      return dataCenterCombine;
    } else {
      return "All";
    }
  };
  // useEffect(() => {
  //   console.log(contextStore?.getAuth);
  // }, []);

  const renderItem = () => {
    return (
      state &&
      state
        .filter(
          (data) =>
            data.name.toLowerCase().includes(search) ||
            data.data_center?.name.toLowerCase().includes(search) ||
            data.country?.name.toLowerCase().includes(search)
        )
        .map((data) => {
          return (
            <tr key={data.uuid} className="setting-table">
              <td> {data?.name} </td>
              <td className="center-col"> {data.country?.name} </td>
              <td className="center-col">
                {dataCenterName(data.role_datacenter)}
              </td>
              <td className="center-col">{checkstatus(data.is_active)}</td>
              {contextStore.getAuth.role.user_management !== 0 ? (
                <td className="center-col">
                  {(contextStore?.getAuth?.role?.name == "Super Admin" ||
                    contextStore?.getAuth?.role?.moderator === 1) && (
                    <a
                      href="#ssd"
                      className="edit"
                      onClick={() => openViewModel(data)}
                      style={{ cursor: "pointer", color: "black" }}
                    >
                      <ManageAccountsIcon
                        style={{
                          fontSize: "1.5rem",
                          paddingRight: "0.25rem",
                        }}
                      ></ManageAccountsIcon>
                    </a>
                  )}
                  <a
                    href="#ssd"
                    className="edit"
                    onClick={() => openEditModel(data)}
                    style={{ cursor: "pointer" }}
                  >
                    <i
                      className="fas fa-edit"
                      style={{
                        color: "black",
                        fontSize: "14px",
                      }}
                    ></i>{" "}
                  </a>{" "}
                </td>
              ) : null}
            </tr>
          );
        })
    );
  };

  return (
    <React.Fragment>
      <Loading
        loading={isLoading}
        background="rgba(0, 0, 0, 0.40)"
        loaderColor="rgb(248, 153, 3)"
      />
      <Layout>
        <div className="content-body">
          <Navigation />
          <hr className="main" />
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-10">
                <div>
                  <span>{t("rolepermission.filter")}: </span>
                  <input
                    type="search"
                    value={searchQuery}
                    style={{
                      marginBottom: "1rem",
                      border: "1px solid #92979A",
                    }}
                    onChange={filterRoles}
                  />
                </div>
              </div>

              <div className="col-2 col-sm-2">
                <div className="add-new-button">
                  {contextStore.getAuth.role.user_management !== 0 ? (
                    <a
                      href="#sd"
                      id="addnew"
                      className="btn btn-primary me-3 btn-sm"
                      onClick={() => {
                        setShowCreateModal(true);
                      }}
                    >
                      <img src="/images/plus-circle.svg" alt="" />
                      &nbsp; {t("rolepermission.add_new_role")}
                    </a>
                  ) : null}
                </div>
              </div>

              {showCreateModal == true && (
                <CreateAndUpdateRole
                  retriveCurrentData={getData}
                  permission={permission}
                  token={contextStore.token()}
                  show={showCreateModal}
                  setShow={setShowCreateModal}
                  isEdit={isEdit}
                  setIsEdit={setIsEdit}
                  data={roleDetail}
                  getRoleDetail={getRoleDetail}
                  setIsLoading={setIsLoading}
                  setRoleDetail={setRoleDetail}
                />
              )}

              {viewModal === true ? (
                <AssignedUserPopup
                  token={contextStore.token()}
                  permission={permission}
                  retriveCurrentData={getData}
                  viewModal={viewModal}
                  setViewModal={setViewModal}
                  data={updateData}
                  setIsLoading={setIsLoading}
                  isLoading={isLoading}
                  roles={allRolesData.filter((el) => el.id != updateData.id)}
                />
              ) : null}
            </div>
            <div className="col-lg-12 layout-card-div2">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-responsive-md">
                      <thead>
                        <tr>
                          <th
                            onClick={() => {
                              setAscending(!ascending);
                              if (ascending == true) {
                                state.sort((a, b) =>
                                  a.name > b.name ? 1 : -1
                                );
                              }
                              if (ascending == false) {
                                state.sort((a, b) =>
                                  a.name < b.name ? 1 : -1
                                );
                              }
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <strong> {t("rolepermission.role_name")} </strong>
                            <i
                              className={`fa fa-solid fa-sort-${
                                ascending ? "down" : "up"
                              }`}
                            ></i>
                          </th>
                          <th
                            className="center-col"
                            onClick={() => {
                              setAscendingCountry(!ascendingCountry);
                              if (ascendingCountry == true) {
                                state.sort((a, b) =>
                                  a.country?.name > b.country?.name ? 1 : -1
                                );
                              }
                              if (ascendingCountry == false) {
                                state.sort((a, b) =>
                                  a.country?.name < b.country?.name ? 1 : -1
                                );
                              }
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <strong> {t("rolepermission.country")} </strong>
                            <i
                              className={`fa fa-solid fa-sort-${
                                ascendingCountry ? "down" : "up"
                              }`}
                            ></i>
                          </th>
                          <th
                            className="center-col"
                            onClick={() => {
                              setAscendingDataCenter(!ascendingDataCenter);
                              if (ascendingDataCenter == true) {
                                state.sort((a, b) =>
                                  dataCenterName(a.role_datacenter) >
                                  dataCenterName(b.role_datacenter)
                                    ? 1
                                    : -1
                                );
                              }
                              if (ascendingDataCenter == false) {
                                state.sort((a, b) =>
                                  dataCenterName(a.role_datacenter) <
                                  dataCenterName(b.role_datacenter)
                                    ? 1
                                    : -1
                                );
                              }
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <strong>
                              {" "}
                              {t("rolepermission.data_centres")}{" "}
                            </strong>
                            <i
                              className={`fa fa-solid fa-sort-${
                                ascendingDataCenter ? "down" : "up"
                              }`}
                            ></i>
                          </th>
                          <th
                            className="center-col"
                            onClick={() => {
                              setAscendingStatus(!ascendingStatus);
                              if (ascendingStatus == true) {
                                state.sort((a, b) =>
                                  a.is_active > b.is_active ? 1 : -1
                                );
                              }
                              if (ascendingStatus == false) {
                                state.sort((a, b) =>
                                  a.is_active < b.is_active ? 1 : -1
                                );
                              }
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <strong> {t("rolepermission.status")} </strong>
                            <i
                              className={`fa fa-solid fa-sort-${
                                ascendingStatus ? "down" : "up"
                              }`}
                            ></i>
                          </th>
                          {contextStore.getAuth.role.user_management !== 0 ? (
                            <th className="center-col">
                              <strong> {t("rolepermission.actions")} </strong>
                            </th>
                          ) : null}
                        </tr>
                      </thead>
                      <tbody>{renderItem()}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default Setting;
