import { css } from 'glamor'
import { Row } from 'react-bootstrap'

const styles = {
  col: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '14px',
    marginLeft: '1px !important'
  },
  button: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    border: '2px solid',
    borderRadius: '5px'
  },
  buttonActive: {
    borderColor: '#0069d9 !important',
    cursor: 'pointer'
  },
  buttonInactive: {
    borderColor: '#707070 !important'
  },
  buttonSelected: {
    backgroundColor: '#0069d9'
  },
  i: {
    position: 'relative',
    top: '18px',
    marginRight: '5px'
  },
  textSelected: {
    color: '#fff'
  }
}

const TicketSwitchButton = ({ id, controlId, label, active, selected, handleClick, extraStyles }) => {
  return (
    <Row {...css(styles.col, extraStyles)} key={`${id}-${controlId}`}>
      {active
        ? (
          <label
            onClick={() => { handleClick(id, controlId) }}
            className='pt-1 pb-1'
            {...css(styles.button, styles.buttonActive, selected && styles.buttonSelected)}
          >
            <i className='fa-sharp fa-solid fa-circle-check' {...css(styles.i, selected && styles.textSelected)} />
            <span className='pt-3 pb-3' {...css(selected && styles.textSelected)}>{label}</span>
          </label>
          )
        : (
          <label
            className='pt-1 pb-1'
            {...css(styles.button, styles.buttonInactive)}
          >
            <i className='fa-sharp fa-solid fa-circle-check' {...css(styles.i)} />
            <span className='pt-3 pb-3'>{label}</span>
          </label>
          )}
    </Row>
  )
}

export default TicketSwitchButton
