import AuthContext from 'context'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CreateTicketService from 'services/createTicketService'
import Swal from 'sweetalert2'
import * as utils from '../../shared/utils'

const FAULT_REPORT_PREFIX = 'Fault Report '
const FAULT_REPORT_TICKET_TYPE_ID = 4
const FAULT_REPORT_TICKET_USB_TYPE_ID = 5

const useCreateFaultTicket = ({ closeModal, teams }) => {
  const { t } = useTranslation()
  const authContext = useContext(AuthContext)
  const [requestOnFlight, setRequestOnFlight] = useState(false)

  const postNewTicket = async (values) => {
    // console.log({ teams })
    try {
      const {
        country,
        operatingSite,
        customer,
        companyName,
        serviceId,
        ticketSubject,
        description,
        services,
        dataCenters,
        countries,
        severity
      } = values

      const dataCenterCountry = utils.getDataCenterCountry(operatingSite, dataCenters, countries)

      const payload = {
        ticket_type_id: FAULT_REPORT_TICKET_TYPE_ID,
        ticket_sub_type_id: FAULT_REPORT_TICKET_USB_TYPE_ID,
        team_id: utils.getTeamIdByCountry(teams, country, FAULT_REPORT_PREFIX, dataCenterCountry,countries),
        customer_id: parseInt(customer, 10),
        priority_id: severity != null && severity != undefined ? severity : 0,
        operating_site_id: parseInt(operatingSite, 10),
        designated_company_id: parseInt(companyName, 10),
        service_id: parseInt(serviceId, 10),
        space_id: services.find(service => serviceId).space_id,
        subject: ticketSubject,
        description
      }
      if(requestOnFlight === false){
        setRequestOnFlight(true)
        // console.log(payload)
        const result = await CreateTicketService
          .createTicket(authContext.token(), payload, 'fault')
        // console.log(result.data.data)
        const ticketId = result.id
        setRequestOnFlight(false)
        closeModal()
        await Swal.fire({
          icon: "success",
          title: `${t("done")}!`,
          text: t("create-ticket.site-access.success", {
            ticket: `${ticketSubject} (#${ticketId})`,
          }),
          confirmButtonText: "Ok",
          customClass: {
            cancelButton: "cancelBtn",
            confirmButton: "confirmBtn",
          },
        })
      }
    } catch (err) {
      setRequestOnFlight(false)
      await Swal.fire(t('error'), err.message)
    }
  }

  return {
    postNewTicket,
    requestOnFlight
  }
}

export default useCreateFaultTicket
