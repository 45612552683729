// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ticket-multi-select-field-multi-select {
  margin-top: 40px;
}
.ticket-multi-select-field-multi-select .select__control {
  background-color: rgb(240, 240, 240);
  border: 0px;
}
.ticket-multi-select-field-multi-select .select__control .select__value-container.select__value-container--is-multi.select__value-container--has-value {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  max-height: 100px;
  overflow-y: auto;
}
.ticket-multi-select-field-multi-select .select__control .select__value-container.select__value-container--is-multi.select__value-container--has-value .select__input-container {
  display: block;
}
.ticket-multi-select-field-multi-select .select__control .select__value-container.select__value-container--is-multi .select__input-container {
  display: inline-grid;
}

.ticket-multi-select-field-multi-select-option {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.ticket-multi-select-field-multi-select-option > input {
  position: relative;
  margin-right: 10px;
  top: 2px;
}`, "",{"version":3,"sources":["webpack://./src/views/super-admin/Tickets/shared/TicketMultiSelectField/TicketMultiSelect.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;AACF;AACE;EACE,oCAAA;EACA,WAAA;AACJ;AACI;EACE,aAAA;EACA,2BAAA;EACA,uBAAA;EACA,iBAAA;EACA,gBAAA;AACN;AACM;EACE,cAAA;AACR;AAGM;EACE,oBAAA;AADR;;AAOA;EACE,aAAA;EACA,2BAAA;EACA,mBAAA;AAJF;AAME;EACE,kBAAA;EACA,kBAAA;EACA,QAAA;AAJJ","sourcesContent":[".ticket-multi-select-field-multi-select {\n  margin-top: 40px;\n\n  .select__control {\n    background-color: rgba(240, 240, 240, 1);\n    border: 0px;\n\n    .select__value-container.select__value-container--is-multi.select__value-container--has-value {\n      display: flex;\n      justify-content: flex-start;\n      align-items: flex-start;\n      max-height: 100px;\n      overflow-y: auto;\n\n      .select__input-container {\n        display: block;\n      }\n    }\n    .select__value-container.select__value-container--is-multi {\n      .select__input-container {\n        display: inline-grid;\n      }\n    }\n  }\n}\n\n.ticket-multi-select-field-multi-select-option {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n\n  & > input {\n    position: relative;\n    margin-right: 10px;\n    top: 2px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
