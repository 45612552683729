import AuthContext from "context"
import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import CreateTicketService from "services/createTicketService"
import Swal from "sweetalert2"
import * as utils from "../../shared/utils"

const FAULT_REPORT_PREFIX = "Incident Management"

const useCreateIncidentTicket = ({ closeModal, teams }) => {
  const { t } = useTranslation()
  const authContext = useContext(AuthContext)
  const [requestOnFlight, setRequestOnFlight] = useState(false)

  const postNewTicket = async (values) => {
    // console.log({ teams })
    try {
      const {
        country,
        operatingSite,
        Subcategory,
        description,
        ImpactedDevice,
        dataCenters,
        countries,
        category,
        severity,
        ticketSubject,
      } = values

      console.log(ImpactedDevice)

      const dataCenterCountry = utils.getDataCenterCountry(
        operatingSite,
        dataCenters,
        countries
      )

      const payload = {
        impacted_device_id: parseInt(ImpactedDevice, 10),
        team_id: utils.getTeamIdByCountry(
          teams,
          country,
          FAULT_REPORT_PREFIX,
          dataCenterCountry,
          countries
        ),
        operating_site_id: parseInt(operatingSite, 10),
        severity: parseInt(severity, 10),
        subcategory_id: parseInt(Subcategory, 10),
        category: category.category,
        subject: ticketSubject,
        description,
      }
      if (requestOnFlight === false) {
        setRequestOnFlight(true)
        // console.log(payload)
        const result = await CreateTicketService.createTicket(
          authContext.token(),
          payload,
          "incident"
        );
        // console.log(result.data.data)
        const ticketId = result.id
        setRequestOnFlight(false)
        closeModal()
        await Swal.fire({
          icon: "success",
          title: `${t("done")}!`,
          text: t("create-ticket.site-access.success", {
            ticket: `${ticketSubject} (#${ticketId})`,
          }),
          confirmButtonText: "Ok",
          customClass: {
            cancelButton: "cancelBtn",
            confirmButton: "confirmBtn",
          },
        })
      }
    } catch (err) {
      setRequestOnFlight(false)
      await Swal.fire(t("error"), err.message)
    }
  }

  return {
    postNewTicket,
    requestOnFlight,
  }
}

export default useCreateIncidentTicket
