import { useState } from 'react';

const TimerPop = ({ handleCancelPOP, handleSaveTimer, timeVal, setTimePop }) => {
  let val = timeVal.split(':');
  let time = val[0] + ':' + val[1];
  const [value, setValue] = useState({ duration_minutes: time, description: '' });
  const handleval = (e) => {
    setValue((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };
  const handleSave = () => {
    handleSaveTimer(value);
    setTimePop(false);
  };
  return (
    <div className="timer-pop">
      <div className="main-body">
        <div>
          <div className="top-cts">
            <div>Confirm Time Spent</div>
            <div className="close" onClick={() => handleCancelPOP()}>
              X
            </div>
          </div>
          <div className="center-cts">
            <div className="center-cts-main">
              <div className="text">Duration</div>
              <div>
                <input
                  type="text"
                  value={value.duration_minutes}
                  name="duration_minutes"
                  onChange={(e) => handleval(e)}
                  placeholder="Duration"
                />
              </div>
            </div>
            <div className="center-cts-main-2">
              <div className="text">Description</div>

              <div style={{ width: '70%' }}>
                <textarea
                  type="text"
                  value={value.description}
                  onChange={(e) => handleval(e)}
                  name="description"
                  placeholder="Describe your activity"
                />
              </div>
            </div>
          </div>
          <div className="bottom-cts">
            <div>
              <button className="Save-btn" onClick={() => handleSave()}>
                SAVE
              </button>
            </div>
            <div>
              <button className="cancel-btn" onClick={() => handleCancelPOP()}>
                CANCEL
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimerPop;
