import { axiosWithToken as axios } from 'utils/axios';
import { LogoutOnSessionExpire } from 'common/helpers';

class NetworkRoomService {
    async getAllNetworkRoom(token, id){

		return await axios(token).get(`getNetworkRoom?data_center_id=`+id);
	}

    async getAllRoomType(token){
        return await axios(token).get(`getRoomType`);
    }

    async createRoom(token, data){
        return await axios(token).post(`CreateNetworkRoom`, data);
    }

    async updateRoom(token, data){
        return await axios(token).post(`UpdateNetworkRoom`, data);
    }

    async destroyRoom(token, data){
        return await axios(token).post(`deleteNetworkRoom`, data);
    }
}

export default new  NetworkRoomService()