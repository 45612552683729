import { axiosWithToken as axios } from 'utils/axios';
import { LogoutOnSessionExpire } from 'common/helpers';

class ContactService {
  /*
	|-------------------------------------------------------------------------------
	| 	Get all Account
	|-------------------------------------------------------------------------------
	*/
  // async getAllContact(token, q) {
  //   return await axios(token).get(`getContact?` + q);
  // } 
  async getAllContact(token, body) {
    return await axios(token).post(`getContact`, body);
  } 
  
  async getContactDetails(token, contactId) {
    return await axios(token).get(`getContactDetails?contactId=` + contactId);
  } 
   
  async updateContact(token, data, contactId) {
    return await axios(token).post(`updateContactDetails?contactId=` + contactId, data);
  }
}

export default new ContactService();
