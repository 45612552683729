import { useTranslation } from "react-i18next";
const CabinetBreakerSwitch = ({ navigationActiveTab, setNavigationActiveTab }) => {

  const { t } = useTranslation();

  const activeClass = (id) => {
    if (navigationActiveTab === id) {
      return "btn btn-secondary";
    }
    return "btn btn-light";
  };

  return (
    <div className="row p-1">
      <div className="col-12 col-sm-12 col-lg-6 col-xl-5">
        <div className="de_line_btn">
          <button
            type="button"
            onClick={() => {
              setNavigationActiveTab(1);
            }}
            className={activeClass(1)}
          >
            {t('dashboard.Cabinets')}
          </button>

          <button
            type="button"
            onClick={() => {
              setNavigationActiveTab(2);
              // resetVar();
            }}
            className={activeClass(2)}
          >
             {t('dashboard.breakers')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CabinetBreakerSwitch;
