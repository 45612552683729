import { Available, Blocked, InService, Reserved, ROFR } from 'components/ColorTile';
import FloorTable from './FloorTable';
import { useTranslation } from 'react-i18next';
const FloorCard = ({
  selectedDataCenter,
  selectedFloor,
  selectFloor,
  setFloorSort,
  floorSort,
  htmldata,
  floorsData
}) => {
  const { t } = useTranslation();
  return (
    <div className="col-lg-5 col-md-12 col-sm-12 col-12">
      <div className="grid_card h-100">
        <div className="inv mb-0">
          <div className="card_head">
            <div className="txt_card">
              <h3>{t('dashboard.Floors')}</h3>
            </div>
            <div className="txt_card_2">
              <InService />
              <Available />
              <Reserved />
              <ROFR />
              <Blocked />
            </div>
          </div>
          <div className="table_scrll-floor">
            <FloorTable
              selectedDataCenter={selectedDataCenter}
              selectedFloor={selectedFloor}
              selectFloor={selectFloor}
              setFloorSort={setFloorSort}
              floorSort={floorSort}
              htmldata={htmldata}
              floorsData={floorsData}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FloorCard;
