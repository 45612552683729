// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-ticket-left-part-upper-section {
  display: flex;
  flex-direction: column;
}
.create-ticket-left-part-upper-section > div {
  margin: 0px;
}`, "",{"version":3,"sources":["webpack://./src/views/super-admin/Tickets/SiteAccessTicketForm/LeftPart/LeftPart.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;AACF;AAAE;EACE,WAAA;AAEJ","sourcesContent":[".create-ticket-left-part-upper-section {\n  display: flex;\n  flex-direction: column;\n  & > div {\n    margin: 0px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
