// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings-cross-connects-blue-label {
  font-size: 1.2rem !important;
}

.settings-cross-connects-div {
  height: 100px;
}

.settings-cross-connects-desc {
  height: 80px;
}`, "",{"version":3,"sources":["webpack://./src/views/super-admin/Setting/DataCenter/CrossConnect/AddCrossConnect/AddCrossConnect.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;AAC9B;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,YAAY;AACd","sourcesContent":[".settings-cross-connects-blue-label {\n  font-size: 1.2rem !important;\n}\n\n.settings-cross-connects-div {\n  height: 100px;\n}\n\n.settings-cross-connects-desc {\n  height: 80px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
