import { Line2 } from "components/graph";
import Button from "@mui/material/Button";
import { useEffect, useState } from "react";
import DataFloorPopup from "./dataFloor-popup";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import { numberFormat, numberFormatToDecimalPlace } from "common/helpers";
import BoltIcon from "@mui/icons-material/Bolt";
import ThermostatIcon from "@mui/icons-material/Thermostat";

const FloorTable = ({
  selectedDataCenter,
  selectFloor,
  selectedFloor,
  setFloorSort,
  floorSort,
  htmldata,
  floorsData
}) => {
  const [fileExists, setFileExists] = useState(false);
  const [filePath, setFilePath] = useState("");
  const [show, setShow] = useState(false);
  const [ascending, setAscending] = useState(true);
  const { t } = useTranslation();
  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      arrow
      placement="top"
      classes={{ popper: className }}
      TransitionComponent={Zoom}
      TransitionProps={{ timeout: 300 }}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      color: "rgba(0, 0, 0, 0.87)",
      minWidth: 150,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid black",
    },

    [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]: {
      marginBottom: "0px",
      marginLeft: "0rem",
    },
  }));
  const HtmlTooltipForIndicator = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      arrow
      placement="top"
      classes={{ popper: className }}
      TransitionComponent={Zoom}
      TransitionProps={{ timeout: 300 }}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 200,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid black",
      textAlign: "center",
    },

    [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]: {
      marginBottom: "0.5rem",
      marginLeft: "0rem",
    },
  }));

  useEffect(() => {
    if (floorsData && floorsData.length) {
      selectFloor(floorsData[0]);
    } else {
      selectFloor([]);
    }
  }, [floorsData]);

  const getCabsStatus = (data) => {
    let available = 0;
    let inservicePer = 0;
    let availPer = 0;
    let reservedPer = 0;
    let rofrPer = 0;
    let blockedPer = 0;
    let reserved = 0;
    let rofr = 0;
    let blocked = 0;

    let design_cabs = data.rooms.reduce(
      (previous, current) => (previous += Number(current.design_cabs)),
      0
    );

    reserved = data.rooms.reduce(
      (previous, current) => (previous += Number(current.reserved_cabs)),
      0
    );

    rofr = data.rooms.reduce(
      (previous, current) => (previous += Number(current.rofr_cabs)),
      0
    );

    blocked = data.rooms.reduce(
      (previous, current) => (previous += Number(current.blocked_cabs)),
      0
    );

    let sold_cabs = data.rooms.reduce(
      (previous, current) => (previous += Number(current.sold_cabs)),
      0
    );

    available =
      Number(design_cabs) -
      Number(sold_cabs) -
      Number(reserved) -
      Number(rofr) -
      Number(blocked);

    let totalCabs = sold_cabs + available + blocked + reserved + rofr;

    if (design_cabs === 0) {
      sold_cabs = 0;
      available = 0;
      inservicePer = 0;
      availPer = 0;
    } else {
      inservicePer = (sold_cabs * 100) / totalCabs;
      availPer = (available * 100) / totalCabs;
      reservedPer = (reserved * 100) / totalCabs;
      rofrPer = (rofr * 100) / totalCabs;
      blockedPer = (blocked * 100) / totalCabs;
    }

    return (
      <HtmlTooltip
        title={
          <>
            <div style={{ padding: "0.5rem 0px 0px 0px" }}>
              <div
                style={{
                  color: "white",
                  fontSize: "0.8rem",
                  marginBottom: "0.5rem",
                }}
              >
                Cabinets
              </div>
              <div
                className="room_count"
                style={{
                  background: "#FE8600",
                }}
              >
                <div>In Services</div>
                <div>:</div>
                <div> {numberFormatToDecimalPlace(sold_cabs)}</div>
              </div>
              <div
                className="room_count"
                style={{
                  background: "#3feb7b",
                }}
              >
                <div>Available</div>
                <div>:</div>
                <div> {numberFormatToDecimalPlace(available)}</div>
              </div>
              <div
                className="room_count"
                style={{
                  background: "#1b70c0",
                }}
              >
                <div>Reserved</div>
                <div>:</div>
                <div> {numberFormatToDecimalPlace(reserved)}</div>
              </div>
              <div
                className="room_count"
                style={{
                  background: "#595959",
                }}
              >
                <div>ROFR</div>
                <div>:</div>
                <div> {numberFormatToDecimalPlace(rofr)}</div>
              </div>
              <div
                className="room_count"
                style={{
                  background: "#000000",
                  border: "1px solid white",
                }}
              >
                <div>Blocked</div>
                <div>:</div>
                <div> {numberFormatToDecimalPlace(blocked)}</div>
              </div>
            </div>
          </>
        }
      >
        <Button style={{ width: "11ch" }}>
          <Line2
            totalText={data.design_cabs}
            inServiceText={sold_cabs}
            inServicePercent={inservicePer}
            availableText={available}
            availablePercent={availPer}
            totalPercent={0}
            reservedPer={reservedPer}
            rofrPer={rofrPer}
            blockedPer={blockedPer}
            reserved={reserved}
            rofr={rofr}
            blocked={blocked}
          />
        </Button>
      </HtmlTooltip>
    );
  };
  const getCagesStatus = (data) => {
    let available = 0;
    let soldPer = 0;
    let availPer = 0;
    let reservedPer = 0;
    let rofrPer = 0;
    let blockedPer = 0;
    let reserved = 0;
    let rofr = 0;
    let blocked = 0;

    let design_cages = data.rooms.reduce(
      (previous, current) => (previous += Number(current.design_cages)),
      0
    );

    reserved = data.rooms.reduce(
      (previous, current) => (previous += Number(current.reserved_cages)),
      0
    );

    rofr = data.rooms.reduce(
      (previous, current) => (previous += Number(current.rofr_cages)),
      0
    );

    blocked = data.rooms.reduce(
      (previous, current) => (previous += Number(current.blocked_cages)),
      0
    );

    let sold_cages = data.rooms.reduce(
      (previous, current) => (previous += Number(current.sold_cages)),
      0
    );

    available =
      Number(design_cages) -
      Number(sold_cages) -
      Number(reserved) -
      Number(rofr) -
      Number(blocked);

    let totalCages = sold_cages + available + blocked + reserved + rofr;

    if (design_cages === 0) {
      sold_cages = 0;
      available = 0;
      soldPer = 0;
      availPer = 0;
    } else {
      soldPer = (sold_cages * 100) / totalCages;
      availPer = (available * 100) / totalCages;

      reservedPer = (reserved * 100) / totalCages;
      rofrPer = (rofr * 100) / totalCages;
      blockedPer = (blocked * 100) / totalCages;
    }

    return (
      <HtmlTooltip
        title={
          <>
            <div style={{ padding: "0.5rem 0px 0px 0px" }}>
              <div
                style={{
                  color: "white",
                  fontSize: "0.8rem",
                  marginBottom: "0.5rem",
                }}
              >
                Cages
              </div>
              <div
                className="room_count"
                style={{
                  background: "#FE8600",
                }}
              >
                <div>In Services</div>
                <div>:</div>
                <div> {numberFormatToDecimalPlace(sold_cages)}</div>
              </div>
              <div
                className="room_count"
                style={{
                  background: "#3feb7b",
                }}
              >
                <div>Available</div>
                <div>:</div>
                <div> {numberFormatToDecimalPlace(available)}</div>
              </div>
              <div
                className="room_count"
                style={{
                  background: "#1b70c0",
                }}
              >
                <div>Reserved</div>
                <div>:</div>
                <div> {numberFormatToDecimalPlace(reserved)}</div>
              </div>
              <div
                className="room_count"
                style={{
                  background: "#595959",
                }}
              >
                <div>ROFR</div>
                <div>:</div>
                <div> {numberFormatToDecimalPlace(rofr)}</div>
              </div>
              <div
                className="room_count"
                style={{
                  background: "#000000",
                  border: "1px solid white",
                }}
              >
                <div>Blocked</div>
                <div>:</div>
                <div> {numberFormatToDecimalPlace(blocked)}</div>
              </div>
            </div>
          </>
        }
      >
        <Button style={{ width: "11ch" }}>
          <Line2
            totalText={design_cages}
            inServiceText={sold_cages}
            availableText={available}
            totalPercent={0}
            reservedPer={reservedPer}
            rofrPer={rofrPer}
            blockedPer={blockedPer}
            reserved={reserved}
            rofr={rofr}
            blocked={blocked}
          />
        </Button>
      </HtmlTooltip>
    );
  };
  const getPowerStatus = (data) => {
    let available = 0;
    let soldPer = 0;
    let availPer = 0;
    let reservedPer = 0;
    let rofrPer = 0;
    let blockedPer = 0;
    let reserved = 0;
    let rofr = 0;
    let blocked = 0;

    let design_power = data.rooms.reduce(
      (previous, current) => (previous += Number(current.design_power)),
      0
    );

    reserved = data.rooms.reduce(
      (previous, current) => (previous += Number(current.reserved_power)),
      0
    );

    rofr = data.rooms.reduce(
      (previous, current) => (previous += Number(current.rofr_power)),
      0
    );

    blocked = data.rooms.reduce(
      (previous, current) => (previous += Number(current.blocked_power)),
      0
    );

    let sold_power = data.rooms.reduce(
      (previous, current) => (previous += Number(current.sold_power)),
      0
    );

    available = Number(
      Number(design_power) -
        Number(sold_power) -
        Number(reserved) -
        Number(rofr) -
        Number(blocked)
    );

    let totalPower = sold_power + available + blocked + reserved + rofr;
    if (design_power === 0) {
      sold_power = 0;
      available = 0;
      soldPer = 0;
      availPer = 0;
    } else {
      soldPer = (sold_power * 100) / totalPower;
      availPer = (available * 100) / totalPower;

      reservedPer = (reserved * 100) / totalPower;
      rofrPer = (rofr * 100) / totalPower;
      blockedPer = (blocked * 100) / totalPower;
    }

    return (
      <HtmlTooltip
        title={
          <>
            <div style={{ padding: "0.5rem 0px 0px 0px" }}>
              <div
                style={{
                  color: "white",
                  fontSize: "0.8rem",
                  marginBottom: "0.5rem",
                }}
              >
                Power(KW)
              </div>
              <div
                className="room_count"
                style={{
                  background: "#FE8600",
                }}
              >
                <div>In Services</div>
                <div>:</div>
                <div> {numberFormatToDecimalPlace(sold_power)}</div>
              </div>
              <div
                className="room_count"
                style={{
                  background: "#3feb7b",
                }}
              >
                <div>Available</div>
                <div>:</div>
                <div> {numberFormatToDecimalPlace(available)}</div>
              </div>
              <div
                className="room_count"
                style={{
                  background: "#1b70c0",
                }}
              >
                <div>Reserved</div>
                <div>:</div>
                <div> {numberFormatToDecimalPlace(reserved)}</div>
              </div>
              <div
                className="room_count"
                style={{
                  background: "#595959",
                }}
              >
                <div>ROFR</div>
                <div>:</div>
                <div> {numberFormatToDecimalPlace(rofr)}</div>
              </div>
              <div
                className="room_count"
                style={{
                  background: "#000000",
                  border: "1px solid white",
                }}
              >
                <div>Blocked</div>
                <div>:</div>
                <div> {numberFormatToDecimalPlace(blocked)}</div>
              </div>
            </div>
          </>
        }
      >
        <Button style={{ width: "11ch" }}>
          <Line2
            totalText={design_power}
            inServiceText={sold_power}
            availableText={available}
            inServicePercent={soldPer}
            availablePercent={availPer}
            totalPercent={0}
            reservedPer={reservedPer}
            rofrPer={rofrPer}
            blockedPer={blockedPer}
            reserved={reserved}
            rofr={rofr}
            blocked={blocked}
          />
        </Button>
      </HtmlTooltip>
    );
  };

  const checkIfExixt = (data, type) => {
    if (type == "layout") {
      const commonRoomIds = data.rooms.map((item) => item.id);

      const layoutAvailable = htmldata.filter((item) =>
        commonRoomIds.includes(item.room_id)
      );
      const count = layoutAvailable.length;
      if (data?.is_layout || count > 0) {
        return true;
      } else {
        return false;
      }
    }
    if (type == "power") {
      const commonRoomIds = data.rooms.map((item) => item.id);
      const results = htmldata.filter(
        (item) =>
          commonRoomIds.includes(item.room_id) && item.isPowerDataAvailable == 1
      );
      if (results.length > 0) {
        return true;
      } else {
        return false;
      }
    }
    if (type == "temp") {
      const commonRoomIds = data.rooms.map((item) => item.id);
      const results = htmldata.filter(
        (item) =>
          commonRoomIds.includes(item.room_id) &&
          item.isTemperatureDataAvailable == 1
      );
      if (results.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  };
  const getDcIndicataorTemperatureStatus = (data) => {
    const commonRoomIds = data.rooms.map((item) => item.id);
    const temperatureDataAvailable = htmldata.filter(
      (item) =>
        commonRoomIds.includes(item.room_id) &&
        item.isTemperatureDataAvailable == 1
    );
    const totalRooms = data.rooms.length;
    const count = temperatureDataAvailable.length;
    return (
      <HtmlTooltipForIndicator
        title={
          <>
            <div style={{ padding: "0rem 0px 0px 0px" }}>
              <div
                style={{
                  color: "white",
                  fontSize: "0.8rem",
                }}
              >
                {totalRooms == count
                  ? "Temperature and humidity data available"
                  : `Temperature and humidity data available ONLY in ${count}
                ${count > 1 ? " rooms" : " room"}`}
              </div>
            </div>
          </>
        }
      >
        <ThermostatIcon
          style={{
            cursor: "pointer",
            fontSize: "2rem",
            color: totalRooms == count ? "#fe8600" : "",
          }}
        />
      </HtmlTooltipForIndicator>
    );
  };
  const getDcIndicataorPowerStatus = (data) => {
    const commonRoomIds = data.rooms.map((item) => item.id);
    const powerDataAvailable = htmldata.filter(
      (item) =>
        commonRoomIds.includes(item.room_id) && item.isPowerDataAvailable == 1
    );
    const totalRooms = data.rooms.length;
    const count = powerDataAvailable.length;
    return (
      <HtmlTooltipForIndicator
        title={
          <>
            <div style={{ padding: "0rem 0px 0px 0px" }}>
              <div
                style={{
                  color: "white",
                  fontSize: "0.8rem",
                }}
              >
                {count == totalRooms
                  ? "Power data available"
                  : `Power data available ONLY in ${count}
                ${count > 1 ? " rooms" : " room"}`}
              </div>
            </div>
          </>
        }
      >
        <BoltIcon
          style={{
            cursor: "pointer",
            fontSize: "2rem",
            color: totalRooms == count ? "#fe8600" : "",
          }}
        />
      </HtmlTooltipForIndicator>
    );
  };
  const getDcIndicataorLayoutStatus = (data) => {
    const commonRoomIds = data.rooms.map((item) => item.id);

    const layoutAvailable = htmldata.filter((item) =>
      commonRoomIds.includes(item.room_id)
    );
    const totalRooms = data.rooms.length;
    const count = layoutAvailable.length;
    return (
      <HtmlTooltipForIndicator
        title={
          <>
            <div style={{ padding: "0rem 0px 0px 0px" }}>
              <div
                style={{
                  color: "white",
                  fontSize: "0.8rem",
                }}
              >
                {count == totalRooms
                  ? "Room layouts available"
                  : `${count} room ${
                      count > 1 ? "layouts" : "layout"
                    }  available`}
              </div>
            </div>
          </>
        }
      >
        <img
          src={
            count == 0
              ? "/images/Group 3647.svg"
              : count == totalRooms
              ? "/images/Group 3647 orange.svg"
              : "/images/Group 3647 black.svg"
          }
          alt="Layout popup"
          onClick={() => checkDataFloor(data)}
        />
      </HtmlTooltipForIndicator>
    );
  };
  const checkDataFloor = async (data) => {
    selectFloor(data);
    let filePath = `/images/${data?.datacenter?.country_id}/${data.data_center_id}/${data.id}/${data.id}.png`;

    return await fetch(filePath, { method: "HEAD" }).then((res) => {
      if (res.ok) {
        setFilePath(filePath);
        setFileExists(true);
      } else {
        setFileExists(false);
      }
      setShow(true);
    });
  };

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th
              onClick={() => {
                setFloorSort(!floorSort);
                if (floorSort === true) {
                  floorsData.sort((a, b) => (a.name > b.name ? 1 : -1));
                }
                if (floorSort === false) {
                  floorsData.sort((a, b) => (a.name < b.name ? 1 : -1));
                }
              }}
              style={{ cursor: "pointer" }}
            >
              {" "}
              {t("floor.Floors")}
              <i
                className={`fa fa-solid fa-sort-${floorSort ? "down" : "up"}`}
              ></i>
            </th>
            {!selectedDataCenter || selectedDataCenter?.name == "ALL" ? (
              <th
                onClick={() => {
                  setAscending(!ascending);
                  if (ascending) {
                    floorsData.sort((a, b) =>
                      a.datacenter.name > b.datacenter.name ? 1 : -1
                    );
                  }
                  if (!ascending) {
                    floorsData.sort((a, b) =>
                      a.datacenter.name < b.datacenter.name ? 1 : -1
                    );
                  }
                }}
                style={{ cursor: "pointer" }}
              >
                {" "}
                {t("floor.data_centre")}
                <i
                  className={`fa fa-solid fa-sort-${ascending ? "down" : "up"}`}
                ></i>
              </th>
            ) : (
              ""
            )}
            <th> {t("floor.cabinets")} </th>
            <th> {t("floor.cages")}</th>
            <th> {t("floor.power_kw")} </th>
            <th> {t("floor.layout")} </th>
          </tr>
        </thead>
        <tbody>
          {floorsData &&
            floorsData.map((data, key) => {
              return (
                <tr
                  key={key}
                  style={{ cursor: "pointer" }}
                  className={selectedFloor.id === data.id ? "tr_active" : ""}
                >
                  <td onClick={() => selectFloor(data)}>
                    {data.name}{" "}
                    <i
                      className="fa fa-circle"
                      style={{
                        color: data.status === 1 ? "#3FEB7B" : "#E0E2E5",
                      }}
                      aria-hidden="true"
                    ></i>
                  </td>
                  {!selectedDataCenter || selectedDataCenter?.name == "ALL" ? (
                    <td
                      className="custom_style"
                      onClick={() => selectFloor(data)}
                    >
                      {data?.datacenter?.name}{" "}
                    </td>
                  ) : (
                    ""
                  )}

                  <td onClick={() => selectFloor(data)}>
                    {getCabsStatus(data)}
                  </td>
                  <td onClick={() => selectFloor(data)}>
                    {getCagesStatus(data)}
                  </td>
                  <td onClick={() => selectFloor(data)}>
                    {getPowerStatus(data)}
                  </td>
                  <td>
                    <span style={{ display: "flex", alignItems: "center" }}>
                      <div style={{ width: "33.33%" }}>
                        {checkIfExixt(data, "layout")
                          ? getDcIndicataorLayoutStatus(data)
                          : ""}
                      </div>
                      <div style={{ width: "33.33%" }}>
                        {checkIfExixt(data, "power") ? (
                          <span>{getDcIndicataorPowerStatus(data)} </span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div style={{ width: "33.33%" }}>
                        {checkIfExixt(data, "temp") ? (
                          <span>{getDcIndicataorTemperatureStatus(data)}</span>
                        ) : (
                          ""
                        )}
                      </div>
                    </span>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      {show && (
        <DataFloorPopup
          show={show}
          setShow={setShow}
          fileExists={fileExists}
          filePath={filePath}
          selectedDataCenter={selectedDataCenter}
          selectedFloor={selectedFloor}
        />
      )}
    </div>
  );
};

export default FloorTable;
