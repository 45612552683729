import { numberFormat } from 'common/helpers';
import { XError } from 'components/common';
import AuthContext from 'context';
import React, { useContext, useEffect, useState } from 'react';
import Common from 'services/commonService';
import Floors from 'services/floorServices';
import Swal from 'sweetalert2';
import { LogoutOnSessionExpire } from 'common/helpers';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';

const EditFloor = (props) => {
  const authContext = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [statusData, setStatusData] = useState([]);
  const [dataState, setDataState] = useState({
    design_power: 0,
    design_cabs: 0,
    design_cages: 0,
    sold_power: 0,
    sold_cabs: 0,
    sold_cages: 0,
    reserved_power: 0,
    reserved_cabs: 0,
    reserved_cages: 0,
    rofr_power: 0,
    rofr_cabs: 0,
    rofr_cages: 0,
    blocked_power: 0,
    blocked_cabs: 0,
    blocked_cages: 0,
    available_power: 0,
    available_cabs: 0,
    available_cages: 0
  });

  const [state, setState] = useState({
    floor_id: '',
    name: '',
    status: '',
    cabs: '',
    power: ''
  });
  const [error, setError] = useState({
    name: '',
    status: '',
    cabs: '',
    power: ''
  });
  const { t } = useTranslation();

  useEffect(() => {
    if (authContext.getStatusData) {
      setStatusData(authContext.getStatusData);
    }
    // Common.status().then((res) => setStatusData(res.data.data))
    setState({
      floor_id: props.floor_data.id,
      name: props.floor_data.name,
      status: props.floor_data.status,
      cabs: props.floor_data.design_cabs || 0,
      power: props.floor_data.design_power || 0
    });
    calculateRoom();

    //props.selectDataCenterFloor(props.data_center_id);

    return () => {
      //setCountries([]);
      //setDataCenters([]);
      setState({});
    };
  }, [props.show]);

  const deleteFloor = async () => {
    closeModal();

    Swal.fire({
      title: t('common.are_you_sure'),
      text: t('common.you_wont_be_able_to_revert_this'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: t('common.yes_delete_it'),
      customClass: {
        cancelButton: 'cancelBtn',
        confirmButton: 'confirmBtn'
      }
    }).then(async (result) => {
      if (result.isConfirmed) {
        setState({ ...state, floor_id: props.floor_data.id });

        await Floors.deleteFloor(authContext.getToken, {
          ...state,
          floor_id: props.floor_data.id
        })
          .then(async (res) => {
            props.setMountComponent(!props.mountComponent);
            closeModal();
            //Swal.fire('Floor Deleted');
            props.selectDataCenterFloor(authContext.getNetworkDataCenter, 0);
          })
          .catch((err) => {
            LogoutOnSessionExpire(err);
            let error = {
              floor_id: ''
            };
            const errors = err?.response?.data?.errors;

            if (
              errors?.floor_id !== undefined ||
              errors?.floor_id !== '' ||
              errors?.floor_id !== null
            ) {
              error.floor_id = errors.floor_id;
            }

            setError({ ...error });
          });
      }
    });
  };

  const onSubmit = async () => {
    // setState({...state,data_center_id: props.data_center_id})
    setIsLoading(true);
    if (checkValidation()) {
      setState({ ...state, floor_id: props.floor_data.id });

      await Floors.updateFloor(authContext.getToken, {
        ...state,
        floor_id: props.floor_data.id,
        data_center_id: props.data_center_id.id
      })
        .then(async (res) => {
          setIsLoading(false);

          props.setMountComponent(!props.mountComponent);
          props.getFloorData(props.floor_data);
          closeModal();
          Swal.fire(t('datacenter.floor_updated'));
          props.setTotalDesignPower(res.data.totalPower);
          //props.selectDataCenterFloor(props.dataCenterId)
        })
        .catch((err) => {
          LogoutOnSessionExpire(err);
          setIsLoading(false);
          let error = {
            name: ''
          };
          const errors = err?.response?.data?.errors;

          if (errors?.name !== undefined || errors?.name !== '' || errors?.name !== null) {
            error.name = errors.name;
          }

          setError({ ...error });
        });
    } else {
      setIsLoading(false);
    }
  };

  const checkValidation = () => {
    let error = {
      name: '',
      /*soldCabinet:"",
			cabinet:"",
			kva:"",
			cages:"",
			soldCages:"",
			soldkva:"",*/
      status: ''
    };

    const {
      name
      /*soldCabinet,
			cabinet,
			kva,
			cages,
			soldCages,
			soldkva,
			status*/
    } = state;

    let flag = true;

    if (name === '' || name === null || name === undefined) {
      error.name = 'The name field is required.';
      flag = false;
    }

    //  if (soldCabinet === "" || soldCabinet === null || soldCabinet === undefined) {

    // 	error.soldCabinet = "The Room field is required.";
    // 	flag = false;
    // }
    /* if (cabinet === "" || cabinet === null || cabinet === undefined) {

			error.cabinet = "The cabinet field is required.";
			flag = false;
        }
		if (cages === "" || cages === null || cages === undefined) {

			error.cages = "The cages field is required.";
			flag = false;
        }
        if (kva === "" || kva === null || kva === undefined) {

			error.kva = "The kva field is required.";
			flag = false;
        }
*/
    setError({ ...error });

    return flag;
  };

  const closeModal = () => {
    props.setShow(false);
  };

  const calculateRoom = () => {
    const data = props.floor_data.rooms.reduce(
      (
        {
          preTotalCabs,
          preTotalCage,
          preTotalPower,
          preSoldCabs,
          preSoldCage,
          preSoldPower,
          preReservedCabs,
          preReservedCage,
          preReservedPower,
          preROFRCabs,
          preROFRCage,
          preROFRPower,
          preBlockedCabs,
          preBlockedCage,
          preBlockedPower,
          preAvailableCabs,
          preAvailableCage,
          preAvailablePower
        },
        {
          design_power,
          design_cabs,
          design_cages,
          sold_power,
          sold_cabs,
          sold_cages,
          reserved_power,
          reserved_cabs,
          reserved_cages,
          rofr_power,
          rofr_cabs,
          rofr_cages,
          blocked_power,
          blocked_cabs,
          blocked_cages,
          available_cabs,
          available_cages,
          available_power
        }
      ) => {
        return {
          preTotalPower: Number(preTotalPower) + Number(design_power),
          preTotalCabs: Number(preTotalCabs) + Number(design_cabs),
          preTotalCage: Number(preTotalCage) + Number(design_cages),
          preSoldPower: Number(preSoldPower) + Number(sold_power),
          preSoldCabs: Number(preSoldCabs) + Number(sold_cabs),
          preSoldCage: Number(preSoldCage) + Number(sold_cages),
          preReservedPower: Number(preReservedPower) + Number(reserved_power),
          preReservedCabs: Number(preReservedCabs) + Number(reserved_cabs),
          preReservedCage: Number(preReservedCage) + Number(reserved_cages),
          preROFRPower: Number(preROFRPower) + Number(rofr_power),
          preROFRCabs: Number(preROFRCabs) + Number(rofr_cabs),
          preROFRCage: Number(preROFRCage) + Number(rofr_cages),
          preBlockedPower: Number(preBlockedPower) + Number(blocked_power),
          preBlockedCabs: Number(preBlockedCabs) + Number(blocked_cabs),
          preBlockedCage: Number(preBlockedCage) + Number(blocked_cages),
          preAvailableCabs: Number(preAvailableCabs) + Number(available_power),
          preAvailableCage: Number(preAvailableCage) + Number(available_cages),
          preAvailablePower: Number(preAvailablePower) + Number(available_power)
        };
      },
      {
        preTotalCabs: 0,
        preTotalCage: 0,
        preTotalPower: 0,
        preSoldCabs: 0,
        preSoldCage: 0,
        preSoldPower: 0,
        preReservedCabs: 0,
        preReservedCage: 0,
        preReservedPower: 0,
        preROFRCabs: 0,
        preROFRCage: 0,
        preROFRPower: 0,
        preBlockedCabs: 0,
        preBlockedCage: 0,
        preBlockedPower: 0,
        preAvailableCabs: 0,
        preAvailableCage: 0,
        preAvailablePower: 0
      }
    );

    setDataState({ ...data });
  };

  return (
    <div
      className="modal show bd-example-modal-lg"
      style={{ display: 'block' }}
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header mt-59">
            <h3 className="modal-title"> {t('datacenter.edit_floor')} </h3>
            <CloseIcon style={{ cursor: 'pointer' }} onClick={() => closeModal()} />
          </div>
          <div className="modal-body">
            <div className="card">
              <div className="card-body" style={{ padding: '0px', overflow: 'hidden' }}>
                <div className="basic-form">
                  <form>
                    <div className="row">
                      <div className="mb-3 col-md-12 mt-2313">
                        <label className="form-label">
                          {' '}
                          {t('datacenter.name')} <small className="text-danger">*</small>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          defaultValue={state.name}
                          onChange={(event) => setState({ ...state, name: event.target.value })}
                        />
                        <XError message={error.name} />
                      </div>
                    </div>
                    {/*
                    <div className="row">
                      <div className="mb-3 col-md-12 mt-2313 dt_td">
                        <table>
                          <thead>
                            <tr style={{ borderBottom: "2px solid black" }}>
                              <th></th>
                              <th
                                style={{ fontWeight: "bold", color: "black" }}
                              >
                                {t("datacenter.cabEs")}
                              </th>
                              <th
                                style={{ fontWeight: "bold", color: "black" }}
                              >
                                {t("datacenter.cages")}
                              </th>
                              <th
                                style={{ fontWeight: "bold", color: "black" }}
                              >
                                {t("datacenter.kws")}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{t("datacenter.total")}: </td>
                              <td>{numberFormat(dataState.preTotalCabs)}</td>
                              <td>{numberFormat(dataState.preTotalCage)}</td>
                              <td>
                                {numberFormat(dataState.preTotalPower, 3)}
                              </td>
                            </tr>
                            <tr>
                              <td>{t("datacenter.sold")}: </td>
                              <td>{numberFormat(dataState.preSoldCabs)}</td>
                              <td>{numberFormat(dataState.preSoldCage)}</td>
                              <td>{numberFormat(dataState.preSoldPower, 3)}</td>
                            </tr>
                            <tr>
                              <td>{t("datacenter.reserved")}: </td>
                              <td>{numberFormat(dataState.preReservedCabs)}</td>
                              <td>{numberFormat(dataState.preReservedCage)}</td>
                              <td>
                                {numberFormat(dataState.preReservedPower, 3)}
                              </td>
                            </tr>
                            {/* <tr>
                              <td>{t('datacenter.rofr')}: </td>
                              <td>{numberFormat(dataState.preROFRCabs)}</td>
                              <td>{numberFormat(dataState.preROFRCage)}</td>
                              <td>{numberFormat(dataState.preROFRPower, 3)}</td>
                            </tr>
                            <tr>
                              <td>{t("datacenter.blocked")}: </td>
                              <td>{numberFormat(dataState.preBlockedCabs)}</td>
                              <td>{numberFormat(dataState.preBlockedCage)}</td>
                              <td>
                                {numberFormat(dataState.preBlockedPower, 3)}
                              </td>
                            </tr>
                            <tr>
                              <td>{t("datacenter.available")}: </td>
                              <td>
                                {numberFormat(dataState.preAvailableCabs)}
                              </td>
                              <td>
                                {numberFormat(dataState.preAvailableCage)}
                              </td>
                              <td>
                                {numberFormat(dataState.preAvailablePower, 3)}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    */}
                    {/*<div className="row">
            <div className="mb-3 col-md-6 mt-2313">
                <label className="form-label"> Total Cabinets <small className="text-danger">*</small></label>
               <input
                className="form-control"
                type="number"
                maxLength={9}
                placeholder="# of Cabinets"
                defaultValue={state.cabinet}
                style={{border:"oldlace"}}
                readOnly
                />
                <XError message={error.cabinet} />
            </div>

            <div className="mb-3 col-md-6 mt-2313">
                <label className="form-label"> Sold Cabinets <small className="text-danger hide">*</small></label>
                <input
                className="form-control"
                type="number"
                maxLength={9}
                placeholder="Sold Cabinets"
                defaultValue={state.soldCabinet}
                style={{border:"oldlace"}}
                readOnly
                />
                <XError message={error.soldCabinet} />
            </div>

            <div className="mb-3 col-md-6 mt-2313">
                <label className="form-label"> Total Cages <small className="text-danger">*</small></label>
               <input
                className="form-control"
                type="number"
                maxLength={9}
                placeholder="# of Cages"
                defaultValue={state.cages}
                style={{border:"oldlace"}}
                readOnly
                />
                <XError message={error.cages} />
            </div>

            <div className="mb-3 col-md-6 mt-2313">
                <label className="form-label"> Sold Cages <small className="text-danger hide">*</small></label>
                <input
                className="form-control"
                type="number"
                maxLength={9}
                placeholder="Sold Cages"
                defaultValue={state.soldCages}
                style={{border:"oldlace"}}
                readOnly
                />
                <XError message={error.soldCages} />
            </div>
        </div>
         <div className="row">
            <div className="mb-3 col-md-6 mt-2313">
                <label className="form-label"> Total kWs <small className="text-danger">*</small></label>
                 <input
                type="number"
                min="0.00000"
                step="0.00001"
                maxLength="11"
                className="form-control"
                placeholder="# of kWs"
                defaultValue={state.kva}
                style={{border:"oldlace"}}
                //onInput={(event) => fnValidate(event)}
                //onChange={(event) => validatePower(event)}
                readOnly

                />
                <XError message={error.kva} />
            </div>	*/}
                    {/*<div className="mb-3 col-md-6 mt-2313">
                <label className="form-label"> Sold kWs <small className="text-danger hide">*</small></label>
                <input
                className="form-control"
                type="number"
                maxLength={9}
                placeholder="Sold kWs"
                defaultValue={state.soldkva}
                style={{border:"oldlace"}}
                readOnly

                />
                <XError message={error.soldkva} />
            </div>
        </div>*/}
                    {/* Status */}
                    <div className="row">
                      <div className="mb-3 col-md-12 mt-2313">
                        <label className="form-label">
                          {' '}
                          {t('datacenter.status')} <small className="text-danger">*</small>
                        </label>
                        <select
                          value={state.status}
                          onChange={(event) => {
                            setState({
                              ...state,
                              status: event.target.value
                            });
                          }}
                          className="default-select form-control wide"
                        >
                          {statusData &&
                            statusData.map((status) => {
                              if (status.status_type_id === 1) {
                                return (
                                  <option value={status.id} key={status.id}>
                                    {status.status_name}
                                  </option>
                                );
                              }
                            })}
                        </select>
                        <XError message={error.status} />
                      </div>
                    </div>

                    {/* Cabs */}
                    <div className="row">
                      <div className="mb-3 col-md-12 mt-2313">
                        <label className="form-label"> {t('datacenter.cabs')} </label>
                        <input
                          value={state.cabs}
                          onChange={(event) => {
                            setState({
                              ...state,
                              cabs: Number(event.target.value)
                            });
                          }}
                          className="default-select form-control wide"
                        ></input>
                        <XError message={error.cabs} />
                      </div>
                    </div>

                    {/* Power */}
                    <div className="row">
                      <div className="mb-3 col-md-12 mt-2313">
                        <label className="form-label">Design {t('datacenter.k_w')} </label>
                        <input
                          value={state.power}
                          onChange={(event) => {
                            setState({
                              ...state,
                              power: Number(event.target.value)
                            });
                          }}
                          className="default-select form-control wide"
                        ></input>
                        <XError message={error.power} />
                      </div>
                    </div>

                    <div
                      className="toolbar toolbar-bottom mt-51 d-flex justify-content-between"
                      role="toolbar"
                    >
                      <div className="delt">
                        <button
                          type="button"
                          onClick={() => deleteFloor()}
                          style={{ marginRight: '1rem' }}
                          className="btn btn-outline-primary red_color mr_1"
                        >
                          <img
                            alt=""
                            src="\images\trash-2.svg"
                            style={{
                              width: '11px',
                              marginTop: '-0.188rem',
                              marginRight: '0.5rem'
                            }}
                          />
                          {t('common.delete')}
                        </button>
                      </div>
                      <div className="usr_cncl">
                        <button
                          type="button"
                          onClick={closeModal}
                          style={{ marginRight: '1rem' }}
                          className="btn btn-outline-primary"
                        >
                          {t('common.cancel')}
                        </button>

                        {isLoading ? (
                          <button type="button" className="btn btn-primary">
                            {' '}
                            {t('common.loading')}...{' '}
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={() => onSubmit()}
                            className="btn btn-primary"
                          >
                            {' '}
                            {t('common.save')}{' '}
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditFloor;
