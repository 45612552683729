// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-ticket-accordion {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.create-ticket-accordion .accordion-item .accordion-header {
  padding: 0px !important;
}
.create-ticket-accordion .accordion-item .accordion-header > * {
  color: #fff;
}
.create-ticket-accordion .accordion-item .accordion-header button {
  font-size: 16px;
  font-weight: 700;
}
.create-ticket-accordion .accordion-item .accordion-header button:after {
  display: none;
}
.create-ticket-accordion .accordion-item .accordion-header button:focus {
  border-color: #f0f7fe !important;
  box-shadow: 0 0 0 0.25rem rgba(248, 237, 234, 0.25) !important;
}

.create-ticket-accordion-inactive {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.create-ticket-accordion-inactive .accordion-header button:focus {
  border-color: #f0f7fe !important;
  box-shadow: 0 0 0 0.25rem rgba(248, 237, 234, 0.25) !important;
}`, "",{"version":3,"sources":["webpack://./src/views/super-admin/Tickets/shared/TicketAccordion/TicketAccordion.scss"],"names":[],"mappings":"AAAA;EACI,4BAAA;EACA,6BAAA;AACJ;AACQ;EACI,uBAAA;AACZ;AAAY;EACI,WAAA;AAEhB;AAAY;EACI,eAAA;EACA,gBAAA;AAEhB;AAAY;EACI,aAAA;AAEhB;AAAY;EAEI,gCAAA;EACA,8DAAA;AAChB;;AAMA;EACI,4BAAA;EACA,6BAAA;AAHJ;AAKQ;EACI,gCAAA;EACA,8DAAA;AAHZ","sourcesContent":[".create-ticket-accordion {\n    padding-left: 0px !important;\n    padding-right: 0px !important;\n    .accordion-item {\n        .accordion-header {\n            padding: 0px !important;\n            & > * {\n                color: #fff;\n            }\n            button {\n                font-size: 16px;\n                font-weight: 700;\n            }\n            button:after {\n                display: none;\n            }\n            button:focus{\n                // display: none;\n                border-color: #f0f7fe !important;\n                box-shadow: 0 0 0 0.25rem rgba(248, 237, 234, 0.25) !important;\n                // border: 0px !important;\n            }\n        }\n    }\n}\n\n.create-ticket-accordion-inactive {\n    padding-left: 0px !important;\n    padding-right: 0px !important;\n    .accordion-header {\n        button:focus{\n            border-color: #f0f7fe !important;\n            box-shadow: 0 0 0 0.25rem rgba(248, 237, 234, 0.25) !important;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
