import { useEffect } from "react"
import CloseIcon from "@mui/icons-material/Close"

const ErrorModel = (props) => {
  useEffect(() => {
    console.log(props)
  }, [props.show])
  const closeModal = () => {
    props.setShow(false)
  }
  return (
    <div
      class="modal show fade"
      id="exampleModal"
      style={{ display: "block" }}
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div
            class="modal-header"
            style={{ marginTop: "2.5rem", marginBottom: "2.5rem" }}
          >
            <h3 className="modal-title">
              {" "}
              {props?.errorMsg?.data == undefined
                ? props?.errorMsg?.error
                  ? props?.errorMsg?.error + `(${props?.errorMsg?.code})`
                  : "Something went wrong ! (403)"
                : props?.errorMsg?.data +
                  " (" +
                  (props?.errorMsg?.code != undefined
                    ? props?.errorMsg?.code
                    : "403") +
                  ")"}{" "}
            </h3>

            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => closeModal()}
            />
          </div>
          <div class="modal-body">
            {props?.errorMsg?.detail?.message
              ? props?.errorMsg?.detail?.message
              : props?.errorMsg?.detail}
          </div>
        </div>
      </div>
    </div>
  )
}
export default ErrorModel
