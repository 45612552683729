import { css } from 'glamor'
import { InputGroup } from 'react-bootstrap'
import './TicketCheckBox.scss'

const TicketCheckBox = ({ label, checked, fontSize, active, handelCheckboxToggle }) => {
  const styles = {
    container: {
      display: 'flex',
      jusifyContent: 'flex-start',
      alignItems: 'center'
    },
    label: {
    //   marginLeft: '6px',
      fontSize: fontSize || '16px'
    },
    checkbox: {
      width: '15px',
      height: '15px',
      border: '3px solid black'
    }
  }
  return (
    <div {...css(styles.container)} className='ticket-form-checkbox'>
      <InputGroup.Checkbox
        checked={checked}
        disabled={!active}
        onClick={handelCheckboxToggle}
      />
      <div {...css(styles.label)}>{label}</div>
    </div>
  )
}

export default TicketCheckBox
