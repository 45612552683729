import AuthContext from 'context';
import React, { useContext, useEffect, useState } from 'react';
import Loading from '../pre-loader/loader';
import Floors from 'services/floorServices';
// import RoleModel from "services/roleServices";
import Common from 'services/commonService';
import Layout from '../dashboard-layout';
import {
  Cabling,
  DashbordCard,
  FloorCard,
  MenuTab,
  Network,
  RoomCard,
  SelectedDataCenter,
  CabinetOrBreaker,
} from "./components";
import { cardData } from "./data";
import { LogoutOnSessionExpire } from "common/helpers";
import CabinetServices from "services/CabinetService";
import CrossConnect from "../Setting/DataCenter/CrossConnect";
import CrossConnectTable from "../Setting/DataCenter/CrossConnect/CrossConnectTable";
import "./Dashboard.css";
import useGetCabinetsAndBreakers from "./useGetCabinetsAndBreakers";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import TechDocs from "./components/Tech Docs";

const Dashboard = () => {
  const incrementStep = 100;
  const location = useLocation();
  const contextStore = useContext(AuthContext);
  const [selectedDataCenter, setSelectedDataCenter] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedFloor, selectFloor] = useState('');
  const [floorSort, setFloorSort] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [hallSort, setHallSort] = useState(true);
  const [selectedRoom, setselectedRoom] = useState(null);
  const [editCabinets, setEditCabinets] = useState();
  const [showCabinetsEdit, setShowCabinetsEdit] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [statusData, setStatusData] = useState([]);
  const [nextCabinet, setNextCabinet] = useState(0);
  const [nextBreaker, setNextBreaker] = useState(0);
  const [mountForFetch, setMountForFetch] = useState(true);
  const [menuTab, setMenuTab] = useState({
    inventory: true,
    crossConnect: false,
    capacity: false,
    cabling: false,
    network: false,
    techDocs: false,
  });
  const [navigationActiveTab, setNavigationActiveTab] = useState(1);
  const [floorsData, setFloorsData] = useState([]);
  const [htmldata, setHtmlData] = useState([]);
  const { t, i18n } = useTranslation();
  const [query, setQuery] = useState('');
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);

  const dcAllowed = contextStore.getdcId;

  // id of the dc that are allowed to get bcm data from odoo table
  const downloadBCMData = [
    {
      MNL1: 9
    }
  ];
  const isDCAllowed = (data, value) => {
    return data?.some((item) => Object.values(item).includes(value));
  };

  const result = isDCAllowed(downloadBCMData, dcAllowed);

  const searchCategoryCabinets = [
    { name: t(`datacenter.name`), field: 'name' },
    { name: t(`datacenter.reseller`), field: 'reseller_name' },
    { name: t(`datacenter.customer`), field: 'customer_name' },
    { name: t(`datacenter.status`), field: 'status' },
    { name: t(`datacenter.breakers`), field: 'num_breakers' },
    { name: t(`datacenter.x_connects`), field: 'num_xconnects' }
  ];
  const searchCategoryBreakers = [
    { name: t(`breaker.name`), field: result ? 'breaker_id' : 'name' },
    // { name: t(`breaker.floor`), field: "floor_name" },
    { name: t(`breaker.room`), field: result ? 'room' : 'room_name' },
    { name: t(`breaker.panel`), field: 'pwr_panel_id' },
    { name: t(`breaker.status`), field: 'status_name' },
    { name: t(`breaker.upstream_device`), field: 'upstream_device_name' },
    {
      name: t(`breaker.service_cabinet`),
      field: result ? 'cabinet_name' : 'svc_cabinet_name'
    }
  ];
  const {
    searchQuery,
    setSearchQuery,
    cabinetAscending,
    setCabinetAscending,
    cabinets,
    breakers,
    getCabinetsData,
    getBreakersData,
    filterCabinets,
    filterBreakers,
    filterMNLBreakers,
    setCabinets,
    setBreakers,
    lastRefreshTime,
    total,
  } = useGetCabinetsAndBreakers({
    selectedRoom,
    setselectedRoom,
    setIsLoading,
    nextCabinet,
    setNextCabinet,
    setMountForFetch,
    mountForFetch,
    nextBreaker,
    setNextBreaker,
    query,
    setQuery,
    setPageNumber,
    setItemsPerPage
  });

  const getCurrentPageNext = () => {
    console.log('here');
    switch (navigationActiveTab) {
      case 1:
        setNextCabinet((prev) => {
          return Number(prev) + Number(incrementStep);
        });
        break;
      case 2:
        setNextBreaker((prev) => {
          return Number(prev) + Number(incrementStep);
        });

        break;
    }
  };

  useEffect(() => {
    setQuery('');
    if (navigationActiveTab === 1 && !!selectedRoom) {
      if (pageNumber == 1) {
        filterCabinets(1, itemsPerPage, selectedRoom, false);
      } else {
        setPageNumber(1);
      }
    }
    if (navigationActiveTab === 2 && !!selectedRoom) {
      if (pageNumber == 1) {
        filterBreakers(1, itemsPerPage, selectedRoom, false);
      } else {
        setPageNumber(1);
      }
    }
    return () => cleanUpTask();
  }, [navigationActiveTab]);

  const getData = (offset, selectedRoom) => {
    if (navigationActiveTab === 1 && !!selectedRoom) {
      filterCabinets(pageNumber, itemsPerPage, selectedRoom, false);
    }
    if (navigationActiveTab === 2 && !!selectedRoom) {
      filterBreakers(pageNumber, itemsPerPage, selectedRoom, false);
    }
  };

  const cleanUpTask = () => {
    setCabinets([]);
    setBreakers([]);
    setNextCabinet(0);
    setNextBreaker(0);
    setMountForFetch(true);
  };

  useEffect(() => {
    console.log("hi pagenumber");
    if (navigationActiveTab == 1) {
      filterCabinets(pageNumber, itemsPerPage, selectedRoom);
    }
    if (navigationActiveTab == 2) {
      filterBreakers(pageNumber, itemsPerPage, selectedRoom);
    }
  }, [pageNumber, query]);

  useEffect(() => {
    contextStore.setDataCenterFloor([]);
    setFloorsData([]);
    setHtmlData([]);
    setCabinets([]);
    setBreakers([]);
    setQuery('');
    setIsLoading(true);
    getFloorData();
    setFloorSort(true);
    setHallSort(true);
    if (contextStore.getStatusData) {
      setStatusData(contextStore.getStatusData);
    } else {
      LogoutOnSessionExpire();
    }
  }, [selectedDataCenter]);

  const getFloorData = async () => {
    if (
      selectedDataCenter &&
      selectedDataCenter.name === 'ALL' &&
      selectedCountry === '' &&
      location.state == null
    ) {
      await Floors.findAllFloor(contextStore.token())
        .then((res) => {
          contextStore.setDataCenterFloor(res.data.data);
          setFloorsData(res.data.data);
          setHtmlData(res.data.html);
        })
        .catch((err) => {
          LogoutOnSessionExpire(err);
          /*500 internal server error*/
        })
        .finally(() => setIsLoading(false));
    } else if (
      selectedCountry !== '' &&
      location.state == null &&
      selectedDataCenter &&
      selectedDataCenter.name === 'ALL'
    ) {
      await Floors.floorByCountryId(contextStore.token(), selectedCountry)
        .then((res) => {
          contextStore.setDataCenterFloor(res.data.data);
          setFloorsData(res.data.data);

          // console.log("Html records---",res.data.html);
          setHtmlData(res.data.html);
        })
        .catch((err) => {
          LogoutOnSessionExpire(err);
        })
        .finally(() => setIsLoading(false));
    } else {
      if (selectedDataCenter != '' && selectedDataCenter.id != 'all') {
        await Floors.floorByDataCenterId(contextStore.token(), selectedDataCenter)
          .then((res) => {
            contextStore.setdcId(res.data.data[0].data_center_id); // setting the dcId for OP-1118 to check if dc is acc to the ticket
            contextStore.setDataCenterFloor(res.data.data);
            setFloorsData(res.data.data);

            setHtmlData(res.data.html);
          })
          .catch((err) => {
            LogoutOnSessionExpire(err);
            /*500 internal server error*/
          })
          .finally(() => setIsLoading(false));
      }
    }
  };

  const getStatus = (id) => {
    let statusDataMod = statusData.filter((data) => {
      return data.id === id;
    });

    if (statusDataMod && statusDataMod.length) {
      return statusDataMod[0].status_name;
    }

    return null;
  };
  const getEditCabinetPopup = (res) => {
    setEditCabinets(res);
    setShowCabinetsEdit(true);
  };

  return (
    <React.Fragment>
      <Loading loading={isLoading} background="rgba(0, 0, 0, 0.4)" loaderColor="rgb(248, 153, 3)" />
      <Layout
        selectedDataCenter={selectedDataCenter}
        setSelectedDataCenter={setSelectedDataCenter}
        selectedCountry={selectedCountry}
        setSelectedCountry={setSelectedCountry}
        getFloorData={getFloorData}
        page={'data-center'}
      >
        <div className="bg_color_dash">
          <div className="container-fluid pb-5">
            <div className="row pt-2">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 gx-3">
                <div className="row g-2 mt-2">
                  <DashbordCard cardData={cardData} floorsData={floorsData} />
                </div>
                <div className="row g-2">
                  <div className="invglob">
                    <MenuTab
                      setMenuTab={setMenuTab}
                      menuTab={menuTab}
                      getFloorData={getFloorData}
                    />
                    {menuTab.inventory ? (
                      <SelectedDataCenter selectedDataCenter={selectedDataCenter} />
                    ) : null}
                  </div>
                </div>
                {menuTab.inventory ? (
                  <div className="row mt-2 g-1 gx-3 pb-4">
                    <div className="dashboard-floor-card">
                      <FloorCard
                        selectedDataCenter={selectedDataCenter}
                        selectedFloor={selectedFloor}
                        selectFloor={selectFloor}
                        setFloorSort={setFloorSort}
                        floorSort={floorSort}
                        htmldata={htmldata}
                        setHtmlData={setHtmlData}
                        floorsData={floorsData}
                      />
                      <RoomCard
                        selectedFloor={selectedFloor}
                        selectedDataCenter={selectedDataCenter}
                        hallSort={hallSort}
                        setHallSort={setHallSort}
                        getCabinetsData={getData}
                        htmldata={htmldata}
                        setHtmlData={setHtmlData}
                        setIsLoading={setIsLoading}
                      ></RoomCard>
                    </div>

                    <div className="col-xl-5 col-lg-5"></div>
                    <CabinetOrBreaker
                      selectedRoom={selectedRoom}
                      searchQuery={searchQuery}
                      filterCabinets={filterCabinets}
                      filterBreakers={filterBreakers}
                      cabinetAscending={cabinetAscending}
                      setCabinetAscending={setCabinetAscending}
                      cabinets={cabinets}
                      breakers={breakers}
                      getEditCabinetPopup={getEditCabinetPopup}
                      isReadOnly={isReadOnly}
                      getStatus={getStatus}
                      navigationActiveTab={navigationActiveTab}
                      setNavigationActiveTab={setNavigationActiveTab}
                      setSearchQuery={setSearchQuery}
                      getCurrentPageNext={getCurrentPageNext}
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                      lastRefreshTime={lastRefreshTime}
                      searchCategory={
                        navigationActiveTab == 1 ? searchCategoryCabinets : searchCategoryBreakers
                      }
                      query={query}
                      setQuery={setQuery}
                      setPageNumber={setPageNumber}
                      setItemsPerPage={setItemsPerPage}
                      total={total}
                      pageNumber={pageNumber}
                    />
                  </div>
                ) : null}
                {menuTab.capacity ? <div className="row mt-2"></div> : null}
                {menuTab.cabling ? (
                  <div className="row mt-2">
                    <Cabling selectedDataCenter={selectedDataCenter} />
                  </div>
                ) : null}
                {menuTab.network ? (
                  <div className="row mt-2">
                    <Network selectedDataCenter={selectedDataCenter} />
                  </div>
                ) : null}
                {menuTab.crossConnect ? (
                  <div className="row mt-2">
                    <CrossConnectTable
                      selectedDataCenter={selectedDataCenter}
                      setIsLoading={setIsLoading}
                      isReadOnly={true}
                    />
                  </div>
                ) : null}
                {menuTab.techDocs ? (
                  <div className="row mt-2">
                    <TechDocs
                      selectedDataCenter={selectedDataCenter}
                      setIsLoading={setIsLoading}
                      selectedCountry={selectedCountry}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default Dashboard;