/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState, useContext, useRef } from "react";
import Layout from "../../Layouts";
import Navigation from "../Component/Navigation";
import DataCenterNav from "./Navigation";
import RoleModel from "services/roleServices";
import CabinetServices from "services/CabinetService";
import DataCenterChart from "services/dataCenterChart";
import Floors from "services/floorServices";
import AuthContext from "context";
import Floor from "./floor";
import EditFloor from "./editFloor";
import CreateRoom from "./room";
import EditRoom from "./editRoom";
import EditCabinet from "./editCabinet";
import CreateDataCenter from "./dataCenter";
import "./dataCenter.css";
import { LogoutOnSessionExpire } from "common/helpers";
import Loading from "views/super-admin/pre-loader/loader";
import { numberFormat } from "common/helpers";
import Swal from "sweetalert2";
import { useGetBreakersByRoom } from "./shared/useGetBreakersByRoom";
import { useTranslation } from "react-i18next";
import Common from "services/commonService";
import SerachBox from "../Component/searchBox";
import Pagination from "views/super-admin/Ticket/components/shared/usePagination";
const DataCenter = (props) => {
  const authContext = useContext(AuthContext);
  const [dataCenter, setDataCenter] = useState([]);
  const [currentDataCenter, setCurrentDataCenter] = useState([]);
  const [allFloorData, setAllFloorData] = useState([]);
  const [room, setRoom] = useState([]);
  const [selectedRoom, setselectedRoom] = useState(null);
  const [cabinets, setCabinets] = useState([]);
  const [floorIndex, setFloorIndex] = useState(0);
  const [editFloorData, setEditFloorData] = useState();
  const [showFloorEdit, setShowFloorEdit] = useState(false);
  const [editRoom, setEditRoom] = useState();
  const [showRoomEdit, setShowRoomEdit] = useState(false);
  const [editCabinets, setEditCabinets] = useState();
  const [showCabinetsEdit, setShowCabinetsEdit] = useState(false);
  const [countryName, setCountryName] = useState("Country");
  const [currentTab, setCurrentTab] = useState(0);
  const [activeTab, setActiveTab] = useState();
  const [country, setCountry] = useState(0);
  const initialMount = useRef(true);
  const [isLoading, setIsLoading] = useState(true);
  const [ascending, setAscending] = useState(true);
  const [roomAscending, setRoomAscending] = useState(true);
  const [cabinetAscending, setCabinetAscending] = useState(true);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [statusData, setStatusData] = useState([]);
  const [statusPmi, setStatusPmi] = useState(0);
  const [countries, setCountries] = useState([]);
  const [mountComponent, setMountComponent] = useState(false);
  const [floorKey, setFloorKey] = useState(0);
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const cabinetRef = useRef();
  const [nextCabinet, setNextCabinet] = useState(0);
  const [mountForEach, setMountForFetch] = useState(true);
  const { t } = useTranslation();
  const [query, setQuery] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [totalDesignPower, setTotalDesignPower] = useState();
  const [total, setTotal] = useState(0);
  const floorPower = [];

  const searchCategory = [
    { name: t(`datacenter.name`), field: "name" },
    { name: t(`datacenter.reseller`), field: "reseller_name" },
    { name: t(`datacenter.customer`), field: "customer_name" },
    { name: t(`datacenter.status`), field: "status" },
    { name: t(`datacenter.breakers`), field: "num_breakers" },
    { name: t(`datacenter.x_connects`), field: "num_xconnects" },
  ];

  const changeTotalPower = () => {
    if (parseFloat(totalDesignPower) === 0 || null) {
      const total = floorPower.reduce((sum, power) => sum + power, 0);
      setTotalDesignPower(total);
    }
  };

  useEffect(() => {
    console.log(currentDataCenter);
    setTotalDesignPower(currentDataCenter.design_power);
  }, [currentDataCenter]);

  useEffect(() => {
    console.log(currentDataCenter);
    setTotalDesignPower(currentDataCenter.design_power);
    getData();

    if (
      authContext?.getAuth?.role?.space === 3 ||
      authContext?.getAuth?.role?.m_e === 3 ||
      authContext?.getAuth?.role?.network === 3
    ) {
      setIsReadOnly(false);
    }

    if (initialMount.current === false) {
      selectDataCenterFloor(authContext.getNetworkDataCenter, floorIndex);
    } else {
      if (authContext.getStatusData) {
        setStatusData(authContext.getStatusData);
      } else {
        LogoutOnSessionExpire();
      }
      // Common.status()
      //   .then((res) => setStatusData(res.data.data))
      //   .catch((err) => LogoutOnSessionExpire(err))
    }
    getAllDataCenter(roomAscending);

    return () => cleanTask();
    console.log("first useEffect");
  }, [mountComponent]);

  useEffect(() => {
    // cabinetRef.current?.scrollTo(0, 0);
    cabinetRef.current?.addEventListener("scroll", handlescroll);
    return () =>
      cabinetRef.current?.removeEventListener("scroll", handlescroll);
  }, []);

  const handlescroll = () => {
    if (!isLoading) {
      const scrollTop = cabinetRef.current?.scrollTop;
      const scrollHeight = cabinetRef.current?.scrollHeight;
      const clientHeight = cabinetRef.current?.clientHeight;
      if (scrollTop + clientHeight + 1 > scrollHeight) {
        setNextCabinet((prev) => Number(prev) + Number(100));
      }
    }
  };

  const { breakerList } = useGetBreakersByRoom(selectedRoom?.id);

  const cleanUpTask = () => {
    setSearchQuery("");
    setselectedRoom(null);
  };

  const getData = async () => {
    await RoleModel.countryService(authContext.token())
      .then((res) => {
        setCountries(res.data.data);
      })
      .catch((err) => LogoutOnSessionExpire(err));
  };

  const getFloorData = (data) => {
    setRoomAscending(true);
    const sortedData = data.rooms.sort((a, b) => (a.name < b.name ? 1 : -1));

    setRoom(sortedData);
    if (sortedData.length > 0) {
      setNextCabinet(0);
      setCabinets([]);
      setMountForFetch(false);
      if (pageNumber == 1) {
        getCabinetsList(sortedData[0]);
      } else {
        setPageNumber(1);
      }
    } else {
      setselectedRoom(sortedData[0]);
      setNextCabinet(0);
      setCabinets([]);
      setMountForFetch(false);
    }
  };

  const getStatus = (id) => {
    let statusDataMod = statusData.filter((data) => {
      return data.id === id;
    });

    if (statusDataMod && statusDataMod.length) {
      return statusDataMod[0].status_name;
    }

    return null;
  };

  const getCabinetsList = async (room) => {
    if (!!room) {
      setIsLoading(true);
      setCabinetAscending(true);
      setselectedRoom(room);
      let body = {
        room_id: room.id,
        page: pageNumber,
        pageSize: itemsPerPage,
      };
      if (query !== "") {
        body[query.name] = query["value"];
      }

      await CabinetServices.searchCabinet(authContext.token(), body)
        .then((res) => {
          setStatusPmi(res.data.pmi.length);
          if (res.data.data.length > 0) {
            setCabinets(
              res.data.data.sort((a, b) => (a.name < b.name ? 1 : -1))
            );
            setTotal(res.data.total);
          } else {
            setCabinets([]);
            setTotal(0);
          }
        })
        .catch((err) => LogoutOnSessionExpire(err))
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    getCabinetsList(selectedRoom);
  }, [query, pageNumber]);

  const getAllDataCenter = async () => {
    setCountryName("Country");

    await RoleModel.dataCenter(authContext.token())
      .then((res) => {
        const data = res.data.data.filter((data) => data.dc_type_id === 1);

        setDataCenter(data);

        if (data.length > 0) {
          if (
            authContext.getNetworkDataCenter === undefined ||
            authContext.getNetworkDataCenter.length === 0
          ) {
            authContext.setNetworkDataCenter(data[0]);
          }
          if (initialMount.current) {
            setActiveTab(authContext.getNetworkDataCenter.id);

            selectDataCenterFloor(data[0]);

            setCountry(authContext.getNetworkDataCenter.country_id);
            initialMount.current = false;
          }
        }
      })
      .catch((err) => LogoutOnSessionExpire(err));
  };

  const getDataCenterById = async (e) => {
    setCountryName(e.name);
    setMountForFetch(false);
    await RoleModel.dataCenterByCountryId(authContext.token(), e)
      .then((res) => {
        setDataCenter(res.data.data);
        if (res.data.data.length > 0) {
          setActiveTab(res.data.data[0].id);
          selectDataCenterFloor(res.data.data[0]);
        }
      })
      .catch((err) => LogoutOnSessionExpire(err));
  };

  const cleanTask = () => {
    setNextCabinet(0);
    setCabinets([]);
  };

  // const getCabinetsData = async (e) => {
  //   cleanUpTask();
  //   // cleanTask();
  //   setNextCabinet(0);
  //   setCabinets([]);
  //   setCabinetAscending(true);
  //   setIsLoading(true);
  //   setMountForFetch(false);
  //   setselectedRoom(e);
  //   await CabinetServices.selectByHallId(authContext.token(), {
  //     room_id: e.id,
  //     offset: 0,
  //   })
  //     .then((res) => {
  //       setStatusPmi(res.data.pmi.length);
  //       setMountForFetch(true);
  //       //	if(res.data.data.length > 0){
  //       setCabinets(res.data.data.sort((a, b) => (a.name < b.name ? 1 : -1)));
  //       // console.log("first",res.data.data.sort((a, b) => (a.name < b.name ? 1 : -1)))
  //       //	}
  //     })
  //     .catch((err) => LogoutOnSessionExpire(err))
  //     .finally(() => setIsLoading(false));
  // };

  const saveDesignPower = async () => {
    setIsLoading(true);
    console.log(currentDataCenter, totalDesignPower);
    await DataCenterChart.update(authContext.token(), {
      data_center_id: currentDataCenter.id,
      total_design_power: totalDesignPower,
    })
      .then((res) => {
        console.log(res.data.data);
        Swal.fire("Data center has been updated!");
        //authContext.setNetworkDataCenter(res.data.data)
        //setCurrentDataCenter({...currentDataCenter, design_power: res.data.data.design_power})
        const updatedDataCenters = dataCenter.map((dc) => {
          if (dc.id === res.data.data.id) {
            // Return a new object with updated design_power
            return { ...dc, design_power: res.data.data.design_power };
          }
          return dc; // Return other data centers unchanged
        });

        // Update the state with the modified array
        setDataCenter(updatedDataCenters);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        Swal.fire({
          text: err.error.response.data.message,
          icon: "warning",
          showCancelButton: false,
        }).then((result) => {});
        setIsLoading(false);
        LogoutOnSessionExpire(err);
      });
  };

  const selectDataCenterFloor = async (e, floor_id = 0) => {
    cleanUpTask();

    setIsLoading(true);
    authContext.setNetworkDataCenter(e);
    setAscending(true);
    setCurrentDataCenter(e);
    setTotalDesignPower(e.design_power === null ? 0 : e.design_power);
    var getId = false;
    if (initialMount.current) {
      getId = true;
    }

    setAllFloorData([]);
    setRoom([]);
    setNextCabinet(0);
    setCabinets([]);
    setMountForFetch(false);
    await Floors.floorByDataCenterId(authContext.token(), e)
      .then((res) => {
        if (res.data.data.length > 0) {
          setAllFloorData(res.data.data);

          if (floor_id !== 0) {
            let newData;
            newData = res.data.data.filter((data, key) => {
              return data.id === floor_id;
            });

            if (newData[0] !== undefined) {
              setRoom(newData[0].rooms);
              if (newData[0].rooms.length > 0) {
                if (pageNumber == 1) {
                  getCabinetsList(newData[0].rooms);
                } else {
                  setPageNumber(1);
                }
              }
              setFloorIndex(newData[0].id);
              setActiveTab(newData[0].id);
            }
          }

          if (getId === true || floor_id === 0) {
            if (res.data.data[0] !== undefined) {
              setRoom(res.data.data[0].rooms);
              if (res.data.data[0].rooms.length > 0) {
                if (pageNumber == 1) {
                  getCabinetsList(res.data.data[0].rooms[0]);
                } else {
                  setPageNumber(1);
                }
              }
              setFloorIndex(res.data.data[0].id);
              setActiveTab(res.data.data[0].id);
            }
          }
        } else {
          setFloorIndex(null);
        }
      })
      .catch((err) => LogoutOnSessionExpire(err));
    setIsLoading(false);
  };

  const renderCountry = () => {
    return (
      countries.length &&
      countries.map((data, i) => {
        return (
          <a
            href="#sd"
            className="dropdown-item"
            key={i}
            onClick={() => {
              setCurrentTab(0);
              setCountry(data.id);
              getDataCenterById(data);
            }}
          >
            {data.name}{" "}
          </a>
        );
      })
    );
  };

  // const addSelect = () => {

  // }

  const renderDataCenter = () => {
    return (
      dataCenter &&
      dataCenter.map((data, i) => {
        return (
          <li
            className="nav-item"
            key={i}
            onClick={() => {
              setCurrentTab(data.id);
              selectDataCenterFloor(data);
            }}
          >
            <a
              href="#sd"
              className={`nav-link ${
                currentTab === 0 && i === 0 ? "active show" : ""
              }`}
            >
              {data.name}
            </a>
          </li>
        );
      })
    );
  };

  const getEditFloorPopup = (res) => {
    setEditFloorData(res);
    setShowFloorEdit(true);
  };

  const getEditRoomPopup = (res) => {
    setEditRoom(res);
    setShowRoomEdit(true);
  };

  const getEditCabinetPopup = (res) => {
    setEditCabinets(res);
    setShowCabinetsEdit(true);
  };

  const deleteDataCenter = () => {
    Swal.fire({
      title: t("common.are_you_sure"),
      text: t("common.you_wont_be_able_to_revert_this"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("common.yes_delete_it"),
      customClass: {
        cancelButton: "cancelBtn",
        confirmButton: "confirmBtn",
      },
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          await DataCenterChart.destroy(authContext.token(), {
            data_center_id: currentDataCenter.id,
          }).then(async (res) => {
            await Floors.findAllFloor(authContext.token())
              .then((res) => {
                Swal.fire("Data center has been deleted!");
                setMountComponent(!mountComponent);
                authContext.setDataCenterFloor(res.data.data);
              })
              .catch((err) => {
                LogoutOnSessionExpire(err);
                /*500 internal server error*/
              });
          });

          const newDataCenter = dataCenter.filter(
            (center) => center.id !== currentDataCenter.id
          );

          setCurrentDataCenter(newDataCenter[0]);
          setActiveTab(newDataCenter[0].id);
          selectDataCenterFloor(newDataCenter[0]);
          setCountry(newDataCenter[0].country_id);
        }
      })
      .catch((err) => LogoutOnSessionExpire(err));
  };

  return (
    <React.Fragment>
      <Loading loading={isLoading} />
      <Layout>
        <div className="content-body">
          <Navigation />
          <hr className="main" />
          <div className="container-fluid pt-0">
            <div className="row ">
              <DataCenterNav />
              <div className="col-lg-11">
                <div id="pro">
                  <div className="profile-tab">
                    <div className="custom-tab-1">
                      <div className="main_scrll">
                        <div className="btn_ul">
                          <ul className="nav nav-tabs mb-3">
                            <li className="nav-item">
                              {" "}
                              <button
                                className="btn btn-secondary"
                                style={{
                                  width: "100%",
                                  background:
                                    countryName != "Country"
                                      ? "#EAECF2"
                                      : "#11263C",
                                  color:
                                    countryName != "Country"
                                      ? "#000000"
                                      : "#ffffff",
                                  border:
                                    countryName != "Country" ? "none" : "",
                                }}
                                onClick={() => {
                                  initialMount.current = true;
                                  getAllDataCenter();
                                }}
                              >
                                {" "}
                                {t("datacenter.global")}{" "}
                              </button>
                            </li>
                            <li className="nav-item">
                              <div className="btn-group" role="group">
                                <button
                                  type="button"
                                  className="btn btn-light dropdown-toggle"
                                  style={{
                                    width: "100%",
                                    background:
                                      countryName == "Country"
                                        ? "#EAECF2"
                                        : "#11263C",
                                    color:
                                      countryName == "Country"
                                        ? "#000000"
                                        : "#ffffff",
                                    border:
                                      countryName == "Country" ? "none" : "",
                                  }}
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  {" "}
                                  {countryName}{" "}
                                </button>
                                <div
                                  className="dropdown-menu"
                                  style={{ margin: "0px" }}
                                >
                                  {renderCountry()}
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="outer-div-setting ">
                          <div className="ul_scrll">
                            <ul className="nav nav-tabs mb-3">
                              {dataCenter &&
                                dataCenter.map((data, index) => {
                                  if (
                                    authContext.getNetworkDataCenter &&
                                    authContext.getNetworkDataCenter.id ===
                                      data.id
                                  ) {
                                    return (
                                      <li
                                        className={
                                          index === 0 ? "nav-item" : "nav-item"
                                        }
                                        key={index}
                                      >
                                        <a
                                          href="#sd"
                                          onClick={() =>
                                            selectDataCenterFloor(data)
                                          }
                                          style={{ cursor: "pointer" }}
                                          className="nav-link active show"
                                        >
                                          <img
                                            className="down setting_down"
                                            src="\images\downward-arrow.png"
                                          />
                                          {data.name}
                                        </a>
                                      </li>
                                    );
                                  } else {
                                    return (
                                      <li
                                        className={
                                          index === 0 ? "nav-item" : "nav-item"
                                        }
                                        key={index}
                                      >
                                        <a
                                          href="#sd"
                                          onClick={() => {
                                            selectDataCenterFloor(data);
                                          }}
                                          style={{ cursor: "pointer" }}
                                          className="nav-link"
                                        >
                                          {" "}
                                          {data.name}{" "}
                                        </a>
                                      </li>
                                    );
                                  }
                                })}
                            </ul>
                          </div>
                          <div>
                            <span>
                              <i>
                                {t(
                                  "datacenter.select_a_country_to_view_partner_data_center"
                                )}
                              </i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="title" style={{ marginBottom: "-2.687rem" }}>
                    <div className="row">
                      <h5 className="card-title col-md-6">
                        {" "}
                        {t("datacenter.inventory_for")} {currentDataCenter.name}{" "}
                      </h5>
                      <p className="col-md-6" style={{ textAlign: "right" }}>
                        {isReadOnly === false ? (
                          <a
                            href="#sd"
                            id="addnewdatacenter"
                            data-bs-toggle="modal"
                            data-bs-target=".bd-example-modal-lg2"
                          >
                            <img
                              src="\images\plus-circle-blue.svg"
                              style={{ width: "1.25rem" }}
                            />{" "}
                            &nbsp;{t("datacenter.add_data_center")}
                          </a>
                        ) : null}
                      </p>
                    </div>
                    <div className="row mb-3">
                      <div className="col-12 col-sm-12 col-lg-12">
                        <div className="form-check d-flex align-items-center">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={statusPmi != 0 ? "checked" : ""}
                            readOnly
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault1"
                            style={{ marginBottom: "0px" }}
                          >
                            {t("datacenter.inventory_data_from_pmi_if_checked")}
                          </label>
                        </div>
                      </div>
                    </div>
                    <p>
                      {" "}
                      {t(
                        "datacenter.each_data_center_can_set_capacity_thresholds_at_the_data_center_floor_or_room_level"
                      )}{" "}
                    </p>
                  </div>

                  <div className="row">
                    <div className="col-xl-4 col-lg-4">
                      <div className="inv">
                        <div className="invtab">
                          <p>{t("datacenter.floor")}</p>

                          {isReadOnly === false ? (
                            <p style={{ marginBottom: "4px" }}>
                              <a
                                href="#sd"
                                id="addneww"
                                data-bs-toggle="modal"
                                data-bs-target=".bd-example-modal-lg"
                              >
                                <img
                                  src="\images\plus-circle-blue.svg"
                                  style={{ width: "1.25rem" }}
                                />{" "}
                                &nbsp;{t("datacenter.add_floor")}
                              </a>{" "}
                            </p>
                          ) : null}
                        </div>
                        <table>
                          <thead>
                            <tr>
                              <th
                                onClick={() => {
                                  setAscending(!ascending);
                                  if (ascending === true) {
                                    allFloorData.sort((a, b) =>
                                      a.name > b.name ? 1 : -1
                                    );
                                  }
                                  if (ascending === false) {
                                    allFloorData.sort((a, b) =>
                                      a.name < b.name ? 1 : -1
                                    );
                                  }
                                }}
                                style={{ cursor: "pointer" }}
                              >
                                {" "}
                                {t("datacenter.floor")}{" "}
                                <i
                                  className={`fa fa-solid fa-sort-${
                                    ascending ? "down" : "up"
                                  }`}
                                ></i>
                              </th>
                              <th> {t("datacenter.cabs")} </th>
                              <th> Design {t("datacenter.k_w")} </th>
                              {isReadOnly === false ? <th> </th> : null}
                            </tr>
                          </thead>
                          <tbody>
                            {allFloorData &&
                              allFloorData.map((res, id) => {
                                floorPower.push(parseInt(res.design_power)); // push to array to calculate total design power if set to 0
                                return (
                                  <tr
                                    key={id}
                                    className={
                                      floorIndex === res.id ? "tr_active" : ""
                                    }
                                    onClick={() => {
                                      getFloorData(res);
                                      setFloorIndex(res.id);
                                      setFloorKey(id);
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <td>
                                      {" "}
                                      {res.name}
                                      <i
                                        className="fa fa-circle"
                                        style={{
                                          color:
                                            res.status === 1
                                              ? "#3FEB7B"
                                              : "#E0E2E5",
                                          marginLeft: "5px",
                                        }}
                                        aria-hidden="true"
                                      ></i>
                                    </td>
                                    <td> {numberFormat(res.design_cabs)} </td>
                                    <td>
                                      {" "}
                                      {numberFormat(
                                        res.design_power,
                                        2 // from 3 decimal place to 2
                                      )}{" "}
                                    </td>
                                    {isReadOnly === false ? (
                                      <td>
                                        <a
                                          href="#sd"
                                          onClick={() => getEditFloorPopup(res)}
                                          style={{ cursor: "pointer" }}
                                        >
                                          <i className="fas fa-edit"></i>
                                        </a>
                                      </td>
                                    ) : null}
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                      <div class="d-flex justify-content-between">
                        <div>
                          <h5 style={{ fontWeight: "600" }}>
                            Total Design Power :
                          </h5>
                        </div>
                        <div class="col-md-6">
                          <input
                            name="total_design_power"
                            value={parseFloat(totalDesignPower).toFixed(2)}
                            onChange={(event) =>
                              setTotalDesignPower(event.target.value)
                            }
                          />{" "}
                          <span style={{ fontWeight: "600" }}>kW</span>
                        </div>
                      </div>
                      <div class="d-flex justify-content-end">
                        <button
                          type="button"
                          class="btn btn-sm btn-primary"
                          style={{ width: "fit-content", marginTop: ".5rem" }}
                          onClick={() => {
                            changeTotalPower();
                            saveDesignPower();
                          }}
                        >
                          {" "}
                          Save{" "}
                        </button>
                      </div>

                      {/* <button
                        type="button"
                        onClick={() => deleteDataCenter()}
                        style={{ marginRight: "1rem" }}
                        className="btn btn-outline-primary red_color mr_1"
                      >
                        <img
                          src="\images\trash-2.svg"
                          style={{
                            width: "11px",
                            marginTop: "-0.188rem",
                            marginRight: "0.5rem",
                          }}
                        />
                        {t("datacenter.delete")}
                      </button> */}
                    </div>

                    <div className="col-xl-8 col-lg-8">
                      <div className="leftnav">
                        <p> {t("datacenter.rooms")} </p>
                        {isReadOnly === false ? (
                          <p>
                            <a
                              href="#sd"
                              id="addneww"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModalCenter"
                            >
                              <img
                                alt=""
                                src="\images\plus-circle-blue.svg"
                                style={{ width: "1.25rem" }}
                              />{" "}
                              &nbsp;{t("datacenter.add_rooms")}{" "}
                            </a>{" "}
                          </p>
                        ) : null}
                      </div>

                      <div
                        className="card-body data-hall-block"
                        style={{ overflow: "auto" }}
                      >
                        <div className="table-responsive">
                          <table className="table header-border verticle-middle">
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  className="pd-l"
                                  width="25%"
                                  onClick={() => {
                                    setRoomAscending(!roomAscending);
                                    if (roomAscending === true) {
                                      room.sort((a, b) =>
                                        a.name > b.name ? 1 : -1
                                      );
                                    }
                                    if (roomAscending === false) {
                                      room.sort((a, b) =>
                                        a.name < b.name ? 1 : -1
                                      );
                                    }
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  {t("datacenter.name")}{" "}
                                  <i
                                    className={`fa fa-solid fa-sort-${
                                      roomAscending ? "down" : "up"
                                    }`}
                                  ></i>
                                </th>
                                <th scope="col"> {t("datacenter.status")} </th>
                                <th scope="col"> {t("datacenter.cabs")} </th>
                                <th scope="col">
                                  {" "}
                                  Design {t("datacenter.k_w")}{" "}
                                </th>
                                {isReadOnly ? <th scope="col"> </th> : null}
                              </tr>
                            </thead>
                            <tbody id="cardnew">
                              {room &&
                                room.length > 0 &&
                                room.map((res) => {
                                  if (res.deletedAt !== null) {
                                    return null;
                                  }
                                  return (
                                    <tr
                                      key={res?.id}
                                      className={
                                        selectedRoom?.id === res?.id
                                          ? "tr_active"
                                          : ""
                                      }
                                    >
                                      <th
                                        className="pd-l bold-txt"
                                        onClick={() => {
                                          if (pageNumber == 1) {
                                            getCabinetsList(res);
                                          } else {
                                            setPageNumber(1);
                                          }
                                        }}
                                        style={{ cursor: "pointer" }}
                                      >
                                        {res.name}
                                      </th>
                                      <td>
                                        <div
                                          style={{
                                            height: "6px",
                                            width: "5em",
                                            overflow: "hidden",
                                            backgroundColor:
                                              res.status === 1
                                                ? "#3FEB7B"
                                                : "#E0E2E5",
                                          }}
                                        >
                                          <div
                                            className="progress-bar bg-success"
                                            role="progressbar"
                                          ></div>
                                        </div>
                                      </td>
                                      <td> {numberFormat(res.design_cabs)} </td>
                                      <td>
                                        {" "}
                                        {numberFormat(res.design_power, 2)}{" "}
                                      </td>{" "}
                                      {/* from 3 decimal place to 2 */}
                                      {isReadOnly === false ? (
                                        <td>
                                          {" "}
                                          <a
                                            href="#sd"
                                            onClick={() =>
                                              getEditRoomPopup(res)
                                            }
                                          >
                                            {" "}
                                            <i className="fas fa-edit"></i>
                                          </a>{" "}
                                        </td>
                                      ) : null}
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4"></div>

                    <div className="col-xl-8 col-lg-8">
                      <div className="row">
                        <div className="col-md-7">
                          <div
                            className="txt_card"
                            style={{ marginLeft: "11px", marginTop: "5px" }}
                          >
                            <h3>{selectedRoom?.name || ""}</h3>
                          </div>
                          {/* <h5 className="card-title">{selectedRoom?.name || ""}</h5>*/}
                        </div>
                        {/* <div className="col-md-6">
                          <div>
                            <span>{t("datacenter.filter")}: </span>
                            <input
                              type="search"
                              value={searchQuery}
                              style={
                                selectedRoom == null
                                  ? {
                                      marginBottom: "1rem",
                                      border: "1px solid #92979A",
                                    }
                                  : { border: "1px solid #92979A" }
                              }
                              onChange={getCabinetsList}
                            />
                          </div>
                        </div> */}
                        <div className="col-md-5">
                          <div className="d-flex align-items-center justify-content-end">
                            <div>Filter : &nbsp;</div>
                            <SerachBox
                              searchCategory={searchCategory}
                              query={query}
                              setQuery={setQuery}
                              setIsLoading={setIsLoading}
                              setPageNumber={setPageNumber}
                              setItemsPerPage={setItemsPerPage}
                            ></SerachBox>
                          </div>
                        </div>
                      </div>

                      <div className="leftnav mt-0">
                        <p> {t("datacenter.cabinets")} </p>
                        {isReadOnly === false ? (
                          <p style={{ display: "none" }}>
                            <a
                              href="#sd"
                              id="addneww"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModalCenter"
                            >
                              <img
                                src="\images\plus-circle-blue.svg"
                                style={{ width: "1.25rem" }}
                              />{" "}
                              &nbsp;{t("datacenter.add_rooms")}{" "}
                            </a>{" "}
                          </p>
                        ) : null}
                      </div>

                      <div
                        className="card-body data-hall-block"
                        style={{ overflow: "hidden" }}
                      >
                        <div
                          className="table-responsive"
                          style={{
                            overflowX: "auto",
                            width: "100%",
                            height: "400px",
                            marginBottom: "1.5rem",
                          }}
                          ref={cabinetRef}
                        >
                          <table
                            className="table header-border verticle-middle"
                            style={{ whiteSpace: "nowrap" }}
                          >
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  className="pd-l"
                                  width="25%"
                                  onClick={() => {
                                    setCabinetAscending(!cabinetAscending);
                                    if (cabinetAscending === true) {
                                      cabinets.sort((a, b) =>
                                        a.name > b.name ? 1 : -1
                                      );
                                    }
                                    if (cabinetAscending === false) {
                                      cabinets.sort((a, b) =>
                                        a.name < b.name ? 1 : -1
                                      );
                                    }
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  {t("datacenter.name")}{" "}
                                  <i
                                    className={`fa fa-solid fa-sort-${
                                      cabinetAscending ? "down" : "up"
                                    }`}
                                  ></i>
                                </th>
                                <th scope="col">
                                  {" "}
                                  {t("datacenter.reseller")}{" "}
                                </th>
                                <th
                                  scope="col"
                                  className="pd-l"
                                  onClick={() => {
                                    setCabinetAscending(!cabinetAscending);
                                    if (cabinetAscending === true) {
                                      cabinets.sort((a, b) =>
                                        a.customer > b.customer ? 1 : -1
                                      );
                                    }
                                    if (cabinetAscending === false) {
                                      cabinets.sort((a, b) =>
                                        a.customer < b.customer ? 1 : -1
                                      );
                                    }
                                  }}
                                >
                                  {" "}
                                  {t("datacenter.customer")}{" "}
                                  <i
                                    className={`fa fa-solid fa-sort-${
                                      cabinetAscending ? "down" : "up"
                                    }`}
                                  ></i>
                                </th>
                                <th
                                  scope="col"
                                  className="pd-l"
                                  onClick={() => {
                                    setCabinetAscending(!cabinetAscending);
                                    if (cabinetAscending === true) {
                                      cabinets.sort((a, b) =>
                                        a.status > b.status ? 1 : -1
                                      );
                                    }
                                    if (cabinetAscending === false) {
                                      cabinets.sort((a, b) =>
                                        a.status < b.status ? 1 : -1
                                      );
                                    }
                                  }}
                                >
                                  {" "}
                                  {t("datacenter.status")}{" "}
                                  <i
                                    className={`fa fa-solid fa-sort-${
                                      cabinetAscending ? "down" : "up"
                                    }`}
                                  ></i>
                                </th>

                                <th scope="col">
                                  {" "}
                                  # {t("datacenter.breakers")}{" "}
                                </th>
                                <th scope="col">
                                  {" "}
                                  # {t("datacenter.x_connects")}{" "}
                                </th>
                              </tr>
                            </thead>
                            <tbody id="cardnew">
                              {cabinets &&
                                cabinets.length > 0 &&
                                cabinets.map((res) => {
                                  if (res.deletedAt != null) {
                                    return null;
                                  }
                                  return (
                                    <tr key={res.id}>
                                      <th className="pd-l bold-txt">
                                        {res.name}
                                      </th>
                                      <td>
                                        {" "}
                                        {res.reseller_name != null
                                          ? res.reseller_name
                                          : "-"}
                                      </td>
                                      <th
                                        className="pd-l bold-txt"
                                        style={{ cursor: "pointer" }}
                                      >
                                        {res.customer_name != null
                                          ? res.customer_name
                                          : "-"}
                                      </th>
                                      <td>{getStatus(res.status)}</td>
                                      {/* <td> {numberFormat(res.max_kw, 3)} </td>
                                    <td> {numberFormat(res.sold_kw, 3)} </td> */}
                                      <td>
                                        {" "}
                                        {res.num_breakers != null
                                          ? res.num_breakers
                                          : "-"}{" "}
                                      </td>
                                      <td>
                                        {res.num_xconnects != null
                                          ? res.num_xconnects
                                          : "-"}
                                      </td>
                                      {isReadOnly === false ? (
                                        <td>
                                          {" "}
                                          <a
                                            href="#sd"
                                            onClick={() =>
                                              getEditCabinetPopup(res)
                                            }
                                          >
                                            {" "}
                                            <i className="fas fa-edit"></i>
                                          </a>{" "}
                                        </td>
                                      ) : null}
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                        <div className="d-flex justify-content-end mt-3">
                          {((cabinets && cabinets.length > 0) ||
                            (breakerList && breakerList.length > 0)) && (
                            <Pagination
                              setItemsPerPage={setItemsPerPage}
                              setPageNumber={setPageNumber}
                              total={total}
                              pageNumber={pageNumber}
                            ></Pagination>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* {currentDataCenter && <Floor allFloorData={allFloorData} dataCenterId={dataCenterId} selectDataCenterFloor={selectDataCenterFloor}/>} */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Floor
          data_center_id={currentDataCenter}
          show={true}
          selectDataCenterFloor={selectDataCenterFloor}
          setMountComponent={setMountComponent}
          mountComponent={mountComponent}
          totalDesignPower={totalDesignPower}
          setTotalDesignPower={setTotalDesignPower}
        />
        {showFloorEdit && (
          <EditFloor
            floor_data={editFloorData}
            show={showFloorEdit}
            setShow={setShowFloorEdit}
            data_center_id={currentDataCenter}
            selectDataCenterFloor={selectDataCenterFloor}
            setMountComponent={setMountComponent}
            mountComponent={mountComponent}
            getFloorData={getFloorData}
            totalDesignPower={totalDesignPower}
            setTotalDesignPower={setTotalDesignPower}
          />
        )}
        <CreateRoom
          show={true}
          data_center_id={currentDataCenter}
          selectDataCenterFloor={selectDataCenterFloor}
          floorIndex={floorIndex}
          setMountComponent={setMountComponent}
          mountComponent={mountComponent}
          setFloorIndex={setFloorIndex}
          floorKey={floorKey}
        />

        {showRoomEdit && (
          <EditRoom
            room={allFloorData[floorIndex]}
            show={showRoomEdit}
            data_center_id={currentDataCenter}
            selectDataCenterFloor={selectDataCenterFloor}
            floorIndex={floorIndex}
            setFloorIndex={setFloorIndex}
            editRoom={editRoom}
            setMountComponent={setMountComponent}
            mountComponent={mountComponent}
            setShow={setShowRoomEdit}
            floorKey={floorKey}
          />
        )}

        {showCabinetsEdit && (
          <EditCabinet
            room={allFloorData[floorIndex]}
            show={showCabinetsEdit}
            data_center_id={currentDataCenter}
            selectDataCenterFloor={selectDataCenterFloor}
            floorIndex={floorIndex}
            setFloorIndex={setFloorIndex}
            editCabinets={editCabinets}
            setShow={setShowCabinetsEdit}
            getCabinetsData={getCabinetsList}
            selectedRoom={selectedRoom}
            setMountComponent={setMountComponent}
            mountComponent={mountComponent}
            breakerList={breakerList}
          />
        )}

        <CreateDataCenter
          show={true}
          country={country}
          setDataCenter={setDataCenter}
          dataCenter={dataCenter}
          countryList={countries}
          getAllDataCenter={getAllDataCenter}
          setMountComponent={setMountComponent}
          mountComponent={mountComponent}
        />
      </Layout>
    </React.Fragment>
  );
};

export default DataCenter;
