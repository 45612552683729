import AuthContext from "context";
import React, { useContext, useEffect, useState } from "react";
import CabinetBreakerRelation  from "services/cabinetBreakerRelationService";


export const useGetCabinetBreakerRelation = ({ breakerId, cabinetId}) => {
  const authContext = useContext(AuthContext);
  const [breakers, setBreakers] = useState([]);
  const [breakersByCabinet, setBreakersByCabinet] = useState([]);
  const [cabinetByBreaker, setCabinetByBreakers] = useState(null);
  const [numActiveSlots, setNumActiveSlots] = useState(null)

  const getBreakers = async () => {
    const getBreakersDto = await CabinetBreakerRelation
      .getBreakers(authContext.token(), breakerId);
    if (getBreakersDto.data?.data) {
      setBreakers(getBreakersDto.data.data);
      if (getBreakersDto.data.data[0] && getBreakersDto.data.data[0].cabinet) {
        setCabinetByBreakers(getBreakersDto.data.data[0].cabinet)
      }
    }
  }

  const getCabinet = async () => {
    const getCabinetDto = await CabinetBreakerRelation
      .getCabinet(authContext.token(), cabinetId);
    if (getCabinetDto.data?.data) {
      const records = getCabinetDto.data.data;
      const breakers= [null, null, null, null]
      records.forEach(record => {
        breakers[record.ordinal] = record.breaker_id
      })
      // console.log({records, breakers})
      for (let i = breakers.length; i >= 0; i--) {
        if (breakers[i] !== null && !isNaN(breakers[i]))  {
          setNumActiveSlots(i);
          break;
        }
      }
      setBreakersByCabinet(breakers)
    }
  }
  
  useEffect(() => {
    if (breakerId) {
      getBreakers();
    }
  }, [breakerId])

  useEffect(() => {
    if (cabinetId) {
      getCabinet();
    }
  }, [cabinetId])

  return {
    breakers,
    breakersByCabinet,
    cabinetByBreaker,
    numActiveSlots,
  }
}