/* eslint-disable default-case */
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import AuthContext from "context";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import { DoughnutChartCard } from "@digitaledgedc/deuikit";

const Card = ({ cardData, state, category }) => {
  const [installData, setInstallData] = useState(null);
  const [deinstallData, setDeinstallData] = useState(null);
  const [upgradeDowngradeData, setUpgradeDowngradeData] = useState(null);
  const [othersData, setOthersData] = useState(null);

  const { t } = useTranslation();
  useEffect(() => {
    getInstall();
    getDeinstall();
    getUpgrade();
    getOthers();
  }, [state]);

  const getInstall = () => {
    let total = 0;
    let provisioning = 0;
    let inService = 0;
    let pendingChange = 0;
    let acceptance = 0;
    let inServicePP = 0;
    let newTag = 0;
    let workInProgress = 0;
    state &&
      state.map((data) => {
        // console.log("hiii----",data.status)

        if (category === "installed_base") {
          if (data.name === "In Service") {
            inService += data.result.add;
          }

          if (data.name === "Pending Provisioning") {
            provisioning += data.result.add;
          }
          if (data.name === "Pending Change") {
            pendingChange += data.result.add;
          }
          if (data.name === "Customer Acceptance") {
            acceptance += data.result.add;
          }
          total = inService + provisioning + pendingChange + acceptance;
        } else {
          if (data.name === "In Service (PP)") {
            inServicePP += data.result.add;
          }

          if (data.name === "New") {
            newTag += data.result.add;
          }
          if (data.name === "Work In Progress") {
            workInProgress += data.result.add;
          }
          if (data.name === "Customer Acceptance") {
            acceptance += data.result.add;
          }
          total = inServicePP + newTag + workInProgress + acceptance;
        }
      });

    setInstallData({
      types: [
        {
          id: "1",
          title: category === "installed_base" ? "Provisioning" : "New",
          value: category === "installed_base" ? provisioning : newTag,
          color: "#1b70c0",
        },
        {
          id: "2",
          title:
            category === "installed_base" ? "In Service" : "In Service (PP)",
          value: category === "installed_base" ? inService : inServicePP,
          color: "#70ad47",
        },
        {
          id: "3",
          title:
            category === "installed_base"
              ? "Pending Change"
              : "Work In Progress",

          value: category === "installed_base" ? pendingChange : workInProgress,
          color: "#f2dc34",
        },
        {
          id: "4",
          title: "Acceptance",
          value: acceptance,
          color: "#f89903",
        },
      ],
      totalNumber: total,
    });
  };
  const getDeinstall = () => {
    let provisioning = 0;
    let inService = 0;
    let pendingChange = 0;
    let acceptance = 0;
    let inServicePP = 0;
    let newTag = 0;
    let workInProgress = 0;
    let total = 0;
    state &&
      state.map((data) => {
        if (category === "installed_base") {
          if (data.name === "In Service") {
            inService += data.result.delete;
          }
          if (data.name === "Pending Provisioning") {
            provisioning += data.result.delete;
          }
          if (data.name === "Pending Change") {
            pendingChange += data.result.delete;
          }
          if (data.name === "Customer Acceptance") {
            acceptance += data.result.delete;
          }
          total = inService + provisioning + pendingChange + acceptance;
        } else {
          if (data.name === "In Service (PP)") {
            inServicePP += data.result.delete;
          }
          if (data.name === "New") {
            newTag += data.result.delete;
          }
          if (data.name === "Work In Progress") {
            workInProgress += data.result.delete;
          }
          if (data.name === "Customer Acceptance") {
            acceptance += data.result.delete;
          }
          total = inServicePP + newTag + workInProgress + acceptance;
        }
      });

    setDeinstallData({
      types: [
        {
          id: "1",
          title: category === "installed_base" ? "Provisioning" : "New",
          value: category === "installed_base" ? provisioning : newTag,
          color: "#1b70c0",
        },
        {
          id: "2",
          title:
            category === "installed_base" ? "In Service" : "In Service (PP)",
          value: category === "installed_base" ? inService : inServicePP,
          color: "#70ad47",
        },
        {
          id: "3",
          title:
            category === "installed_base"
              ? "Pending Change"
              : "Work In Progress",

          value: category === "installed_base" ? pendingChange : workInProgress,
          color: "#f2dc34",
        },
        {
          id: "4",
          title: "Acceptance",
          value: acceptance,
          color: "#f89903",
        },
      ],
      totalNumber: total,
    });
  };

  const getUpgrade = () => {
    let provisioning = 0;
    let inService = 0;
    let pendingChange = 0;
    let acceptance = 0;
    let inServicePP = 0;
    let newTag = 0;
    let workInProgress = 0;
    let total = 0;
    state &&
      state.map((data) => {
        if (category === "installed_base") {
          if (data.name === "In Service") {
            inService += data.result.changed;
          }
          if (data.name === "Pending Provisioning") {
            provisioning += data.result.changed;
          }
          if (data.name === "Pending Change") {
            pendingChange += data.result.changed;
          }
          if (data.name === "Customer Acceptance") {
            acceptance += data.result.changed;
          }
          total = inService + provisioning + pendingChange + acceptance;
        } else {
          if (data.name === "In Service (PP)") {
            inServicePP += data.result.changed;
          }
          if (data.name === "New") {
            newTag += data.result.changed;
          }
          if (data.name === "Work In Progress") {
            workInProgress += data.result.changed;
          }
          if (data.name === "Customer Acceptance") {
            acceptance += data.result.changed;
          }
          total = inServicePP + newTag + workInProgress + acceptance;
        }
      });

    setUpgradeDowngradeData({
      types: [
        {
          id: "1",
          title: category === "installed_base" ? "Provisioning" : "New",
          value: category === "installed_base" ? provisioning : newTag,
          color: "#1b70c0",
        },
        {
          id: "2",
          title:
            category === "installed_base" ? "In Service" : "In Service (PP)",
          value: category === "installed_base" ? inService : inServicePP,
          color: "#70ad47",
        },
        {
          id: "3",
          title:
            category === "installed_base"
              ? "Pending Change"
              : "Work In Progress",

          value: category === "installed_base" ? pendingChange : workInProgress,
          color: "#f2dc34",
        },
        {
          id: "4",
          title: "Acceptance",
          value: acceptance,
          color: "#f89903",
        },
      ],
      totalNumber: total,
    });
  };
  const getOthers = () => {
    let provisioning = 0;
    let inService = 0;
    let pendingChange = 0;
    let acceptance = 0;
    let inServicePP = 0;
    let newTag = 0;
    let workInProgress = 0;
    let total = 0;
    state &&
      state.map((data) => {
        if (category === "installed_base") {
          if (data.name === "In Service") {
            inService += data.result.others;
          }
          if (data.name === "Pending Provisioning") {
            provisioning += data.result.others;
          }
          if (data.name === "Pending Change") {
            pendingChange += data.result.others;
          }
          if (data.name === "Customer Acceptance") {
            acceptance += data.result.others;
          }
          total = inService + provisioning + pendingChange + acceptance;
        } else {
          if (data.name === "In Service (PP)") {
            inServicePP += data.result.others;
          }
          if (data.name === "New") {
            newTag += data.result.others;
          }
          if (data.name === "Work In Progress") {
            workInProgress += data.result.others;
          }
          if (data.name === "Customer Acceptance") {
            acceptance += data.result.others;
          }
          total = inServicePP + newTag + workInProgress + acceptance;
        }
      });

    setOthersData({
      types: [
        {
          id: "1",
          title: category === "installed_base" ? "Provisioning" : "New",
          value: category === "installed_base" ? provisioning : newTag,
          color: "#1b70c0",
        },
        {
          id: "2",
          title:
            category === "installed_base" ? "In Service" : "In Service (PP)",
          value: category === "installed_base" ? inService : inServicePP,
          color: "#70ad47",
        },
        {
          id: "3",
          title:
            category === "installed_base"
              ? "Pending Change"
              : "Work In Progress",

          value: category === "installed_base" ? pendingChange : workInProgress,
          color: "#f2dc34",
        },
        {
          id: "4",
          title: "Acceptance",
          value: acceptance,
          color: "#f89903",
        },
      ],
      totalNumber: total,
    });
  };

  return (
    <Grid
      container
      spacing={1}
      justifyContent="center"
      style={{ marginTop: "0px" }}
    >
      {[
        { data: installData, title: "Install" },
        { data: deinstallData, title: "Deinstall" },
        {
          data: upgradeDowngradeData,
          title: "Upgrade/Downgrade",
        },
        { data: othersData, title: "Others" },
      ].map((item, index) => (
        <Grid key={index} item xs={12} sm={6} md={3} lg={3} xl={3}>
          <DoughnutChartCard
            graphData={
              item.data != null
                ? item.data
                : {
                    totalNumber: 0,
                    types: [],
                  }
            }
            title={item.title}
            loading={false}
            size="sm"
            titleStyle={{
              fontSize: "0.813rem",
            }}
            legendTextStyle={{
              fontSize: "0.438rem",
            }}
            maxLengthFadeOut={20}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default Card;
