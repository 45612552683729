import { useEffect, useState, useContext, useRef } from "react";
import AuthContext from "context";
import Swal from "sweetalert2";

const DescriptionAndAssignSection = ({
  title,
  assigneeName,
  checkPermission,
  assigneeIsCurrentUser,
  assignees,
  handleAssign,
  handleUnassign,
  assigneeId,
  isEdit,
  setIsEdit,
  description,
  handleChangeDescription,
  handleUpdate,
  handleDiscard,
  draftDescription,
  componentType,
  maintenanceStatus,
}) => {
  const contextStore = useContext(AuthContext);
  const [filteredAssignees, setFilteredAssignees] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [isAssigneShow, setIsAssigneeShow] = useState(false);
  const divRef = useRef(null);
  const inputRef = useRef(null);
  useEffect(() => {
    setFilteredAssignees(assignees);
  }, [assignees]);

  // useEffect(() => {
  //   console.log(assigneeName);
  // }, [assigneeName]);

  useEffect(() => {
    if (
      isEdit &&
      checkPermission("Edit Description") &&
      checkPermission("Maintenance Status")
    ) {
      inputRef.current.focus();
    }
  }, [isEdit]); // Empty dependency array ensures this effect runs only once after initial render

  const HandleSearch = (val) => {
    setSearchValue(val);
    setFilteredAssignees(filterByValue(assignees, val));
  };

  const filterByValue = (array, value) => {
    return array.filter(
      (data) =>
        JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setTimeout(() => {
          setIsAssigneeShow(false);
        }, 300);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleEdit = () => {
    if (draftDescription != "") {
      Swal.fire({
        text:
          "Are you sure you want to exit? Your changes to the input Draft Description will be discarded.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Exit",
        customClass: {
          cancelButton: "cancelBtn",
          confirmButton: "confirmBtn",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          setIsEdit(true);
        }
      });
    } else {
      setIsEdit(true);
    }
  };
  return (
    <>
      <div class="row">
        <div class="col-md-10 d-flex justify-content-between align-items-center">
          <div>
            <span
              style={{
                fontSize: "1rem",
                color: "#000",
                fontWeight: "bold",
              }}
            >
              {title}
            </span>
          </div>
          <div
            style={{
              fontSize: "0.875rem",
              color: "#000000",
            }}
          >
            Assigned To: <b>{assigneeName}</b>
          </div>
        </div>
        <div className="col-md-2">
          <div class="mb-2">
            <div>
              {checkPermission(
                componentType == "Provisioning" ? "Assign IB" : "Assign Ticket"
              ) &&
                checkPermission("Maintenance Status") && (
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link"
                      role="button"
                      style={{ padding: "0px" }}
                    >
                      <button
                        class="btn me-3 btn-sm assign-btn-new"
                        ref={divRef}
                        onClick={() => {
                          setIsAssigneeShow(true);
                        }}
                      >
                        Assign
                      </button>
                    </a>

                    {isAssigneShow && (
                      <div
                        ref={divRef}
                        className="dropdown-menu show"
                        style={{
                          overflowY: "scroll",
                          maxHeight: "50vh",
                          left: "-116px !important",
                        }}
                      >
                        {assignees && assignees.length > 0 && (
                          <div className="tc_drop1-out">
                            {assigneeName !== "N/A" &&
                              componentType !== "Provisioning" && (
                                <div
                                  className="dropdown-item ai-icon"
                                  style={{
                                    cursor: "pointer",
                                    marginBottom: "0.5rem",
                                  }}
                                  onClick={() => {
                                    handleUnassign();
                                    setIsAssigneeShow(false);
                                  }}
                                >
                                  {" "}
                                  {assigneeIsCurrentUser
                                    ? "Unassign From Me"
                                    : "Unassign"}
                                </div>
                              )}
                            <div class="tc_drop1">
                              <div className="inp-out">
                                <input
                                  type="text"
                                  onChange={(e) => HandleSearch(e.target.value)}
                                  placeholder="Search...."
                                  value={searchValue}
                                />
                              </div>
                            </div>
                          </div>
                        )}
                        {!assigneeIsCurrentUser && (
                          <div
                            className="dropdown-item ai-icon"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              handleAssign(contextStore?.getAuth?.email);
                              setIsAssigneeShow(false);
                            }}
                          >
                            Assign To Me
                          </div>
                        )}
                        {filteredAssignees &&
                          filteredAssignees.length > 0 &&
                          filteredAssignees.map((assignee) => {
                            return (
                              assigneeId !== assignee.id && (
                                <div
                                  className="dropdown-item ai-icon"
                                  style={{
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    handleAssign(assignee.email);
                                    setIsAssigneeShow(false);
                                  }}
                                >
                                  {" "}
                                  {assignee.name}
                                </div>
                              )
                            );
                          })}
                      </div>
                    )}
                  </li>
                )}
            </div>
          </div>
        </div>
      </div>
      <div className="row d-flex align-items-center mt-3">
        <div className="col-md-10">
          <h5
            class="card-title"
            style={{
              fontSize: "1rem",
              fontWeight: "bold",
            }}
          >
            Description :
          </h5>
        </div>
      </div>
      <div className="row">
        <div className="col-md-10">
          <div style={{ maxWidth: "100%" }}>
            <div class="field">
              {(!isEdit || !checkPermission("Edit Description")) && (
                <p
                  class="input-style2"
                  id="descriptionBox"
                  style={{
                    maxWidth: "100%",
                    marginBottom: "1rem",
                    wordBreak: "break-all",
                  }}
                >
                  <div
                    style={{
                      fontSize: "1.03rem",
                      color: "#8392A5",
                      whiteSpace: "pre-wrap ",
                    }}
                  >
                    {description}
                  </div>
                </p>
              )}
              {isEdit &&
                checkPermission("Edit Description") &&
                checkPermission("Maintenance Status") && (
                  <div
                    class=""
                    style={{
                      maxWidth: "100%",
                      height: "150px",
                      marginBottom: "1rem",
                    }}
                  >
                    <textarea
                      ref={inputRef}
                      class="form-control"
                      rows="6"
                      style={{
                        height: "100%",
                        resize: "none",
                        border: "2px solid #ff7a00",
                      }}
                      onChange={handleChangeDescription}
                      value={description}
                    />
                  </div>
                )}
            </div>
          </div>
        </div>
        <div className="col-md-2">
          {(checkPermission("Edit Description") ||
            checkPermission("Edit Details")) &&
            checkPermission("Maintenance Status") && (
              <div>
                {!isEdit && (
                  <button
                    class="btn me-3 btn-sm assign-btn-new"
                    onClick={() => {
                      handleEdit();
                    }}
                  >
                    Edit
                  </button>
                )}
                {isEdit && (
                  <div>
                    <button
                      class="btn me-3 btn-sm save-btn-new"
                      onClick={() => {
                        handleUpdate();
                      }}
                      style={{ marginBottom: "1rem" }}
                    >
                      Save
                    </button>
                    <button
                      class="btn me-3 btn-sm discard-btn-new"
                      disabled={!isEdit}
                      onClick={handleDiscard}
                    >
                      Discard
                    </button>
                  </div>
                )}
              </div>
            )}
        </div>
      </div>
    </>
  );
};
export default DescriptionAndAssignSection;
