// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings-data-center-cabinets-dropdown-or-button {
  width: 100px;
}`, "",{"version":3,"sources":["webpack://./src/views/super-admin/Setting/DataCenter/shared/DropdownOrButton/DropdownOrButton.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd","sourcesContent":[".settings-data-center-cabinets-dropdown-or-button {\n  width: 100px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
