import Table from './table';
import { useTranslation } from 'react-i18next';
const UsageData = (props) => {
  console.log(props);
  const { t } = useTranslation();
  const columns = [
    { name: 'usage_id', value: t('provisioning.usage_data_table.usage_id') },
    { name: 'usage_date', value: t('provisioning.usage_data_table.date') },
    { name: 'raw_data', value: t('provisioning.usage_data_table.raw_data') },
    { name: 'ns_usage_data', value: t('provisioning.usage_data_table.usage_data') },
    { name: 'uom', value: t('provisioning.usage_data_table.uom') },
    { name: 'unit_price', value: t('provisioning.usage_data_table.unit_price') },
    { name: 'created_by', value: t('provisioning.usage_data_table.created_by') },
    { name: 'create_date', value: t('provisioning.usage_data_table.created_on') }
  ];
  return (
    <>
      {' '}
      <Table data={props.data} columns={columns}></Table>
    </>
  );
};

export default UsageData;
