import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import Datetime from 'react-datetime'
import 'react-datetime/css/react-datetime.css'
import './TicketLabelAndTime.scss'
import TicketInputField from '../TicketInputField'
import { TICKET_MODAL_BACKGROUND_COLOR, TICKET_COMMON_STYLES } from '../constants'
import { css } from 'glamor'

const getClass = (mode) => {
  return `ticket-date-time-mode-${mode}`
}

const TicketLabelAndTime = ({
  id,
  label,
  value,
  active,
  setFieldValue,
  isTopPlacement,
  required,
  format,
  hoursConstraint
}) => {
  useEffect(() => {
    if (value === '') {
      setFieldValue(id, moment())
    }
  }, [active])

  const [pickerMode, setPickerMode] = useState(null)

  const renderView = (mode, renderDefault) => {
    setPickerMode(mode)
    return (
      <div
        className='wrapper'
      >{renderDefault()}
      </div>
    )
  }

  const styles = {
    label: {
      marginTop: '5px',
      paddingTop: '5px',
      marginLeft: '5px',
      paddingLeft: '5px'
    },
    ...TICKET_COMMON_STYLES
  }

  return (
    <>
      {active
        ? (
          <div className='form-floating' {...css(styles.borderBottom, styles.selectPadding)}>
            <div htmlFor='floatingSelect' {...css(styles.label)}>
              {label}
              {required && (
                <span {...css(styles.span)} className='red_star'>
                  *
                </span>
              )}
            </div>
            <Datetime
              onChange={(value) => {
                if (value.format) {
                  setFieldValue(id, value.format())
                }
              }}
              className='ticket-date-time-mode-time'
              value={moment(value)}
              timeFormat='HH:mm'
              dateFormat={false}
              inputProps={{
                style: {
                  color: 'black',
                  backgroundColor: TICKET_MODAL_BACKGROUND_COLOR,
                  borderColor: TICKET_MODAL_BACKGROUND_COLOR
                },
                disabled: !active
              }}
              renderView={(mode, renderDefault) => renderView(mode, renderDefault)}
              timeConstraints={{
                minutes: { step: 10 }
              }}
            />
          </div>
          )
        : (
          <TicketInputField
            id={id}
            label={label}
            backgroundColor={TICKET_MODAL_BACKGROUND_COLOR}
            required={required}
          />
          )}

    </>

  )
}

const TicketLabelAndTimeMemo = ({
  id,
  label,
  value,
  active,
  setFieldValue,
  isTopPlacement,
  required
}) => {
  return useMemo(() => {
    return (
      <TicketLabelAndTime
        id={id}
        label={label}
        value={value}
        active={active}
        setFieldValue={setFieldValue}
        isTopPlacement={isTopPlacement}
        required={required}
      />
    )
  }, [
    id,
    label,
    value,
    active,
    isTopPlacement,
    required
  ])
}

export default TicketLabelAndTimeMemo
