import AuthContext from "context";
import { useContext, useEffect, useState } from "react";
import Account from "services/accountService"

export const useEditCabinet = () => {
  const authContext = useContext(AuthContext);
  const [resellerList, setResellerList] = useState([{
    id: 1,
    name: "Reseller #1"
  }])

  const [slots, setSlots] = useState([
    {id: 1, value: "button", active: true},
    {id: 2, value: null, active: false},
    {id: 3, value: null, active: false}
  ])

  // const getResellers = async () => {
  //   const getResellerssDto = await Account
  //     .getResellers(authContext.token());
  //   if (getResellerssDto.data?.data) {
  //     setResellerList(getResellerssDto.data.data);
  //   }
  // }

  // useEffect(() => {
  //   getResellers();
  // }, [])

  const addBreaker = (id) => {
    let copy = [...slots];
    copy[id-1] = {id, value: "dropdown", active: true};
    if (id < 3) {
      copy[id] = {id: id+1, value: "button", active: true};
    }

    setSlots(copy)
  }

  const updateSlots = (numActiveSlots) => {
    const newSlots = [];
    for (let i = 1; i <= numActiveSlots; i++) {
        newSlots.push({id: i, value: "dropdown", active: true})
    }
    if (newSlots.length < 3) {
      newSlots.push({id: newSlots.length + 1, value: "button", active: true})
    }
    setSlots(newSlots);
  }

  return {
    resellerList,
    addBreaker,
    slots,
    updateSlots,
  }
}