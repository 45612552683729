const ArrowUpIcon = () => {
  return (
    <svg
      height='20px' width='20px' version='1.1' id='_x32_' xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 512 512'
    >
      <style type='text/css' />
      <g>
        <path
          class='st0' d='M255.991,0C114.615,0,0,114.615,0,256.009C0,397.385,114.615,512,255.991,512
		C397.385,512,512,397.385,512,256.009C512,114.615,397.385,0,255.991,0z M345.464,323.884l-89.473-89.456l-89.456,89.456
		l-44.097-44.097l133.552-133.57l133.57,133.57L345.464,323.884z'
        />
      </g>
    </svg>
  )
}

export default ArrowUpIcon
