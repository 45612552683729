import {
	axiosWithoutToken as axios,
	axiosWithToken as authAxios
} from 'utils/axios';
import { LogoutOnSessionExpire } from 'common/helpers';

/*
|-------------------------------------------------------------------------------
| Auth Login
|-------------------------------------------------------------------------------
*/
export const loginService = async data => await axios().post(`LoginController`,data);

/*
|-------------------------------------------------------------------------------
| Auth Azure Login
|-------------------------------------------------------------------------------
*/
export const loginAzure = async data => await axios().post(`AzureLogin`,data);

/*
|-------------------------------------------------------------------------------
| Forgot Password
|-------------------------------------------------------------------------------
*/
export const forgotPassword = async data => await axios().post(`ForgotPassword`,data);

/*
|-------------------------------------------------------------------------------
| Reset Password
|-------------------------------------------------------------------------------
*/
export const resetPassword = async data => await axios().post(`ResetPassword`,data);
/*
|-------------------------------------------------------------------------------
| Auth
|-------------------------------------------------------------------------------
*/
export const auth = async (token) => await authAxios(token).get(`Auth`)
//;
/*
|-------------------------------------------------------------------------------
| LogoutSession
|-------------------------------------------------------------------------------
*/
export const logoutSession = async token => await authAxios(token).get(`LogoutSession`);
/*
|-------------------------------------------------------------------------------
| Oddo Test
|-------------------------------------------------------------------------------
*/
export const oddoTest = async (token) => await authAxios(token).get(`checkOddoConnection`);

/*
|-------------------------------------------------------------------------------
| Verify Email
|-------------------------------------------------------------------------------
*/
export const verifyEmail = async data => await axios().post(`VerifyUserEmail`,data);

