import { css } from 'glamor'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import TicketCard from '../../shared/TicketCard'
import TicketButton from '../../shared/TicketButton'
import PackageDetail from './PackageDetail'
import PackageList from './PackageList'
import { useContext, useEffect, useState } from 'react'
import ShipmentTicketFormContext from '../context/ShipmentTicketFormContext'
import usePackageDetails from '../hooks/usePackageDetails'
import GenericAlertModal from '../../shared/GenericAlertModal'
import { TICKET_COMMON_STYLES, TICKET_MODAL_BACKGROUND_COLOR } from '../../shared/constants'
import GenericTicketModal from '../../GenericTicketModal'
import TicketLabelAndTime from '../../shared/TicketLabelAndTime'
import TicketSelectField from '../../shared/TicketSelectField'
import TicketLabelAndDate from '../../shared/TicketLabelAndDate'

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '100%'
  },
  section: {
    '& > *': {
      padding: '0 20px 0 20px'
    }
  },
  checkBoxes: {
    '& > *': {
      marginBottom: '2px'
    }
  },
  loadingDockSection: {
    padding: '0 20px 20px 20px'
  },
  colPadding0: {
    paddingLeft: '0px'
  },
  ...TICKET_COMMON_STYLES
}

const RightPart = ({closeModal}) => {
  const { t } = useTranslation()
  const form = useContext(ShipmentTicketFormContext)

  const LOADING_DOCK_REQUIRED = [
    { id: 'no', value: 'no', label: t('common.no') },
    { id: 'yes', value: 'yes', label: t('common.yes') }
  ]

  const SHIPMENT_CONFIRMATION_FORM_TYPE = {
    id: 'shipment-confirmation',
    name: form.formik.values.shipmentType === 'inbound'
      ? 'create-ticket.shipment.confirmation.title-inbound-ticket'
      : 'create-ticket.shipment.confirmation.title-outbound-ticket',
    formWidth: '500px',
    fontSize: '26px'
  }

  const {
    handleAddPackage,
    handleDeletePackage,
    handleChangePackage,
    validationError: detailsValidationError,
    setValidationError: setDetailsValidationError
  } = usePackageDetails({ formik: form.formik })

  const [show, setShow] = useState(false)
  const [message, setMessage] = useState(null)

  const handleAlertModalClose = () => {
    form.setValidationError('')
    setDetailsValidationError('')
  }

  useEffect(() => {
    if (form.validationError !== '' && form.isActive('submit')) {
      setMessage(form.validationError)
      setShow(true)
    } else if (!form.isActive('submit')) {
      form.setValidationError('')
    }
  }, [form.validationError])

  useEffect(() => {
    if (detailsValidationError !== '' && form.isActive('expectedDate')) {
      setMessage(detailsValidationError)
      setShow(true)
    }
  }, [detailsValidationError])

  return (
    <div {...css(styles.container)}>
      <div>
        <div {...css(styles.loadingDockSection)}>
          <Row>
            <TicketSelectField
              id='loadingDockRequired'
              label={t('create-ticket.shipment.loading-dock-required')}
              setFieldValue={form.formik.setFieldValue}
              required
              active={form.isActive('expectedDate')}
              options={LOADING_DOCK_REQUIRED}
              backgroundColor={TICKET_MODAL_BACKGROUND_COLOR}
              value={form.formik.values.loadingDockRequired}
              defaultValue={LOADING_DOCK_REQUIRED[0]}
            />
          </Row>
          <div>
            {form.formik.values.loadingDockRequired === 'yes' && (
              <Row>
                <Col {...css(styles.colPadding0)}>
                  <TicketLabelAndDate
                    label={t('create-ticket.shipment.date')}
                    // isTopPlacement
                    value={form.formik.values.expectedDate}
                    setFieldValue={form.formik.setFieldValue}
                    active={form.isActive('expectedDate')}
                    readOnly
                    format='YYYY-MM-DD'
                  />
                </Col>
                <Col>
                  <TicketLabelAndTime
                    label={t('create-ticket.site-access.start-time')}
                    id='loadingDockStartTime'
                    // isTopPlacement
                    value={form.formik.values.loadingDockStartTime}
                    setFieldValue={form.formik.setFieldValue}
                    active={form.isActive('expectedDate')}
                    required
                  />
                </Col>
                <Col>
                  <TicketLabelAndTime
                    label={t('create-ticket.site-access.end-time')}
                    id='loadingDockEndTime'
                    // isTopPlacement
                    value={form.formik.values.loadingDockEndTime}
                    setFieldValue={form.formik.setFieldValue}
                    active={form.isActive('expectedDate')}
                    required
                  />
                </Col>
              </Row>
            )}
          </div>
        </div>
        <Row>
          <TicketCard label={t('create-ticket.shipment.package-detail')}>
            <PackageDetail
              isActive={form.isActive}
              detailsTrackingNo={form.formik.values.detailsTrackingNo}
              detailsRemarks={form.formik.values.detailsRemarks}
              detailsWeight={form.formik.values.detailsWeight}
              detailsWeightDimension={form.formik.values.detailsWeightDimension}
              detailsHeight={form.formik.values.detailsHeight}
              detailsWidth={form.formik.values.detailsWidth}
              detailsDepth={form.formik.values.detailsDepth}
              detailsSizeDimension={form.formik.values.detailsSizeDimension}
              setFieldValue={form.formik.setFieldValue}
              weightDimensions={form.weightDimensions}
              lengthDimensions={form.lengthDimensions}
              handleAddPackage={handleAddPackage}
            />
          </TicketCard>
        </Row>
        <Row>
          <TicketCard
            label={t('create-ticket.shipment.package-list')}
            extraStyles={{
              maxHeight: '200px',
              overflowY: 'scroll'
            }}
          >
            <PackageList
              details={form.formik.values.packageList}
              onDelete={handleDeletePackage}
              onChange={handleChangePackage}
            />
          </TicketCard>
        </Row>
      </div>
      <div>
        <div>{t('create-ticket.shipment.reminder')}</div>
      </div>
      <div
        {...css(styles.section)}
        className="mt-4 d-flex justify-content-end align-items-end gap-3"
      >
        <TicketButton
          label={t('cancel')}
          variant="secondary"
          width="150px"
          active={true}
          closeModal={closeModal}
        />

        <TicketButton
          label={t('create-ticket.label')}
          variant="primary"
          icon={<i {...css(styles.icon)} className="fa-regular fa-pen-to-square" />}
          active={form.isActive('submit')}
          buttonType="submit"
          width="200px"
        />
      </div>
      {message && (
        <GenericAlertModal
          show={show}
          setShow={setShow}
          message={message}
          onClose={handleAlertModalClose}
        />
      )}
      {form.showConfirmation &&
        <GenericTicketModal
          ticketType={SHIPMENT_CONFIRMATION_FORM_TYPE}
          show={form.showConfirmation}
          setShow={form.setShowConfirmation}
        />}
    </div>
  )
}

export default RightPart
