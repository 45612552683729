import { css } from 'glamor';
import { useTranslation } from 'react-i18next';
import { TICKET_BLUE_COLOR, TICKET_DARK_GREY_COLOR } from '../../shared/constants';
import './Description.scss';

const Description = ({ active, value, setFieldValue }) => {
  const { t } = useTranslation();
  const styles = {
    label: {
      backgroundColor: active ? TICKET_BLUE_COLOR : TICKET_DARK_GREY_COLOR,
      color: '#fff'
    },
    span: {
      padding: '5px 15px'
    }
  };

  const handleChange = (e) => {
    let strippedString = e.target.value.replace(/(<([^>]+)>)/gi, '');
    setFieldValue('description', strippedString);
  };

  return (
    <div className="create-ticket-fault-description">
      <div {...css(styles.label)}>
        <div {...css(styles.span)}>{t('create-ticket.site-access.description')}</div>
      </div>
      {active && (
        <div>
          <textarea
            class="form-control"
            rows="6"
            style={{ height: '100%' }}
            onChange={handleChange}
            value={value}
          />
        </div>
      )}
      {!active && value !== '' && (
        <div>
          <textarea
            class="form-control"
            rows="6"
            style={{ height: '100%' }}
            onChange={handleChange}
            readOnly
            value={value}
          />
        </div>
      )}
    </div>
  );
};

export default Description;
