import AuthContext from "context";
import React, { useContext, useEffect } from "react";
import RoleModel from "services/roleServices";
import Layout from "../../Layouts";
import Navigation from "./Navigation";
import SubNavigation from "./subNavigation";
import { LogoutOnSessionExpire } from "common/helpers";
import { useTranslation } from "react-i18next";

const LayoutWithDataCenter = ({
  initialMount,
  dataCenter,
  setDataCenter,
  countryName,
  setCountryName,
  activeTab,
  setActiveTab,
  country,
  setCountry,
  subMenu = [],
  children,
  setTriggerRefresh,
  triggerRefresh,
}) => {
  const authContext = useContext(AuthContext);
  const [countries, setCountries] = React.useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    getCountries();
    getAllDataCenter();
  }, []);

  const getCountries = () => {
    RoleModel.countryService(authContext.token())
      .then((payload) => {
        setCountries(payload.data.data);
      })
      .catch((err) => {
        LogoutOnSessionExpire(err);

        /*redirect to 500 page */
      });
  };

  const getAllDataCenter = async (flag) => {
    if (country === 0 || flag === "global") {
      setCountry(0);
      setCountryName("Country");

      await RoleModel.dataCenter(authContext.token())
        .then((res) => {
          const filteredData = res.data.data.filter(
            (data) => data.dc_type_id === 1
          );

          if (
            authContext.getNetworkDataCenter === undefined ||
            authContext.getNetworkDataCenter.length === 0
          ) {
            setActiveTab(filteredData[0].id);
            authContext.setNetworkDataCenter(filteredData[0]);
          }
          setDataCenter(filteredData);
          /*if(res.data.data.length > 0){
						if(initialMount.current){
							
							setCountry(res.data.data[0].country_id)
							initialMount.current = false
						}
					}*/
        })
        .finally(() => setTriggerRefresh(!triggerRefresh));
    } else {
      let currentCountry = countries.find((c) => c.id === country);
      //setCountryName(currentCountry.name)
      await RoleModel.dataCenterByCountryId(authContext.token(), currentCountry)
        .then((res) => {
          setDataCenter(res.data.data);
        })
        .finally(() => setTriggerRefresh(!triggerRefresh));
    }
  };

  const renderCountry = () => {
    return (
      countries.length &&
      countries.map((data, i) => {
        return (
          <a
            href="#sd"
            className="dropdown-item"
            key={i}
            onClick={() => {
              setCountry(data.id);
              getDataCenterById(data);
            }}
          >
            {data.name}{" "}
          </a>
        );
      })
    );
  };
  const getDataCenterById = async (e) => {
    setCountryName(e.name);
    await RoleModel.dataCenterByCountryId(authContext.token(), e).then(
      (res) => {
        setDataCenter(res.data.data);
        if (res.data.data.length > 0) {
          setActiveTab(res.data.data[0].id);
          authContext.setNetworkDataCenter(res.data.data[0]);
        }
      }
    );
  };
  return (
    <Layout>
      <div className="content-body">
        <Navigation />
        <hr className="main" />
        <div className="container-fluid pt-0">
          <div className="row ">
            <SubNavigation data={subMenu} />
            <div className="col-lg-11">
              <div id="pro">
                <div className="profile-tab">
                  <div className="custom-tab-1">
                    <div className="main_scrll">
                      <div className="btn_ul">
                        <ul className="nav nav-tabs mb-3">
                          <li className="nav-item">
                            <button
                              className="btn btn-secondary"
                              style={{ width: "100%" }}
                              onClick={() => getAllDataCenter("global")}
                            >
                              {" "}
                              {t("xconnect.global")}{" "}
                            </button>
                          </li>
                          <li className="nav-item">
                            <div className="btn-group" role="group">
                              <button
                                type="button"
                                className="btn btn-light dropdown-toggle"
                                style={{ width: "100%" }}
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                {" "}
                                {countryName}{" "}
                              </button>
                              <div
                                className="dropdown-menu"
                                style={{ margin: "0px" }}
                              >
                                {renderCountry()}
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                      <div className="outer-div-setting">
                        <div className="ul_scrll">
                          <ul className="nav nav-tabs custom-scroll-gap">
                            {dataCenter &&
                              dataCenter.map((data, index) => {
                                if (
                                  authContext.getNetworkDataCenter &&
                                  authContext.getNetworkDataCenter.id ===
                                    data.id
                                ) {
                                  return (
                                    <li
                                      className={
                                        index === 0 ? "nav-item" : "nav-item"
                                      }
                                      key={index}
                                    >
                                      <a
                                        href="#sd"
                                        onClick={() => {
                                          getCountries();
                                          getAllDataCenter();
                                          setActiveTab(data.id);
                                          authContext.setNetworkDataCenter(
                                            data
                                          );
                                        }}
                                        style={{ cursor: "pointer" }}
                                        className="nav-link active show"
                                      >
                                        <img
                                          alt=""
                                          className="down setting_down"
                                          src="\images\downward-arrow.png"
                                        />
                                        {data.name}
                                      </a>
                                    </li>
                                  );
                                } else {
                                  return (
                                    <li
                                      className={
                                        index === 0 ? "nav-item" : "nav-item"
                                      }
                                      key={index}
                                    >
                                      <a
                                        href="#sd"
                                        onClick={() => {
                                          getCountries();
                                          getAllDataCenter();
                                          setActiveTab(data.id);
                                          authContext.setNetworkDataCenter(
                                            data
                                          );
                                        }}
                                        style={{ cursor: "pointer" }}
                                        className="nav-link"
                                      >
                                        {" "}
                                        {data.name}{" "}
                                      </a>
                                    </li>
                                  );
                                }
                              })}
                          </ul>
                        </div>
                        <div>
                          <span>
                            <i>
                            {t(
                                "xconnect.select_a_country_to_view_partner_data_center"
                              )}
                            </i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {children}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default LayoutWithDataCenter;
