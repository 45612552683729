import AuthContext from "context"
import React, { useContext, useEffect, useRef, useState } from "react"
import Loading from "views/super-admin/pre-loader/loader"
import Common from "services/commonService"
import Power from "services/powerDistribution"
import Layout from "../../Component/LayoutWithDataCenter"
import BreakerEdit from "./Breaker/edit"
import NavigationTab from "./component/NavigationTab"
import { subMenu } from "./data"
import Overview from "./Overview"
import RRPEdit from "./RRP/edit"
import "./style.css"
import UPSEdit from "./UPS/edit"
import { LogoutOnSessionExpire } from "common/helpers"
import usePowerDistribution from "./usePowerDistribution"
import { useTranslation } from "react-i18next"

const PowerDistribution = () => {
  const STATUSTYPEID = 6

  const incrementStep = parseInt(
    process.env.REACT_APP_POWER_DIST_PAGINATION_OFFSET
  )
  const layoutRef = useRef()

  const initialMount = useRef(true)
  const { t } = useTranslation()
  const [mountForFetch, setMountForFetch] = useState(false)

  const authContext = useContext(AuthContext)
  const [dataCenter, setDataCenter] = useState([])
  const [countryName, setCountryName] = useState("Country")
  const [activeTab, setActiveTab] = useState()
  const [country, setCountry] = useState(0)
  const [navigationActiveTab, setNavigationActiveTab] = useState(1)
  const [toggleModal, setModalState] = useState({
    UPSUpdateIsON: false,
    RRPUpdateIsON: false,
    BreakerUpdateIsON: false,
  })
  const [payload, setPayload] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [status, setStatus] = useState([])
  const [isValueChange, setIsValueChange] = useState(false)

  const activeDataCenter = activeTab
    ? activeTab
    : authContext.getNetworkDataCenter?.id
  const [needToReload, setNeedToReload] = useState(false)
  const [objectForEdit, setObjectForEdit] = useState({})
  const [lastActivity, setLastActivity] = useState("")

  const [nextUPS, setNextUPS] = useState(0)
  const [fetchingUPS, setFetchingUPS] = useState(false)
  const [hasMoreUPS, setHasMoreUPS] = useState(false)

  //RPP State
  const [nextRPP, setNextRPP] = useState(0)
  const [fetchingRPP, setFetchingRPP] = useState(false)
  const [hasMoreRPP, setHasMoreRPP] = useState(false)

  // Breaker State
  const [nextBreaker, setNextBreaker] = useState(0)
  const [fetchingBreaker, setFetchingBreaker] = useState(false)
  const [hasMoreBreaker, setHasMoreBreaker] = useState(false)
  const [triggerRefresh, setTriggerRefresh] = useState(false)
  const [pmi, SetPmi] = useState(false)

  const {
    fetchBreaker,
    filterData,
    searchQuery,
    fetchUPS,
    fetchRPP,
  } = usePowerDistribution({
    setPayload,
    nextBreaker,
    setNextBreaker,
    setHasMoreBreaker,
    setFetchingBreaker,
    nextUPS,
    setNextUPS,
    setHasMoreUPS,
    setFetchingUPS,
    nextRPP,
    setNextRPP,
    setHasMoreRPP,
    setFetchingRPP,
    activeDataCenter,
    navigationActiveTab,
    pmi,
    payload,
  })

  const getBreakerByDcAndPmi = () => {
    if (navigationActiveTab === 4) {
      setPayload([])
      setIsLoading(true)
      Power.getBreakerByPmi({
        token: authContext.token(),
        pmi,
        dataCenterId: activeDataCenter,
      })
        .then((res) => {
          setPayload(res.data.data)
          setIsLoading(false)
          if (res.data.totalRecords > parseInt(incrementStep)) {
            setHasMoreBreaker(true)
          } else {
            setHasMoreBreaker(false)
          }

          if (res.data.lastActivity.length) {
            setLastActivity(res.data.lastActivity[0].date_change)
          }
        })
        .catch((err) => {
          LogoutOnSessionExpire(err)
          setIsLoading(false)
          //500 server error
        })
    }
  }

  const getRppByDc = () => {
    if (navigationActiveTab === 3) {
      setPayload([])
      setIsLoading(true)
      Power.getRPP(authContext.token(), activeDataCenter)
        .then((res) => {
          setPayload(res.data.data)
          // console.log("rpppes - ",res.data.data)

          if (res.data.totalRecords > incrementStep) {
            setHasMoreRPP(true)
          }

          if (res.data.lastActivity.length) {
            setLastActivity(res.data.lastActivity[0].date_change)
          }
        })
        .catch((err) => {
          LogoutOnSessionExpire(err)

          //500 server error
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  useEffect(() => {
    setPayload([])
    setIsLoading(true)
    if (authContext.getStatusData) {
      setIsLoading(false)
      setStatus(
        authContext.getStatusData.filter(
          (s) => s.status_type_id === STATUSTYPEID
        )
      )
    } else {
      LogoutOnSessionExpire()
      setIsLoading(false)
    }
    // Common.status()
    //   .then((res) => {
    //     setIsLoading(false)
    //     setStatus(
    //       res.data.data.filter((s) => s.status_type_id === STATUSTYPEID)
    //     )
    //   })
    //   .catch((err) => {
    //     LogoutOnSessionExpire(err)
    //     // 500 error page

    //     setIsLoading(false)
    //   })

    if (navigationActiveTab === 2) {
      setPayload([])
      setIsLoading(true)
      Power.getUPS(authContext.token(), activeDataCenter)
        .then((res) => {
          setPayload(res.data.data)
          if (res.data.lastActivity.length) {
            setLastActivity(res.data.lastActivity[0].date_change)
          }

          if (res.data.totalRecords > incrementStep) {
            setHasMoreUPS(true)
          }
          setIsLoading(false)
        })
        .catch((err) => {
          LogoutOnSessionExpire(err)
          setIsLoading(false)
          //500 server error
        })
    }
    getRppByDc()
    getBreakerByDcAndPmi()

    return () => cleanUpTask()
  }, [activeTab, navigationActiveTab, needToReload, isValueChange.valueOf])

  useEffect(() => {
    getBreakerByDcAndPmi()
  }, [pmi])

  useEffect(() => {
    layoutRef.current?.addEventListener("scroll", handlescroll)

    return () => {
      setMountForFetch(false)
      // layoutRef.current?.scrollTo(0, 0);
      layoutRef.current?.removeEventListener("scroll", handlescroll)
    }
  }, [navigationActiveTab])
  //contextStore.selectedCountry,

  useEffect(() => {
    if (navigationActiveTab == 3 && nextRPP) {
      fetchRPP()
    }
  }, [nextRPP])

  useEffect(() => {
    if (navigationActiveTab == 2 && nextUPS) {
      fetchUPS()
    }
  }, [nextUPS])
  useEffect(() => {
    console.log(nextBreaker)
    if (navigationActiveTab == 4 && nextBreaker) {
      console.log("called")
      fetchBreaker()
    }
  }, [nextBreaker])

  const handlescroll = () => {
    const scrollTop = layoutRef.current.scrollTop
    const scrollHeight = layoutRef.current.scrollHeight
    const clientHeight = layoutRef.current.clientHeight
    console.log(scrollTop, clientHeight, scrollHeight)

    if (scrollTop && scrollTop + clientHeight + 1 >= scrollHeight) {
      //setNextRPP(Number(nextRPP) + Number(incrementStep));

      getCurrentPageNext()
    }
  }

  const cleanUpTask = () => {
    setPayload([])
    setNextUPS(0)
    setNextRPP(0)
    setNextBreaker(0)
    setFetchingUPS(false)
    setFetchingRPP(false)
    setFetchingBreaker(false)
    setHasMoreUPS(false)
    setHasMoreRPP(false)
    setHasMoreBreaker(false)
  }
  const OpenModel = () => {
    if (navigationActiveTab === 2) {
      setModalState({
        ...toggleModal,
        UPSUpdateIsON: true,
      })
    }

    if (navigationActiveTab === 3) {
      setModalState({
        ...toggleModal,
        RRPUpdateIsON: true,
      })
    }

    if (navigationActiveTab === 4) {
      setModalState({
        ...toggleModal,
        BreakerUpdateIsON: true,
      })
    }
  }

  const getCurrentStatus = (statusId) => {
    let temp = status.filter((s) => s.id === statusId)
    if (temp.length) {
      return temp[0].status_name
    }

    return null
  }

  const getCurrentPageNext = () => {
    switch (navigationActiveTab) {
      case 2:
        setMountForFetch(true)
        setNextUPS((prev) => {
          return Number(prev) + Number(incrementStep)
        })
        break
      case 3:
        setMountForFetch(true)
        setNextRPP((prev) => {
          return Number(prev) + Number(incrementStep)
        })

        break
      case 4:
        setNextBreaker((prev) => {
          return Number(prev) + Number(incrementStep)
        })
        break
      // code block
    }
  }

  return (
    <React.Fragment>
      <Loading
        loading={isLoading}
        background="rgba(0, 0, 0, 0.40)"
        loaderColor="rgb(248, 153, 3)"
      />

      <Layout
        setTriggerRefresh={setTriggerRefresh}
        triggerRefresh={triggerRefresh}
        initialMount={initialMount}
        dataCenter={dataCenter}
        setDataCenter={setDataCenter}
        countryName={countryName}
        setCountryName={setCountryName}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        country={country}
        setCountry={setCountry}
        subMenu={subMenu}
      >
        <NavigationTab
          lastActivity={lastActivity}
          activeTab={activeDataCenter}
          needToReload={needToReload}
          setNeedToReload={setNeedToReload}
          navigationActiveTab={navigationActiveTab}
          setNavigationActiveTab={setNavigationActiveTab}
          activeDataCenter={activeTab}
          setIsValueChange={setIsValueChange}
          isValueChange={isValueChange}
          setNextUPS={setNextUPS}
          setFetchingUPS={setFetchingUPS}
          setHasMoreUPS={setHasMoreUPS}
          setNextRPP={setNextRPP}
          setFetchingRPP={setFetchingRPP}
          setHasMoreRPP={setHasMoreRPP}
          setNextBreaker={setNextBreaker}
          setFetchingBreaker={setFetchingBreaker}
          setHasMoreBreaker={setHasMoreBreaker}
          incrementStep={incrementStep}
          searchQuery={searchQuery}
          filterData={filterData}
        />
        {navigationActiveTab === 1 ? (
          <Overview
            setIsLoading={setIsLoading}
            setLastActivity={setLastActivity}
            activeTab={activeTab}
          />
        ) : (
          <div>
            {navigationActiveTab === 4 && (
              <div
                className="form-check d-flex align-items-center"
                style={{ marginTop: "10px" }}
              >
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={pmi}
                  onClick={() => {
                    SetPmi(!pmi)
                  }}
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                />
                <label
                  className="form-check-label"
                  htmlFor="flexRadioDefault1"
                  style={{ marginBottom: "0px" }}
                >
                  {t("pwrdist.inventory_pmi_check")}
                </label>
              </div>
            )}
            <div className="smart_table my-3" ref={layoutRef}>
              <table className="table table-borderless">
                <tbody>
                  <tr>
                    <th width="15%">{t("pwrdist.name")}</th>
                    <th width="15%">{t("pwrdist.floor")}</th>
                    <th width="15%">{t("pwrdist.room")}</th>
                    <th width="15%">{t("pwrdist.panel")}</th>
                    <th width="10%">{t("pwrdist.status")}</th>
                    <th width="15%">{t("pwrdist.upstream_device")}</th>
                    <th width="10%">{t("pwrdist.service_cabinet")}</th>
                    <th width="5%"></th>
                  </tr>

                  {payload &&
                    payload.map((data) => {
                      return (
                        <tr>
                          <td>{data.name}</td>
                          <td>{data.floors?.name || "-"}</td>
                          <td>{data.rooms?.name || "-"}</td>
                          <td>{"-"}</td>
                          <td>{getCurrentStatus(data.status)}</td>
                          <td>{data.upstream_device_name || "-"}</td>
                          <td>{data.cabinets?.name}</td>
                          <td>
                            <a
                              href="#sd"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                OpenModel()
                                setObjectForEdit(data)
                              }}
                            >
                              <i className="fas fa-edit"></i>
                            </a>
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {toggleModal.UPSUpdateIsON ? (
          <UPSEdit
            activeTab={activeDataCenter}
            needToReload={needToReload}
            setNeedToReload={setNeedToReload}
            setModalState={setModalState}
            data={objectForEdit}
            status={status}
          />
        ) : null}

        {toggleModal.RRPUpdateIsON ? (
          <RRPEdit
            setModalState={setModalState}
            status={status}
            editData={objectForEdit}
            activeDataCenter={activeDataCenter}
            setIsValueChange={setIsValueChange}
            isValueChange={isValueChange}
            getRppByDc={getRppByDc}
          />
        ) : null}
        {toggleModal.BreakerUpdateIsON ? (
          <BreakerEdit
            setModalState={setModalState}
            status={status}
            editData={objectForEdit}
            activeDataCenter={activeDataCenter}
            setIsValueChange={setIsValueChange}
            isValueChange={isValueChange}
            getBreakerByDcAndPmi={getBreakerByDcAndPmi}
          />
        ) : null}
      </Layout>
    </React.Fragment>
  )
}

export default PowerDistribution
