import AuthContext from "context"
import React, { useContext, useEffect, useRef, useState } from "react"
import UploadImages from "services/UploadImages"
import Swal from "sweetalert2"
import "./style.css"
import { LogoutOnSessionExpire } from "common/helpers"
import { XError } from "components/common"
import { useTranslation } from "react-i18next"
import CloseIcon from "@mui/icons-material/Close"

const RackModal = ({
  closeModal,
  counter,
  i,
  prevdisable,
  nextdisable,
  prevImage,
  nextImage,
  fileExists,
  refresh,
  setImage,
  setImageStatus,
}) => {
  const authContext = useContext(AuthContext)
  const addImageRef = useRef(null)
  const [state, setState] = useState({
    data_center_id: authContext.getNetworkDataCenter?.id,
    image: "",
    isNewImage: false,
    replaceImageId: counter,
    endAt: i,
    country_id: authContext.getNetworkDataCenter?.country_id,
  })
  const [isLoading, setIsLoading] = useState(false)
  const [display, setDisplay] = useState("")
  const [imageError, setImageError] = useState("")
  const [disabled, setDisabled] = useState(false)
  const { t } = useTranslation()
  useEffect(() => {
    console.log(i)
  }, [])

  const onChangePicture = (e, status) => {
    if (e.target.files[0]) {
      setDisplay(e.target.files[0].name)
      const fileNamearr = e.target.files[0].name.split(".").pop()
      const fileExt = fileNamearr
      if (fileExt === "png") {
        var FR = new FileReader()

        FR.addEventListener("load", function (e) {
          setState({ ...state, image: e.target.result, isNewImage: status })
        })
        FR.readAsDataURL(e.target.files[0])
        setImageError("")
        setDisabled(false)
      } else {
        setImageError("Invalid file type please select PNG file.")
        setDisabled(true)
      }
    }
  }
  const uploadNewImage = (event) => {
    setImageStatus(true)
    onChangePicture(event, true)
  }
  const replaceImage = (event) => {
    setImageStatus(false)
    onChangePicture(event, false)
  }

  const onSubmit = async () => {
    setIsLoading(true)

    if (state.isNewImage === false) {
      Swal.fire({
        title: t("common.are_you_sure"),
        text: t("common.you_wont_be_able_to_revert_this"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: t("common.yes_delete_it"),
        customClass: {
          cancelButton: "cancelBtn",
          confirmButton: "confirmBtn",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          await UploadImages.uploadRackElevation(authContext.getToken, {
            ...state,
            replaceImageId: counter,
          })
            .then((res) => {
              setImage(state.image)

              setIsLoading(false)
              clear()

              Swal.fire(
                t("network.replaced"),
                t("network.your_file_has_been_replaced"),
                "success"
              )
            })
            .catch((err) => {
              LogoutOnSessionExpire(err)
              setIsLoading(false)
              Swal.fire("Error", t("network.somthing_went_wrong"), "danger")
            })
            .finally(() => {
              refresh()
            })
        }
      })
    } else {
      await UploadImages.uploadRackElevation(authContext.getToken, {
        ...state,
        replaceImageId: counter,
      })
        .then((res) => {
          setIsLoading(false)
          clear()
          Swal.fire(
            t("network.added"),
            t("network.a_new_page_has_been_added"),
            "success"
          )
        })
        .catch((err) => {
          LogoutOnSessionExpire(err)
          setIsLoading(false)
          Swal.fire("Error", t("network.somthing_went_wrong"), "danger")
        })
        .finally(() => {
          console.log("test")
          refresh()

          console.log("after refresh")
        })
    }
  }

  const clear = () => {
    closeModal(false)
    setState({
      data_center_id: authContext.getNetworkDataCenter?.id,
      image: "",
      isNewImage: false,
      replaceImageId: counter,
      endAt: i,
      country_id: authContext.getNetworkDataCenter?.country_id,
    })
  }

  return (
    <div
      class="modal fade bd-example-modal-lg show"
      tabindex="-1"
      role="dialog"
      aria-modal="true"
      style={{ display: "block" }}
    >
      <div className="modal-dialog modal-lg rack_eve">
        <div className="modal-content">
          <div className="modal-header mt-59">
            <h3 className="modal-title">{t("network.rack_elevation")}</h3>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => closeModal(false)}
            />
          </div>
          <div className="modal-body">
            <div className="card">
              <div
                className="card-body"
                style={{ padding: "0px", overflow: "hidden" }}
              >
                <div className="row mt-3">
                  <p style={{ fontSize: "0.875rem" }}>
                    {authContext.getNetworkDataCenter?.name || ""}
                  </p>
                  <div className="text-center">
                    <p
                      className="font-weight-bold"
                      style={{ fontSize: "1rem", fontWeight: 700 }}
                    >
                      {t(
                        "network.new_image_will_replace_the_existing_one_and_not_reversible_one_confirmed"
                      )}
                    </p>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    {fileExists ? (
                      <p style={{ fontSize: "0.938rem", fontWeight: 600 }}>
                        {t("network.select_page")} {counter} {t("network.of")}{" "}
                        {i} &nbsp;
                        <React.Fragment>
                          <span
                            style={{
                              cursor: counter === 1 ? "not-allowed" : "pointer",
                            }}
                            onClick={() => {
                              if (counter !== 1) {
                                prevImage(counter)
                              }
                            }}
                          >
                            <img
                              alt=""
                              src="/images/chevrons-left.svg"
                              width="10px"
                            />
                          </span>
                          &nbsp;&nbsp;&nbsp;
                        </React.Fragment>
                        <span
                          style={{
                            cursor: i === counter ? "not-allowed" : "pointer",
                          }}
                          onClick={() => {
                            if (i !== counter) {
                              nextImage(counter)
                            }
                          }}
                        >
                          <img
                            alt=""
                            src="/images/chevrons-right.svg"
                            width="10px"
                          />
                        </span>
                      </p>
                    ) : null}

                    <input
                      type="file"
                      accept="image/png"
                      onChange={(event) => uploadNewImage(event)}
                      style={{ display: "none" }}
                      ref={addImageRef}
                    />

                    <span
                      href="#"
                      onClick={() => addImageRef.current.click()}
                      className="addrack"
                    >
                      <img
                        alt=""
                        src="/images/plus-circle-blue.svg"
                        style={{ width: "1rem" }}
                      />{" "}
                      &nbsp;{t("network.add_page")}
                    </span>
                  </div>
                </div>
                <div className="basic-form">
                  <form>
                    <div className="upload">
                      <label for="file-upload" className="custom-file-upload">
                        <img
                          alt=""
                          src="/images/upload.svg"
                          width="12px;"
                          style={{ marginTop: "-4px" }}
                        />{" "}
                        {t("network.upload_image")}
                      </label>
                      <input
                        accept="image/png"
                        id="file-upload"
                        onChange={(event) => replaceImage(event)}
                        type="file"
                        className="d-none"
                      />
                      {display ? <h5>{display}</h5> : ""}
                    </div>
                    <XError message={imageError} />
                    <div
                      className="toolbar toolbar-bottom mt-51"
                      role="toolbar"
                      style={{ textAlign: "right" }}
                    >
                      <button
                        type="button"
                        onClick={() => closeModal(false)}
                        style={{ marginRight: "10px" }}
                        class="btn btn-outline-primary mr_1"
                      >
                        {" "}
                        {t("common.cancel")}{" "}
                      </button>

                      {isLoading ? (
                        <button type="button" className="btn btn-primary">
                          {t("common.loading")} ...
                        </button>
                      ) : (
                        <button
                          disabled={disabled}
                          type="button"
                          onClick={() => onSubmit()}
                          className="btn btn-primary"
                        >
                          {t("common.save")}
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RackModal
