import React, { useEffect } from 'react';
import Pagination from 'views/super-admin/Ticket/components/shared/usePagination';
const Table = (props) => {
  useEffect(() => {}, [props]);
  return (
    <React.Fragment>
      <div className="card-body" style={{ overflow: 'auto' }}>
        <div className="table-responsive tckect_tt">
          <table className="table header-border table-borderless table-hover verticle-middle">
            <thead>
              <tr>
                {props.columns &&
                  props.columns.map((column, i) => {
                    return (
                      <th scope="col" style={{ cursor: 'pointer' }}>
                        {column.value}
                        {/* <i
                          className={`fa fa-solid fa-sort-${true ? 'down' : 'up'}`}
                          style={{ fontSize: '1.3rem', paddingLeft: '6px' }}
                        ></i> */}
                      </th>
                    );
                  })}
              </tr>
            </thead>
            <tbody>
              {' '}
              {props.data &&
                props.data.map((data, i) => {
                  return (
                    <tr>
                      <td class="site-tr">{data.name}</td>
                      <td>
                        <span
                          class="site-tr"
                          onClick={() => {
                            props.handleDownload(data.id, data.name);
                          }}
                        >
                          {' '}
                          <img
                            src="images/download.svg"
                            alt=""
                            style={{ paddingTop: '2px', width: '12px' }}
                          />
                        </span>
                        <span class="site-tr" onClick={() => props.handleDelete(data.id)}>
                          {' '}
                          <img
                            src="images/fclose.svg"
                            alt=""
                            style={{ paddingTop: '2px', width: '12px', marginLeft: '8px' }}
                          />
                        </span>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        {/* <div className="d-flex justify-content-end mt-3">
          {props.data.rows && props.data.rows.length && (
            <Pagination
              setItemsPerPage={props.setItemsPerPage}
              setPageNumber={props.setPageNumber}
              total={props.total}
              pageNumber={props.pageNumber}
            ></Pagination>
          )}
        </div> */}
      </div>
    </React.Fragment>
  );
};
export default Table;
