import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import StorageContext from "context";
import React, { useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { auth } from "services/auth";
import { msalConfig } from "../Auth/authConfig";
import Logout from "./logout";
import { LogoutOnSessionExpire } from "common/helpers";
import TicketPopup from "./TicketPopup";
import { useState } from "react";
import GenericTicketModal from "../Tickets/GenericTicketModal";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
const pca = new PublicClientApplication(msalConfig);

const Navigation = () => {
  let navigate = useNavigate();
  const { t } = useTranslation();
  const contextData = React.useContext(StorageContext);
  const [invisibleMenu, setInvisibleMenu] = React.useState(false);
  const [state, setState] = React.useState({
    initialName: "",
    fullName: "",
  });
  const location = useLocation();
  const isInitialMount = useRef(true);

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip
      {...props}
      arrow
      placement="top"
      classes={{ popper: className }}
      TransitionComponent={Zoom}
      TransitionProps={{ timeout: 300 }}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.black,
      color: "rgba(0, 0, 0, 0.87)",
      minWidth: 150,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid black",
    },

    [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]: {
      marginBottom: "0px",
      marginLeft: "0rem",
    },
  }));

  React.useEffect(() => {
    //user();
    const isRefreshed = localStorage.getItem("isRefreshed");
    if (isInitialMount.current && isRefreshed && isRefreshed != "true") {
      isInitialMount.current = false;
      user();
    }

    short();
    if (location.pathname.split("/")[1] === "setting") {
      setInvisibleMenu(true);
    } else {
      setInvisibleMenu(false);
    }
  }, [contextData.getAuth]);
  const getMaintenaceModeStatus = () => {
    const status = contextData.getMaintenanceModeStatus;
    if (status && status?.isMaintenanceModeOn) {
      return true;
    } else {
      return false;
    }
  };
  // const onLogout = (event) => {

  // 	event.preventDefault();

  // 	contextData.logout();

  // 	navigate(`/`);
  // }

  const user = async () => {
    const token = localStorage.getItem("token");

    await auth(token)
      .then((res) => {
        contextData.setMaintenanceModeStatus(res.data.maintenanceModeStatus);
        contextData.setAuth(res.data.user);
        contextData.setMonthYear(res.data.currentMonth, res.data.currentYear);
        contextData.setDataCenter(res.data.dataCenter);
        contextData.setCountry(res.data.country);
      })
      .catch((err) => {
        LogoutOnSessionExpire(err);
        localStorage.removeItem("token");
      });
  };
  const short = () => {
    if (contextData.getAuth && contextData.getAuth.name) {
      let initials = "";
      const fullName = contextData.getAuth.name.split(" ");

      if (fullName.length > 1) {
        initials = fullName.shift().charAt(0) + fullName.pop().charAt(0);
      } else {
        initials = fullName.shift().charAt(0);
      }
      setState({
        initialName: initials.toUpperCase(),
        fullName: contextData.getAuth.name,
      });
    }
  };

  const [currentTicketType, setCurrentTicketType] = useState(null);
  const [createTicketShow, setCreateTicketShow] = useState(false);

  const openCreateTicketModal = (ticketType) => {
    setCurrentTicketType(ticketType);
    setCreateTicketShow(true);
    contextData.setAutoRefresh(false);
  };

  const checkTicketAndProvisioningPermission = (type) => {
    if (type == "ticket") {
      if (
        contextData?.getAuth?.role?.role_ticket &&
        contextData?.getAuth?.role?.role_ticket.length > 0
      ) {
        return contextData?.getAuth?.role?.role_ticket.some(
          (item) => item.is_allowed === 1
        );
      } else {
        return false;
      }
    }
    if (type == "provisioning") {
      if (
        contextData?.getAuth?.role?.role_provisioning &&
        contextData?.getAuth?.role?.role_provisioning.length > 0
      ) {
        return contextData?.getAuth?.role?.role_provisioning.some(
          (item) => item.is_allowed === 1
        );
      } else {
        return false;
      }
    }
  };
  const checkTicketCreatePermission = () => {
    const ticketTypes = contextData.getAuth?.ticketPermission;
    if (
      ticketTypes.filter(
        (el) =>
          el?.is_allowed === 1 && el?.roleTicketPermission[0]?.is_allowed === 1
      ).length > 0
    ) {
      return true;
    }
    return false;
  };
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-theme">
      <div className="container-fluid p-0">
        {/*<XLogo/>*/}

        <Link
          to="/data-center"
          className="navbar-brand"
          onClick={() => {
            localStorage.setItem("isRefreshed", false);
          }}
        >
          <img src="\images\logo.png" />
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul
            className={`navbar-nav me-auto mb-2 mb-lg-0 ${
              invisibleMenu ? "invisible" : ""
            }`}
          >
            <li className="nav-item hide">
              <a className="nav-link dt_nav" aria-current="page" href="#">
                {t(`header.dashboard`)}
              </a>
            </li>
            {!getMaintenaceModeStatus() && (
              <li className="nav-item">
                <Link
                  to="/home"
                  className={`nav-link dt_nav ${
                    location.pathname === "/home" ? "active" : ""
                  }`}
                  onClick={() => {
                    localStorage.setItem("isRefreshed", false);
                  }}
                >
                  {t(`header.home`)}
                </Link>
              </li>
            )}
            {!getMaintenaceModeStatus() &&
              contextData?.getAuth?.role?.dcs !== 0 && (
                <li className="nav-item">
                  <Link
                    to="/data-center"
                    className={`nav-link dt_nav ${
                      location.pathname === "/data-center" ? "active" : ""
                    }`}
                    onClick={() => {
                      localStorage.setItem("isRefreshed", false);
                    }}
                  >
                    {t(`header.data_centres`)}
                  </Link>
                </li>
              )}
            {!getMaintenaceModeStatus() && contextData.getAuth?.role?.esg != 0 && (
              <li className="nav-item">
                <Link
                  to="/esg"
                  className={`nav-link dt_nav ${
                    location.pathname === "/esg" ? "active" : ""
                  }`}
                  onClick={() => {
                    localStorage.setItem("isRefreshed", false);
                  }}
                >
                  {t(`header.esg`)}
                </Link>
              </li>
            )}
            {!getMaintenaceModeStatus() &&
              checkTicketAndProvisioningPermission("ticket") && (
                <li className="nav-item">
                  <Link
                    to="/ticket"
                    className={`nav-link dt_nav ${
                      location.pathname === "/ticket" ? "active" : ""
                    }`}
                    onClick={() => {
                      localStorage.setItem("isRefreshed", false);
                    }}
                  >
                    Tickets
                  </Link>
                </li>
              )}
            {!getMaintenaceModeStatus() &&
              checkTicketAndProvisioningPermission("provisioning") && (
                <li className="nav-item">
                  <Link
                    to="/provisioning"
                    className={`nav-link dt_nav ${
                      location.pathname === "/provisioning" ? "active" : ""
                    }`}
                    onClick={() => {
                      localStorage.setItem("isRefreshed", false);
                    }}
                  >
                    {t(`header.provisioning`)}
                  </Link>
                </li>
              )}

            {!getMaintenaceModeStatus() &&
              contextData.getAuth?.role?.accounts != 0 && (
                <li className="nav-item">
                  <Link
                    to="/accounts"
                    className={`nav-link dt_nav ${
                      location.pathname === "/accounts" ? "active" : ""
                    }`}
                    onClick={() => {
                      localStorage.setItem("isRefreshed", false);
                    }}
                  >
                    {t(`header.accounts`)}
                  </Link>
                </li>
              )}
            {!getMaintenaceModeStatus() &&
              contextData.getAuth?.role?.contact != 0 && (
                <li className="nav-item">
                  <Link
                    to="/contacts"
                    className={`nav-link dt_nav ${
                      location.pathname === "/contacts" ? "active" : ""
                    }`}
                    onClick={() => {
                      localStorage.setItem("isRefreshed", false);
                    }}
                  >
                    Contacts
                  </Link>
                </li>
              )}
            <li className="nav-item hide">
              <a className="nav-link dt_nav" href="#">
                Svc Desks
              </a>
            </li>
            <li className="nav-item hide">
              <a className="nav-link dt_nav" href="#">
                Metrics
              </a>
            </li>
            <li className="nav-item hide">
              <a className="nav-link dt_nav" href="#">
                Analytics
              </a>
            </li>

            {/* if maintenance mode status === 3 */}
            {contextData.getMaintenanceModeStatus?.status_current === 3 && (
              <HtmlTooltip
                title={
                  <p
                    style={{
                      color: "white",
                      fontSize: ".8rem",
                      textAlign: "center",
                    }}
                  >
                    The Ops portal is currently in maintenance mode, some
                    services may be deprecated.
                  </p>
                }
              >
                <li
                  className="nav-item"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "10px", // to align it better with other links
                  }}
                >
                  <p
                    style={{
                      border: "2px solid orange",
                      padding: "5px",
                      color: "white",
                      borderRadius: "5px",
                    }}
                  >
                    <WarningAmberIcon
                      sx={{ color: "orange", marginRight: "3px" }}
                    />
                    Maintenance mode{" "}
                    <span style={{ color: "#3dfc03", fontWeight: "500" }}>
                      ON
                    </span>
                  </p>
                </li>
              </HtmlTooltip>
            )}
          </ul>
          {!getMaintenaceModeStatus() && checkTicketCreatePermission() && (
            <TicketPopup openCreateTicketModal={openCreateTicketModal} />
          )}
          <ul className="navbar-nav header-right">
            {contextData?.getAuth?.role?.user_management !== 0 ? (
              <li className="nav-item dropdown notification_dropdown">
                <Link
                  className="nav-link setting"
                  to="/setting-user"
                  onClick={() => {
                    localStorage.setItem("isRefreshed", false);
                  }}
                >
                  <img src="\images\settings-10@1x.png" alt="" />
                </Link>
              </li>
            ) : contextData?.getAuth?.role?.dcs !== 0 ||
              contextData?.getAuth?.role?.space === 3 ||
              contextData?.getAuth?.role?.m_e === 3 ||
              contextData?.getAuth?.role?.network === 3 ? (
              <li className="nav-item dropdown notification_dropdown">
                <Link
                  className="nav-link setting"
                  to={
                    contextData?.getAuth?.role?.dcs != 0
                      ? "/setting-data-center"
                      : "/setting-cabling-and-patch-nw-rooms"
                  }
                  onClick={() => {
                    localStorage.setItem("isRefreshed", false);
                  }}
                >
                  <img src="\images\settings-10@1x.png" alt="" />
                </Link>
              </li>
            ) : null}

            <li className="nav-item dropdown header-profile">
              <a
                className="nav-link"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
              >
                <h4 className="pro"> {state.initialName} </h4>
                <div>
                  <span className="font-w400 d-block">{state.fullName}</span>
                  <small className="text-end font-w400" id="admin">
                    {" "}
                    {contextData?.getAuth?.role?.name}{" "}
                  </small>
                </div>
              </a>
              <i className="fa fa-caret-down" aria-hidden="true" id="don"></i>

              <div className="dropdown-menu dropdown-menu-end">
                <Link
                  to="/profile"
                  className="dropdown-item ai-icon"
                  onClick={() => {
                    localStorage.setItem("isRefreshed", false);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="text-primary"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                  <span className="ms-2">Profile </span>
                </Link>
                {/* <a href="email-inbox.html" className="dropdown-item ai-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="text-success"
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                    <polyline points="22,6 12,13 2,6"></polyline>
                  </svg>
                  <span className="ms-2">Inbox </span>
                </a> */}

                <MsalProvider instance={pca}>
                  <Logout></Logout>
                </MsalProvider>
              </div>
            </li>
          </ul>
        </div>
      </div>
      {createTicketShow && (
        <GenericTicketModal
          ticketType={currentTicketType}
          show={createTicketShow}
          setShow={setCreateTicketShow}
        />
      )}
    </nav>
  );
};

export default Navigation;
