import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const SidePop = ({ setSidePopVal, SidePopVal, setSidePop }) => {
  const [state, setState] = useState({ ...SidePopVal });
  const { t } = useTranslation();

  const Options = [
    { name: t(`provisioning.table.title`), field: 'name' },
    { name: t(`provisioning.table.customer`), field: 'customer' },
    { name: t(`provisioning.table.end_customer`), field: 'end_customer' },
    { name: t(`provisioning.table.sales_order_item`), field: 'sale_order_item' },
    { name: t(`provisioning.table.product`), field: 'product' },
    { name: t(`provisioning.table.service_request_date`), field: 'service_request_date' },
    { name: t(`provisioning.table.service_commencement_date`), field: 'service_commencement_date' },
    { name: t(`provisioning.table.site`), field: 'site' },
    { name: t(`provisioning.table.space_id`), field: 'space' },
    { name: t(`provisioning.table.related_space_service`), field: 'related_space_id' },
    { name: t(`provisioning.table.remarks`), field: 'remarks' },
    { name: t(`provisioning.table.description`), field: 'description' },
    { name: t(`provisioning.table.ban`), field: 'ban' },
    { name: t(`provisioning.table.third_party_circuit`), field: 'third_party_circuit' },
    { name: t(`provisioning.table.parent_task`), field: 'parent_task' },
    {
      name: t(`provisioning.table.customer_service_reference`),
      field: 'customer_service_reference'
    },
    {
      name: t(`provisioning.table.legacy_ib_number`),
      field: 'legacy_ib_number'
    },
    { name: t(`provisioning.table.a_side_service_id`), field: 'a_side_space_id' },
    { name: t(`provisioning.table.z_side_service_id`), field: 'z_side_space_id' },
    { name: t(`provisioning.table.assignee`), field: 'assignee' },
    { name: t(`provisioning.table.status`), field: 'status' },
    { name: t(`provisioning.table.task_type`), field: 'task_type' }
  ];
  return (
    <>
      <div className="sidePop2" style={{ zIndex: '10' }}>
        {Options.map((data, i) => {
          return (
            <div key={i}>
              <div className="d-flex gap-2">
                <div>
                  <input
                    type="checkbox"
                    style={{ cursor: 'pointer' }}
                    onChange={() =>
                      setState((prev) => ({ ...prev, [data.field]: !prev[data.field] }))
                    }
                    checked={state[data.field]}
                  />
                </div>
                <div>{data.name}</div>
              </div>
            </div>
          );
        })}

        <div className="side-buttons d-flex gap-2">
          <div>
            <button className="btn-can" onClick={() => setSidePop(false)}>
              Cancel
            </button>
          </div>
          <div>
            <button
              className="btn-app"
              onClick={() => {
                setSidePopVal({ ...state });
                setSidePop(false);
              }}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SidePop;
