import { css } from 'glamor'

const TicketCard = ({ label, children, extraStyles }) => {
  const styles = {
    header: {
      fontSize: '20px',
      backgroundColor: 'orange',
      borderRadius: '14px 14px 0 0',
      paddingLeft: '10px'
    },
    content: {
      backgroundColor: '#fff',
      borderRadius: '0 0 14px 14px',
      border: '3px solid orange',
      padding: '5px 0 20px 0',
      '& > *': {
        padding: '0 25px 0 25px'
      },
      ...extraStyles
    }
  }
  return (
    <div className='pb-1 mb-3'>
      <div {...css(styles.header)}>{label}</div>
      <div {...css(styles.content)}>
        {children}
      </div>
    </div>
  )
}

export default TicketCard
