export const subMenu = [
  {
    path: "/setting-data-center",
    title: "inventory",
  },
  {
    path: "/setting-power-distibution",
    title: "pwr_dist",
  },
  {
    path: "/setting-cross-connect",
    title: "x_connect",
  },
  {
    path: "/setting-capacity",
    title: "capacity",
  },
  {
    path: "/setting-bcm",
    title: "bcm",
  },
];
