import { axiosWithToken as axios } from "utils/axios";
import { LogoutOnSessionExpire } from "common/helpers";

class RoleService {
  /*
	|-------------------------------------------------------------------------------
	| Role and Permission Listing
	|-------------------------------------------------------------------------------
	*/
  async roleAndPermission(token, q = "") {
    return await axios(token).get(`RoleAndPermissions?q=` + q);
  }
  /*
	|-------------------------------------------------------------------------------
	| Country List
	|-------------------------------------------------------------------------------
	*/
  async countryService(token, allCountry = false) {
    if (allCountry) {
      return await axios(token).get(`Countires?allCountry=1`);
    } else {
      return await axios(token).get(`Countires?allCountry=0`);
    }
  }
  /*
	|-------------------------------------------------------------------------------
	| Data center
	|-------------------------------------------------------------------------------
	*/
  async dataCenter(token) {
    return await axios(token).get(`DataCenters`);
  }
  /*
	|-------------------------------------------------------------------------------
	| Data center by country id list
	|-------------------------------------------------------------------------------
	*/
  async dataCenterByCountryId(token, data) {
    return await axios(token).get(`DataCenters?id=${data.id}`);
  }
  /*
	|-------------------------------------------------------------------------------
	| Create new Role
	|-------------------------------------------------------------------------------
	*/
  async createRoleAndPermissions(token, tab, data) {
    return await axios(token).post(
      `CreateAndUpdateRoleAndPermission?tab=${tab}`,
      data
    );
  }
  /*
	|-------------------------------------------------------------------------------
	| Update Role
	|-------------------------------------------------------------------------------
	*/
  async updateRoleAndPermissions(token, tab, data) {
    return await axios(token).put(
      `CreateAndUpdateRoleAndPermission?tab=${tab}`,
      data
    );
  }
  /*
	|-------------------------------------------------------------------------------
	| Update Status
	|-------------------------------------------------------------------------------
	*/
  async updateStatus(token, data) {
    return await axios(token).post(`UpdateRoleStatus`, data);
  }
  /*
	|-------------------------------------------------------------------------------
	| Destroy Role
	|-------------------------------------------------------------------------------
	*/
  async destroy(token, data) {
    return await axios(token).post(`DeleteRole`, data);
  }
  /*
	|-------------------------------------------------------------------------------
	| Create Data center
	|-------------------------------------------------------------------------------
	*/
  async createDataCenter(token, data) {
    return await axios(token).post(`addDataCenter`, data);
  }

  /*
	|-------------------------------------------------------------------------------
	| Get Data center Type
	|-------------------------------------------------------------------------------
	*/
  async getDataCenterType(token) {
    return await axios(token).get(`getDataCenterType`);
  }

  async getAssignedUser(token, body) {
    return await axios(token).post(`getUsersByRoleId`, body);
  }
  async reassignRole(token, body) {
    return await axios(token).post(`reassignRole`, body);
  }

  async getRoleDetail(token, id) {
    return await axios(token).get(`getRoleDetail?id=${id}`);
  }
}

export default new RoleService();
