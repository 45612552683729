import React, { useEffect, useState } from "react";
import useSetParameters from "../../shared/hooks/useSetParameters";
import useGetServiceIds from "../../shared/hooks/useGetServiceIds";
import useValidateTime from "../hooks/useValidateTime";
import useGetCompaniesAndCountries from "../../shared/hooks/useGetCompaniesAndCountries";
import useGetOperatingSitesAndCustomers from "../../shared/hooks/useGetOperatingSitesAndCustomers";
import useGetTeams from "../../shared/hooks/useGetTeams";
import * as utils from "../../shared/utils";
import * as yup from "yup";
import { useFormik } from "formik";
import ShipmentTicketFormContext from "./ShipmentTicketFormContext";
import useResetFields from "../../FaultTicketForm/hooks/useResetFields";
import { useTranslation } from "react-i18next";
import useGetHandlingInstructions from "../hooks/useGetHandlingInstructions";
import useGetDimensions from "../hooks/useGetDimensions";
import useGetTicketTypes from "../hooks/useGetTicketType";

const initialValues = {
  country: "",
  operatingSite: "",
  customer: "",
  shipmentType: "",
  subject: "",
  description: "",
  companyName: "",
  handlingInstruction: "",
  serviceId: "",
  expectedDate: "",
  courierCompany: "",
  carLicense: "",
  loadingDockRequired: "no",
  loadingDockStartTime: "",
  loadingDockEndTime: "",
  detailsTrackingNo: "",
  detailsRemarks: "",
  detailsWeight: "",
  detailsWeightDimension: "",
  detailsHeight: "",
  detailsWidth: "",
  detailsDepth: "",
  detailsSizeDimension: "",
  packageList: [],
  dataCenters: [],
  countries: [],
  services: [],
  teams: [],
  ticketTypes: [],
  isShowResellerContacts: false,
};

const ShipmentTicketFormContextProvider = ({
  closeModal,
  setLoading,
  id,
  children,
}) => {
  const { t } = useTranslation();

  const checkIfNotEmpty = (field) => {
    return yup
      .string()
      .test(
        "empty-check",
        t("create-ticket.shipment.details.unsaved-form"),
        (value) => value.length === 0
      );
  };

  const schema = yup.object().shape({
    companyName: yup.number().required(),
    operatingSite: yup.number().required(),
    subject: yup.string().required(),
    shipmentType: yup.string().required(),
    handlingInstruction: yup.number().required(),
    // serviceId: yup.number(),
    // expectedDate: yup.object().required(),
    //courierCompany: yup.string().required(t('create-ticket.shipment.courier-company-validation')),
    loadingDockRequired: yup.string().required(),
    packageList: yup.array().min(1).required(),
    //detailsTrackingNo: checkIfNotEmpty('detailsTrackingNo'),
    //detailsRemarks: checkIfNotEmpty('detailsRemarks'),
    detailsWeight: checkIfNotEmpty("detailsWeight"),
    detailsWeightDimension: checkIfNotEmpty("detailsWeightDimension"),
    detailsHeight: checkIfNotEmpty("detailsHeight"),
    detailsWidth: checkIfNotEmpty("detailsWidth"),
    detailsDepth: checkIfNotEmpty("detailsDepth"),
    detailsSizeDimension: checkIfNotEmpty("detailsSizeDimension"),
  });

  const { teams } = useGetTeams();

  const [validationError, setValidationError] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);

  const returnValuesIfValid = async (values) => {
    const result = await schema
      .validate(values, { abortEarly: false })
      .catch((err) => {
        console.log(err?.errors[0]);
        setValidationError(err?.errors[0]);
      });
    return result;
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      // console.log(values)
      const result = await returnValuesIfValid(values);
      if (result) {
        // console.log(result)
        setShowConfirmation(true);
      }
    },
  });

  const { ticketTypes } = useGetTicketTypes(formik.setFieldValue);

  const {
    companies,
    countries,
    companiesSite,
    isCompanyLoading,
  } = useGetCompaniesAndCountries(
    setLoading,
    formik.setFieldValue,
    formik.values.country
  );

  const {
    operatingSites,
    customers,
    dataCenters,
    isOperatingSitesLoading,
  } = useGetOperatingSitesAndCustomers({
    countryId: utils.getCountryIdByCode(formik.values.country, countries),
    companyId: formik.values.companyName,
    setLoading,
    country: formik.values.country,
    countries: countries,
    isShowResellerContacts: formik.values.isShowResellerContacts,
  });

  useValidateTime({
    isRequired: formik.values.loadingDockRequired,
    startTime: formik.values.loadingDockStartTime,
    endTime: formik.values.loadingDockEndTime,
    startTimeName: "loadingDockStartTime",
    endTimeName: "loadingDockEndTime",
    setFieldValue: formik.setFieldValue,
    date: formik.values.expectedDate,
  });

  const { services, isServiceLoading } = useGetServiceIds(
    formik.values.companyName,
    formik.values.operatingSite,
    setLoading,
    id
  );

  useEffect(() => {
    if (services && services.length > 0) {
      formik.setFieldValue("services", services);
    }
  }, [services]);

  useResetFields(formik);

  const { handlingInstructions } = useGetHandlingInstructions();
  const { weightDimensions, lengthDimensions } = useGetDimensions();

  const addCondition = (func, field) => {
    return func(field) && formik.values[field] !== "";
  };

  useSetParameters({
    dataCenters,
    countries,
    setFieldValue: formik.setFieldValue,
  });

  const isActive = (field) => {
    switch (field) {
      case "companyName":
        return formik.values.country !== "";
      case "operatingSite":
        return addCondition(isActive, "companyName");
      case "customer":
        return addCondition(isActive, "operatingSite");
      case "shipmentType":
        return addCondition(isActive, "operatingSite");
      case "subject":
        return addCondition(isActive, "shipmentType");
      case "description":
        return addCondition(isActive, "subject");
      case "handlingInstruction":
        return addCondition(isActive, "subject");
      case "serviceId":
        return addCondition(isActive, "handlingInstruction");
      case "expectedDate":
        return addCondition(isActive, "handlingInstruction");
      case "submit":
        return isActive("expectedDate") && formik.values.packageList.length > 0;
      default:
        return false;
    }
  };

  return (
    <ShipmentTicketFormContext.Provider
      value={{
        formik,
        isActive,
        companies,
        countries,
        teams,
        operatingSites,
        customers,
        services,
        handlingInstructions,
        weightDimensions,
        lengthDimensions,
        validationError,
        setValidationError,
        showConfirmation,
        setShowConfirmation,
        closeModal,
        ticketTypes,
        companiesSite,
        isCompanyLoading,
        isServiceLoading,
        isOperatingSitesLoading,
      }}
    >
      <form onSubmit={formik.handleSubmit}>{children}</form>
    </ShipmentTicketFormContext.Provider>
  );
};

export default ShipmentTicketFormContextProvider;
