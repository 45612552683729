// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card_box_chip {
  /* background: #f58634; */
  border-radius: 14px;
  color: #000000;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  padding: 4px 8px;
  text-align: center;
  border: 1px solid black;
}
.card_box_chips {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.test .btnstyle a.btn.msg-button {
  color: #51b57c;
  font-weight: 600;
  background-color: white;
  border: 2px solid black;
  width: fit-content;
}
.test .btnstyle a.btn.tab_button {
  font-size: 1.1rem;
  color: #92979A;
  font-weight: 600;
  background-color: white;
  width: fit-content;
  padding-bottom: 0;
  margin-bottom: 1rem;
  position: relative;
}
.btnstyle a.btn.tab_button.bactive {
  color:var(--primary) !important;
  border-bottom: 3px solid var(--primary) !important;
  padding-bottom: 0;
  margin-bottom: 1rem;
}
.btnstyle a.btn.tab_button.bactive.bactive:after {
  content:'';
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  top: 100%;
  border-top: 0.75rem solid  var(--primary);
  border-left: 0.75rem solid transparent;
  border-right: 0.75rem solid transparent;
}
.valueText {
  font-size: 1rem;
  color: #82b4b2;
}
`, "",{"version":3,"sources":["webpack://./src/views/super-admin/Contacts/style.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,mBAAmB;EACnB,cAAc;EACd,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;EAClB,uBAAuB;AACzB;AACA;EACE,mBAAmB;EACnB,aAAa;EACb,eAAe;EACf,QAAQ;AACV;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,uBAAuB;EACvB,uBAAuB;EACvB,kBAAkB;AACpB;AACA;EACE,iBAAiB;EACjB,cAAc;EACd,gBAAgB;EAChB,uBAAuB;EACvB,kBAAkB;EAClB,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,+BAA+B;EAC/B,kDAAkD;EAClD,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,UAAU;EACV,kBAAkB;EAClB,OAAO;EACP,QAAQ;EACR,cAAc;EACd,QAAQ;EACR,SAAS;EACT,SAAS;EACT,yCAAyC;EACzC,sCAAsC;EACtC,uCAAuC;AACzC;AACA;EACE,eAAe;EACf,cAAc;AAChB","sourcesContent":[".card_box_chip {\n  /* background: #f58634; */\n  border-radius: 14px;\n  color: #000000;\n  font-size: 12px;\n  font-weight: 400;\n  line-height: 20px;\n  padding: 4px 8px;\n  text-align: center;\n  border: 1px solid black;\n}\n.card_box_chips {\n  align-items: center;\n  display: flex;\n  flex-wrap: wrap;\n  gap: 5px;\n}\n\n.test .btnstyle a.btn.msg-button {\n  color: #51b57c;\n  font-weight: 600;\n  background-color: white;\n  border: 2px solid black;\n  width: fit-content;\n}\n.test .btnstyle a.btn.tab_button {\n  font-size: 1.1rem;\n  color: #92979A;\n  font-weight: 600;\n  background-color: white;\n  width: fit-content;\n  padding-bottom: 0;\n  margin-bottom: 1rem;\n  position: relative;\n}\n.btnstyle a.btn.tab_button.bactive {\n  color:var(--primary) !important;\n  border-bottom: 3px solid var(--primary) !important;\n  padding-bottom: 0;\n  margin-bottom: 1rem;\n}\n.btnstyle a.btn.tab_button.bactive.bactive:after {\n  content:'';\n  position: absolute;\n  left: 0;\n  right: 0;\n  margin: 0 auto;\n  width: 0;\n  height: 0;\n  top: 100%;\n  border-top: 0.75rem solid  var(--primary);\n  border-left: 0.75rem solid transparent;\n  border-right: 0.75rem solid transparent;\n}\n.valueText {\n  font-size: 1rem;\n  color: #82b4b2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
