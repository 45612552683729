import AuthContext from 'context';
import moment from 'moment';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreateTicketService from 'services/createTicketService';
import Swal from 'sweetalert2';
import * as utils from '../../shared/utils';
import ShipmentTicketFormContext from '../context/ShipmentTicketFormContext';

const SHIPMENT_PREFIX = 'Shipment ';

const useCreateShipmentTicket = ({ closeModal }) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const [requestOnFlight, setRequestOnFlight] = useState(false);

  const form = useContext(ShipmentTicketFormContext);

  const { services, dataCenters, countries, teams, handlingInstructions, lengthDimensions } = form;

  const {
    country,
    operatingSite,
    customer,
    shipmentType,
    subject,
    description,
    companyName,
    handlingInstruction,
    serviceId,
    expectedDate,
    courierCompany,
    carLicense,
    loadingDockRequired,
    loadingDockStartTime,
    loadingDockEndTime,
    packageList
  } = form.formik.values;

  const getShipmentDetail = () => {
    return packageList.map(
      (
        {
          detailsTrackingNo,
          detailsWeight,
          detailsWeightDimension,
          detailsHeight,
          detailsWidth,
          detailsDepth,
          detailsSizeDimension,
          detailsRemarks
        },
        index
      ) => {
        const details = {
          item_number: index + 1,
          tracking_number: detailsTrackingNo,
          storage_location: handlingInstructions.find((item) => item.id === handlingInstruction)
            .key,
          loading_dock: loadingDockRequired === 'yes',
          remarks: detailsRemarks
        };

        if (detailsWeight && detailsWeight !== '') {
          details.weight = detailsWeight;
          details.weight_uom = detailsWeightDimension;
        }

        if (detailsHeight && detailsHeight !== '') {
          try {
            const dim = lengthDimensions.find((item) => item.id === detailsSizeDimension).label;
            details.dimension = `${detailsHeight} ${dim} x ${detailsWidth} ${dim} x ${detailsDepth} ${dim}`;
            details.dimension_uom = detailsSizeDimension;
          } catch (err) {}
        }

        return details;
      }
    );
  };

  const postNewTicket = async (values) => {
    // console.log({ ticketTypes })
    try {
      const dataCenterCountry = utils.getDataCenterCountry(operatingSite, dataCenters, countries);

      const payload = {
        is_inbound: shipmentType === 'inbound',
        operating_site_id: operatingSite,
        customer_id: parseInt(customer, 10),
        designated_company_id: parseInt(companyName, 10),
        subject,
        description,
        team_id: utils.getTeamIdByCountry(teams, country, SHIPMENT_PREFIX, dataCenterCountry,countries),
        handling_instruction: handlingInstructions.find((item) => item.id === handlingInstruction)
          .key,
        prority_id: 0,
        expected_shipment_date: moment(expectedDate).utc().format(),
        courier_company: courierCompany,
        car_license: carLicense,
        loading_dock_required: loadingDockRequired === 'yes',
        service_id: serviceId ? serviceId : null,
        space_id: serviceId ? services.find((service) => serviceId).space_id : null,
        shipment_detail: getShipmentDetail()
      };
      if (loadingDockRequired === 'yes') {
        payload.shipment_date_start = moment(loadingDockStartTime).utc().format();
        payload.shipment_date_end = moment(loadingDockEndTime).utc().format();
      }
      if(requestOnFlight === false){
      setRequestOnFlight(true);
      // console.log(payload)
      const result = await CreateTicketService.createTicket(
        authContext.token(),
        payload,
        'shipment'
      );
      // console.log(result.data.data)
      const ticketId = result.id;
      setRequestOnFlight(false);
      closeModal();
      await Swal.fire({
        icon: "success",
        title: `${t("done")}!`,
        text: t("create-ticket.site-access.success", {
          ticket: `${subject} (#${ticketId})`,
        }),
        confirmButtonText: "Ok",
        customClass: {
          cancelButton: "cancelBtn",
          confirmButton: "confirmBtn",
        },
      })
      }
    } catch (err) {
      setRequestOnFlight(false);
      await Swal.fire(t('error'), err.message);
    }
  };

  return {
    postNewTicket,
    requestOnFlight
  };
};

export default useCreateShipmentTicket;
