// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create-ticket-fault-description {
  font-size: 16px;
  margin-top: 20px;
  padding-left: 0 !important;
  padding-right: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/views/super-admin/Tickets/FaultTicketForm/Description/Description.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,gBAAA;EACA,0BAAA;EACA,2BAAA;AACJ","sourcesContent":[".create-ticket-fault-description {\n    font-size: 16px;\n    margin-top: 20px;\n    padding-left: 0 !important;\n    padding-right: 0 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
