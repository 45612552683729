import { axiosWithToken as axios } from "utils/axios"

class BcmService {
  async uploadBcmMappingFile(token, dataCenter, data) {
    return await axios(token).post(
      `uploadBcmMappingExcelFile?dataCenter=${dataCenter}`,
      data
    )
  }

  async getBcmLogs(token, dataCenter) {
    return await axios(token).get(`getBcmLogsList?dataCenter=${dataCenter}`)
  }

  async getRawBCMData(token, breaker, year, month) {
    return await axios(token).get(
      `getRawBCMData?breaker=${breaker}&year=${year}&month=${month}`
    )
  }
  async getBreakerData(token, body) {
    return await axios(token).post(`getBreakerData`, body)
  }

  async downloadBcmMappingFile(token, fileName) {
    return await axios(token).get(
      `downloadBcmMappingFile?fileName=${fileName}`,
      {
        responseType: "blob",
      }
    )
  }
}

export default new BcmService()
