const ArrowDownIcon = () => {
  return (
    <svg
      height='20px' width='20px' version='1.1' id='_x32_' xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 512 512'
    >
      <style type='text/css' />
      <g>
        <path
          class='st0' d='M256.009,0C114.615,0,0,114.615,0,255.991C0,397.385,114.615,512,256.009,512
		C397.385,512,512,397.385,512,255.991C512,114.615,397.385,0,256.009,0z M256.009,365.782l-133.57-133.57l44.096-44.097
		l89.473,89.456l89.456-89.456l44.097,44.097L256.009,365.782z'
        />
      </g>
    </svg>
  )
}

export default ArrowDownIcon
