import React, { useEffect, useState } from 'react';
import AddAccessCard from './addAccessCard';
import EditAccessCard from './editAccessCard';
import moment from 'moment';

const AccessCard = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [data, setData] = useState([]);
  const [index, setIndex] = useState(0);
  const deleteNode = (i) => {
    const updatedData = data.filter((_, index) => index !== i);
    // Update the state with the new array
    setData(updatedData);
  };

  const updateAccessCards = async () => {
    let newData = [];
    data.map((card) => {
      newData.push({
        id: card?.id ? card?.id : null,
        access_card_number: card?.access_card_number,
        status: card?.status === 'Active' ? 'active' : card?.status === 'Disabled' ? 'disabled' : ''
      });
    });
    // console.log(newData);
    props.setIsLoading(true);
    await props.updateNote({ access_card_details: newData }, props.id);
    props.setIsLoading(false);
  };

  useEffect(() => {
    setData(props.data);
  }, [props.data]);

  return (
    <React.Fragment>
      <div className="card-body" style={{ overflow: 'auto' }}>
        {props.checkPermission() && (
          <div class="col-md-12" style={{ textAlign: 'right' }}>
            <button
              class="btn me-3 btn-sm assign-btn "
              onClick={() => {
                setShow(true);
              }}
            >
              Add
            </button>{' '}
            <button
              class="btn me-3 btn-sm assign-btn "
              onClick={() => {
                updateAccessCards();
              }}
            >
              Save
            </button>
            <button
              class="btn me-3 btn-sm assign-btn "
              onClick={() => {
                // console.log(props.data);
                setData(props.data);
              }}
            >
              Discard
            </button>
          </div>
        )}
        <div className="table-responsive tckect_tt">
          <table className="table header-border table-borderless table-hover verticle-middle">
            <thead>
              <tr>
                <th>Access Card Number</th>
                <th>Added Date</th>
                <th>Status</th>
                {props.checkPermission() && <th>Action</th>}
              </tr>
            </thead>
            <tbody>
              {' '}
              {data &&
                data.map((data, i) => {
                  return (
                    <tr>
                      <td className="pd-l bold-txt cus-search">
                        {' '}
                        {data.access_card_number ? data.access_card_number : 'N/A'}
                      </td>
                      <td className="pd-l bold-txt cus-search">
                        {data.added_date
                          ? data.added_date
                          : moment(new Date()).format('YYYY-MM-DD')}
                      </td>
                      <td className="pd-l bold-txt cus-search">
                        {data.status ? data.status : 'N/A'}
                      </td>
                      {props.checkPermission() && (
                        <td className="pd-l bold-txt cus-search">
                          <a
                            href="javascript:void(0)"
                            onClick={() => {
                              setIndex(i);
                              setEditShow(true);
                            }}
                          >
                            <i className={`fa fa-solid fa-pencil`}></i>
                          </a>{' '}
                          |{' '}
                          <a
                            href="javascript:void(0)"
                            onClick={() => {
                              deleteNode(i);
                            }}
                          >
                            <i className={`fa fa-solid fa-trash`}></i>
                          </a>
                        </td>
                      )}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      </div>
      {show && <AddAccessCard show={show} setShow={setShow} setData={setData} data={data} />}
      {editShow && (
        <EditAccessCard setEditShow={setEditShow} setData={setData} index={index} data={data} />
      )}
    </React.Fragment>
  );
};

export default AccessCard;
