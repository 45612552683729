import { useEffect, useState, useContext } from "react";
import AuthContext from "context";
import Layout from "../Layouts";
import "../Home/style.css";
import { useTranslation } from "react-i18next";
import RoleService from "services/roleServices";
import { useNavigate } from "react-router-dom";
import UserService from "services/userServices";
import DataCenter from "services/dataCenterChart";
import Loading from "../pre-loader/loader";
import moment from "moment";

const Home = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [defaultPage, setDefaultPage] = useState(null);
  const contextStore = useContext(AuthContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [pages, setPages] = useState([
    {
      name: "Data Centers",
      path: "/data-center",
      field: "dcs",
    },
    {
      name: "ESG",
      path: "/esg",
      field: "esg",
    },
    {
      name: "Tickets",
      path: "/ticket",
      field: "tickets",
    },
    {
      name: "Provisioning",
      path: "/provisioning",
      field: "provisioning",
    },
  ]);
  const [powerSummary, setPowerSummary] = useState([]);

  useEffect(() => {
    //console.log(contextStore.getAuth?.role)
    if (
      contextStore.getAuth?.role?.name === "Super Admin" ||
      contextStore.getAuth?.role?.name === "GLOBAL-ADMIN"
    ) {
      getDCPowerSummary();
    }
  }, []);

  useEffect(() => {
    const role = contextStore.getAuth.role;
    const permittedPages = pages.filter((el) =>
      el.field == "tickets" || el.field == "provisioning"
        ? role[el.field] != 1
        : role[el.field] != 0
    );
    setPages(permittedPages);
  }, [contextStore.getAuth.role]);
  useEffect(() => {
    const page = pages.filter(
      (el) => el.path == contextStore.getAuth.default_page
    );
    setDefaultPage(page ? page[0]?.name : null);
  }, [contextStore.getAuth.default_page]);

  const updateDefaultPage = (page) => {
    setIsLoading(true);
    UserService.updateDefaultPage(contextStore.token(), { default_page: page })
      .then((res) => {
        const selectedPage = pages.filter((el) => el.path == page);
        setDefaultPage(selectedPage ? selectedPage[0]?.name : null);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const handle = (data) => {
    setShowDropdown(false);
    updateDefaultPage(data.path);
  };

  const checkPermission = (ticketType) => {
    return contextStore.getAuth?.role?.role_ticket.some(
      (item) => item.is_allowed === 1 && item.ticket_type_id == ticketType
    );
  };

  const formatNumber = (number, decimal = 0) => {
    return number.toLocaleString("en-US", {
      minimumFractionDigits: decimal,
      maximumFractionDigits: decimal,
    });
  };

  const checkTicketAndProvisioningPermission = (type) => {
    if (type == "ticket") {
      if (
        contextStore.getAuth?.role?.role_ticket &&
        contextStore.getAuth?.role?.role_ticket.length > 1
      ) {
        return contextStore.getAuth?.role?.role_ticket.some(
          (item) => item.is_allowed === 1
        );
      } else {
        return false;
      }
    }
    if (type == "provisioning") {
      if (
        contextStore.getAuth?.role?.role_provisioning &&
        contextStore.getAuth?.role?.role_provisioning.length > 1
      ) {
        return contextStore.getAuth?.role?.role_provisioning.some(
          (item) => item.is_allowed === 1
        );
      } else {
        return false;
      }
    }
  };

  const getDCPowerSummary = async () => {
    setIsLoading(true);
    await DataCenter.getDCPowerSummary(contextStore.token())
      .then((res) => {
        console.log(res.data);
        setPowerSummary(res.data);
        // const selectedPage = pages.filter((el) => el.path == page);
        // setDefaultPage(selectedPage ? selectedPage[0]?.name : null);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  return (
    <>
      <Loading
        loading={isLoading}
        background="rgba(0, 0, 0, 0.40)"
        loaderColor="rgb(248, 153, 3)"
      />
      <Layout>
        <div className="d-flex align-items-center firstRow">
          <h1 className="head">Home</h1>
          <div className="d-flex">
            <div className="label">Default Page</div>
            <div className="tc_drop1-out">
              <div className="tc_drop2">
                <div className="inp-out">
                  <div className="val">
                    {defaultPage != null ? defaultPage : "Select"}
                  </div>
                  <div className="after"></div>
                </div>
                <div
                  className="img-out"
                  onClick={() => setShowDropdown(() => !showDropdown)}
                >
                  {" "}
                  <img alt="" src="\images\arrow.png" className="arrow-img" />
                </div>
              </div>
              {showDropdown && pages && pages.length > 0 && (
                <div className="tc_drop2-opt">
                  {pages.map((data) => {
                    return (
                      <div className="tc_opt" onClick={() => handle(data)}>
                        {data.name}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
        {checkTicketAndProvisioningPermission("ticket") && (
          <div className="secondRow">
            <h1 className="head" style={{ marginBottom: "1.5rem" }}>
              Tickets
            </h1>
            <div
              className="profile-tab menu_tab_btn"
              style={{ marginLeft: "2rem" }}
            >
              <div className="custom-tab-1">
                <ul
                  className="nav nav-tabs"
                  style={{
                    flexWrap: "nowrap",
                    overflowX: "auto",
                    border: "none",
                  }}
                >
                  {contextStore.getAuth?.role?.role_ticket &&
                    contextStore.getAuth?.role?.role_ticket.length > 1 &&
                    contextStore.getAuth?.role?.role_ticket.filter(
                      (item) => item.is_allowed === 1
                    ).length > 1 && (
                      <li className="nav-item">
                        <button
                          className="btn btn-light"
                          onClick={() => {
                            localStorage.setItem("isIncidentModeOn", "false");
                            navigate("/ticket", { state: 0 });
                          }}
                        >
                          {" "}
                          {t("ticket.all_tickets")}
                        </button>
                      </li>
                    )}

                  {contextStore.getAuth?.role?.role_ticket &&
                    (contextStore.getAuth?.role?.role_ticket.length == 0 ||
                      checkPermission(1)) && (
                      <li className="nav-item">
                        <button
                          className="btn btn-light"
                          onClick={() => {
                            localStorage.setItem("isIncidentModeOn", "false");
                            navigate("/ticket", { state: 1 });
                          }}
                        >
                          {" "}
                          {t("ticket.incidents")}
                        </button>
                      </li>
                    )}
                  {contextStore.getAuth?.role?.role_ticket &&
                    (contextStore.getAuth?.role?.role_ticket.length == 0 ||
                      checkPermission(2)) && (
                      <li className="nav-item">
                        <button
                          className="btn btn-light"
                          onClick={() => {
                            localStorage.setItem("isIncidentModeOn", "false");
                            navigate("/ticket", { state: 2 });
                          }}
                        >
                          {t("ticket.faults")}
                        </button>
                      </li>
                    )}
                  {contextStore.getAuth?.role?.role_ticket &&
                    (contextStore.getAuth?.role?.role_ticket.length == 0 ||
                      checkPermission(4)) && (
                      <li className="nav-item">
                        <button
                          className="btn btn-light"
                          onClick={() => {
                            localStorage.setItem("isIncidentModeOn", "false");
                            navigate("/ticket", { state: 4 });
                          }}
                        >
                          {" "}
                          {t("ticket.site_visits")}
                        </button>
                      </li>
                    )}
                  {contextStore.getAuth?.role?.role_ticket &&
                    (contextStore.getAuth?.role?.role_ticket.length == 0 ||
                      checkPermission(3)) && (
                      <li className="nav-item">
                        <button
                          className="btn btn-light"
                          onClick={() => {
                            localStorage.setItem("isIncidentModeOn", "false");
                            navigate("/ticket", { state: 3 });
                          }}
                        >
                          {" "}
                          {t("ticket.shipments")}
                        </button>
                      </li>
                    )}
                  {contextStore.getAuth?.role?.role_ticket &&
                    (contextStore.getAuth?.role?.role_ticket.length == 0 ||
                      checkPermission(5)) && (
                      <li className="nav-item">
                        <button
                          className="btn btn-light"
                          onClick={() => {
                            localStorage.setItem("isIncidentModeOn", "false");
                            navigate("/ticket", { state: 5 });
                          }}
                        >
                          {" "}
                          {t("ticket.remote_hands")}
                        </button>
                      </li>
                    )}
                </ul>
              </div>
            </div>
          </div>
        )}

        {contextStore.getAuth.role.dcs != 0 && (
          <div className="thirdRow">
            <h1 className="head">Data Centers</h1>
            <div className="dt_tabs header_dash" style={{ boxShadow: "none" }}>
              <div className="profile-tab" style={{ marginLeft: "2rem" }}>
                <div className="custom-tab-1 tab_flex">
                  <div className="outer-div">
                    <div className="tabs_srll">
                      <ul className="nav nav-tabs scroll_tab_dg">
                        {contextStore.getPermittedDataCenter &&
                          contextStore.getPermittedDataCenter.length > 0 &&
                          contextStore.getPermittedDataCenter.map(
                            (data, index) => {
                              return (
                                <li
                                  className={
                                    index === 0 ? "nav-item ml_20" : "nav-item"
                                  }
                                  key={index}
                                >
                                  <a
                                    style={{
                                      cursor: "pointer",
                                      whiteSpace: "nowrap",
                                    }}
                                    className="nav-link"
                                    onClick={() => {
                                      navigate("/data-center", { state: data });
                                      setIsLoading(true);
                                    }}
                                  >
                                    {" "}
                                    {data.name}{" "}
                                  </a>
                                </li>
                              );
                            }
                          )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {(contextStore.getAuth?.role?.name === "Super Admin" ||
          contextStore.getAuth?.role?.name === "GLOBAL-ADMIN") && (
          <div class="thirdRow" bis_skin_checked="1">
            <h1 class="head">DC Power Summary</h1>
            <div class="total-common-parent">
              <h4 class="total-common design-power">
                Total Design Power :{" "}
                {formatNumber(powerSummary?.grandTotalInfo?.design / 1000)} MW
              </h4>
              <h4 class="total-common">
                Total Build Power :{" "}
                {formatNumber(powerSummary?.grandTotalInfo?.build / 1000)} MW
              </h4>
            </div>
            <span>
              <i>
                Note: The Total Build Power Data is based on{" "}
                {moment().format("MMMM")} Month
              </i>
            </span>
            <div>
              <table
                class=""
                border="1"
                style={{ width: "clamp(40vw, 100%, 70vw)" }}
              >
                <thead class="power-summary-thead">
                  <tr>
                    <th>Country</th>
                    <th>Data Center</th>
                    <th style={{ textAlign: "right" }}>Design PUE</th>
                    <th style={{ textAlign: "right" }}>Operating PUE</th>
                    <th style={{ textAlign: "right" }}>Design (kW)</th>
                    <th style={{ textAlign: "right" }}>Build (kW)</th>
                    <th style={{ textAlign: "right" }}>Under Construction</th>
                    <th style={{ textAlign: "right" }}>Sold</th>
                    <th style={{ textAlign: "right" }}>Available</th>
                    <th style={{ textAlign: "right" }}>Reserved</th>
                    <th style={{ textAlign: "right" }}>Blocked</th>
                  </tr>
                </thead>
                <tbody class="power-summary-tbody">
                  {powerSummary?.result?.map((data, index) => {
                    return (
                      <>
                        {data?.data_centers?.map((data_center, dcIndex) => (
                          <tr key={`${index}-${dcIndex}`}>
                            {/* Render the country name only in the first row of each country group */}
                            {dcIndex === 0 ? (
                              <td
                                style={{ verticalAlign: "top" }}
                                rowSpan={data.data_centers.length}
                              >
                                {data?.country || "N/A"}
                              </td>
                            ) : null}
                            <td>{data_center?.name || "N/A"}</td>
                            <td class="ra">
                              {formatNumber(data_center?.design_pue || 0, 2)}
                            </td>
                            <td class="ra">
                              {formatNumber(data_center?.operating_pue || 0, 2)}
                            </td>
                            <td class="ra">
                              {formatNumber(data_center?.design || 0)}
                            </td>
                            <td class="ra">
                              {formatNumber(data_center?.build || 0)}
                            </td>
                            <td class="ra">0</td>
                            <td class="ra">
                              {formatNumber(data_center?.sold || 0)}
                            </td>
                            <td class="ra">
                              {formatNumber(data_center?.available || 0)}
                            </td>
                            <td class="ra">
                              {formatNumber(data_center?.reserved || 0)}
                            </td>
                            <td class="ra">
                              {formatNumber(data_center?.blocked || 0)}
                            </td>
                          </tr>
                        ))}
                      </>
                    );
                  })}
                  {/* Add total row */}
                  <tr class="power-summary-total">
                    <td>Total</td>
                    <td></td>
                    <td class="ra">
                      {formatNumber(
                        powerSummary?.grandTotalInfo?.design_pue || 0,
                        2
                      )}
                    </td>
                    <td class="ra">
                      {formatNumber(
                        powerSummary?.grandTotalInfo?.operating_pue || 0,
                        2
                      )}
                    </td>
                    <td class="ra">
                      {formatNumber(powerSummary?.grandTotalInfo?.design || 0)}
                    </td>
                    <td class="ra">
                      {formatNumber(powerSummary?.grandTotalInfo?.build || 0)}
                    </td>
                    <td class="ra">0</td>
                    <td class="ra">
                      {formatNumber(powerSummary?.grandTotalInfo?.sold || 0)}
                    </td>
                    <td class="ra">
                      {formatNumber(
                        powerSummary?.grandTotalInfo?.available || 0
                      )}
                    </td>
                    <td class="ra">
                      {formatNumber(
                        powerSummary?.grandTotalInfo?.reserved || 0
                      )}
                    </td>
                    <td class="ra">
                      {formatNumber(powerSummary?.grandTotalInfo?.blocked || 0)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        )}
      </Layout>
    </>
  );
};
export default Home;
