import moment from "moment";
import { useState, useMemo, useEffect } from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { callMsGraph } from "views/super-admin/Auth/graph";

const DateTimeSel = ({ value, compareDate, id, setUpdateTicketDetails }) => {
  const [pickerMode, setPickerMode] = useState(null);
  const [dateVal, setdateVal] = useState(
    moment(value, "DD-MMM-YYYY HH:mm Z").format()
  );
  useEffect(() => {
    if (dateVal !== moment(value, "DD-MMM-YYYY HH:mm Z").format()) {
      if (id === "start") {
        setUpdateTicketDetails((prev) => ({ ...prev, start_date: dateVal }));
      } else if (id === "end") {
        setUpdateTicketDetails((prev) => ({ ...prev, end_date: dateVal }));
      } else {
        setUpdateTicketDetails((prev) => ({ ...prev, [id]: dateVal }));
      }
    }
  }, [dateVal]);

  const renderView = (mode, renderDefault) => {
    setPickerMode(mode);
    return <div className="wrapper">{renderDefault()}</div>;
  };
  return (
    <>
      <Datetime
        onChange={(value) => {
          if (value.format) {
            // Convert to UTC
            // console.log(value.utc().format());
            const utcMoment = value.utc().format();
            setdateVal(utcMoment);
          }
        }}
        value={value}
        timeFormat="HH:mm"
        dateFormat="DD-MM-YY"
        inputProps={{
          style: {
            color: "black",
            border: "#ff7a00 solid 2px",
            height: "35px",
          },
        }}
        isValidDate={(current) => {
          if (compareDate) {
            return current.isAfter(moment(compareDate).subtract(1, "day"));
          }
          return current;
        }}
        // renderView={(mode, renderDefault) => renderView(mode, renderDefault)}
      />
    </>
  );
};

const DateTime = ({ value, compareDate, id, setUpdateTicketDetails }) => {
  return useMemo(() => {
    return (
      <DateTimeSel
        setUpdateTicketDetails={setUpdateTicketDetails}
        id={id}
        value={value}
        compareDate={compareDate}
      />
    );
  }, [value, compareDate, id, setUpdateTicketDetails]);
};

export default DateTime;
