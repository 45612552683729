import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import StorageContext from "context";
import React, { useEffect, useState } from "react";
import Routes from "routes";
import { logoutSession } from "services/auth";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { getLanguageCode } from "global-definitions";
import Common from "services/commonService";
import { LogoutOnSessionExpire } from "common/helpers";
import profileService from "services/profileService";

function App() {
  useEffect(() => {
    Common.status()
      .then((res) => setStatusData(res.data.data))
      .catch((err) => LogoutOnSessionExpire(err));
  }, []);

  const [apiToken, setApiToken] = useState("");
  const [statusData, setStatusData] = useState([]);
  const [authData, setAuthData] = useState({});
  const [countryName, setCountryName] = useState(null);
  const [dcId, setdcId] = useState("9");
  const [timezone, setTimezone] = useState("");
  const [selectedCountryContext, setSelectedCountryContext] = useState("");
  const [roomContext, setRoomContext] = useState([]);
  const [uFloorsContext, setUFloorContext] = useState([]);
  const [monthYearContext, setMonthYearContext] = useState({
    month: "",
    year: "",
  });
  const [counterContext, setCounterContext] = useState(59);
  const [networkDataCenterContext, setNetworkDataCenterContext] = useState([]);
  const [globalTicketDC, setglobalTicketDC] = useState(true);
  const [autoRefresh, setAutoRefresh] = useState(true);
  const TenantId = process.env.REACT_APP_TENANT_ID;
  const assetPath = process.env.REACT_APP_ASSET_PATH;
  const domain = window.location.origin;
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const { t, i18n } = useTranslation();
  const [permittedDataCenter, setPermittedDataCenter] = useState([]);
  const [permittedCountry, setPermittedCountry] = useState([]);
  const [maintenanceModeStatus, setMaintenanceModeStatus] = useState({
    currentStatus: "Off",
    targetStatus: "Off",
  });
  const [timezoneData, setTimezoneData] = useState([]);
  useEffect(() => {
    if (apiToken != "") {
      profileService
        .getTimezone(apiToken)
        .then((res) => setTimezoneData(res.data.data))
        .catch((err) => LogoutOnSessionExpire(err));
    }
  }, [apiToken]);
  const contextData = React.useMemo(() => {
    return {
      logout: () => {
        const url = `https://login.microsoftonline.com/${TenantId}/oauth2/logout?post_logout_redirect_uri=${domain}`;
        logoutSession(apiToken);
        localStorage.removeItem("token");
        localStorage.clear();
        setApiToken("");
        setAuthData({});
        setSelectedCountryContext("");
        setNetworkDataCenterContext([]);
        setPermittedDataCenter([]);
        setPermittedCountry([]);
        window.location.href = url;
      },
      setMonthYear: (month, year) => {
        setMonthYearContext({ month, year });
      },
      login: (token) => {
        setApiToken(token);
      },
      token: () => {
        return apiToken;
      },
      setAuth: (data) => {
        i18n.changeLanguage(getLanguageCode(data.language_id));
        setAuthData(data);
      },
      setAutoRefresh: (data) => {
        setAutoRefresh(data);
      },
      /*
			|----------------------------------------------------------
			|	Network
			|----------------------------------------------------------
			*/

      setDataCenterFloor: (data) => {
        setUFloorContext(data);
      },
      setCountryName: (data) => {
        setCountryName(data);
      },
      setdcId: (data) => {
        setdcId(data);
      },
      setStatusData: (data) => {
        setStatusData(data);
      },
      setTimezone: (data) => {
        setTimezone(data);
      },
      setSelectedCountry: (data) => {
        setSelectedCountryContext(data);
      },
      setRoom: (data) => {
        setRoomContext(data);
      },
      setCounter: (data) => {
        setCounterContext(data);
      },
      setNetworkDataCenter: (data) => {
        setNetworkDataCenterContext(data);
      },
      setglobalTicketDC: (data) => {
        setglobalTicketDC(data);
      },
      setDataCenter: (data) => {
        setPermittedDataCenter(data);
      },
      setCountry: (data) => {
        setPermittedCountry(data);
      },
      setMaintenanceModeStatus: (data) => {
        setMaintenanceModeStatus(data);
      },
      setTimezoneData: (data) => {
        setTimezoneData(data);
      },
      getAuth: authData,
      getToken: apiToken,
      getDataCenterFloor: uFloorsContext,
      getRoom: roomContext,
      selectedCountry: selectedCountryContext,
      getMonthYear: monthYearContext,
      getCounter: counterContext,
      getNetworkDataCenter: networkDataCenterContext,
      getAssetPath: assetPath,
      globalTicketDC: globalTicketDC,
      getCountryName: countryName,
      getdcId: dcId,
      getStatusData: statusData,
      getTimezone: timezone,
      getAutoRefresh: autoRefresh,
      getPermittedDataCenter: permittedDataCenter,
      getPermittedCountry: permittedCountry,
      getMaintenanceModeStatus: maintenanceModeStatus,
      getTimezoneData: timezoneData,
    };
  }, [
    authData,
    apiToken,
    roomContext,
    selectedCountryContext,
    uFloorsContext,
    monthYearContext,
    counterContext,
    networkDataCenterContext,
    assetPath,
    globalTicketDC,
    countryName,
    dcId,
    statusData,
    timezone,
    autoRefresh,
    permittedDataCenter,
    permittedCountry,
    maintenanceModeStatus,
    timezoneData,
  ]);

  return (
    <StorageContext.Provider value={contextData}>
      <Routes />
    </StorageContext.Provider>
  );
}

export default App;
