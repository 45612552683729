import React, { useEffect, useState } from 'react';

export const Dropdown = ({id, label, value, list, onChange, breakers }) => {
  const filterBreakers = () => {
    try {
      const copy = [...breakers.map(b => `${b}`)];
      copy.splice(id, 1, 0);
      const breakerList = list.slice();
      const filtered = breakerList.filter((item) => {
        return !copy.includes(`${item.id}`);
      });
      return filtered;
    } catch (e) {
      return [];
      console.log(e);
    }
  };

  const breakerForCurrentSvcCabinet = list.filter(l => l.svc_cabinet_id).map(l => l.id)

  const [selection, setSelection] = useState(list);

  useEffect(() => {
    setSelection(filterBreakers());
  }, [JSON.stringify(breakers)]);

  return (
    <div key={id}>
      <label className="form-label">
        {' '}
        {label} <small className="text-danger hide">*</small>
      </label>
      <select
        className="default-select form-control wide"
        onChange={(event) => {
          onChange(id, event.target.value);
        }}
        value={value}
      >
        <option value="">Choose ...</option>
        {list &&
          selection.map((item) => {
            const isUsed = breakerForCurrentSvcCabinet.includes(item.id)
            return (
              <option disabled={isUsed} value={item.id} key={item.id}>
                {item.name}
              </option>
            );
          })}
      </select>
      {/* <XError message={error.num_breakers} /> */}
    </div>
  );
};
