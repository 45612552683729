import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import ReactPaginate from "react-paginate";

// Example items, to simulate fetching from another resources.

const Pagination = (props) => {
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const pageCount = Math.ceil(props.total / itemsPerPage);
  const handlePageClick = (event) => {
    // console.log(`User requested page number ${event.selected + 1}`);
    props.setPageNumber(event.selected + 1);
    props.setItemsPerPage(itemsPerPage);
  };

  return (
    <>
      <ReactPaginate
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        previousLabel="< previous"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="pagination"
        activeClassName="active"
        renderOnZeroPageCount={null}
        forcePage={props.pageNumber - 1}
      />
    </>
  );
};
export default Pagination;
