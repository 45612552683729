import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { loginAzure, verifyEmail } from "services/auth";
import Swal from "sweetalert2";
import { loginRequest } from "../authConfig";
import { LogoutOnSessionExpire } from "common/helpers";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { auth } from "services/auth";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import IconButton from "@mui/material/IconButton";
import LoginIcon from "@mui/icons-material/Login";
/**
 * Renders a drop down button with child buttons for logging in with a popup or redirect
 */
export const LoginWithAzure = ({ contextData, navigate, HOME }) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();

  // const [message,setMessage] = useState({
  //     message:"",
  //     type:""
  // });

  // useEffect(() => {
  //   if (token && location.pathname.length > 150) {
  //     verifyEmail({ vToken: token })
  //       .then((res) => {
  //         // setMessage({
  //         //     message:res.data.message,
  //         //     type:"success"
  //         // })
  //       })
  //       .catch((err) => {
  //         LogoutOnSessionExpire(err);
  //         // setMessage({
  //         //     message:err.response.data.errors,
  //         //     type:"danger"
  //         // })
  //       });
  //   }
  // }, []);

  const { instance } = useMsal();

  // const isAuthenticated = useIsAuthenticated();

  let { token } = useParams();

  const handleLogout = () => {
    instance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });
  };

  const handleLogin = async () => {
    try {
      // Trigger loginPopup and get the response
      const loginResponse = await instance.loginPopup(loginRequest);
      console.log("Login Response:", loginResponse);
  
      // Set the active account after login
      instance.setActiveAccount(loginResponse.account);
  
      // Save the access token locally
      localStorage.setItem("accessToken", loginResponse.accessToken);
  
      // Acquire token silently for further API calls
      const tokenResponse = await instance.acquireTokenSilent({
        ...loginRequest,
        account: loginResponse.account, // Use the logged-in account
      });
      console.log("Token Response:", tokenResponse);
  
      // Save the access token for later use
      localStorage.setItem("accessToken", tokenResponse.accessToken);
  
      // Call the backend login API with Azure data
      const azureLoginResponse = await loginAzure({ data: loginResponse });
      console.log("Azure Login Response:", azureLoginResponse);
  
      // Save additional tokens or information from the backend
      const { access_token, data } = azureLoginResponse.data || {};
      localStorage.setItem("token", access_token);
  
      if (data && data.length) {
        localStorage.setItem("istimeout", data[0]?.isTimeout);
      }
  
      // Authenticate and set context data
      const authResponse = await auth(access_token);
      console.log("Auth Response:", authResponse);
  
      contextData.setAuth(authResponse.data.user);
      contextData.setMonthYear(authResponse.data.currentMonth, authResponse.data.currentYear);
      contextData.setDataCenter(authResponse.data.dataCenter);
      contextData.setCountry(authResponse.data.country);
  
      // Perform login and navigation
      contextData.login(access_token);
  
      const defaultPage = data?.[0]?.default_page || HOME;
      navigate(defaultPage);
    } catch (err) {
      console.error("Login Error:", err);
  
      // Logout on session expiry or handle error
      LogoutOnSessionExpire(err);
      Swal.fire({
        icon: "error",
        text: err.response?.data || "An error occurred during login",
      });
    }
  };
  

  // const ProfileContent = () => {

  //     const { instance, accounts } = useMsal();
  //     const [graphData, setGraphData] = useState(null);

  //     function RequestProfileData() {
  //         // Silently acquires an access token which is then attached to a request for MS Graph data
  //         instance.acquireTokenSilent({
  //             ...loginRequest,
  //             account: accounts[0]
  //         }).then((response) => {
  //             callMsGraph(response.accessToken).then(response => setGraphData(response));
  //         });
  //     }

  //     return (
  //         <>
  //             <h5 className="card-title">Welcome {accounts[0].name}</h5>
  //             {graphData ?
  //                 console.log(graphData)
  //                 :
  //                 <button variant="secondary" onClick={RequestProfileData}>Request Profile Information</button>

  //             }
  //             <button variant="secondary" onClick={handleLogout}>Logout</button>
  //         </>
  //     );
  // };
  return (
    <div className="App" style={{ marginTop: "3rem" }}>
      <div className="text-center" style={{ marginBottom: 10 }}>
        <button
          type="submit"
          style={{
            lineHeight: 2,
            background: "#010028",
            border: "1px solid #010028",
          }}
          onClick={() => handleLogin("popup")}
          className="btn btn-primary btn-block"
        >
          <svg
            style={{ marginRight: 10 }}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-microsoft"
            viewBox="0 0 16 16"
          >
            <path d="M7.462 0H0v7.19h7.462V0zM16 0H8.538v7.19H16V0zM7.462 8.211H0V16h7.462V8.211zm8.538 0H8.538V16H16V8.211z" />
          </svg>{" "}
          <IconButton aria-label="Example" style={{ color: "#ffffff" }}>
            <LoginIcon />
          </IconButton>
          {t("auth.sign_in_with_azure_active_directory")}
        </button>

        {contextData.getMaintenanceModeStatus &&
          contextData.getMaintenanceModeStatus?.isMaintenanceModeOn && (
            <Stack
              sx={{
                width: "100%",
                fontSize: "1rem",
                padding: "6px !important",
                marginTop: "3rem",
              }}
            >
              <Alert
                variant="outlined"
                severity="warning"
                sx={{
                  fontSize: "1rem",
                  textAlign: "left",
                  border: "2px solid #fe8600",
                  fontWeight: "500",
                }}
              >
                Maintenance mode has been turned{" "}
                {contextData.getMaintenanceModeStatus?.status_current == 2
                  ? "On"
                  : "Off"}
                .<br></br>
                <b> Data Sync is in progress</b>.<br></br>
                Please wait for a moment for the process to be completed before
                accessing the Ops Portal.
              </Alert>
            </Stack>
          )}

        {contextData.getMaintenanceModeStatus &&
          contextData.getMaintenanceModeStatus?.status_current === 3 && (
            <Stack
              sx={{
                width: "100%",
                fontSize: "1rem",
                padding: "6px !important",
                marginTop: "3rem",
              }}
            >
              <Alert
                variant="outlined"
                severity="warning"
                sx={{
                  fontSize: "1rem",
                  textAlign: "left",
                  border: "2px solid #fe8600",
                  fontWeight: "500",
                }}
              >
                The Ops portal is currently in maintenance mode, and some
                services may be deprecated.
              </Alert>
            </Stack>
          )}
      </div>
    </div>
  );
};

export default LoginWithAzure;
