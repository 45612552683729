import AuthContext from "context";
import React, { useContext, useEffect, useRef, useState } from "react";
import Table from "./components/Ticket Table/table";
import Loading from "../pre-loader/loader";
import TicketService from "services/ticketService";
import Layout from "../dashboard-layout";
import Card from "./components/card";
import { cardData } from "./components/data";
import "./style.css";
import "./faultpopup.css";
import { LogoutOnSessionExpire } from "common/helpers";
import moment from "moment";
import FaultPopup from "./components/Ticket Detail Popup/fault";
import SiteFaultPopup from "./components/Ticket Detail Popup/site-access-popup";
import TicketDetails from "services/ticketDetails";
import ErrorModel from "./components/errormodel";
import { useTranslation } from "react-i18next";
import ShipmentOutboundPopup from "./components/Ticket Detail Popup/shipment-outbound";
import ShipmentInboundPopup from "./components/Ticket Detail Popup/shipment-inbound";
import IncidentManagementPopup from "./components/Ticket Detail Popup/incident-management";
import RemoteHandPopup from "./components/Ticket Detail Popup/remote-hands";
import { ticketTypeId } from "../Tickets/ticketConfig";
import Swal from "sweetalert2";
import SearchBox from "./components/searchBoxFilter";
import GroupByBox from "./components/groupByBoxFilter";
import GroupByTable from "./components/Ticket Table/groupByTable";
import SelectBoxFilter from "./components/selectBoxFilter";
import FavouritesBox from "./components/favouritesBoxFilter";
import IncidentOverviewCard from "./components/incidentOverviewCard";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useLocation } from "react-router-dom";
const XLSX = require("xlsx");

const Ticket = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const contextStore = useContext(AuthContext);
  const [state, setState] = useState([]);
  const [groupByTicketState, setGroupByTicketState] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [menu, setMenu] = useState(0);
  const [selectedDataCenter, setSelectedDataCenter] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [liveReload, setLiveReload] = useState(true);
  const [liveReloadPreference, setLiveReloadPreference] = useState(true);
  const [lastrefresh, setLastrefresh] = useState("");
  const [timezone, setTimezone] = useState("");
  const [timezoneid, setTimeZoneId] = useState("");
  const [faultpopup, setFaultPopup] = useState(false);
  const [siteAccess, setSiteAccess] = useState(false);
  const [shipmentout, setShipmentOut] = useState(false);
  const [shipmentin, setShipmentIn] = useState(false);
  const [incident, setIncident] = useState(false);
  const [errorpopup, setErrorPopup] = useState(false);
  const [ticketdata, setTicketData] = useState([]);
  const [sitename, setSiteName] = useState("");
  const [minutes, setMinutesData] = useState([]);
  const [errorMsg, setErrorMsg] = useState("Something went Wrong !");
  const [remotehand, setRemoteHand] = useState(false);
  const [helpdesk, setHelpdesk] = useState("");
  const [ticketSubTypeOpt, setTicketSubTypeOpt] = useState([]);
  const [ticketStatusTypeOpt, setTicketStatusTypeOpt] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [drop1Subtype, Setdrop1Subtype] = useState("");
  const [drop2Val, setdrop2Val] = useState("Open");
  const [playing, setPlaying] = useState(false);
  const [timeDetail, setTimeDetails] = useState({});
  const [runfetch, setRunfetch] = useState(false);
  const [totalTicketsCount, setTotalTicketsCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [ticketTypeName, setTicketTypeName] = useState("");
  const [alarm, setAlarm] = useState(true);
  const [userTimzone, setUserTimezone] = useState("");
  const [timzonePrefix, setTimezonePrefix] = useState("");
  const [isGroupBy, setIsGroupBy] = useState(false);
  const [groupBy, setGroupBy] = useState({});
  const [groups, setGroups] = useState([]);
  const [isSelectedGroup, setIsSelectedGroup] = useState("");
  const [isCollapse, setIsCollapse] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState("Open");
  const [dataCenterList, setDataCenterList] = useState([]);
  const [
    countryCodeForSelectedTicket,
    setCountryCodeForSelectedTicket,
  ] = useState("");
  const [countries, setCountries] = useState();
  const [currentTime, setCurrentTime] = useState({
    time: "",
    status: 0,
  });

  useEffect(() => {
    if (location.state) {
      setMenu(location.state);
    }
  }, [location.state]);

  const [sidePopValDb, setSidePopValDb] = useState(null);
  const [sort, setSort] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");
  const [SidePopVal, setSidePopVal] = useState({
    id: true,
    subject: true,
    designated_company: false,
    tickettype: true,
    operatingsite: true,
    priority: true,
    create_date: true,
    close_date: false,
    visit_start_date: false,
    visit_end_date: false,
    ticket_status: true,
    readiness: true,
    assignee: true,
    company: true,
    visitors: false,
    description: false,
    spaces_display: false,
    visiting_rooms_display: false,
    portal_code: false,
  });
  const [filteredColumns, setFilteredColumns] = useState([]);
  const [filteredSearchedCategory, setFilteredSearchedCategory] = useState([]);
  const [incidentMode, setIncidentMode] = useState(false);
  const [incidentOverviewData, setIncidentOverviewData] = useState([]);
  const [incidentModePermission, setIncidentModePermission] = useState(false);
  const [incidentOverviewSite, setIncidentOverviewSite] = useState("");
  const [incidentOverviewPriority, setIncidentOverviewPriority] = useState(
    "P0,P1,E1,I1,P2,P3"
  );
  const columns = [
    {
      name: "id",
      value: t(`ticket.ticket#`),
      width: 10,
    },
    {
      name: "subject",
      value: t(`ticket.subject`),
      width: 60,
    },
    {
      name: "designated_company",
      value: t(`ticket.company`),
      width: 60,
    },
    {
      name: "tickettype",
      value: t(`ticket.ticket_type`),
      width: 25,
    },
    {
      name: "operatingsite",
      value: t(`ticket.site`),
      width: 10,
    },
    {
      name: "priority",
      value: t(`ticket.severity`),
      width: 10,
    },
    {
      name: "create_date",
      value: t(`ticket.creation_time`),
      width: 25,
    },
    {
      name: "close_date",
      value: t(`ticket.close_date`),
      width: 25,
    },
    {
      name: "visit_start_date",
      value: t(`ticket.site_visit_date_start`),
      width: 25,
    },
    {
      name: "visit_end_date",
      value: t(`ticket.site_visit_date_end`),
      width: 25,
    },
    {
      name: "ticket_status",
      value: t(`ticket.status`),
      width: 25,
    },
    {
      name: "readiness",
      value: t(`ticket.readiness`),
      width: 25,
    },
    {
      name: "assignee",
      value: t(`ticket.assignee`),
      width: 25,
    },
    {
      name: "company",
      value: t(`ticket.customer`),
      width: 25,
    },
    {
      name: "visitors",
      value: t(`ticket.visitors`),
      width: 60,
    },
    {
      name: "description",
      value: t(`ticket.description`),
      width: 60,
    },
    {
      name: "spaces_display",
      value: t(`ticket.space_id`),
      width: 25,
    },
    {
      name: "visiting_rooms_display",
      value: t(`ticket.room_id`),
      width: 25,
    },
    {
      name: "portal_code",
      value: t(`ticket.portal_code`),
      width: 25,
    },
  ];
  const searchCategory = [
    { id: 1, name: t(`ticket.id`), field: "id" },
    { id: 2, name: t(`ticket.subject`), field: "subject" },
    { id: 3, name: t(`ticket.company`), field: "company" },
    { id: 4, name: t(`ticket.ticket_type`), field: "tickettype_name" },
    { id: 5, name: t(`ticket.site`), field: "site_name" },
    { id: 6, name: t(`ticket.severity`), field: "severity" },
    { id: 7, name: t(`ticket.creation_time`), field: "create_date" },
    { id: 8, name: t(`ticket.status`), field: "ticket_status" },
    { id: 9, name: t(`ticket.customer`), field: "customer" },
    { id: 10, name: t(`ticket.readiness`), field: "readiness" },
    { id: 11, name: t(`ticket.assignee`), field: "assignee" },
    { id: 12, name: t(`ticket.visit_start_date`), field: "visit_start_date" },
    { id: 13, name: t(`ticket.visit_end_date`), field: "visit_end_date" },
    { id: 14, name: t(`ticket.visitors`), field: "visitors" },
    { id: 15, name: t(`ticket.space_id`), field: "spaces" },
    { id: 16, name: t(`ticket.room_id`), field: "visiting_rooms" },
    { id: 17, name: t(`ticket.close_date`), field: "close_date" },
    { id: 18, name: t(`ticket.portal_code`), field: "portal_code" },
    { id: 19, name: t(`ticket.description`), field: "description" },
  ];
  const groupCategory = [
    { id: 1, name: t(`ticket.assignee`), field: "assignee" },
    { id: 2, name: t(`ticket.ticket_type`), field: "tickettype" },
    { id: 3, name: t(`ticket.status`), field: "ticket_status" },
    { id: 4, name: t(`ticket.company`), field: "designated_company" },
  ];
  const statusCategory = [
    { id: 1, name: t(`ticket.open`), field: "status" },
    { id: 2, name: t(`ticket.closed`), field: "status" },
    { id: 3, name: t(`ticket.all`), field: "status" },
  ];
  const textOperators = [
    {
      name: "contains",
      value: "contains",
    },
    {
      name: `doesn't contain`,
      value: "not_contains",
    },
    {
      name: "is equal to",
      value: "equal",
    },
    {
      name: "is not equal to",
      value: "not_equal",
    },
    {
      name: "is set",
      value: "is_set",
    },
    {
      name: "is not set",
      value: "is_not_set",
    },
  ];
  const dateOperators = [
    {
      name: "contains",
      value: "contains",
    },
    {
      name: `doesn't contain`,
      value: "not_contains",
    },
    {
      name: "is after",
      value: "is_after",
    },
    {
      name: "is before",
      value: "is_before",
    },
    {
      name: "is after or equal to",
      value: "greater_than_equal",
    },
    {
      name: "is before or equal to",
      value: "lesser_than_equal",
    },
    {
      name: "is set",
      value: "is_set",
    },
    {
      name: "is not set",
      value: "is_not_set",
    },
  ];
  const numberOperators = [
    {
      name: "contains",
      value: "contains",
    },
    {
      name: `doesn't contain`,
      value: "not_contains",
    },
    {
      name: "is equal to",
      value: "equal",
    },
    {
      name: "is not equal to",
      value: "not_equal",
    },
    {
      name: "is set",
      value: "is_set",
    },
    {
      name: "is not set",
      value: "is_not_set",
    },
  ];

  const [filter, setFilter] = useState([]);
  const [count, setcount] = useState(60);
  const [favourites, setFavourites] = useState([]);
  const [permissions, setPermissions] = useState([]);

  const check = useRef(false);
  let myInterval;
  let countdown;
  let second = contextStore.getCounter;
  useEffect(() => {
    if (liveReload) {
      countdown = setInterval(() => {
        if (count > 0) {
          setcount((prevTimer) => prevTimer - 1);
        } else {
          clearInterval(countdown);
          setRunfetch(true);
        }
      }, 1000);
    }

    return () => clearInterval(countdown);
  }, [count, liveReload]);

  useEffect(() => {
    if (liveReloadPreference) {
      setLiveReload(contextStore.getAutoRefresh);
    }
  }, [contextStore.getAutoRefresh]);

  useEffect(() => {
    if (isGroupBy) {
      setIncidentMode(false);
    }
  }, [isGroupBy]);

  useEffect(() => {
    const incidentPermission = permissions.find(
      (el) => el.ticket_type_id == 1 && el.is_allowed == 1
    );
    if (
      incidentPermission &&
      incidentPermission.roleTicketPermission &&
      incidentPermission.roleTicketPermission.length > 0
    ) {
      setIncidentModePermission(
        incidentPermission.roleTicketPermission.find(
          (el) => el.name == "Incident Mode" && el.is_allowed == 1
        )
      );

      if (
        !incidentPermission.roleTicketPermission.find(
          (el) => el.name == "Incident Mode" && el.is_allowed == 1
        )
      ) {
        localStorage.setItem("isIncidentModeOn", "false");
      }
    }
  }, [permissions]);

  const transformedData = (array) => {
    const data = array.map((item) => {
      const { roleTicketPermissionName, ...rest } = item;
      return { ...rest, name: roleTicketPermissionName.name };
    });
    return data;
  };

  // useEffect(() => {
  //   console.log(permissions);
  // }, [permissions]);
  useEffect(() => {
    if (selectedDataCenter != "") {
      if (incidentMode) {
        setIncidentOverviewData([]);
        getIncidentOverviewData();
        setMenu(1);
        setIncidentOverviewPriority("P0,P1,E1,I1,P2,P3");
        setIncidentOverviewSite("");
        if (menu == 1) {
          fetchTicket();
        }
      } else {
        if (pageNumber != 1) {
          setPageNumber(1);
        }
        if (liveReload === false) {
          clearInterval(myInterval);
          contextStore.setCounter(59);
        }
        setIsSelectedGroup("");
        setGroups([]);
        setState([]);
        clearInterval(countdown);
        clearInterval(myInterval);
        setState([]);
        fetchTicket();
        fetchTicketsChartsData();
      }
    }
  }, [incidentMode, selectedDataCenter, selectedStatus]);

  useEffect(() => {
    if (selectedDataCenter != "") {
      fetchTicket();
    }
  }, [
    filter,
    pageNumber,
    groupBy,
    isGroupBy,
    isSelectedGroup,
    selectedStatus,
    sort,
    sortDirection,
    incidentOverviewSite,
    incidentOverviewPriority,
    menu,
  ]);

  useEffect(() => {
    if (menu == 0 || menu == 4) {
      setFilteredColumns(columns);
      setFilteredSearchedCategory(searchCategory);
    }
    if (menu === 1) {
      const filteredColumns = columns.filter(
        (el) =>
          el.value !== "Site Visit Date Start" &&
          el.value !== "Site Visit Date End" &&
          el.value !== "Visitors" &&
          el.value !== "Company" &&
          el.value !== "Portal Code" &&
          el.value !== "Space ID" &&
          el.value !== "Room ID"
      );
      setFilteredColumns(filteredColumns);
      const filteredSearchedCategory = searchCategory.filter(
        (el) =>
          el.name !== "Site Visit Date Start" &&
          el.name !== "Site Visit Date End" &&
          el.name !== "Visitors" &&
          el.name !== "Company" &&
          el.name !== "Portal Code" &&
          el.name !== "Space ID" &&
          el.name !== "Room ID"
      );
      setFilteredSearchedCategory(filteredSearchedCategory);
    }
    if (menu === 2 || menu === 3 || menu === 5) {
      const filteredColumns = columns.filter(
        (el) =>
          el.value !== "Site Visit Date Start" &&
          el.value !== "Site Visit Date End" &&
          el.value !== "Visitors" &&
          el.value !== "Room ID"
      );
      setFilteredColumns(filteredColumns);
      const filteredSearchedCategory = searchCategory.filter(
        (el) =>
          el.name !== "Site Visit Date Start" &&
          el.name !== "Site Visit Date End" &&
          el.name !== "Visitors" &&
          el.name !== "Room ID"
      );
      setFilteredSearchedCategory(filteredSearchedCategory);
    }
  }, [menu]);

  useEffect(() => {
    if (
      contextStore.getAuth?.role?.role_ticket &&
      contextStore.getAuth.role.role_ticket.length == 1
    ) {
      if (
        contextStore.getAuth.role.role_ticket[0].ticketTypes.name == "Incidents"
      ) {
        setMenu(1);
      }
      if (
        contextStore.getAuth.role.role_ticket[0].ticketTypes.name == "Faults"
      ) {
        setMenu(2);
      }
      if (
        contextStore.getAuth.role.role_ticket[0].ticketTypes.name == "Shipment"
      ) {
        setMenu(3);
      }
      if (
        contextStore.getAuth.role.role_ticket[0].ticketTypes.name ==
        "Site Visits"
      ) {
        setMenu(4);
      }
      if (
        contextStore.getAuth.role.role_ticket[0].ticketTypes.name ==
        "Remote Hands"
      ) {
        setMenu(5);
      }
    }
  }, []);

  const [newTicket, setNewTicket] = useState([]);
  const oldState = useRef([]);
  useEffect(() => {
    if (filter.length == 0 && sort == "id" && sortDirection == "desc") {
      if (
        oldState.current.length > 0 &&
        pageNumber === 1 &&
        searchVal === "" &&
        selectedStatus === "Open" &&
        !incidentMode
      ) {
        const property = "id";
        const oldSet = new Set(oldState.current.map((item) => item[property]));
        const newSet = new Set(state.map((item) => item[property]));
        const added = state.filter((item) => !oldSet.has(item[property]));
        const oldSetArray = Array.from(oldSet);
        const firstValueInOldSet = oldSetArray[0];
        const isAddedValueGreater = added.some(
          (addedItem) => addedItem[property] > firstValueInOldSet
        );
        if (isAddedValueGreater) {
          if (added.length > 0 && alarm) {
            playAlarm();
          }
        }
        setNewTicket(added);
      }
      if (pageNumber === 1 && searchVal === "" && selectedStatus === "Open") {
        oldState.current = [...state];
      }
    }
    // oldState.current = state;
  }, [state]);

  // Arrow function to set value in local storage
  const setAlarmOn = (isChecked) => {
    setAlarm(isChecked);
    localStorage.setItem("alarmSetting", JSON.stringify(isChecked));
  };

  const calculateTableTopPosition = () => {
    const card = document.getElementById("card");
    const height = 154 + card?.clientHeight;
    if (window.innerWidth > 1605) {
      return height + 22 + "px";
    } else {
      return height + "px";
    }
  };

  useEffect(() => {
    // Retrieve value from local storage on component mount
    const storedAlarmSetting = localStorage.getItem("alarmSetting");
    if (storedAlarmSetting !== null) {
      setAlarm(JSON.parse(storedAlarmSetting));
    }
  }, []);

  useEffect(() => {
    // Retrieve value from local storage on component mount
    const storedIncicentModeSetting = localStorage.getItem("isIncidentModeOn");
    if (storedIncicentModeSetting !== null) {
      setIncidentMode(JSON.parse(storedIncicentModeSetting));
    }
  }, []);
  useEffect(() => {
    fetchFavourites();
    fetchPermissions();
  }, []);

  useEffect(() => {
    if (runfetch) {
      fetchTicket(true);
      if (!incidentMode) {
        fetchTicketsChartsData(drop2Val);
      }
      setRunfetch(false);
    }
  }, [runfetch]);

  const numberInTwoDigit = (num) => {
    if (num < 10) {
      num = `0${num}`;
    }
    return num;
  };

  const readinessChange = async (readiness, ticketId) => {
    setIsLoading(true);
    await TicketService.updateReadiness(
      localStorage.getItem("token"),
      ticketId,
      readiness
    )
      .then((res) => {
        if (res?.data?.data?.success === undefined) {
          setErrorMsg(res.data);
          setErrorPopup(true);
          setIsLoading(false);
        } else {
          fetchTicket();
        }
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };
  const getIncidentOverviewData = async () => {
    const dc = selectedDataCenter.id;
    const cid = contextStore.selectedCountry.id
      ? contextStore.selectedCountry.id
      : contextStore.getAuth.role.country_id == 6
      ? ""
      : contextStore.getAuth.role.country_id;

    setIsLoading(true);
    let body = {
      offset: 0,
      page: pageNumber ? pageNumber : 1,
      sort: sort,
      sortDir: sortDirection,
      filters: [
        {
          name: "country",
          value: cid,
          operator: "contains",
        },
        {
          name: "tickettype",
          value: 1,
          operator: "contains",
        },
        {
          name: "status",
          value: selectedStatus,
          operator: "contains",
        },
      ],
    };
    if (filter && filter.length > 0) {
      body.timezone = userTimzone ? userTimzone : "Asia/Hong_Kong";
    }
    if (isGroupBy && groupBy.field == "tickettype" && isSelectedGroup !== "") {
      body.isGroup = true;
    }

    if (cid != "") {
      body.filters.push({
        name: "site",
        value: dc ? dc : "",
        operator: "contains",
      });
    } else if (dc != "") {
      body.filters.push({
        name: "site",
        value: dc,
        operator: "contains",
      });
    }

    if (filter && filter.length > 0) {
      for (let i = 0; i < filter.length; i++) {
        if (filter[i].name == "site") {
          const siteFilter = body.filters.filter((el) => el.name == "site")[0];
          siteFilter["operator"] = filter[i].operator;
          siteFilter["value"] = filter[i].value;
        } else if (filter[i].name == "tickettype") {
          const siteFilter = body.filters.filter(
            (el) => el.name == "tickettype"
          )[0];
          siteFilter["operator"] = filter[i].operator;
          siteFilter["value"] = filter[i].value;
        } else {
          body.filters.push({
            name: filter[i].name,
            value: filter[i].value,
            operator: filter[i].operator,
          });
        }
      }
    }
    if (isGroupBy && groupBy && isSelectedGroup == "") {
      setGroups([]);
      body.groupBy = groupBy.field;
    }
    if (isGroupBy && groupBy && isSelectedGroup !== "") {
      setGroupByTicketState([]);
      const filter = body.filters.filter((el) => el.name == groupBy.field)[0];
      filter["value"] = isSelectedGroup;
    }
    body.filters = body.filters.filter((el) => el.value != undefined);
    await TicketService.getIncidentTicketsOverview(
      localStorage.getItem("token"),
      body
    )
      .then((res) => {
        setIncidentOverviewData(res.data.data);
      })
      .catch((err) => {
        LogoutOnSessionExpire(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const fetchTicket = async (isRefresh) => {
    const dc = selectedDataCenter.id;
    const cid = selectedCountry ? selectedCountry.id : "";
    setIsLoading(true);
    let body = {
      offset: 0,
      page: pageNumber ? pageNumber : 1,
      sort: sort,
      sortDir: sortDirection,
      filters: [
        {
          name: "country",
          value: cid,
          operator: "contains",
        },
        {
          name: "tickettype",
          value: menu,
          operator: "contains",
        },
        {
          name: "status",
          value: selectedStatus,
          operator: "contains",
        },
      ],
    };
    if (filter && filter.length > 0) {
      body.timezone = userTimzone ? userTimzone : "Asia/Hong_Kong";
    }
    if (isGroupBy && groupBy.field == "tickettype" && isSelectedGroup !== "") {
      body.isGroup = true;
    }

    if (cid != "") {
      body.filters.push({
        name: "site",
        value: dc ? dc : "",
        operator: "contains",
      });
    } else if (dc != "") {
      body.filters.push({
        name: "site",
        value: dc,
        operator: "contains",
      });
    }

    if (filter && filter.length > 0) {
      for (let i = 0; i < filter.length; i++) {
        if (filter[i].name == "site") {
          const siteFilter = body.filters.filter((el) => el.name == "site")[0];
          siteFilter["operator"] = filter[i].operator;
          siteFilter["value"] = filter[i].value;
        } else if (filter[i].name == "tickettype") {
          const siteFilter = body.filters.filter(
            (el) => el.name == "tickettype"
          )[0];
          siteFilter["operator"] = filter[i].operator;
          siteFilter["value"] = filter[i].value;
        } else {
          body.filters.push({
            name: filter[i].name,
            value: filter[i].value,
            operator: filter[i].operator,
          });
        }
      }
    }
    if (isGroupBy && groupBy && isSelectedGroup == "") {
      setGroups([]);
      body.groupBy = groupBy.field;
    }
    // console.log(isSelectedGroup);
    // console.log(groupBy);
    // console.log(body);
    if (isGroupBy && groupBy && isSelectedGroup !== "") {
      setGroupByTicketState([]);
      const filter = body.filters.filter(
        (el) => el.name.toLocaleLowerCase() == groupBy.field
      )[0];
      // console.log(body.filters);
      // console.log(filter);
      if (filter) {
        filter["value"] = isSelectedGroup;
      } else {
        body.filters.push({
          name: groupBy.field,
          value: isSelectedGroup,
          operator: "contains",
        });
      }
    }
    body.filters = body.filters.filter((el) => el.value != undefined);

    if (incidentMode && incidentOverviewSite != "") {
      body.filters.push({
        name: "site_name",
        value: incidentOverviewSite,
        operator: "contains",
      });
    }
    if (incidentMode && incidentOverviewPriority != "") {
      body.filters.push({
        name: "incident_severity",
        value: incidentOverviewPriority,
        operator: "contains",
      });
    }
    await TicketService.getTicketsList(localStorage.getItem("token"), body)
      .then((res) => {
        if (!!groupBy?.filterName && isGroupBy && isSelectedGroup == "") {
          let ticketData = res.data.ticket;
          setGroups(() => (ticketData !== "" ? ticketData : []));
          setGroupByTicketState([]);
        } else {
          if (isGroupBy) {
            // console.log(res.data);
            let ticketData = res.data.ticket.rows;
            setGroupByTicketState(() => (ticketData !== "" ? ticketData : []));
          } else {
            // console.log(res.data);
            let ticketData = res.data.ticket.rows;
            if (!isRefresh) {
              oldState.current = ticketData;
            }
            setState(() => (ticketData !== "" ? ticketData : []));
          }
        }
        // setDataCenterList(res.data.dataCenterslist);
        setTotalTicketsCount(res.data.ticket.total);
        setTicketSubTypeOpt(res.data.ticketsubtype);
        setTicketStatusTypeOpt(res.data.ticketStatusType);
        setLastrefresh(res.data.last_refresh);
        setMinutesData(res.data.minutes);
        setSidePopValDb(res.data.ticketCols);
        setTimezone(res?.data?.user?.timezone?.timezone_prefix);
        localStorage.setItem("token", res?.data?.user?.access_token);
        contextStore.login(res?.data?.user?.access_token);
        setTimeZoneId(res?.data?.user?.timezone_id);
        setUserTimezone(
          res?.data?.user?.timezone?.timezone
            ? res?.data?.user?.timezone?.timezone
            : ""
        );
        setTimezonePrefix(
          res?.data?.user?.timezone?.timezone_prefix
            ? res?.data?.user?.timezone?.timezone_prefix
            : ""
        );

        if (res?.data?.user?.timezone && res?.data?.user?.timezone?.timezone) {
          setCurrentTime({
            ...currentTime,
            time:
              moment(new Date())
                .tz(res?.data?.user?.timezone?.timezone)
                .format("YYYY-MM-DD HH:mm:ss") +
              " " +
              res?.data?.user?.timezone?.timezone_prefix,
            status: 1,
          });
        } else {
          setCurrentTime({
            ...currentTime,
            time:
              moment(new Date())
                .tz("Asia/Hong_Kong")
                .format("YYYY-MM-DD HH:mm:ss") + " HKT",
            status: 1,
          });
        }
        // setCurrentTime({...currentTime, t})
      })
      .catch((err) => {
        LogoutOnSessionExpire(err);
        //500 error page
      })
      .finally(() => {
        setIsLoading(false);
        clearInterval(countdown);
        setcount(59);
      });
  };
  const fetchFavourites = async () => {
    await TicketService.getFavouritesList(localStorage.getItem("token"))
      .then((res) => {
        let search_data = [];
        res.data.data.forEach((element) => {
          search_data.push({
            id: element.id,
            name: element.name,
            filter: JSON.parse(element.search_data),
          });
        });
        setFavourites(search_data);
      })
      .catch((err) => {
        LogoutOnSessionExpire(err);
        //500 error page
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const fetchPermissions = async () => {
    await TicketService.getTicketPermissions(
      localStorage.getItem("token"),
      contextStore?.getAuth?.role_id
    )
      .then((res) => {
        const data = res.data.ticketPermission.map((el) => {
          el.roleTicketPermission = transformedData(el.roleTicketPermission);
          return el;
        });
        setPermissions(data);
      })
      .catch((err) => {
        LogoutOnSessionExpire(err);
        //500 error page
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const addFavourites = async (name, filter) => {
    await TicketService.addFavourite(localStorage.getItem("token"), {
      name: name,
      type: "",
      search_data: filter,
    })
      .then((res) => {
        fetchFavourites();
      })
      .catch((err) => {
        LogoutOnSessionExpire(err);
        //500 error page
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const deleteFavourite = async (id) => {
    await TicketService.deleteFavourite(localStorage.getItem("token"), id)
      .then((res) => {
        fetchFavourites();
      })
      .catch((err) => {
        LogoutOnSessionExpire(err);
        //500 error page
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const exportAllTickets = async (selectedColumns) => {
    setLiveReload(false);
    let startExport = true;
    const dc = selectedDataCenter.id;
    const cid = contextStore.selectedCountry.id
      ? contextStore.selectedCountry.id
      : contextStore.getAuth.role.country_id == 6
      ? ""
      : contextStore.getAuth.role.country_id;

    const batchSize = 250;

    if (totalTicketsCount > batchSize) {
      startExport = false;
      const action = await Swal.fire({
        title: "Warning",
        html: `<p>You are about to Export ${totalTicketsCount} tickets, this can take some time. Are you sure want to Export?</p>`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Continue",
        cancelButtonText: "Cancel",
        customClass: {
          cancelButton: "cancelBtn",
          confirmButton: "confirmBtn",
        },
      });

      if (action.isConfirmed) {
        // Handle the "OK" button click
        startExport = true;
        //getBacthes(totalTicketsCount)
      } else {
        startExport = false;
      }
    }

    if (startExport) {
      const fullBatches = Math.ceil(totalTicketsCount / batchSize);
      setIsLoading(true);
      let ticketData = [];
      for (let i = 1; i <= fullBatches; i++) {
        let body = {
          offset: 0,
          page: i,
          sort: sort,
          sortDir: sortDirection,
          limit: batchSize,
          filters: [
            {
              name: "country",
              value: cid,
              operator: "contains",
            },
            {
              name: "tickettype",
              value: menu,
              operator: "contains",
            },
            {
              name: "status",
              value: selectedStatus,
              operator: "contains",
            },
          ],
        };

        if (cid != "") {
          body.filters.push({
            name: "site",
            value: dc ? dc : "",
            operator: "contains",
          });
        } else if (dc != "") {
          body.filters.push({
            name: "site",
            value: dc,
            operator: "contains",
          });
        }
        if (filter && filter.length > 0) {
          for (let i = 0; i < filter.length; i++) {
            if (filter[i].name == "site") {
              const siteFilter = body.filters.filter(
                (el) => el.name == "site"
              )[0];
              siteFilter["operator"] = filter[i].operator;
              siteFilter["value"] = filter[i].value;
            } else if (filter[i].name == "tickettype") {
              const siteFilter = body.filters.filter(
                (el) => el.name == "tickettype"
              )[0];
              siteFilter["operator"] = filter[i].operator;
              siteFilter["value"] = filter[i].value;
            } else {
              body.filters.push({
                name: filter[i].name,
                value: filter[i].value,
                operator: filter[i].operator,
              });
            }
          }
        }
        body.filters = body.filters.filter((el) => el.value != undefined);

        await TicketService.getTicketsList(localStorage.getItem("token"), body)
          .then((res) => {
            ticketData.push(...res.data.ticket.rows);
            //  convertToExcel(ticketData, selectedColumns, false);
          })
          .catch((err) => {
            LogoutOnSessionExpire(err);
            if (
              err?.error?.response?.data?.error?.message ===
              "Endpoint request timed out"
            ) {
              Swal.fire({
                title: err?.error?.response?.data?.error?.message,
                html: `<p>We can not process this request right now. Please contact Administrator for the Report.</p>`,
                icon: "warning",
                confirmButtonText: "Ok",
                customClass: {
                  cancelButton: "cancelBtn",
                  confirmButton: "confirmBtn",
                },
              });
            }
            setIsLoading(false);
            //500 error page
          })
          .finally(() => {
            //setIsLoading(false);
          });
      }
      convertToExcel(ticketData, selectedColumns, false);
      setIsLoading(false);
    }
    setLiveReload(liveReload);
  };
  const fetchTicketsChartsData = async (stat) => {
    const dc = selectedDataCenter.id;
    const cid = selectedCountry ? selectedCountry.id : "";
    await TicketService.getTicketsChartData(localStorage.getItem("token"), {
      country_id: cid,
      data_center_id: dc,
      status: selectedStatus,
      tickettypeid: menu,
    })
      .then((res) => {
        if (res.data.data.fault) {
          let ticketData = res.data.data;
          setChartData([{ ...ticketData }]);
        } else {
          setErrorMsg(res.data);
          setErrorPopup(true);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        LogoutOnSessionExpire(err);
      });
  };
  const getStatus = (status) => {
    if (ticketStatusTypeOpt.length > 0) {
      const ticketStatus = ticketStatusTypeOpt.filter(
        (data) => data.name == status
      );
      return ticketStatus[0]?.color ? ticketStatus[0]?.color : "#000000";
    }
  };

  const getCountryCodeForSelectedTicket = (site) => {
    const countryId = dataCenterList.filter((el) => el.name === site)[0]
      .country_id;
    const countryCode = countries.filter((el) => el.id === countryId)[0]
      .country_code;
    setCountryCodeForSelectedTicket(countryCode);
  };
  const handleModalPopupOnClick = async (
    site,
    tid,
    ticket_type_id,
    subname,
    ticket_type_name
  ) => {
    getCountryCodeForSelectedTicket(site);
    setIsLoading(true);
    await TicketDetails.getTicketDetail(
      localStorage.getItem("token"),
      tid
    ).then((res) => {
      setTicketData(res?.data?.data);
      setHelpdesk(res?.data?.helpdesk?.value);
      setTimeDetails(res?.data?.timeDetail);
      if (!res?.data?.data) {
        setErrorMsg(res.data);
        setErrorPopup(true);
      }
    });
    setTicketTypeName(ticket_type_name);
    setSiteName(site);
    setIsLoading(false);
    const ticketTypeId = getTicketTypeId(ticket_type_id);
    if (ticketTypeId === 4) {
      setSiteAccess(true);
    } else if (ticketTypeId === 3) {
      if (ticket_type_name === "Inbound") {
        setShipmentIn(true);
      } else {
        setShipmentOut(true);
      }
    } else if (ticketTypeId === 1) {
      setIncident(true);
    } else if (ticketTypeId == 5) {
      setRemoteHand(true);
    } else {
      setFaultPopup(true);
    }
  };

  const getTicketTypeId = (ticket_type_id) => {
    const data = ticketSubTypeOpt;
    const ticketType = data.filter(
      (ticketType) => ticketType.oddo_ticket_type_id == ticket_type_id
    );
    return ticketType[0].ticket_type_id;
  };

  const audioRef = useRef(null);
  const playAlarm = () => {
    const audio = new Audio("/audio/alarm.mp3"); // Replace with your audio file's path
    audio.loop = true;
    audio.play();
    setPlaying(true);
    audioRef.current = audio;
    setTimeout(() => {
      audio.pause();
      setPlaying(false);
    }, process.env.REACT_APP_ALARM_IDLE_TIME);
  };

  useEffect(() => {
    const handleClick = () => {
      if (playing && audioRef.current) {
        audioRef.current.pause();
        setPlaying(false);
      }
    };

    document.addEventListener("keydown", handleClick);
    window.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("keydown", handleClick);
      window.removeEventListener("click", handleClick);
    };
  }, [playing]);

  const checkPermission = (ticketType) => {
    if (contextStore.getAuth?.role?.role_ticket.length === 0) {
      return true;
    } else {
      return contextStore.getAuth?.role?.role_ticket.some(
        (item) => item.is_allowed === 1 && item.ticket_type_id == ticketType
      );
    }
  };

  const isAllowedToGenerateReport = (data) => {
    return data.every((item) =>
      item.roleTicketPermission.some(
        (permission) =>
          permission.name === "Generate Report" && permission.is_allowed === 1
      )
    );
  };
  const checkPermissionByName = (permissionName) => {
    if (menu == 0) {
      const data = permissions.filter((el) => el.is_allowed === 1);
      return data.every((item) =>
        item.roleTicketPermission.some(
          (permission) =>
            permission.name === "Generate Report" && permission.is_allowed === 1
        )
      );
    } else {
      const ticketTypePermission = permissions.filter(
        (el) => el.ticket_type_id == menu
      )[0]?.roleTicketPermission;
      if (permissionName && ticketTypePermission) {
        const isPermitted = ticketTypePermission.filter(
          (el) => el.name == permissionName && el.is_allowed == 1
        );

        if (isPermitted.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    }

    return false;
  };
  const escalateTeams = async (ticketId) => {
    Swal.fire({
      title: t("common.are_you_sure"),
      text: t("common.confirm_teams_escalation"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("common.yes"),
      customClass: {
        cancelButton: "cancelBtn",
        confirmButton: "confirmBtn",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        await TicketDetails.escalateTicketNotification(
          localStorage.getItem("token"),
          ticketId
        )
          .then((res) => {
            if (
              res?.data?.code !== undefined ||
              res?.data?.error !== undefined
            ) {
              setErrorPopup(true);
              setErrorMsg(res.data);
            } else {
              Swal.fire("Teams escalation done successfully.");
            }
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    });
  };

  const checkIfIncidentTicket = (data) => {
    if (data.tickettypeid == ticketTypeId.incidentTicketId) {
      if (
        data.priority == "P0" ||
        data.priority == "P1" ||
        data.priority == "I1" ||
        data.priority == "E1"
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const downloadExcel = () => {
    const selectedColumns = columns.filter((column) => {
      if (SidePopVal[column.name]) {
        return column;
      }
    });
    if (totalTicketsCount <= 50) {
      convertToExcel(state, selectedColumns, false);
    } else {
      exportAllTickets(selectedColumns);
    }
  };
  const convertToExcel = (ticketList, selectedColumns, isSlice) => {
    let tableData = ticketList.map((ticket) => {
      const ticketDetail = {};
      selectedColumns.map((column) => {
        ticketDetail[column.value] =
          typeof ticket[column.name] == "string" && isSlice
            ? ticket[column.name].slice(0, 32767)
            : ticket[column.name];
      });
      return ticketDetail;
    });
    tableData.forEach((row, rowIndex) => {
      // Iterate through each column of the row
      Object.keys(row).forEach((column) => {
        const cellValue = row[column];
        // Check if the cell value is a string and exceeds the maximum length
        if (typeof cellValue === "string" && cellValue.length > 32767) {
          // console.log(`Cell at row ${rowIndex + 1}, column ${column} exceeds the maximum length.`);
          Swal.fire({
            width: "42rem",
            title: "",
            html: `<p style="text-align:left">An error occurs when exporting. An Excel cell accepts only maximum 32767 characters. Please consider excluding <b>${column}</b> column from list or download with sliced <b>${column}</b>.</p>`,
            icon: "warning",
            denyButtonColor: "#6e7881",
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: `Download without ${column}`,
            denyButtonText: `Download with sliced ${column}`,
            cancelButtonText: `cancel`,
            customClass: {
              cancelButton: "cancelBtn",
              confirmButton: "confirmBtn",
            },
          }).then((result) => {
            if (result.isConfirmed) {
              const columnWithRemoved = selectedColumns.filter(
                (el) => el.value != column
              );
              convertToExcel(ticketList, columnWithRemoved);
            }
            if (result.isDenied) {
              convertToExcel(ticketList, selectedColumns, true);
            }
            if (result.isDismissed) {
              setIsLoading(false);
              setLiveReload(true);
            }
          });
        }
      });
    });
    // array of objects to save in Excel
    let binaryWS = XLSX.utils.json_to_sheet(tableData);

    const cols = [];
    selectedColumns.map((column) => {
      cols.push({ width: column.width });
    });
    binaryWS["!cols"] = cols;
    // Create a new Workbook
    var wb = XLSX.utils.book_new();
    // Name your sheet
    XLSX.utils.book_append_sheet(wb, binaryWS, "Tickets List");
    // export your excel
    XLSX.writeFile(wb, getExcelSheetName());
  };

  const getExcelSheetName = () => {
    const date = new Date();
    const downloadDateTime = userTimzone
      ? moment(date).tz(userTimzone).format("YYYY-MM-DD HH:mm") +
        " " +
        timzonePrefix.toUpperCase()
      : moment(date).tz("Asia/Hong_Kong").format("YYYY-MM-DD HH:mm") +
        " " +
        timzonePrefix.toUpperCase();
    if (menu == 0) {
      return `${t("ticket.all_tickets")} ( ${downloadDateTime} ).xlsx`;
    }
    if (menu == 1) {
      return `${t("ticket.incidents")} ( ${downloadDateTime} ).xlsx`;
    }
    if (menu == 2) {
      return `${t("ticket.faults")} ( ${downloadDateTime} ).xlsx`;
    }
    if (menu == 4) {
      return `${t("ticket.site_visits")} ( ${downloadDateTime} ).xlsx`;
    }
    if (menu == 3) {
      return `${t("ticket.shipments")} ( ${downloadDateTime} ).xlsx`;
    }
    if (menu == 5) {
      return `${t("ticket.remote_hands")} ( ${downloadDateTime} ).xlsx`;
    }
  };

  return (
    <React.Fragment>
      <Loading
        loading={isLoading}
        background="rgba(0, 0, 0, 0.4)"
        loaderColor="rgb(248, 153, 3)"
      />
      <Layout
        setSelectedDataCenterForTicket={setSelectedDataCenter}
        setSelectedCountryForTicket={setSelectedCountry}
        setIsSelectedGroup={setIsSelectedGroup}
        isSelectedGroup={isSelectedGroup}
        setDataCenterList={setDataCenterList}
        setCountriesForTickets={setCountries}
      >
        <div className="bg_color_dash">
          <div className="container-fluid pb-5">
            <div className="d-flex justify-content-between pt-2">
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ gap: "1rem" }}
              >
                {incidentModePermission && !isGroupBy && (
                  <div
                    class="form-check form-switch d-flex align-items-center"
                    style={{ paddingLeft: "0px", marginBottom: "0rem" }}
                  >
                    <label
                      className="form-label"
                      style={{
                        float: "left",
                        marginRight: "1rem",
                        marginBottom: "0rem",
                      }}
                    >
                      {t("rolepermission.incident_mode")}
                    </label>
                    <label className="switch" style={{ marginBottom: "0rem" }}>
                      <input
                        type="checkbox"
                        onChange={(event) => {
                          localStorage.setItem(
                            "isIncidentModeOn",
                            event.target.checked ? "true" : "false"
                          );
                          setIncidentMode(event.target.checked);
                        }}
                        checked={incidentMode}
                        id="flexSwitchCheckDefault"
                      />

                      <div className="slider round">
                        <span className="on" style={{ fontSize: "10px" }}>
                          ON
                        </span>
                        <span className="off">{t("ticket.off")}</span>
                      </div>
                    </label>
                  </div>
                )}

                {incidentModePermission && !isGroupBy && incidentMode && (
                  <>
                    <div>
                      <span className="form-label" style={{ color: "#ca2222" }}>
                        High: P0/P1/E1/I1
                      </span>
                    </div>
                    <div>
                      <span className="form-label" style={{ color: "#FFA500" }}>
                        Low: P2/P3
                      </span>
                    </div>
                  </>
                )}
              </div>

              <div className="toggle_tickets">
                <h6 style={{ marginRight: "20px" }}>
                  {t("ticket.last_refresh")} :{" "}
                  {!currentTime.status ? (
                    <span style={{ color: "#FF7A00" }}>{currentTime.time}</span>
                  ) : (
                    <span>{currentTime.time}</span>
                  )}
                </h6>

                <RefreshIcon
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => fetchTicket()}
                  data-html2canvas-ignore="true"
                  title="Refresh Ticket"
                />

                <h6 style={{ marginRight: "5px", marginLeft: "5px" }}>
                  {t("ticket.auto_refresh")}
                </h6>

                <label className="switch">
                  <input
                    type="checkbox"
                    onChange={(event) => {
                      setLiveReload(event.target.checked);
                      setLiveReloadPreference(event.target.checked);
                    }}
                    checked={
                      !liveReloadPreference ? liveReloadPreference : liveReload
                    }
                    id="flexSwitchCheckDefault"
                  />

                  <div className="slider round">
                    <span className="on">{numberInTwoDigit(count)}</span>
                    <span className="off">{t("ticket.off")}</span>
                  </div>
                </label>

                <h6 style={{ marginRight: "5px", marginLeft: "20px" }}>
                  {t(`ticket.alarm_on_off`)}
                </h6>
                <label className="switch">
                  <input
                    type="checkbox"
                    onChange={(event) => setAlarmOn(event.target.checked)}
                    checked={alarm}
                    id="flexSwitchCheckDefault"
                  />

                  <div className="slider round">
                    <span className="off">{t("ticket.off")}</span>
                  </div>
                </label>
              </div>
            </div>
            <div>
              <div
                className="d-flex justify-content-end"
                style={{ marginTop: "-5px" }}
              >
                {" "}
                <span>
                  <i>{t("ticket.k_c_t_z")}</i>
                </span>
              </div>
            </div>
            <div className="row pt-0" id="card">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 gx-4">
                <div className="row g-2">
                  {incidentMode ? (
                    <IncidentOverviewCard
                      cardData={incidentOverviewData}
                      selectedDataCenter={selectedDataCenter}
                      selectedCountry={selectedCountry}
                      dataCenterList={dataCenterList}
                      setIncidentOverviewSite={setIncidentOverviewSite}
                      setIncidentOverviewPriority={setIncidentOverviewPriority}
                    />
                  ) : (
                    <Card
                      cardData={cardData}
                      state={chartData}
                      menu={menu}
                      setMenu={setMenu}
                      checkPermission={checkPermission}
                    />
                  )}
                </div>
                <div className="row">
                  <div className="invglob" style={{ zIndex: "5" }}>
                    <div className="profile-tab menu_tab_btn">
                      <div className="custom-tab-1">
                        <ul
                          className="nav nav-tabs"
                          style={{
                            flexWrap: "nowrap",
                            overflowX: "auto",
                          }}
                        >
                          {contextStore.getAuth?.role?.role_ticket &&
                            !incidentMode &&
                            contextStore.getAuth?.role?.role_ticket.filter(
                              (item) => item.is_allowed === 1
                            ).length > 1 && (
                              <li className="nav-item">
                                <button
                                  className={
                                    menu === 0
                                      ? "btn btn-secondary"
                                      : "btn btn-light"
                                  }
                                  onClick={() => {
                                    setMenu(0);

                                    // playAlarm();
                                  }}
                                >
                                  {" "}
                                  {t("ticket.all_tickets")}
                                </button>
                              </li>
                            )}

                          {contextStore.getAuth?.role?.role_ticket &&
                            checkPermission(1) && (
                              <li className="nav-item">
                                <button
                                  className={
                                    menu === 1
                                      ? "btn btn-secondary"
                                      : "btn btn-light"
                                  }
                                  onClick={() => {
                                    setMenu(1);
                                  }}
                                >
                                  {" "}
                                  {t("ticket.incidents")}
                                </button>
                              </li>
                            )}
                          {contextStore.getAuth?.role?.role_ticket &&
                            !incidentMode &&
                            checkPermission(2) && (
                              <li className="nav-item">
                                <button
                                  className={
                                    menu === 2
                                      ? "btn btn-secondary"
                                      : "btn btn-light"
                                  }
                                  onClick={() => {
                                    setMenu(2);
                                  }}
                                >
                                  {t("ticket.faults")}
                                </button>
                              </li>
                            )}
                          {contextStore.getAuth?.role?.role_ticket &&
                            !incidentMode &&
                            checkPermission(4) && (
                              <li className="nav-item">
                                <button
                                  className={
                                    menu === 4
                                      ? "btn btn-secondary"
                                      : "btn btn-light"
                                  }
                                  onClick={() => {
                                    setMenu(4);
                                  }}
                                >
                                  {" "}
                                  {t("ticket.site_visits")}
                                </button>
                              </li>
                            )}
                          {contextStore.getAuth?.role?.role_ticket &&
                            !incidentMode &&
                            checkPermission(3) && (
                              <li className="nav-item">
                                <button
                                  className={
                                    menu === 3
                                      ? "btn btn-secondary"
                                      : "btn btn-light"
                                  }
                                  onClick={() => {
                                    setMenu(3);
                                  }}
                                >
                                  {" "}
                                  {t("ticket.shipments")}
                                </button>
                              </li>
                            )}
                          {contextStore.getAuth?.role?.role_ticket &&
                            !incidentMode &&
                            checkPermission(5) && (
                              <li className="nav-item">
                                <button
                                  className={
                                    menu === 5
                                      ? "btn btn-secondary"
                                      : "btn btn-light"
                                  }
                                  onClick={() => {
                                    setMenu(5);
                                  }}
                                >
                                  {" "}
                                  {t("ticket.remote_hands")}
                                </button>
                              </li>
                            )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="row"
                  style={{
                    zIndex: "5",
                    position: "relative",
                    paddingTop: "0.5rem",
                  }}
                >
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div
                      className="grid_card grid_mr h-100 tckt"
                      style={{ display: "grid", gap: "2rem" }}
                    >
                      <div className="d-flex justify-content-between py-1">
                        <div>
                          <SearchBox
                            searchCategory={filteredSearchedCategory}
                            filter={filter}
                            setFilter={setFilter}
                            setIsLoading={setIsLoading}
                            setPageNumber={setPageNumber}
                            setItemsPerPage={setItemsPerPage}
                            groupBy={groupBy}
                            setGroupBy={setGroupBy}
                            setIsGroupBy={setIsGroupBy}
                            isGroupBy={isGroupBy}
                            isSelectedGroup={isSelectedGroup}
                            setIsSelectedGroup={setIsSelectedGroup}
                            textOperators={textOperators}
                            dateOperators={dateOperators}
                            numberOperators={numberOperators}
                          ></SearchBox>
                        </div>
                        <div className="d-flex px-5 gap-5 text-center">
                          <SelectBoxFilter
                            statusCategory={statusCategory}
                            selectedStatus={selectedStatus}
                            setSelectedStatus={setSelectedStatus}
                            setIsSelectedGroup={setIsSelectedGroup}
                          ></SelectBoxFilter>
                          {!incidentMode && (
                            <GroupByBox
                              groupCategory={groupCategory}
                              groupBy={groupBy}
                              setGroupBy={setGroupBy}
                              isGroupBy={isGroupBy}
                              setIsGroupBy={setIsGroupBy}
                              setIsLoading={setIsLoading}
                              setPageNumber={setPageNumber}
                              setItemsPerPage={setItemsPerPage}
                              isSelectedGroup={isSelectedGroup}
                              setIsSelectedGroup={setIsSelectedGroup}
                            ></GroupByBox>
                          )}
                          {!incidentMode && (
                            <FavouritesBox
                              favourites={favourites}
                              groupBy={groupBy}
                              setGroupBy={setGroupBy}
                              isGroupBy={isGroupBy}
                              setIsGroupBy={setIsGroupBy}
                              isSelectedGroup={isSelectedGroup}
                              setIsSelectedGroup={setIsSelectedGroup}
                              filter={filter}
                              setFilter={setFilter}
                              setIsLoading={setIsLoading}
                              setPageNumber={setPageNumber}
                              setItemsPerPage={setItemsPerPage}
                              addFavourite={addFavourites}
                              deleteFavourite={deleteFavourite}
                              setState={setState}
                            ></FavouritesBox>
                          )}

                          {checkPermissionByName("Generate Report") && (
                            <div>
                              {" "}
                              <img
                                src="images/excel.png"
                                className="img-fluid"
                                style={{
                                  cursor: "pointer",
                                  height: "30px",
                                  width: "30px",
                                }}
                                alt=""
                                onClick={downloadExcel}
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      {isGroupBy && (
                        <div className="card-body">
                          <div className="table-responsive tckect_tt table-scroll-set2">
                            <GroupByTable
                              columns={filteredColumns}
                              groups={groups}
                              setItemsPerPage={setItemsPerPage}
                              setPageNumber={setPageNumber}
                              total={totalTicketsCount}
                              pageNumber={pageNumber}
                              userTimzon={userTimzone}
                              readinessChange={readinessChange}
                              newTicket={newTicket}
                              handleModalPopupOnClick={handleModalPopupOnClick}
                              timzonePrefix={timzonePrefix}
                              escalateTeams={escalateTeams}
                              checkIfIncidentTicket={checkIfIncidentTicket}
                              playing={playing}
                              getStatus={getStatus}
                              state={groupByTicketState}
                              isCollapse={isCollapse}
                              isSelectedGroup={isSelectedGroup}
                              setIsSelectedGroup={setIsSelectedGroup}
                              totalTicketsCount={totalTicketsCount}
                              setState={setState}
                              setIsCollapse={setIsCollapse}
                              groupBy={groupBy}
                              sidePopValDb={sidePopValDb}
                              setSidePopValDb={setSidePopValDb}
                              setSort={setSort}
                              setSortDirection={setSortDirection}
                              sort={sort}
                              sortDirection={sortDirection}
                              SidePopVal={SidePopVal}
                              setSidePopVal={setSidePopVal}
                              menu={menu}
                            ></GroupByTable>
                          </div>
                        </div>
                      )}
                      {!isGroupBy && (
                        <div className="card-body">
                          <div
                            className="table-responsive tckect_tt table-scroll-set"
                            style={{ top: calculateTableTopPosition() }}
                          >
                            <Table
                              columns={filteredColumns}
                              data={state}
                              setItemsPerPage={setItemsPerPage}
                              setPageNumber={setPageNumber}
                              total={totalTicketsCount}
                              pageNumber={pageNumber}
                              userTimzone={userTimzone}
                              readinessChange={readinessChange}
                              newTicket={newTicket}
                              handleModalPopupOnClick={handleModalPopupOnClick}
                              timzonePrefix={timzonePrefix}
                              escalateTeams={escalateTeams}
                              checkIfIncidentTicket={checkIfIncidentTicket}
                              playing={playing}
                              getStatus={getStatus}
                              menu={menu}
                              sidePopValDb={sidePopValDb}
                              setSidePopValDb={setSidePopValDb}
                              setSort={setSort}
                              setSortDirection={setSortDirection}
                              sort={sort}
                              sortDirection={sortDirection}
                              SidePopVal={SidePopVal}
                              setSidePopVal={setSidePopVal}
                              setIsLoading={setIsLoading}
                            ></Table>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {ticketdata
          ? faultpopup && (
              <FaultPopup
                show={faultpopup}
                setShow={setFaultPopup}
                ticketdata={ticketdata}
                sitename={sitename}
                menu={menu}
                ticketTypeName={ticketTypeName}
                getTicketDetail={handleModalPopupOnClick}
                permissions={
                  permissions.filter((el) => el.ticket_type_id == 2)[0]
                    .roleTicketPermission
                }
              />
            )
          : errorpopup && (
              <ErrorModel
                show={errorpopup}
                setShow={setErrorPopup}
                errorMsg={errorMsg}
              />
            )}
        {ticketdata
          ? remotehand && (
              <RemoteHandPopup
                show={remotehand}
                setShow={setRemoteHand}
                ticketdata={ticketdata}
                sitename={sitename}
                timeDetails={timeDetail}
                menu={menu}
                ticketTypeName={ticketTypeName}
                getTicketDetail={handleModalPopupOnClick}
                permissions={
                  permissions.filter((el) => el.ticket_type_id == 5)[0]
                    .roleTicketPermission
                }
              />
            )
          : errorpopup && (
              <ErrorModel show={errorpopup} setShow={setErrorPopup} />
            )}
        {ticketdata
          ? siteAccess && (
              <SiteFaultPopup
                show={siteAccess}
                setShow={setSiteAccess}
                ticketdata={ticketdata}
                sitename={sitename}
                menu={menu}
                ticketTypeName={ticketTypeName}
                getTicketDetail={handleModalPopupOnClick}
                countryCode={countryCodeForSelectedTicket}
                permissions={
                  permissions.filter((el) => el.ticket_type_id == 4)[0]
                    .roleTicketPermission
                }
              />
            )
          : errorpopup && (
              <ErrorModel
                show={errorpopup}
                setShow={setErrorPopup}
                errorMsg={errorMsg}
              />
            )}
        {ticketdata
          ? shipmentout && (
              <ShipmentOutboundPopup
                show={shipmentout}
                setShow={setShipmentOut}
                ticketdata={ticketdata}
                sitename={sitename}
                menu={menu}
                helpdesk={helpdesk}
                ticketTypeName={ticketTypeName}
                getTicketDetail={handleModalPopupOnClick}
                permissions={
                  permissions.filter((el) => el.ticket_type_id == 3)[0]
                    .roleTicketPermission
                }
              />
            )
          : errorpopup && (
              <ErrorModel
                show={errorpopup}
                setShow={setErrorPopup}
                errorMsg={errorMsg}
              />
            )}
        {ticketdata
          ? shipmentin && (
              <ShipmentInboundPopup
                show={shipmentin}
                setShow={setShipmentIn}
                ticketdata={ticketdata}
                sitename={sitename}
                menu={menu}
                helpdesk={helpdesk}
                ticketTypeName={ticketTypeName}
                getTicketDetail={handleModalPopupOnClick}
                permissions={
                  permissions.filter((el) => el.ticket_type_id == 3)[0]
                    .roleTicketPermission
                }
              />
            )
          : errorpopup && (
              <ErrorModel
                show={errorpopup}
                setShow={setErrorPopup}
                errorMsg={errorMsg}
              />
            )}
        {ticketdata
          ? incident && (
              <IncidentManagementPopup
                show={incident}
                setShow={setIncident}
                ticketdata={ticketdata}
                sitename={sitename}
                menu={menu}
                ticketTypeName={ticketTypeName}
                getTicketDetail={handleModalPopupOnClick}
                permissions={
                  permissions.filter((el) => el.ticket_type_id == 1)[0]
                    .roleTicketPermission
                }
              />
            )
          : errorpopup && (
              <ErrorModel
                show={errorpopup}
                setShow={setErrorPopup}
                errorMsg={errorMsg}
              />
            )}
        {errorpopup ? (
          <ErrorModel
            show={errorpopup}
            setShow={setErrorPopup}
            errorMsg={errorMsg}
          />
        ) : (
          ""
        )}
      </Layout>
    </React.Fragment>
  );
};

export default Ticket;
