import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const IncidentOverviewCard = ({
  cardData,
  selectedDataCenter,
  selectedCountry,
  dataCenterList,
  setIncidentOverviewSite,
  setIncidentOverviewPriority,
}) => {
  const [filteredCardData, setFilteredCardData] = useState([]);
  const { t } = useTranslation();
  const [activeSite, setActiveSite] = useState("");
  const [activePriority, setActivePriority] = useState(null);

  useEffect(() => {
    // console.log(selectedDataCenter);
    // console.log(selectedCountry);
    // console.log(cardData);
    if (selectedCountry == "") {
      if (selectedDataCenter && selectedDataCenter.name == "ALL") {
        setFilteredCardData(getFilteredData(cardData));
      }
      if (
        selectedDataCenter &&
        selectedDataCenter != "" &&
        selectedDataCenter.name != "ALL"
      ) {
        setFilteredCardData(
          getFilteredData(cardData).filter(
            (el) => el.operationSite == selectedDataCenter.name
          )
        );
      }
      if (selectedDataCenter == "") {
        setFilteredCardData(getFilteredData(cardData));
      }
    } else {
      const data = getFilteredData(cardData).filter(
        (el) => el.country_id == selectedCountry.id
      );
      if (selectedDataCenter && selectedDataCenter.name == "ALL") {
        setFilteredCardData(data);
      }
      if (
        selectedDataCenter &&
        selectedDataCenter != "" &&
        selectedDataCenter.name != "ALL"
      ) {
        setFilteredCardData(
          data.filter((el) => el.operationSite == selectedDataCenter.name)
        );
      }
      if (selectedDataCenter == "") {
        setFilteredCardData(data);
      }
    }

    setActiveSite("");
    setActivePriority(null);
  }, [selectedDataCenter, selectedCountry, cardData, dataCenterList]);

  const getFilteredData = (data) => {
    const filteredData = data.filter((item) => {
      if (
        dataCenterList
          .map((innerItem) => innerItem.name)
          .includes(item.operationSite)
      ) {
        const index = dataCenterList.findIndex(function (obj) {
          return obj.name === item.operationSite;
        });
        item.index = index;
        item.country_id = dataCenterList[index].country_id;
        return item;
      }
    });
    return filteredData.sort((a, b) => a.index - b.index);
  };

  const getSevertyData = (status, data) => {
    // console.log(data);
    if (status) {
      return data.filter((el) => el.priority == true)[0].count;
    } else {
      return data.filter((el) => el.priority == false)[0].count;
    }
  };
  const getSevertyDataForAll = (status) => {
    let high = 0;
    let low = 0;
    cardData.forEach((item) => {
      item.priorityStats.forEach((stat) => {
        if (stat.priority) {
          high += stat.count;
        } else {
          low += stat.count;
        }
      });
    });
    if (status) {
      return high;
    } else {
      return low;
    }
  };

  // useEffect(() => {
  //   console.log(dataCenterList);
  //   console.log(dataCenterList.length);
  // });

  return (
    <>
      {dataCenterList &&
        dataCenterList.length > 1 &&
        filteredCardData &&
        filteredCardData.length > 1 && (
          <div style={{ width: "10%" }} className="incident_card">
            <div
              className="grid_card"
              style={{
                border: activeSite == "" ? "2px solid blue" : "",
                cursor: "pointer",
                height: "7rem",
                padding: "0rem 1rem 0rem 0.5rem",
              }}
            >
              <div
                className="card_head justify-content-center"
                onClick={() => {
                  setActiveSite("");
                  setIncidentOverviewSite("");
                  setIncidentOverviewPriority("P0,P1,E1,I1,P2,P3");
                  setActivePriority(null);
                }}
              >
                <h3
                  style={{
                    fontSize: "1.25rem",
                    fontWeight: 600,
                    paddingTop: "0.5rem",
                  }}
                >
                  All
                </h3>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div
                  className="text-center"
                  style={{
                    background:
                      activeSite == "" && activePriority == true
                        ? "#e2f0d9"
                        : "",
                    borderRadius: "4px",
                    padding: "0.4rem 13% 0rem 13%",
                  }}
                  onClick={() => {
                    if (activeSite != "") {
                      setActiveSite("");
                      setIncidentOverviewSite("");
                    }
                    setActivePriority(true);
                    setIncidentOverviewPriority("P0,P1,E1,I1");
                  }}
                >
                  <span
                    style={{
                      color: "#ca2222",
                      fontWeight: 600,
                      fontSize: "1rem",
                    }}
                  >
                    High
                  </span>
                  <br></br>
                  <span
                    style={{
                      color: "#ca2222",
                      fontWeight: 700,
                      fontSize: "1rem",
                    }}
                  >
                    {getSevertyDataForAll(true)}
                  </span>
                </div>
                <div
                  className="text-center"
                  style={{
                    background:
                      activeSite == "" && activePriority == false
                        ? "#e2f0d9"
                        : "",
                    borderRadius: "4px",
                    padding: "0.4rem 13% 0rem 13%",
                  }}
                  onClick={() => {
                    if (activeSite != "") {
                      setActiveSite("");
                      setIncidentOverviewSite("");
                    }
                    setIncidentOverviewPriority("P2,P3");
                    setActivePriority(false);
                  }}
                >
                  <span
                    style={{
                      color: "#FFA500",
                      fontWeight: 600,
                      fontSize: "1rem",
                    }}
                  >
                    Low
                  </span>
                  <br></br>
                  <span
                    style={{
                      color: "#FFA500",
                      fontWeight: 600,
                      fontSize: "1rem",
                    }}
                  >
                    {getSevertyDataForAll(false)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
      {filteredCardData &&
        filteredCardData.length > 0 &&
        filteredCardData.map((data) => {
          return (
            <div style={{ width: "10%" }} className="incident_card">
              <div
                className="grid_card"
                style={{
                  border:
                    activeSite == data.operationSite ? "2px solid blue" : "",
                  cursor: "pointer",
                  height: "7rem",
                  padding: "0rem 1rem 0rem 0.5rem",
                }}
              >
                <div
                  className="card_head justify-content-center"
                  onClick={() => {
                    setActiveSite(data.operationSite);
                    setIncidentOverviewSite(data.operationSite);
                    setIncidentOverviewPriority("P0,P1,E1,I1,P2,P3");
                    setActivePriority(null);
                  }}
                >
                  <h3
                    style={{
                      fontSize: "1.25rem",
                      fontWeight: 600,
                      paddingTop: "0.5rem",
                    }}
                  >
                    {data.operationSite}
                  </h3>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div
                    className="text-center"
                    style={{
                      background:
                        activeSite == data.operationSite &&
                        activePriority == true
                          ? "#e2f0d9"
                          : "",
                      borderRadius: "4px",
                      padding: "0.4rem 13% 0rem 13%",
                    }}
                    onClick={() => {
                      if (activeSite != data.operationSite) {
                        setActiveSite(data.operationSite);
                        setIncidentOverviewSite(data.operationSite);
                      }
                      setActivePriority(true);
                      setIncidentOverviewPriority("P0,P1,E1,I1");
                    }}
                  >
                    <span
                      style={{
                        color: "#ca2222",
                        fontWeight: 600,
                        fontSize: "1rem",
                      }}
                    >
                      High
                    </span>
                    <br></br>
                    <span
                      style={{
                        color: "#ca2222",
                        fontWeight: 700,
                        fontSize: "1rem",
                      }}
                    >
                      {getSevertyData(true, data.priorityStats)}
                    </span>
                  </div>
                  <div
                    className="text-center"
                    style={{
                      background:
                        activeSite == data.operationSite &&
                        activePriority == false
                          ? "#e2f0d9"
                          : "",
                      borderRadius: "4px",
                      padding: "0.4rem 13% 0rem 13%",
                    }}
                    onClick={() => {
                      if (activeSite != data.operationSite) {
                        setActiveSite(data.operationSite);
                        setIncidentOverviewSite(data.operationSite);
                      }
                      setIncidentOverviewPriority("P2,P3");
                      setActivePriority(false);
                    }}
                  >
                    <span
                      style={{
                        color: "#FFA500",
                        fontWeight: 600,
                        fontSize: "1rem",
                      }}
                    >
                      Low
                    </span>
                    <br></br>
                    <span
                      style={{
                        color: "#FFA500",
                        fontWeight: 600,
                        fontSize: "1rem",
                      }}
                    >
                      {getSevertyData(false, data.priorityStats)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
};

export default IncidentOverviewCard;
