import React, { useEffect, useState, useContext, useRef } from "react";
import Navigation from "../navigation";
import RoleService from "services/roleServices";
import UserService from "services/userServices";
import AuthContext from "context";
import { LogoutOnSessionExpire } from "common/helpers";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import StarIcon from "@mui/icons-material/Star";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const DataCenterNav = ({
  getFloorData,
  setSelectedDataCenter,
  setSelectedCountry,
  selectedDataCenter,
  selectedCountry,
}) => {
  const navigate = useNavigate();
  const contextStore = useContext(AuthContext);
  const [countries, setCountries] = useState([]);
  const [dataCenter, setDataCenter] = useState([]);
  const [countryText, setCountryText] = useState("Country");
  const [countrycount, setCountryCount] = useState("");
  const { t } = useTranslation();
  const location = useLocation();
  const [defaultPage, setDefaultPage] = useState(null);

  useEffect(() => {
    const dataCenters = contextStore.getPermittedDataCenter;
    setDataCenter([
      ...dataCenters,
      ...(dataCenters.length > 1 ? [{ name: "ALL", id: "all" }] : []),
    ]);
  }, [contextStore.getPermittedDataCenter]);

  useEffect(() => {
    if (selectedDataCenter == "" && dataCenter.length > 1) {
      if (location.state != null && location.pathname == "/data-center") {
        setSelectedDataCenter(location.state);
      } else {
        setSelectedDataCenter(dataCenter[dataCenter.length - 1]);
      }
    }
  }, [dataCenter]);

  useEffect(() => {
    if (dataCenter.length == 1) {
      if (location.state != null && location.pathname == "/data-center") {
        setSelectedDataCenter(location.state);
      } else {
        setSelectedDataCenter(dataCenter[0]);
      }
    }
    if (dataCenter.length > 1) {
      if (location.state != null && location.pathname == "/data-center") {
        setSelectedDataCenter(location.state);
      } else {
        setSelectedDataCenter(dataCenter.filter((el) => el.id == "all")[0]);
      }
    }
  }, [selectedCountry]);

  useEffect(() => {
    setCountries(contextStore.getPermittedCountry);
  }, [contextStore.getPermittedCountry]);

  useEffect(() => {
    if (countries.length == 1) {
      contextStore.setSelectedCountry(countries[0]);
      setCountryText(countries[0].name);
      setSelectedCountry(countries[0]);
    }
  }, [countries]);

  const onCountryChange = (country) => {
    contextStore.setSelectedCountry(country);
    setCountryText(country.name);
    setSelectedCountry(country);
    const dataCenters = contextStore.getPermittedDataCenter.filter(
      (el) => el.country_id == country.id
    );
    setDataCenter([
      ...dataCenters,
      ...(dataCenters.length > 1 ? [{ name: "ALL", id: "all" }] : []),
    ]);
  };

  const onDataCenterChange = (dataCenter) => {
    if (location.state) {
      navigate("/data-center", { replace: true, state: null });
    }
    setSelectedDataCenter(dataCenter);
  };

  const getCountryName = (dataCenter) => {
    const addresss = dataCenter.address;
    if (addresss) {
      const parts = addresss.split(",");
      let country = parts[parts.length - 1].trim();
      if (country === "South Korea") {
        country = "Korea";
      }
      contextStore.setCountryName(country);
    }
  };

  const onGlobal = () => {
    setCountryText("Country");
    const dataCenters = contextStore.getPermittedDataCenter;
    setDataCenter([
      ...dataCenters,
      ...(dataCenters.length > 1 ? [{ name: "ALL", id: "all" }] : []),
    ]);
    setSelectedCountry("");
    contextStore.setSelectedCountry("");
  };

  const handleDefaultPage = () => {
    const page =
      location.pathname == "/data-center"
        ? "Data center"
        : location.pathname == "/ticket"
        ? "Tickets"
        : "Provisioning";
    const text = page + " will be set as default page.";
    Swal.fire({
      title: t("common.are_you_sure"),
      text: text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      customClass: {
        cancelButton: "cancelBtn",
        confirmButton: "confirmBtn",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        updateDefaultPage(location.pathname);
      }
    });
  };

  const updateDefaultPage = (page) => {
    UserService.updateDefaultPage(contextStore.token(), { default_page: page })
      .then((res) => {
        setDefaultPage(page);
      })
      .catch((err) => {
        LogoutOnSessionExpire(err);
      });
  };

  useEffect(() => {
    setDefaultPage(contextStore.getAuth.default_page);
  }, [contextStore.getAuth.default_page]);

  const getTitle = () => {
    if (location.pathname == defaultPage) {
      if (location.pathname == "/data-center") {
        return "Data Center is set as default page";
      }
      if (location.pathname == "/esg") {
        return "ESG is set as default page";
      }
      if (location.pathname == "/ticket") {
        return "Tickets is set as default page";
      }
      if (location.pathname == "/provisioning") {
        return "Provisioning is set as default page";
      }
    } else {
      return "Set as default page";
    }
  };
  return (
    <React.Fragment>
      <Navigation />
      <div className="dt_tabs header_dash">
        <div className="profile-tab">
          <div className="custom-tab-1 tab_flex">
            <div className="gin_btn">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  {countries.length > 1 &&
                  location.pathname != "/provisioning" ? (
                    <button
                      type="text"
                      onClick={() => onGlobal()}
                      className="btn btn-light"
                      style={{
                        background:
                          countryText != "Country" ? "#EAECF2" : "#11263C",
                        color: countryText != "Country" ? "#000000" : "#ffffff",
                      }}
                    >
                      {" "}
                      {t("header.global")}{" "}
                    </button>
                  ) : null}
                </li>
                <li className="nav-item">
                  <div className="btn-group" role="group">
                    <button
                      type="button"
                      className="btn btn-secondary dropdown-toggle"
                      style={{
                        background:
                          countryText == "Country" ? "#EAECF2" : "#11263C",
                        color: countryText == "Country" ? "#000000" : "#ffffff",
                        border: countryText == "Country" ? "none" : "",
                      }}
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {countryText}
                    </button>
                    <div className="dropdown-menu" style={{ margin: "0px" }}>
                      {countries.map((country, i) => {
                        return (
                          <div key={i}>
                            <a
                              href="#sd"
                              style={{ cursor: "pointer" }}
                              onClick={() => onCountryChange(country)}
                              className="dropdown-item form-check-label"
                            >
                              {country.name}
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div className="outer-div">
              <div className="tabs_srll">
                <ul className="nav nav-tabs scroll_tab_dg">
                  {dataCenter &&
                    dataCenter.map((data, index) => {
                      if (
                        selectedDataCenter &&
                        selectedDataCenter.id === data.id
                      ) {
                        return (
                          <li
                            className={
                              index === 0 ? "nav-item ml_20" : "nav-item"
                            }
                            key={index}
                          >
                            <a
                              href="#sds"
                              onClick={() => {
                                onDataCenterChange(data);
                                getCountryName(data);
                              }}
                              style={{
                                cursor: "pointer",
                                whiteSpace: "nowrap",
                              }}
                              className="nav-link active show"
                            >
                              <img
                                className="down"
                                src="images/downward-arrow.png"
                              />
                              {data.name}
                            </a>
                          </li>
                        );
                      } else {
                        return (
                          <li
                            className={
                              index === 0 ? "nav-item ml_20" : "nav-item"
                            }
                            key={index}
                          >
                            <a
                              href="#sds"
                              onClick={() => {
                                onDataCenterChange(data);
                                getCountryName(data);
                              }}
                              style={{
                                cursor: "pointer",
                                whiteSpace: "nowrap",
                              }}
                              className="nav-link"
                            >
                              {" "}
                              {data.name}{" "}
                            </a>
                          </li>
                        );
                      }
                    })}
                </ul>
              </div>
              <div>
                <span>
                  <i>
                    **{" "}
                    {t("header.select_a_country_to_view_partner_data_center")}
                  </i>
                </span>
              </div>
            </div>
            <div>
              <Tooltip
                title={<span style={{ fontSize: "1rem" }}>{getTitle()}</span>}
                TransitionComponent={Zoom}
                placement="left"
                arrow
              >
                <IconButton
                  edge="end"
                  style={{ marginRight: "2rem" }}
                  onClick={
                    defaultPage == location.pathname ? "" : handleDefaultPage
                  }
                >
                  {defaultPage == location.pathname ? (
                    <StarIcon style={{ color: "#FE8600" }} />
                  ) : (
                    <StarBorderIcon style={{ color: "black" }} />
                  )}
                </IconButton>{" "}
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DataCenterNav;
