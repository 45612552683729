import AuthContext from 'context'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CreateTicketService from 'services/createTicketService'
import Swal from 'sweetalert2'
import _ from 'lodash'

const TICKET_SUB_TYPES_TABLE = 'ticket_sub_types'
const SHIPMENT_TICKET_TYPE = 3

const useGetTicketTypes = (setFieldValue) => {
  const { t } = useTranslation()
  const authContext = useContext(AuthContext)

  const [ticketTypes, setTicketTypes] = useState([])

  const getHandlingInstructions = async () => {
    try {
      const resultDto = await CreateTicketService
        .getTableContent(authContext.token(), TICKET_SUB_TYPES_TABLE)
      const result = _.orderBy(resultDto
        .filter(item => item.ticket_type_id === SHIPMENT_TICKET_TYPE)
        .map(item => {
          const { id, name } = item
          return { ...item, label: name, value: id, key: name.trim().toLowerCase() }
        }), ['name'], ['asc'])
      setTicketTypes(result)
      setFieldValue('shipmentType', result[0].key)
    } catch (err) {
      await Swal.fire(t('error'), err.message)
    }
  }

  useEffect(() => {
    getHandlingInstructions()
  }, [])

  return {
    ticketTypes
  }
}

export default useGetTicketTypes
