// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tech_docs_table {
  width: 100%;
  padding: 20px;
}

.tech_docs_table table {
  width: 100%;
  background-color: #f8f8f8;
  border: 2px solid white;
}

.tech_docs_table thead tr {
  background-color: #3b7dff;
  color: #ffffff;
  text-align: left;
  border: 4px solid white;
}

.tech_docs_table thead tr th {
  color: white;
}
.tech_docs_table th,
.tech_docs_table td {
  padding: 10px;
  border-left: 2px solid white;
  border-right: 2px solid white;
}
.tech_docs_table .table-row:nth-child(odd) {
  background-color: #ced7ff;
  border: 2px solid white;
}

.tech_docs_table .table-row:nth-child(even) {
  background-color: #e8ecff;
  border: 2px solid white;
}
.tech_docs_table .download-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/views/super-admin/Dashboard/components/Tech Docs/techDocs.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;AACf;;AAEA;EACE,WAAW;EACX,yBAAyB;EACzB,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,YAAY;AACd;AACA;;EAEE,aAAa;EACb,4BAA4B;EAC5B,6BAA6B;AAC/B;AACA;EACE,yBAAyB;EACzB,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;EACzB,uBAAuB;AACzB;AACA;EACE,gBAAgB;EAChB,YAAY;EACZ,eAAe;EACf,eAAe;AACjB","sourcesContent":[".tech_docs_table {\n  width: 100%;\n  padding: 20px;\n}\n\n.tech_docs_table table {\n  width: 100%;\n  background-color: #f8f8f8;\n  border: 2px solid white;\n}\n\n.tech_docs_table thead tr {\n  background-color: #3b7dff;\n  color: #ffffff;\n  text-align: left;\n  border: 4px solid white;\n}\n\n.tech_docs_table thead tr th {\n  color: white;\n}\n.tech_docs_table th,\n.tech_docs_table td {\n  padding: 10px;\n  border-left: 2px solid white;\n  border-right: 2px solid white;\n}\n.tech_docs_table .table-row:nth-child(odd) {\n  background-color: #ced7ff;\n  border: 2px solid white;\n}\n\n.tech_docs_table .table-row:nth-child(even) {\n  background-color: #e8ecff;\n  border: 2px solid white;\n}\n.tech_docs_table .download-btn {\n  background: none;\n  border: none;\n  cursor: pointer;\n  font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
