import { XError } from "components/common"
import AuthContext from "context"
import React, { useContext, useEffect, useRef, useState } from "react"
import Loading from "./../../pre-loader/loader"
import Common from "services/commonService"
import RoleServices from "services/roleServices"
import Swal from "sweetalert2"
import { LogoutOnSessionExpire } from "common/helpers"
import { useTranslation } from "react-i18next"
import CloseIcon from "@mui/icons-material/Close"

const CreateDataCenter = (props) => {
  const modalRef = useRef(null)
  const authContext = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(true)
  const [statusData, setStatusData] = useState([])
  const [dataCenterType, setDataCenterType] = useState([])
  const { t } = useTranslation()
  const [modalDC, setModalDC] = useState()
  const [state, setState] = useState({
    name: "",
    country_id: "",
    status: "",
    type: "",
    operator: "",
    address: "",
  })

  const [error, setError] = useState({
    name: "",
    country: "",
    status: "",
    type: "",
    operator: "",
    address: "",
  })

  useEffect(() => {
    var modal = document.getElementById("myModalDC")
    setModalDC(modal)
    // Check if the modal element exists
    if (modal) {
      // Add click event listener to the modal
      modal.addEventListener("click", function (event) {
        // Check if the clicked element is the modal itself (not a child element)
        if (event.target === modal) {
          // Hide the modal by removing the 'show' class
          modal.classList.remove("show")
          // Optionally, you might want to remove the modal element from the DOM
          //modal.remove();
        }
      })
    } else {
      console.error("Modal element not found.")
    }

    RoleServices.getDataCenterType(authContext.getToken).then((res) => {
      setDataCenterType(res.data.data)
      setIsLoading(false)
    })
    // using context for status api
    if (authContext.getStatusData) {
      setStatusData(authContext.getStatusData)
    }

    // Common.status().then((res) => setStatusData(res.data.data))

    setState({
      name: "",
      country_id: "",
      status: "",
      type: "",
      operator: "",
      address: "",
    })

    return () => {
      //setCountries([]);
      //setDataCenters([]);
      setState({})
    }
  }, [props.show])

  const onSubmit = async (data) => {
    // if(props.country ===  0){
    // 	closeModal();
    // 	Swal.fire({
    // 	  icon: 'error',
    // 	  title: 'Oops...',
    // 	  text: 'Please select country',
    // 	})
    // }else{
    setIsLoading(true)
    if (checkValidation()) {
      await RoleServices.createDataCenter(authContext.getToken, state)
        .then((res) => {
          setIsLoading(false)
          res.data.data.country_id = Number(res.data.data.country_id)
          res.data.data.display_order = null

          props.dataCenter.sort((a, b) => {
            return a.display_order - b.display_order
          })
          props.getAllDataCenter()
          //props.selectDataCenterFloor(props.data_center_id);
          //props.setShow(false)
          closeModal()
          Swal.fire(t("datacenter.new_data_center_created"))
        })
        .catch((err) => {
          console.log(err)
          setIsLoading(false)
          LogoutOnSessionExpire(err)
          let error = {
            name: "",
            country: "",
            status: "",
            type: "",
            operator: "",
            address: "",
          }
          const errors = err?.response?.data?.errors

          if (
            errors?.name !== undefined ||
            errors?.name !== "" ||
            errors?.name !== null
          ) {
            error.name = errors.name
          }
          if (
            errors?.country !== "" ||
            errors?.country !== null ||
            errors?.country !== undefined
          ) {
            error.country = errors.country
          }
          if (
            errors?.type !== "" ||
            errors?.type !== null ||
            errors?.type !== undefined
          ) {
            error.type = errors.type
          }
          if (
            errors?.address !== "" ||
            errors?.address !== null ||
            errors?.address !== undefined
          ) {
            error.address = errors.address
          }

          setError({ ...error })
        })
    } else {
      setIsLoading(false)
    }
    //  }
  }

  const checkValidation = () => {
    let error = {
      name: "",
      country: "",
      status: "",
      type: "",
      operator: "",
      address: "",
    }

    const { name, country_id, type, address } = state

    let flag = true

    if (name === "" || name === null || name === undefined) {
      error.name = "The name field is required."
      flag = false
    }

    if (country_id === "" || country_id === null || country_id === undefined) {
      error.country = "The country field is required."
      flag = false
    }

    if (type === "" || type === null || type === undefined) {
      error.type = "The type field is required."
      flag = false
    }

    if (address === "" || address === null || address === undefined) {
      error.address = "The address field is required."
      flag = false
    }

    setError({ ...error })
    return flag
  }

  const closeModal = () => {
    setState({
      name: "",
      country_id: "",
      type: "",
      operator: "",
      address: "",
      status: "",
    })
    setError({
      name: "",
      country: "",
      type: "",
      operator: "",
      address: "",
      status: "",
    })

    modalDC.click()
    //document.getElementById('closeIcon').click()
    //modalRef.current.click()
  }
  return (
    <React.Fragment>
      <Loading
        loading={isLoading}
        background="rgba(0, 0, 0, 0.40)"
        loaderColor="rgb(248, 153, 3)"
      />
      <div
        className="modal fade bd-example-modal-lg2"
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
        id="myModalDC"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header mt-59">
              <h3 className="modal-title">
                {" "}
                {t("datacenter.add_data_center")}{" "}
              </h3>
              <button
                style={{
                  cursor: "pointer",
                  background: "transparent",
                  border: "none",
                }}
                data-bs-dismiss="modal"
                ref={modalRef}
                onClick={() => props.setShow(false)}
              >
                <CloseIcon />
              </button>
            </div>
            <div className="modal-body">
              <div className="card">
                <div
                  className="card-body"
                  style={{ padding: "0px", overflow: "hidden" }}
                >
                  <div className="basic-form">
                    <form>
                      <div className="row">
                        <div className="mb-3 col-md-12 mt-2313">
                          <label className="form-label">
                            {" "}
                            {t("datacenter.name_of_data_center")}{" "}
                            <small className="text-danger">*</small>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={state.name}
                            placeholder={t("datacenter.name_of_data_center")}
                            onChange={(event) =>
                              setState({ ...state, name: event.target.value })
                            }
                          />
                          <XError message={error.name} />
                        </div>
                      </div>

                      <div className="row">
                        <div className="mb-3 col-md-6 mt-2313">
                          <label className="form-label">
                            {" "}
                            {t("datacenter.type")}{" "}
                            <small className="text-danger">*</small>
                          </label>
                          <select
                            onChange={(event) => {
                              setState({
                                ...state,
                                type: event.target.value,
                              })
                            }}
                            className="default-select form-control wide"
                            value={state.type}
                          >
                            <option value="">
                              {t("datacenter.select_type")}
                            </option>
                            {dataCenterType &&
                              dataCenterType.map((type) => {
                                return (
                                  <option value={type.id} key={type.id}>
                                    {type.name}
                                  </option>
                                )
                              })}
                          </select>
                          <XError message={error.type} />
                        </div>
                        <div className="mb-3 col-md-6 mt-2313">
                          <label className="form-label">
                            {" "}
                            {t("datacenter.operator_name")}{" "}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={state.operator}
                            placeholder={t("datacenter.operator_name")}
                            onChange={(event) =>
                              setState({
                                ...state,
                                operator: event.target.value,
                              })
                            }
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="mb-3 col-md-12 mt-2313">
                          <label className="form-label">
                            {" "}
                            {t("datacenter.address")}{" "}
                            <small className="text-danger">*</small>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={state.address}
                            placeholder="Address"
                            onChange={(event) =>
                              setState({
                                ...state,
                                address: event.target.value,
                              })
                            }
                          />
                          <XError message={error.address} />
                        </div>
                      </div>

                      <div className="row">
                        <div className="mb-3 col-md-12 mt-2313">
                          <label className="form-label">
                            {" "}
                            {t("datacenter.country")}{" "}
                            <small className="text-danger">*</small>
                          </label>
                          <select
                            onChange={(event) => {
                              setState({
                                ...state,
                                country_id: event.target.value,
                              })
                            }}
                            className="default-select form-control wide"
                            value={state.country_id}
                          >
                            <option value="">
                              {t("datacenter.select_country")}
                            </option>
                            {props.countryList &&
                              props.countryList.map((country) => {
                                if (country.name !== "Global") {
                                  return (
                                    <option value={country.id} key={country.id}>
                                      {country.name}
                                    </option>
                                  )
                                }
                              })}
                          </select>
                          <XError message={error.country} />
                        </div>
                      </div>
                      <div className="row">
                        <div className="mb-3 col-md-12 mt-2313">
                          <label className="form-label">
                            {" "}
                            {t("datacenter.status")}{" "}
                            <small className="text-danger">*</small>
                          </label>
                          <select
                            onChange={(event) => {
                              setState({
                                ...state,
                                status: event.target.value,
                              })
                            }}
                            className="default-select form-control wide"
                            value={state.status}
                          >
                            {statusData &&
                              statusData.map((status) => {
                                if (status.status_type_id === 1) {
                                  return (
                                    <option value={status.id} key={status.id}>
                                      {status.status_name}
                                    </option>
                                  )
                                }
                              })}
                          </select>
                          <XError message={error.status} />
                        </div>
                      </div>

                      <div
                        className="toolbar toolbar-bottom mt-51 d-flex justify-content-between"
                        role="toolbar"
                      >
                        <div className="delt">
                          {/*<button type="button" className="btn btn-outline-primary mr_1 red_color">
	        	<img src="\images\trash-2.svg" style={{width: "11px", marginTop: "-0.188rem"}} /> 
	        	Delete
	        </button>*/}
                        </div>
                        <div className="usr_cncl">
                          <button
                            type="button"
                            onClick={closeModal}
                            style={{ marginRight: "1rem" }}
                            className="btn btn-outline-primary"
                          >
                            {t("common.cancel")}
                          </button>

                          {isLoading ? (
                            <button type="button" className="btn btn-primary">
                              {" "}
                              {t("common.loading")}...{" "}
                            </button>
                          ) : (
                            <button
                              type="button"
                              onClick={() => onSubmit(props.data_center_id)}
                              className="btn btn-primary"
                            >
                              {" "}
                              {t("common.save")}{" "}
                            </button>
                          )}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default CreateDataCenter
