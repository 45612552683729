// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.op-405 {
    position: relative;
    padding-top: 2rem;
    height: 75vh;
    padding: 12rem 12rem 0rem 12rem;
    padding-left: 53.6rem;
  }
  .card-body {
    overflow: scroll;
  }
  
  .op-405 .main_col {
    display: flex;
    gap: 114px;
    margin-top: 7.1rem;
    margin-left: -7.1rem;
  }
  .op-405 .shape-5 img {
    width: 123.7rem;
    margin-right: 1rem;
    left: -30rem;
    margin-top: -154px;
    position: absolute;
  }`, "",{"version":3,"sources":["webpack://./src/views/super-admin/Dashboard/components/Layoutcss/op-405.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,YAAY;IACZ,+BAA+B;IAC/B,qBAAqB;EACvB;EACA;IACE,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,UAAU;IACV,kBAAkB;IAClB,oBAAoB;EACtB;EACA;IACE,eAAe;IACf,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;EACpB","sourcesContent":[".op-405 {\n    position: relative;\n    padding-top: 2rem;\n    height: 75vh;\n    padding: 12rem 12rem 0rem 12rem;\n    padding-left: 53.6rem;\n  }\n  .card-body {\n    overflow: scroll;\n  }\n  \n  .op-405 .main_col {\n    display: flex;\n    gap: 114px;\n    margin-top: 7.1rem;\n    margin-left: -7.1rem;\n  }\n  .op-405 .shape-5 img {\n    width: 123.7rem;\n    margin-right: 1rem;\n    left: -30rem;\n    margin-top: -154px;\n    position: absolute;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
