// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ticket-date-time-mode-days {
  position: relative;
}
.ticket-date-time-mode-days .rdtPicker {
  position: absolute;
  top: -240px;
}

.ticket-date-time-mode-months {
  position: relative;
}
.ticket-date-time-mode-months .rdtPicker {
  position: absolute;
  top: -190px;
}

.ticket-date-time-mode-years {
  position: relative;
}
.ticket-date-time-mode-years .rdtPicker {
  position: absolute;
  top: -190px;
}

.ticket-date-time-mode-time {
  position: relative;
}
.ticket-date-time-mode-time .rdtPicker {
  position: absolute;
  top: -160px;
}`, "",{"version":3,"sources":["webpack://./src/views/super-admin/Tickets/shared/TicketLabelAndDate/TicketLabelAndDate.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;AAAI;EACI,kBAAA;EACA,WAAA;AAER;;AAEA;EACI,kBAAA;AACJ;AAAI;EACI,kBAAA;EACA,WAAA;AAER;;AAEA;EACI,kBAAA;AACJ;AAAI;EACI,kBAAA;EACA,WAAA;AAER;;AAEA;EACI,kBAAA;AACJ;AAAI;EACI,kBAAA;EACA,WAAA;AAER","sourcesContent":[".ticket-date-time-mode-days {\n    position: relative;\n    .rdtPicker {\n        position: absolute;\n        top: -240px;\n    }\n}\n\n.ticket-date-time-mode-months {\n    position: relative;\n    .rdtPicker {\n        position: absolute;\n        top: -190px;\n    }\n}\n\n.ticket-date-time-mode-years {\n    position: relative;\n    .rdtPicker {\n        position: absolute;\n        top: -190px;\n    }\n}\n\n.ticket-date-time-mode-time {\n    position: relative;\n    .rdtPicker {\n        position: absolute;\n        top: -160px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
