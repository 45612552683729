/* eslint-disable react-hooks/exhaustive-deps */
import { css } from 'glamor';
import { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import TicketButton from 'views/super-admin/Tickets/shared/TicketButton';
import GenericAlertModal from '../GenericAlertModal';
import './ActionButtons.scss';

const styles = {
  icon: {
    marginLeft: '10px'
  },
  row: {
    marginBottom: '10px',
    padding: '5px 20px 5px 20px'
  }
};

const ActionButtons = ({
  setFieldValue,
  active,
  isVisitorFormNotEmpty,
  validationMessage,
  setValidationMessage,
  closeModal
}) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState(null);
  const [handleClose, setHandleClose] = useState(null);

  const openAlertModal = ({ msg, handler }) => {
    setMessage(msg);
    if (handler) {
      setHandleClose(handler);
    }
    setShow(true);
  };

  useEffect(() => {
    if (validationMessage && active) {
      openAlertModal({
        msg: validationMessage,
        handler: () => {
          setValidationMessage(null);
        }
      });
    }
  }, [validationMessage]);

  const handleClick = (id) => {
    setFieldValue('buttonBeingClicked', id);
  };

  const handleSubmit = () => {
    if (isVisitorFormNotEmpty) {
      openAlertModal({ msg: t('create-ticket.site-access.visitor-details-not-saved') });
      handleClick('dismiss');
    } else {
      handleClick('submit');
    }
  };
  return (
    <>
      <Row {...css(styles.row)}>
        <Col>
          {!active && (
            <TicketButton
              label={t('cancel')}
              variant="secondary"
              handleClick={() => handleClick('cancel')}
              width="150px"
              active
              closeModal={closeModal}
              // buttonType="submit"
            />
          )}
        </Col>
        <Col>
          <TicketButton
            label={t('create-ticket.label')}
            variant="primary"
            icon={<i {...css(styles.icon)} className="fa-regular fa-pen-to-square" />}
            handleClick={handleSubmit}
            active={active}
            buttonType="submit"
          />
        </Col>
      </Row>
      <GenericAlertModal show={show} setShow={setShow} message={message} onClose={handleClose} />
    </>
  );
};

const ActionButtonsMemo = ({
  active,
  setFieldValue,
  isVisitorFormNotEmpty,
  validationMessage,
  setValidationMessage,
  closeModal
}) => {
  return useMemo(() => {
    return (
      <ActionButtons
        active={active}
        setFieldValue={setFieldValue}
        isVisitorFormNotEmpty={isVisitorFormNotEmpty}
        validationMessage={validationMessage}
        setValidationMessage={setValidationMessage}
        closeModal={closeModal}
      />
    );
  }, [active, isVisitorFormNotEmpty, validationMessage, closeModal]);
};

export default ActionButtonsMemo;
