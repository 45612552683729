import { isEmpty } from 'lodash';
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

const FavouritesBox = ({
  favourites,
  filter,
  setFilter,
  groupBy,
  setIsGroupBy,
  isSelectedGroup,
  setIsSelectedGroup,
  setGroupBy,
  setPageNumber,
  setSearchValue,
  addFavourite,
  setIsLoading,
  deleteFavourite,
  setState
}) => {
  const { t } = useTranslation();
  const handleChange = (data) => {
    setFilter([]);
    setState([]);
    setGroupBy({});
    setIsGroupBy(false);
    data.filter.forEach((element) => {
      if (element.type === 'group') {
        setIsGroupBy(true);
        setIsSelectedGroup('');
        setGroupBy({
          id: element.id,
          filterName: element.filterName,
          field: element.field,
          type: 'group'
        });
      }
      if (element.type == 'search') {
        let newFilter = {
          id: element.id,
          name: element.name,
          value: element.value,
          operator: element.operator,
          filterName: element.filterName,
          type: 'search'
        };
        setIsSelectedGroup('');
        setFilter((filter) => [...filter, newFilter]);
        setPageNumber(1);
      }
    });
  };
  const handleDelete = (data) => {
    Swal.fire({
      title: t(`ticket.remove_favourite`),
      text: t(`ticket.remove_favourite_text`),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      customClass: {
        cancelButton: "cancelBtn",
        confirmButton: "confirmBtn",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsLoading(true);
        deleteFavourite(data.id);
      }
    });
  };
  const saveFavourite = () => {
    Swal.fire({
      title: t(`ticket.add_to_favourites`),
      text: t(`ticket.add_to_favourites_text`),
      input: 'text',
      icon: 'info',
      showCancelButton: true,
      confirmButtonText: 'Submit',
      customClass: {
        cancelButton: "cancelBtn",
        confirmButton: "confirmBtn",
      },
    }).then((result) => {
      if (result.value) {
        let search_data = [...filter];
        if (!isEmpty(groupBy)) {
          search_data = [...filter, groupBy];
        }
        setIsLoading(true);
        addFavourite(result.value, JSON.stringify(search_data));
      }
    });
  };
  return (
    <React.Fragment>
      <div className="tc_drop1-out d-flex align-items-center">
        <div style={{ width: '100%', cursor: 'pointer' }}>
          <strong>
            <span>
              <div
                className="btn-group"
                role="group"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span>
                  <i className="fa-solid fa-star" style={{ marginRight: '0.5rem' }}></i>
                </span>
                <span>{t(`ticket.favourites`)}</span>
              </div>
              <div className="dropdown-menu">
                <div
                  style={{
                    overflow: 'auto'
                  }}
                >
                  {(!isEmpty(groupBy) || filter.length > 0) && (
                    <div className="group_by_list d-flex justify-content-between">
                      <span onClick={() => saveFavourite()}>
                        {' '}
                        {t(`ticket.save_current_search`)}
                      </span>
                    </div>
                  )}
                  {favourites &&
                    favourites.length == 0 &&
                    !(!isEmpty(groupBy) || filter.length > 0) && (
                      <div className="group_by_list d-flex justify-content-center">
                        <span> {t(`ticket.no_items`)}</span>
                      </div>
                    )}
                  {favourites &&
                    favourites.map((data, i) => {
                      return (
                        <div>
                          <div className="group_by_list d-flex justify-content-between align-items-center">
                            <span
                              style={{
                                textOverflow: 'ellipsis',
                                maxWidth: '12rem',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                marginRight: '0.5rem',
                                width: '100%'
                              }}
                              onClick={() => handleChange(data)}
                            >
                              {' '}
                              {data.name}
                            </span>
                            <span onClick={() => handleDelete(data)}>
                              {' '}
                              <i className="fa-solid fa-trash-can"></i>
                            </span>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </span>
          </strong>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FavouritesBox;
