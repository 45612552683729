const RemoteHandTimesheet = ({ timesheet, details, deleteSheet, permission }) => {
  return (
    <div class="site-table" style={{ width: "90%" }}>
      <table class="table">
        <thead>
          <tr>
            <th scope="col" class="site-tr">
              Date
            </th>
            <th scope="col" class="site-tr">
              Employee
            </th>
            <th scope="col" class="site-tr">
              Description
            </th>
            <th scope="col" class="site-tr">
              Duration (Hours)
            </th>
          </tr>
        </thead>
        <tbody>
          {timesheet &&
            timesheet.map((data, key) => {
              return (
                <tr key={key}>
                  <td class="site-tr">{data?.date || "-"}</td>
                  <td class="site-tr">{data?.employee || "-"}</td>
                  <td class="site-tr">{data?.description || "-"}</td>
                  <td class="site-tr">
                    <div className="duration-inner">
                      <div>{data?.duration_hours || "-"}</div>

                      {
                      permission && (<div>
                        <img
                          src="images/fclose.svg"
                          width={8}
                          alt=""
                          onClick={() => deleteSheet(data.id)}
                        />
                      </div>)
                      }
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>

      <div className="remote-time-total">
        <div className="time-total-innr">
          <div className="HS-txt">Hours Spent:</div>
          <div>{details.total_hours_spent}</div>
        </div>
      </div>
    </div>
  );
};

export default RemoteHandTimesheet;
