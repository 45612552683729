import { css } from 'glamor'
import Modal from 'react-bootstrap/Modal'
import { useTranslation } from 'react-i18next'
import TicketButton from 'views/super-admin/Tickets/shared/TicketButton'

const styles = {
  text: {
    fontSize: '16px',
    fontWeight: '700'
  },
  button: {
    marginRight: '20px !important'
  }
}

const GenericAlertModal = ({ show, setShow, message, onClose }) => {
  const { t } = useTranslation()
  const handleClose = () => {
    setShow(false)
    if (onClose) {
      onClose()
    }
  }

  return (
    <Modal
      show={show}
      centered
      onHide={handleClose}
      backdrop='static'
      keyboard={false}
    >
      <Modal.Header className='mt-3 mb-1' closeButton>
        <Modal.Title>{'   '}</Modal.Title>
      </Modal.Header>
      <Modal.Body
        className='mt-4 pt-4 d-flex justify-content-center'
        {...css(styles.text)}
        style={{ height: '100px' }}
      >
        {message}
      </Modal.Body>
      <Modal.Footer className='d-flex justify-content-center'>
        <div {...css(styles.button)}>
          <TicketButton
            label={t('ok')}
            variant='primary'
            handleClick={handleClose}
            active
          />
        </div>
      </Modal.Footer>
    </Modal>
  )
}

export default GenericAlertModal
