import React from "react"

import "./loader.css"

const Loading = (props) => {
  if (props.loading) {
    return (
      <div>
        <div className="loader-bg">
          <h3>Loading...</h3>
          <div className="loader">
            <span></span>
          </div>
        </div>
      </div>
    )
  }
  return null
}

export default Loading
