import { css } from 'glamor';
import { useState } from 'react';
import { BinIcon, EditUserIcon } from '../../../../shared/Icons';
import { useTranslation } from 'react-i18next';
import ConfirmDeleteModal from 'views/super-admin/Tickets/shared/ConfirmDeleteModal';
import ConfirmEditModal from 'views/super-admin/Tickets/shared/ConfirmEditModal';

const styles = {
  container: {
    border: '1px solid rgba(0, 0, 0, 0.125)',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '10px',
    alignItems: 'center',
    marginBottom: '10px',
    '& > *': {
      marginRight: '12px'
    }
  },
  text: {
    fontSize: '14px'
  }
};

const VisitorRow = ({
  id,
  name,
  handleDelete,
  handleChange,
  handleSave,
  handleEdit,
  isEditMode,
  setIsEditMode
}) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const [edit, setEdit] = useState(false);

  return (
    <>
      <div {...css(styles.container)} key={id}>
        <BinIcon
          onClick={() => {
            setShow(true);
          }}
        />
        <EditUserIcon
          onClick={() => {
            handleChange(id);
          }}
        />
        <div {...css(styles.text)}>{name}</div>
      </div>

      <ConfirmEditModal
        show={isEditMode}
        setShow={setIsEditMode}
        message={t('create-ticket.site-access.visitor-edit')}
        handleClick={() => {
          setIsEditMode(false);
          handleEdit(id);
        }}
        handleClickOk={() => {
          setIsEditMode(false);
          handleSave(id);
        }}
      />

      <ConfirmDeleteModal
        show={show}
        setShow={setShow}
        message={t('create-ticket.site-access.visitor-delete', {
          name: `"${name}"`
        })}
        handleClickOk={() => handleDelete(id)}
      />
    </>
  );
};

export default VisitorRow;
