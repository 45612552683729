// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `          .map_pic {
    margin-top: 1.4rem;
}
.dfin_bg {
    background: #147AD6;
    font-size: 1.063rem;
    color: #fff;
    padding-left: 15px;
}
.box_dfinfo {
    border: 1px solid #147AD6;
    margin-top: 1.4rem;
}
.data_dfi {
    display: flex;
    align-items: center;
    gap: 0rem;
    width: 100%;
}
.data_dfi p {
    flex-basis: 40%;
    padding-left: 15px;
    font-size: 0.875rem;
}
.w_11{
  width: 11%;
}
.w_89{
  width: 89%;
}
.dfinfo i {
    font-size: 15px;
    margin-left: 5px;
    cursor: pointer;
}
@media (max-width: 991px){
  .w_11{
  width: 100% !Important;
}
.w_89{
  width: 100% !important;
}
}`, "",{"version":3,"sources":["webpack://./src/views/super-admin/Dashboard/components/networkStyle.css"],"names":[],"mappings":"UAAU;IACN,kBAAkB;AACtB;AACA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,WAAW;IACX,kBAAkB;AACtB;AACA;IACI,yBAAyB;IACzB,kBAAkB;AACtB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,WAAW;AACf;AACA;IACI,eAAe;IACf,kBAAkB;IAClB,mBAAmB;AACvB;AACA;EACE,UAAU;AACZ;AACA;EACE,UAAU;AACZ;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,eAAe;AACnB;AACA;EACE;EACA,sBAAsB;AACxB;AACA;EACE,sBAAsB;AACxB;AACA","sourcesContent":["          .map_pic {\n    margin-top: 1.4rem;\n}\n.dfin_bg {\n    background: #147AD6;\n    font-size: 1.063rem;\n    color: #fff;\n    padding-left: 15px;\n}\n.box_dfinfo {\n    border: 1px solid #147AD6;\n    margin-top: 1.4rem;\n}\n.data_dfi {\n    display: flex;\n    align-items: center;\n    gap: 0rem;\n    width: 100%;\n}\n.data_dfi p {\n    flex-basis: 40%;\n    padding-left: 15px;\n    font-size: 0.875rem;\n}\n.w_11{\n  width: 11%;\n}\n.w_89{\n  width: 89%;\n}\n.dfinfo i {\n    font-size: 15px;\n    margin-left: 5px;\n    cursor: pointer;\n}\n@media (max-width: 991px){\n  .w_11{\n  width: 100% !Important;\n}\n.w_89{\n  width: 100% !important;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
