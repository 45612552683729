import { axiosWithToken as axios } from "utils/axios";
import { LogoutOnSessionExpire } from "common/helpers";

class Activity {
  async getActivity(token, type, id, tab, next) {
    return await axios(token).get(
      `CountryActivityLog?type=` +
        type +
        "&id=" +
        id +
        "&tab=" +
        tab +
        "&offset=" +
        next
    );
  }
  async getSystemActivity(token, body) {
    return await axios(token).post(`getSystemActivity`, body);
  }
}

export default new Activity();
