// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bcm_total {
  border: 1px solid #00359d;
  text-align: center;
  padding-bottom: 1rem;
  border-radius: 5px 5px 0px 0px;
}
.bcm_total h4 {
  background-color: #00359d;
  border-radius: 4px 4px 0px 0px;
  padding: 0.3rem 0px;
  font-size: 1rem;
  color: #fff;
}
.bcm_total p {
  font-size: 3rem;
  color: #00359d;
  font-weight: 700;
}

.bcm_total_other {
  border: 1px solid #3b7dff;
  text-align: center;
  padding-bottom: 1rem;
  border-radius: 5px 5px 0px 0px;
}
.bcm_total_other h4 {
  background-color: #3b7dff;
  border-radius: 4px 4px 0px 0px;
  padding: 0.3rem 0px;
  font-size: 1rem;
  color: #fff;
}
.bcm_total_other p {
  font-size: 3rem;
  color: #3b7dff;
  font-weight: 700;
}
`, "",{"version":3,"sources":["webpack://./src/views/super-admin/Setting/DataCenter/BCM/bcm.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,oBAAoB;EACpB,8BAA8B;AAChC;AACA;EACE,yBAAyB;EACzB,8BAA8B;EAC9B,mBAAmB;EACnB,eAAe;EACf,WAAW;AACb;AACA;EACE,eAAe;EACf,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,oBAAoB;EACpB,8BAA8B;AAChC;AACA;EACE,yBAAyB;EACzB,8BAA8B;EAC9B,mBAAmB;EACnB,eAAe;EACf,WAAW;AACb;AACA;EACE,eAAe;EACf,cAAc;EACd,gBAAgB;AAClB","sourcesContent":[".bcm_total {\n  border: 1px solid #00359d;\n  text-align: center;\n  padding-bottom: 1rem;\n  border-radius: 5px 5px 0px 0px;\n}\n.bcm_total h4 {\n  background-color: #00359d;\n  border-radius: 4px 4px 0px 0px;\n  padding: 0.3rem 0px;\n  font-size: 1rem;\n  color: #fff;\n}\n.bcm_total p {\n  font-size: 3rem;\n  color: #00359d;\n  font-weight: 700;\n}\n\n.bcm_total_other {\n  border: 1px solid #3b7dff;\n  text-align: center;\n  padding-bottom: 1rem;\n  border-radius: 5px 5px 0px 0px;\n}\n.bcm_total_other h4 {\n  background-color: #3b7dff;\n  border-radius: 4px 4px 0px 0px;\n  padding: 0.3rem 0px;\n  font-size: 1rem;\n  color: #fff;\n}\n.bcm_total_other p {\n  font-size: 3rem;\n  color: #3b7dff;\n  font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
