import React, { useContext, useEffect, useState } from "react";
import "../techDocs.css";
import DownloadIcon from "@mui/icons-material/Download";
import ListIcon from "@mui/icons-material/List";
import AuthContext from "context";
import moment from "moment";
import SerachBox from "views/super-admin/Contacts/searchBox";
import Select from "react-select";
import Pagination from "views/super-admin/Ticket/components/shared/usePagination";

const Table = ({
  selectedType,
  files,
  types,
  handleDownload,
  total,
  setPageNumber,
  setItemsPerPage,
  setIsLoading,
  pageNumber,
  query,
  setQuery,
  selectedTypeId,
  setSelectedTypeId,
  generalTypes,
  setSelectedType,
}) => {
  const customStylesForSelect = {
    control: (provided, state) => ({
      ...provided,
      // Change border color based on focus state
      border: state.isFocused ? "1px solid #fe8600" : "1px solid #eeeeee", // Change colors as needed
      "&:hover": {
        border: state.isFocused ? "1px solid #fe8600" : "1px solid #eeeeee", // Change hover color if needed
      },
      boxShadow: null,
      height: "1.75rem !important",
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: "1.063rem",
      fontWeight: 400,
      color: "#8392A5",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: "1.063rem",
      fontWeight: 400,
      color: "#8392A5",
    }),
    option: (provided) => ({
      ...provided,
      fontSize: "1.063rem",
      fontWeight: 400,
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    menuList: (provided) => ({
      ...provided,
      maxHeight: "18rem",
    }),
  };

  const contextStore = useContext(AuthContext);
  const [options, setOptions] = useState([]);
  const getNameOfType = (id) => {
    if (types && types.length > 0) {
      const type = types.filter((el) => el.id == id);
      if (type.length > 0) {
        return type[0].name;
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const searchCategory = [
    { name: "File Name", field: "file_name" },
    { name: "Uploaded By", field: "uploaded_by" },
  ];
  const convertDate = (date) => {
    if (
      contextStore?.getAuth &&
      contextStore?.getAuth?.timezone &&
      contextStore?.getAuth?.timezone?.timezone &&
      contextStore?.getAuth?.timezone?.timezone_prefix
    ) {
      const hongKongTime = moment.tz(date, "Asia/Hong_Kong");
      return hongKongTime
        .clone()
        .tz(contextStore?.getAuth?.timezone?.timezone)
        .format("DD MMM YYYY");
    } else {
      return moment(date).tz("Asia/Hong_Kong").format("DD MMM YYYY");
    }
  };
  const formatOptions = (data) => {
    return data.map((item) => ({ value: item.id, label: item.name }));
  };

  useEffect(() => {
    if (generalTypes && generalTypes.length > 1) {
      setOptions([{ id: 0, name: "All Types" }, ...generalTypes]);
    }
  }, [generalTypes]);
  return (
    <div
      className="table-container tech_docs_table"
      style={{ padding: "1rem 3rem" }}
    >
      {selectedType && selectedType.name === "Summary" && (
        <h1 style={{ color: "#171C3C", fontSize: "1rem", fontWeight: "bold" }}>
          Latest Uploded Documents
        </h1>
      )}
      {selectedType && selectedType.name === "File List" && (
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <div style={{ fontSize: "1rem" }}>Select Type : &nbsp;</div>
            <div style={{ width: "20rem" }}>
              {" "}
              <Select
                styles={customStylesForSelect}
                options={formatOptions(options)}
                value={formatOptions(options).filter(
                  (el) => el.value == selectedTypeId
                )}
                onChange={(option) => {
                  setSelectedTypeId(option.value);
                }}
                placeholder={""}
                menuPlacement="auto"
              />
            </div>
          </div>
          <div className="d-flex align-items-center">
            <div>Filter : &nbsp;</div>
            <SerachBox
              searchCategory={searchCategory}
              query={query}
              setQuery={setQuery}
              setIsLoading={setIsLoading}
              setPageNumber={setPageNumber}
              setItemsPerPage={setItemsPerPage}
            ></SerachBox>
          </div>
        </div>
      )}

      <table style={{ marginTop: "1rem" }}>
        <thead>
          <tr>
            <th style={{ width: "5%" }}>Action</th>
            {selectedType && selectedType.id === 0 && (
              <th style={{ width: "15%" }}>Type</th>
            )}
            <th style={{ width: "30%" }}>File Name</th>
            <th style={{ width: "10%" }}>Uploaded By</th>
            <th style={{ width: "10%" }}>Uploaded Date</th>
            <th style={{ width: "15%" }}>Notes</th>
          </tr>
        </thead>
        <tbody>
          {files.map((item, index) => (
            <tr key={index} className="table-row">
              <td>
                <DownloadIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleDownload(item?.file_path, item?.file_name);
                  }}
                ></DownloadIcon>
              </td>

              {selectedType && selectedType.id === 0 && (
                <td>
                  {(selectedTypeId === 0 || selectedTypeId === "") && (
                    <ListIcon
                      style={{ marginRight: "0.5rem" }}
                      onClick={() => {
                        setSelectedType({ name: "File List", id: 0 });
                        setSelectedTypeId(item.type_id);
                      }}
                    ></ListIcon>
                  )}
                  {getNameOfType(item.type_id)}
                </td>
              )}
              <td>{item.file_name}</td>
              <td>{item.uploaded_by}</td>
              <td>{convertDate(item.createdAt)}</td>
              <td>{item.notes}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="d-flex justify-content-end mt-3">
        {files && files.length > 0 && (
          <Pagination
            setItemsPerPage={setItemsPerPage}
            setPageNumber={setPageNumber}
            total={total}
            pageNumber={pageNumber}
          ></Pagination>
        )}
      </div>
    </div>
  );
};

export default Table;
