const PlusIcon = ({ handleClick }) => {
  return (
    <div>
      <i
        className='fa fa-2x fa-user-plus'
        style={{
          position: 'relative',
          left: '10px',
          cursor: 'pointer'
        }}
        onClick={handleClick}
      />
    </div>
  )
}

export default PlusIcon
