import AuthContext from 'context';
import { useContext, useEffect, useState } from 'react';
import CreateTicketService from 'services/createTicketService';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
const FAULT_TICKET_TYPE = 2;

const useGetTicketTypes = (setLoading) => {
  const authContext = useContext(AuthContext);
  const { t } = useTranslation();

  const [ticketTypes, setTicketTypes] = useState([]);

  const getTicketTypes = async () => {
    const getTicketTypesDto = await CreateTicketService.getTicketTypes(authContext.token());
    setTicketTypes(
      _.orderBy(getTicketTypesDto.data?.data, ['TicketSubTypeName'], ['asc'])
        .filter((type) => type.ticket_types_id === FAULT_TICKET_TYPE)
        .map((type) => {
          const { TicketSubTypeName, TicketSubTypeID } = type;
          return {
            ...type,
            label: t(`create-ticket.fault-ticket.${TicketSubTypeName.split(' ').join('_')}`),
            value: TicketSubTypeID
          };
        })
    );
  };

  useEffect(() => {
    getTicketTypes();
  }, []);

  return {
    ticketTypes
  };
};

export default useGetTicketTypes;
