import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./dragAndDrop.css";
import { useEffect, useState, useContext } from "react";
import AuthContext from "context";
import Swal from "sweetalert2";
import DragAndDrop from "./dragAndDrop";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import ticketService from "services/ticketService";
import RefreshIcon from "@mui/icons-material/Refresh";

const DraftDescription = ({
  isEdit,
  checkPermission,
  ticketId,
  setIsLoading,
  setErrorMsg,
  setErrorPopup,
  setUpdateTicketDetails,
  ticketDescription,
  handleUpdateDescription,
  updateTicketDetails,
  isUpdateDescriptionList,
  setIsUpdateDescriptionList,
  ticketStatus,
  setDraftDescription,
}) => {
  const [isDraftEdit, setIsDraftEdit] = useState(false);
  const [isAppendTicket, setIsAppendTicket] = useState(false);
  const { t } = useTranslation();
  const [isPreview, setIsPreview] = useState(false);
  const [isCollapse, setIsCollapse] = useState(true);
  const [descriptionList, setDescriptionList] = useState([]);
  const [notAppendedDescriptionList, setNotAppendedDescriptionList] = useState(
    []
  );
  const contextStore = useContext(AuthContext);
  const [description, setDescription] = useState("");
  useEffect(() => {
    setDraftDescription(description.trim());
  }, [description]);
  useEffect(() => {
    if (isEdit) {
      setIsCollapse(true);
      setIsDraftEdit(false);
    }
  }, [isEdit]);
  useEffect(() => {
    getDraftDescriptionList();
  }, []);
  useEffect(() => {
    if (isUpdateDescriptionList) {
      updateDraftDescriptionOrder(descriptionList, "isAppendUpdate=true");
      setIsUpdateDescriptionList(false);
    }
  }, [isUpdateDescriptionList]);

  useEffect(() => {
    if (isAppendTicket) {
      handleUpdateDescription();
      setIsAppendTicket(false);
    }
  }, [isAppendTicket]);

  useEffect(() => {
    const data = descriptionList.filter((el) => el.is_appended == 0);
    setNotAppendedDescriptionList(data);
  }, [descriptionList]);
  const getDraftDescriptionList = async () => {
    setIsLoading(true);
    await ticketService
      .getDraftDescriptionListByTicketId(
        localStorage.getItem("token"),
        ticketId
      )
      .then((res) => {
        setDescriptionList(res.data.data.sort((a, b) => a.order - b.order));
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const addDraftDescription = async () => {
    if (description.trim() != "") {
      setIsLoading(true);
      const body = {
        ticket_id: ticketId,
        description: description,
        order: descriptionList.length + 1,
      };
      await ticketService
        .addDraftDescription(localStorage.getItem("token"), body)
        .then((res) => {
          setDescription("");
          getDraftDescriptionList();
        })
        .catch((err) => {
          console.log(err.error.response);
          setErrorMsg(err.error.response.data.error);
          setErrorPopup(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const deleteDraftDescription = async (id) => {
    setIsLoading(true);
    await ticketService
      .deleteDraftDescription(localStorage.getItem("token"), id)
      .then((res) => {
        getDraftDescriptionList();
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateDraftDescriptionOrder = async (body, query) => {
    setIsLoading(true);
    await ticketService
      .updateDraftDescriptionBulk(localStorage.getItem("token"), query, body)
      .then((res) => {
        getDraftDescriptionList();
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleDelete = (id) => {
    Swal.fire({
      text: "Are you sure to delete this Draft Description?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      customClass: {
        cancelButton: "cancelBtn",
        confirmButton: "confirmBtn",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        deleteDraftDescription(id);
      }
    });
  };

  const handleUpdate = () => {
    Swal.fire({
      text: "Are you sure to append all to Description?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      customClass: {
        cancelButton: "cancelBtn",
        confirmButton: "confirmBtn",
      },
    }).then((result) => {
      setIsPreview(false);
      if (result.isConfirmed) {
        let finalDescription = ticketDescription;
        descriptionList.forEach((data) => {
          finalDescription = finalDescription + "\n" + data.description;
        });
        setUpdateTicketDetails((prev) => ({
          ...prev,
          description: finalDescription,
        }));
        setIsAppendTicket(true);
      }
    });
  };

  return (
    <>
      <div className="row mb-3">
        <div class="col-md-10">
          <Accordion
            disabled={isEdit}
            style={{
              border: isDraftEdit ? "2px solid #ff7a00" : "1px solid #707070",
              boxShadow: "none",
            }}
            expanded={!isCollapse}
          >
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  onClick={() => {
                    setIsCollapse(!isCollapse);
                    setIsDraftEdit(false);
                    setIsPreview(false);
                    setDescription("");
                  }}
                />
              }
              aria-controls="panel1-content"
              id="panel1-header"
              sx={{ fontWeight: "medium", fontSize: 14 }}
            >
              <div
                className="d-flex justify-content-between align-items-center"
                style={{ width: "95%" }}
              >
                <div>
                  {" "}
                  Draft Description{" "}
                  {!isCollapse && (
                    <RefreshIcon
                      style={{ cursor: "pointer", marginLeft: "1rem" }}
                      onClick={() => getDraftDescriptionList()}
                      fontSize="large"
                      data-html2canvas-ignore="true"
                    />
                  )}
                </div>

                {isDraftEdit &&
                  !isCollapse &&
                  descriptionList &&
                  descriptionList.length > 0 && (
                    <div>
                      <div
                        class="form-check form-switch d-flex align-items-center"
                        style={{ paddingLeft: "0px", marginBottom: "0rem" }}
                      >
                        <label
                          className="form-label"
                          style={{
                            float: "left",
                            marginRight: "1rem",
                            marginBottom: "0rem",
                          }}
                        >
                          Preview
                        </label>
                        <label
                          className="switch"
                          style={{ marginBottom: "0rem" }}
                        >
                          <input
                            type="checkbox"
                            onChange={(event) => {
                              setIsPreview(event.target.checked);
                              setDescription("");
                            }}
                            checked={isPreview}
                            id="flexSwitchCheckDefault"
                          />

                          <div className="slider round">
                            <span className="on" style={{ fontSize: "10px" }}>
                              ON
                            </span>
                            <span className="off">{t("ticket.off")}</span>
                          </div>
                        </label>
                      </div>
                    </div>
                  )}
              </div>
            </AccordionSummary>
            <AccordionDetails>
              {isDraftEdit && !isPreview && (
                <div className="d-flex align-items-center mb-3">
                  <div style={{ width: "90%" }}>
                    <textarea
                      rows="2"
                      placeholder="Add new line of description"
                      className="form-control"
                      style={{
                        height: "100%",
                        resize: "none",
                      }}
                      onChange={(e) => {
                        setDescription(
                          e.target.value.replace(/(<([^>]+)>)/gi, "")
                        );
                      }}
                      value={description}
                    />
                  </div>
                  <div style={{ width: "10%" }}>
                    <IconButton
                      disabled={description.trim() == ""}
                      edge="end"
                      style={{
                        marginLeft: "0.5rem",
                        opacity: description.trim() == "" ? "0.2" : "1",
                      }}
                      onClick={() => {
                        addDraftDescription();
                      }}
                    >
                      <AddIcon
                        style={{
                          fontSize: "2.5rem",
                          fontWeight: "bolder",
                          color: "black",
                        }}
                      />
                    </IconButton>
                  </div>
                </div>
              )}
              {!isPreview && (
                <DragAndDrop
                  isDraftEdit={isDraftEdit}
                  data={descriptionList}
                  handleDelete={handleDelete}
                  handleUpdateOrder={updateDraftDescriptionOrder}
                  ticketStatus={ticketStatus}
                ></DragAndDrop>
              )}
              {isPreview && (
                <p
                  class="input-style2"
                  id="descriptionBox"
                  style={{
                    maxWidth: "100%",
                    marginBottom: "1rem",
                    wordBreak: "break-all",
                  }}
                >
                  <div
                    style={{
                      fontSize: "1.03rem",
                      color: "#8392A5",
                      whiteSpace: "pre-wrap ",
                    }}
                  >
                    {ticketDescription}
                  </div>
                  {descriptionList &&
                    descriptionList.length > 0 &&
                    descriptionList.map((data) => {
                      return (
                        <div
                          style={{
                            fontSize: "1.03rem",
                            color: "#8392A5",
                            whiteSpace: "pre-wrap ",
                          }}
                        >
                          {data.description}
                        </div>
                      );
                    })}
                </p>
              )}

              <div className="d-flex justify-content-end">
                {isDraftEdit && (
                  <div>
                    {descriptionList && descriptionList.length > 0 && (
                      <button
                        class="btn me-3 btn-sm save-btn-new"
                        onClick={() => {
                          handleUpdate();
                          setDescription("");
                        }}
                        style={{ minWidth: "14rem" }}
                      >
                        {isPreview
                          ? "Append to Description"
                          : "Append all to Description"}
                      </button>
                    )}

                    <button
                      class="btn me-3 btn-sm discard-btn-new"
                      onClick={() => {
                        setDescription("");
                        if (isPreview) {
                          setIsPreview(false);
                        } else {
                          setIsDraftEdit(false);
                          setIsPreview(false);
                        }
                      }}
                    >
                      Close
                    </button>
                  </div>
                )}
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
        <div className="col-md-2 d-flex">
          {checkPermission("Draft") &&
            checkPermission("Maintenance Status") &&
            !isDraftEdit && (
              <button
                class="btn me-3 btn-sm assign-btn-new"
                disabled={isEdit}
                style={{ opacity: isEdit ? "0.4" : "1" }}
                onClick={() => {
                  setIsDraftEdit(true);
                  setIsCollapse(false);
                  setDescription("");
                }}
              >
                Draft
              </button>
            )}
        </div>
      </div>
    </>
  );
};
export default DraftDescription;
