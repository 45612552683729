import React, { useState, useEffect } from 'react';

const SidePop = ({ setSidePopVal, SidePopVal, setSidePop, menu, isSave, setIsSave }) => {
  const [state, setState] = useState({ ...SidePopVal });
  const [filteredOptions, setFilteredOptions] = useState([]);
  const Options = [
    { id: 1, name: 'Ticket', field: 'id' },
    { id: 2, name: 'Subject', field: 'subject' },
    { id: 3, name: 'Ticket Type', field: 'tickettype' },
    { id: 4, name: 'Site', field: 'operatingsite' },
    { id: 5, name: 'Severity', field: 'priority' },
    { id: 6, name: 'Creation Time', field: 'create_date' },
    { id: 18, name: 'Close Date', field: 'close_date' },
    { id: 7, name: 'Status', field: 'ticket_status' },
    { id: 8, name: 'Customer', field: 'company' },
    { id: 9, name: 'Site Visit Date Start', field: 'visit_start_date' },
    { id: 10, name: 'Site Visit Date End', field: 'visit_end_date' },
    { id: 11, name: 'Company', field: 'designated_company' },
    { id: 12, name: 'Readiness', field: 'readiness' },
    { id: 13, name: 'Assignee', field: 'assignee' },
    { id: 14, name: 'Visitors', field: 'visitors' },
    { id: 15, name: 'Description', field: 'description' },
    { id: 19, name: 'Portal Code', field: 'portal_code' },
    { id: 16, name: 'Space ID', field: 'spaces_display' },
    { id: 17, name: 'Room ID', field: 'visiting_rooms_display' }
  ];

  const setAll = () => {
    const obj = state;
    for (let key in obj) {
      obj[key] = true;
    }
    // console.log(obj);
    setState(obj);
  };
  useEffect(() => {
    if (menu == 0 || menu == 4) {
      setFilteredOptions(Options);
    }
    if (menu === 1) {
      const x = Options;
      const filteredOptions = x.filter(
        (el) =>
          el.name !== 'Site Visit Date Start' &&
          el.name !== 'Site Visit Date End' &&
          el.name !== 'Visitors' &&
          el.name !== 'Company' &&
          el.name !== 'Portal Code' &&
          el.name !== 'Space ID' &&
          el.name !== 'Room ID'
      );
      setFilteredOptions(filteredOptions);
    }
    if (menu === 2 || menu === 3 || menu === 5) {
      const x = Options;
      const filteredOptions = x.filter(
        (el) =>
          el.name !== 'Site Visit Date Start' &&
          el.name !== 'Site Visit Date End' &&
          el.name !== 'Visitors' &&
          el.name !== 'Room ID'
      );
      setFilteredOptions(filteredOptions);
    }
  }, [menu]);
  return (
    <>
      <div className="sidePop" style={{ zIndex: '10' }}>
        {filteredOptions.map((data, i) => {
          return (
            <div key={i}>
              <div className="d-flex gap-2">
                <div>
                  <input
                    type="checkbox"
                    style={{ cursor: 'pointer' }}
                    onChange={() =>
                      setState((prev) => ({ ...prev, [data.field]: !prev[data.field] }))
                    }
                    checked={state[data.field]}
                  />
                </div>
                <div>{data.name}</div>
              </div>
            </div>
          );
        })}

        <div className="side-buttons d-flex gap-2">
          <div>
            <button className="btn-app" onClick={() => setAll()}>
              Select All
            </button>
          </div>
          <div>
            <button className="btn-can" onClick={() => setSidePop(false)}>
              Cancel
            </button>
          </div>
          <div>
            <button
              className="btn-app"
              onClick={() => {
                setSidePopVal({ ...state });
                setSidePop(false);
                setIsSave(true);
              }}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default SidePop;
