// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings-cross-connect-add-button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  min-width: 100px;
}

.settings-cross-connect-link-add {
  width: 150px;
}

`, "",{"version":3,"sources":["webpack://./src/components/common/AddEntilty/AddEntity.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".settings-cross-connect-add-button {\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  min-width: 100px;\n}\n\n.settings-cross-connect-link-add {\n  width: 150px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
