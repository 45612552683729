// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-cabinet-nav {
  border: 1px solid #E7E8E9;
  padding: 0px !important;	
}

.newCab{
  border: 2px solid green!important;
}`, "",{"version":3,"sources":["webpack://./src/views/super-admin/Dashboard/components/CabinetOrBreaker/CabinetOrBreaker.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,uBAAuB;AACzB;;AAEA;EACE,iCAAiC;AACnC","sourcesContent":[".dashboard-cabinet-nav {\n  border: 1px solid #E7E8E9;\n  padding: 0px !important;\t\n}\n\n.newCab{\n  border: 2px solid green!important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
