import i18n from "i18next";
import { initReactI18next } from "react-i18next";


// Importing translation files

import translationEN from "./locales/en/translation.json";
import translationFIL from "./locales/fil/translation.json";
import translationID from "./locales/id/translation.json";
import translationJA from "./locales/ja/translation.json";
import translationKO from "./locales/ko/translation.json";
import translationZH from "./locales/zh/translation.json";
import translationZHCHT from "./locales/zh-CHT/translation.json";


//Creating object with the variables of imported translation files
const resources = {
    en: {
        translation: translationEN,
    },
    fil: {
        translation: translationFIL,
    },
    id: {
        translation: translationID,
    },
    ja: {
        translation: translationJA,
    },
    ko: {
        translation: translationKO,
    },
    zh: {
        translation: translationZH,
    },
    'zh-CHT': {
        translation: translationZHCHT,
    },
};

//i18N Initialization

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng:"en", //default language
    fallbackLng: "en",
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
    },
});

export default i18n;