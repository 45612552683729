import AuthContext from 'context'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import createTicketService from 'services/createTicketService'

import Swal from 'sweetalert2'

const useGetTeams = () => {
  const authContext = useContext(AuthContext)
  const [teams, setTeams] = useState([])
  const { t } = useTranslation()
  const getHelpdeskTeam = async () => {
    try {
      const data = await createTicketService.getHelpDeskTeam(authContext.token())
      setTeams(data)
    } catch (err) {
      await Swal.fire(t('error'), err.message)
    }
  }

  useEffect(() => {
    getHelpdeskTeam()
  }, [])

  return {
    teams
  }
}

export default useGetTeams
