import { Available, Blocked, Complete, InService, Reserved, ROFR } from 'components/ColorTile';
import AuthContext from 'context';
import { useContext, useState } from 'react';
import Floors from 'services/floorServices';
import CabinetServices from '../../../../services/CabinetService';
import dataHallServices from 'services/dataHallServices';
import EditCabinet from '../../Setting/DataCenter/editCabinet';
import CabinetPopup from './Cabinet-Popup';
import RoomTable from './Room-Table';
import { LogoutOnSessionExpire } from 'common/helpers';
import './Room-Card.css';
import { useTranslation } from 'react-i18next';
const RoomCard = ({
  selectedFloor,
  selectedDataCenter,
  hallSort,
  setHallSort,
  getCabinetsData,
  htmldata,
  setIsLoading
}) => {
  const authContext = useContext(AuthContext);
  const [showPopup, setShowPopup] = useState('');
  const [show, setShow] = useState(false);
  const [roomName, setRoomName] = useState('');
  // const [dataCenter, setDataCenter] = useState([]);
  const [currentDataCenter, setCurrentDataCenter] = useState([]);
  const [allFloorData, setAllFloorData] = useState([]);
  // const [room, setRoom] = useState([]);
  const [selectedRoom, setselectedRoom] = useState({});
  // const [cabinets, setCabinets] = useState([]);
  const [floorIndex, setFloorIndex] = useState(0);
  // const [editFloorData, setEditFloorData] = useState();
  // const [showFloorEdit, setShowFloorEdit] = useState(false);
  // const [editRoom, setEditRoom] = useState();
  // const [showRoomEdit, setShowRoomEdit] = useState(false);
  const [editCabinets, setEditCabinets] = useState();
  const [showCabinetsEdit, setShowCabinetsEdit] = useState(false);
  const { t } = useTranslation();
  const [cabinetData, setCabinetData] = useState([]);
  const [temperatureData, setTemperatureData] = useState([]);
  const [powerBreakerData, setPowerBreakerData] = useState([]);
  const [mapData, setMapData] = useState([]);
  const [isCabinetDataLoading, setIsCabinetDataLoading] = useState(false);
  const [isCabinetInfoDataLoading, setIsCabinetInfoDataLoading] = useState(true);

  const [isTemperaturDataLoading, setIsTemeratureDataLoading] = useState(true);
  const [isPowerBreakerDataLoading, setIsPowerBreakerDataLoading] = useState(true);
  const [isMapDataLoading, setIsMapDataLoading] = useState(false);
  const [activateTab, setActivateTab] = useState('lay');

  // const [countryName, setCountryName] = useState("Country");
  // const [currentTab, setCurrentTab] = useState(0);
  // const [activeTab, setActiveTab] = useState();
  // const [country, setCountry] = useState(0);
  // const initialMount = useRef(true);
  // const [ascending, setAscending] = useState(true);
  // const [roomAscending, setRoomAscending] = useState(true);
  // const [cabinetAscending, setCabinetAscending] = useState(true);
  // const [isReadOnly, setIsReadOnly] = useState(true);
  // const [statusData, setStatusData] = useState([]);

  const getCabinetData = async (id, name, status = false) => {
    setRoomName(name);
    setIsLoading(true);
    setIsCabinetDataLoading(true);
    await CabinetServices.selectByHallId(authContext.token(), {
      room_id: id,
      status: status
    })
      .then((res) => {
        setShowPopup(res.data);
        setIsCabinetDataLoading(false);
        setShow(true);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsCabinetDataLoading(false);
        LogoutOnSessionExpire(err);
      });
    getCabinetInfo(name);
    getTemparatureData(name);
    getPowerBreakerData(name);
  };

  const getTemparatureData = async (name) => {
    setIsTemeratureDataLoading(true);
    await CabinetServices.getCabinetInfo(name, 'temperature')
      .then((res) => {
        let groupedData = {};

        res.data.data.forEach((item) => {
          if (groupedData.hasOwnProperty(item.cabinet_name)) {
            groupedData[item.cabinet_name].push(item);
          } else {
            groupedData[item.cabinet_name] = [item];
          }
        });

        setTemperatureData(groupedData);
        setIsTemeratureDataLoading(false);
      })
      .catch((err) => {
        setIsTemeratureDataLoading(false);
        LogoutOnSessionExpire(err);
      });
  };

  const getPowerBreakerData = async (name) => {
    setIsPowerBreakerDataLoading(true);
    await CabinetServices.getCabinetInfo(name, 'power')
      .then((res) => {
        let groupedData = {};
        res.data.data.forEach((item) => {
          if (groupedData[item.cabinet_name]) {
            groupedData[item.cabinet_name].push(item);
          } else {
            groupedData[item.cabinet_name] = [item];
          }
        });

        setPowerBreakerData(groupedData);
        setIsPowerBreakerDataLoading(false);
      })
      .catch((err) => {
        setIsPowerBreakerDataLoading(false);
        LogoutOnSessionExpire(err);
      });
  };

  const getCabinetInfo = async (name) => {
    setIsCabinetInfoDataLoading(true);
    await CabinetServices.getCabinetInfo(name, 'cabinet')
      .then((res) => {
        setCabinetData(res.data.data);
        setIsCabinetInfoDataLoading(false);
      })
      .catch((err) => {
        setIsCabinetInfoDataLoading(false);
        LogoutOnSessionExpire(err);
      });
  };

  const getHeatMapData = async (name) => {
    setIsMapDataLoading(true);
    await dataHallServices
      .getHeatMapData(localStorage.getItem('token'), name)
      .then((res) => {
        setMapData(res.data);
        setShow(true); // showing data only when the data is loaded from the api
        setIsLoading(false); // setting loader false when we close it
        setIsMapDataLoading(false);
      })
      .catch((err) => {
        setIsMapDataLoading(false);
        LogoutOnSessionExpire(err);
      });
  };
  const getCabinetsDataEdit = async (e) => {
    // setCabinetAscending(true);

    setselectedRoom(e);
    await CabinetServices.selectByHallId(authContext.token(), {
      room_id: e.id
    })
      .then((res) => {
        //console.log(res.data.data)
        //	if(res.data.data.length > 0){
        // setCabinets(res.data.data.sort((a, b) => (a.name < b.name ? 1 : -1)));
        //	}
      })
      .catch((err) => LogoutOnSessionExpire(err));
  };

  const selectDataCenterFloor = async (e, floor_id = 0) => {
    authContext.setNetworkDataCenter(e);
    // setAscending(true);
    setCurrentDataCenter(e);

    await Floors.floorByDataCenterId(authContext.token(), e)
      .then((res) => {
        if (res.data.data.length > 0) {
          setAllFloorData(res.data.data);
          // setRoom(res.data.data[floor_id].rooms);
          if (res.data.data[floor_id].rooms.length > 0) {
            getCabinetsDataEdit(res.data.data[floor_id].rooms[0]);
          }

          setFloorIndex(floor_id);
          // setActiveTab(floor_id);
        } else {
          setFloorIndex(null);
        }
      })
      .catch((err) => LogoutOnSessionExpire(err));
  };

  return (
    <>
      <div className="col-lg-7 col-md-12 col-sm-12 col-12 dashboard-room-card">
        <div className="grid_card h-100">
          <div className="card_head">
            <div className="txt_card">
              <h3>{t('dashboard.rooms')}</h3>
            </div>

            <div className="txt_card_2">
              <InService />
              <Available />
              <Reserved />
              <ROFR />
              <Blocked />
              <Complete />
            </div>
          </div>
          <div
            className="card-body"
            style={{ overflowX: 'hidden', overflowY: 'auto', height: '200px' }}
          >
            <div className="table-responsive">
              <RoomTable
                getCabinetsData={getCabinetsData}
                hallSort={hallSort}
                setHallSort={setHallSort}
                selectedFloor={selectedFloor}
                getCabinetData={getCabinetData}
                selectedDataCenter={selectedDataCenter}
                htmldata={htmldata}
                setActivateTab={setActivateTab}
              />
            </div>
          </div>
        </div>
        {show && (
          <CabinetPopup
            show={show}
            data={showPopup}
            setShow={setShow}
            roomName={roomName}
            cabinetData={cabinetData}
            temperatureData={temperatureData}
            powerBreakerData={powerBreakerData}
            mapData={mapData}
            getHeatMapData={getHeatMapData}
            selectedFloor={selectedFloor}
            selectedDataCenter={selectedDataCenter}
            setShowCabinetsEdit={setShowCabinetsEdit}
            setEditCabinets={setEditCabinets}
            isCabinetDataLoading={isCabinetDataLoading}
            isTemperaturDataLoading={isTemperaturDataLoading}
            isPowerBreakerDataLoading={isPowerBreakerDataLoading}
            isMapDataLoading={isMapDataLoading}
            isCabinetInfoDataLoading={isCabinetInfoDataLoading}
            activateTab={activateTab}
            htmldata={htmldata}
          />
        )}
        {showCabinetsEdit && (
          <EditCabinet
            room={allFloorData[floorIndex]}
            show={showCabinetsEdit}
            data_center_id={currentDataCenter}
            selectDataCenterFloor={selectDataCenterFloor}
            floorIndex={floorIndex}
            setFloorIndex={setFloorIndex}
            editCabinets={editCabinets}
            setShow={setShowCabinetsEdit}
            getCabinetsData={getCabinetsDataEdit}
            selectedRoom={selectedRoom}
            showCabinetPopUp={() => {
              setShow(true);
            }}
          />
        )}
      </div>
    </>
  );
};

export default RoomCard;
