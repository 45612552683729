import { useTranslation } from 'react-i18next';
import TicketAccordion from '../../shared/TicketAccordion';

const Description = ({ value, setFieldValue, active }) => {
  const { t } = useTranslation();
  const handleChange = (e) => {
    let strippedString = e.target.value.replace(/(<([^>]+)>)/gi, '');
    setFieldValue('description', strippedString);
  };

  return (
    <TicketAccordion
      label={t('create-ticket.site-access.description')}
      active={active}
      valid={value.length > 0}
    >
      <textarea
        class="form-control"
        rows="5"
        style={{ height: '100%' }}
        onChange={handleChange}
        value={value}
      />
    </TicketAccordion>
  );
};

export default Description;
