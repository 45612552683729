import { css } from 'glamor'

const styles = {
  container: {
    display: 'flex',
    // marginBottom: '10px',
    fontSize: '14px'
  }
}

const DetailsListRowReadOnly = ({ id, index, name, weight, size }) => {
  return (
    <>
      <tr {...css(styles.container)}>
        <td width='10%'>{index + 1}</td>
        <td width='40%'>{name}</td>
        <td width='20%'>{weight}</td>
        <td width='30%'>{size}</td>
      </tr>
    </>

  )
}

export default DetailsListRowReadOnly
