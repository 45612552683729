import AuthContext from "context";
import { useContext, useEffect, useState } from "react";
import createTicketService from "services/createTicketService";

const Impacted = (siteId) => {
  const authContext = useContext(AuthContext);
  const [devices, setDevices] = useState([]);
  const [isImpactedDeviceLoading, setIsImpactedDeviceLoading] = useState(false);
  const getDevice = async () => {
    try {
      setIsImpactedDeviceLoading(true);
      const result = await createTicketService.getDevices(
        authContext.token(),
        siteId
      );
      let finalResult = result.data.map((data) => {
        const { id, name } = data;
        return { label: name, value: id };
      });
      setDevices(finalResult);
      setIsImpactedDeviceLoading(false);
    } catch (e) {
      console.log(e);
      setIsImpactedDeviceLoading(false);
    }
  };

  useEffect(() => {
    if (siteId) {
      getDevice();
    }
  }, [siteId]);

  return { devices, isImpactedDeviceLoading };
};

export default Impacted;
