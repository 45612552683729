import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Navigation = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const activeClass = (route) => {
    if (location.pathname === route) {
      return "plink active";
    }
    if (typeof route === "object") {
      let classType = "plink";
      route.map((r) => {
        if (location.pathname === r) {
          classType = "plink active";
        }
      });

      return classType;
    }
    return "plink";
  };
  // const location = useLocation();
  // const contextData = React.useContext(StorageContext);

  // const activeClass = (route) => {

  // 	if(typeof route === 'string'){

  // 		if (location.pathname === route) {
  // 			return "nav-link active";
  // 		}
  // 	}

  // 	if(typeof route === 'object'){

  // 		let classType = "nav-link";
  // 		route.map(r => {
  // 			if(location.pathname === r){
  // 				classType = "nav-link active";
  // 			}
  // 		})

  // 		return classType;
  // 	}

  // 	return "nav-link";
  // }
  return (
    <div className="col-xl-1">
      <div className="leftside">
        <p>
          <Link
            className={activeClass("/setting-data-center")}
            to="/setting-data-center"
          >
            {t("datacenter.inventory")}{" "}
          </Link>
        </p>
        <p>
          <Link
            className={activeClass("/setting-power-distibution")}
            to="/setting-power-distibution"
          >
            {t("datacenter.pwr_dist")}{" "}
          </Link>
        </p>
        <p>
          <Link
            className={activeClass("/setting-cross-connect")}
            to="/setting-cross-connect"
          >
            {t("datacenter.x_connect")}{" "}
          </Link>
        </p>
        <p>
          <Link
            className={activeClass(["/setting-capacity", "/capacity-layout"])}
            to="/setting-capacity"
          >
            {t("datacenter.capacity")}{" "}
          </Link>
        </p>
        <p>
          <Link className={activeClass("/setting-bcm")} to="/setting-bcm">
            {t("datacenter.bcm")}{" "}
          </Link>
        </p>
      </div>
    </div>
  );
};

export default Navigation;
