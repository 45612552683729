/* eslint-disable default-case */
import AuthContext from "context";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DoughnutChartCard } from "@digitaledgedc/deuikit";
import { ticketType } from "./shared/data";
import { Grid } from "@mui/material";

const Card = ({ cardData, state, menu, setMenu, checkPermission }) => {
  const [incidentData, setIncidentData] = useState(null);
  const [faultData, setFaultData] = useState(null);
  const [shipmentData, setShipmentData] = useState(null);
  const [siteVisitData, setSiteVisitData] = useState(null);
  const [remoteHandData, setRemoteHandData] = useState(null);

  const { t } = useTranslation();
  const contextStore = useContext(AuthContext);

  useEffect(() => {
    getIncident();
    getFaults();
    getShipment();
    getSiteVisit();
    getRemoteHands();
  }, [state]);

  const getIncident = () => {
    let p0 = 0;
    let p1 = 0;
    let p2 = 0;
    let p3 = 0;
    let i = 0;
    let e = 0;
    let total = 0;

    state &&
      state.map((data) => {
        const result = data.incident.result;
        total = data.incident.total;
        if (result.P0) {
          p0 += result.P0;
        }

        if (result.P1) {
          p1 += result.P1;
        }

        if (result.P2) {
          p2 += result.P2;
        }

        if (result.P3) {
          p3 += result.P3;
        }

        if (result.I1 || result.I2 || result.I3) {
          i +=
            (result.I1 ? result.I1 : 0) +
            (result.I2 ? result.I2 : 0) +
            (result.I3 ? result.I3 : 0);
        }

        if (result.E1 || result.E2 || result.E3) {
          e +=
            (result.E1 ? result.E1 : 0) +
            (result.E2 ? result.E2 : 0) +
            (result.E3 ? result.E3 : 0);
        }
      });

    setIncidentData({
      types: [
        {
          id: "1",
          title: "P0",
          value: p0,
          color: "#e03138",
        },
        {
          id: "2",
          title: "P1",
          value: p1,
          color: "#f78600",
        },
        {
          id: "3",
          title: "P2",
          value: p2,
          color: "#f2dc34",
        },
        {
          id: "4",
          title: "P3",
          value: p3,
          color: "#40ff00",
        },
        {
          id: "5",
          title: "I",
          value: i,
          color: "#1b70c0",
        },
        {
          id: "5",
          title: "E",
          value: e,
          color: "#c2adc2",
        },
      ],
      totalNumber: total,
    });
  };
  const getFaults = () => {
    let s1 = 0;
    let s2 = 0;
    let s3 = 0;
    let s4 = 0;
    let total = 0;
    state &&
      state.map((data) => {
        const result = data.fault.result;
        total = data.fault.total;
        if (result["S-1"]) {
          s1 += result["S-1"];
        }

        if (result["S-2"]) {
          s2 += result["S-2"];
        }

        if (result["S-3"]) {
          s3 += result["S-3"];
        }
        if (result["S-4"]) {
          s4 += result["S-4"];
        }
      });

    setFaultData({
      types: [
        {
          id: "1",
          title: "S1",
          value: s1,
          color: "#e03138",
        },
        {
          id: "2",
          title: "S2",
          value: s2,
          color: "#f78600",
        },
        {
          id: "3",
          title: "S3",
          value: s3,
          color: "#f2dc34",
        },
        {
          id: "4",
          title: "S4",
          value: s4,
          color: "#40ff00",
        },
      ],
      totalNumber: total,
    });
  };

  const getShipment = () => {
    let s1 = 0;
    let s2 = 0;
    let s3 = 0;
    let s4 = 0;
    let total = 0;
    state &&
      state.map((data) => {
        const result = data.shipments.result;
        total = data.shipments.total;
        if (result["S-1"]) {
          s1 += result["S-1"];
        }

        if (result["S-2"]) {
          s2 += result["S-2"];
        }

        if (result["S-3"]) {
          s3 += result["S-3"];
        }
        if (result["S-4"]) {
          s4 += result["S-4"];
        }
      });

    setShipmentData({
      types: [
        {
          id: "1",
          title: "S1",
          value: s1,
          color: "#e03138",
        },
        {
          id: "2",
          title: "S2",
          value: s2,
          color: "#f78600",
        },
        {
          id: "3",
          title: "S3",
          value: s3,
          color: "#f2dc34",
        },
        {
          id: "4",
          title: "S4",
          value: s4,
          color: "#40ff00",
        },
      ],
      totalNumber: total,
    });
  };
  const getSiteVisit = () => {
    let s1 = 0;
    let s2 = 0;
    let s3 = 0;
    let s4 = 0;
    let total = 0;
    state &&
      state.map((data) => {
        const result = data.site_access.result;
        total = data.site_access.total;

        if (result["S-1"]) {
          s1 += result["S-1"];
        }

        if (result["S-2"]) {
          s2 += result["S-2"];
        }

        if (result["S-3"]) {
          s3 += result["S-3"];
        }
        if (result["S-4"]) {
          s4 += result["S-4"];
        }
      });

    setSiteVisitData({
      types: [
        {
          id: "1",
          title: "S1",
          value: s1,
          color: "#e03138",
        },
        {
          id: "2",
          title: "S2",
          value: s2,
          color: "#f78600",
        },
        {
          id: "3",
          title: "S3",
          value: s3,
          color: "#f2dc34",
        },
        {
          id: "4",
          title: "S4",
          value: s4,
          color: "#40ff00",
        },
      ],
      totalNumber: total,
    });
  };

  const getRemoteHands = () => {
    let s1 = 0;
    let s2 = 0;
    let s3 = 0;
    let s4 = 0;
    let total = 0;
    state &&
      state.map((data) => {
        const result = data["remote-hands"].result;
        total = data["remote-hands"].total;
        if (result["S-1"]) {
          s1 += result["S-1"];
        }

        if (result["S-2"]) {
          s2 += result["S-2"];
        }

        if (result["S-3"]) {
          s3 += result["S-3"];
        }
        if (result["S-4"]) {
          s4 += result["S-4"];
        }
      });

    setRemoteHandData({
      types: [
        {
          id: "1",
          title: "S1",
          value: s1,
          color: "#e03138",
        },
        {
          id: "2",
          title: "S2",
          value: s2,
          color: "#f78600",
        },
        {
          id: "3",
          title: "S3",
          value: s3,
          color: "#f2dc34",
        },
        {
          id: "4",
          title: "S4",
          value: s4,
          color: "#40ff00",
        },
      ],
      totalNumber: total,
    });
  };

  const handlePieClick = (ticketTypeName, ticketNumber) => {
    const permission = checkPermission(ticketNumber);
    if (permission) {
      setMenu(ticketNumber);
    }
  };

  return (
    <Grid
      container
      spacing={1}
      justifyContent="start"
      style={{ marginTop: "0px" }}
    >
      {[
        {
          data: incidentData,
          title: "Incidents",
          ticketType: 1,
          ticketTypeName: "Incidents",
        },
        {
          data: faultData,
          title: "Faults",
          ticketType: 2,
          ticketTypeName: "Faults",
        },
        {
          data: siteVisitData,
          title: "Site Visits",
          ticketType: 4,
          ticketTypeName: "Site Visits",
        },
        {
          data: shipmentData,
          title: "Shipments",
          ticketType: 3,
          ticketTypeName: "Shipment",
        },
        {
          data: remoteHandData,
          title: "Remote Hands",
          ticketType: 5,
          ticketTypeName: "Remote Hands",
        },
      ]
        .filter((item) => checkPermission(item.ticketType))
        .map((item, index) => (
          <Grid key={index} item xs={12} sm={6} md={2.4} lg={2.4} xl={2.4}>
            <DoughnutChartCard
              graphData={
                item.data != null
                  ? item.data
                  : {
                      totalNumber: 0,
                      types: [],
                    }
              }
              title={item.title}
              loading={false}
              size="sm"
              titleStyle={{
                fontSize: "0.813rem",
              }}
              legendTextStyle={{
                fontSize: "0.438rem",
              }}
              onClick={() => {
                handlePieClick(item.ticketTypeName, item.ticketType);
              }}
              maxLengthFadeOut={15}
            />
          </Grid>
        ))}
    </Grid>
  );
};

export default Card;
