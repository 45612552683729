import { LogoutOnSessionExpire } from "common/helpers";
import { myBase64Image } from "components/common/getImage";
import AuthContext from "context";
import saveAs from "file-saver";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import ESGService from "services/esgServices";
import Layout from "../Layouts";
import Loading from "../pre-loader/loader";
import "./style.css";
import { useTranslation } from "react-i18next";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import StarIcon from "@mui/icons-material/Star";
import Swal from "sweetalert2";
import UserService from "services/userServices";
import { useLocation } from "react-router-dom";

const ExcelJS = require("exceljs");

const ESG = () => {
  // const [selectedDataCenter, selectDataCenter] = useState("");
  const contextStore = useContext(AuthContext);
  const [state, setState] = useState([]);
  const [month, setMonth] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [year, setYear] = useState("");
  const [countryName, setCountryName] = useState([]);
  const { t } = useTranslation();
  const location = useLocation();
  const [defaultPage, setDefaultPage] = useState(null);
  const [oneTime, setOneTime] = useState(true);


  useEffect(() => {
    setMonth(Number(contextStore.getMonthYear.month));
    setYear(contextStore.getMonthYear.year);
  }, [contextStore.getMonthYear]);

  useEffect(() => {
    if (month != "" && year != "" && oneTime) {
      getPower();
      setOneTime(false);
    }
  }, [month, year]);

  const getPower = () => {
    setIsLoading(true)
    ESGService.index(contextStore.token(), {
      month: month ? month : contextStore.getMonthYear.month,
      year: year ? year : contextStore.getMonthYear.year,
    })
      .then((res) => {
        res.data.data.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });
        let stateData = [];
        let countryName = [];
        res.data.data.forEach((data) => {
          countryName.push({ name: data.name, total: data.data_center.length });
          data.data_center.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            }
            if (a.name > b.name) {
              return 1;
            }
            return 0;
          });
        });
        res.data.data.map((data) =>
          data.data_center.map((data_center) => stateData.push(data_center))
        );
        setCountryName(countryName);
        setState(stateData);
      })
      .catch((err) => {
        LogoutOnSessionExpire(err);
      })
      .finally(() => setIsLoading(false));
  };

  const renderMonth = () => {
    let months = [];

    for (let i = 1; i <= 12; i++) {
      months.push(moment(i, "M").format("MMM"));
    }
    return months.map((m, key) => (
      <option value={key + 1} key={key}>
        {m}
      </option>
    ));
  };
  const renderYear = () => {
    let years = [];

    for (let i = 2022; i <= moment().format("YYYY"); i++) {
      years.push(moment(i, "YYYY").format("YYYY"));
    }
    return years.map((y, key) => (
      <option value={y} key={y}>
        {y}
      </option>
    ));
  };
  const extractValue = (data) => {
    if (data === undefined || data === null) {
      return "-";
    } else {
      return data;
    }
  };

  const convertNumbertoAlpha = (column) => {
    var temp,
      letter = "";
    while (column > 0) {
      temp = (column - 1) % 26;
      letter = String.fromCharCode(temp + 65) + letter;
      column = (column - temp - 1) / 26;
    }
    return letter;
    //return (num + 9).toString(36).toUpperCase()
  };

  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("My Sheet");
    console.log(worksheet.id);
    let row = 5;
    for (let i = 0; i < row; i++) {
      worksheet.addRow([]);
    }

    worksheet.getColumn("A").width = 50;
    let topHeader = ["Country"];
    let header = ["Data center/Site"];
    let serviceAvailable = ["Service Availability"];
    let noOfIncident = ["   Number of Incidents"];
    let typeOfIncident = ["   Type of Incidents"];
    let customerImapcted = ["   Customers Impacted"];
    let totalServiceDowntime = ["   Total Service Downtime(mins)"];
    let numSecurityIncident = ["   Number of security incidents"];
    let securityTypeIncident = ["   Type of Incidents"];
    let securityImpacted = ["   Who is impacted"];
    let numEhsincident = ["   Number of EHS incidents"];
    let ehsTypeincident = ["   Type of incidents"];
    let ehsImpacted = ["   Who is impacted"];
    let operationPue = ["Operating PUE"];
    let designedPue = ["Design PUE"];
    let installedIt = ["Installed IT capacity (KVA)"];
    let operatingIt = ["Operating IT consumption(KVA)"];

    countryName.map((data) => topHeader.push(data.name));

    state &&
      state.forEach((data) => {
        header.push(data.name);
        serviceAvailable.push(
          extractValue(data?.data_center_performance?.availability)
        );
        noOfIncident.push(
          extractValue(data?.data_center_performance?.infra_incident_num)
        );
        typeOfIncident.push(
          extractValue(data?.data_center_performance?.infra_incident_type)
        );
        console.log(data?.data_center_performance?.infra_incidents);
        // customerImapcted.push(['Customers Impacted', extractValue(infra.impact)])
        let infra_incident = [];
        data?.data_center_performance?.infra_incidents.length &&
          data?.data_center_performance.infra_incidents.forEach((infra) => {
            infra_incident.push(infra.impact);
          });
        customerImapcted.push(infra_incident.join(","));
        totalServiceDowntime.push("N/A");

        numSecurityIncident.push(
          extractValue(data?.data_center_performance?.security_incident_num)
        );
        securityTypeIncident.push(
          extractValue(data?.data_center_performance?.security_incident_type)
        );
        let security_incident = [];
        data?.data_center_performance?.security_incidents.length &&
          data?.data_center_performance?.security_incidents.forEach(
            (security) => {
              security_incident.push(security.impact);
            }
          );
        securityImpacted.push(security_incident.join(","));

        numEhsincident.push(
          extractValue(data?.data_center_performance?.ehs_incident_num)
        );
        ehsTypeincident.push(
          extractValue(data?.data_center_performance?.infra_incident_type)
        );
        let ehs_impacted = [];
        data?.data_center_performance?.ehs_incidents.length &&
          data?.data_center_performance?.ehs_incidents.forEach((ehs) => {
            ehs_impacted.push(ehs.impact);
          });
        ehsImpacted.push(ehs_impacted.join(","));

        operationPue.push(
          extractValue(data?.data_center_performance?.opertating_pue)
        );
        designedPue.push(
          extractValue(data?.data_center_performance?.design_pue)
        );
        installedIt.push(
          extractValue(data?.data_center_performance?.installed_kw)
        );
        operatingIt.push(
          extractValue(data?.data_center_performance?.operating_kw)
        );
      });
    worksheet.getCell("A6").value = "Country";
    // worksheet.getColumn(1).font = {bold: true}
    let startPoint = convertNumbertoAlpha(2);
    let total = 2;
    countryName.map((data) => {
      total = total + data.total;
      let newCursor = convertNumbertoAlpha(total - 1);
      worksheet.mergeCells(
        startPoint + (row + 1) + ":" + newCursor + (row + 1)
      );
      worksheet.getCell(startPoint + (row + 1)).value = data.name;
      startPoint = convertNumbertoAlpha(total);
    });

    //worksheet.addRow(topHeader);

    worksheet.getRow(row + 1).font = { bold: true };
    worksheet.addRow(header);
    worksheet.getRow(row + 2).font = { bold: true };
    worksheet.addRow(serviceAvailable);
    worksheet.getRow(row + 3).font = { bold: true };
    worksheet.addRow(["Infrastructure Incident"]);
    worksheet.getRow(row + 4).font = { bold: true };
    worksheet.addRow(noOfIncident);
    worksheet.addRow(typeOfIncident);
    worksheet.addRow(customerImapcted);
    worksheet.addRow(totalServiceDowntime);
    worksheet.addRow(["Security Indcident"]);
    worksheet.getRow(row + 9).font = { bold: true };
    worksheet.addRow(numSecurityIncident);
    worksheet.addRow(securityTypeIncident);
    worksheet.addRow(securityImpacted);
    worksheet.addRow(["Environment, Health & safety (EHS) incident"]);
    worksheet.getRow(row + 13).font = { bold: true };
    worksheet.addRow(numEhsincident);
    worksheet.addRow(ehsTypeincident);
    worksheet.addRow(ehsImpacted);
    worksheet.addRow(operationPue);
    worksheet.addRow(designedPue);
    worksheet.addRow(installedIt);
    worksheet.addRow(operatingIt);
    worksheet.getRow(row + 17).font = { bold: true };
    worksheet.getRow(row + 18).font = { bold: true };
    worksheet.getRow(row + 19).font = { bold: true };
    worksheet.getRow(row + 20).font = { bold: true };

    worksheet.eachRow(function (row, rowNumber) {
      row.eachCell(function (cell, colNumber) {
        cell.alignment = { vertical: "middle", horizontal: "left" };
      });
    });

    const imageId2 = workbook.addImage({
      base64: myBase64Image,
      extension: "png",
    });

    worksheet.addImage(imageId2, {
      tl: { col: 2, row: 0 },
      ext: { width: 300, height: 100 },
    });
    workbook.xlsx.writeBuffer().then(function (buffer) {
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        `ESG-${year}_${month}.xlsx`
      );
    });
  };
  const handleDefaultPage = () => {
    const text = "ESG will be set as default page.";
    Swal.fire({
      title: t("common.are_you_sure"),
      text: text,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      customClass: {
        cancelButton: "cancelBtn",
        confirmButton: "confirmBtn",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        updateDefaultPage(location.pathname);
      }
    });
  };

  const updateDefaultPage = (page) => {
    UserService.updateDefaultPage(contextStore.token(), { default_page: page })
      .then((res) => {
        setDefaultPage(page);
      })
      .catch((err) => {
        LogoutOnSessionExpire(err);
      });
  };

  useEffect(() => {
    setDefaultPage(contextStore.getAuth.default_page);
  }, [contextStore.getAuth.default_page]);
  const getTitle = () => {
    if (location.pathname == defaultPage) {
      if (location.pathname == "/data-center") {
        return "Data Center is set as default page";
      }
      if (location.pathname == "/esg") {
        return "ESG is set as default page";
      }
      if (location.pathname == "/ticket") {
        return "Tickets is set as default page";
      }
      if (location.pathname == "/provisioning") {
        return "Provisioning is set as default page";
      }
    } else {
      return "Set as default page";
    }
  };
  return (
    <React.Fragment>
      <Loading
        loading={isLoading}
        background="rgba(0, 0, 0, 0.40)"
        loaderColor="rgb(248, 153, 3)"
      />

      <Layout>
        <div className="main_sec_esg">
          <div className="container my-3 main_esg">
            <div className="row g-3">
              <div className="col-12 col-sm-7">
                <div className="txt_esg_top">
                  <h3>{t("esg.data_center_performance")}</h3>
                  <p>
                    {moment(month, "M").format("MMM")} {year}
                  </p>
                </div>
              </div>
              <div className="col-12 col-sm-5">
                {month != "" && year != "" && (
                  <div className="left_box_month">
                    <div className="choose_date">
                      <select
                        className="form-select w-3rem"
                        aria-label="Default select example"
                        onChange={(event) => {
                          setMonth(event.target.value);
                        }}
                        defaultValue={month}
                      >
                        {renderMonth()}
                      </select>
                    </div>
                    <div className="choose_date">
                      <select
                        className="form-select w-3rem"
                        aria-label="Default select example"
                        onChange={(event) => {
                          setYear(event.target.value);
                        }}
                        defaultValue={year}
                      >
                        {renderYear()}
                      </select>
                    </div>
                    <div className="btn_go_esg">
                      <button className="btn btn-secondary" onClick={getPower}>
                        {t("esg.go")}
                      </button>
                    </div>
                    <div className="excel_icon">
                      <span onClick={downloadExcel}>
                        <img src="images/excel.png" width="25%" alt="" />
                        <span>{t("esg.export_to_excel")}</span>
                      </span>
                    </div>
                    <div>
                      <Tooltip
                        title={
                          <span style={{ fontSize: "1rem" }}>{getTitle()}</span>
                        }
                        TransitionComponent={Zoom}
                        placement="left"
                        arrow
                      >
                        <IconButton
                          edge="end"
                          style={{ marginRight: "1rem" }}
                          onClick={
                            defaultPage == location.pathname
                              ? ""
                              : handleDefaultPage
                          }
                        >
                          {defaultPage == location.pathname ? (
                            <StarIcon style={{ color: "#FE8600" }} />
                          ) : (
                            <StarBorderIcon style={{ color: "black" }} />
                          )}
                        </IconButton>{" "}
                      </Tooltip>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="data_esg_tb mt-4 mb-5">
          <div className="container">
            <div className="tb_st_esg">
              <table className="table table-borderless esg_exprt mb-5">
                <thead>
                  <tr>
                    <th
                      style={{
                        fontSize: "1rem",
                        color: "#92979A",
                        width: "43%",
                      }}
                    >
                      {t("esg.country")} :
                    </th>

                    {countryName &&
                      countryName.map((data) => {
                        return (
                          <th
                            key={data.name}
                            colSpan={data.total}
                            style={{
                              fontSize: "1rem",
                              color: "#92979A",
                              textAlign: "left",
                            }}
                          >
                            {data.name}
                          </th>
                        );
                      })}
                  </tr>
                  <tr>
                    <th
                      style={{
                        fontSize: "1rem",
                        color: "#92979A",
                        width: "43%",
                      }}
                    >
                      {t("esg.data_center_site")} :
                    </th>

                    {state &&
                      state.map((data) => {
                        return (
                          <th
                            key={data.name}
                            style={{
                              fontSize: "1rem",
                              color: "#92979A",
                              textAlign: "left",
                            }}
                          >
                            {data.name}
                          </th>
                        );
                      })}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      style={{
                        fontSize: "0.875rem",
                        color: "#0E0E0E",
                        fontWeight: 500,
                      }}
                    >
                      {t("esg.service_availability")} :
                    </td>
                    {state &&
                      state.map((data) => {
                        return (
                          <td
                            key={data.id}
                            style={{
                              fontSize: "0.875rem",
                              color: "#418DC8",
                              textAlign: "left",
                            }}
                          >
                            {extractValue(
                              data?.data_center_performance?.availability
                            )}
                          </td>
                        );
                      })}
                  </tr>
                  <tr>
                    <td className="bg_font">
                      {t("esg.infrastructure_incident")}
                    </td>
                    {state && state.map((data) => <td key={data.id}></td>)}
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontSize: "0.875rem",
                        color: "#0E0E0E",
                        fontWeight: 500,
                        position: "relative",
                        left: "10px",
                      }}
                    >
                      {t("esg.number_of_incidents")}
                    </td>
                    {state &&
                      state.map((data) => {
                        return (
                          <td
                            key={data.id}
                            style={{
                              fontSize: "0.875rem",
                              color: "#0E0E0E",
                              textAlign: "left",
                            }}
                          >
                            {extractValue(
                              data?.data_center_performance?.infra_incident_num
                            )}
                          </td>
                        );
                      })}
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontSize: "0.875rem",
                        color: "#0E0E0E",
                        fontWeight: 500,
                        position: "relative",
                        left: "10px",
                      }}
                    >
                      {t("esg.type_of_incidents")}
                    </td>
                    {state &&
                      state.map((data) => {
                        return (
                          <td
                            key={data.id}
                            style={{
                              fontSize: "0.875rem",
                              color: "#0E0E0E",
                              textAlign: "left",
                            }}
                          >
                            {extractValue(
                              data?.data_center_performance?.infra_incident_type
                            )}
                          </td>
                        );
                      })}
                  </tr>
                  <tr>
                    <td
                      className="valign"
                      valign="top"
                      style={{
                        fontSize: "0.875rem",
                        color: "#0E0E0E",
                        fontWeight: 500,
                        position: "relative",
                        left: "10px",
                        verticalAlign: "text-top",
                      }}
                    >
                      {t("esg.customers_impacted")}
                    </td>
                    {state &&
                      state.map((data) => {
                        return (
                          <td
                            key={data.id}
                            style={{
                              fontSize: "0.875rem",
                              color: "#0E0E0E",
                              textAlign: "left",
                            }}
                          >
                            {data?.data_center_performance?.infra_incidents
                              .length &&
                              data?.data_center_performance.infra_incidents.map(
                                (infra) => (
                                  <p key={infra.id}>
                                    {extractValue(infra.impact)}
                                  </p>
                                )
                              )}
                          </td>
                        );
                      })}
                  </tr>

                  <tr>
                    <td
                      style={{
                        fontSize: "0.875rem",
                        color: "#0E0E0E",
                        fontWeight: 500,
                        position: "relative",
                        left: "10px",
                      }}
                    >
                      {t("esg.total_service_downtime_mins")}
                    </td>
                    {state &&
                      state.map((data) => {
                        return (
                          <td
                            key={data.id}
                            style={{
                              fontSize: "0.875rem",
                              color: "#0E0E0E",
                              textAlign: "left",
                            }}
                          >
                            N/A
                          </td>
                        );
                      })}
                  </tr>
                  <tr>
                    <td className="bg_font">{t("esg.security_indcident")}</td>
                    {state && state.map((data) => <td key={data.id}></td>)}
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontSize: "0.875rem",
                        color: "#0E0E0E",
                        fontWeight: 500,
                        position: "relative",
                        left: "10px",
                      }}
                    >
                      {t("esg.number_of_security_incidents")}
                    </td>
                    {state &&
                      state.map((data) => {
                        return (
                          <td
                            key={data.id}
                            style={{
                              fontSize: "0.875rem",
                              color: "#0E0E0E",
                              textAlign: "left",
                            }}
                          >
                            {extractValue(
                              data?.data_center_performance
                                ?.security_incident_num
                            )}
                          </td>
                        );
                      })}
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontSize: "0.875rem",
                        color: "#0E0E0E",
                        fontWeight: 500,
                        position: "relative",
                        left: "10px",
                      }}
                    >
                      {t("esg.type_of_incidents")}
                    </td>
                    {state &&
                      state.map((data) => {
                        return (
                          <td
                            key={data.id}
                            style={{
                              fontSize: "0.875rem",
                              color: "#0E0E0E",
                              textAlign: "left",
                            }}
                          >
                            {extractValue(
                              data?.data_center_performance
                                ?.security_incident_type
                            )}
                          </td>
                        );
                      })}
                  </tr>
                  <tr>
                    <td
                      className="valign"
                      style={{
                        fontSize: "0.875rem",
                        color: "#0E0E0E",
                        fontWeight: 500,
                        position: "relative",
                        left: "10px",
                        verticalAlign: "text-top",
                      }}
                    >
                      {t("esg.who_is_impacted")}
                    </td>
                    {state &&
                      state.map((data) => {
                        return (
                          <td
                            key={data.id}
                            style={{
                              fontSize: "0.875rem",
                              color: "#0E0E0E",
                              textAlign: "left",
                            }}
                          >
                            {data?.data_center_performance?.security_incidents
                              .length &&
                              data?.data_center_performance?.security_incidents.map(
                                (security) => (
                                  <p key={security.id}>
                                    {extractValue(security.impact)}
                                  </p>
                                )
                              )}
                          </td>
                        );
                      })}
                  </tr>
                  <tr>
                    <td className="bg_font">
                      {t("esg.environment_health_and_safety_incident")}
                    </td>
                    {state && state.map((data) => <td key={data.id}></td>)}
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontSize: "0.875rem",
                        color: "#0E0E0E",
                        fontWeight: 500,
                        position: "relative",
                        left: "10px",
                      }}
                    >
                      {t("esg.number_of_ehs_incidents")}
                    </td>
                    {state &&
                      state.map((data) => {
                        return (
                          <td
                            key={data.id}
                            style={{
                              fontSize: "0.875rem",
                              color: "#0E0E0E",
                              textAlign: "left",
                            }}
                          >
                            {extractValue(
                              data?.data_center_performance?.ehs_incident_num
                            )}
                          </td>
                        );
                      })}
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontSize: "0.875rem",
                        color: "#0E0E0E",
                        fontWeight: 500,
                        position: "relative",
                        left: "10px",
                      }}
                    >
                      {t("esg.type_of_incidents")}
                    </td>
                    {state &&
                      state.map((data) => {
                        return (
                          <td
                            key={data.id}
                            style={{
                              fontSize: "0.875rem",
                              color: "#0E0E0E",
                              textAlign: "left",
                            }}
                          >
                            {extractValue(
                              data?.data_center_performance?.ehs_incident_type
                            )}
                          </td>
                        );
                      })}
                  </tr>
                  <tr>
                    <td
                      className="valign"
                      style={{
                        fontSize: "0.875rem",
                        color: "#0E0E0E",
                        fontWeight: 500,
                        position: "relative",
                        left: "10px",
                        verticalAlign: "text-top",
                      }}
                    >
                      {t("esg.who_is_impacted")}
                    </td>
                    {state &&
                      state.map((data) => {
                        return (
                          <td
                            key={data.id}
                            style={{
                              fontSize: "0.875rem",
                              color: "#0E0E0E",
                              textAlign: "left",
                            }}
                          >
                            {data?.data_center_performance?.ehs_incidents
                              .length &&
                              data?.data_center_performance?.ehs_incidents.map(
                                (ehs) => (
                                  <p key={ehs.id}>{extractValue(ehs.impact)}</p>
                                )
                              )}
                          </td>
                        );
                      })}
                  </tr>

                  <tr>
                    <td
                      className="bg_font"
                      style={{
                        fontSize: "0.875rem",
                        color: "#0E0E0E",
                        fontWeight: 500,
                      }}
                    >
                      {t("esg.operating_pue")}
                    </td>
                    {state &&
                      state.map((data) => {
                        return (
                          <td
                            key={data.id}
                            style={{
                              fontSize: "0.875rem",
                              color: "#0E0E0E",
                              textAlign: "left",
                            }}
                          >
                            {extractValue(
                              data?.data_center_performance?.opertating_pue
                            )}
                          </td>
                        );
                      })}
                  </tr>
                  <tr>
                    <td
                      className="bg_font"
                      style={{
                        fontSize: "0.875rem",
                        color: "#0E0E0E",
                        fontWeight: 500,
                      }}
                    >
                      {t("esg.design_pue")}
                    </td>
                    {state &&
                      state.map((data) => {
                        return (
                          <td
                            key={data.id}
                            style={{
                              fontSize: "0.875rem",
                              color: "#0E0E0E",
                              textAlign: "left",
                            }}
                          >
                            {extractValue(
                              data?.data_center_performance?.design_pue
                            )}
                          </td>
                        );
                      })}
                  </tr>

                  <tr>
                    <td
                      className="bg_font"
                      style={{
                        fontSize: "0.875rem",
                        color: "#0E0E0E",
                        fontWeight: 500,
                      }}
                    >
                      {t("esg.installed_it_capacity")}
                    </td>
                    {state &&
                      state.map((data) => {
                        return (
                          <td
                            key={data.id}
                            style={{
                              fontSize: "0.875rem",
                              color: "#0E0E0E",
                              textAlign: "left",
                            }}
                          >
                            {extractValue(
                              data?.data_center_performance?.installed_kw
                            )}
                          </td>
                        );
                      })}
                  </tr>
                  <tr>
                    <td
                      className="bg_font"
                      style={{
                        fontSize: "0.875rem",
                        color: "#0E0E0E",
                        fontWeight: 500,
                      }}
                    >
                      {t("esg.operating_it_consumption")}
                    </td>
                    {state &&
                      state.map((data) => {
                        return (
                          <td
                            key={data.id}
                            style={{
                              fontSize: "0.875rem",
                              color: "#0E0E0E",
                              textAlign: "left",
                            }}
                          >
                            {extractValue(
                              data?.data_center_performance?.operating_kw
                            )}
                          </td>
                        );
                      })}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default ESG;
