import Table from './table';
import { useTranslation } from 'react-i18next';
const SubTasks = (props) => {
  const { t } = useTranslation();
  const columns = [
    { name: 'name', value: t('provisioning.sub_tasks_table.name') },
    { name: 'assignee', value: t('provisioning.sub_tasks_table.assignee') },
    { name: 'status', value: t('provisioning.sub_tasks_table.status') }
  ];
  return (
    <>
      {' '}
      {props?.data?.length > 0 && (
        <Table
          data={props.data}
          currentServiceId={props.currentServiceId}
          setCurrentServiceId={props.setCurrentServiceId}
          refreshData={props.refreshData}
          setIsLoading={props.setIsLoading}
          columns={columns}
        ></Table>
      )}
    </>
  );
};

export default SubTasks;
