import { validateEmail } from "common/helpers";
import {
	XAlert, XError
} from 'components/common';
import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { forgotPassword } from 'services/auth';
import Layout from '../Layouts';
import { LogoutOnSessionExpire } from 'common/helpers';
import Loading from "views/super-admin/pre-loader/loader";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
const ForgotPassword = () => {
	const { t, i18n } = useTranslation();
	const [email,setEmail] = useState("");
	const [error,setError] = useState("");
	const [isLoading,setIsLoading] = useState(true);
	const [message,setMessage] = useState({
		type:"",
		message:""
	});
	 
	useEffect(() => {
		const timer = setTimeout(() => {
				setIsLoading(false);
			 }, 1000);
			return () => clearTimeout(timer);
	}, []);

	const onSubmit = async () => {
		setMessage({
			type:"",
			message:""
		});
		setIsLoading(true);
		if(isLoading === false){

			if(validationCheck()){
				await forgotPassword({email}).then(res => {

					setIsLoading(false);
					setEmail("");
					setMessage({
						type:"success",
						message:res.data.message
					});

				}).catch(err => {

					LogoutOnSessionExpire(err)
					setIsLoading(false);

					if(err?.response?.data?.errors?.email){

						setError(err.response.data.errors.email);
					}
				})
			}
		}else{

			setMessage({
				type:"danger",
				message:"Please wait before retrying."
			});
		}
	}

	const validationCheck = () => {

		let flag = true;

		if(email === "" || email === null || email === undefined){
			setError(t("auth.email_required"));
			flag = false;
		}else{
			if(!validateEmail(email)){
				setError(t("auth.invalid_email"));
				flag = false;
			}
		}

		return flag;
	}

	return(
		<React.Fragment>
			<Loading
				loading={isLoading}
				background="rgba(0, 0, 0, 0.40)"
				loaderColor="rgb(248, 153, 3)"
			/>
			<Layout>
				<div className="authincation-content">
					<div className="row no-gutters">
						<div className="col-xl-12">
							<div className="auth-form">
								<Link to="/">
									<h4 className="mb-4"> 
										<img alt="" className="arrow-left" src="/images/arrow-left@1x.png" /> 
										{t("auth.back_to_login")}
									</h4>
								</Link>
								<XAlert message={message.message} type={message.type}/>
								<h3>  {t("auth.password_reset")} </h3>
								<p> {t("auth.send_a_link_to_your_email_to_reset_the_password")} </p>
											
								<form action="index.html">
									<div className="mb-3">
										<label className="mb-1"><strong>{t("auth.email_address")} </strong></label>
										<input 
										type="email" 
										className="form-control"
										placeholder={t("auth.email_address")}
										onChange={event => setEmail(event.target.value)}
										value={email} />
										<XError message={error}/>
									</div>
									<div className="text-center">

										{isLoading === false?
										<button 
										type="button" 
										onClick={onSubmit}
										className="btn btn-primary btn-block"> {" "}
										{t("auth.send_reset_link")}{" "}  </button>:
										<button 
										type="button"
										className="btn btn-primary btn-block">  {" "}
										{t("auth.sending")} ...{" "} </button>}
										
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</Layout>
		</React.Fragment>
	);
}

export default ForgotPassword;