// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-floor-card {
  height: 230px;
  background-color: #eaecf2;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* overflow-y: auto; */
}`, "",{"version":3,"sources":["webpack://./src/views/super-admin/Dashboard/Dashboard.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,sBAAsB;AACxB","sourcesContent":[".dashboard-floor-card {\n  height: 230px;\n  background-color: #eaecf2;\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n  /* overflow-y: auto; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
