import React, { useEffect, useState, useRef } from "react";
import ClearIcon from "@mui/icons-material/Clear";

const SearchBox = ({
  placeholder,
  options,
  setQuery,
  setPageNumber,
  entityId,
}) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    if (entityId != "") {
      setSearchValue("");
    }
  }, [entityId]);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);
  const HandleSearch = (val) => {
    setSearchValue(() => val);
    if (val.length > 0) {
      setFilteredOptions(
        options.filter((data) =>
          data.name.toLowerCase().includes(val.toLowerCase())
        )
      );
      setShowDropdown(true);
    } else {
      setShowDropdown(false);
      setQuery("");
    }
  };
  const HandleDropdown = (val) => {
    console.log(val);
    setShowDropdown(() => false);
    setQuery(val.id ? val.id : val.uuid);
    setSearchValue(val.name);
    setPageNumber(1);
  };

  const handleClearInput = () => {
    setSearchValue("");
    setQuery("");
    inputRef.current.focus();
  };

  return (
    <React.Fragment>
      <div className="tc_drop1-out" ref={dropdownRef}>
        <div class="tc_drop1" style={{ width: "150px" }}>
          <div className="inp-out">
            <input
              type="text"
              onChange={(e) => HandleSearch(e.target.value)}
              value={searchValue}
              placeholder={placeholder}
              style={{ width: "100px" }}
              ref={inputRef}
            />
            {searchValue && (
              <ClearIcon
                onClick={handleClearInput}
                style={{ cursor: "pointer", marginTop: "4px" }}
                className="arrow-img"
              />
            )}
            <div className="after"></div>
          </div>
          <div
            className="img-out"
            onClick={() => setShowDropdown(() => !showDropdown)}
          >
            {" "}
            <img alt="" src="\images\arrow.png" className="arrow-img" />
          </div>
        </div>
        {showDropdown ? (
          <div
            className="tc_drop1-opt"
            style={{ width: "150px", overflow: "auto", maxHeight: "20rem" }}
          >
            {filteredOptions && filteredOptions.length > 0 ? (
              filteredOptions.map((data) => {
                return (
                  <div className="tc_opt" onClick={() => HandleDropdown(data)}>
                    {data.name}
                  </div>
                );
              })
            ) : (
              <div className="tc_opt py-2 text-center">No data found</div>
            )}
          </div>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default SearchBox;
