export const LANGUAGES = {
  EN: 1,
  ZH_CHT: 2,
  ZH: 3,
  JP: 4,
  KR: 5,
  IND: 6,
  PH: 7
}

export const getLanguageCode = lang_id => {
  let lang_code = 'en'

  switch (lang_id) {
    case LANGUAGES.EN:
      lang_code = 'en'
      break
    case LANGUAGES.ZH_CHT:
      lang_code = 'zh-CHT'
      break
    case LANGUAGES.ZH:
      lang_code = 'zh'
      break
    case LANGUAGES.JP:
      lang_code = 'ja'
      break
    case LANGUAGES.KR:
      lang_code = 'ko'
      break
    case LANGUAGES.IND:
      lang_code = 'id'
      break
    case LANGUAGES.PH:
      lang_code = 'fil'
  }

  return lang_code
}
