export default process.env.REACT_APP_BASE_URL;

// export default "http://localhost:7071/";

//export default 'https://depapim.azure-api.net/';

// export default "https://stgdedc.azurewebsites.net/";

// export default "https://localdedc.azurewebsites.net/";

// export default 'https://dedc-prdapim.azure-api.net/';

// export default "https://ops-portal-func-dev.azurewebsites.net/";
