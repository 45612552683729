import { XError } from "components/common"
import AuthContext from "context"
import React, { useContext, useEffect, useRef, useState } from "react"
import CabinetService from "services/CabinetService"
import Common from "services/commonService"
import Swal from "sweetalert2"
import "./capacityStyle.css"
import { LogoutOnSessionExpire } from "common/helpers"
import { Dropdown } from "./shared/Dropdown/Dropdown"
import { DropdownOrButton } from "./shared/DropdownOrButton/DropdownOrButton"
import { useEditCabinet } from "./shared/useEditCabinet"
import { useGetCabinetBreakerRelation } from "./PowerDistribution/Breaker/useGetCabinetBreakerRelation"
import { useTranslation } from "react-i18next"
import CloseIcon from "@mui/icons-material/Close"

const EditCabinet = (props) => {
  const [modalIsOpen, setIsOpen] = React.useState(false)
  const authContext = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(false)
  const [statusData, setStatusData] = useState([])
  const { t } = useTranslation()
  const [state, setState] = useState({
    cabinet_id: "",
    name: "",
    ref_name: "",
    customer: "",
    max_kws: "",
    sold_kws: "",
    num_breakers: "",
    breakers: [null, null, null, null],
    status: "",
    reseller_id: "",
  })
  const [error, setError] = useState({
    status: "",
  })

  const { breakersByCabinet, numActiveSlots } = useGetCabinetBreakerRelation({
    cabinetId: props.editCabinets.id,
  })

  const { resellerList, addBreaker, slots, updateSlots } = useEditCabinet()

  useEffect(() => {
    setState({
      ...state,
      breakers: breakersByCabinet,
    })

    if (numActiveSlots) {
      updateSlots(numActiveSlots)
    }
  }, [breakersByCabinet])

  useEffect(() => {
    // console.log(props.editCabinets);
    // context for status api
    if (authContext.getStatusData) {
      setStatusData(authContext.getStatusData)
    }
    // Common.status().then((res) => setStatusData(res.data.data))
    setIsOpen(props.show)
    setState({
      cabinet_id: props.editCabinets.id,
      name: props.editCabinets.name,
      ref_name: props.editCabinets.ref_name,
      customer: props.editCabinets.customer,
      max_kws: props.editCabinets.max_kw,
      sold_kws: props.editCabinets.sold_kw,
      num_breakers: props.editCabinets.num_breakers,
      breakers: [null, null, null, null],
      num_xconnects: props.editCabinets.num_xconnects,
      status: props.editCabinets.status,
      reseller_id: props.editCabinets.reseller_id,
    })

    return () => {
      //setCountries([]);
      //setDataCenters([]);
      setState({})
    }
  }, [props.show])

  // const deleteRoom = async () => {

  // 	closeModal();

  // 	setState({...state,room_id:props.editCabinets.id})
  // 	Swal.fire({
  // 	  	title: 'Are you sure?',
  // 	  	text: "You won't be able to revert this!",
  // 	  	icon: 'warning',
  // 	  	showCancelButton: true,
  // 	  	cancelButtonColor: '#d33',
  // 	  	confirmButtonText: 'Yes, delete it!'
  // 	}).then(async (result) => {
  // 	  if (result.isConfirmed) {

  // 	    await Room.deleteRoom(authContext.getToken,{
  // 	    	...state,
  // 	    	room_id: props.editCabinets.id
  // 	    }).then(async res => {

  // 			setIsLoading(false);
  //
  // 			let newData = data.map(floor => {

  // 				if(floor.id === res?.data?.data?.id){
  // 					return res.data.data;
  // 				}
  // 				return floor;
  // 			});

  // 			closeModal();

  // 		}).catch(err => {

  // 			setIsLoading(false);
  // 			let error = {
  // 				cabinet_id:"",
  //                 customer:"",
  //                 max_kws:"",
  //                 sold_kws:"",
  //                 num_breakers:"",
  //                 num_xconnects:"",
  //                 status:""
  // 			};
  // 			const errors = err?.response?.data?.errors;

  // 			if(errors?.name !== undefined || errors?.name !== "" || errors?.name !== null){
  // 				error.name = errors.name;
  // 			}
  // 		})
  // 	  }
  // 	})

  // }

  const onSubmit = async (data) => {
    setIsLoading(true)
    if (checkValidation()) {
      setState({ ...state, cabinet_id: props.editCabinets.id })

      const reseller = resellerList.find(
        (r) => r.id === parseInt(state.reseller_id, 10)
      )?.name
      await CabinetService.updateCabinets(authContext.getToken, {
        ...state,
        cabinet_id: props.editCabinets.id,
        reseller,
      })
        .then(async (res) => {
          setIsLoading(false)

          // let newData = data.map(floor => {

          // 	if(floor.id === res?.data?.data?.id){
          // 		return res.data.data;
          // 	}
          // 	return floor;
          // });
          props.getCabinetsData(0, props.selectedRoom)
          //props.selectDataCenterFloor(props.data_center_id, props.floorIndex);
          closeModal()
          Swal.fire(t("dashboard.cabinet_updated"))
          //props.selectDataCenterFloor(props.dataCenterId)
        })
        .catch((err) => {
          LogoutOnSessionExpire(err)
          setIsLoading(false)

          let error = {
            customer: "",
            max_kws: "",
            sold_kws: "",
            num_breakers: "",
            num_xconnects: "",
            status: "",
          }
          const errors = err?.response?.data?.errors

          if (
            errors?.customer !== undefined ||
            errors?.customer !== "" ||
            errors?.customer !== null
          ) {
            error.customer = errors.customer
          }
        })
    }
  }

  const checkValidation = () => {
    let error = {
      customer: "",
      max_kws: "",
      sold_kws: "",
      num_breakers: "",
      num_xconnects: "",
      status: "",
    }

    const { customer } = state

    let flag = true

    if (customer === "" || customer === null || customer === undefined) {
      error.customer = "The customer field is required."
      flag = false
    }

    setError({ ...error })
    return flag
  }

  const closeModal = () => {
    setIsOpen(false)
    props.setShow(false)
  }

  const updateBreakers = (id, val) => {
    let copy = state.breakers.slice()
    copy[id] = val
    setState({
      ...state,
      breakers: copy,
    })
  }

  return (
    <div
      className="modal show"
      style={{ display: "block" }}
      id="exampleModalCenter"
    >
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header mt-59">
            <h3 className="modal-title"> {t("dashboard.cabinet")} </h3>
            <CloseIcon
              onClick={(e) => {
                closeModal(e)
                if (props.showCabinetPopUp) {
                  props.showCabinetPopUp()
                }
              }}
            />
          </div>
          <div className="modal-body">
            <div className="card">
              <div
                className="card-body"
                style={{ padding: "0px", overflow: "hidden" }}
              >
                <div className="basic-form">
                  <form>
                    <div className="row">
                      <div className="mb-3 col-md-6 mt-2313">
                        <label className="form-label">
                          {" "}
                          {t("dashboard.cabinet_id")}{" "}
                          <small className="text-danger hide">*</small>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder={t("dashboard.cabinet_id")}
                          value={state.name}
                          onChange={(event) =>
                            setState({
                              ...state,
                              name: event.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="mb-3 col-md-6 mt-2313">
                        <label className="form-label">
                          {" "}
                          {t("dashboard.reference_name")}{" "}
                          <small className="text-danger hide">*</small>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder={t("dashboard.reference_name")}
                          value={state.ref_name}
                          onChange={(event) =>
                            setState({
                              ...state,
                              ref_name: event.target.value,
                            })
                          }
                        />
                        <XError message={error.ref_name} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="mb-3 col-md-6 mt-2313">
                        <label className="form-label">
                          {" "}
                          {t("dashboard.customer")}
                          <small className="text-danger">*</small>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t("dashboard.customer")}
                          value={state.customer}
                          name="customer"
                          onChange={(event) =>
                            setState({ ...state, customer: event.target.value })
                          }
                        />
                        <XError message={error.customer} />
                      </div>
                      <div className="mb-3 col-md-6 mt-2313">
                        <label className="form-label">
                          {" "}
                          {t("dashboard.reseller")}
                        </label>
                        <select
                          className="default-select form-control wide"
                          onChange={(event) => {
                            setState({
                              ...state,
                              reseller_id: event.target.value,
                            })
                          }}
                          value={state.reseller_id}
                        >
                          <option value="">{t("dashboard.choose")} ...</option>
                          {resellerList &&
                            resellerList.map((r) => {
                              return (
                                <option value={r.id} key={r.id}>
                                  {r.name}
                                </option>
                              )
                            })}
                        </select>
                        {/* <XError message={error.customer} /> */}
                      </div>
                    </div>
                    <div className="row">
                      <div className="mb-3 col-md-6 mt-2313">
                        <label className="form-label">
                          {" "}
                          {t("dashboard.max_k_w_s")}{" "}
                          <small className="text-danger hide">*</small>
                        </label>
                        <input
                          className="form-control"
                          type="number"
                          maxLength={9}
                          placeholder={t("dashboard.max_k_w_s")}
                          value={state.max_kws}
                          onChange={(event) =>
                            setState({
                              ...state,
                              max_kws:
                                event.target.value.length <= 9
                                  ? event.target.value
                                  : state.max_kws,
                            })
                          }
                        />
                        <XError message={error.max_kws} />
                      </div>
                      <div className="mb-3 col-md-6 mt-2313">
                        <label className="form-label">
                          {" "}
                          {t("dashboard.sold_k_w_s")}{" "}
                          <small className="text-danger hide">*</small>
                        </label>
                        <input
                          className="form-control"
                          type="number"
                          maxLength={9}
                          placeholder={t("dashboard.sold_k_w_s")}
                          value={state.sold_kws}
                          onChange={(event) =>
                            setState({
                              ...state,
                              sold_kws:
                                event.target.value.length <= 9
                                  ? event.target.value
                                  : state.sold_kws,
                            })
                          }
                        />
                        <XError message={error.sold_kws} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="mb-3 col-md-6 mt-2313">
                        <Dropdown
                          id={0}
                          label={"Pwr Breaker 1"}
                          list={props.breakerList}
                          value={state.breakers[0]}
                          onChange={updateBreakers}
                          breakers={state.breakers}
                        />
                      </div>
                      <div className="mb-3 col-md-6 mt-2313">
                        <label className="form-label">
                          {" "}
                          # {t("dashboard.x_connects")}{" "}
                          <small className="text-danger hide">*</small>
                        </label>
                        <input
                          className="form-control"
                          type="number"
                          maxLength={9}
                          placeholder="# X-Connects"
                          value={state.num_xconnects}
                          onChange={(event) =>
                            setState({
                              ...state,
                              num_xconnects:
                                event.target.value.length <= 9
                                  ? event.target.value
                                  : state.num_xconnects,
                            })
                          }
                        />
                        <XError message={error.num_xconnects} />
                      </div>
                    </div>
                    {slots
                      .filter((slot) => slot.active)
                      .map((slot) => (
                        <div className="row">
                          <div className="mb-3 col-md-6 mt-2313">
                            <DropdownOrButton
                              id={slot.id}
                              label={`Pwr Breaker ${slot.id + 1}`}
                              list={props.breakerList}
                              value={state.breakers[slot.id]}
                              updateBreakers={updateBreakers}
                              slotValue={slot.value}
                              previousBreaker={state.breakers[slot.id - 1]}
                              addBreaker={addBreaker}
                              breakers={state.breakers}
                            />
                          </div>
                          {slot.id === 1 && (
                            <div className="mb-3 col-md-6 mt-2313">
                              <label className="form-label">
                                {" "}
                                {t("dashboard.status")}
                                <small className="text-danger">*</small>
                              </label>
                              <select
                                value={state.status}
                                onChange={(event) => {
                                  setState({
                                    ...state,
                                    status: event.target.value,
                                  })
                                }}
                                className="default-select form-control wide"
                              >
                                {statusData &&
                                  statusData.map((status) => {
                                    if (status.status_type_id === 1) {
                                      return (
                                        <option
                                          value={status.id}
                                          key={status.id}
                                        >
                                          {status.status_name}
                                        </option>
                                      )
                                    }
                                  })}
                              </select>
                              <XError message={error.status} />
                            </div>
                          )}
                        </div>
                      ))}
                    <div
                      className="toolbar toolbar-bottom mt-51 d-flex justify-content-between"
                      role="toolbar"
                    >
                      {/* <div className="delt">
	        <button 
	        type="button" 
	        onClick={() => deleteRoom()}
	        className="btn btn-outline-primary mr_1 red_color">
	        	<img src="\images\trash-2.svg" style={{width: "11px", marginTop: "-0.188rem",marginRight:"0.5rem"}} /> 
	        	Delete
	        </button>
	        </div>   */}
                      <div className="usr_cncl">
                        <button
                          type="button"
                          onClick={(e) => {
                            closeModal(e)
                            if (props.showCabinetPopUp) {
                              props.showCabinetPopUp()
                            }
                          }}
                          style={{ marginRight: "1rem" }}
                          className="btn btn-outline-primary"
                        >
                          {t("common.cancel")}
                        </button>

                        {isLoading ? (
                          <button type="button" className="btn btn-primary">
                            {" "}
                            {t("common.loading")} ...{" "}
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={() => {
                              onSubmit(props.data_center_id)

                              if (props.showCabinetPopUp) {
                                props.showCabinetPopUp()
                              }
                            }}
                            className="btn btn-primary"
                          >
                            {" "}
                            {t("common.save")}{" "}
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditCabinet
