import { useTranslation } from "react-i18next"
import { useContext } from "react"
import AuthContext from "context"
import { DownloadPopup } from "./Popup/DownloadPopup"

const BreakerTable = ({ breakers, setIsLoading }) => {
  const { t } = useTranslation()
  const contextStore = useContext(AuthContext)
  const dcAllowed = contextStore.getdcId

  // id of the dc that are allowed to get bcm data from odoo table
  const downloadBCMData = [
    {
      MNL1: 9,
    },
  ]
  const isDCAllowed = (data, value) => {
    return data?.some((item) => Object.values(item).includes(value))
  }

  const result = isDCAllowed(downloadBCMData, dcAllowed)

  return (
    <table
      className="table header-border verticle-middle"
      style={{ whiteSpace: "nowrap" }}
    >
      <tbody>
        <tr>
          {result && <th width="5%">{t("Raw Data")}</th>}
          <th width="15%">{t("breaker.name")}</th>
          <th width="15%">{t("breaker.floor")}</th>
          <th width="15%">{t("breaker.room")}</th>
          <th width="15%">{t("breaker.panel")}</th>
          <th width="10%">{t("breaker.status")}</th>
          <th width="15%">{t("breaker.upstream_device")}</th>
          <th width="10%">{t("breaker.service_cabinet")}</th>
        </tr>

        {breakers.map((data) => {
          return (
            <tr>
              {result && (
                <td>
                  <DownloadPopup
                    name={result ? data.breaker_id : data.name}
                    room={data.room}
                    setIsLoading={setIsLoading}
                  />
                </td>
              )}
              <td>{result ? data?.breaker_id || "-" : data?.name || "-"}</td>
              <td>
                {result
                  ? data?.room?.substring(6, 7) + "F" || "-"
                  : data?.floor_name || "-"}
              </td>
              <td>{result ? data?.room || "-" : data?.room_name || "-"}</td>
              <td>{"-"}</td>
              <td>{data.status_name}</td>
              <td>{data.upstream_device_name || "-"}</td>
              <td>
                {result
                  ? data?.cabinet_name || "-"
                  : data?.svc_cabinet_name || "-"}
              </td>
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

export default BreakerTable
