export const category = [
  {
    label: "Infrastructure Incident",
    value: {
      category: "infrastructure",
      subCategory: [
        { value: "1", label: "Electrical" },
        { value: "2", label: "Mechanical" },
        { value: "3", label: "Fire Protection" },
        { value: "4", label: "BMS" },
        { value: "5", label: "Cross Connect" },
        { value: "6", label: "Network" },
      ],
      severity: [
        { value: "1", label: "P0" },
        { value: "2", label: "P1" },
        { value: "3", label: "P2" },
        { value: "4", label: "P3" },
      ],
    },
  },
  {
    label: "Security Incident",
    value: {
      category: "security",
      subCategory: [
        { value: "7", label: "CAS" },
        { value: "8", label: "CCTV" },
        { value: "9", label: "Security Breach" },
        { value: "10", label: "Security Threat" },
        { value: "11", label: "Data Loss" },
        { value: "12", label: "Authorities Access" },
      ],
      severity: [
        { value: "5", label: "I1" },
        { value: "6", label: "I2" },
        { value: "7", label: "I3" },
      ],
    },
  },
  {
    label: "Environmental, Health & Safety (EHS)",
    value: {
      category: "ehs",
      subCategory: [
        { value: "13", label: "Environmental" },
        { value: "14", label: "Electrical" },
        { value: "15", label: "Mechanical" },
      ],
      severity: [
        { value: "8", label: "E1" },
        { value: "9", label: "E2" },
        { value: "10", label: "E3" },
      ],
    },
  },
];

export const defaultValue = {
  category: "infrastructure",
  subCategory: [
    { value: "1", label: "Electrical" },
    { value: "2", label: "Mechanical" },
    { value: "3", label: "Fire Protection" },
    { value: "4", label: "BMS" },
    { value: "5", label: "Cross Connect" },
    { value: "6", label: "Network" },
  ],
  severity: [
    { value: "1", label: "P0" },
    { value: "2", label: "P1" },
    { value: "3", label: "P2" },
    { value: "4", label: "P3" },
  ],
};
