import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Table from './table';
import SerachBox from '../../Setting/Component/searchBox';
import accountService from 'services/accountService';
import StorageContext from 'context';
import TicketService from 'services/createTicketService';

const ContactsTab = (props) => {
  const contextStore = useContext(StorageContext);
  const { t } = useTranslation();
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [total, setTotal] = useState(0);
  const [contactList, setContactList] = useState([]);
  const [filteredList, setFilteredList] = useState([]);
  const [errorpopup, setErrorPopup] = useState(false);
  const [errorMsg, setErrorMsg] = useState('Something went Wrong !');
  const [query, setQuery] = useState('');

  useEffect(() => {
    props.setIsLoading(true);
    getContactsList();
  }, [props.data, pageNumber, itemsPerPage]);

  useEffect(() => {
    if (query) {
      const data = contactList.filter((contact) => {
        if (contact[`${query['name']}`]) {
          const value = query['value'];
          const key = query['name'];
          if (contact[key].toLowerCase().includes(value.toLowerCase())) {
            return contact;
          }
        }
      });
      setFilteredList(data);
    } else {
      setFilteredList(contactList);
    }
  }, [query]);

  const columns = [
    {
      name: 'name',
      value: t(`account.contacts_table.name`)
    },
    {
      name: 'email',
      value: t(`account.contacts_table.email`)
    },
    {
      name: 'mobile',
      value: t(`account.contacts_table.mobile`)
    },
    {
      name: 'permanent_site_access',
      value: t(`account.contacts_table.permanent_site_access`)
    },
    {
      name: 'is_paa',
      value: t(`account.contacts_table.is_paa`)
    }
  ];

  const searchCategory = [
    { name: t(`account.contacts_table.name`), field: 'name' },
    { name: t(`account.contacts_table.email`), field: 'email' },
    { name: t(`account.contacts_table.mobile`), field: 'mobile' },
    { name: t(`account.contacts_table.permanent_site_access`), field: 'permanent_site_access' }
  ];

  //Get contacts list API call
  const getContactsList = async () => {
    await TicketService.getCompaniesContacts(contextStore.token(), props.data.customer_id, true)
      .then((res) => {
        if (res.data.data.length > 0) {
          setTotal(res.data.data.length);
          const contactList = res.data.data.map((data) => {
            return {
              id: data.id,
              name: data.name,
              email: data.email,
              mobile: data.mobile,
              is_paa: data.is_paa,
              permanent_site_access:
                data.permanent_site_access.length > 0
                  ? data.permanent_site_access.map((x) => x.name).join(', ')
                  : ''
            };
          });
          setContactList(contactList);
          setFilteredList(contactList);
        } else {
          setContactList([]);
        }
      })
      .catch((err) => {})
      .finally(() => {
        props.setIsLoading(false);
      });
  };

  return (
    <div>
      <div className="row">
        <div>
          <SerachBox
            searchCategory={searchCategory}
            query={query}
            setQuery={setQuery}
            setIsLoading={props.setIsLoading}
            setPageNumber={setPageNumber}
          ></SerachBox>
        </div>
      </div>
      <Table
        columns={columns}
        data={filteredList}
        setItemsPerPage={setItemsPerPage}
        setPageNumber={setPageNumber}
        total={total}
        pageNumber={pageNumber}
        setIsLoading={props.setIsLoading}
      ></Table>
    </div>
  );
};

export default ContactsTab;
