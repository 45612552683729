import { css } from 'glamor'
import { useTranslation } from 'react-i18next'
import DetailsListRow from 'views/super-admin/Tickets/shared/DetailsListRow'
import DetailsListRowReadOnly from 'views/super-admin/Tickets/shared/DetailsListRowReadOnly'

const PackageList = ({ details, onDelete, onChange, readOnly }) => {
  const { t } = useTranslation()

  const styles = {
    container: {
      height: '100px',
      overflowY: 'auto'
    },
    empty: {
      fontSize: '14px',
      fontStyle: 'italic'
    }
  }

  const cut = (string) => {
    const LIMIT = 25
    if (string.length < LIMIT) {
      return string
    }
    return `${string.substr(0, LIMIT)}...`
  }

  return (
    <table {...css(styles.container)}>
      {details.length > 0 && details.map(({
        id,
        detailsTrackingNo,
        detailsWeight,
        detailsWeightDimension: wd,
        detailsHeight,
        detailsWidth,
        detailsDepth,
        detailsSizeDimension: sd
      }, index) => (
        <tbody key={id}>
          {!readOnly && (
            <DetailsListRow
              key={id}
              id={id}
              name={detailsTrackingNo}
              weight={detailsWeight}
              size={detailsHeight && `${detailsHeight} x ${detailsWidth} x ${detailsDepth}`}
              handleDelete={onDelete}
              handleChange={onChange}
            />
          )}
          {readOnly && (
            <DetailsListRowReadOnly
              key={id}
              id={id}
              index={index}
              name={cut(detailsTrackingNo)}
              weight={`${detailsWeight}`}
              size={detailsHeight && `${detailsHeight} x ${detailsWidth} x ${detailsDepth}`}
            />
          )}
        </tbody>
      ))}
      {details.length === 0 && (
        <div className='p-4 d-flex flex-column justify-content-around align-items-center'>
          <div {...css(styles.empty)}>
            {t('create-ticket.shipment.empty-list-placeholder')}
          </div>
        </div>
      )}
    </table>
  )
}

export default PackageList
