import { useState } from "react";
import { Link } from "react-router-dom";
import Table from "./Components/table";
import FolderCopyIcon from "@mui/icons-material/FolderCopy";
import { useTranslation } from "react-i18next";
import React, { useContext, useEffect, useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { XError } from "components/common";
import Swal from "sweetalert2";
import AuthContext from "context";
import TechDocsService from "services/techDocsService";
import UploadDocsModal from "./Components/uploadDocsModal";
import TechDocsTypesModal from "./Components/techDocsTypesModal";
const TechDocs = ({ selectedDataCenter, setIsLoading, selectedCountry }) => {
  const authContext = useContext(AuthContext);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isTechDocsModalOpen, setIsTechDocsModalOpen] = useState(false);
  const [types, setTypes] = useState([]);
  const [generalTypes, setGeneralTypes] = useState([]);
  const [specialTypes, setSpecialTypes] = useState([]);
  const [files, setFiles] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [total, setTotal] = useState(0);
  const [query, setQuery] = useState("");
  const { t } = useTranslation();
  const [selectedTypeId, setSelectedTypeId] = useState("");
  const [selectedType, setSelectedType] = useState({ name: "Summary", id: 0 });

  useEffect(() => {
    getTechDocsTypes();
  }, []);

  // useEffect(() => {
  //   setQuery("");
  //   setSelectedTypeId("");
  // }, [selectedType]);

  useEffect(() => {
    getTechDocsUploadedFiles();
  }, [
    pageNumber,
    itemsPerPage,
    query,
    selectedTypeId,
    selectedType,
    selectedDataCenter,
  ]);
  useEffect(() => {
    if (types && types.length > 0) {
      setGeneralTypes(types.filter((el) => el?.type == "General"));
      setSpecialTypes(types.filter((el) => el?.type == "Special"));
    } else {
      setGeneralTypes([]);
      setSpecialTypes([]);
    }
  }, [types]);
  const getTechDocsTypes = async () => {
    setIsLoading(true);

    await TechDocsService.getAllTechDocsTypes(localStorage.getItem("token"))
      .then((res) => {
        setTypes(res.data.data);
      })
      .catch((err) => {
        Swal.fire({
          // title: "Are you sure?",
          text: err.response.data,
          icon: "error",
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "confirmBtn",
          },
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const getTechDocsUploadedFiles = async () => {
    setIsLoading(true);
    let body = {
      page: pageNumber ? pageNumber : 1,
      pageSize: itemsPerPage,
      site: selectedDataCenter?.name,
    };
    if (query !== "") {
      body[query.name] = query["value"];
    }
    if (selectedTypeId !== 0 && selectedTypeId !== "") {
      body["type_id"] = selectedTypeId;
    }
    if (selectedType.id === 0 && selectedType.name === "Summary") {
      body["latest_record"] = true;
    }
    if (selectedCountry && selectedCountry?.id) {
      body["country_id"] = selectedCountry?.id;
    }
    await TechDocsService.getTechDocsUploadedFile(
      localStorage.getItem("token"),
      body
    )
      .then((res) => {
        setFiles(res.data.data);
        setTotal(res.data.total);
      })
      .catch((err) => {
        Swal.fire({
          // title: "Are you sure?",
          text: err?.response?.data,
          icon: "error",
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "confirmBtn",
          },
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const downloadFile = async (filePath, fileName) => {
    setIsLoading(true);

    await TechDocsService.downloadTechDocsFile(
      localStorage.getItem("token"),
      filePath
    )
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        setIsLoading(false);
      })
      .catch((err) => {
        Swal.fire({
          // title: "Are you sure?",
          text: err.response.data,
          icon: "error",
          confirmButtonText: "Ok",
          customClass: {
            confirmButton: "confirmBtn",
          },
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const handleDownload = (filePath, fileName) => {
    Swal.fire({
      text: "Are you sure to download this file?",
      icon: "info",
      showCancelButton: true,
      confirmButtonText: "Download",
      customClass: {
        cancelButton: "cancelBtn",
        confirmButton: "confirmBtn",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        downloadFile(filePath, fileName);
      }
    });
  };
  const openModal = () => {
    setIsUploadModalOpen(true);
  };
  const closeModal = () => {
    setIsUploadModalOpen(false);
    setIsTechDocsModalOpen(false);
  };

  return (
    <>
      <div
        style={{
          marginTop: "1rem",
          display: "flex",
          alignItems: "center",
        }}
      >
        <button
          class="btn me-3 btn-sm assign-btn-new"
          style={{
            cursor: "pointer",
            marginLeft: "3rem",
          }}
          onClick={() => {
            setIsUploadModalOpen(true);
          }}
        >
          <img
            alt=""
            src="images/upload-orange.svg"
            style={{ width: "15px" }}
          />
          &nbsp;&nbsp;&nbsp;Upload
        </button>
        <span style={{ fontSize: "1.25rem" }}>
          Data Center: <b>{selectedDataCenter?.name}</b>
        </span>
        <UploadDocsModal
          isOpen={isUploadModalOpen}
          setIsLoading={setIsLoading}
          closeModal={closeModal}
          types={generalTypes}
          dataCenter={
            selectedCountry &&
            selectedCountry?.id &&
            selectedDataCenter?.name === "ALL"
              ? null
              : selectedDataCenter?.name
          }
          getTechDocsUploadedFile={getTechDocsUploadedFiles}
          selectedCountry={selectedCountry}
          dataCenters={authContext.getPermittedDataCenter.filter(
            (el) => el.country_id === selectedCountry?.id
          )}
        ></UploadDocsModal>
      </div>
      <div
        className="card-header"
        id="header"
        style={{
          borderBottom: "2px solid #E7E8E9",
        }}
      >
        <div className="d-sm-flex d-block justify-content-between align-items-center">
          <div
            className="card-action coin-tabs mt-1 mt-sm-0"
            style={{
              background: "transparent",
              marginLeft: "4rem",
            }}
          >
            <ul className="nav nav-tabs" role="tablist">
              {types && types.length > 0}
              {
                <li className="nav-item gap_s" style={{ cursor: "pointer" }}>
                  <a
                    className={
                      selectedType.id === 0 && selectedType.name === "Summary"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    onClick={() => {
                      setSelectedType({ name: "Summary", id: 0 });
                      setQuery("");
                      setSelectedTypeId("");
                    }}
                  >
                    Summary
                  </a>
                </li>
              }
              {generalTypes && generalTypes.length > 0}
              {
                <li className="nav-item gap_s" style={{ cursor: "pointer" }}>
                  <a
                    className={
                      selectedType.id === 0 && selectedType.name === "File List"
                        ? "nav-link active"
                        : "nav-link"
                    }
                    onClick={() => {
                      setSelectedType({ name: "File List", id: 0 });
                      setQuery("");
                      setSelectedTypeId("");
                    }}
                  >
                    File List
                  </a>
                </li>
              }

              {specialTypes &&
                specialTypes.length > 0 &&
                specialTypes.map((type, index) => {
                  return (
                    <li
                      className="nav-item gap_s"
                      style={{ cursor: "pointer" }}
                    >
                      <a
                        className={
                          selectedType.id === type.id
                            ? "nav-link active"
                            : "nav-link"
                        }
                        // onClick={() => {
                        //   setSelectedType({ name: type.name, id: type.id });
                        // }}
                      >
                        {type.name}
                      </a>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
        <div style={{ marginTop: "-1rem", marginRight: "2rem" }}>
          <button
            class="me-3 btn-sm confirmBtn"
            style={{
              cursor: "pointer",
              width: "10rem !important",
              borderRadius: "0.5rem",
              color: "#fff !important",
            }}
            onClick={() => {
              setIsTechDocsModalOpen(true);
            }}
          >
            <FolderCopyIcon style={{ color: "#fff" }}></FolderCopyIcon>
            &nbsp;&nbsp;&nbsp;Modify Type
          </button>
          <TechDocsTypesModal
            isOpen={isTechDocsModalOpen}
            setIsLoading={setIsLoading}
            closeModal={closeModal}
            types={types}
            getTechDocsTypes={getTechDocsTypes}
            files={files}
          ></TechDocsTypesModal>
        </div>
      </div>
      <div>
        <Table
          selectedType={selectedType}
          files={files}
          types={types}
          handleDownload={handleDownload}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          setItemsPerPage={setItemsPerPage}
          itemsPerPage={itemsPerPage}
          total={total}
          query={query}
          setQuery={setQuery}
          setSelectedTypeId={setSelectedTypeId}
          selectedTypeId={selectedTypeId}
          generalTypes={generalTypes}
          setSelectedType={setSelectedType}
        ></Table>
      </div>
    </>
  );
};
export default TechDocs;
