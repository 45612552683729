import React from "react";

const XAlert = ({ message, type }) => {
  if (message) {
    return (
      <p
        className={"alert-" + type}
        style={{
          color: "#155724",
          fontSize: "1rem",
          padding: "0.75rem 1rem 0.75rem 1rem",
        }}
      >
        {" "}
        {message}{" "}
      </p>
    );
  }
  return null;
};

export default XAlert;
