import { useFormik } from "formik";
import { useEffect } from "react";
import * as yup from "yup";
import useGetCompaniesAndCountries from "../shared/hooks/useGetCompaniesAndCountries";
import useGetOperatingSitesAndCustomers from "../shared/hooks/useGetOperatingSitesAndCustomers";
import useGetServiceIds from "../shared/hooks/useGetServiceIds";
import useGetTeams from "../shared/hooks/useGetTeams";
import useResetFields from "../shared/hooks/useResetFields";
import useSetParameters from "../shared/hooks/useSetParameters";
import useStartEndTime from "../shared/hooks/useStartEndTime";
import * as utils from "../shared/utils";
import useCreateRemoteHandsTicket from "./hooks/useCreateRemoteHandsTicket";
import useGetTicketTypes from "./hooks/useGetTicketTypes";

const useRemoteHandsTicketForm = ({ closeModal, setLoading }) => {
  const schema = yup.object().shape({
    companyName: yup.number().required(),
    operatingSite: yup.number().required(),
    // customer: yup.number().required(),
    serviceId: yup.number().required(),
    remoteHandsType: yup.string().required(),
    ticketType: yup.number().required(),
    requestSubject: yup.string().required(),
    description: yup.string().required(),
    // banId: yup.number().required(),
    // attention: yup.boolean().required(),
    // disclaimer: yup.boolean().required(),
    // reminder: yup.boolean().required()
  });

  const { teams } = useGetTeams();
  const { ticketTypes } = useGetTicketTypes();

  const returnValuesIfValid = async (values) => {
    const result = await schema
      .validate(values, { abortEarly: false })
      .catch((err) => {
        console.log(err?.errors[0]);
      });
    return result;
  };

  const { postNewTicket, requestOnFlight } = useCreateRemoteHandsTicket({
    closeModal,
    teams,
  });

  const formik = useFormik({
    initialValues: {
      country: "",
      companyName: "",
      operatingSite: "",
      customer: "",
      remoteHandsType: "regular",
      ticketType: "",
      requestSubject: "",
      serviceId: "",
      description: "",
      scheduleStartTime: "",
      scheduleEndTime: "",
      attention: false,
      disclaimer: false,
      banId: "",
      reminder: "",
      dataCenters: [],
      countries: [],
      services: [],
      isShowResellerContacts: false,
    },
    onSubmit: async (values) => {
      const result = await returnValuesIfValid(values);
      // console.log(result)
      if (result) {
        postNewTicket(result);
      }
    },
  });

  const {
    companies,
    countries,
    companiesSite,
    isCompanyLoading,
  } = useGetCompaniesAndCountries(
    setLoading,
    formik.setFieldValue,
    formik.values.country
  );

  // const { companyBans } = useGetCompanyBan(formik.values.companyName)

  const {
    operatingSites,
    customers,
    dataCenters,
    isOperatingSitesLoading,
  } = useGetOperatingSitesAndCustomers({
    countryId: utils.getCountryIdByCode(formik.values.country, countries),
    companyId: formik.values.companyName,
    fetchCustomers: true,
    setLoading,
    country: formik.values.country,
    countries: countries,
    isShowResellerContacts: formik.values.isShowResellerContacts,
  });

  useStartEndTime({
    startDateTime: formik.values.scheduleStartTime,
    endDateTime: formik.values.scheduleEndTime,
    startDateTimeName: "scheduleStartTime",
    endDateTimeName: "scheduleEndTime",
    setFieldValue: formik.setFieldValue,
  });

  const { services, isServiceLoading } = useGetServiceIds(
    formik.values.companyName,
    formik.values.operatingSite,
    setLoading
  );

  useEffect(() => {
    if (services && services.length > 0) {
      formik.setFieldValue("services", services);
    }
  }, [services]);

  useResetFields(formik);

  const addCondition = (func, field) => {
    return func(field) && formik.values[field] !== "";
  };

  const addConditionBool = (func, field) => {
    return func(field) && formik.values[field];
  };

  useSetParameters({
    dataCenters,
    countries,
    setFieldValue: formik.setFieldValue,
  });

  const isActive = (field) => {
    switch (field) {
      case "companyName":
        return formik.values.country !== "";
      case "operatingSite":
        return addCondition(isActive, "companyName");
      case "remoteHandsType":
        return addCondition(isActive, "operatingSite");
      case "customer":
        return addCondition(isActive, "remoteHandsType");
      case "ticketType":
        return addCondition(isActive, "remoteHandsType");
      case "requestSubject":
        return addCondition(isActive, "ticketType");
      case "serviceId":
        return addCondition(isActive, "requestSubject");
      case "description":
        return addCondition(isActive, "serviceId");
      case "scheduleStartTime":
        return addCondition(isActive, "description");
      // case 'attention':
      //   return addCondition(isActive, 'description')
      // case 'disclaimer':
      //   return addConditionBool(isActive, 'attention')
      // case 'banId':
      //   return addConditionBool(isActive, 'disclaimer')
      // case 'reminder':
      //   return addCondition(isActive, 'banId')
      case "submit":
        return formik.values["remoteHandsType"] == "scheduled"
          ? addCondition(isActive, "scheduleStartTime")
          : addCondition(isActive, "description") && !requestOnFlight;
      default:
        return false;
    }
  };

  return {
    formik,
    isActive,
    companies,
    countries,
    teams,
    operatingSites,
    customers,
    services,
    ticketTypes,
    companiesSite,
    isServiceLoading,
    isCompanyLoading,
    isOperatingSitesLoading,
    // companyBans
  };
};

export default useRemoteHandsTicketForm;
