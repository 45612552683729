import { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Table from './table';
import SerachBox from '../searchBox';
import StorageContext from 'context';
import provisioningService from 'services/provisioningService';

const IBTab = (props) => {
  const contextStore = useContext(StorageContext);
  const { t } = useTranslation();
  const [pageNumber, setPageNumber] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [total, setTotal] = useState(0);
  const [ibList, setIBList] = useState([]);
  const [errorpopup, setErrorPopup] = useState(false);
  const [errorMsg, setErrorMsg] = useState('Something went Wrong !');
  const [query, setQuery] = useState('');
  const [filter, setFilter] = useState([]);

  useEffect(() => {
    console.log(filter);
    if (filter.length > 0) {
      props.setIsLoading(true);
      getIBList();
    }
  }, [props.data, pageNumber, itemsPerPage, query, filter]);

  useEffect(() => {
    setFilter([
      { name: 'category', value: 'installed_base' },
      { name: 'customer', value: props.data.customer_name }
    ]);
  }, []);
  const columns = [
    {
      name: 'name',
      value: t(`account.ib_table.title`)
    },
    {
      name: 'sale_order_item',
      value: t(`account.ib_table.sales_order_item`)
    },
    {
      name: 'service_request_date',
      value: t(`account.ib_table.service_request_date`)
    },
    {
      name: 'service_commencement_date',
      value: t(`account.ib_table.service_commencement_date`)
    },
    {
      name: 'customer_service_reference',
      value: t(`account.ib_table.customer_service_reference`)
    },
    {
      name: 'assignee',
      value: t(`account.ib_table.assignee`)
    },
    {
      name: 'status',
      value: t(`account.ib_table.status`)
    }
  ];

  const searchCategory = [
    { name: 'Name', field: 'name' },
    { name: 'Sales Order Item', field: 'sale_order_item' },
    { name: 'Assignee', field: 'assignee' },
    { name: 'Status', field: 'status' }
  ];

  //Get IB list API call
  const getIBList = async () => {
    const body = {
      page: pageNumber,
      limit: itemsPerPage,
      filters: query ? [...filter, query] : [...filter]
    };
    console.log(body);
    await provisioningService
      .getServices(localStorage.getItem('token'), body)
      .then((res) => {
        let ibData = res.data.data.rows;

        setIBList(ibData);
        setTotal(res.data.data.total);
      })
      .catch((err) => {
        // LogoutOnSessionExpire(err);
        //500 error page
      })
      .finally(() => {
        props.setIsLoading(false);
      });
  };

  return (
    <div>
      <div className="row">
        <div>
          <SerachBox
            searchCategory={searchCategory}
            query={query}
            setQuery={setQuery}
            setIsLoading={props.setIsLoading}
            setPageNumber={setPageNumber}
          ></SerachBox>
        </div>
      </div>
      <Table
        columns={columns}
        data={ibList}
        setItemsPerPage={setItemsPerPage}
        setPageNumber={setPageNumber}
        total={total}
        pageNumber={pageNumber}
        setIsLoading={props.setIsLoading}
        filter={filter}
        setFilter={setFilter}
      ></Table>
    </div>
  );
};

export default IBTab;
