import AuthContext from "context";
import React, { useContext, useEffect, useRef, useState } from "react";
import Loading from "views/super-admin/pre-loader/loader";
import Floors from "services/floorServices";
import PatchPanelService from "services/patchPanelService";
import Layout from "../Component/LayoutWithDataCenter";
import AddModal from "./componentPatch/addModel";
import EditModal from "./componentPatch/editModel";
import { subMenu } from "./data";
import "./patchPanelStyle.css";
import { useTranslation } from "react-i18next";
import { LogoutOnSessionExpire } from "common/helpers";

const PatchPanel = () => {
  const contextStore = useContext(AuthContext);
  const initialMount = useRef(true);
  const [dataCenter, setDataCenter] = useState([]);
  const [countryName, setCountryName] = useState("Country");
  const [activeTab, setActiveTab] = useState(
    contextStore.getNetworkDataCenter?.id
  );
  const [country, setCountry] = useState(0);
  const [openAddModal, setOpenAddModel] = useState(false);
  const [openEditModal, setOpenEditModel] = useState(false);
  const [payload, setPayload] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [editableObj, setEditableObj] = useState({});
  const [ascending, setAscending] = useState(true);
  const [fascending, setFAscending] = useState(true);
  const [rascending, setRAscending] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [cabascending, setcabAscending] = useState(true);
  const [activeDataCenter, setActiveDataCenter] = useState({});
  const [floorsData, setFloorData] = useState([]);
   const [triggerRefresh, setTriggerRefresh] = useState(false);
   const { t } = useTranslation();

  useEffect(() => {
    getFloorData();
    if (activeTab !== undefined) {
      PatchPanelService.index(contextStore.getToken, {
        dataCenterId: activeTab,
      })
        .then((res) => {
          setPayload(res.data.patchPanel);
        })
        .catch((err) => {
          LogoutOnSessionExpire(err);
          console.log(err);
        });
    }
    // const timer = setTimeout(() => {
    //   setIsLoading(false);
    // }, 500);
    // return () => clearTimeout(timer);
  }, [activeTab, refresh,triggerRefresh]);

  const getFloorData = async () => {
    setIsLoading(true);
    await Floors.findAllFloor(contextStore.token())
      .then((res) => {
        setFloorData(res.data.data); 
      }).catch((err) => {
        LogoutOnSessionExpire(err); 
      }).finally(() => setIsLoading(false));
  };
  return (
    <React.Fragment>
      <Loading
        loading={isLoading}
        background="rgba(0, 0, 0, 0.40)"
        loaderColor="rgb(248, 153, 3)"
      />
      <Layout
        setTriggerRefresh={setTriggerRefresh}
        triggerRefresh={triggerRefresh}
        initialMount={initialMount}
        dataCenter={dataCenter}
        setDataCenter={setDataCenter}
        countryName={countryName}
        setCountryName={setCountryName}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        country={country}
        setCountry={setCountry}
        subMenu={subMenu}
      >
        <div className="tblop-153">
          <div className="ttl_op-153">
            <h6>{activeDataCenter.name}</h6>
            <a
              href="#sd"
              onClick={() => setOpenAddModel(true)}
              style={{
                color: "#147AD6",
                fontWeight: 600,
                fontSize: "0.813rem",
                cursor: "pointer",
              }}
              data-bs-toggle="modal"
              data-bs-target=".bd-example-modal-lg"
            >
              <img
                alt=""
                src="/images/plus-circle-blue.svg"
                style={{ width: "1rem" }}
              />
              &nbsp;{t("cabling.add_patch_Panel")}
            </a>
          </div>
          <div className="op-153_table mt-1">
            <div
              className="table-responsive"
              style={{
                overflow: "auto",
                height: "350px",
              }}
            >
              <table
                className="table  table-responsive-md"
                style={{ border: "1px solid #eee" }}
              >
                <thead style={{ borderBottom: "1px solid #eee" }}>
                  <tr style={{ borderBottom: "1px solid #eee" }}>
                    <th
                      onClick={() => {
                        setAscending(!ascending);
                        if (ascending === true) {
                          payload.sort((a, b) => (a.name < b.name ? 1 : -1));
                        }
                        if (ascending === false) {
                          payload.sort((a, b) => (a.name > b.name ? 1 : -1));
                        }
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {" "}
                      {t("cabling.name")}{" "}
                      <i
                        className={`fa fa-solid fa-sort-${
                          ascending ? "down" : "up"
                        }`}
                      ></i>
                    </th>
                    <th></th>
                    <th># {t("cabling.ports")}</th>
                    <th>{t("cabling.port_details")}</th>
                    <th
                      onClick={() => {
                        setFAscending(!fascending);
                        if (fascending === true) {
                          payload.sort((a, b) =>
                            a.floor_name > b.floor_name ? 1 : -1
                          );
                        }
                        if (fascending === false) {
                          payload.sort((a, b) =>
                            a.floor_name < b.floor_name ? 1 : -1
                          );
                        }
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {" "}
                      {t("cabling.floor")}{" "}
                      <i
                        className={`fa fa-solid fa-sort-${
                          fascending ? "down" : "up"
                        }`}
                      ></i>
                    </th>
                    <th
                      onClick={() => {
                        setRAscending(!rascending);
                        if (rascending === true) {
                          payload.sort((a, b) =>
                            a.room_name > b.room_name ? 1 : -1
                          );
                        }
                        if (rascending === false) {
                          payload.sort((a, b) =>
                            a.room_name < b.room_name ? 1 : -1
                          );
                        }
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {" "}
                      {t("cabling.room")}{" "}
                      <i
                        className={`fa fa-solid fa-sort-${
                          rascending ? "down" : "up"
                        }`}
                      ></i>
                    </th>

                    <th
                      onClick={() => {
                        setcabAscending(!cabascending);
                        if (cabascending === true) {
                          payload.sort((a, b) =>
                            a.cabinet_name > b.cabinet_name ? 1 : -1
                          );
                        }
                        if (cabascending === false) {
                          payload.sort((a, b) =>
                            a.cabinet_name < b.cabinet_name ? 1 : -1
                          );
                        }
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      {" "}
                      {t("cabling.cabinet_rack")}{" "}
                      <i
                        className={`fa fa-solid fa-sort-${
                          cabascending ? "down" : "up"
                        }`}
                      ></i>
                    </th>
                    <th>{t("cabling.remarks")}</th>
                  </tr>
                </thead>
                <tbody>
                  {payload &&
                    payload.map((load) => {
                      return (
                        <tr key={load.id}>
                          <td width="12%">{load?.name || ""}</td>
                          <td width="1%">
                            <span
                              onClick={() => {
                                setOpenEditModel(true);
                                setEditableObj(load);
                              }}
                            >
                              <i
                                className="fas fa-edit"
                                data-bs-toggle="modal"
                                data-bs-target=".update_Popup"
                              ></i>
                              <span className="edit"></span>
                            </span>
                          </td>
                          <td width="1%">{load?.num_ports || 0}</td>
                          <td width="1%">
                            <a
                              href="#sd"
                              style={{
                                color: "#147AD6",
                                textDecoration: "underline",
                              }}
                            >
                              View
                            </a>
                          </td>
                          <td width="1%">{load?.floor_name}</td>
                          <td width="6%">{load?.room_name}</td>
                          <td width="10%">{load?.cabinet_name}</td>
                          <td width="20%">{load?.description}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {openAddModal === true ? (
          <AddModal
            closeModal={setOpenAddModel}
            contextStore={contextStore}
            setRefresh={setRefresh}
            refresh={refresh}
            activeTab={activeTab}
            floorsData={floorsData}
            getFloorData={getFloorData}
          />
        ) : null}

        {openEditModal === true ? (
          <EditModal
            closeModal={setOpenEditModel}
            contextStore={contextStore}
            setRefresh={setRefresh}
            refresh={refresh}
            activeTab={activeTab}
            setEditableObj={setEditableObj}
            editableObj={editableObj}
            floorsData={floorsData}
            getFloorData={getFloorData}
          />
        ) : null}
      </Layout>
    </React.Fragment>
  );
};

export default PatchPanel;
