// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-room-card {
  padding-left: 6px;
  padding-right: 0px !important;
}`, "",{"version":3,"sources":["webpack://./src/views/super-admin/Dashboard/components/Room-Card.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,6BAA6B;AAC/B","sourcesContent":[".dashboard-room-card {\n  padding-left: 6px;\n  padding-right: 0px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
