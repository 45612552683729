import AuthContext from 'context';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import createTicketService from 'services/createTicketService';
import Swal from 'sweetalert2';

const useGetCompanies = (country_code) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);

  const [companies, setCompanies] = useState([]);

  const getCompanies = async () => {
    try {
      const data = await createTicketService.getCompanies(authContext.token(), country_code);

      setCompanies(
        data.map((company) => {
          const { id, name, company_code } = company;
          return {
            ...company,
            label: company_code ? `${name} (${company_code})` : name,
            value: id
          };
        })
      );
    } catch (err) {
      await Swal.fire(t('error'), err.message);
    }
  };

  // useEffect(() => {
  //   getCompanies();
  // }, []);

  return {
    companies
  };
};

export default useGetCompanies;