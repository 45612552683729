// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard-ticket-popup-icon-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 34px;
  height: 34px;
  border-radius: 56px;
  margin-right: 1.594rem;
  margin-left: 15px;
}
@media (max-width: 800px) {
  .dashboard-ticket-popup-icon-div {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .dashboard-ticket-popup-icon-div {
    margin-bottom: 5px;
    margin-left: 25px;
  }
}
.dashboard-ticket-popup-icon-div .dashboard-ticket-popup {
  cursor: pointer;
}
.dashboard-ticket-popup-icon-div .fade.show.popover.bs-popover-bottom {
  margin-top: 15px;
  border-color: rgb(254, 134, 0);
}
.dashboard-ticket-popup-icon-div .fade.show.popover.bs-popover-bottom .popover-arrow::after {
  border-bottom-color: rgb(254, 134, 0);
}
.dashboard-ticket-popup-icon-div .fade.show.popover.bs-popover-bottom .popover-body {
  padding-top: 5px;
}`, "",{"version":3,"sources":["webpack://./src/views/super-admin/dashboard-layout/TicketPopup/TicketPopup.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;EACA,mBAAA;EACA,sBAAA;EACA,iBAAA;AACF;AAAE;EAVF;IAWI,mBAAA;EAGF;AACF;AADE;EAdF;IAeI,kBAAA;IACA,iBAAA;EAIF;AACF;AAFE;EACE,eAAA;AAIJ;AAFE;EACE,gBAAA;EACA,8BAAA;AAIJ;AAFI;EACE,qCAAA;AAIN;AADI;EACE,gBAAA;AAGN","sourcesContent":[".dashboard-ticket-popup-icon-div {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  width: 34px;\n  height: 34px;\n  border-radius: 56px;\n  margin-right: 1.594rem;\n  margin-left: 15px;\n  @media (max-width: 800px) {\n    margin-bottom: 10px;\n  }\n\n  @media (max-width: 767px) {\n    margin-bottom: 5px;\n    margin-left: 25px;\n  }\n\n  .dashboard-ticket-popup {\n    cursor: pointer;\n  }\n  .fade.show.popover.bs-popover-bottom {\n    margin-top: 15px;\n    border-color: rgb(254, 134, 0);\n\n    .popover-arrow::after {\n      border-bottom-color: rgb(254, 134, 0);\n    }\n\n    .popover-body {\n      padding-top: 5px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
