import moment from 'moment'
import { useEffect } from 'react'

const useValidateTime = ({
  isRequired,
  startTime,
  endTime,
  startTimeName,
  endTimeName,
  setFieldValue,
  date
}) => {
  useEffect(() => {
    if (isRequired) {
      const start = startTime
      const end = endTime
      const now = moment(new Date())
      const nowHours = moment(new Date()).hour()

      // console.log({ start, end })
      if (moment(start) > moment(end)) {
        setFieldValue(endTimeName, start)
      }
      const diff = now.startOf('day').diff(moment(date).startOf('day'), 'days')
      // console.log({ diff, mh: moment(start).hour() })
      const isToday = diff === 0
      if (isToday && nowHours > moment(start).hour()) {
        setFieldValue(
          startTimeName,
          now.set({ hours: nowHours, minutes: 0, seconds: 0 }).format()
        )
      }
    }
  }, [startTime, endTime])

  const updateDate = (date, dateToUpdate, dateToUpdateName) => {
    const updatedTime = moment(dateToUpdate)
      .set('year', moment(date).year())
      .set('month', moment(date).month())
      .set('day', moment(date).day())

    setFieldValue(dateToUpdateName, updatedTime)
  }

  useEffect(() => {
    if (startTime !== '') {
      updateDate(date, startTime, startTimeName)
      updateDate(date, endTime, endTimeName)
    }
  }, [date])

  return {
  }
}

export default useValidateTime
