import React, { useContext, useEffect, useState, useRef } from 'react';
import Swal from 'sweetalert2';
import JoditEditor from 'jodit-react';
import { XError } from 'components/common';

const EditAccessCard = (props) => {
  const [cardData, setCardData] = useState({
    access_card_number: props.data[props.index]?.access_card_number,
    status: props.data[props.index]?.status
  });

  const closeModal = () => {
    props.setEditShow(false);
  };

  const addData = () => {
    const updatedData = [...props.data];
    updatedData[props.index] = { ...updatedData[props.index], ...cardData };
    props.setData(updatedData);
    closeModal();
  };

  return (
    <div
      class="modal show bd-example-modal-lg"
      id="main-model"
      style={{ display: 'block' }} /*onClick={()=> closeModal()}*/
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      {/* <Loading loading={isLoading} background="rgba(0, 0, 0, 0.4)" loaderColor="rgb(248, 153, 3)" /> */}
      <div class="modal-dialog modal-xl lay-2-3pop">
        <div class="modal-content fault-content" id="content-area">
          <div class="modal-header mt-59">
            <h1 class="display-title">Edit Access Card</h1>
            {/* <div className="faultClose-outer">
                <div>
                  <img
                    className="faultClose"
                    width={27}
                    src="images/faultClose.png"
                    alt=""
                    onClick={() => handleExitbutton()}
                  />
                </div>
            </div> */}
          </div>
          <div class="modal-body" style={{ paddingTop: '1rem' }}>
            <div
              class=""
              style={{
                height: 'cal(100%-0%)',
                borderBottom: '1px solid black',
                borderTop: '1px solid black'
              }}
            >
              <div class="pt-5 pb-5">
                <div class="main_fault">
                  <div className="card-body" style={{ overflow: 'hidden' }}>
                    <div class="card-details">
                      <div class="row">
                        <div class="ticket-details">
                          <div class="mb-3 row">
                            <label for="inputPassword" class="col-sm-2">
                              Card Number
                            </label>
                            <div class="col-sm-10">
                              <input
                                type="text"
                                class="form-control"
                                id="inputPassword"
                                defaultValue={props.data[props.index]?.access_card_number}
                                onChange={(e) => {
                                  setCardData({ ...cardData, access_card_number: e.target.value });
                                }}
                              />
                            </div>
                          </div>
                          <div class="mb-3 row">
                            <label for="inputPassword" class="col-sm-2">
                              Status
                            </label>
                            <div class="col-sm-10">
                              <select
                                name=""
                                id=""
                                class="default-select form-control wide"
                                defaultValue={props.data[props.index]?.status}
                                onChange={(e) => {
                                  setCardData({ ...cardData, status: e.target.value });
                                }}
                              >
                                <option value="Active">Active</option>
                                <option value="Disabled">Disabled</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-4">
              <button type="button" className="btn btnfollower" onClick={addData}>
                UPDATE
              </button>
              <button type="button" style={{}} className="btn cancelfollower" onClick={closeModal}>
                CANCEL
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EditAccessCard;
