import { style } from "glamor";
import React, { useState, useEffect, useContext, useRef } from "react";
import ErrorModel from "views/super-admin/Ticket/components/errormodel";
import SubTasks from "./Sub Task/subTasks";
import provisioningService from "services/provisioningService";
import Swal from "sweetalert2";
import AuthContext from "context";
import Attachments from "./Attachments/attachments";
import Loading from "views/super-admin/pre-loader/loader";
import UsageData from "./Usage Data/usageData";
import moment from "moment";
import AddProvFollowers from "./addFollowers";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { get, isEmpty } from "lodash";
import CloseIcon from "@mui/icons-material/Close";
import PrintIcon from "@mui/icons-material/Print";
import RefreshIcon from "@mui/icons-material/Refresh";
import provisionDetails from "services/provisioningService";
import DescriptionAndAssignSection from "../../Ticket/components/Ticket Detail Popup/shared/descriptionAndAssignSection";
import TagComponent from "./tagsComponent";

const ProvisioningPop = (props) => {
  const [serviceList, setserviceList] = useState([]);
  const { t } = useTranslation();
  const [active, setActive] = useState("log");
  const [errorpopup, setErrorPopup] = useState(false);
  const [toggleEdit, setToggleEdit] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Something went Wrong !");
  const contextStore = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const fileInputRef = useRef(null);
  const [fileData, setFileData] = useState([]);
  const [showAttachment, setShowAttachment] = useState([]);
  const [payload, setPayload] = useState();
  const [isRefreshAttachments, setIsRefreshAttachments] = useState(false);
  const [content, setContent] = useState("");
  const [isRefreshProvisioningData, setIsRefreshProvisioningData] = useState(
    false
  );
  const [istypetrue, setistypetrue] = useState(true);
  const [DesignatedId, setDesignatedId] = useState("");
  const [updateTicketDetails, setUpdateTicketDetails] = useState({
    description: "",
  });
  const [draftDescription, setDraftDescription] = useState("");
  const [usageData, setUsageData] = useState([]);
  const [assignees, setAssignees] = useState([]);
  const [filteredAssignees, setFilteredAssignees] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [isUpdateDescriptionList, setIsUpdateDescriptionList] = useState(false);
  const [state, setState] = useState({
    id: "",
    assignee: "",
    assignee_id: "",
    activity_user: "",
    assignee_is_current_user: false,
    description: "",
    name: "",
    site: "",
    project: "",
    product: "",
    customer: "",
    banId: "",
    end_customer: "",
    ops_contact: "",
    sales_order: "",
    sale_order_item: "",
    service_request_date: "",
    service_commencement_date: "",
    billing_start_date: "",
    sub_tasks: [],
    status_id: "",
    notes: [],
    messages: [],
    product_category: "",
    product_attribute: "",
    breaker: "",
    related_service_id: "",
    related_space_service: "",
    related_space: "",
    space: "",
    other_space: "",
    parent_task: [],
    legacy_ib_number: "",
    remarks: "",
    third_party_circuit: "",
    customer_service_reference: "",
    interconnect: "",
    change_order_line: "",
    tags: [],
    tags_ids: [],
  });
  const [updateProvisioning, setUpdateProvisioning] = useState({});
  const [follower, setFollower] = useState([]);
  const [follows, setFollows] = useState(false);
  const [userFollow, setUserFollows] = useState("");
  const [addfollower, setAddFollower] = useState(false);
  const [buttonText, setButtonText] = useState("Follow");
  const [icon, setIcon] = useState(follows ? "fas fa-check" : "black");
  const [buttonStyle, setButtonStyle] = useState({
    color: follows ? "#2cc970" : "black",
  });
  const [spaceList, setSpaceList] = useState([]);
  const [patchPanelListA, setPatchPanelListA] = useState([]);
  const [patchPanelPortListA, setPatchPanelPortListA] = useState([]);
  const [patchPanelListZ, setPatchPanelListZ] = useState([]);
  const [patchPanelPortListZ, setPatchPanelPortListZ] = useState([]);
  const [selectedSpace, setSelectedSpace] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [spaceName, setSpaceName] = useState("");
  const [tags, setTags] = useState([]);
  const [tagIds, setTagIds] = useState([]);

  // react-select custom style for orange border
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: "#ff7a00",
      boxShadow: "0 0 0 1px #ff7a00",
    }),
  };

  const handlePrint = () => {
    let followerDiv = document.querySelector("#follwerDropdown");
    followerDiv.classList.remove("dropdown-menu");
    followerDiv.classList.add("follow-dropdown-menu");
    html2canvas(document.querySelector("#capture"), {
      windowHeight: document.querySelector("#content-area").scrollHeight + 500,
    }).then((canvas) => {
      var imgWidth = 210;
      var pageHeight = 290;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF("p", "mm");
      var position = 0;
      var pageData = canvas.toDataURL("image/jpeg", 1.0);
      var imgData = encodeURIComponent(pageData);
      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      doc.setLineWidth(5);
      doc.setDrawColor(255, 255, 255);
      doc.rect(0, 0, 210, 295);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        doc.setLineWidth(5);
        doc.setDrawColor(255, 255, 255);
        doc.rect(0, 0, 210, 295);
        heightLeft -= pageHeight;
      }
      window.open(
        doc.output("bloburl", { filename: "new-file.pdf" }),
        "_blank"
      );
      followerDiv.classList.add("dropdown-menu");

      // doc.save('file.pdf');
    });
  };

  useEffect(() => {
    if (!checkPermission("Log Notes") && checkPermission("Send Message")) {
      setActive("message");
    }

    if (!checkPermission("Log Notes") && !checkPermission("Send Message")) {
      setActive("Attachment");
    }
  }, [props.permissions]);
  const ticketstatusname = [
    { data: "New", id: 1 },
    { data: "In Progress", id: 2 },
    { data: "Pending Customer", id: 3 },
    { data: "Scheduled", id: 4 },
    { data: "Completed", id: 5 },
    { data: "Cancelled", id: 6 },
    { data: "Closed", id: 7 },
  ];

  useEffect(() => {
    if (props.currentServiceId != "") {
      setActive("log");
    }
  }, [props.currentServiceId]);

  useEffect(() => {
    getAssignees();
    getServiceTags();
  }, []);

  // useEffect(() => {
  //   console.log(serviceList);
  // }, [serviceList]);
  const checkPermission = (permissionName) => {
    if (permissionName) {
      if (permissionName == "Maintenance Status") {
        return true;
      } else {
        const isPermitted = props?.permissions?.filter(
          (el) => el.name == permissionName && el.is_allowed == 1
        );

        if (isPermitted?.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    }
    return false;
  };

  useEffect(() => {
    if (props.ticketData.messages === null) {
      props.ticketData.messages = "";
    } else {
      props.ticketData?.messages.map((val) => {
        val.type = "message";
        return val;
      });
    }
    if (props.ticketData.notes === null) {
      props.ticketData.notes = "";
    } else {
      props.ticketData.notes.map((val) => {
        val.type = "note";
        return val;
      });
    }
    if (props.ticketData.logs === null) {
      props.ticketData.logs = "";
    } else {
      props.ticketData.logs.map((val) => {
        val.type = "logs";
        return val;
      });
    }

    setState({
      id: props.ticketData.id,
      assignee: props.ticketData.assignee,
      activity_user: props.ticketData.activity_user,
      assignee_id: props.ticketData.assignee_id,
      assignee_is_current_user: props.ticketData.assignee_is_current_user,
      description: props.ticketData.description,
      name: props.ticketData.name,
      site: props.ticketData.site,
      project: props.ticketData.project,
      product: props.ticketData.product,
      customer: props.ticketData.customer,
      banId: props.ticketData.ban,
      end_customer: props.ticketData.end_customer,
      notes: [
        ...props.ticketData.notes,
        ...props.ticketData.messages,
        ...props.ticketData.logs,
      ],
      messages: props.ticketData.messages,
      ops_contact: props.ticketData.ops_contact,
      sales_order: props.ticketData.sale_order,
      sale_order_item: props.ticketData.sale_order_item,
      service_request_date: props.ticketData.service_request_date,
      service_commencement_date: props.ticketData.service_commencement_date,
      billing_start_date: props.ticketData.billing_start_date,
      task_type: props.ticketData.task_type,
      sub_tasks: props.ticketData.sub_tasks,
      status_id: props.ticketData.status_id,
      product_category: props.ticketData.product_category,
      product_attribute: props.ticketData.product_info.product_attribute,
      related_service_id: props.ticketData.product_info.related_space_service,
      breaker: props.ticketData.product_info.breaker,
      related_space_service:
        props.ticketData.product_info.related_space_service,
      related_space: props.ticketData.product_info.related_space,
      space: props.ticketData.product_info.space,
      other_space: props.ticketData.product_info.other_space,
      parent_task: !!props.ticketData.parent ? [props.ticketData.parent] : [],
      legacy_ib_number: props.ticketData.legacy_ib_number,
      remarks: props.ticketData.remarks,
      third_party_circuit: props.ticketData.third_party_circuit,
      customer_service_reference: props.ticketData.customer_service_reference,
      interconnect: props.ticketData.interconnect,
      change_order_line: props.ticketData.change_order_line,
      tags: props.ticketData.tags,
      tags_ids: props.ticketData.tags_ids,
    });
    getFollowList();
  }, [props.ticketData]);

  useEffect(() => {
    if (props.provision) {
      document
        .getElementById("main-model")
        .addEventListener("click", async function (e) {
          if (
            document.getElementById("content-area") != null &&
            document.getElementById("content-area").contains(e.target)
          ) {
          } else {
            props.handleExitbutton();
          }
        });
    }
  }, [props.provision]);

  useEffect(() => {
    if (active == "usage-data") {
      getUsageData();
    }
  }, [active]);

  const ColourStyle = (val) => {
    let styleClass = "";
    let value = val?.toLowerCase();
    switch (value) {
      case "install":
        styleClass = "dark_green";
        break;

      case "deinstall":
        styleClass = "";
        break;

      default:
        styleClass = "yellow-s1";
        break;
    }
    return styleClass;
  };

  // this sets the desc value from res on component load
  useEffect(() => {
    if (state?.description) {
      setUpdateProvisioning((prev) => ({
        ...prev,
        description: state.description || "",
      }));
    }
  }, [state?.description]);

  useEffect(() => {
    setUpdateProvisioning((prev) => ({
      ...prev,
      tag_ids: tagIds,
    }));
  }, [tagIds]);
  // useEffect(() => {
  //   console.log(updateTicketDetails);
  // }, [updateTicketDetails]);

  const handleChangeDescription = (e) => {
    let strippedString = e.target.value.replace(/(<([^>]+)>)/gi, "");
    setUpdateProvisioning((prev) => ({
      ...prev,
      description: strippedString,
    }));
  };

  const updateTicket = async () => {
    setIsLoading(true);
    await provisionDetails.updateServices(
      localStorage.getItem("token"),
      props.ticketData.id,
      updateProvisioning
    );
    console
      .log(props.ticketData.id, updateProvisioning)
      .then((res) => {
        if (res?.data?.code !== undefined || res?.data?.error !== undefined) {
          // console.log("here")
          setErrorPopup(true);

          //setStatusChange(true)
        } else {
          setToggleEdit(false);
          // updateTicketDetail(false);
          props.getServices(
            props.ticketData?.id,
            props.ticketData?.tickettypeid,
            props.ticketData?.ticket_sub_type_name,
            props.ticketData?.tickettype
          );
          Swal.fire({
            title: "Updated Successfully",
            icon: "success",
            customClass: {
              confirmButton: "confirmBtn",
            },
          });
          setIsUpdateDescriptionList(true);
          // props.handleModalPopupOnClick(props.sitename, props.ticketData.id)
        }
      })
      .catch((err) => {
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateTicketDetail = async (status, assign = false) => {
    setIsLoading(true);
    await provisionDetails
      .updateServices(localStorage.getItem("token"), props.ticketData.id)
      .then((res) => {
        //setprops.ticketData(res?.data?.data);
        if (!res?.data?.data) {
          setErrorPopup(true);
        }

        let ticket_Detail = res?.data?.data;
        if (ticket_Detail.messages === null) {
          ticket_Detail.messages = "";
        } else {
          ticket_Detail.messages.map((val) => {
            val.type = "message";
            return val;
          });
        }
        if (ticket_Detail.attachments === null) {
          ticket_Detail.attachments = [];
        } else {
          ticket_Detail.attachments.map((val) => {
            val.type = "attachments";
            return val;
          });
        }
        if (ticket_Detail.notes === null) {
          ticket_Detail.notes = "";
        } else {
          ticket_Detail.notes.map((val) => {
            val.type = "note";
            return val;
          });
        }
        if (ticket_Detail.logs === null) {
          ticket_Detail.logs = "";
        } else {
          ticket_Detail.logs.map((val) => {
            val.type = "logs";
            return val;
          });
        }
        let service_id = [];
        let space_id = [];
        let room_ids = [];
        if (
          ticket_Detail.site_access_services !== null &&
          ticket_Detail.site_access_services.length > 0
        ) {
          ticket_Detail.site_access_services.map((data) => {
            service_id.push(data.name);
          });
        }
        if (
          ticket_Detail.site_access_spaces !== null &&
          ticket_Detail.site_access_spaces.length > 0
        ) {
          ticket_Detail.site_access_spaces.map((data) => {
            space_id.push(data.name);
          });
        }
        if (
          props?.props.ticketData?.site_access_visiting_rooms !== null &&
          props?.props.ticketData?.site_access_visiting_rooms.length > 0
        ) {
          props.ticketData.site_access_visiting_rooms.map((data) => {
            room_ids.push(data.name);
          });
        }
        if (assign) {
          setState({
            ...state,
            assignto: ticket_Detail.assignto,
            notes: [
              ...ticket_Detail.notes,
              ...ticket_Detail.messages,
              ...ticket_Detail.logs,
            ],
            messages: ticket_Detail.messages,
            subject: ticket_Detail.subject,
            attachments: ticket_Detail.attachments,
            tags: ticket_Detail.tags,
            tags_ids: ticket_Detail.tags_ids,
          });
        } else {
          setState({
            ticket_id: ticket_Detail.id,
            company: ticket_Detail.company,
            type: ticket_Detail.tickettype,
            service_id: ticket_Detail.serviceid,
            priority: ticket_Detail.priority,
            description: ticket_Detail.description,
            subject: ticket_Detail.subject,
            attachments: ticket_Detail.attachments,
            notes: [
              ...ticket_Detail.notes,
              ...ticket_Detail.messages,
              ...ticket_Detail.logs,
            ],
            ticket_status_id: ticket_Detail.ticket_statusid,
            ticket_status: ticket_Detail.ticket_status,
            messages: ticket_Detail.messages,
            tickettypeid: ticket_Detail.tickettypeid,
            team_id: ticket_Detail.teamid,
            assignto: ticket_Detail.assignto,
            designatedcompany: ticket_Detail.designatedcompany,
            visitors: ticket_Detail.visitors,
            customer_email: ticket_Detail.partner_email,
            visit_date:
              ticket_Detail.site_visit_date_start +
              " - " +
              ticket_Detail.site_visit_date_end,
            special_visit_name: ticket_Detail.special_visit_area_name,
            service_ids: service_id.length > 0 ? service_id.toString() : "-",
            space_ids: space_id.length > 0 ? space_id.toString() : "-",
            site_access_visiting_rooms:
              room_ids.length > 0 ? room_ids.toString() : "-",
            subcategory: ticket_Detail.subcategory,
            impacted_device: ticket_Detail?.details?.impacted_device,
            sub_tickets: ticket_Detail.sub_tickets,
            category: ticket_Detail.category,
            priorityid: ticket_Detail.priorityid,
            impacted_device_id: ticket_Detail?.details?.impacted_device_id,
            readiness_id: ticket_Detail.readiness_id,
            assignee: ticket_Detail.assignee,
            project: ticket_Detail.project,
            product: ticket_Detail.product,
            assignee_id: ticket_Detail.assignto_id,
            assignee_is_current_user: ticket_Detail.assignee_is_current_user,
            portal_code: ticket_Detail.portal_code,
            tags: ticket_Detail.tags,
            tags_ids: ticket_Detail.tags_ids,
          });
          // setistypetrue(true);
          setDesignatedId(parseInt(props.ticketData.designated_company_id));
          setUpdateProvisioning({
            description: ticket_Detail.description,
            impacted_device_id: parseInt(
              ticket_Detail?.details?.impacted_device_id
            ),
            category: ticket_Detail.category,
            //subcategory_id: parseInt(ticket_Detail.subcategory),
            severity: parseInt(ticket_Detail.priorityid),
          });
        }
        setistypetrue(true);

        if (status) {
        } else {
          Swal.fire({
            title: "Updated Successfully",
            icon: "success",
            customClass: {
              confirmButton: "confirmBtn",
            },
          });
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const unassignTaskTo = async () => {
    setIsLoading(true);
    const assignee = assignees.filter(
      (assignee) => assignee.id === state.assignee_id
    );
    const email = assignee[0].email;
    await provisionDetails
      .unassignFromMe(localStorage.getItem("token"), props.ticketData.id, {
        email: email,
      })
      .then((res) => {
        if (res?.data?.code !== undefined || res?.data?.error !== undefined) {
          console.log("here");
          setErrorMsg(res.data);
          setErrorPopup(true);
          setIsLoading(false);
        } else {
          updateTicketDetail(false);
          // Swal.fire("Updated Successfully.");
          // props.handleModalPopupOnClick(props.sitename, props.ticketData.id)
        }
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        // setIsLoading(false);
      });
  };

  const updateStage = async (stageId, selectedkey) => {
    let currentStatus;
    props.statusList &&
      props.statusList.map((data, key, elements) => {
        if (state.status_id == data?.id) {
          currentStatus = key;
        }
      });

    if (selectedkey <= currentStatus) {
      await Swal.fire("Error", "You can't select the previous stage.");
    } else {
      Swal.fire({
        text: t("common.confirm_status_update"),
        icon: "question",
        showCancelButton: true,
        confirmButtonText: t("common.yes"),
        cancelButtonText: t("common.no"),
        customClass: {
          cancelButton: "cancelBtn",
          confirmButton: "confirmBtn",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          setIsLoading(true);
          await provisioningService
            .updateStatus(localStorage.getItem("token"), props.ticketData.id, {
              stage_id: stageId,
            })
            .then((res) => {
              if (
                res?.data?.code !== undefined ||
                res?.data?.error !== undefined
              ) {
                console.log("here");
                setErrorMsg(res.data);
                console.log("here");
                setErrorPopup(true);
                setIsLoading(false);

                //setStatusChange(true)
              } else {
                Swal.fire({
                  title: "Updated Successfully",
                  icon: "success",
                  customClass: {
                    confirmButton: "confirmBtn",
                  },
                });
                props.refreshData(props.ticketData.id);
                props.fetchServices();
              }
            })
            .catch((err) => {
              console.log(err.error.response);
              setErrorMsg(err.error.response.data.error);
              setErrorPopup(true);
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
      });
    }
  };
  const removeNullValues = (data) => {
    const result = {};
    for (const key in data) {
      if (data[key] !== null) {
        result[key] = data[key];
      }
    }
    return result;
  };

  const updateService = async () => {
    setIsLoading(true);
    if (!isEmpty(updateProvisioning)) {
      await provisioningService
        .updateServices(
          localStorage.getItem("token"),
          props.ticketData.id,
          updateProvisioning
        )

        .then((res) => {
          if (res?.data?.code !== undefined || res?.data?.error !== undefined) {
            setErrorMsg(res.data);
            setErrorPopup(true);
            setIsLoading(false);

            //setStatusChange(true)
          } else {
            Swal.fire({
              title: "Updated Successfully",
              icon: "success",
              customClass: {
                confirmButton: "confirmBtn",
              },
            });
            setToggleEdit(false);
            props.fetchServices();
            props.refreshData(props.ticketData.id);
            setToggleEdit(false);
            setserviceList([]);
            setSpaceList([]);
            setPatchPanelListA([]);
            setPatchPanelListZ([]);
            setPatchPanelPortListA([]);
            setPatchPanelPortListZ([]);
            setUpdateProvisioning({});
          }
        })
        .catch((err) => {
          console.log(err.error.response);
          setErrorMsg(err.error.response.data.error);
          setErrorPopup(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      setToggleEdit(false);
    }
  };

  const encodeFileToBase64 = (file, callback) => {
    const reader = new FileReader();

    reader.onload = () => {
      const base64Data = reader.result.split(",")[1];
      callback(null, base64Data);
    };

    reader.onerror = (error) => {
      callback(error);
    };

    reader.readAsDataURL(file);
  };

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const addAttachment = async (imagedata) => {
    setIsLoading(true);
    await provisioningService
      .addServiceAttachment(localStorage.getItem("token"), state.id, imagedata)
      .then((res) => {
        if (res?.data?.code !== undefined || res?.data?.error !== undefined) {
          console.log("here");
          setErrorMsg(res.data);
          console.log("here");
          setErrorPopup(true);
          setIsLoading(false);

          //setStatusChange(true)
        } else {
          let Fdata = res.data.data.id;
          if (active === "log" || active === "message") {
            setShowAttachment((prev) => {
              let file = { name: imagedata.file_name, id: res.data.data.id };
              return [...prev, file];
            });
            setFileData([...fileData, Fdata]);
          }
        }
      })
      .finally((done) => {
        setIsRefreshAttachments(true);
        setIsLoading(false);
      });
  };

  const handleFileChange = (event) => {
    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      encodeFileToBase64(file, async (err, base64) => {
        setPayload({
          file_name: file.name,
          content: base64,
        });
        await addAttachment({
          file_name: file.name,
          content: base64,
        }).then(() => {
          if (i + 1 == event.target.files.length) {
            // updateTicketDetail(true);
          }
        });
      });
    }
  };

  const getServiceAttachment = async (attachmentId, fileName) => {
    setIsLoading(true);
    await provisioningService
      .getServiceAttachment(
        localStorage.getItem("token"),
        state.id,
        attachmentId
      )
      .then((res) => {
        if (res?.data?.data === undefined) {
          console.log("here");
          setErrorMsg(res.data);
          console.log("here");
          setErrorPopup(true);
          setIsLoading(false);
          //setStatusChange(true)
        } else {
          handleDownload(res.data.data.data, fileName);
        }
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getUsageData = async () => {
    setIsLoading(true);
    await provisioningService
      .getServiceUsageData(localStorage.getItem("token"), props.ticketData.id)
      .then((res) => {
        if (res?.data?.code !== undefined || res?.data?.error !== undefined) {
          console.log("here");
          setErrorMsg(res.data);
          console.log("here");
          setErrorPopup(true);
          setIsLoading(false);

          //setStatusChange(true)
        } else {
          setUsageData(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleDownload = (base64String, fileName, filetype) => {
    // Convert the base64 string to a Blob
    const byteCharacters = atob(base64String);
    const byteArrays = new Uint8Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteArrays[i] = byteCharacters.charCodeAt(i);
    }
    const blob = new Blob([byteArrays]);

    // Create a URL for the Blob
    const blobUrl = URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement("a");
    link.href = blobUrl;
    link.target = "_blank"; // Open the link in a new tab/window
    link.textContent = "Click here to download"; // Set the link text

    // Append the link to the document
    link.download = fileName;

    link.click();

    URL.revokeObjectURL(blobUrl);
  };

  const handleDiscard = () => {
    setToggleEdit(false);
    setUpdateProvisioning((prev) => ({
      ...prev,
      description: props.ticketData.description,
    }));
  };

  const handleDelete = async (i) => {
    setIsLoading(true);
    try {
      const delData = await provisioningService.deleteServiceAttachment(
        localStorage.getItem("token"),
        state.id,
        i
      );
      if (delData) {
        setIsRefreshAttachments(true);
        setShowAttachment((prev) => [...prev.filter((data) => data.id !== i)]);
        setFileData((prev) => prev.filter((data) => data !== i));
        props.refreshData(props.ticketData.id);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const sendMessage = async () => {
    if (content != "") {
      setIsLoading(true);
      if (active == "message") {
        await provisioningService
          .createServiceMessage(localStorage.getItem("token"), state.id, {
            content: content,
            attachments: fileData,
          })
          .then((res) => {
            if (
              res?.data?.code !== undefined ||
              res?.data?.error !== undefined
            ) {
              console.log("here");
              setErrorMsg(res.data);
              console.log("here");
              setErrorPopup(true);
              setIsLoading(false);

              //setStatusChange(true)
            } else {
              props.refreshData(props.ticketData.id);
              Swal.fire({
                title: "Updated Successfully",
                icon: "success",
                customClass: {
                  confirmButton: "confirmBtn",
                },
              });
              setContent("");
              setFileData([]);
              setShowAttachment([]);
              //props.handleModalPopupOnClick(props.sitename, props.ticketData.id)
            }
          })
          .catch((err) => {
            console.log(err.error.response);
            setErrorMsg(err.error.response.data.error);
            setErrorPopup(true);
          })
          .finally(() => {
            setIsLoading(false);
          });
      } else {
        await provisioningService
          .createServiceNote(localStorage.getItem("token"), state.id, {
            content: content,
            attachments: fileData,
          })
          .then((res) => {
            if (
              res?.data?.code !== undefined ||
              res?.data?.error !== undefined
            ) {
              console.log("here");
              setErrorMsg(res.data);
              console.log("here");
              setErrorPopup(true);
              setIsLoading(false);
            } else {
              props.refreshData(props.ticketData.id);
              Swal.fire({
                title: "Updated Successfully",
                icon: "success",
                customClass: {
                  confirmButton: "confirmBtn",
                },
              });
              setContent("");
              setFileData([]);
              setShowAttachment([]);
              // props.handleModalPopupOnClick(props.sitename, props.ticketData.id)
            }
          })
          .catch((err) => {
            console.log(err.error.response);
            const error = err.error.response.data.error;
            if (error.code == 400 && error.data == "BOSS Restriction/error") {
              error.detail =
                "There is an error performing the action and communicating with BOSS. Please capture the date, time and this message and report this to Ops Portal Team. In the meantime, you may perform the action in BOSS.";
            }
            setErrorMsg(err.error.response.data.error);
            setErrorPopup(true);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  };

  const separateArrayDateWise = (history) => {
    const data = history.sort(function (a, b) {
      var c = new Date(a.date);
      var d = new Date(b.date);
      return d - c;
    });
    // Use reduce to separate array based on date
    const separatedByDate = data.reduce((acc, obj) => {
      const date = moment(obj.date).format("MMMM DD, YYYY");
      // If the date key doesn't exist in the accumulator, create an array for it
      if (!acc[date]) {
        acc[date] = [];
      }

      // Push the current object to the array corresponding to its date
      acc[date].push(obj);

      return acc;
    }, {});

    const mappedData = Object.entries(separatedByDate).map(([date, items]) => ({
      date,
      items,
    }));
    return mappedData;
  };

  const getTime = (date) => {
    let result = moment(date).fromNow();
    const now = moment();
    const days = now.diff(date, "days");
    const weeks = now.diff(date, "weeks");
    if (days >= 7) {
      if (days <= 13) {
        result = `a week ago`;
      } else if (days > 13 && days <= 25) {
        result = `${weeks} weeks ago`;
      }
    }
    return result;
  };

  const replace = (description) => {
    let result = description.replace(/<div>/g, " \n");
    result = result.replace(/<\/div>/g, "");
    result = result.replace(/<br \/>/g, "\n");
    result = result.replace(/<br>/g, "\n");
    result = result.replace(/&nbsp;/g, " ");
    return result;
  };

  const getAssignees = async () => {
    setIsLoading(true);
    await provisioningService
      .getAssignees(localStorage.getItem("token"))
      .then((res) => {
        if (res?.data?.code !== undefined || res?.data?.error !== undefined) {
          console.log("here");
          setErrorMsg(res.data);
          console.log("here");
          setErrorPopup(true);
          setIsLoading(false);

          //setStatusChange(true)
        } else {
          setAssignees(res.data.data);
          setFilteredAssignees(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const assignTaskTo = async (email) => {
    setSearchValue("");
    setFilteredAssignees(assignees);
    setIsLoading(true);
    await provisioningService
      .assignTaskTo(localStorage.getItem("token"), props.ticketData.id, {
        email: email,
      })
      .then((res) => {
        console.log("test", res.data);
        if (res?.data?.code !== undefined || res?.data?.error !== undefined) {
          console.log("here");
          setErrorMsg(res.data);
          setErrorPopup(true);
          setIsLoading(false);
          //setStatusChange(true)
        } else {
          Swal.fire({
            title: "Updated Successfully",
            icon: "success",
            customClass: {
              confirmButton: "confirmBtn",
            },
          });
          props.fetchServices();
          props.refreshData(props.ticketData.id);
        }
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const HandleSearch = (val) => {
    setSearchValue(val);
    setFilteredAssignees(filterByValue(assignees, val));
  };

  const filterByValue = (array, value) => {
    return array.filter(
      (data) =>
        JSON.stringify(data).toLowerCase().indexOf(value.toLowerCase()) !== -1
    );
  };

  const follow = async () => {
    setIsLoading(true);
    await provisioningService
      .serviceSubscribe(localStorage.getItem("token"), state.id)
      .then((res) => {
        if (res?.data?.data?.success === undefined) {
          setErrorMsg(res.data);
          setErrorPopup(true);
        } else {
          setFollows(true);
          getFollowList();
        }
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const unfollow = async () => {
    setIsLoading(true);
    await provisioningService
      .serviceUnsubscribe(localStorage.getItem("token"), state.id)
      .then((res) => {
        if (res?.data?.data?.success === undefined) {
          setErrorMsg(res.data);
          setErrorPopup(true);
        } else {
          setFollows(false);
          getFollowList();
        }
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getFollowList = async () => {
    // console.log("provi---",contextStore.getAuth?.name)
    setIsLoading(true);
    await provisioningService
      .serviceFollower(localStorage.getItem("token"), props.ticketData.id)
      .then((res) => {
        const userfollow = res.data.data.find(
          (team) => team.name === contextStore.getAuth?.name
        );
        const userExists = Boolean(userfollow);
        if (userExists) {
          setFollows(true);
          setButtonText("Following");
          setIcon("fas fa-check");
          setButtonStyle({ color: "#2cc970" });
        } else {
          setFollows(false);
          setButtonText("Follow");
          setIcon("");
          setButtonStyle({ color: "black" });
        }
        setFollower(res.data.data);
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const delfollower = async (contact_id) => {
    await Swal.fire({
      title: "Are you sure?",
      text: "you want to remove follower.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      customClass: {
        cancelButton: "cancelBtn",
        confirmButton: "confirmBtn",
      },
    }).then((res) => {
      if (res.isConfirmed) {
        provisioningService
          .serviceRemoveFollower(
            localStorage.getItem("token"),
            state.id,
            contact_id
          )
          .then((res) => {
            getFollowList();
          })
          .catch((err) => {
            console.log(err.error.response);
            setErrorMsg(err.error.response.data.error);
            setErrorPopup(true);
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    });
  };

  useEffect(() => {
    if (serviceName !== "") {
      const getData = setTimeout(() => {
        getServiceList(serviceName);
      }, 1000);
      return () => clearTimeout(getData);
    }
  }, [serviceName]);

  useEffect(() => {
    if (spaceName !== "") {
      const getData = setTimeout(() => {
        getSpaceList(spaceName);
      }, 1000);
      return () => clearTimeout(getData);
    }
  }, [spaceName]);

  useEffect(() => {
    if (toggleEdit && state.name.includes("Cross Connect")) {
      setIsLoading(true);
      handleServiceList();
      getSpaceList();
      if (
        props.ticketData?.interconnect?.cross_connect_a_side?.a_side_space_id
      ) {
        setIsLoading(true);
        getSpaceList(
          props.ticketData?.interconnect?.cross_connect_a_side?.a_side_space_id
        );
        getPatchPanelListBySpaceId(
          props.ticketData?.interconnect?.cross_connect_a_side
            ?.a_side_space_id_id,
          "A"
        );
      } else {
        getPatchPanelList("A");
      }
      if (
        props.ticketData?.interconnect?.cross_connect_z_side?.z_side_space_id
      ) {
        setIsLoading(true);
        getSpaceList(
          props.ticketData?.interconnect?.cross_connect_z_side?.z_side_space_id
        );
        getPatchPanelListBySpaceId(
          props.ticketData?.interconnect?.cross_connect_z_side
            ?.z_side_space_id_id,
          "Z"
        );
      } else {
        getPatchPanelList("Z");
      }
      if (
        props.ticketData?.interconnect?.cross_connect_a_side
          ?.a_side_interconnect_patchpanel_id
      ) {
        setIsLoading(true);
        getPatchPanelPortList(
          props.ticketData?.interconnect?.cross_connect_a_side
            ?.a_side_space_id_id,
          props.ticketData?.interconnect?.cross_connect_a_side
            ?.a_side_interconnect_patchpanel_id_id,
          "A"
        );
      }
      if (
        props.ticketData?.interconnect?.cross_connect_z_side
          ?.z_side_interconnect_patchpanel_id
      ) {
        setIsLoading(true);
        getPatchPanelPortList(
          props.ticketData?.interconnect?.cross_connect_z_side
            ?.z_side_space_id_id,
          props.ticketData?.interconnect?.cross_connect_z_side
            ?.z_side_interconnect_patchpanel_id_id,
          "Z"
        );
      }
    }
  }, [toggleEdit]);

  const handleServiceList = async () => {
    await getServiceList().then(async () => {
      if (
        props.ticketData?.interconnect?.cross_connect_a_side
          ?.a_side_service_id ||
        props.ticketData?.interconnect?.cross_connect_z_side?.z_side_service_id
      ) {
        setIsLoading(true);
        await getServiceList(
          props.ticketData?.interconnect?.cross_connect_a_side
            ?.a_side_service_id
        );
      }
      if (
        props.ticketData?.interconnect?.cross_connect_z_side?.z_side_service_id
      ) {
        setIsLoading(true);
        await getServiceList(
          props.ticketData?.interconnect?.cross_connect_z_side
            ?.z_side_service_id
        );
      }
    });
  };

  const getValue = (field, list, side) => {
    if (side === "A") {
      const value =
        updateProvisioning[field] !== undefined
          ? updateProvisioning[field]
          : props.ticketData?.interconnect?.cross_connect_a_side[field + "_id"]
          ? props.ticketData?.interconnect?.cross_connect_a_side[field + "_id"]
          : "";
      return list.length > 0
        ? list.filter((option) => option.value === value)
        : "";
    }
    if (side === "Z") {
      const value =
        updateProvisioning[field] !== undefined
          ? updateProvisioning[field]
          : props.ticketData?.interconnect?.cross_connect_z_side[field + "_id"]
          ? props.ticketData?.interconnect?.cross_connect_z_side[field + "_id"]
          : "";
      return list.length > 0
        ? list.filter((option) => option.value === value)
        : "";
    }
  };

  const handleChange = (field, data) => {
    if (field === "a_side_service_id") {
      setPatchPanelListA([]);
      setPatchPanelPortListA([]);
      delete updateProvisioning["a_side_service_id"];
      delete updateProvisioning["a_side_space_id"];
      delete updateProvisioning["a_side_interconnect_patchpanel_id"];
      delete updateProvisioning["a_side_interconnect_patchpanel_ports_id"];
      setUpdateProvisioning(updateProvisioning);
      setUpdateProvisioning((prev) => ({
        ...prev,
        a_side_service_id: !!data ? data.value : null,
        a_side_space_id: !!data ? data.space_id : null,
        a_side_interconnect_patchpanel_id: null,
        a_side_interconnect_patchpanel_ports_id: null,
      }));
      if (!!data && !!data.space) {
        getSpaceList(data.space);
        getPatchPanelListBySpaceId(data.space_id, "A");
      } else {
        getPatchPanelList("A");
      }
    }
    if (field === "a_side_space_id") {
      setPatchPanelListA([]);
      setPatchPanelPortListA([]);
      getPatchPanelListBySpaceId(data.value, "A");
      delete updateProvisioning["a_side_space_id"];
      delete updateProvisioning["a_side_interconnect_patchpanel_id"];
      delete updateProvisioning["a_side_interconnect_patchpanel_ports_id"];
      setUpdateProvisioning(updateProvisioning);
      setUpdateProvisioning((prev) => ({
        ...prev,
        a_side_space_id: data.value,
        a_side_interconnect_patchpanel_id: null,
        a_side_interconnect_patchpanel_ports_id: null,
      }));
    }

    if (field === "a_side_interconnect_patchpanel_id") {
      console.log(data);
      setPatchPanelPortListA([]);
      getPatchPanelPortList(
        updateProvisioning["a_side_space_id"] !== undefined
          ? updateProvisioning["a_side_space_id"]
          : props.ticketData?.interconnect?.cross_connect_a_side[
              "a_side_space_id_id"
            ],
        data.value,
        "A"
      );
      delete updateProvisioning["a_side_interconnect_patchpanel_id"];
      delete updateProvisioning["a_side_interconnect_patchpanel_ports_id"];
      setUpdateProvisioning(updateProvisioning);
      setUpdateProvisioning((prev) => ({
        ...prev,
        a_side_interconnect_patchpanel_id: data.value,
        a_side_interconnect_patchpanel_ports_id: null,
      }));
    }
    if (field === "a_side_interconnect_patchpanel_ports_id") {
      delete updateProvisioning["a_side_interconnect_patchpanel_ports_id"];
      setUpdateProvisioning(updateProvisioning);
      setUpdateProvisioning((prev) => ({
        ...prev,
        a_side_interconnect_patchpanel_ports_id: data.value,
      }));
    }
    if (field === "z_side_service_id") {
      setPatchPanelListZ([]);
      setPatchPanelPortListZ([]);
      delete updateProvisioning["z_side_service_id"];
      delete updateProvisioning["z_side_space_id"];
      delete updateProvisioning["z_side_interconnect_patchpanel_id"];
      delete updateProvisioning["z_side_interconnect_patchpanel_ports_id"];
      setUpdateProvisioning(updateProvisioning);
      setUpdateProvisioning((prev) => ({
        ...prev,
        z_side_service_id: !!data ? data.value : null,
        z_side_space_id: !!data ? data.space_id : null,
        z_side_interconnect_patchpanel_id: null,
        z_side_interconnect_patchpanel_ports_id: null,
      }));
      if (!!data && !!data.space) {
        getSpaceList(data.space);
        getPatchPanelListBySpaceId(data.space_id, "Z");
      } else {
        getPatchPanelList("Z");
      }
    }
    if (field === "z_side_space_id") {
      setPatchPanelListZ([]);
      setPatchPanelPortListZ([]);
      getPatchPanelListBySpaceId(data.value, "Z");
      delete updateProvisioning["z_side_space_id"];
      delete updateProvisioning["z_side_interconnect_patchpanel_id"];
      delete updateProvisioning["z_side_interconnect_patchpanel_ports_id"];
      setUpdateProvisioning(updateProvisioning);
      setUpdateProvisioning((prev) => ({
        ...prev,
        z_side_space_id: data.value,
        z_side_interconnect_patchpanel_id: null,
        z_side_interconnect_patchpanel_ports_id: null,
      }));
    }

    if (field === "z_side_interconnect_patchpanel_id") {
      setPatchPanelPortListZ([]);
      getPatchPanelPortList(
        updateProvisioning["z_side_space_id"] !== undefined
          ? updateProvisioning["z_side_space_id"]
          : props.ticketData?.interconnect?.cross_connect_z_side[
              "z_side_space_id_id"
            ],
        data.value,
        "Z"
      );
      delete updateProvisioning["z_side_interconnect_patchpanel_id"];
      delete updateProvisioning["z_side_interconnect_patchpanel_ports_id"];
      setUpdateProvisioning(updateProvisioning);
      setUpdateProvisioning((prev) => ({
        ...prev,
        z_side_interconnect_patchpanel_id: data.value,
        z_side_interconnect_patchpanel_ports_id: null,
      }));
    }
    if (field === "z_side_interconnect_patchpanel_ports_id") {
      delete updateProvisioning["z_side_interconnect_patchpanel_ports_id"];
      setUpdateProvisioning(updateProvisioning);
      setUpdateProvisioning((prev) => ({
        ...prev,
        z_side_interconnect_patchpanel_ports_id: data.value,
      }));
    }
  };
  const getServiceList = async (name) => {
    setIsLoading(true);
    const body = {
      type: "quicksearch",
      limit: "50",
      filters: [
        {
          name: "country",
          value: props.ticketData.country,
        },
        {
          name: "site",
          value: props.ticketData.site,
        },
        { name: "category", value: "installed_base" },
        { name: "status", value: "in service" },
        {
          name: "product_type",
          value: "IPT%.RC,MCS601.RC,CAB%.RC,CAG%.RC,OFC001.RC,CCL%.RC,IXP%.RC", // mandatory, exhaustive list for now
        },
      ],
    };
    if (name) {
      body.filters.push({
        name: "name",
        value: name,
      });
    }
    await provisioningService
      .getServices(localStorage.getItem("token"), body)
      .then((res) => {
        const data = res.data.data.rows.map((el) => {
          el.label = el.name;
          el.value = el.id;
          return el;
        });
        setserviceList((prev) =>
          [...prev, ...data].filter(
            (item, index, self) =>
              index ===
              self.findIndex((t) => t.id === item.id && t.name === item.name)
          )
        );
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getSpaceList = async (name) => {
    setIsLoading(true);
    const body = {
      filters: [
        {
          name: "site",
          value: props.ticketData.site,
        },
      ],
    };

    if (name) {
      body.filters.push({
        name: "name",
        value: name,
      });
    }
    await provisioningService
      .getSpaceList(localStorage.getItem("token"), body)
      .then((res) => {
        const data = res.data.data.rows.map((el) => {
          el.label = el.name;
          el.value = el.id;
          return el;
        });
        setSpaceList((prev) =>
          [...prev, ...data].filter(
            (item, index, self) =>
              index ===
              self.findIndex((t) => t.id === item.id && t.name === item.name)
          )
        );
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getPatchPanelList = async (side) => {
    setIsLoading(true);
    const body = {
      filters: [
        { name: "space", value: false, operator: "=" },
        { name: "availability", value: true },
      ],
    };
    await provisioningService
      .getPatchPanelList(localStorage.getItem("token"), body)
      .then((res) => {
        const data = res.data.data.rows.map((el) => {
          el.label = el.name;
          el.value = el.id;
          return el;
        });
        if (side === "A") {
          setPatchPanelListA(data);
        }
        if (side === "Z") {
          setPatchPanelListZ(data);
        }
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getPatchPanelListBySpaceId = async (space_id, side) => {
    setIsLoading(true);
    const body = {
      filters: [{ name: "availability", value: true }],
    };
    await provisioningService
      .getPatchPanelListBySpaceId(localStorage.getItem("token"), space_id, body)
      .then((res) => {
        const data = res.data.data.rows.map((el) => {
          el.label = el.name;
          el.value = el.id;
          return el;
        });
        if (side === "A") {
          setPatchPanelListA(data);
        }
        if (side === "Z") {
          setPatchPanelListZ(data);
        }
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getPatchPanelPortList = async (space_id, patch_panel_id, side) => {
    setIsLoading(true);
    const body = {
      limit: 200,
      // filters: [
      //   {
      //     name: 'site',
      //     value: props.ticketData.site
      //   },
      //   {
      //     name: 'customer',
      //     value: props.ticketData.customer_id
      //   }
      // ]
    };
    await provisioningService
      .getPatchPanelPortsByPatchPanelId(
        localStorage.getItem("token"),
        patch_panel_id,
        body
      )
      .then((res) => {
        const data = res.data.data.rows.map((el) => {
          el.label = el.name;
          el.value = el.id;
          return el;
        });
        if (side === "A") {
          setPatchPanelPortListA(data);
        }
        if (side === "Z") {
          setPatchPanelPortListZ(data);
        }
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getServiceTags = async () => {
    setIsLoading(true);
    await provisioningService
      .getServiceTags(localStorage.getItem("token"))
      .then((res) => {
        if (res?.data?.code !== undefined || res?.data?.error !== undefined) {
          console.log("here");
          setErrorMsg(res.data);
          console.log("here");
          setErrorPopup(true);
          setIsLoading(false);

          //setStatusChange(true)
        } else {
          setTags(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const createServiceTags = async (tagName) => {
    setIsLoading(true);
    await provisioningService
      .createServiceTag(localStorage.getItem("token"), { name: tagName })
      .then((res) => {
        if (res?.data?.code !== undefined || res?.data?.error !== undefined) {
          console.log("here");
          setErrorMsg(res.data);
          console.log("here");
          setErrorPopup(true);
          setIsLoading(false);

          //setStatusChange(true)
        } else {
          getServiceTags();
          Swal.fire({
            title: "Tag Added Successfully",
            icon: "success",
            customClass: {
              confirmButton: "confirmBtn",
            },
          });
        }
      })
      .catch((err) => {
        console.log(err.error.response);
        setErrorMsg(err.error.response.data.error);
        setErrorPopup(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  return (
    <>
      <div
        class="modal show bd-example-modal-lg"
        id="main-model"
        style={{ display: "block" }} /*onClick={()=> closeModal()}*/
        tabIndex="-1"
        role="dialog"
        aria-hidden="true"
      >
        <Loading
          loading={isLoading}
          background="rgba(0, 0, 0, 0.4)"
          loaderColor="rgb(248, 153, 3)"
        />
        <div class="modal-dialog modal-xl lay-2-3pop" id="capture">
          <div class="modal-content fault-content" id="content-area">
            <div class="modal-header mt-59">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: ".5rem",
                  marginTop: "2rem",
                }}
              >
                <div
                  className="side-div"
                  style={{ display: "flex", gap: "2rem" }}
                >
                  <h6 class="sel">{state.site}</h6>
                  <h5 className={"sel-s1 " + ColourStyle(state.task_type)}>
                    {state.task_type}
                  </h5>
                </div>
                <div>
                  <h1 class="display-title">{state?.name} </h1>
                </div>
              </div>

              <div className="fc-side-out">
                <div
                  className="faultClose-outer"
                  data-html2canvas-ignore="true"
                >
                  <div
                    style={{
                      display: "flex",
                      gap: "1rem",
                      alignItems: "flex-end",
                      marginTop: "4.5rem",
                    }}
                  >
                    <RefreshIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setIsLoading(true);
                        props.handlePop(state);
                      }}
                      fontSize="large"
                    />

                    <div style={{ width: "4rem" }}>
                      {checkPermission("Print") && (
                        <PrintIcon
                          style={{ cursor: "pointer" }}
                          onClick={(event) => {
                            event.stopPropagation();
                            handlePrint();
                          }}
                          fontSize="large"
                        />
                      )}
                    </div>
                  </div>
                  <div className="faultClose-outer">
                    <CloseIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => props.handleExitbutton()}
                      fontSize="large"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div class="paging_tab">
                {props.statusList &&
                  props.statusList.map((data, key, elements) => (
                    <div class="item3" key={key}>
                      <button
                        className={
                          state.status_id == data?.id
                            ? `btn btn-light tab-button-style tab-btn3 btstyle${key} t-menu-active`
                            : `btn btn-light tab-button-style tab-btn3 btstyle${key}`
                        }
                        onClick={() => {
                          if (checkPermission("Change IB Status")) {
                            updateStage(data?.id, key);
                          }
                        }}
                      >
                        {data?.name}{" "}
                      </button>
                      {state.status_id == data?.id ? (
                        <img
                          src={
                            elements[0]?.id == state.status_id
                              ? ""
                              : "img/second.svg"
                          }
                          alt=""
                          className="imgstyle-ststus"
                        />
                      ) : elements[key - 1]?.id == state.status_id ? (
                        <img
                          src="img/first.svg"
                          alt=""
                          className="imgstyle-ststus"
                        />
                      ) : (
                        <img
                          src="img/third.svg"
                          alt=""
                          className="imgstyle-ststus"
                        />
                      )}
                    </div>
                  ))}
              </div>
            </div>
            <div class="modal-body" style={{ paddingTop: "1rem" }}>
              <div class="" style={{ height: "cal(100%-0%)" }}>
                <div class="">
                  <div class="main_fault">
                    <div className="card-body" style={{ overflow: "hidden" }}>
                      <div class="card-details">
                        <div class="row">
                          <DescriptionAndAssignSection
                            title={
                              state?.project + " - " + state?.product || ""
                            }
                            assigneeName={
                              state.assignee
                                ? state.assignee === "OPS Portal Bot"
                                  ? `Ops Portal Bot (${state.activity_user})`
                                  : state.assignee
                                : "N/A"
                            }
                            checkPermission={checkPermission}
                            assigneeIsCurrentUser={
                              state.assignee_is_current_user
                            }
                            assignees={assignees}
                            handleAssign={assignTaskTo}
                            handleUnassign={unassignTaskTo}
                            assigneeId={state.assignee_id}
                            isEdit={toggleEdit}
                            setIsEdit={setToggleEdit}
                            description={
                              toggleEdit
                                ? updateProvisioning.description
                                  ? updateProvisioning.description.replace(
                                      /(<([^>]+)>)/gi,
                                      ""
                                    )
                                  : ""
                                : state?.description
                                ? state.description.replace(/(<([^>]+)>)/gi, "")
                                : ""
                            }
                            handleChangeDescription={handleChangeDescription}
                            handleUpdate={updateService}
                            handleDiscard={handleDiscard}
                            draftDescription={draftDescription}
                            componentType={"Provisioning"}
                          ></DescriptionAndAssignSection>
                        </div>
                        <div class="row">
                          <div className="col-md-6">
                            <div class="col-md-12">
                              <div className="d-flex">
                                <div>
                                  {" "}
                                  <label
                                    style={{
                                      fontSize: "0.875rem",
                                      color: "#000000",
                                      minWidth: "61px",
                                    }}
                                  >
                                    Customer :
                                  </label>
                                </div>

                                <div>
                                  <b class="ticket-data2">
                                    {state.customer || "-"}
                                  </b>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <label
                                style={{
                                  fontSize: "0.875rem",
                                  color: "#000000",
                                }}
                              >
                                End Customer :
                              </label>
                              <b class="ticket-data2">
                                {state.end_customer || "-"}
                              </b>
                            </div>
                            <div class="col-md-12">
                              <label
                                style={{
                                  fontSize: "0.875rem",
                                  color: "#000000",
                                }}
                              >
                                Ops Contact :
                              </label>
                              <b class="ticket-data2">
                                {state.ops_contact || "-"}
                              </b>
                            </div>

                            <div class="col-md-12">
                              <label
                                style={{
                                  fontSize: "0.875rem",
                                  color: "#000000",
                                }}
                              >
                                Legacy IB No :
                              </label>
                              <b class="ticket-data2">
                                {state?.legacy_ib_number || "-"}
                              </b>
                            </div>
                            <div class="col-md-12">
                              <div className="d-flex">
                                <div>
                                  {" "}
                                  <label
                                    style={{
                                      fontSize: "0.875rem",
                                      color: "#000000",
                                    }}
                                  >
                                    BAN ID :
                                  </label>
                                </div>
                                <div>
                                  <b class="ticket-data2">
                                    {state.banId || "-"}
                                  </b>
                                </div>
                              </div>
                            </div>

                            {state.name.includes("Cross Connect") && (
                              <div class="col-md-12">
                                <div className="d-flex">
                                  <div>
                                    {" "}
                                    <label
                                      style={{
                                        fontSize: "0.875rem",
                                        color: "#000000",
                                      }}
                                    >
                                      Product :
                                    </label>
                                  </div>
                                  <div>
                                    <b class="ticket-data2">
                                      {state.product || "-"}
                                    </b>
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <div>
                                    {" "}
                                    <label
                                      style={{
                                        fontSize: "0.875rem",
                                        color: "#000000",
                                      }}
                                    >
                                      Product Attribute :
                                    </label>
                                  </div>
                                  <div>
                                    <b class="ticket-data2">
                                      {state.product_attribute || "-"}
                                    </b>
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <div>
                                    {" "}
                                    <label
                                      style={{
                                        fontSize: "0.875rem",
                                        color: "#000000",
                                      }}
                                    >
                                      Related Service ID :
                                    </label>
                                  </div>
                                  <div>
                                    <b class="ticket-data2">
                                      {state.related_service_id || "-"}
                                    </b>
                                  </div>
                                </div>
                              </div>
                            )}

                            <div class="col-md-12">
                              {state.product_category == "Power" &&
                                state.project.includes("Installed Base") && (
                                  <>
                                    <div className="d-flex">
                                      <div>
                                        {" "}
                                        <label
                                          style={{
                                            fontSize: "0.875rem",
                                            color: "#000000",
                                          }}
                                        >
                                          Product :
                                        </label>
                                      </div>
                                      <div>
                                        <b class="ticket-data2">
                                          {state.product || "-"}
                                        </b>
                                      </div>
                                    </div>
                                    <div className="d-flex">
                                      <div>
                                        {" "}
                                        <label
                                          style={{
                                            fontSize: "0.875rem",
                                            color: "#000000",
                                          }}
                                        >
                                          Product Attribute :
                                        </label>
                                      </div>
                                      <div>
                                        <b class="ticket-data2">
                                          {state.product_attribute || "-"}
                                        </b>
                                      </div>
                                    </div>
                                    <div className="d-flex">
                                      <div>
                                        {" "}
                                        <label
                                          style={{
                                            fontSize: "0.875rem",
                                            color: "#000000",
                                          }}
                                        >
                                          Breaker ID :
                                        </label>
                                      </div>
                                      <div>
                                        <b class="ticket-data2">
                                          {state.breaker || "-"}
                                        </b>
                                      </div>
                                    </div>
                                    <div className="d-flex">
                                      <div>
                                        {" "}
                                        <label
                                          style={{
                                            fontSize: "0.875rem",
                                            color: "#000000",
                                          }}
                                        >
                                          Related Sapce Service ID :
                                        </label>
                                      </div>
                                      <div>
                                        <b class="ticket-data2">
                                          {state.related_space_service || "-"}
                                        </b>
                                      </div>
                                    </div>
                                    <div className="d-flex">
                                      <div>
                                        {" "}
                                        <label
                                          style={{
                                            fontSize: "0.875rem",
                                            color: "#000000",
                                          }}
                                        >
                                          Related Space ID :
                                        </label>
                                      </div>
                                      <div>
                                        <b class="ticket-data2">
                                          {state.related_space || "-"}
                                        </b>
                                      </div>
                                    </div>
                                  </>
                                )}
                              {state.product_category == "Space" &&
                                state.project.includes("Installed Base") && (
                                  <>
                                    <div className="d-flex">
                                      <div>
                                        {" "}
                                        <label
                                          style={{
                                            fontSize: "0.875rem",
                                            color: "#000000",
                                          }}
                                        >
                                          Product :
                                        </label>
                                      </div>
                                      <div>
                                        <b class="ticket-data2">
                                          {state.product || "-"}
                                        </b>
                                      </div>
                                    </div>
                                    <div className="d-flex">
                                      <div>
                                        {" "}
                                        <label
                                          style={{
                                            fontSize: "0.875rem",
                                            color: "#000000",
                                          }}
                                        >
                                          Product Attribute :
                                        </label>
                                      </div>
                                      <div>
                                        <b class="ticket-data2">
                                          {state.product_attribute || "-"}
                                        </b>
                                      </div>
                                    </div>
                                    <div className="d-flex">
                                      <div>
                                        {" "}
                                        <label
                                          style={{
                                            fontSize: "0.875rem",
                                            color: "#000000",
                                          }}
                                        >
                                          Space :
                                        </label>
                                      </div>
                                      <div>
                                        <b class="ticket-data2">
                                          {state.space || "-"}
                                        </b>
                                      </div>
                                    </div>
                                    <div className="d-flex">
                                      <div>
                                        {" "}
                                        <label
                                          style={{
                                            fontSize: "0.875rem",
                                            color: "#000000",
                                          }}
                                        >
                                          Other Space Name :
                                        </label>
                                      </div>
                                      <div>
                                        <b class="ticket-data2">
                                          {state.other_space || "-"}
                                        </b>
                                      </div>
                                    </div>
                                  </>
                                )}
                            </div>
                            <div class="col-md-12">
                              <div className="d-flex">
                                <div>
                                  {" "}
                                  <label
                                    style={{
                                      fontSize: "0.875rem",
                                      color: "#000000",
                                    }}
                                  >
                                    Tags :
                                  </label>
                                </div>
                                <div style={{ width: "80%" }}>
                                  <TagComponent
                                    options={tags}
                                    createServiceTags={createServiceTags}
                                    savedTags={state.tags}
                                    tagIds={tagIds}
                                    setTagIds={setTagIds}
                                    isEdit={toggleEdit}
                                    checkPermission={checkPermission}
                                  ></TagComponent>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div class="col-md-12">
                              <div className="d-flex">
                                <div>
                                  {" "}
                                  <label
                                    style={{
                                      fontSize: "0.875rem",
                                      color: "#000000",
                                    }}
                                  >
                                    Sales Order :
                                  </label>
                                  <b class="ticket-data2">
                                    {state.sales_order || "-"}
                                  </b>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-12">
                              <div className="d-flex">
                                <div>
                                  {" "}
                                  <label
                                    style={{
                                      fontSize: "0.875rem",
                                      color: "#000000",
                                    }}
                                  >
                                    Sales Order Item :
                                  </label>
                                  <b class="ticket-data2">
                                    {state.sale_order_item || "-"}
                                  </b>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-12">
                              <div className="d-flex">
                                <div>
                                  {" "}
                                  <label
                                    style={{
                                      fontSize: "0.875rem",
                                      color: "#000000",
                                    }}
                                  >
                                    Service Request Date :
                                  </label>
                                </div>
                                <div>
                                  <b class="ticket-data2">
                                    {state.service_request_date || "-"}
                                  </b>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-12">
                              <div className="d-flex">
                                <div>
                                  {" "}
                                  <label
                                    style={{
                                      fontSize: "0.875rem",
                                      color: "#000000",
                                    }}
                                  >
                                    Service Commencement Date :
                                  </label>
                                </div>
                                <div>
                                  <b class="ticket-data2">
                                    {state.service_commencement_date || "-"}
                                  </b>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-12">
                              <div className="d-flex">
                                <div>
                                  {" "}
                                  <label
                                    style={{
                                      fontSize: "0.875rem",
                                      color: "#000000",
                                    }}
                                  >
                                    Billing Start Date :
                                  </label>
                                </div>
                                <div>
                                  <b class="ticket-data2">
                                    {state.billing_start_date || "-"}
                                  </b>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-12">
                              <div className="d-flex">
                                <div>
                                  {" "}
                                  <label
                                    style={{
                                      fontSize: "0.875rem",
                                      color: "#000000",
                                    }}
                                  >
                                    Remarks :
                                  </label>
                                </div>
                                <div>
                                  <b class="ticket-data2">
                                    {state.remarks || "-"}
                                  </b>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-12">
                              <div className="d-flex">
                                <div>
                                  {" "}
                                  <label
                                    style={{
                                      fontSize: "0.875rem",
                                      color: "#000000",
                                    }}
                                  >
                                    3rd Party Circuit ID :
                                  </label>
                                </div>
                                <div>
                                  <b class="ticket-data2">
                                    {state.third_party_circuit || "-"}
                                  </b>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-12">
                              <div className="d-flex">
                                <div>
                                  {" "}
                                  <label
                                    style={{
                                      fontSize: "0.875rem",
                                      color: "#000000",
                                    }}
                                  >
                                    Customer Service Reference :
                                  </label>
                                </div>
                                <div>
                                  <b class="ticket-data2">
                                    {state.customer_service_reference || "-"}
                                  </b>
                                </div>
                              </div>
                              <div className="d-flex">
                                <div>
                                  {" "}
                                  <label
                                    style={{
                                      fontSize: "0.875rem",
                                      color: "#000000",
                                    }}
                                  >
                                    Change Order Line :
                                  </label>
                                </div>
                                <div>
                                  <b class="ticket-data2">
                                    {state.change_order_line || "-"}
                                  </b>
                                </div>
                              </div>
                            </div>
                          </div>
                          {state.name.includes("Cross Connect") && (
                            <div className="col-md-6">
                              <div className="col-md-12">
                                <div className="d-flex">
                                  <div>
                                    {" "}
                                    <label
                                      style={{
                                        fontSize: "1.25rem",
                                        color: "#000000",
                                        minWidth: "61px",
                                        fontWeight: "700",
                                      }}
                                    >
                                      A-Side
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div className="d-flex">
                                  <div>
                                    {" "}
                                    <label
                                      style={{
                                        fontSize: "0.875rem",
                                        color: "#000000",
                                      }}
                                    >
                                      Service ID :
                                    </label>
                                  </div>
                                  {toggleEdit &&
                                  checkPermission("Edit Details") ? (
                                    <div
                                      style={{
                                        width: "400px",
                                        marginLeft: "1rem",
                                        marginBottom: "1rem",
                                      }}
                                    >
                                      <Select
                                        styles={customStyles}
                                        options={serviceList}
                                        value={getValue(
                                          "a_side_service_id",
                                          serviceList,
                                          "A"
                                        )}
                                        onChange={(service) => {
                                          handleChange(
                                            "a_side_service_id",
                                            service
                                          );
                                        }}
                                        onInputChange={(event) => {
                                          setServiceName(event);
                                        }}
                                        isClearable
                                      />
                                    </div>
                                  ) : (
                                    <b class="ticket-data2">
                                      {state.interconnect?.cross_connect_a_side
                                        ?.a_side_service_id || "-"}
                                    </b>
                                  )}
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div className="d-flex">
                                  <div>
                                    {" "}
                                    <label
                                      style={{
                                        fontSize: "0.875rem",
                                        color: "#000000",
                                      }}
                                    >
                                      Space ID :
                                    </label>
                                  </div>
                                  {toggleEdit &&
                                  checkPermission("Edit Details") ? (
                                    <div
                                      style={{
                                        width: "400px",
                                        marginLeft: "1rem",
                                        marginBottom: "1rem",
                                      }}
                                    >
                                      <Select
                                        styles={customStyles}
                                        disabled={
                                          updateProvisioning.a_side_service_id ==
                                          null
                                        }
                                        options={spaceList}
                                        value={getValue(
                                          "a_side_space_id",
                                          spaceList,
                                          "A"
                                        )}
                                        onChange={(space) => {
                                          handleChange(
                                            "a_side_space_id",
                                            space
                                          );
                                        }}
                                        onInputChange={(event) => {
                                          setSpaceName(event);
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <b class="ticket-data2">
                                      {state.interconnect?.cross_connect_a_side
                                        ?.a_side_space_id || "-"}
                                    </b>
                                  )}
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div className="d-flex">
                                  <div>
                                    {" "}
                                    <label
                                      style={{
                                        fontSize: "0.875rem",
                                        color: "#000000",
                                      }}
                                    >
                                      Patch Panel ID :
                                    </label>
                                  </div>
                                  {toggleEdit &&
                                  checkPermission("Edit Details") ? (
                                    <div
                                      style={{
                                        width: "400px",
                                        marginLeft: "1rem",
                                        marginBottom: "1rem",
                                      }}
                                    >
                                      <Select
                                        styles={customStyles}
                                        options={patchPanelListA}
                                        value={getValue(
                                          "a_side_interconnect_patchpanel_id",
                                          patchPanelListA,
                                          "A"
                                        )}
                                        onChange={(panel) => {
                                          handleChange(
                                            "a_side_interconnect_patchpanel_id",
                                            panel
                                          );
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <b class="ticket-data2">
                                      {state.interconnect?.cross_connect_a_side
                                        ?.a_side_interconnect_patchpanel_id ||
                                        "-"}
                                    </b>
                                  )}
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div className="d-flex">
                                  <div>
                                    {" "}
                                    <label
                                      style={{
                                        fontSize: "0.875rem",
                                        color: "#000000",
                                      }}
                                    >
                                      Patch Panel Port ID :
                                    </label>
                                  </div>
                                  {toggleEdit &&
                                  checkPermission("Edit Details") ? (
                                    <div
                                      style={{
                                        width: "400px",
                                        marginLeft: "1rem",
                                        marginBottom: "1rem",
                                      }}
                                    >
                                      <Select
                                        styles={customStyles}
                                        options={patchPanelPortListA}
                                        value={getValue(
                                          "a_side_interconnect_patchpanel_ports_id",
                                          patchPanelPortListA,
                                          "A"
                                        )}
                                        onChange={(port) => {
                                          handleChange(
                                            "a_side_interconnect_patchpanel_ports_id",
                                            port
                                          );
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <b class="ticket-data2">
                                      {state.interconnect?.cross_connect_a_side
                                        ?.a_side_interconnect_patchpanel_ports_id ||
                                        "-"}
                                    </b>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}

                          {state.name.includes("Cross Connect") && (
                            <div className="col-md-6">
                              <div className="col-md-12">
                                <div className="d-flex">
                                  <div>
                                    {" "}
                                    <label
                                      style={{
                                        fontSize: "1.25rem",
                                        color: "#000000",
                                        minWidth: "61px",
                                        fontWeight: "700",
                                      }}
                                    >
                                      Z-Side
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div className="d-flex">
                                  <div>
                                    {" "}
                                    <label
                                      style={{
                                        fontSize: "0.875rem",
                                        color: "#000000",
                                      }}
                                    >
                                      Service ID :
                                    </label>
                                  </div>
                                  {toggleEdit &&
                                  checkPermission("Edit Details") ? (
                                    <div
                                      style={{
                                        width: "400px",
                                        marginLeft: "1rem",
                                        marginBottom: "1rem",
                                      }}
                                    >
                                      <Select
                                        styles={customStyles}
                                        options={serviceList}
                                        value={getValue(
                                          "z_side_service_id",
                                          serviceList,
                                          "Z"
                                        )}
                                        onChange={(service) => {
                                          handleChange(
                                            "z_side_service_id",
                                            service
                                          );
                                        }}
                                        onInputChange={(event) => {
                                          setServiceName(event);
                                        }}
                                        isClearable
                                      />
                                    </div>
                                  ) : (
                                    <b class="ticket-data2">
                                      {state.interconnect?.cross_connect_z_side
                                        ?.z_side_service_id || "-"}
                                    </b>
                                  )}
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div className="d-flex">
                                  <div>
                                    {" "}
                                    <label
                                      style={{
                                        fontSize: "0.875rem",
                                        color: "#000000",
                                      }}
                                    >
                                      Space ID :
                                    </label>
                                  </div>
                                  {toggleEdit &&
                                  checkPermission("Edit Details") ? (
                                    <div
                                      style={{
                                        width: "400px",
                                        marginLeft: "1rem",
                                        marginBottom: "1rem",
                                      }}
                                    >
                                      <Select
                                        styles={customStyles}
                                        options={spaceList}
                                        value={getValue(
                                          "z_side_space_id",
                                          spaceList,
                                          "Z"
                                        )}
                                        onChange={(space) => {
                                          handleChange(
                                            "z_side_space_id",
                                            space
                                          );
                                        }}
                                        onInputChange={(event) => {
                                          setSpaceName(event);
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <b class="ticket-data2">
                                      {state.interconnect?.cross_connect_z_side
                                        ?.z_side_space_id || "-"}
                                    </b>
                                  )}
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div className="d-flex">
                                  <div>
                                    {" "}
                                    <label
                                      style={{
                                        fontSize: "0.875rem",
                                        color: "#000000",
                                      }}
                                    >
                                      Patch Panel ID :
                                    </label>
                                  </div>
                                  {toggleEdit &&
                                  checkPermission("Edit Details") ? (
                                    <div
                                      style={{
                                        width: "400px",
                                        marginLeft: "1rem",
                                        marginBottom: "1rem",
                                      }}
                                    >
                                      <Select
                                        styles={customStyles}
                                        options={patchPanelListZ}
                                        value={getValue(
                                          "z_side_interconnect_patchpanel_id",
                                          patchPanelListZ,
                                          "Z"
                                        )}
                                        onChange={(panel) => {
                                          handleChange(
                                            "z_side_interconnect_patchpanel_id",
                                            panel
                                          );
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <b class="ticket-data2">
                                      {state.interconnect?.cross_connect_z_side
                                        ?.z_side_interconnect_patchpanel_id ||
                                        "-"}
                                    </b>
                                  )}
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div className="d-flex">
                                  <div>
                                    {" "}
                                    <label
                                      style={{
                                        fontSize: "0.875rem",
                                        color: "#000000",
                                      }}
                                    >
                                      Patch Panel Port ID :
                                    </label>
                                  </div>
                                  {toggleEdit &&
                                  checkPermission("Edit Details") ? (
                                    <div
                                      style={{
                                        width: "400px",
                                        marginLeft: "1rem",
                                        marginBottom: "1rem",
                                      }}
                                    >
                                      <Select
                                        styles={customStyles}
                                        options={patchPanelPortListZ}
                                        value={getValue(
                                          "z_side_interconnect_patchpanel_ports_id",
                                          patchPanelPortListZ,
                                          "Z"
                                        )}
                                        onChange={(port) => {
                                          handleChange(
                                            "z_side_interconnect_patchpanel_ports_id",
                                            port
                                          );
                                        }}
                                      />
                                    </div>
                                  ) : (
                                    <b class="ticket-data2">
                                      {state.interconnect?.cross_connect_z_side
                                        ?.z_side_interconnect_patchpanel_ports_id ||
                                        "-"}
                                    </b>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div class="row pt-2">
                          <div class="col-md-12">
                            <div style={{ position: "relative" }}>
                              <div class="btnstyle">
                                <div class="btn-group">
                                  {checkPermission("Log Notes") && (
                                    <a
                                      href="#"
                                      className={`btn msg-button ${
                                        active == "log" ? "bactive" : ""
                                      }`}
                                      onClick={() => {
                                        setActive("log");
                                      }}
                                    >
                                      Log Note
                                    </a>
                                  )}
                                  {checkPermission("Send Message") && (
                                    <a
                                      href="#"
                                      className={`btn msg-button ${
                                        active == "message" ? "bactive" : ""
                                      }`}
                                      onClick={() => {
                                        setActive("message");
                                      }}
                                    >
                                      Send Message
                                    </a>
                                  )}
                                  <a
                                    href="#"
                                    className={`btn msg-button ${
                                      active == "Attachment" ? "bactive" : ""
                                    }`}
                                    onClick={() => {
                                      setActive("Attachment");
                                    }}
                                  >
                                    Attachment
                                  </a>
                                  <a
                                    href="#"
                                    className={`btn msg-button ${
                                      active == "sub-task" ? "bactive" : ""
                                    }`}
                                    onClick={() => {
                                      setActive("sub-task");
                                    }}
                                  >
                                    {state.project.includes("Installed Base")
                                      ? "Parent Task"
                                      : "Sub Tasks"}
                                  </a>

                                  {(state.product_category == "Space" ||
                                    (state.product_category == "Power" &&
                                      props.ticketData?.product
                                        .toLowerCase()
                                        .includes(
                                          "metered power committed usage"
                                        ))) &&
                                    state.project.includes("Installed Base") &&
                                    checkPermission("Usage Data") && (
                                      <a
                                        href="#"
                                        className={`btn msg-button ${
                                          active == "usage-data"
                                            ? "bactive"
                                            : ""
                                        }`}
                                        onClick={() => {
                                          setActive("usage-data");
                                        }}
                                      >
                                        Usage Data
                                      </a>
                                    )}

                                  <a
                                    href="#"
                                    className={`btn msg-button ${
                                      active == "history" ? "bactive" : ""
                                    }`}
                                    onClick={() => {
                                      setActive("history");
                                    }}
                                  >
                                    History
                                  </a>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "baseline",
                                  }}
                                >
                                  {checkPermission("Follow") && (
                                    <a
                                      href="#"
                                      onClick={follows ? unfollow : follow}
                                      onMouseOver={() => {
                                        setButtonText(
                                          follows ? "Unfollow" : "Follow"
                                        );
                                        setIcon(follows ? "fas fa-close" : "");
                                        setButtonStyle({
                                          color: follows ? "red" : "black",
                                        });
                                      }}
                                      onMouseOut={() => {
                                        setButtonText(
                                          follows ? "Following" : "Follow"
                                        );
                                        setIcon(follows ? "fas fa-check" : "");
                                        setButtonStyle({
                                          color: follows ? "#2cc970" : "black",
                                        });
                                      }}
                                    >
                                      <span
                                        className={icon}
                                        style={buttonStyle}
                                      ></span>{" "}
                                      <b style={buttonStyle}>{buttonText}</b>
                                    </a>
                                  )}

                                  <ul className="navbar-nav header-right">
                                    <li className="nav-item dropdown header-profile">
                                      <a
                                        className="nav-link"
                                        href="#"
                                        role="button"
                                        data-bs-toggle="dropdown"
                                        style={{
                                          display: "flex",
                                          alignItems: "baseline",
                                        }}
                                      >
                                        <i
                                          className="fas fa-user"
                                          id="don"
                                          style={{ color: "#228683" }}
                                        ></i>{" "}
                                        <span
                                          className="ms-1"
                                          style={{
                                            color: "#228683",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          {follower.length}{" "}
                                        </span>
                                      </a>
                                      <div
                                        className="dropdown-menu dropdown-menu-end"
                                        style={{ width: "max-content" }}
                                        id="follwerDropdown"
                                      >
                                        {checkPermission("Follow") && (
                                          <a
                                            href="#"
                                            className="dropdown-item ai-icon"
                                            onClick={() => setAddFollower(true)}
                                          >
                                            <span className="">
                                              Add Followers{" "}
                                            </span>
                                          </a>
                                        )}
                                        {checkPermission("Follow") && <hr />}
                                        <table>
                                          <thead>
                                            {follower &&
                                              follower.map((data, key) => (
                                                <tr className="">
                                                  <th>
                                                    <img
                                                      src="images/Icon.svg"
                                                      style={{
                                                        width: "18px",
                                                        height: "18px",
                                                      }}
                                                    />{" "}
                                                  </th>
                                                  <th>
                                                    <span className="">
                                                      {data.name}{" "}
                                                    </span>{" "}
                                                  </th>
                                                  {/* <th style={{cursor:"pointer"}}> <i className="fas fa-pencil" id="don"></i></th> */}
                                                  {checkPermission(
                                                    "Follow"
                                                  ) && (
                                                    <th
                                                      style={{
                                                        cursor: "pointer",
                                                      }}
                                                    >
                                                      <img
                                                        src="images/fclose.svg"
                                                        alt=""
                                                        style={{
                                                          width: "12px",
                                                          height: "12px",
                                                        }}
                                                        onClick={() =>
                                                          delfollower(
                                                            data.contact_id
                                                          )
                                                        }
                                                      />
                                                    </th>
                                                  )}
                                                </tr>
                                              ))}
                                          </thead>
                                        </table>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              {active == "message" || active == "log" ? (
                                <div class="inputstyle">
                                  <div class="msg_input">
                                    <div class="media-left">
                                      <div class="field">
                                        <p class="control">
                                          <textarea
                                            class="input input-style"
                                            type="text"
                                            placeholder="  New message..."
                                            value={content}
                                            onChange={(event) => {
                                              setContent(event.target.value);
                                            }}
                                          ></textarea>

                                          <span class="icon btnsmall cst-btnsmall">
                                            <div
                                              style={{ textAlign: "center" }}
                                            >
                                              <img
                                                src="img/attach.svg"
                                                alt="Click to select file"
                                                onClick={handleImageClick}
                                              />
                                              <input
                                                type="file"
                                                style={{ display: "none" }}
                                                ref={fileInputRef}
                                                onChange={handleFileChange}
                                              />
                                            </div>
                                            <button
                                              class="button send_button"
                                              onClick={sendMessage}
                                            >
                                              <span class="send-btn">SEND</span>
                                            </button>
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}

                              {active == "sub-task" && (
                                <SubTasks
                                  data={
                                    state.project.includes("Installed Base")
                                      ? state.parent_task
                                      : state.sub_tasks
                                  }
                                  setCurrentServiceId={
                                    props.setCurrentServiceId
                                  }
                                  currentServiceId={props.ticketData.id}
                                  refreshData={props.refreshData}
                                  setIsLoading={setIsLoading}
                                ></SubTasks>
                              )}
                              {active == "usage-data" && (
                                <UsageData data={usageData}></UsageData>
                              )}

                              {active == "Attachment" && (
                                <Attachments
                                  serviceId={state.id}
                                  setIsLoading={setIsLoading}
                                  setErrorMsg={setErrorMsg}
                                  setErrorPopup={setErrorPopup}
                                  refreshData={isRefreshAttachments}
                                  setIsRefreshAttachments={
                                    setIsRefreshAttachments
                                  }
                                  handleDelete={handleDelete}
                                  handleImageClick={handleImageClick}
                                  fileInputRef={fileInputRef}
                                  handleFileChange={handleFileChange}
                                  handleDownload={getServiceAttachment}
                                  permission={checkPermission}
                                ></Attachments>
                              )}

                              {active == "history" && (
                                <div className="pb-3">
                                  {state.notes.length > 0 ? (
                                    <div class="row pt-2 pb-5">
                                      <h5 class="history-title">History</h5>
                                      {separateArrayDateWise(state.notes).map(
                                        (history) => (
                                          <div>
                                            <h1 className="separator">
                                              {moment().format(
                                                "MMMM DD, YYYY"
                                              ) === history.date
                                                ? "Today"
                                                : history.date}
                                            </h1>
                                            <div class="col-md-12">
                                              <div class="history-div">
                                                {history.items.map(
                                                  (data, key) => (
                                                    <div
                                                      class="col-md-12"
                                                      key={key}
                                                      style={{
                                                        paddingBottom: "3px",
                                                      }}
                                                    >
                                                      <div
                                                        class="history-out"
                                                        id="history"
                                                      >
                                                        <div class="history">
                                                          {" "}
                                                          <div class="img-left">
                                                            <div class="img-title">
                                                              <img
                                                                src={
                                                                  data.type ==
                                                                  "note"
                                                                    ? "images/Icon.svg"
                                                                    : "images/message-user.svg"
                                                                }
                                                                className="history-user-img"
                                                              />
                                                            </div>
                                                          </div>
                                                          <div class="description">
                                                            <div>
                                                              <h5 class="des-name">
                                                                {data?.author_id ||
                                                                  data[key]
                                                                    ?.author_id}
                                                                <span
                                                                  style={{
                                                                    opacity: 0.5,
                                                                  }}
                                                                >
                                                                  {" "}
                                                                  &nbsp; &nbsp;-
                                                                  {getTime(
                                                                    data.date
                                                                  )}
                                                                </span>
                                                                {/* <span class="now-btn">
                                                      {data.changedDate}
                                                      <small style={{ color: '#E03138' }}>*</small>
                                                    </span> */}
                                                              </h5>
                                                              <div
                                                                dangerouslySetInnerHTML={{
                                                                  __html: data.body
                                                                    ? data.body.replace(
                                                                        /\n/g,
                                                                        "<br />"
                                                                      )
                                                                    : data[
                                                                        key
                                                                      ]?.body.replace(
                                                                        /\n/g,
                                                                        "<br />"
                                                                      ),
                                                                }}
                                                              />
                                                              {!data.body &&
                                                                data.tracking_values &&
                                                                data.tracking_values.map(
                                                                  (
                                                                    data,
                                                                    key
                                                                  ) => (
                                                                    <div
                                                                      dangerouslySetInnerHTML={{
                                                                        __html: data,
                                                                      }}
                                                                    />
                                                                  )
                                                                )}
                                                            </div>
                                                          </div>
                                                        </div>

                                                        <div>
                                                          {data.attachments
                                                            .length > 0 ? (
                                                            <div>
                                                              {" "}
                                                              <div className="attach-outer">
                                                                Attachments
                                                              </div>
                                                              <div className="file-name-box">
                                                                {data.attachments.map(
                                                                  (val) => {
                                                                    return (
                                                                      <div className="down-file">
                                                                        <div>
                                                                          {
                                                                            val.name
                                                                          }
                                                                        </div>{" "}
                                                                        <div className="down-file-inner">
                                                                          <div
                                                                            onClick={() =>
                                                                              getServiceAttachment(
                                                                                val.id,
                                                                                val.name
                                                                              )
                                                                            }
                                                                          >
                                                                            <img
                                                                              src="images/fdown.svg"
                                                                              width={
                                                                                13
                                                                              }
                                                                              alt=""
                                                                            />
                                                                          </div>

                                                                          <div>
                                                                            <img
                                                                              src="images/fclose.svg"
                                                                              width={
                                                                                8
                                                                              }
                                                                              alt=""
                                                                              style={{
                                                                                paddingTop:
                                                                                  "2px",
                                                                              }}
                                                                              onClick={() =>
                                                                                handleDelete(
                                                                                  val.id
                                                                                )
                                                                              }
                                                                            />
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    );
                                                                  }
                                                                )}
                                                              </div>
                                                            </div>
                                                          ) : (
                                                            ""
                                                          )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        {showAttachment.length > 0 &&
                          (active === "log" || active === "message") && (
                            <div className="show-Attach-outer">
                              {" "}
                              <div className="attach-outer">Attachments</div>
                              <div className="file-name-box">
                                {showAttachment.map((val, index) => {
                                  return (
                                    <div className="down-file" key={index}>
                                      <div>{val.name}</div>{" "}
                                      <div className="down-file-inner">
                                        <div>
                                          <img
                                            src="images/fclose.svg"
                                            onClick={() => handleDelete(val.id)}
                                            width={8}
                                            alt=""
                                            style={{ paddingTop: "2px" }}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {addfollower && (
              <AddProvFollowers
                show={addfollower}
                setShow={setAddFollower}
                ticketId={state.id}
                getFollowList={getFollowList}
              />
            )}
            {errorpopup && (
              <ErrorModel
                show={errorpopup}
                setShow={setErrorPopup}
                errorMsg={errorMsg}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProvisioningPop;
