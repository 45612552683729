// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ticket-form-checkbox span.input-group-text {
  background: transparent !important;
  padding: 0px 0px !important;
}
.ticket-form-checkbox span.input-group-text .form-check-input {
  border-color: black;
}`, "",{"version":3,"sources":["webpack://./src/views/super-admin/Tickets/shared/TicketCheckBox/TicketCheckBox.scss"],"names":[],"mappings":"AACE;EACE,kCAAA;EACA,2BAAA;AAAJ;AAEI;EACE,mBAAA;AAAN","sourcesContent":[".ticket-form-checkbox {\n  span.input-group-text {\n    background: transparent !important;\n    padding: 0px 0px !important;\n\n    .form-check-input {\n      border-color: black;\n\n      // &:checked {\n      //     background-color: aqua;\n      //     color: blueviolet;\n      // }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
