import { validateEmail } from 'common/helpers';
import { XCard } from 'components/Auth';
import { XAlert, XError } from 'components/common';
import StorageContext from 'context';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { loginService, verifyEmail } from 'services/auth';
import Layout from '../Layouts';
import { LogoutOnSessionExpire } from 'common/helpers';
import { useLocation } from 'react-router-dom';
import Loading from 'views/super-admin/pre-loader/loader';
import { useTranslation } from 'react-i18next';
import { auth } from 'services/auth';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
const OfflineLogin = () => {
  const HOME = '/home';
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();
  // const location = useLocation();
  let { token } = useParams();

  const [state, setState] = useState({});
  const [message, setMessage] = useState({
    message: '',
    type: ''
  });
  const [canShowPassword, setCanShowPassword] = useState(false);
  let contextData = React.useContext(StorageContext);
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (token && location.pathname.length > 150) {
      verifyEmail({ vToken: token })
        .then((res) => {
          setMessage({
            message: res.data.message,
            type: 'success'
          });
        })
        .catch((err) => {
          LogoutOnSessionExpire(err);
          setMessage({
            message: err.response.data.errors,
            type: 'danger'
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, []);

  const onSubmit = (event) => {
    setIsLoading(true);
    event.preventDefault();
    if (validation()) {
      loginService({
        email: state.email,
        password: state.password
      })
        .then((res) => {
          localStorage.setItem('token', res?.data?.access_token);
          localStorage.setItem('istimeout', res.data.data[0].isTimeout);
          auth(res?.data?.access_token).then((res) => {
            contextData.setMaintenanceModeStatus(res.data.maintenanceModeStatus);
            contextData.setAuth(res.data.user);
            contextData.setMonthYear(res.data.currentMonth, res.data.currentYear);
            contextData.setDataCenter(res.data.dataCenter);
            contextData.setCountry(res.data.country);
          });
          contextData.login(res?.data?.access_token);
          navigate(!!res.data?.data[0]?.default_page ? res.data?.data[0]?.default_page : HOME);
        })
        .catch((err) => {
          LogoutOnSessionExpire(err);
          let error = {
            errEmail: '',
            errPassword: ''
          };

          const { email, password } = err?.response?.data?.errors;

          if (email !== undefined || email !== '' || email !== null) {
            error.errEmail = email;
          }
          if (password !== undefined || password !== '' || password !== null) {
            error.errPassword = password;
          }

          setState({ ...state, ...error });
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };
  const validation = () => {
    let flag = true;
    let error = {
      errEmail: '',
      errPassword: ''
    };

    if (state.email === null || state.email === undefined || state.email === '') {
      error.errEmail = t('auth.email_required');

      flag = false;
    } else {
      if (!validateEmail(state.email)) {
        error.errEmail = t('auth.invalid_email');
      }
    }

    if (state.password === null || state.password === undefined || state.password === '') {
      error.errPassword = t('auth.password_required');

      flag = false;
    }

    setState({ ...state, ...error });

    return flag;
  };

  return (
    <React.Fragment>
      <Loading
        loading={isLoading}
        background="rgba(0, 0, 0, 0.40)"
        loaderColor="rgb(248, 153, 3)"
      />
      <Layout>
        <XCard title="Login">
          <XAlert message={message.message} type={message.type} />
          <form onSubmit={onSubmit}>
            <div className="form_div">
              <label className="mb-1">
                <strong> {t('auth.email_address')} </strong>
              </label>
              <input
                type="email"
                className="form-control"
                onChange={(event) => setState({ ...state, email: event.target.value })}
                placeholder={t('auth.email_address')}
              />
              <XError message={state.errEmail} />
            </div>

            <div className="form_div_2">
              <label className="mb-1">
                <strong> {t('auth.password')} </strong>
              </label>
              <input
                type={canShowPassword ? 'text' : 'password'}
                className="form-control"
                onChange={(event) => setState({ ...state, password: event.target.value })}
                placeholder={t('auth.password')}
              />
              <i
                className={`fa fa-eye${canShowPassword ? '' : '-slash'}`}
                onClick={() => setCanShowPassword(!canShowPassword)}
                aria-hidden="true"
              ></i>
              <XError message={state.errPassword} />
            </div>

            <div className="row d-flex justify-content-between mt-4 mb-2">
              <div className="mb-3" id="forget">
                <Link to="/forgot-password">{t('auth.forgot_password')}?</Link>
              </div>
            </div>

            <div className="text-center">
              <button type="submit" className="btn btn-primary btn-block">
                {t('auth.login')}
              </button>
              {contextData.getMaintenanceModeStatus &&
                contextData.getMaintenanceModeStatus?.isMaintenanceModeOn && (
                  <Stack
                    sx={{
                      width: '100%',
                      fontSize: '1rem',
                      padding: '6px !important',
                      marginTop: '2rem'
                    }}
                  >
                    <Alert
                      variant="outlined"
                      severity="warning"
                      sx={{
                        fontSize: '1rem',
                        textAlign: 'left',
                        border: '2px solid #fe8600',
                        fontWeight: '500'
                      }}
                    >
                      Maintenance mode has been turned{' '}
                      {contextData.getMaintenanceModeStatus?.status_current == 2 ? 'On' : 'Off'}.
                      <br></br>
                      <b> Data Sync is in progress</b>.<br></br>
                      Please wait for a moment for the process to be completed before accessing the
                      Ops Portal.
                    </Alert>
                  </Stack>
                )}

              {contextData.getMaintenanceModeStatus &&
                contextData.getMaintenanceModeStatus?.status_current === 3 && (
                  <Stack
                    sx={{
                      width: '100%',
                      fontSize: '1rem',
                      padding: '6px !important',
                      marginTop: '3rem'
                    }}
                  >
                    <Alert
                      variant="outlined"
                      severity="warning"
                      sx={{
                        fontSize: '1rem',
                        textAlign: 'left',
                        border: '2px solid #fe8600',
                        fontWeight: '500'
                      }}
                    >
                      The Ops portal is currently in maintenance mode, and some services may be
                      deprecated.
                    </Alert>
                  </Stack>
                )}
            </div>
          </form>
        </XCard>
      </Layout>
    </React.Fragment>
  );
};

export default OfflineLogin;
