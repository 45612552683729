import { useFormik } from "formik";
import { useEffect } from "react";
import useGetCompaniesAndCountries from "../shared/hooks/useGetCompaniesAndCountries";
import useGetTeams from "../shared/hooks/useGetTeams";
import useCreateIncidentTicket from "./hooks/useCreateIncidentTicket";
import useResetFields from "./hooks/useResetFields";
import * as utils from "../shared/utils";
import useSetParameters from "../shared/hooks/useSetParameters";
import { category } from "./data";
import Impacted from "./hooks/Impacted";
import useDataCenter from "./hooks/useDatacenter";
import useGetOperatingSites from "./hooks/newOperatingSite";
import { defaultValue } from "./data";
import { useTranslation } from "react-i18next";

const useIncidentTicketForm = ({ closeModal, setLoading }) => {
  const { teams } = useGetTeams();
  const { t } = useTranslation();

  const { postNewTicket } = useCreateIncidentTicket({ closeModal, teams });
  const translatedCategory = category.map((category) => ({
    ...category,
    label: t(
      `create-ticket.incident-ticket.${category.label.split(" ").join("_")}`
    ),
    value: {
      ...category.value,
      subCategory: category.value.subCategory.map((subCategory) => ({
        ...subCategory,
        label: t(
          `create-ticket.incident-ticket.${subCategory.label
            .split(" ")
            .join("_")}`
        ),
      })),
      severity: category.value.severity.map((severity) => ({
        ...severity,
        label: severity.label,
      })),
    },
  }));
  const replaceSpaceWithUnderscore = (data) => {
    const x = JSON.stringify(data);
    const y = x.split(" ").join("_");
    return JSON.parse(y);
  };
  const formik = useFormik({
    initialValues: {
      country: "",
      TicketTypes: "",
      operatingSite: "",
      ImpactedDevice: "",
      description: "",
      category: replaceSpaceWithUnderscore(defaultValue),
      Subcategory: "",
      dataCenters: [],
      countries: [],
      severity: "",
      ticketSubject: "",
    },
    onSubmit: async (values) => {
      if (values) {
        postNewTicket(values);
      }
    },
  });

  const { countries } = useGetCompaniesAndCountries(
    setLoading,
    formik.setFieldValue
  );

  const { dataCenters } = useDataCenter({
    countryId: utils.getCountryIdByCode(formik.values.country, countries),
  });

  const { operatingSites, isOperatingSitesLoading } = useGetOperatingSites({
    countryId: utils.getCountryIdByCode(formik.values.country, countries),
    dataCenters,
    country: formik.values.country,
    countries,
  });

  useSetParameters({
    dataCenters,
    countries,
    setFieldValue: formik.setFieldValue,
  });

  // const { ticketTypes } = useGetTicketTypes();
  const ticketTypes = [
    {
      id: 1,
      label: t(
        `create-ticket.incident-ticket.${"Incident Management"
          .split(" ")
          .join("_")}`
      ),
    },
  ];

  useResetFields(formik);

  const { devices, isImpactedDeviceLoading } = Impacted(
    formik.values.operatingSite
  );

  useEffect(() => {
    formik.setFieldValue("severity", "");
    formik.setFieldValue("Subcategory", "");
  }, [formik.values.category]);

  const addCondition = (func, field) => {
    return func(field) && formik.values[field] !== "";
  };

  const isActive = (field) => {
    switch (field) {
      case "TicketTypes":
        return (
          formik.values.country !== "" && formik.values.country !== "global"
        );
      case "operatingSite":
        return addCondition(isActive, "TicketTypes");
      case "ImpactedDevice":
        return addCondition(isActive, "operatingSite");

      case "category":
        return addCondition(isActive, "operatingSite");
      case "Subcategory":
        return addCondition(isActive, "category");
      case "ticketSubject":
        return addCondition(isActive, "Subcategory");
      case "description":
        return addCondition(isActive, "ticketSubject");
      case "submit":
        return addCondition(isActive, "description");

      default:
        return false;
    }
  };

  return {
    formik,
    isActive,
    countries,
    dataCenters,
    ticketTypes,
    category: translatedCategory,
    devices,
    operatingSites,
    isOperatingSitesLoading,
    isImpactedDeviceLoading,

    // ticketTypes
  };
};

export default useIncidentTicketForm;
