import { XError } from "components/common";
import AuthContext from "context";
import React, { useContext, useEffect, useRef, useState } from "react";
import Common from "services/commonService";
import Room from "services/roomServices";
import Swal from "sweetalert2";
import { LogoutOnSessionExpire } from "common/helpers";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";

const CreateRoom = (props) => {
  const modalRef = useRef(null);
  const authContext = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [statusData, setStatusData] = useState([]);
  const { t } = useTranslation();
  const [state, setState] = useState({
    floor_id: "",
    name: "",
    cabinet: "",
    power: "",
    soldCabinet: "",
    cages: "",
    status: "",
  });
  const [error, setError] = useState({
    floor_id: "",
    name: "",
    cabinet: "",
    power: "",
    soldCabinet: "",
    cages: "",
    status: "",
  });

  useEffect(() => {
    if (authContext.getStatusData) {
      setStatusData(authContext.getStatusData);
    }
    // Common.status().then((res) => setStatusData(res.data.data))

    setState({
      floor_id: "",
      name: "",
      cabinet: "",
      power: "",
      soldCabinet: "",
      cages: "",
      status: "",
    });

    return () => {
      //setCountries([]);
      //setDataCenters([]);
      setState({});
    };
  }, [props.show]);

  const onSubmit = async (data) => {
    if (!props.floorIndex) {
      closeModal();
      Swal.fire({
        icon: "error",
        title: t("datacenter.oops"),
        text: t("datacenter.please_select_floor"),
      });
    } else {
      // setState({...state,data_center_id: props.data_center_id})
      setIsLoading(true);
      if (checkValidation()) {
        // setState({...state,data_center_id:props.dataCenterId.id})

        await Room.addRoom(authContext.getToken, {
          ...state,
          floor_id: props.floorIndex,
        })
          .then(async (res) => {
            setIsLoading(false);

            props.setMountComponent(!props.mountComponent);
            closeModal();
            Swal.fire(t("datacenter.new_room_created"));

            props.selectDataCenterFloor(
              authContext.getNetworkDataCenter,
              props.floorKey
            );
          })
          .catch((err) => {
            LogoutOnSessionExpire(err);

            setIsLoading(false);
            let error = {
              floor_id: "",
              name: "",
              cabinet: "",
              power: "",
              soldCabinet: "",
              cages: "",
              status: "",
            };
            const errors = err?.response?.data?.errors;

            if (
              errors?.name !== undefined ||
              errors?.name !== "" ||
              errors?.name !== null
            ) {
              error.name = errors.name;
            }
            if (
              errors?.cabinet !== undefined ||
              errors?.cabinet !== "" ||
              errors?.cabinet !== null
            ) {
              error.cabinet = errors.cabinet;
            }
            if (
              errors?.cages !== undefined ||
              errors?.cages !== "" ||
              errors?.cages !== null
            ) {
              error.cages = errors.cages;
            }
            if (
              errors?.power !== undefined ||
              errors?.power !== "" ||
              errors?.power !== null
            ) {
              error.power = errors.power;
            }

            setError({ ...error });
          });
      }
    }
  };

  const checkValidation = () => {
    let error = {
      name: "",
      cabinet: "",
      power: "",
      soldCabinet: "",
      cages: "",
      status: "",
    };

    const { name, cabinet, power, cages } = state;

    let flag = true;

    if (name === "" || name === null || name === undefined) {
      error.name = "The name field is required.";
      flag = false;
    }

    if (cabinet === "" || cabinet === null || cabinet === undefined) {
      error.cabinet = "The cabinet field is required.";
      flag = false;
    }
    if (cages === "" || cages === null || cages === undefined) {
      error.cages = "The cages field is required.";
      flag = false;
    }
    if (power === "" || power === null || power === undefined) {
      error.power = "The power field is required.";
      flag = false;
    }
    setError({ ...error });
    setIsLoading(false);
    return flag;
  };

  const closeModal = () => {
    setState({
      floor_id: "",
      name: "",
      cabinet: "",
      power: "",
      soldCabinet: "",
      cages: "",
      status: "",
    });
    setError({
      floor_id: "",
      name: "",
      cabinet: "",
      power: "",
      soldCabinet: "",
      cages: "",
      status: "",
    });
    setIsLoading(false);
    modalRef.current.click();
  };

  const validatePower = (e) => {
    let t = e.target.value;
    let newValue = state.power;

    if (t.toString().split(".")[0].length <= 6) {
      newValue =
        t.indexOf(".") >= 0
          ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 6)
          : t;
      setState({ ...state, power: Number(newValue) });
    }
  };

  return (
    <div className="modal fade" id="exampleModalCenter">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header mt-59">
            <h3 className="modal-title"> {t("datacenter.add_room")} </h3>
            <button
              style={{
                cursor: "pointer",
                background: "transparent",
                border: "none",
              }}
              data-bs-dismiss="modal"
              ref={modalRef}
              onClick={() => closeModal()}
            >
              <CloseIcon />
            </button>
          </div>
          <div className="modal-body">
            <div className="card">
              <div
                className="card-body"
                style={{ padding: "0px", overflow: "hidden" }}
              >
                <div className="basic-form">
                  <form>
                    <div className="row">
                      <div className="mb-3 col-md-12 mt-2313">
                        <label className="form-label">
                          {" "}
                          {t("datacenter.name")}{" "}
                          <small className="text-danger">*</small>
                        </label>
                        <input
                          type="text"
                          maxLength={45}
                          className="form-control"
                          placeholder="Name of Room"
                          value={state.name}
                          onChange={(event) =>
                            setState({ ...state, name: event.target.value })
                          }
                        />
                        <XError message={error.name} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="mb-3 col-md-12 mt-2313">
                        <label className="form-label">
                          {" "}
                          {t("datacenter.total_cabinets")}{" "}
                          <small className="text-danger">*</small>
                        </label>

                        <input
                          className="form-control"
                          type="number"
                          maxLength={9}
                          placeholder={t("datacenter.total_cabinets")}
                          value={state.cabinet}
                          onChange={(event) =>
                            setState({
                              ...state,
                              cabinet:
                                event.target.value.length <= 9
                                  ? event.target.value.replace(/[^\d]/, "")
                                  : state.cabinet.replace(/[^\d]/, ""),
                            })
                          }
                        />
                        <XError message={error.cabinet} />
                      </div>
                    </div>
                    {/* <div className="row">
            <div className="mb-3 col-md-12 mt-2313">
                <label className="form-label"> Sold Cabinets <small className="text-danger">*</small></label>
    
                <input 
                className="form-control" 
                type="number"
                maxLength={9}
                placeholder="Sold Cabinets"
                value={state.soldCabinet}
                onChange={event => {
                	let value = event.target.value.replace(/[^\d]/,'');

                	if( Number(value) <= Number(state.cabinet)){
                		setError({
							...error,
							soldCabinet:""
						});
	                	setState({
		                	...state,
		                	soldCabinet:event.target.value.length<=9?value:state.soldCabinet
	                	});
                	}else{
				        setError({
							...error,
							soldCabinet:"Sold cabinet should not greater than total cabinet"
						})
                	}
                }}
                />
                <XError message={error.soldCabinet} />
            </div>									
        </div> */}
                    <div className="row">
                      <div className="mb-3 col-md-12 mt-2313">
                        <label className="form-label">
                          {" "}
                          {t("datacenter.total_cages")}{" "}
                          <small className="text-danger">*</small>
                        </label>
                        <input
                          className="form-control"
                          type="number"
                          maxLength={9}
                          placeholder={t("datacenter.total_cages")}
                          value={state.cages.replace(/[^\d]/, "")}
                          onChange={(event) =>
                            setState({
                              ...state,
                              cages:
                                event.target.value.length <= 9
                                  ? event.target.value.replace(/[^\d]/, "")
                                  : state.cages.replace(/[^\d]/, ""),
                            })
                          }
                        />
                        <XError message={error.cages} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="mb-3 col-md-12 mt-2313">
                        <label className="form-label">
                          {" "}
                          {t("datacenter.total_k_w_s")}
                          <small className="text-danger">*</small>
                        </label>
                        <input
                          type="number"
                          min="0.00000"
                          step="0.00001"
                          maxLength="11"
                          className="form-control"
                          placeholder="# of kWs"
                          value={state.power}
                          onChange={(event) => validatePower(event)}
                          //onChange={event => setState({...state,power:event.target.value})}
                        />
                        <XError message={error.power} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="mb-3 col-md-12 mt-2313">
                        <label className="form-label">
                          {" "}
                          {t("datacenter.status")}{" "}
                          <small className="text-danger">*</small>
                        </label>
                        <select
                          onChange={(event) => {
                            setState({
                              ...state,
                              status: event.target.value,
                            });
                          }}
                          value={state.status}
                          className="default-select form-control wide"
                        >
                          {statusData &&
                            statusData.map((status) => {
                              if (status.status_type_id === 1) {
                                return (
                                  <option value={status.id} key={status.id}>
                                    {status.status_name}
                                  </option>
                                );
                              }
                            })}
                        </select>
                        <XError message={error.status} />
                      </div>
                    </div>

                    <div
                      className="toolbar toolbar-bottom mt-51 d-flex justify-content-between"
                      role="toolbar"
                    >
                      <div className="delt">
                        {/*<button type="button" className="btn btn-outline-primary mr_1 red_color">
	        	<img src="\images\trash-2.svg" style={{width: "11px", marginTop: "-0.188rem"}} /> 
	        	Delete
	        </button>*/}
                      </div>
                      <div
                        className="usr_cncl"
                        style={{ padding: ".5rem 0rem" }}
                      >
                        <button
                          type="button"
                          onClick={closeModal}
                          style={{ marginRight: "1rem" }}
                          className="btn btn-outline-primary"
                        >
                          {t("common.cancel")}
                        </button>

                        {isLoading ? (
                          <button type="button" className="btn btn-primary">
                            {" "}
                            {t("common.loading")}...{" "}
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={() => onSubmit(props.data_center_id)}
                            className="btn btn-primary"
                          >
                            {" "}
                            {t("common.save")}{" "}
                          </button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateRoom;
