// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.firstRow {
  border-bottom: 1px solid black;
  padding: 0.5rem 1rem;
}
.head {
  font-size: 1.25rem;
  font-weight: 600;
}
.label {
  font-size: 1rem;
  font-weight: 500;
  margin-left: 4rem;
  margin-right: 1rem;
}

.secondRow {
  border-bottom: 1px solid black;
  padding: 0.5rem 4rem 2rem 4rem;
}
.thirdRow {
  border-bottom: 1px solid black;
  padding: 0.5rem 4rem 0.5rem 4rem;
}
.power-summary-thead th{
  color: white !important;
  background-color: #414141;
}
.power-summary-tbody td{
  color: black;
  background-color: #e3e7ea;
}
.power-summary-total td{
  background-color: #b7b7b7;
  font-weight: 600;
}
.total-common{
  font-weight: 600;
}

.total-common.design-power{
  margin-right: 2.5rem;
}

.total-common-parent {
  display: flex;
}
.ra {
  text-align: right;
}`, "",{"version":3,"sources":["webpack://./src/views/super-admin/Home/style.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,oBAAoB;AACtB;AACA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,8BAA8B;EAC9B,8BAA8B;AAChC;AACA;EACE,8BAA8B;EAC9B,gCAAgC;AAClC;AACA;EACE,uBAAuB;EACvB,yBAAyB;AAC3B;AACA;EACE,YAAY;EACZ,yBAAyB;AAC3B;AACA;EACE,yBAAyB;EACzB,gBAAgB;AAClB;AACA;EACE,gBAAgB;AAClB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,aAAa;AACf;AACA;EACE,iBAAiB;AACnB","sourcesContent":[".firstRow {\n  border-bottom: 1px solid black;\n  padding: 0.5rem 1rem;\n}\n.head {\n  font-size: 1.25rem;\n  font-weight: 600;\n}\n.label {\n  font-size: 1rem;\n  font-weight: 500;\n  margin-left: 4rem;\n  margin-right: 1rem;\n}\n\n.secondRow {\n  border-bottom: 1px solid black;\n  padding: 0.5rem 4rem 2rem 4rem;\n}\n.thirdRow {\n  border-bottom: 1px solid black;\n  padding: 0.5rem 4rem 0.5rem 4rem;\n}\n.power-summary-thead th{\n  color: white !important;\n  background-color: #414141;\n}\n.power-summary-tbody td{\n  color: black;\n  background-color: #e3e7ea;\n}\n.power-summary-total td{\n  background-color: #b7b7b7;\n  font-weight: 600;\n}\n.total-common{\n  font-weight: 600;\n}\n\n.total-common.design-power{\n  margin-right: 2.5rem;\n}\n\n.total-common-parent {\n  display: flex;\n}\n.ra {\n  text-align: right;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
