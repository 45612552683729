import { css, active } from "glamor";
import { Row } from "react-bootstrap";
import Papa from "papaparse";
import { useTranslation } from "react-i18next";
import { TICKET_MODAL_ORANGE_COLOR } from "../../shared/constants";
import TicketCheckBox from "../../shared/TicketCheckBox";
import ActionButtons from "./ActionButtons";
import VisitorDetailsForm from "./VisitorDetailsForm";
import VisitorDetailsTitle from "./VisitorDetailsTitle";
import VisitorList from "./VisitorList";
import VisitorListTitle from "./VisitorListTitle";
import VisitDetails from "../LeftPart/VisitDetails";
import ConfirmVerifyModal from "../../shared/VerificationModal";
import { useState } from "react";
import * as XLSX from "xlsx";
import Swal from "sweetalert2";

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: "#fff",
    border: `2px solid ${TICKET_MODAL_ORANGE_COLOR}`,
    borderRadius: "5px",
    minHeight: "100%",
  },
  section: {
    "& > *": {
      padding: "0 20px 0 20px",
    },
  },
  checkBoxes: {
    "& > *": {
      marginBottom: "2px",
    },
  },
};

const RightPart = ({ form, closeModal }) => {
  const { t } = useTranslation();
  const activeVisitorForm = form.isActive("visitorForm");
  const [edit, setEdit] = useState(false);

  const handelCheckboxToggle = (id) => {
    form.formik.setFieldValue(id, !form.formik.values[id]);
  };

  const {
    visitorName,
    visitorEmail,
    visitorCompanyName,
    visitorContactNumber,
    visitorVehicleInformation,
    visitorIdNumber,
    visitorList,
  } = form.formik.values;

  // const handleCsvUpload = (event) => {
  //   try {
  //     // console.log(event.target.files[0]);
  //     if (
  //       event.target.files[0].type == "text/csv" ||
  //       event.target.files[0].type == "text/plain"
  //     ) {
  //       Papa.parse(event.target.files[0], {
  //         encoding: "ISO-8859-1",
  //         header: true,
  //         skipEmptyLines: true,
  //         complete: function (results) {
  //           // console.log(results);
  //           if (results.data && results.data.length > 0) {
  //             const jsonData = results.data.map((obj) => {
  //               return removeSpacesFromKeys(obj);
  //             });
  //             const filteredData = removeKeysWithEmpty(jsonData);
  //             console.log(filteredData);
  //             if (checkForValidation(filteredData)) {
  //               form.handleCsvUpload(filteredData);
  //             } else {
  //               Swal.fire(
  //                 t("error"),
  //                 "There are some mandatory fields for Visitors that are missing. Please check and try again."
  //               );
  //             }
  //           }

  //           if (event.target.files[0]) {
  //             event.target.value = "";
  //           }
  //         },
  //       });
  //     } else {
  //       const file = event.target.files[0];
  //       const reader = new FileReader();
  //       reader.onload = (e) => {
  //         const data = new Uint8Array(e.target.result);
  //         const workbook = XLSX.read(data, { type: "array" });
  //         const sheetName = workbook.SheetNames[0];
  //         const sheet = workbook.Sheets[sheetName];
  //         // Convert Excel to JSON
  //         const jsonData = XLSX.utils.sheet_to_json(sheet).map((obj) => {
  //           return removeSpacesFromKeys(obj);
  //         });
  //         // console.log(jsonData);
  //         const filteredData = removeKeysWithEmpty(jsonData);
  //         console.log(filteredData);
  //         if (checkForValidation(filteredData)) {
  //           form.handleCsvUpload(filteredData);
  //         } else {
  //           Swal.fire(
  //             t("error"),
  //             "There are some mandatory fields for Visitors that are missing. Please check and try again."
  //           );
  //         }
  //       };
  //       reader.readAsArrayBuffer(file);
  //       // console.log('XLSX file');
  //     }
  //   } catch {
  //     console.log("Something went wrong");
  //   }
  // };

  const handleCsvUpload = (event) => {
    try {
      const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
      const requiredFields = [
        "Visitor Name",
        "Visitor Email",
        "Contact Number",
      ];
      let invalidData = [];
      let validData = [];

      const validateEntry = (entry, index) => {
        let errors = [];
        requiredFields.forEach((field) => {
          if (!entry[field] || !entry[field].trim()) {
            errors.push(`${field} is missing`);
          }
        });
        if (
          entry["Visitor Email"] &&
          !emailRegex.test(entry["Visitor Email"])
        ) {
          errors.push("Visitor Email is invalid");
        }
        if (errors.length > 0) {
          invalidData.push(`Row ${index + 1}: ${errors.join(", ")}`);
          return false;
        }
        return true;
      };

      const processFileData = (jsonData) => {
        invalidData = [];
        validData = jsonData.filter((entry, index) =>
          validateEntry(entry, index)
        );

        if (invalidData.length > 0) {
          Swal.fire({
            icon: "error",
            title: t("error"),
            html: `${invalidData.join("<br>")} ${
              validData.length > 0
                ? `<br><br><b  style="font-size:18px">${
                    validData.length
                  } visitor${validData.length > 1 ? "s" : ""}  ${
                    validData.length > 1 ? "have" : "has"
                  } been added.<b>`
                : ""
            }`,
          });
        }
        if (validData.length > 0) {
          form.handleCsvUpload(validData);
        }
      };

      if (
        event.target.files[0].type === "text/csv" ||
        event.target.files[0].type === "text/plain"
      ) {
        Papa.parse(event.target.files[0], {
          encoding: "ISO-8859-1",
          header: true,
          skipEmptyLines: true,
          complete: function (results) {
            if (results.data && results.data.length > 0) {
              const jsonData = results.data.map(removeSpacesFromKeys);
              const filteredData = removeKeysWithEmpty(jsonData);
              processFileData(filteredData);
            }
            event.target.value = ""; // Reset input
          },
        });
      } else {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = XLSX.read(data, { type: "array" });
          const sheet = workbook.Sheets[workbook.SheetNames[0]];
          const jsonData = XLSX.utils
            .sheet_to_json(sheet)
            .map(removeSpacesFromKeys);
          const filteredData = removeKeysWithEmpty(jsonData);
          processFileData(filteredData);
        };
        reader.readAsArrayBuffer(event.target.files[0]);
      }
    } catch (error) {
      console.log("Something went wrong", error);
    }
  };

  const removeKeysWithEmpty = (data) => {
    // Filtering data to remove keys with empty values
    const filteredData = data.map((item) => {
      const filteredItem = {};
      for (const key in item) {
        if (item[key] !== "") {
          filteredItem[key] = item[key];
        }
      }
      return filteredItem;
    });

    return filteredData;
  };

  function removeSpacesFromKeys(obj) {
    const newObj = {};
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        const trimmedKey = key.trim();
        let value = obj[key].toString();
        newObj[trimmedKey] = value.trim();
      }
    }
    return newObj;
  }

  const checkForValidation = (data) => {
    const requiredKeys = ["Visitor Name", "Visitor Email", "Contact Number"];
    const missingObjects = [];
    data.forEach((obj, index) => {
      const missingKeys = requiredKeys.filter((key) => !(key in obj));
      if (missingKeys.length > 0) {
        missingObjects.push({ index: index + 1, missingKeys });
      }
    });

    if (missingObjects.length > 0) {
      return false;
      // return missingObjects.map((obj) =>
      //   console.log(`Object at index ${obj.index}: Missing keys: ${obj.missingKeys.join(', ')}`)
      // );
    } else {
      return true;
      // return console.log('All objects have required keys.');
    }
  };

  return (
    <div {...css(styles.container)}>
      <div {...css(styles.section)}>
        <Row>
          <VisitorDetailsTitle
            text={t("create-ticket.site-access.visit-details-title")}
            csvButton
            active={activeVisitorForm}
            // active={true}
            onCsvUpload={handleCsvUpload}
          />
        </Row>
        <div>
          <VisitDetails
            setFieldValue={form.formik.setFieldValue}
            startDateTime={form.formik.values.startDateTime}
            endDateTime={form.formik.values.endDateTime}
            active={form.isActive("ticketSubject")}
          />
        </div>
        <VisitorDetailsForm
          visitor={{
            visitorName,
            visitorEmail,
            visitorCompanyName,
            visitorContactNumber,
            visitorVehicleInformation,
            visitorIdNumber,
          }}
          active={activeVisitorForm}
          // active={true}
          setFieldValue={form.formik.setFieldValue}
          onAddVisitor={() => {
            if (form?.handleAddVisitor()?.isError) {
              setEdit(true);
            } else {
              setEdit(false);
            }
          }}
        />
        <Row>
          <VisitorListTitle
            handleClick={form.removeAllVisitors}
            text={t("create-ticket.site-access.visitor-list-title")}
            active={visitorList.length > 0}
          />
        </Row>
        <VisitorList
          visitors={form.formik.values.visitorList}
          onDelete={form.handleDeleteVisitor}
          onChange={form.handleChangeVisitor}
          onSave={() => {
            if (form.handleSaveUser()) {
              setEdit(true);
            } else {
              setEdit(false);
            }
          }}
          onEdit={form.handleOldUser}
          onNo={form.handleOldUser}
          isEditMode={form.isEditMode}
          setIsEditMode={form.setIsEditMode}
        />
        <ConfirmVerifyModal
          show={edit}
          setShow={setEdit}
          message={form?.err?.errorMessage}
          handleClick={() => setEdit}
        />
      </div>

      <div {...css(styles.section)}>
        <div className="mb-3 pb-2" {...css(styles.checkBoxes)}>
          <TicketCheckBox
            label={t("create-ticket.site-access.allow-open")}
            checked={form.formik.values.allowOpenRack}
            active={activeVisitorForm}
            handelCheckboxToggle={() => handelCheckboxToggle("allowOpenRack")}
          />
          <TicketCheckBox
            label={t("create-ticket.site-access.cargo-use")}
            active={activeVisitorForm}
            checked={form.formik.values.cargoUse}
            handelCheckboxToggle={() => handelCheckboxToggle("cargoUse")}
          />
          <TicketCheckBox
            label={t("create-ticket.site-access.agree-collection")}
            fontSize="14px"
            checked={form.formik.values.agreeCollection}
            active={activeVisitorForm}
            handelCheckboxToggle={() => handelCheckboxToggle("agreeCollection")}
          />
        </div>
        <ActionButtons
          setFieldValue={form.formik.setFieldValue}
          active={form.isActive("submit")}
          isVisitorFormNotEmpty={form.checkIfVisitorFormNotEmpty()}
          validationMessage={form.validationMessage}
          setValidationMessage={form.setValidationMessage}
          closeModal={closeModal}
        />
      </div>
    </div>
  );
};

export default RightPart;
