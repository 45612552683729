// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `button.btn.btn-upload-img {
    padding: 5px 6px;
    border: 1px solid #000;
    border-radius: 100px;
    font-size: 15px;
    width: 160px;
    position: absolute;
    right: 15px;
    top: 0px;
}
.pages_count {
    position: absolute;
    right: -35%;
    top: 0;
}
.map_pic {
    margin-top: 0rem;
}
@media screen and (max-width: 821px){
  button.btn.btn-upload-img {
    padding: 5px 6px;
    border: 1px solid #000;
    border-radius: 100px;
    font-size: 15px;
    width: 160px;
    position: relative;
    left: 0px;
    top: 0px;
}
.pages_count {
    position: absolute;
    right: 0;
    top: 0;
}
}`, "",{"version":3,"sources":["webpack://./src/views/super-admin/Setting/Network/network.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,sBAAsB;IACtB,oBAAoB;IACpB,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,WAAW;IACX,QAAQ;AACZ;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,MAAM;AACV;AACA;IACI,gBAAgB;AACpB;AACA;EACE;IACE,gBAAgB;IAChB,sBAAsB;IACtB,oBAAoB;IACpB,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,SAAS;IACT,QAAQ;AACZ;AACA;IACI,kBAAkB;IAClB,QAAQ;IACR,MAAM;AACV;AACA","sourcesContent":["button.btn.btn-upload-img {\n    padding: 5px 6px;\n    border: 1px solid #000;\n    border-radius: 100px;\n    font-size: 15px;\n    width: 160px;\n    position: absolute;\n    right: 15px;\n    top: 0px;\n}\n.pages_count {\n    position: absolute;\n    right: -35%;\n    top: 0;\n}\n.map_pic {\n    margin-top: 0rem;\n}\n@media screen and (max-width: 821px){\n  button.btn.btn-upload-img {\n    padding: 5px 6px;\n    border: 1px solid #000;\n    border-radius: 100px;\n    font-size: 15px;\n    width: 160px;\n    position: relative;\n    left: 0px;\n    top: 0px;\n}\n.pages_count {\n    position: absolute;\n    right: 0;\n    top: 0;\n}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
