/* eslint-disable react-hooks/exhaustive-deps */
import AuthContext from "context";
import React, { useContext, useEffect, useState } from "react";
import CabinetService from "services/CabinetService";
import Common from "services/commonService";
import floorServices from "services/floorServices";
import networkRoomService from "services/networkRoomService";
import Swal from "sweetalert2";
import "../roomStyle.css";
import { LogoutOnSessionExpire } from "common/helpers";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
const EditRoom = ({
  closeModal,
  activeDataCenter,
  getNetworkRooms,
  selectedRoom,
}) => {
  const authContext = useContext(AuthContext);
  const contextStore = useContext(AuthContext);
  const [roomType, setRoomType] = useState([]);
  const [floor, setFloor] = useState([]);
  const [room, setRoom] = useState([]);
  const [status, setStatus] = useState([]);
  const [cabinet, setCabinet] = useState([]);
  const [idfStatus, setIdfStatus] = useState(false);
  const { t } = useTranslation();
  const [state, setState] = useState({
    id: selectedRoom.id,
    data_center: activeDataCenter.id,
    name: selectedRoom.name,
    description: selectedRoom.remarks,
    ref_name: selectedRoom.ref_name,
    floor_id: selectedRoom.floor_id,
    room_id: selectedRoom.data_hall_id,
    room_type: selectedRoom.room_type_id,
    cabinet_id: selectedRoom.cabinet_id,
    status: selectedRoom.status,
  });
  const [error, setError] = useState({
    name: "",
    description: "",
    ref_name: "",
    floor_id: "",
    room_id: "",
    room_type: "",
    cabinet_id: "",
    status: "",
  });

  useEffect(() => {
    // using context for status api
    if (contextStore.getStatusData) {
      setStatus(contextStore.getStatusData);
    }
    // await Common.status().then((res) => setStatus(res.data.data))
    getFllorsByDataCenterId();
    getAllRoomType();
    //getRoom(selectedRoom.floor_id)
  }, []);

  const getFllorsByDataCenterId = async () => {
    await floorServices
      .floorByDataCenterId(authContext.token(), activeDataCenter)
      .then((res) => {
        if (selectedRoom.room_type_id === "8") {
          setIdfStatus(true);
        } else {
          setIdfStatus(false);
        }
        setFloor(res.data.data);
        const data = res.data.data.filter(
          (d) => d.id === Number(selectedRoom.floor_id)
        );
        setRoom(data[0].rooms.filter((d) => d.room_type_id === 7));
        getCabinets(state.room_id);
        //setState({...state,room_id: selectedRoom.data_hall_id})
      });
  };

  const getAllRoomType = async () => {
    await networkRoomService
      .getAllRoomType(authContext.token())
      .then((res) =>
        setRoomType(
          res.data.data.filter((data) => data.type === "network_room")
        )
      );
  };

  const getRoom = (id) => {
    const data = floor.filter((d) => d.id === Number(id));
    setRoom(data[0].rooms.filter((d) => d.room_type_id === 7));
    setCabinet([]);
  };

  const getCabinets = async (id) => {
    if (id !== "") {
      CabinetService.getAllCabinetsRoomID(authContext.token(), id).then((res) =>
        setCabinet(res.data.data)
      );
    } else {
      setCabinet([]);
      setState({
        ...state,
        cabinet_id: "",
      });
    }
  };

  const submitNetworkRoom = async (data) => {
    // setState({...state,data_center_id: props.data_center_id})

    if (checkValidation()) {
      // setState({...state,data_center_id:props.dataCenterId.id})

      await networkRoomService
        .updateRoom(authContext.getToken, { ...state })
        .then(async (res) => {
          getNetworkRooms(activeDataCenter.id);
          closeModal();
          Swal.fire(t("cabling.network_room_updated"));
          //props.selectDataCenterFloor(props.dataCenterId)
        })
        .catch((err) => {
          LogoutOnSessionExpire(err);

          let error = {
            name: "",
            floor_id: "",
            room_type: "",
            cabinet_id: "",
          };
          const errors = err?.response?.data?.errors;

          if (
            errors?.name !== undefined ||
            errors?.name !== "" ||
            errors?.name !== null
          ) {
            error.name = errors.name;
          }

          if (
            errors?.floor_id !== undefined ||
            errors?.floor_id !== "" ||
            errors?.floor_id !== null
          ) {
            error.floor_id = errors.floor_id;
          }
          if (
            errors?.room_type !== undefined ||
            errors?.room_type !== "" ||
            errors?.room_type !== null
          ) {
            error.room_type = errors.room_type;
          }

          setError({ ...error });
        });
    }
  };

  const checkValidation = () => {
    let error = {
      name: "",
      floor_id: "",
      room_type: "",
      cabinet_id: "",
    };

    const { name, floor_id, room_type } = state;

    let flag = true;

    if (name === "" || name === null || name === undefined) {
      error.name = "The name field is required.";
      flag = false;
    }

    if (floor_id === "" || floor_id === null || floor_id === undefined) {
      error.floor_id = "The floor id field is required.";
      flag = false;
    }

    if (room_type === "" || room_type === null || room_type === undefined) {
      error.power = "The room type field is required.";
      flag = false;
    }
    setError({ ...error });
    return flag;
  };

  const onDelete = () => {
    Swal.fire({
      title: t("common.are_you_sure"),
      text: t("common.you_wont_be_able_to_revert_this"),
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: t("common.yes_delete_it"),
      customClass: {
        cancelButton: "cancelBtn",
        confirmButton: "confirmBtn",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        networkRoomService
          .destroyRoom(authContext.getToken, state)
          .then((res) => {
            getNetworkRooms(activeDataCenter.id);
            closeModal(false);
            Swal.fire("success", t("cabling.deleted"), "success");
          })
          .catch((err) => {
            LogoutOnSessionExpire(err);
            closeModal(false);
            Swal.fire("error", t("cabling.something_went_wrong"));
          });
      }
    });
  };

  return (
    <div className="modal show" style={{ display: "block" }}>
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header mt-59">
            <h3 className="modal-title">{t("cabling.update_network_room")}</h3>

            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => closeModal(false)}
              data-bs-dismiss="modal"
            />
          </div>
          <div className="modal-body">
            <div className="card">
              <div
                className="card-body"
                style={{ padding: "0px", overflow: "hidden" }}
              >
                <p>{activeDataCenter.name}</p>
                <div className="basic-form">
                  <form>
                    <div className="row">
                      <div className="col-md-6 mt-2313">
                        <label className="form-label">
                          {t("cabling.name")}:
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name"
                          onChange={(event) =>
                            setState({
                              ...state,
                              name: event.target.value,
                            })
                          }
                          value={state.name}
                        />
                      </div>
                      <div className="col-md-6 col-sm-6 mt-2313">
                        <label className="form-label">
                          {t("cabling.room_type")}:
                          <span className="text-danger">*</span>
                        </label>
                        <select
                          className="default-select form-control wide"
                          value={state.room_type}
                          onChange={(event) => {
                            setState({
                              ...state,
                              room_type: event.target.value,
                            });
                            if (event.target.value === "8") {
                              setIdfStatus(true);
                            } else {
                              setIdfStatus(false);
                            }
                          }}
                        >
                          <option value="">{t("cabling.choose")} ...</option>
                          {roomType &&
                            roomType.map((room) => {
                              return (
                                <option value={room.id} key={room.id}>
                                  {room.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 col-sm-12 mt-2313">
                        <label className="form-label">
                          {t("cabling.floor")}:{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <select
                          className="default-select form-control wide"
                          value={state.floor_id}
                          onChange={(event) => {
                            getRoom(event.target.value);
                            setState({
                              ...state,
                              floor_id: event.target.value,
                              room_id: "",
                              cabinet_id: "",
                            });
                          }}
                        >
                          <option value="">{t("cabling.choose")} ...</option>
                          {floor &&
                            floor.map((floor) => {
                              return (
                                <option value={floor.id} key={floor.id}>
                                  {floor.name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                    {idfStatus && (
                      <div className="row">
                        <div className="col-md-6 col-sm-6 mt-2313">
                          <label className="form-label">
                            {" "}
                            {t("cabling.room")}:
                          </label>
                          <select
                            className="default-select form-control wide"
                            value={state.room_id}
                            onChange={(event) => {
                              setState({
                                ...state,
                                room_id: event.target.value,
                              });
                              getCabinets(event.target.value);
                            }}
                          >
                            <option value="">{t("cabling.choose")} ...</option>
                            {room &&
                              room.map((hall) => {
                                return (
                                  <option value={hall.id} key={hall.id}>
                                    {hall.name}
                                  </option>
                                );
                              })}
                          </select>
                          <span style={{ fontSize: "9px" }}>
                            {t("cabling.for_rooms_inside_a_room")}
                          </span>
                        </div>
                        <div className="col-md-6 col-sm-6 mt-2313">
                          <label className="form-label">
                            {" "}
                            {t("cabling.cabinet_rack")}:
                          </label>
                          <select
                            className="default-select form-control wide"
                            onChange={(event) =>
                              setState({
                                ...state,
                                cabinet_id: event.target.value,
                              })
                            }
                            value={state.cabinet_id}
                          >
                            <option value="">{t("cabling.choose")} ...</option>
                            {cabinet &&
                              cabinet.map((cab) => {
                                return (
                                  <option value={cab.id} key={cab.id}>
                                    {cab.name}
                                  </option>
                                );
                              })}
                          </select>
                          <span style={{ fontSize: "9px" }}>
                            {t("cabling.for_idf_racks")}
                          </span>
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-md-6 col-sm-6 mt-2313">
                        <label className="form-label">
                          {" "}
                          {t("cabling.status")}:
                          <span className="text-danger">*</span>
                        </label>
                        <select
                          className="default-select form-control wide"
                          value={state.status}
                          onChange={(event) =>
                            setState({
                              ...state,
                              status: event.target.value,
                            })
                          }
                        >
                          {status &&
                            status.map((status, i) => {
                              if (status.status_type_id === 4) {
                                return (
                                  <option value={status.id} key={status.id}>
                                    {status.status_name}
                                  </option>
                                );
                              }
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 col-sm-12 mt-2313">
                        <label className="form-label">
                          {" "}
                          {t("cabling.remarks")}:
                        </label>
                        <textarea
                          className="form-control"
                          style={{ height: "70px" }}
                          onChange={(event) =>
                            setState({
                              ...state,
                              description: event.target.value,
                            })
                          }
                        >
                          {state.description}
                        </textarea>
                      </div>
                    </div>
                    <div
                      class="toolbar toolbar-bottom mt-51 d-flex"
                      role="toolbar"
                      style={{ float: "right" }}
                    >
                      <div class="delt" style={{ marginRight: "25px" }}>
                        <button
                          type="button"
                          onClick={onDelete}
                          class="btn btn-outline-primary mr_1 red_color"
                        >
                          <img
                            src="\images\trash-2.svg"
                            alt=""
                            style={{
                              width: "11px",
                              marginTop: "-0.188rem",
                            }}
                          />
                          {t("common.delete")}
                        </button>
                      </div>
                      <button
                        type="button"
                        onClick={() => closeModal(false)}
                        class="btn btn-outline-primary mr_1"
                      >
                        {" "}
                        {t("common.cancel")}{" "}
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        onClick={() => submitNetworkRoom()}
                      >
                        {t("common.save")}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditRoom;
