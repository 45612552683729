import React, { useContext, useEffect, useRef, useState } from "react";
import CabinetBreakerSwitch from "./CabinetBreakerSwitch";
import "./CabinetOrBreaker.css";
import CabinetTable from "./CabinetTable";
import BreakerTable from "./BreakerTable";
import { useTranslation } from "react-i18next";
import SerachBox from "views/super-admin/Accounts/searchBox";
import Pagination from "views/super-admin/Ticket/components/shared/usePagination";
const CabinetOrBreaker = ({
  selectedRoom,
  searchQuery,
  filterCabinets,
  filterBreakers,
  cabinetAscending,
  setCabinetAscending,
  cabinets,
  breakers,
  getEditCabinetPopup,
  isReadOnly,
  getStatus,
  navigationActiveTab,
  setNavigationActiveTab,
  getCurrentPageNext,
  isLoading,
  setIsLoading,
  lastRefreshTime,
  searchCategory,
  query,
  setQuery,
  setPageNumber,
  setItemsPerPage,
  total,
  pageNumber,
}) => {
  const { t } = useTranslation();

  return (
    <div className="col-lg-7 col-md-12 col-sm-12 col-12">
      <div className="grid_card h-100">
        <div className="card_head">
          <div className="col-xl-12 col-lg-12">
            <div className="row">
              <div className="col-md-7">
                <div
                  className="txt_card"
                  style={{ marginLeft: "11px", marginTop: "5px" }}
                >
                  <h3>{selectedRoom?.name || ""}</h3>
                </div>
              </div>
              <div
                className="col-md-5"
                style={{ margingTop: "5px", marginBottom: "5px" }}
              >
                <div className="d-flex align-items-center justify-content-end">
                  <div>{t("dashboard.filter")}: &nbsp;</div>
                  <SerachBox
                    searchCategory={searchCategory}
                    query={query}
                    setQuery={setQuery}
                    setIsLoading={setIsLoading}
                    setPageNumber={setPageNumber}
                    setItemsPerPage={setItemsPerPage}
                    navigationActiveTab={navigationActiveTab}
                  ></SerachBox>
                </div>
              </div>
            </div>
            <div className="dashboard-cabinet-nav">
              <CabinetBreakerSwitch
                navigationActiveTab={navigationActiveTab}
                setNavigationActiveTab={setNavigationActiveTab}
              />
            </div>

            <div
              className="card-body data-hall-block"
              style={{ overflow: "hidden" }}
            >
              <div
                className="table-responsive"
                style={{
                  overflowX: "auto",
                  width: "100%",
                  height: "400px",
                  marginBottom: "1.5rem",
                }}
              >
                {navigationActiveTab === 1 && (
                  <CabinetTable
                    cabinets={cabinets}
                    cabinetAscending={cabinetAscending}
                    setCabinetAscending={setCabinetAscending}
                    getEditCabinetPopup={getEditCabinetPopup}
                    isReadOnly={isReadOnly}
                    getStatus={getStatus}
                    lastRefreshTime={lastRefreshTime}
                  />
                )}
                {navigationActiveTab === 2 && (
                  <BreakerTable
                    breakers={breakers}
                    setIsLoading={setIsLoading}
                  />
                )}
              </div>
              <div className="d-flex justify-content-end mt-3">
                {((cabinets && cabinets.length > 0) ||
                  (breakers && breakers.length > 0)) && (
                  <Pagination
                    setItemsPerPage={setItemsPerPage}
                    setPageNumber={setPageNumber}
                    total={total}
                    pageNumber={pageNumber}
                  ></Pagination>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CabinetOrBreaker;
