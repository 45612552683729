import { useRef, useState } from 'react';
import Layout from "../Component/LayoutWithDataCenter";
import { subMenu } from "./data";

const Cabling = () => {

	const initialMount = useRef(true);
	const [dataCenter, setDataCenter] = useState([]);
	const [countryName, setCountryName] = useState("Country");
	const [activeTab,setActiveTab] = useState();
	const [country, setCountry] = useState(0);
	const [triggerRefresh, setTriggerRefresh] = useState(false);

	return (
		<Layout
		setTriggerRefresh={setTriggerRefresh}
        triggerRefresh={triggerRefresh}
		initialMount={initialMount}
		dataCenter={dataCenter}
		setDataCenter={setDataCenter}
		countryName={countryName}
		setCountryName={setCountryName}
		activeTab={activeTab}
		setActiveTab={setActiveTab}
		country={country}
		setCountry={setCountry}
		subMenu={subMenu}
		>
			
		</Layout>
	);
}

export default Cabling;