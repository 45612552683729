// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ticket-multi-select-field-multi-select .select__menu {
  z-index: 10;
}

.ticket-multi-select-field-multi-select-nolabel .select__menu {
  z-index: 10;
}
.ticket-multi-select-field-multi-select-nolabel .select__control {
  background-color: #fff !important;
  border-color: #fff !important;
}`, "",{"version":3,"sources":["webpack://./src/views/super-admin/Tickets/shared/TicketSelectField/TextSelectField.scss"],"names":[],"mappings":"AACI;EACI,WAAA;AAAR;;AAMI;EACI,WAAA;AAHR;AAMI;EACI,iCAAA;EACA,6BAAA;AAJR","sourcesContent":[".ticket-multi-select-field-multi-select {\n    .select__menu {\n        z-index: 10;\n\n    }\n}\n\n.ticket-multi-select-field-multi-select-nolabel {\n    .select__menu {\n        z-index: 10;\n\n    }\n    .select__control {\n        background-color: #fff !important;\n        border-color: #fff !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
