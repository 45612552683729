const SiteAccessDetailTable = ({
  visitors,
  toggleEdit,
  istypetrue,
  delVisitor,
  setToggleLine,
  checkPermission,
}) => {
  return (
    <div class="site-table" style={{ width: "90%" }}>
      <table class="table">
        <thead>
          <tr>
            <th scope="col" class="site-tr">
              Visitor Name
            </th>
            <th scope="col" class="site-tr">
              Visitor Email
            </th>
            <th scope="col" class="site-tr">
              Visitor ID Number
            </th>
            <th scope="col" class="site-tr">
              Visitor Company
            </th>
            <th scope="col" class="site-tr">
              Contact Number
            </th>
            <th scope="col" class="site-tr">
              Cargo E/V Use
            </th>
            <th scope="col" class="site-tr">
              Vehicle Inf (Plate No.)
            </th>
          </tr>
        </thead>
        <tbody>
          {visitors &&
            visitors.map((data, key) => {
              return (
                <tr key={key}>
                  <td class="site-tr">{data?.name}</td>
                  <td class="site-tr">{data?.email}</td>
                  <td class="site-tr">{data?.identification_number}</td>
                  <td class="site-tr">{data?.visitor_company_name}</td>
                  <td class="site-tr">{data?.contact_number}</td>
                  <td class="site-tr">
                    {data?.cargo_use ? (
                      <input type="checkbox" checked disabled />
                    ) : (
                      <input type="checkbox" disabled />
                    )}
                  </td>
                  <td class="site-tr">{data?.vehicle_info}</td>
                  {toggleEdit &&
                  istypetrue &&
                  checkPermission("Edit Details") &&
                  checkPermission("Maintenance Status") ? (
                    <td
                      class="site-tr"
                      style={{
                        borderTop: "0.0625rem solid #EEEEEE",
                      }}
                    >
                      {" "}
                      <img
                        src="images/fclose.svg"
                        alt=""
                        width={15}
                        height={15}
                        onClick={() => delVisitor(key)}
                      />
                    </td>
                  ) : null}
                </tr>
              );
            })}
          {toggleEdit &&
            istypetrue &&
            checkPermission("Add Visitors") &&
            checkPermission("Maintenance Status") && (
              <tr>
                <div
                  style={{
                    color: "#14d6d3",
                    fontWeight: "600",
                    cursor: "pointer",
                  }}
                  onClick={() => setToggleLine(true)}
                >
                  Add a line
                </div>
              </tr>
            )}
        </tbody>
      </table>
    </div>
  );
};
export default SiteAccessDetailTable;
