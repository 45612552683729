export const SHEETS = {
  PERFORMANCE_REPORT: 0,
  CAPACITY_BREKDOWN: 2,
}

const PERFORMANCE_REPORT_MAPPING = [
  { row: 5, col: 2, field: "dataCenterName", title: "Data Center Name" },
  { row: 6, col: 2, field: "reportingMonth", title: "Reporting Month" },
  { row: 12, col: 2, field: "dp_availability", title: "Availability" },
  {
    row: 15,
    col: 2,
    field: "dp_infra_incident_num",
    title: "Infrastructure Incident Number",
  },
  {
    row: 16,
    col: 2,
    field: "dp_infra_incident_type",
    title: "Infrastructure Incident Type",
  },
  {
    row: 17,
    col: 2,
    field: "ii_impact",
    title: "Infrastructure Incident Impact",
  },
  {
    row: 21,
    col: 2,
    field: "dp_security_incident_num",
    title: "Security Incident Number",
  },
  {
    row: 22,
    col: 2,
    field: "dp_security_incident_type",
    title: "Security Incident Type",
  },
  { row: 23, col: 2, field: "si_impact", title: "Security Incident Impact" },
  {
    row: 26,
    col: 2,
    field: "dp_ehs_incident_num",
    title: "EHS Incident Number",
  },
  {
    row: 27,
    col: 2,
    field: "dp_ehs_incident_type",
    title: "EHS Incident Type",
  },
  { row: 28, col: 2, field: "ei_impact", title: "EHS Incident Impact" },
  { row: 31, col: 2, field: "dp_opertating_pue", title: "Opertating PUE" },
  { row: 32, col: 2, field: "dp_design_pue", title: "Design PUE" },
  { row: 33, col: 2, field: "dp_installed_kw", title: "Installed KW" },
  { row: 34, col: 2, field: "dp_operating_kw", title: "Operating KW" },
]
const DESIGN_PUE_AND_TARGET_PUE_MAPPING = [
  {
    row: 44,
    col: [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
    field: "month",
    title: "Month",
  },
  {
    row: 46,
    col: [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
    field: "design_pue",
    title: "Design PUE",
  },
  {
    row: 47,
    col: [3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
    field: "target_pue",
    title: "Target PUE",
  },
]

const CB_SETTING = {
  SECTION_ANCHOR_NAME: "DATA HALL",
  SECTION_ANCHOR_COL: 2,
  SECTION_TYPE_ANCHOR_COL: 6,
  SECTIONS: [
    {
      id: 0,
      name: "CABE",
    },
    {
      id: 1,
      name: "IT POWER (KW)",
    },
    {
      id: 2,
      name: "CAGES",
    },
  ],
  NEXT_ROOM_OFFSET: 2,
  ROOM_NAME_COL: 2,
}

const CAPACITY_BREKDOWN_MAPPING = [
  { section: 0, col: 3, field: "total_cabs" },
  { section: 1, col: 3, field: "total_power" },
  { section: 2, col: 3, field: "total_cages" },
  { section: 0, col: 4, field: "sold_cabs" },
  { section: 1, col: 4, field: "sold_power" },
  { section: 2, col: 4, field: "sold_cages" },
  { section: 0, col: 6, field: "reserved_cabs" },
  { section: 1, col: 6, field: "reserved_power" },
  { section: 2, col: 6, field: "reserved_cages" },
  { section: 0, col: 8, field: "blocked_cabs" },
  { section: 1, col: 8, field: "blocked_power" },
  { section: 2, col: 8, field: "blocked_cages" },
  { section: 0, col: 10, field: "available_cabs" },
  { section: 1, col: 10, field: "available_power" },
  { section: 2, col: 10, field: "available_cages" },
]

export const checkFieldsPresence = (list) => {
  const missingFields = []
  const foundFields = list.map(([key]) => key)
  const foundFieldsMap = {}
  list.forEach(([key, value]) => {
    foundFieldsMap[key] = value
  })

  PERFORMANCE_REPORT_MAPPING.forEach((rec) => {
    if (
      !foundFields.includes(rec.field) ||
      foundFieldsMap[rec.field] === undefined
    ) {
      missingFields.push(rec.field)
    }
    // console.log("++", {r: rec, f: foundFieldsMap[rec.field]})
  })
  if (missingFields.length > 0) {
    const missingFieldsFullName = missingFields.map((f) => {
      return PERFORMANCE_REPORT_MAPPING.find((r) => r.field === f).title
    })
    throw `Missing ${missingFieldsFullName.join(", ")}`
  }
}

const getRoom = (rows, index) => {
  const roomRow = rows.find((r) => r.index === index).row
  if (roomRow[CB_SETTING.ROOM_NAME_COL]) {
    return roomRow
  }
  return null
}

const getRooms = (rows, firstRowIndex) => {
  const rooms = []
  const firstRoomRow = getRoom(rows, firstRowIndex)
  if (firstRoomRow) {
    rooms.push(firstRoomRow)
  }
  let currentRoomIndex = firstRowIndex
  while (getRoom(rows, currentRoomIndex + CB_SETTING.NEXT_ROOM_OFFSET)) {
    const nextRoom = getRoom(
      rows,
      currentRoomIndex + CB_SETTING.NEXT_ROOM_OFFSET
    )
    const roomName = nextRoom[CB_SETTING.ROOM_NAME_COL]
    const regex = /\w\w\w\w-\w\w-\w\w/
    if (nextRoom && regex.test(roomName)) {
      rooms.push(nextRoom)
    } else {
      break
    }
    currentRoomIndex += CB_SETTING.NEXT_ROOM_OFFSET
  }

  return rooms
}

const getResult = (field) => {
  if (field?.formula) {
    return field.result || 0
  }
  return field
}

const mapToFields = (dividedBysections) => {
  const data = {}
  CAPACITY_BREKDOWN_MAPPING.forEach((rec) => {
    if (dividedBysections[rec.section]) {
      data[rec.field] = getResult(dividedBysections[rec.section][rec.col])
    } else {
      data[rec.field] = 0
    }
  })

  // console.log("test", data);
  return data
}

const getRowByIndex = (rows, index) => {
  return rows.find((r) => r.index === index).row
}

const getRoomReport = (index, room, rows, sectionRows) => {
  try {
    const roomName = room[CB_SETTING.ROOM_NAME_COL]
    const dividedBysections = {}
    sectionRows.forEach((section) => {
      dividedBysections[section.rowTypeIndex] = getRoom(
        rows,
        section.rowIndex + index * CB_SETTING.NEXT_ROOM_OFFSET
      )
    })
    // console.log(dividedBysections);

    return { roomName, report: mapToFields(dividedBysections) }
  } catch (err) {
    console.log(err)
    throw "Can't breakdown the report into sections"
  }

  // console.log({ index, room, rows, dividedBysections});
}

const getSectionRows = (rows) => {
  const getRowType = (rowTypeName) => {
    try {
      return CB_SETTING.SECTIONS.find(
        (s) => s.name === rowTypeName.toUpperCase()
      ).id
    } catch (err) {
      throw `Can't identify this section: '${rowTypeName}'. Did you mean ${CB_SETTING.SECTIONS.map(
        (c) => c.name
      ).join(", ")}?`
    }
  }
  const sections = []
  rows.forEach((r) => {
    if (
      r.row[CB_SETTING.SECTION_ANCHOR_COL] &&
      r.row[CB_SETTING.SECTION_ANCHOR_COL].toUpperCase() ===
        CB_SETTING.SECTION_ANCHOR_NAME
    ) {
      const rowWithType = getRowByIndex(rows, r.index - 1)
      const rowTypeName = rowWithType[CB_SETTING.SECTION_TYPE_ANCHOR_COL]
      const rowTypeIndex = getRowType(rowTypeName)
      if (rowTypeIndex === undefined) {
        throw "Can't determine section type"
      }
      // console.log({rowTypeIndex})
      sections.push({ rowIndex: r.index + 1, rowTypeIndex })
    }
  })
  return sections
}

const validateRoomData = (rooms, rows) => {
  let invalidFields = []

  rooms.forEach((room, index) => {
    const roomReport = getRoomReport(index, room, rows, getSectionRows(rows))
    const {
      total_cabs,
      total_power,
      total_cages,
      sold_cabs,
      sold_power,
      sold_cages,
      reserved_cabs,
      reserved_power,
      reserved_cages,
      blocked_cabs,
      blocked_power,
      blocked_cages,
    } = roomReport.report

    const roomName = roomReport.roomName
    let isValid = true

    // Calculate deltas
    const deltaCabs = total_cabs - (sold_cabs + reserved_cabs + blocked_cabs)
    const deltaPower =
      total_power - (sold_power + reserved_power + blocked_power)
    const deltaCages =
      total_cages - (sold_cages + reserved_cages + blocked_cages)
    // Checking if any delta becomes negative
    if (deltaCabs < 0) {
      invalidFields.push({
        roomName,
        field: "sold_cabs",
        delta: deltaCabs,
      })
      isValid = false
    }

    if (deltaPower < 0) {
      invalidFields.push({
        roomName,
        field: "sold_power",
        delta: deltaPower,
      })
      isValid = false
    }

    if (deltaCages < 0) {
      invalidFields.push({
        roomName,
        field: "sold_cages",
        delta: deltaCages,
      })
      isValid = false
    }

    // if delta is non-negative
    if (isValid) {
      roomReport.report.available_cabs = deltaCabs
      roomReport.report.available_power = deltaPower
      roomReport.report.available_cages = deltaCages
    }
  })

  return invalidFields
}

export const getCapacityBreakdown = (rows) => {
  const report = []
  const sectionRows = getSectionRows(rows)
  const rooms = getRooms(rows, sectionRows[0].rowIndex)
  // console.log("rooms", rooms);

  const invalidFields = validateRoomData(rooms, rows)

  rooms.forEach((room, index) => {
    const roomReport = getRoomReport(index, room, rows, sectionRows)
    report.push(roomReport)
  })
  return {
    report,
    invalidFields,
  }
}

export const mapPerfReport = (list, row) => {
  const data = {}
  const filteredByRow = PERFORMANCE_REPORT_MAPPING.filter((m) => m.row === row)
  if (filteredByRow.length > 0) {
    filteredByRow.forEach((rec) => {
      if (list[rec.col] !== undefined) {
        // console.log({d: rec.field, l: getResult(list[rec.col])})
        data[rec.field] = getResult(list[rec.col])
      }
    })
  }
  if (data && data.dataCenterName && data.dataCenterName.includes("/")) {
    const splitted = data.dataCenterName.split("/")
    data.dataCenterName = splitted[0]
  }
  // console.log(data)
  return Object.entries(data)
}

export const mapDesignPueAndTargetPueForAllMonths = (list, row) => {
  // console.log(row);
  const months = {}
  const design_pue = {}
  const target_pue = {}
  const filteredByRow = DESIGN_PUE_AND_TARGET_PUE_MAPPING.filter(
    (m) => m.row === row
  )
  if (filteredByRow.length > 0) {
    if (filteredByRow[0].field == "month") {
      filteredByRow[0].col.forEach((rec) => {
        if (list[rec] !== undefined) {
          // console.log({d: rec.field, l: getResult(list[rec.col])})
          months[rec] = getResult(list[rec])
        }
      })
      return months
    }
    if (filteredByRow[0].field == "design_pue") {
      filteredByRow[0].col.forEach((rec) => {
        if (list[rec] !== undefined) {
          // console.log({d: rec.field, l: getResult(list[rec.col])})
          design_pue[rec] = getResult(list[rec])
        }
      })
      return design_pue
    }
    if (filteredByRow[0].field == "target_pue") {
      filteredByRow[0].col.forEach((rec) => {
        if (list[rec] !== undefined) {
          // console.log({d: rec.field, l: getResult(list[rec.col])})
          target_pue[rec] = getResult(list[rec])
        }
      })
      return target_pue
    }
  }
  console.log(months)
  console.log(design_pue)
}
