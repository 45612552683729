import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import BoxSelect from "../shared/BoxSelect";
import { TICKET_MODAL_BACKGROUND_COLOR } from "../shared/constants";
import TicketSelectField from "../shared/TicketSelectField";
import useRemoteHandsTicketForm from "./useRemoteHandsTicketForm";
import * as utils from "../shared/utils";
import { css } from "glamor";
import TicketButton from "../shared/TicketButton";
import TicketSwitchButton from "../shared/TicketSwitchButton";
import Description from "./Description";
import Schedule from "./Schedule";
import TicketInputField from "../shared/TicketInputField";
import SpaceField from "../shared/TicketInputField/spaceField";
import { useState, useEffect } from "react";
import TicketCheckBox from "../shared/TicketCheckBox";

const RemoteHandsTicketForm = ({ closeModal, setLoading }) => {
  const { t } = useTranslation();
  const form = useRemoteHandsTicketForm({ closeModal, setLoading });
  const [isEndCustomer, setEndCustomer] = useState(false);
  const [isShowResellerContacts, setShowResellerContacts] = useState(false);

  const globalOption = {
    label: t("global"),
    value: "global",
  };
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      minHeight: "500px",
    },
    icon: {
      marginLeft: "10px",
    },
  };

  const getColStyle = (index) => {
    return { paddingLeft: index === 0 ? "0px !important" : null };
  };

  const handleRemoteHandsTypeButtonClick = (id, controlId) => {
    const { setFieldValue } = form.formik;
    if (controlId === "regular") {
      setFieldValue(id, "regular");
    } else if (controlId === "scheduled") {
      setFieldValue(id, "scheduled");
    }
  };
  const handelCheckboxToggle = () => {
    setShowResellerContacts(!isShowResellerContacts);
    form.formik.setFieldValue(
      "isShowResellerContacts",
      !isShowResellerContacts
    );
    form.formik.setFieldValue("customer", "");
  };

  useEffect(() => {
    if (form.formik.values.companyName != "") {
      const isEndCustomer = utils.isSelectedCompanyEndCustomer(
        form.formik.values.companyName,
        form.companies
      );
      setEndCustomer(isEndCustomer);
    }
    form.formik.setFieldValue("isShowResellerContacts", false);
  }, [form.formik.values.companyName]);

  const remoteHandsTypeButtons = [
    {
      controlId: "regular",
      label: t(
        "create-ticket.remote-hands.switch-button-regular"
      ).toUpperCase(),
      selected: form.formik.values.remoteHandsType === "regular",
    },
    {
      controlId: "scheduled",
      label: t(
        "create-ticket.remote-hands.switch-button-scheduled"
      ).toUpperCase(),
      selected: form.formik.values.remoteHandsType === "scheduled",
    },
  ];

  return (
    <form onSubmit={form.formik.handleSubmit} className="remote_hand">
      <div {...css(styles.container)}>
        <div>
          <Row>
            <BoxSelect
              id="country"
              setFieldValue={form.formik.setFieldValue}
              value={form.formik.values.country}
              options={[...form.countries]}
              label={t("create-ticket.site-access.country").toUpperCase()}
              placeholder={t(
                "create-ticket.site-access.operating-site-placeholder"
              )}
              active
            />
          </Row>
          <Row>
            <TicketSelectField
              id="companyName"
              label={t("create-ticket.site-access.company-name")}
              setFieldValue={form.formik.setFieldValue}
              required
              active={form.isActive("companyName")}
              options={form.companies}
              backgroundColor={TICKET_MODAL_BACKGROUND_COLOR}
              placeholder={t(
                "create-ticket.site-access.company-name-placeholder"
              )}
              value={form.formik.values.companyName}
              isCompanyLoading={form.isCompanyLoading}
            />
          </Row>
          <Row style={{ marginTop: "10px" }}>
            <BoxSelect
              id="operatingSite"
              setFieldValue={form.formik.setFieldValue}
              value={form.formik.values.operatingSite}
              options={form.operatingSites}
              label={t(
                "create-ticket.site-access.operating-site"
              ).toUpperCase()}
              placeholder={t(
                "create-ticket.site-access.operating-site-placeholder"
              )}
              active={form.isActive("operatingSite")}
              isOperatingSitesLoading={form.isOperatingSitesLoading}
            />
          </Row>
          <Row>
            {remoteHandsTypeButtons.map((button, index) => (
              <Col
                key={button.controlId}
                className="mt-3 pt-1"
                {...css(getColStyle(index))}
              >
                <TicketSwitchButton
                  id="remoteHandsType"
                  controlId={button.controlId}
                  label={button.label}
                  handleClick={handleRemoteHandsTypeButtonClick}
                  active={form.isActive("remoteHandsType")}
                  selected={button.selected}
                />
              </Col>
            ))}
          </Row>

          <Row className="p_relative">
            {isEndCustomer &&
              form.formik.values.companyName &&
              form.isActive("customer") && (
                <div className="show_reseller_contacts">
                  <TicketCheckBox
                    label="Show Reseller Contacts"
                    checked={form.formik.values.isShowResellerContacts}
                    active={true}
                    handelCheckboxToggle={() => handelCheckboxToggle()}
                  />{" "}
                </div>
              )}
            <TicketSelectField
              id="customer"
              label={t("create-ticket.site-access.customer")}
              setFieldValue={form.formik.setFieldValue}
              active={form.isActive("customer")}
              options={form.customers}
              backgroundColor={TICKET_MODAL_BACKGROUND_COLOR}
              placeholder={t("create-ticket.site-access.customer-placeholder")}
              value={form.formik.values.customer}
            />
          </Row>
          <Row className="mb-4">
            <TicketSelectField
              id="ticketType"
              label={t("create-ticket.remote-hands.ticket-type")}
              setFieldValue={form.formik.setFieldValue}
              active={form.isActive("ticketType")}
              options={form.ticketTypes}
              backgroundColor={TICKET_MODAL_BACKGROUND_COLOR}
              placeholder={t(
                "create-ticket.remote-hands.ticket-type-placeholder"
              )}
              value={form.formik.values.ticketType}
              required
            />
          </Row>
          <Row>
            <TicketInputField
              id="requestSubject"
              value={form.formik.requestSubject}
              setFieldValue={form.formik.setFieldValue}
              label={t("create-ticket.remote-hands.request-subject")}
              required
              active={form.isActive("requestSubject")}
              backgroundColor={TICKET_MODAL_BACKGROUND_COLOR}
            />
          </Row>
          <Row className="mb-4">
            <TicketSelectField
              id="serviceId"
              label={t("create-ticket.site-access.service-id")}
              setFieldValue={form.formik.setFieldValue}
              required
              active={form.isActive("serviceId")}
              options={form.services}
              backgroundColor={TICKET_MODAL_BACKGROUND_COLOR}
              placeholder={t(
                "create-ticket.site-access.service-id-placeholder"
              )}
              value={form.formik.values.serviceId}
              isServiceLoading={form.isServiceLoading}
            />
          </Row>
          <Row className="mb-4">
            <SpaceField
              SpaceValue={form.formik.values.serviceId}
              services={form.services}
            />
          </Row>
          <Row>
            <Description
              value={form.formik.values.description}
              setFieldValue={form.formik.setFieldValue}
              active={form.isActive("description")}
            />
          </Row>
          <Row
            style={{
              opacity:
                form.isActive("remoteHandsType") &&
                form.formik.values.remoteHandsType === "regular"
                  ? 0.3
                  : 1,
            }}
          >
            <Schedule
              scheduleStartTime={form.formik.values.scheduleStartTime}
              scheduleEndTime={form.formik.values.scheduleEndTime}
              setFieldValue={form.formik.setFieldValue}
              active={
                form.isActive("scheduleStartTime") &&
                form.formik.values.remoteHandsType === "scheduled"
              }
            />
          </Row>
          {/* <Row>
            <Attention
              id='attention'
              value={form.formik.values.attention}
              setFieldValue={form.formik.setFieldValue}
              active={form.isActive('attention')}
            />
          </Row> */}
          {/* <Row>
            <Disclaimer
              id='disclaimer'
              value={form.formik.values.disclaimer}
              setFieldValue={form.formik.setFieldValue}
              active={form.isActive('disclaimer')}
            />
          </Row>
          <Row>
            <CompanyBan
              id='banId'
              value={form.formik.values.banId}
              setFieldValue={form.formik.setFieldValue}
              companyBans={form.companyBans}
              active={form.isActive('banId')}
            />
          </Row>
          <Row>
            <Reminder
              id='reminder'
              value={form.formik.values.reminder}
              setFieldValue={form.formik.setFieldValue}
              active={form.isActive('reminder')}
            />
          </Row> */}
        </div>
        <div>
          <Row className="mt-4 d-flex justify-content-end gap-3">
            <TicketButton
              label={t("cancel")}
              variant="secondary"
              width="150px"
              active={true}
              closeModal={closeModal}
            />
            <TicketButton
              label={t("create-ticket.label")}
              variant="primary"
              icon={
                <i
                  {...css(styles.icon)}
                  className="fa-regular fa-pen-to-square"
                />
              }
              active={form.isActive("submit")}
              buttonType="submit"
              width="200px"
            />
          </Row>
        </div>
      </div>
    </form>
  );
};

export default RemoteHandsTicketForm;
