import { XError } from "components/common"
import AuthContext from "context"
import React, { useContext, useEffect, useState } from "react"
import CabinetService from "services/CabinetService"
import Common from "services/commonService"
import floorServices from "services/floorServices"
import networkRoomService from "services/networkRoomService"
import Swal from "sweetalert2"
import { LogoutOnSessionExpire } from "common/helpers"
import { useTranslation } from "react-i18next"
import CloseIcon from "@mui/icons-material/Close"
//import "../roomStyle.css";

const AddRoom = ({ closeModal, activeDataCenter, getNetworkRooms }) => {
  const authContext = useContext(AuthContext)
  const [roomType, setRoomType] = useState([])
  const [floor, setFloor] = useState([])
  const [room, setRoom] = useState([])
  const [status, setStatus] = useState([])
  const [cabinet, setCabinet] = useState([])
  const [idfStatus, setIdfStatus] = useState(false)
  const { t } = useTranslation()
  const [state, setState] = useState({
    data_center: activeDataCenter ? activeDataCenter.id : 0,
    name: "",
    description: "",
    ref_name: "",
    floor_id: "",
    room_id: "",
    room_type: "",
    cabinet_id: "",
    status: "",
  })
  const [error, setError] = useState({
    name: "",
    description: "",
    ref_name: "",
    floor_id: "",
    room_id: "",
    room_type: "",
    cabinet_id: "",
    status: "",
  })

  useEffect(() => {
    if (authContext.getStatusData) {
      const statusData = authContext.getStatusData.filter(
        (data) => data.status_type_id === 4
      )
      setStatus(statusData)
      //set first value selected
      if (statusData && statusData.length) {
        setState({
          ...state,
          status: statusData[0].id,
        })
      }
    }

    // Common.status().then((res) => {
    //   const statusData = res.data.data.filter(
    //     (data) => data.status_type_id === 4
    //   )
    //   setStatus(statusData)
    //   //set first value selected
    //   if (statusData && statusData.length) {
    //     setState({
    //       ...state,
    //       status: statusData[0].id,
    //     })
    //   }
    // })
    floorServices
      .floorByDataCenterId(authContext.token(), activeDataCenter)
      .then((res) => setFloor(res.data.data))
    networkRoomService
      .getAllRoomType(authContext.token())
      .then((res) =>
        setRoomType(
          res.data.data.filter((data) => data.type === "network_room")
        )
      )
    //CabinetService.get
  }, [])

  const getRoom = (id) => {
    const data = floor.filter((d) => d.id === Number(id))
    setRoom(data[0].rooms.filter((d) => d.room_type_id === 7))
    setCabinet([])
  }

  const getCabinets = async (id) => {
    if (id !== "") {
      CabinetService.getAllCabinetsRoomID(authContext.token(), id).then((res) =>
        setCabinet(res.data.data)
      )
    } else {
      setCabinet([])
    }
  }

  const submitNetworkRoom = async (data) => {
    // setState({...state,data_center_id: props.data_center_id})

    if (checkValidation()) {
      // setState({...state,data_center_id:props.dataCenterId.id})

      await networkRoomService
        .createRoom(authContext.getToken, { ...state })
        .then(async (res) => {
          getNetworkRooms(activeDataCenter.id)
          closeModal()
          Swal.fire(t("cabling.new_network_room_created"))
          //props.selectDataCenterFloor(props.dataCenterId)
        })
        .catch((err) => {
          LogoutOnSessionExpire(err)
          let error = {
            name: "",
            floor_id: "",
            room_id: "",
            room_type: "",
            cabinet_id: "",
          }
          const errors = err?.response?.data?.errors

          if (
            errors?.name !== undefined ||
            errors?.name !== "" ||
            errors?.name !== null
          ) {
            error.name = errors.name
          }

          if (
            errors?.floor_id !== undefined ||
            errors?.floor_id !== "" ||
            errors?.floor_id !== null
          ) {
            error.floor_id = errors.floor_id
          }

          if (
            errors?.room_type !== undefined ||
            errors?.room_type !== "" ||
            errors?.room_type !== null
          ) {
            error.room_type = errors.room_type
          }

          setError({ ...error })
        })
    }
  }

  const checkValidation = () => {
    let error = {
      name: "",
      floor_id: "",
      room_id: "",
      room_type: "",
      cabinet_id: "",
    }

    const { name, floor_id, room_type } = state

    let flag = true

    if (name === "" || name === null || name === undefined) {
      error.name = "The name field is required."
      flag = false
    }

    if (floor_id === "" || floor_id === null || floor_id === undefined) {
      error.floor_id = "The floor id field is required."
      flag = false
    }

    if (room_type === "" || room_type === null || room_type === undefined) {
      error.power = "The room type field is required."
      flag = false
    }
    setError({ ...error })
    return true
  }

  return (
    <div className="modal show" style={{ display: "block" }}>
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header mt-59">
            <h3 className="modal-title">{t("cabling.add_network_room")}</h3>
            <CloseIcon
              style={{ cursor: "pointer" }}
              onClick={() => closeModal(false)}
              data-bs-dismiss="modal"
            />
          </div>
          <div className="modal-body">
            <div className="card">
              <div
                className="card-body"
                style={{ padding: "0px", overflow: "hidden" }}
              >
                <p>{activeDataCenter?.name || ""}</p>
                <div className="basic-form">
                  <form>
                    <div className="row">
                      <div className="col-md-6 mt-2313">
                        <label className="form-label">
                          {t("cabling.name")}:
                          <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Name"
                          onChange={(event) =>
                            setState({
                              ...state,
                              name: event.target.value,
                            })
                          }
                        />
                        <XError message={error.name} />
                      </div>
                      <div className="col-md-6 col-sm-6 mt-2313">
                        <label className="form-label">
                          {t("cabling.room_type")}:
                          <span className="text-danger">*</span>
                        </label>
                        <select
                          className="default-select form-control wide"
                          onChange={(event) => {
                            setState({
                              ...state,
                              room_type: event.target.value,
                            })
                            if (event.target.value === "8") {
                              setIdfStatus(true)
                            } else {
                              setIdfStatus(false)
                            }
                          }}
                        >
                          <option value="">{t("cabling.choose")}...</option>
                          {roomType &&
                            roomType.map((room) => {
                              return (
                                <option value={room.id} key={room.id}>
                                  {room.name}
                                </option>
                              )
                            })}
                        </select>
                        <XError message={error.room_type} />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 col-sm-12 mt-2313">
                        <label className="form-label">
                          {t("cabling.floor")}:{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <select
                          className="default-select form-control wide"
                          onChange={(event) => {
                            getRoom(event.target.value)
                            setState({
                              ...state,
                              floor_id: event.target.value,
                            })
                          }}
                        >
                          <option value="">{t("cabling.choose")} ...</option>
                          {floor &&
                            floor.map((floor) => {
                              return (
                                <option value={floor.id} key={floor.id}>
                                  {floor.name}
                                </option>
                              )
                            })}
                        </select>
                        <XError message={error.floor_id} />
                      </div>
                    </div>
                    {idfStatus && (
                      <div className="row">
                        <div className="col-md-6 col-sm-6 mt-2313">
                          <label className="form-label">
                            {" "}
                            {t("cabling.room")}:
                          </label>
                          <select
                            className="default-select form-control wide"
                            onChange={(event) => {
                              setState({
                                ...state,
                                room_id: event.target.value,
                              })
                              getCabinets(event.target.value)
                            }}
                          >
                            <option value="">{t("cabling.choose")} ...</option>
                            {room &&
                              room.map((hall) => {
                                return (
                                  <option value={hall.id} key={hall.id}>
                                    {hall.name}
                                  </option>
                                )
                              })}
                          </select>
                          <span style={{ fontSize: "9px" }}>
                            {t("cabling.for_rooms_inside_a_room")}
                          </span>
                        </div>
                        <div className="col-md-6 col-sm-6 mt-2313">
                          <label className="form-label">
                            {" "}
                            {t("cabling.cabinet_rack")}:
                          </label>
                          <select
                            className="default-select form-control wide"
                            onChange={(event) =>
                              setState({
                                ...state,
                                cabinet_id: event.target.value,
                              })
                            }
                          >
                            <option value="">{t("cabling.choose")} ...</option>
                            {cabinet &&
                              cabinet.map((cab) => {
                                return (
                                  <option value={cab.id} key={cab.id}>
                                    {cab.name}
                                  </option>
                                )
                              })}
                          </select>
                          <span style={{ fontSize: "9px" }}>
                            {t("cabling.for_idf_racks")}
                          </span>
                        </div>
                      </div>
                    )}
                    <div className="row">
                      <div className="col-md-6 col-sm-6 mt-2313">
                        <label className="form-label">
                          {" "}
                          {t("cabling.status")}:
                          <span className="text-danger">*</span>
                        </label>
                        <select
                          className="default-select form-control wide"
                          onChange={(event) =>
                            setState({
                              ...state,
                              status: event.target.value,
                            })
                          }
                        >
                          {status &&
                            status.map((status, i) => {
                              if (status.status_type_id === 4) {
                                return (
                                  <option value={status.id} key={status.id}>
                                    {status.status_name}
                                  </option>
                                )
                              }
                            })}
                        </select>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12 col-sm-12 mt-2313">
                        <label className="form-label">
                          {" "}
                          {t("cabling.remarks")}:
                        </label>
                        <textarea
                          className="form-control"
                          style={{ height: "70px" }}
                          onChange={(event) =>
                            setState({
                              ...state,
                              description: event.target.value,
                            })
                          }
                        ></textarea>
                      </div>
                    </div>
                    <div
                      className="toolbar toolbar-bottom mt-51 d-flex justify-content-end"
                      role="toolbar"
                    >
                      <button
                        type="button"
                        onClick={() => closeModal(false)}
                        className="btn btn-outline-primary mr_1"
                      >
                        {" "}
                        {t("common.cancel")}{" "}
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => submitNetworkRoom()}
                      >
                        {t("common.save")}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddRoom
