import { isEmpty } from 'lodash';
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

const FilterOperatorBox = ({
  operators,
  HandleDropdown,
  selectedSearchCategoryData,
  searchValue
}) => {
  const { t } = useTranslation();

  const [operator, setOperator] = useState(operators);
  useEffect(() => {
    if (searchValue.length > 0) {
      setOperator(operators);
    } else {
      const operator = operators.filter((el) => el.value == 'is_set' || el.value == 'is_not_set');
      setOperator(operator);
    }
  }, [searchValue]);
  // useEffect(() => {
  //   if (searchValue.length > 0) {
  //     setOperator(operators);
  //   } else {
  //     const operator = operators.filter((el) => el.value == 'is_set' || el.value == 'is_not_set');
  //     setOperator(operator);
  //   }
  // }, []);
  return (
    <React.Fragment>
      <div className="tc_drop2-out d-flex align-items-center operator_box">
        <div style={{ width: '100%', cursor: 'pointer' }}>
          <strong>
            <span>
              <div>
                <div
                  style={{
                    overflow: 'auto'
                  }}
                >
                  {operator &&
                    operator.map((data, i) => {
                      return (
                        <div>
                          <div className="group_by_list d-flex justify-content-between align-items-center">
                            <span
                              style={{
                                textOverflow: 'ellipsis',
                                maxWidth: '12rem',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                marginRight: '0.5rem',
                                width: '100%'
                              }}
                              onClick={() => HandleDropdown(selectedSearchCategoryData, data)}
                            >
                              {' '}
                              {data.name}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </span>
          </strong>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FilterOperatorBox;
