import {
	axiosWithoutToken as axios
} from 'utils/axios';
import { LogoutOnSessionExpire } from 'common/helpers';


class Common {

	async permission(){
		
		return await axios().get(`Permissions`);
	}

	async status(){
		
		return await axios().get(`status`);
	}

	async language(){
		
		return await axios().get(`languages`);
	}

}

export default new Common();
