import { numberFormat, LogoutOnSessionExpire } from "common/helpers";
import { myBase64Image } from "components/common/getImage";
import AuthContext from "context";
import saveAs from "file-saver";
import moment from "moment";
import React, { useContext, useEffect, useRef, useState } from "react";
import CapacityService from "services/capacityService";
import DataCenterPerformance from "services/DataCenterPerformance";
import Floors from "services/floorServices";
import RoleModel from "services/roleServices";
import Swal from "sweetalert2";
import Layout from "../../Layouts";
import Navigation from "../Component/Navigation";
import "./capacityStyle.css";
import DataCenterNav from "./Navigation";
import { Link } from "react-router-dom";
import Loading from "views/super-admin/pre-loader/loader";
import { useTranslation } from "react-i18next";
import ExcelUploadModal from "./Capacity/ExcelUploadModal";
import CloseIcon from "@mui/icons-material/Close";

const ExcelJS = require("exceljs");

const Capacity = (props) => {
  const authContext = useContext(AuthContext);
  const [invalidEntries, setInvalidEntries] = useState([]);
  const [isValid, setIsValid] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [dataCenter, setDataCenter] = useState([]);
  const [currentDataCenter, setCurrentDataCenter] = useState([]);
  const [countryName, setCountryName] = useState("Country");
  const initialMount = useRef(true);
  const [capcityData, setCapacityData] = useState([]);
  const [month, setMonth] = useState("");
  const [year, setYear] = useState(Number(authContext.getMonthYear.year));
  const modalRef = useRef(null);
  const newBtn = useRef(null);
  const [isSubmitEnabled, setSubmitEnabled] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [isDataChanged, setDataChanged] = useState(false);
  const [triggerRefresh, setTriggerRefresh] = useState(false);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  const [invalidFields, setInvalidFields] = useState({});
  const [performanceState, setPerformanceState] = useState({
    data_center_id: "",
    month: "",
    year: "",
    availability: "",
    opertating_pue: "",
    design_pue: "",
    installed_kw: "",
    operating_kw: "",
    infra_incident_num: "",
    infra_incident_type: "",
    security_incident_num: "",
    security_incident_type: "",
    ehs_incident_num: "",
    ehs_incident_type: "",
  });

  const [infraImpact, setInfraImpact] = useState([
    {
      impact: "-",
    },
  ]);
  const [securityImpact, setSecurityImpact] = useState([
    {
      impact: "-",
    },
  ]);
  const [ehsImpact, setEhsImpact] = useState([
    {
      impact: "-",
    },
  ]);

  const [showExcelUploadModal, setShowExcelUploadModal] = useState(false);
  const [excelReportData, setExcelReportData] = useState({});

  const totalObj = {
    totalCabe: 0,
    totalCage: 0,
    totalPower: 0,
    installCabe: 0,
    installCage: 0,
    installPower: 0,
    reservedCabe: 0,
    reservedCage: 0,
    reservedPower: 0,
    blockedCabe: 0,
    blockedCage: 0,
    blockedPower: 0,
    availableCabe: 0,
    availableCage: 0,
    availablePower: 0,
    deltaCabe: 0,
    deltaCage: 0,
    deltaPower: 0,
  };

  const [countries, setCountries] = useState([]);

  useEffect(() => {
    if (initialMount.current === true) {
      getData();

      setMonth(Number(authContext.getMonthYear.month) - 1);

      setYear(year);

      initialMount.current = false;

      if (
        authContext?.getAuth?.role?.space === 3 ||
        authContext?.getAuth?.role?.m_e === 3 ||
        authContext?.getAuth?.role?.network === 3
      ) {
        setIsReadOnly(false);
      }
      getAllDataCenter();
    } else {
      setDataChanged(false);
      // clearData();
    }
  }, [isDataChanged, authContext.getNetworkDataCenter, triggerRefresh]);

  const clearData = () => {
    setPerformanceState({
      availability: "",
      opertating_pue: "",
      design_pue: "",
      installed_kw: "",
      operating_kw: "",
      infra_incident_num: "",
      infra_incident_type: "",
      security_incident_num: "",
      security_incident_type: "",
      ehs_incident_num: "",
      ehs_incident_type: "",
    });
  };

  const getData = async () => {
    await RoleModel.countryService(authContext.token()).then((res) => {
      setCountries(res.data.data);
      setIsLoading(false);
    });
  };

  const onChangeData = (event, data, totalType, slug, floorName, roomName) => {
    let newValue = event.target.value;

    // Ensure the input contains only numbers
    if (isNaN(newValue) || newValue.includes(" ")) {
      return;
    }

    const newTotalType =
      totalType - (newValue - data.monthly_utilization[slug]);
    const fieldKey = `${slug}-${floorName}-${roomName}`;

    let isValid = true;

    capcityData.map((capacity) => {
      if (capacity.id === data.id) {
        setSubmitEnabled(true);
        setDataChanged(true);
        capacity.monthly_utilization[slug] = newValue;
        capacity.newTotal = newTotalType;

        const updatedFloorTotal = calculateFloorTotal(floorName); // deltaE of slug for the floor

        // invalid fields when total becomes non-negative
        if (updatedFloorTotal < 0) {
          // Add field to invalid fields
          setInvalidFields((prevInvalidFields) => ({
            ...prevInvalidFields,
            [fieldKey]: true,
          }));
          isValid = false;
        } else {
          // If total is non-negative, remove all fields related to this room's deltaE
          setInvalidFields((prevInvalidFields) =>
            Object.keys(prevInvalidFields).reduce((acc, key) => {
              // Remove fields which meet the valid conditions
              if (!key.includes(`${slug}-${floorName}-${roomName}`)) {
                acc[key] = prevInvalidFields[key];
              }
              return acc;
            }, {})
          );
        }

        // Handle individual fields when their value is "0.00"
        if (newValue === "0.00") {
          setInvalidFields((prevInvalidFields) => {
            const {
              [fieldKey]: removedField,
              ...remainingFields
            } = prevInvalidFields;

            // Only remove the field if the condition is met
            if (newValue === "0.00") {
              return remainingFields;
            }

            // If the field doesn't meet the condition, return the original invalidFields
            return prevInvalidFields;
          });
        }

        // Check if there are any remaining invalid fields
        if (!isValid) {
          setIsValid(false);
        } else {
          setIsValid(true);
        }
      }
    });
  };

  console.log(invalidFields);

  const calculateFloorTotal = (floorName) => {
    const total = capcityData
      .filter((capacity) => capacity.floor.name === floorName)
      .reduce((acc, capacity) => acc + (capacity.newTotal || 0), 0);
    return total;
  };

  const getPeformanceData = async (payload) => {
    await DataCenterPerformance.index(authContext.token(), payload).then(
      (data) => {
        if (data.data.data != null) {
          setPerformanceState(data.data.data);
        } else {
          clearData();
        }
        if (
          data.data.data?.infra_incidents &&
          data.data.data.infra_incidents.length
        ) {
          setInfraImpact(data.data.data.infra_incidents);
        } else {
          setInfraImpact([{ impact: "-" }]);
        }
        if (
          data.data.data?.security_incidents &&
          data.data.data.security_incidents.length
        ) {
          setSecurityImpact(data.data.data.security_incidents);
        } else {
          setSecurityImpact([{ impact: "-" }]);
        }
        if (
          data.data.data?.ehs_incidents &&
          data.data.data.ehs_incidents.length
        ) {
          setEhsImpact(data.data.data.ehs_incidents);
        } else {
          setEhsImpact([{ impact: "-" }]);
        }
      }
    );
  };
  const onSubmit = async () => {
    if (performanceState !== undefined || performanceState !== null) {
      delete performanceState.infra_incidents;
      delete performanceState.security_incidents;
      delete performanceState.ehs_incidents;
    }

    const performanceData = {
      ...performanceState,
      data_center_id: currentDataCenter.id,
      month: month || authContext.getMonthYear.month - 1,
      year: year || year,
      infra: infraImpact,
      security: securityImpact,
      ehs: ehsImpact,
    };

    const data = [];

    capcityData &&
      capcityData.map((util) => {
        data.push(util.monthly_utilization);
        return util;
      });

    CapacityService.store(authContext.token(), { data })
      .then((res) => {
        DataCenterPerformance.updateOrCreate(
          authContext.token(),
          performanceData
        )
          .then(() => {
            selectDataCenterFloor(currentDataCenter);
            findFloor();
            Swal.fire({
              icon: "success",
              title: t("capacity.success"),
              text: t("capacity.data_updated"),
            });
          })
          .catch((err) => {
            LogoutOnSessionExpire(err);
            Swal.fire({
              icon: "error",
              title: t("capacity.oops"),
              text: t("capacity.something_went_wrong"),
            });
          });
      })
      .catch((err) => {
        LogoutOnSessionExpire(err);
        Swal.fire({
          icon: "error",
          title: t("capacity.oops"),
          text: t("capacity.something_went_wrong"),
        });
      });

    onClose();
  };
  const findFloor = () => {
    Floors.findAllFloor(authContext.token())
      .then((res) => {
        authContext.setDataCenterFloor(res.data.data);
      })
      .catch((err) => {
        LogoutOnSessionExpire(err);
        // 500 internal server error
      });
  };
  const onClose = () => {
    getCapacity(authContext.getNetworkDataCenter);
    modalRef.current.click();
  };

  const onExcelUpload = () => {
    setShowExcelUploadModal(true);
  };

  const getAllDataCenter = async () => {
    setCountryName("Country");
    await RoleModel.dataCenter(authContext.token()).then(async (res) => {
      const newDataCenter = res.data.data.filter(
        (data) => data.dc_type_id === 1
      );

      setDataCenter(newDataCenter);
      // alert(authContext.getNetworkDataCenter.id)
      if (
        authContext.getNetworkDataCenter === undefined ||
        authContext.getNetworkDataCenter.length === 0
      ) {
        authContext.setNetworkDataCenter(newDataCenter[0]);
        selectDataCenterFloor(newDataCenter[0]);
        // getCapacity(newDataCenter[0]);
      }
      if (res.data.data.length > 0) {
        if (initialMount.current) {
          selectDataCenterFloor(authContext.getNetworkDataCenter);
          initialMount.current = false;
        }
      }
      if (newDataCenter.length) {
        await getCapacity(
          authContext.getNetworkDataCenter.id === undefined
            ? newDataCenter[0]
            : { id: authContext.getNetworkDataCenter.id }
        );
        setCurrentDataCenter(
          authContext.getNetworkDataCenter.id === undefined
            ? newDataCenter[0]
            : { id: authContext.getNetworkDataCenter.id }
        );
      }
    });
  };

  const getDataCenterById = async (e) => {
    setIsLoading(true);
    setCountryName(e.name);

    await RoleModel.dataCenterByCountryId(authContext.token(), e).then(
      (res) => {
        setDataCenter(res.data.data);
        setIsLoading(false);
        if (res.data.data.length > 0) {
          selectDataCenterFloor(res.data.data[0]);
        }
      }
    );
  };
  const getCapacity = async (dataCenter) => {
    setIsLoading(true);
    const data = {
      dataCenterId: dataCenter.id,
      month: month || authContext.getMonthYear.month - 1,
      year: year || year,
    };

    getPeformanceData(data);

    await CapacityService.index(authContext.token(), data)
      .then(async (res) => {
        await setCapacityData(res.data.data);
      })
      .catch((err) => {
        LogoutOnSessionExpire(err);
      })
      .finally(() => setIsLoading(false));
  };

  const selectDataCenterFloor = async (e) => {
    // console.log(e);
    authContext.setNetworkDataCenter(e);

    getCapacity(e);
    setCurrentDataCenter(e);
  };
  const onFilter = async () => {
    setInvalidFields({});
    const data = {
      dataCenterId: authContext.getNetworkDataCenter.id,
      month,
      year,
    };
    // console.log(data);
    getPeformanceData(data);
    const selectedDate = new Date(year + "/" + month + "/01");
    const currentDate = new Date(
      authContext.getMonthYear.year +
        "/" +
        Number(authContext.getMonthYear.month) +
        "/01"
    );
    // console.log(selectedDate, currentDate);
    if (moment(selectedDate).isAfter(currentDate)) {
      Swal.fire(t("capacity.future_date"));
      setCapacityData([]);
    } else {
      await CapacityService.index(authContext.token(), data).then(
        async (res) => {
          if (
            Number(authContext.getMonthYear.month) - 1 === Number(month) &&
            year === year
          ) {
            setIsReadOnly(false);
          } else {
            setIsReadOnly(true);
          }

          await setCapacityData(res.data.data);
        }
      );
    }
  };

  useEffect(() => {
    if (
      excelReportData &&
      excelReportData.dataCenterId &&
      excelReportData.dataCenterId === currentDataCenter.id &&
      excelReportData.year === parseInt(year, 10) &&
      excelReportData.month === parseInt(month, 10)
    ) {
      onFilter();
    }
  }, [excelReportData]);

  const renderCountry = () => {
    return (
      countries.length &&
      countries.map((data, i) => {
        return (
          <a
            href="#sae"
            className="dropdown-item"
            key={i}
            onClick={() => {
              getDataCenterById(data);
            }}
          >
            {data.name}{" "}
          </a>
        );
      })
    );
  };

  // const renderDataCenter = () => {

  //    return dataCenter && dataCenter.map((data, i) => {

  //        return (
  //            <li
  //            className="nav-item"
  //            key={i}
  //            onClick={() => {
  //                setCurrentTab(data.id)
  //                selectDataCenterFloor(data)
  //            }}>
  //                <a
  //                href="#"
  //                className={`nav-link ${currentTab === 0 && i === 0 ?"active show":""}`}
  //                >

  //                    {data.name}
  //                </a>
  //            </li>
  //        )
  //    })
  // }
  // const renderCountry = () => {

  //    return countries.length && countries.map((data, i) => {

  //        return <a className="dropdown-item"
  //            key={i}
  //            onClick={() => {

  //                getDataCenterById(data)
  //            }}>{data.name} </a>
  //    })
  // }
  const isFutureMonth = (key) => {
    if (year == moment().year()) {
      if (key <= moment().month()) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const renderMonth = () => {
    const months = [];
    for (let i = 1; i <= 12; i++) {
      months.push(moment(i, "M").format("MMM"));
    }
    return months.map((m, key) => (
      <option
        value={key + 1}
        key={key}
        disabled={isFutureMonth(key)}
        className={isFutureMonth(key) ? "disabled_months" : ""}
      >
        {m}
      </option>
    ));
  };

  const renderYear = () => {
    const years = [];
    for (let i = 2022; i <= moment().format("YYYY"); i++) {
      years.push(moment(i, "YYYY").format("YYYY"));
    }
    return years.map((y, key) => (
      <option value={y} key={y}>
        {y}
      </option>
    ));
  };
  const extractValue = (data) => {
    if (data === undefined) {
      return "";
    } else {
      if (data === 0) {
        return "0.00";
      } else {
        return data;
      }
    }
  };
  const extractValue2 = (data) => {
    if (data === undefined) {
      return "-";
    } else {
      if (data === 0) {
        return 0;
      } else {
        return data;
      }
    }
  };
  const extVal = (util, hall) => {
    if (isReadOnly === true) {
      return extractValue(util);
    } else {
      return extractValue(hall);
    }
  };
  const addInfraInputField = () => {
    setInfraImpact([
      ...infraImpact,
      {
        impact: "",
      },
    ]);
  };

  const removeInfraInputFields = (index) => {
    const rows = [...infraImpact];
    rows.splice(index, 1);
    setInfraImpact(rows);
  };

  const handleInfraChange = (index, evnt) => {
    setSubmitEnabled(true);
    const { name, value } = evnt.target;
    const list = [...infraImpact];
    list[index][name] = value;
    setInfraImpact(list);
  };

  const addSecurityInputField = () => {
    setSecurityImpact([
      ...securityImpact,
      {
        impact: "",
      },
    ]);
  };

  const removeSecurityInputFields = (index) => {
    const rows = [...securityImpact];
    rows.splice(index, 1);
    setSecurityImpact(rows);
  };

  const handleSecurityChange = (index, evnt) => {
    setSubmitEnabled(true);
    const { name, value } = evnt.target;
    const list = [...securityImpact];
    list[index][name] = value;
    setSecurityImpact(list);
  };

  const addEHSInputField = () => {
    setEhsImpact([
      ...ehsImpact,
      {
        impact: "",
      },
    ]);
  };

  const removeEHSInputFields = (index) => {
    const rows = [...ehsImpact];
    rows.splice(index, 1);
    setEhsImpact(rows);
  };

  const handleEHSChange = (index, evnt) => {
    setSubmitEnabled(true);
    const { name, value } = evnt.target;
    const list = [...ehsImpact];
    list[index][name] = value;
    setEhsImpact(list);
  };

  // checking if inputs are correct and if yes then open modal and if no then show error
  const handleSaveClick = (e) => {
    e.preventDefault();
    if (isValid) {
      setShowModal(true);
    } else {
      Swal.fire({
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#FE8600",
        confirmButtonText: "Proceed with data",
        cancelButtonColor: "#FF0000",
        text:
          "Some entries in the data may cause issues, please review and update them as needed",
      }).then((result) => {
        if (result.isConfirmed) {
          onSubmit();
        }
      });
    }
  };

  // closing the modal
  const handleClose = () => {
    setShowModal(false);
    onClose();
  };

  const downloadExcel = () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("My Sheet");

    const row = 6;
    worksheet.mergeCells("A" + row + ":B" + row);
    const customCell1 = worksheet.getCell("A" + row);
    customCell1.value = currentDataCenter.name;

    worksheet.mergeCells("C" + row + ":E" + row);
    const customCell2 = worksheet.getCell("C" + row);
    customCell2.value = "Total";

    worksheet.mergeCells("F" + row + ":H" + row);
    const customCell3 = worksheet.getCell("F" + row);
    customCell3.value = "Installed/Sold";

    worksheet.mergeCells("I" + row + ":K" + row);
    const customCell4 = worksheet.getCell("I" + row);
    customCell4.value = "Reserved";

    worksheet.mergeCells("L" + row + ":N" + row);
    const customCell5 = worksheet.getCell("L" + row);
    customCell5.value = "Stranded/Hold";

    worksheet.mergeCells("O" + row + ":Q" + row);
    const customCell6 = worksheet.getCell("O" + row);
    customCell6.value = "Available";

    worksheet.mergeCells("R" + row + ":T" + row);
    const customCell7 = worksheet.getCell("R" + row);
    customCell7.value = "Delta";

    worksheet.addRow([
      "Floor",
      "Rooms",
      "CabE",
      "Cages",
      "(kW)",
      "CabE",
      "Cages",
      "(kW)",
      "CabE",
      "Cages",
      "(kW)",
      "CabE",
      "Cages",
      "(kW)",
      "CabE",
      "Cages",
      "(kW)",
      "CabE",
      "Cages",
      "(kW)",
    ]);
    worksheet.getRow(row).font = { bold: true };
    worksheet.getRow(row + 1).font = { bold: true };
    capcityData.map((capacity) => {
      let totalCabs = 0;
      let totalCages = 0;
      let totalpower = 0;

      if (capacity.monthly_utilization !== null) {
        let utilCab = 0;
        let utilCage = 0;
        let utilPower = 0;
        const c_m = capacity.monthly_utilization;

        utilCab =
          Number(c_m?.sold_cabs || 0) +
          Number(c_m?.reserved_cabs || 0) +
          Number(c_m?.blocked_cabs || 0) +
          Number(c_m?.available_cabs || 0);

        utilCage =
          Number(c_m?.sold_cages || 0) +
          Number(c_m?.reserved_cages || 0) +
          Number(c_m?.blocked_cages || 0) +
          Number(c_m?.available_cages || 0);

        utilPower =
          Number(c_m?.sold_power || 0) +
          Number(c_m?.reserved_power || 0) +
          Number(c_m?.blocked_power || 0) +
          Number(c_m?.available_power || 0);

        if (isReadOnly) {
          totalCabs = Number(c_m.total_cabs) - Number(utilCab);
          totalCages = Number(c_m.total_cages) - Number(utilCage);
          totalpower = Number(c_m.total_power) - Number(utilPower);
        } else {
          if (c_m.total_cabs === undefined) {
            c_m.total_cabs = capacity.monthly_utilization.total_cabs;
          }
          totalCabs =
            Number(capacity.monthly_utilization.total_cabs) - Number(utilCab);
          totalCages =
            Number(capacity.monthly_utilization.total_cages) - Number(utilCage);
          totalpower =
            Number(capacity.monthly_utilization.total_power) -
            Number(utilPower);
        }
      } else {
        capacity.monthly_utilization = {
          month: month || Number(authContext.getMonthYear.month) - 1,
          year: year,
          room_id: capacity.id,
          data_center_id: currentDataCenter.id,
          country_id: currentDataCenter.country_id,
        };
        totalCabs = isReadOnly
          ? 0
          : Number(capacity.monthly_utilization.total_cabs);
        totalCages = isReadOnly
          ? 0
          : Number(capacity.monthly_utilization.total_cages);
        totalpower = isReadOnly
          ? 0
          : Number(capacity.monthly_utilization.total_power);
      }

      const mu = capacity.monthly_utilization;

      worksheet.addRow([
        capacity.floor.name,
        capacity.name,
        extVal(mu.total_cabs, capacity.monthly_utilization.total_cabs),
        extVal(mu.total_cages, capacity.monthly_utilization.total_cages),
        extVal(mu.total_power, capacity.monthly_utilization.total_power),
        extractValue(mu.sold_cabs),
        extractValue(mu.sold_cages),
        extractValue(mu.sold_power),
        extractValue(mu.reserved_cabs),
        extractValue(mu.reserved_cages),
        extractValue(mu.reserved_power),
        extractValue(mu.blocked_cabs),
        extractValue(mu.blocked_cages),
        extractValue(mu.blocked_power),
        extractValue(mu.available_cabs),
        extractValue(mu.available_cages),
        extractValue(mu.available_power),
        numberFormat(totalCabs),
        numberFormat(totalCages),
        numberFormat(totalpower),
      ]);
    });
    const imageId2 = workbook.addImage({
      base64: myBase64Image,
      extension: "png",
    });

    const getDataCenterObj = dataCenter.filter(
      (d) => d.id === currentDataCenter.id
    );
    let fileName = "monthly-utilization";
    if (getDataCenterObj && getDataCenterObj.length) {
      fileName = getDataCenterObj[0].name;
    }

    worksheet.addImage(imageId2, {
      tl: { col: 7, row: 0 },
      ext: { width: 300, height: 100 },
    });
    workbook.xlsx.writeBuffer().then(function (buffer) {
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        `${fileName}-${year}_${month}.xlsx`
      );
    });
  };

  return (
    <>
      <Loading
        loading={isLoading}
        background="rgba(0, 0, 0, 0.40)"
        loaderColor="rgb(248, 153, 3)"
      />
      <Layout
        setTriggerRefresh={setTriggerRefresh}
        triggerRefresh={triggerRefresh}
      >
        <div className="content-body">
          <Navigation />
          <hr className="main" />
          <div className="container-fluid pt-0">
            <div className="row">
              <DataCenterNav />
              <div className="col-lg-11">
                <div id="pro">
                  <div className="profile-tab">
                    <div className="custom-tab-1">
                      <div className="main_scrll">
                        <div className="btn_ul">
                          <ul className="nav nav-tabs mb-3">
                            <li className="nav-item">
                              {" "}
                              <button
                                className="btn btn-secondary"
                                style={{ width: "100%" }}
                                onClick={getAllDataCenter}
                              >
                                {" "}
                                {t("capacity.global")}{" "}
                              </button>
                            </li>
                            <li className="nav-item">
                              <div className="btn-group" role="group">
                                <button
                                  type="button"
                                  className="btn btn-light dropdown-toggle"
                                  style={{ width: "100%" }}
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  {" "}
                                  {countryName}{" "}
                                </button>
                                <div
                                  className="dropdown-menu"
                                  style={{ margin: "0px" }}
                                >
                                  {renderCountry()}
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="outer-div-setting">
                          <div className="ul_scrll">
                            <ul className="nav nav-tabs custom-scroll-gap">
                              {dataCenter &&
                                dataCenter.map((data, index) => {
                                  if (
                                    authContext.getNetworkDataCenter &&
                                    authContext.getNetworkDataCenter.id ===
                                      data.id
                                  ) {
                                    return (
                                      <li
                                        className={
                                          index === 0 ? "nav-item" : "nav-item"
                                        }
                                        key={index}
                                      >
                                        <a
                                          href="#sd"
                                          onClick={() =>
                                            selectDataCenterFloor(data)
                                          }
                                          style={{ cursor: "pointer" }}
                                          className="nav-link active show"
                                        >
                                          <img
                                            className="down setting_down"
                                            alt="arrow"
                                            src="\images\downward-arrow.png"
                                          />
                                          {data.name}
                                        </a>
                                      </li>
                                    );
                                  } else {
                                    return (
                                      <li
                                        className={
                                          index === 0 ? "nav-item" : "nav-item"
                                        }
                                        key={index}
                                      >
                                        <a
                                          href="#sd"
                                          onClick={() =>
                                            selectDataCenterFloor(data)
                                          }
                                          style={{ cursor: "pointer" }}
                                          className="nav-link"
                                        >
                                          {" "}
                                          {data.name}{" "}
                                        </a>
                                      </li>
                                    );
                                  }
                                })}
                            </ul>
                          </div>
                          <div>
                            <span>
                              <i>
                                {t(
                                  "capacity.select_a_country_to_view_partner_data_center"
                                )}
                              </i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Capacity Block */}
                  <div className="row">
                    <div>
                      <div className="row gy-4 align-items-center">
                        <div className="col-12 col-sm-12 col-md-6 col-lg-9">
                          <div className="card-header" id="header">
                            <div className="d-sm-flex d-block justify-content-between align-items-center">
                              <div className="card-action coin-tabs mt-3 mt-sm-0">
                                {/* <CapacityNavigation /> */}
                                <ul className="nav nav-tabs" role="tablist">
                                  <li className="nav-item gap_s">
                                    <a
                                      href="#sd"
                                      className="nav-link active"
                                      id="tab1"
                                      data-bs-toggle="tab"
                                    >
                                      {t("capacity.monthly_utilisation")}
                                    </a>
                                  </li>
                                  <li className="nav-item gap_s">
                                    <a
                                      href="#sd"
                                      className="nav-link"
                                      id="tab2"
                                      data-bs-toggle="tab"
                                    >
                                      {" "}
                                      {t("capacity.thresholds")}
                                    </a>
                                  </li>
                                  <li className="nav-item gap_s">
                                    <Link
                                      className="nav-link"
                                      id="tab3"
                                      to="/capacity-layout"
                                    >
                                      {t("capacity.layouts")}
                                    </Link>
                                    {/* <a
                                                                        className="nav-link"
                                                                        id='tab3'
                                                                        data-bs-toggle="tab"
                                                                    >Layouts</a> */}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-12 col-sm-12 col-md-6 col-lg-3">
                          <div className="left_box_month">
                            <div className="choose_date">
                              <select
                                className="form-select w-3rem"
                                aria-label="Default select example"
                                onChange={(event) => {
                                  setMonth(event.target.value);
                                }}
                                value={month}
                              >
                                {renderMonth()}
                              </select>
                            </div>
                            <div className="choose_date">
                              <select
                                className="form-select w-3rem"
                                aria-label="Default select example"
                                onChange={(event) => {
                                  if (event.target.value == moment().year()) {
                                    setMonth(
                                      authContext.getMonthYear.month - 1
                                    );
                                  }
                                  setYear(event.target.value);
                                }}
                                value={year}
                              >
                                {renderYear()}
                              </select>
                            </div>
                            <div className="choose_date">
                              <button
                                className="btn btn-secondary"
                                onClick={onFilter}
                              >
                                {t("capacity.go")}
                              </button>
                            </div>
                            <div className="excel_icon cap_icon">
                              <img
                                alt="excel"
                                src="\images\excel.png"
                                width="40px"
                                onClick={downloadExcel}
                              />
                            </div>
                          </div>
                        </div>
                        <div
                          className="table_monthly mt-4"
                          style={{ overflowX: "auto" }}
                        >
                          <div className="flex_table">
                            <table
                              className="table table-borderless tb_dcp mb-4"
                              style={{ width: "350px", whiteSpace: "nowrap" }}
                            >
                              <thead>
                                <tr>
                                  <th
                                    colSpan="7"
                                    className="text-start"
                                    style={{
                                      fontWeight: "600 !important",
                                      fontSize: "1.2rem",
                                      border: "none",
                                    }}
                                  >
                                    {t("capacity.data_center_performance")}
                                  </th>
                                </tr>

                                <tr>
                                  <td
                                    className="text-start"
                                    style={{ fontWeight: 600 }}
                                  >
                                    {t("capacity.service_availability")}:
                                  </td>
                                  <td className="text-start">
                                    <input
                                      type="text"
                                      value={extractValue2(
                                        performanceState?.availability
                                      )}
                                      onChange={(event) => {
                                        setSubmitEnabled(true);
                                        setPerformanceState({
                                          ...performanceState,
                                          availability: event.target.value,
                                        });
                                      }}
                                      style={{
                                        width: "75px",
                                        color: "#58a1d6",
                                      }}
                                    />
                                  </td>
                                  <td className="text-start" />
                                </tr>
                                <tr>
                                  <td className="text-start">
                                    {t("capacity.operating_pue")}
                                  </td>
                                  <td className="text-start">
                                    <input
                                      type="text"
                                      value={extractValue2(
                                        performanceState?.opertating_pue
                                      )}
                                      onChange={(event) => {
                                        setSubmitEnabled(true);
                                        setPerformanceState({
                                          ...performanceState,
                                          opertating_pue: event.target.value,
                                        });
                                      }}
                                      style={{ width: "75px" }}
                                    />
                                  </td>
                                  <td className="text-start" />
                                </tr>
                                <tr>
                                  <td className="text-start">
                                    {t("capacity.design_pue")}
                                  </td>
                                  <td className="text-start">
                                    <input
                                      type="text"
                                      value={extractValue2(
                                        performanceState?.design_pue
                                      )}
                                      onChange={(event) => {
                                        setSubmitEnabled(true);
                                        setPerformanceState({
                                          ...performanceState,
                                          design_pue: event.target.value,
                                        });
                                      }}
                                      style={{ width: "75px" }}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-start">
                                    {t("capacity.target_pue")}
                                  </td>
                                  <td className="text-start">
                                    <input
                                      type="text"
                                      value={extractValue2(
                                        performanceState?.target_pue
                                      )}
                                      onChange={(event) => {
                                        setSubmitEnabled(true);
                                        setPerformanceState({
                                          ...performanceState,
                                          target_pue: event.target.value,
                                        });
                                      }}
                                      style={{ width: "75px" }}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-start">
                                    {t("capacity.installed_it_capacity")}
                                  </td>
                                  <td className="text-start">
                                    <input
                                      type="text"
                                      value={extractValue2(
                                        performanceState?.installed_kw
                                      )}
                                      onChange={(event) => {
                                        setSubmitEnabled(true);
                                        setPerformanceState({
                                          ...performanceState,
                                          installed_kw: event.target.value,
                                        });
                                      }}
                                      style={{ width: "75px" }}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-start">
                                    {t(
                                      "capacity.capacityoperating_it_consumption"
                                    )}
                                  </td>
                                  <td className="text-start">
                                    <input
                                      type="text"
                                      value={extractValue2(
                                        performanceState?.operating_kw
                                      )}
                                      onChange={(event) => {
                                        setSubmitEnabled(true);
                                        setPerformanceState({
                                          ...performanceState,
                                          operating_kw: event.target.value,
                                        });
                                      }}
                                      style={{ width: "75px" }}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-start" />
                                  <td className="text-start" />
                                </tr>
                                <tr>
                                  <td className="text-start" />
                                  <td className="text-start" />
                                </tr>
                              </thead>
                            </table>
                            <table
                              className="table table-borderless tb_dcp mb-4"
                              style={{
                                width: "350px",
                                whiteSpace: "nowrap",
                              }}
                            >
                              <thead>
                                <tr>
                                  <th
                                    colSpan="7"
                                    className="text-start"
                                    style={{
                                      fontWeight: "600 !important",
                                      fontSize: "1.2rem",
                                      border: "none",
                                    }}
                                  />
                                </tr>
                                <tr>
                                  <td
                                    className="text-start"
                                    style={{
                                      background: "#595959",
                                      color: "#fff",
                                    }}
                                  >
                                    {t("capacity.incidents")}
                                  </td>
                                  <td
                                    className="text-start"
                                    style={{
                                      background: "#595959",
                                      color: "#fff",
                                    }}
                                  >
                                    {t("capacity.infra")}
                                  </td>
                                  <td
                                    className="text-start"
                                    style={{
                                      background: "#595959",
                                      color: "#fff",
                                    }}
                                  >
                                    {t("capacity.security")}
                                  </td>
                                  <td
                                    className="text-start"
                                    style={{
                                      background: "#595959",
                                      color: "#fff",
                                    }}
                                  >
                                    {t("capacity.e_h_s")}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-start">
                                    {t("capacity.number")}
                                  </td>
                                  <td className="text-start">
                                    <input
                                      type="text"
                                      value={extractValue2(
                                        performanceState?.infra_incident_num
                                      )}
                                      onChange={(event) => {
                                        setSubmitEnabled(true);
                                        setPerformanceState({
                                          ...performanceState,
                                          infra_incident_num:
                                            event.target.value,
                                        });
                                      }}
                                      style={{ width: "55px" }}
                                    />
                                  </td>
                                  <td className="text-start">
                                    <input
                                      type="text"
                                      value={extractValue2(
                                        performanceState?.security_incident_num
                                      )}
                                      onChange={(event) => {
                                        setSubmitEnabled(true);
                                        setPerformanceState({
                                          ...performanceState,
                                          security_incident_num:
                                            event.target.value,
                                        });
                                      }}
                                      style={{ width: "55px" }}
                                    />
                                  </td>
                                  <td className="text-start">
                                    <input
                                      type="text"
                                      value={extractValue2(
                                        performanceState?.ehs_incident_num
                                      )}
                                      onChange={(event) => {
                                        setSubmitEnabled(true);
                                        setPerformanceState({
                                          ...performanceState,
                                          ehs_incident_num: event.target.value,
                                        });
                                      }}
                                      style={{ width: "55px" }}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-start">{t("types")}</td>
                                  <td className="text-start">
                                    <input
                                      type="text"
                                      value={extractValue2(
                                        performanceState?.infra_incident_type
                                      )}
                                      onChange={(event) => {
                                        setSubmitEnabled(true);
                                        setPerformanceState({
                                          ...performanceState,
                                          infra_incident_type:
                                            event.target.value,
                                        });
                                      }}
                                      style={{ width: "55px" }}
                                    />
                                  </td>
                                  <td className="text-start">
                                    <input
                                      type="text"
                                      value={extractValue2(
                                        performanceState?.security_incident_type
                                      )}
                                      onChange={(event) => {
                                        setSubmitEnabled(true);
                                        setPerformanceState({
                                          ...performanceState,
                                          security_incident_type:
                                            event.target.value,
                                        });
                                      }}
                                      style={{ width: "55px" }}
                                    />
                                  </td>
                                  <td className="text-start">
                                    <input
                                      type="text"
                                      value={extractValue2(
                                        performanceState?.ehs_incident_type
                                      )}
                                      onChange={(event) => {
                                        setSubmitEnabled(true);
                                        setPerformanceState({
                                          ...performanceState,
                                          ehs_incident_type: event.target.value,
                                        });
                                      }}
                                      style={{ width: "55px" }}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <td className="text-start" valign="top">
                                    {t("capacity.who_s_impacted")}
                                  </td>
                                  <td className="text-start" valign="top">
                                    {infraImpact &&
                                      infraImpact.map((imp, index) => {
                                        if (index === 0) {
                                          return (
                                            <div key={index}>
                                              <input
                                                type="text"
                                                name="impact"
                                                onChange={(evnt) =>
                                                  handleInfraChange(index, evnt)
                                                }
                                                value={extractValue2(
                                                  imp?.impact
                                                )}
                                                style={{ width: "55px" }}
                                              />
                                              <span
                                                onClick={addInfraInputField}
                                                style={{
                                                  marginLeft: "5px",
                                                  cursor: "pointer",
                                                  background: "green",
                                                  color: "#fff",
                                                  padding: "2px 5px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <i
                                                  className="fa fa-plus"
                                                  aria-hidden="true"
                                                  style={{
                                                    color: "#fff",
                                                    fontSize: "12px",
                                                    padding: "2px 5px",
                                                  }}
                                                />
                                              </span>
                                            </div>
                                          );
                                        } else {
                                          return (
                                            <div
                                              style={{ marginTop: "5px" }}
                                              key={index}
                                            >
                                              <input
                                                type="text"
                                                name="impact"
                                                value={extractValue2(
                                                  imp?.impact
                                                )}
                                                onChange={(evnt) =>
                                                  handleInfraChange(index, evnt)
                                                }
                                                style={{ width: "55px" }}
                                              />
                                              <span
                                                onClick={() =>
                                                  removeInfraInputFields(index)
                                                }
                                                style={{
                                                  marginLeft: "5px",
                                                  cursor: "pointer",
                                                  background: "red",
                                                  color: "#fff",
                                                  padding: "2px 5px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <i
                                                  className="fa fa-times"
                                                  aria-hidden="true"
                                                  style={{
                                                    color: "#fff",
                                                    fontSize: "12px",
                                                    padding: "2px 5px",
                                                  }}
                                                />
                                              </span>
                                            </div>
                                          );
                                        }
                                      })}
                                  </td>
                                  <td className="text-start" valign="top">
                                    {securityImpact &&
                                      securityImpact.map((imp, index) => {
                                        if (index === 0) {
                                          return (
                                            <div key={index}>
                                              <input
                                                type="text"
                                                name="impact"
                                                onChange={(evnt) =>
                                                  handleSecurityChange(
                                                    index,
                                                    evnt
                                                  )
                                                }
                                                value={extractValue2(
                                                  imp?.impact
                                                )}
                                                style={{ width: "55px" }}
                                              />
                                              <span
                                                onClick={addSecurityInputField}
                                                style={{
                                                  marginLeft: "5px",
                                                  cursor: "pointer",
                                                  background: "green",
                                                  color: "#fff",
                                                  padding: "2px 5px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <i
                                                  className="fa fa-plus"
                                                  aria-hidden="true"
                                                  style={{
                                                    color: "#fff",
                                                    fontSize: "12px",
                                                    padding: "2px 5px",
                                                  }}
                                                />
                                              </span>
                                            </div>
                                          );
                                        } else {
                                          return (
                                            <div
                                              style={{ marginTop: "5px" }}
                                              key={index}
                                            >
                                              <input
                                                type="text"
                                                name="impact"
                                                value={extractValue2(
                                                  imp?.impact
                                                )}
                                                onChange={(evnt) =>
                                                  handleSecurityChange(
                                                    index,
                                                    evnt
                                                  )
                                                }
                                                style={{ width: "55px" }}
                                              />
                                              <span
                                                onClick={() =>
                                                  removeSecurityInputFields(
                                                    index
                                                  )
                                                }
                                                style={{
                                                  marginLeft: "5px",
                                                  cursor: "pointer",
                                                  background: "red",
                                                  color: "#fff",
                                                  padding: "2px 5px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <i
                                                  className="fa fa-times"
                                                  aria-hidden="true"
                                                  style={{
                                                    color: "#fff",
                                                    fontSize: "12px",
                                                    padding: "2px 5px",
                                                  }}
                                                />
                                              </span>
                                            </div>
                                          );
                                        }
                                      })}
                                  </td>
                                  <td className="text-start" valign="top">
                                    {ehsImpact &&
                                      ehsImpact.map((imp, index) => {
                                        if (index === 0) {
                                          return (
                                            <div key={index}>
                                              <input
                                                type="text"
                                                name="impact"
                                                onChange={(evnt) =>
                                                  handleEHSChange(index, evnt)
                                                }
                                                value={extractValue2(
                                                  imp.impact
                                                )}
                                                style={{ width: "55px" }}
                                              />
                                              <span
                                                onClick={addEHSInputField}
                                                style={{
                                                  marginLeft: "5px",
                                                  cursor: "pointer",
                                                  background: "green",
                                                  color: "#fff",
                                                  padding: "2px 5px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <i
                                                  className="fa fa-plus"
                                                  aria-hidden="true"
                                                  style={{
                                                    color: "#fff",
                                                    fontSize: "12px",
                                                    padding: "2px 5px",
                                                  }}
                                                />
                                              </span>
                                            </div>
                                          );
                                        } else {
                                          return (
                                            <div
                                              style={{ marginTop: "5px" }}
                                              key={index}
                                            >
                                              <input
                                                type="text"
                                                name="impact"
                                                value={extractValue2(
                                                  imp.impact
                                                )}
                                                onChange={(evnt) =>
                                                  handleEHSChange(index, evnt)
                                                }
                                                style={{ width: "55px" }}
                                              />
                                              <span
                                                onClick={() =>
                                                  removeEHSInputFields(index)
                                                }
                                                style={{
                                                  marginLeft: "5px",
                                                  cursor: "pointer",
                                                  background: "red",
                                                  color: "#fff",
                                                  padding: "2px 5px",
                                                  fontSize: "12px",
                                                }}
                                              >
                                                <i
                                                  className="fa fa-times"
                                                  aria-hidden="true"
                                                  style={{
                                                    color: "#fff",
                                                    fontSize: "12px",
                                                    padding: "2px 5px",
                                                  }}
                                                />
                                              </span>
                                            </div>
                                          );
                                        }
                                      })}
                                  </td>
                                </tr>
                              </thead>
                            </table>
                          </div>

                          <table className="table border border-light table-borderless">
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  colSpan="2"
                                  style={{
                                    textAlign: "left",
                                    paddingLeft: "17px !important",
                                  }}
                                >
                                  {authContext.getNetworkDataCenter?.name ||
                                    dataCenter[0]?.name}
                                </th>
                                <th
                                  scope="col"
                                  colSpan="3"
                                  className="total_color"
                                >
                                  {t("capacity.total")}
                                </th>
                                <th scope="col" colSpan="3" className="in_so">
                                  {t("capacity.installed_sold")}
                                </th>
                                <th scope="col" colSpan="3" className="res">
                                  {t("capacity.reserved")}
                                </th>
                                <th scope="col" colSpan="3" className="strd">
                                  {t("capacity.stranded_hold")}
                                </th>
                                <th scope="col" colSpan="3" className="avilbl">
                                  {t("capacity.available")}
                                </th>
                                <th scope="col" colSpan="3" className="entvs">
                                  {t("capacity.delta")}
                                </th>
                              </tr>
                            </thead>
                            <thead>
                              <tr>
                                <th scope="col"> {t("capacity.floor")}</th>
                                <th scope="col"> {t("capacity.rooms")}</th>

                                <th scope="col" className="bg_gray">
                                  {t("capacity.cab_e")}
                                </th>
                                <th scope="col" className="bg_gray">
                                  {t("capacity.cages")}
                                </th>
                                <th scope="col" className="bg_gray">
                                  ({t("k_w")})
                                </th>
                                <th scope="col" className="tbr">
                                  {t("capacity.cab_e")}
                                </th>
                                <th scope="col" className="tbr">
                                  {t("capacity.cages")}
                                </th>
                                <th scope="col" className="tbr">
                                  ({t("k_w")})
                                </th>
                                <th scope="col" className="tbr">
                                  {t("capacity.cab_e")}
                                </th>
                                <th scope="col" className="tbr">
                                  {t("capacity.cages")}
                                </th>
                                <th scope="col" className="tbr">
                                  ({t("k_w")})
                                </th>
                                <th scope="col" className="tbr">
                                  {t("capacity.cab_e")}
                                </th>
                                <th scope="col" className="tbr">
                                  {t("capacity.cages")}
                                </th>
                                <th scope="col" className="tbr">
                                  ({t("k_w")})
                                </th>
                                <th scope="col" className="tbr">
                                  {t("capacity.cab_e")}
                                </th>
                                <th scope="col" className="tbr">
                                  {t("capacity.cages")}
                                </th>
                                <th scope="col" className="tbr">
                                  ({t("k_w")})
                                </th>
                                <th scope="col" className="tbr">
                                  {t("capacity.cab_e")}
                                </th>
                                <th scope="col" className="tbr">
                                  {t("capacity.cages")}
                                </th>
                                <th scope="col" className="tbr">
                                  ({t("k_w")})
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {capcityData &&
                                capcityData.map((capacity) => {
                                  let totalCabs = 0;
                                  let totalCages = 0;
                                  let totalpower = 0;

                                  if (capacity.monthly_utilization !== null) {
                                    let utilCab = 0;
                                    let utilCage = 0;
                                    let utilPower = 0;
                                    const c_m = capacity.monthly_utilization;

                                    utilCab =
                                      Number(c_m?.sold_cabs || 0) +
                                      Number(c_m?.reserved_cabs || 0) +
                                      Number(c_m?.blocked_cabs || 0) +
                                      Number(c_m?.available_cabs || 0);

                                    utilCage =
                                      Number(c_m?.sold_cages || 0) +
                                      Number(c_m?.reserved_cages || 0) +
                                      Number(c_m?.blocked_cages || 0) +
                                      Number(c_m?.available_cages || 0);

                                    utilPower =
                                      Number(c_m?.sold_power || 0) +
                                      Number(c_m?.reserved_power || 0) +
                                      Number(c_m?.blocked_power || 0) +
                                      Number(c_m?.available_power || 0);

                                    if (isReadOnly) {
                                      totalCabs =
                                        Number(c_m.total_cabs) -
                                        Number(utilCab);
                                      totalCages =
                                        Number(c_m.total_cages) -
                                        Number(utilCage);
                                      totalpower =
                                        Number(c_m.total_power) -
                                        Number(utilPower);
                                    } else {
                                      if (c_m.total_cabs === undefined) {
                                        c_m.total_cabs =
                                          capacity.monthly_utilization.total_cabs;
                                      }
                                      totalCabs =
                                        Number(
                                          capacity.monthly_utilization
                                            .total_cabs
                                        ) - Number(utilCab);
                                      totalCages =
                                        Number(
                                          capacity.monthly_utilization
                                            .total_cages
                                        ) - Number(utilCage);

                                      totalpower =
                                        Number(
                                          capacity.monthly_utilization
                                            .total_power
                                        ) - Number(utilPower);
                                    }
                                  } else {
                                    capacity.monthly_utilization = {
                                      month:
                                        month ||
                                        Number(authContext.getMonthYear.month) -
                                          1,
                                      year: year,
                                      room_id: capacity.id,
                                      data_center_id:
                                        authContext.getNetworkDataCenter.id,
                                      country_id:
                                        authContext.getNetworkDataCenter
                                          .country_id,
                                    };
                                    totalCabs = isReadOnly
                                      ? 0
                                      : Number(
                                          capacity.monthly_utilization
                                            .total_cabs
                                        );
                                    totalCages = isReadOnly
                                      ? 0
                                      : Number(
                                          capacity.monthly_utilization
                                            .total_cages
                                        );
                                    totalpower = isReadOnly
                                      ? 0
                                      : Number(
                                          capacity.monthly_utilization
                                            .total_power
                                        );
                                  }

                                  const mu = capacity.monthly_utilization;
                                  let changeValue = extVal(
                                    mu.total_cabs,
                                    capacity.monthly_utilization.total_cabs
                                  );
                                  totalObj.totalCabe +=
                                    changeValue !== "" &&
                                    changeValue !== undefined &&
                                    changeValue !== null &&
                                    changeValue !== "-"
                                      ? parseFloat(changeValue)
                                      : 0;
                                  changeValue = extVal(
                                    mu.total_cages,
                                    capacity.monthly_utilization.total_cages
                                  );
                                  totalObj.totalCage +=
                                    changeValue !== "" &&
                                    changeValue !== undefined &&
                                    changeValue !== null &&
                                    changeValue !== "-"
                                      ? parseFloat(changeValue)
                                      : 0;
                                  changeValue = extVal(
                                    mu.total_power,
                                    capacity.monthly_utilization.total_power
                                  );
                                  totalObj.totalPower +=
                                    changeValue !== "" &&
                                    changeValue !== undefined &&
                                    changeValue !== null &&
                                    changeValue !== "-"
                                      ? parseFloat(changeValue)
                                      : 0;
                                  changeValue = extractValue(mu.sold_cabs);
                                  totalObj.installCabe +=
                                    changeValue !== "" &&
                                    changeValue !== undefined &&
                                    changeValue !== null &&
                                    changeValue !== "-"
                                      ? parseFloat(changeValue)
                                      : 0;
                                  changeValue = extractValue(mu.sold_cages);
                                  totalObj.installCage +=
                                    changeValue !== "" &&
                                    changeValue !== undefined &&
                                    changeValue !== null &&
                                    changeValue !== "-"
                                      ? parseFloat(changeValue)
                                      : 0;
                                  changeValue = extractValue(mu.sold_power);
                                  totalObj.installPower +=
                                    changeValue !== "" &&
                                    changeValue !== undefined &&
                                    changeValue !== null &&
                                    changeValue !== "-"
                                      ? parseFloat(changeValue)
                                      : 0;
                                  changeValue = extractValue(mu.reserved_cabs);
                                  totalObj.reservedCabe +=
                                    changeValue !== "" &&
                                    changeValue !== undefined &&
                                    changeValue !== null &&
                                    changeValue !== "-"
                                      ? parseFloat(changeValue)
                                      : 0;
                                  changeValue = extractValue(mu.reserved_cages);
                                  totalObj.reservedCage +=
                                    changeValue !== "" &&
                                    changeValue !== undefined &&
                                    changeValue !== null &&
                                    changeValue !== "-"
                                      ? parseFloat(changeValue)
                                      : 0;
                                  changeValue = extractValue(mu.reserved_power);
                                  totalObj.reservedPower +=
                                    changeValue !== "" &&
                                    changeValue !== undefined &&
                                    changeValue !== null &&
                                    changeValue !== "-"
                                      ? parseFloat(changeValue)
                                      : 0;
                                  changeValue = extractValue(mu.blocked_cabs);
                                  totalObj.blockedCabe +=
                                    changeValue !== "" &&
                                    changeValue !== undefined &&
                                    changeValue !== null &&
                                    changeValue !== "-"
                                      ? parseFloat(changeValue)
                                      : 0;
                                  changeValue = extractValue(mu.blocked_cages);
                                  totalObj.blockedCage +=
                                    changeValue !== "" &&
                                    changeValue !== undefined &&
                                    changeValue !== null &&
                                    changeValue !== "-"
                                      ? parseFloat(changeValue)
                                      : 0;
                                  changeValue = extractValue(mu.blocked_power);
                                  totalObj.blockedPower +=
                                    changeValue !== "" &&
                                    changeValue !== undefined &&
                                    changeValue !== null &&
                                    changeValue !== "-"
                                      ? parseFloat(changeValue)
                                      : 0;
                                  changeValue = extractValue(mu.available_cabs);
                                  totalObj.availableCabe +=
                                    changeValue !== "" &&
                                    changeValue !== undefined &&
                                    changeValue !== null &&
                                    changeValue !== "-"
                                      ? parseFloat(changeValue)
                                      : 0;
                                  changeValue = extractValue(
                                    mu.available_cages
                                  );
                                  totalObj.availableCage +=
                                    changeValue !== "" &&
                                    changeValue !== undefined &&
                                    changeValue !== null &&
                                    changeValue !== "-"
                                      ? parseFloat(changeValue)
                                      : 0;
                                  changeValue = extractValue(
                                    mu.available_power
                                  );
                                  totalObj.availablePower +=
                                    changeValue !== "" &&
                                    changeValue !== undefined &&
                                    changeValue !== null &&
                                    changeValue !== "-"
                                      ? parseFloat(changeValue)
                                      : 0;
                                  changeValue = totalCabs;
                                  totalObj.deltaCabe +=
                                    changeValue !== "" &&
                                    changeValue !== undefined &&
                                    changeValue !== null &&
                                    changeValue !== "-"
                                      ? parseFloat(changeValue)
                                      : 0;
                                  changeValue = totalCages;
                                  totalObj.deltaCage +=
                                    changeValue !== "" &&
                                    changeValue !== undefined &&
                                    changeValue !== null &&
                                    changeValue !== "-"
                                      ? parseFloat(changeValue)
                                      : 0;
                                  changeValue = totalpower;
                                  totalObj.deltaPower +=
                                    changeValue !== "" &&
                                    changeValue !== undefined &&
                                    changeValue !== null &&
                                    changeValue !== "-"
                                      ? parseFloat(changeValue)
                                      : 0;

                                  return (
                                    <tr
                                      key={capacity.id}
                                      className={
                                        totalCabs < 0 ||
                                        totalCages < 0 ||
                                        totalpower < 0
                                          ? "negative_alert"
                                          : ""
                                      }
                                    >
                                      <td>{capacity.floor.name} </td>
                                      <td>{capacity.name}</td>
                                      <td className="bg_gray">
                                        {/* {setData(extVal(mu.total_cabs,capacity.monthly_utilization.total_cabs))} */}
                                        <input
                                          value={extVal(
                                            mu.total_cabs,
                                            capacity.monthly_utilization
                                              .total_cabs
                                          )}
                                          min="0"
                                          type="number"
                                          onKeyDown={(event) => {
                                            if (
                                              event.key === "+" ||
                                              event.key === "-"
                                            ) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(event) => {
                                            capacity.monthly_utilization.total_cabs =
                                              event.target.value;
                                            onChangeData(
                                              event,
                                              capacity,
                                              "total_cabs"
                                            );
                                          }}
                                        />
                                      </td>
                                      <td className="bg_gray">
                                        <input
                                          value={extVal(
                                            mu.total_cages,
                                            capacity.monthly_utilization
                                              .total_cages
                                          )}
                                          min="0"
                                          type="number"
                                          onKeyDown={(event) => {
                                            if (
                                              event.key === "+" ||
                                              event.key === "-"
                                            ) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(event) => {
                                            capacity.monthly_utilization.total_cages =
                                              event.target.value;
                                            onChangeData(
                                              event,
                                              capacity,
                                              "total_cages"
                                            );
                                          }}
                                        />
                                      </td>
                                      <td className="bg_gray">
                                        <input
                                          value={extVal(
                                            mu.total_power,
                                            capacity.monthly_utilization
                                              .total_power
                                          )}
                                          min="0"
                                          type="number"
                                          onKeyDown={(event) => {
                                            if (
                                              event.key === "+" ||
                                              event.key === "-"
                                            ) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(event) => {
                                            capacity.monthly_utilization.total_power =
                                              event.target.value;
                                            onChangeData(
                                              event,
                                              capacity,
                                              "total_power"
                                            );
                                          }}
                                        />
                                      </td>
                                      <td className="tbr">
                                        <input
                                          value={extractValue(mu.sold_cabs)}
                                          style={{
                                            border:
                                              totalCabs < 0
                                                ? "2px solid red"
                                                : "",
                                          }}
                                          min="0"
                                          type="number"
                                          onKeyDown={(event) => {
                                            if (
                                              event.key === "+" ||
                                              event.key === "-"
                                            ) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(event) => {
                                            onChangeData(
                                              event,
                                              capacity,
                                              totalCabs,
                                              "sold_cabs",
                                              capacity.floor.name,
                                              capacity.name
                                            );
                                          }}
                                        />
                                      </td>
                                      <td className="tbr">
                                        <input
                                          value={extractValue(mu.sold_cages)}
                                          style={{
                                            border:
                                              totalCages < 0
                                                ? "2px solid red"
                                                : "",
                                          }}
                                          min="0"
                                          type="number"
                                          onKeyDown={(event) => {
                                            if (
                                              event.key === "+" ||
                                              event.key === "-"
                                            ) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(event) => {
                                            onChangeData(
                                              event,
                                              capacity,
                                              totalCages,
                                              "sold_cages",
                                              capacity.floor.name,
                                              capacity.name
                                            );
                                          }}
                                        />
                                      </td>
                                      <td className="tbr">
                                        <input
                                          value={extractValue(mu.sold_power)}
                                          style={{
                                            border:
                                              totalpower < 0
                                                ? "2px solid red"
                                                : "",
                                          }}
                                          onChange={(event) => {
                                            onChangeData(
                                              event,
                                              capacity,
                                              totalpower,
                                              "sold_power",
                                              capacity.floor.name,
                                              capacity.name
                                            );
                                          }}
                                        />
                                      </td>
                                      <td className="tbr">
                                        <input
                                          value={extractValue(mu.reserved_cabs)}
                                          style={{
                                            border:
                                              totalCabs < 0
                                                ? "2px solid red"
                                                : "",
                                          }}
                                          min="0"
                                          type="number"
                                          onKeyDown={(event) => {
                                            if (
                                              event.key === "+" ||
                                              event.key === "-"
                                            ) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(event) => {
                                            onChangeData(
                                              event,
                                              capacity,
                                              totalCabs,
                                              "reserved_cabs",
                                              capacity.floor.name,
                                              capacity.name
                                            );
                                          }}
                                        />
                                      </td>
                                      <td className="tbr">
                                        <input
                                          value={extractValue(
                                            mu.reserved_cages
                                          )}
                                          style={{
                                            border:
                                              totalCages < 0
                                                ? "2px solid red"
                                                : "",
                                          }}
                                          min="0"
                                          type="number"
                                          onKeyDown={(event) => {
                                            if (
                                              event.key === "+" ||
                                              event.key === "-"
                                            ) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(event) => {
                                            onChangeData(
                                              event,
                                              capacity,
                                              totalCages,
                                              "reserved_cages",
                                              capacity.floor.name,
                                              capacity.name
                                            );
                                          }}
                                        />
                                      </td>
                                      <td className="tbr">
                                        <input
                                          value={extractValue(
                                            mu.reserved_power
                                          )}
                                          style={{
                                            border:
                                              totalpower < 0
                                                ? "2px solid red"
                                                : "",
                                          }}
                                          min="0"
                                          type="number"
                                          onKeyDown={(event) => {
                                            if (
                                              event.key === "+" ||
                                              event.key === "-"
                                            ) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(event) => {
                                            onChangeData(
                                              event,
                                              capacity,
                                              totalpower,
                                              "reserved_power",
                                              capacity.floor.name,
                                              capacity.name
                                            );
                                          }}
                                        />
                                      </td>
                                      <td className="tbr">
                                        <input
                                          value={extractValue(mu.blocked_cabs)}
                                          style={{
                                            border:
                                              totalCabs < 0
                                                ? "2px solid red"
                                                : "",
                                          }}
                                          min="0"
                                          type="number"
                                          onKeyDown={(event) => {
                                            if (
                                              event.key === "+" ||
                                              event.key === "-"
                                            ) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(event) => {
                                            onChangeData(
                                              event,
                                              capacity,
                                              totalCabs,
                                              "blocked_cabs",
                                              capacity.floor.name,
                                              capacity.name
                                            );
                                          }}
                                        />
                                      </td>
                                      <td className="tbr">
                                        <input
                                          value={extractValue(mu.blocked_cages)}
                                          style={{
                                            border:
                                              totalCages < 0
                                                ? "2px solid red"
                                                : "",
                                          }}
                                          min="0"
                                          type="number"
                                          onKeyDown={(event) => {
                                            if (
                                              event.key === "+" ||
                                              event.key === "-"
                                            ) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(event) => {
                                            onChangeData(
                                              event,
                                              capacity,
                                              totalCages,
                                              "blocked_cages",
                                              capacity.floor.name,
                                              capacity.name
                                            );
                                          }}
                                        />
                                      </td>
                                      <td className="tbr">
                                        <input
                                          value={extractValue(mu.blocked_power)}
                                          style={{
                                            border:
                                              totalpower < 0
                                                ? "2px solid red"
                                                : "",
                                          }}
                                          min="0"
                                          type="number"
                                          onKeyDown={(event) => {
                                            if (
                                              event.key === "+" ||
                                              event.key === "-"
                                            ) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(event) => {
                                            onChangeData(
                                              event,
                                              capacity,
                                              totalpower,
                                              "blocked_power",
                                              capacity.floor.name,
                                              capacity.name
                                            );
                                          }}
                                        />
                                      </td>
                                      <td className="tbr">
                                        <input
                                          value={extractValue(
                                            mu.available_cabs
                                          )}
                                          style={{
                                            border:
                                              totalCabs < 0
                                                ? "2px solid red"
                                                : "",
                                          }}
                                          min="0"
                                          type="number"
                                          onKeyDown={(event) => {
                                            if (
                                              event.key === "+" ||
                                              event.key === "-"
                                            ) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(event) => {
                                            onChangeData(
                                              event,
                                              capacity,
                                              totalCabs,
                                              "available_cabs",
                                              capacity.floor.name,
                                              capacity.name
                                            );
                                          }}
                                        />
                                      </td>
                                      <td className="tbr">
                                        <input
                                          value={extractValue(
                                            mu.available_cages
                                          )}
                                          style={{
                                            border:
                                              totalCages < 0
                                                ? "2px solid red"
                                                : "",
                                          }}
                                          min="0"
                                          type="number"
                                          onKeyDown={(event) => {
                                            if (
                                              event.key === "+" ||
                                              event.key === "-"
                                            ) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(event) => {
                                            onChangeData(
                                              event,
                                              capacity,
                                              totalCages,
                                              "available_cages",
                                              capacity.floor.name,
                                              capacity.name
                                            );
                                          }}
                                        />
                                      </td>
                                      <td className="tbr">
                                        <input
                                          value={extractValue(
                                            mu.available_power
                                          )}
                                          style={{
                                            border:
                                              totalpower < 0
                                                ? "2px solid red"
                                                : "",
                                          }}
                                          min="0"
                                          type="number"
                                          onKeyDown={(event) => {
                                            if (
                                              event.key === "+" ||
                                              event.key === "-"
                                            ) {
                                              event.preventDefault();
                                            }
                                          }}
                                          onChange={(event) => {
                                            onChangeData(
                                              event,
                                              capacity,
                                              totalpower,
                                              "available_power",
                                              capacity.floor.name,
                                              capacity.name
                                            );
                                          }}
                                        />
                                      </td>
                                      <td
                                        className="tbr"
                                        style={{
                                          backgroundColor:
                                            Number(totalCabs) < 0
                                              ? "red"
                                              : "white",
                                          color:
                                            Number(totalCabs) < 0
                                              ? "white"
                                              : "black",
                                        }}
                                      >
                                        {numberFormat(totalCabs, 2)}
                                      </td>
                                      <td
                                        className="tbr"
                                        style={{
                                          backgroundColor:
                                            Number(totalCages) < 0
                                              ? "red"
                                              : "white",
                                          color:
                                            Number(totalCages) < 0
                                              ? "white"
                                              : "black",
                                        }}
                                      >
                                        {numberFormat(totalCages, 2)}
                                      </td>
                                      <td
                                        className="tbr"
                                        style={{
                                          backgroundColor:
                                            Number(totalpower) < 0
                                              ? "red"
                                              : "white",
                                          color:
                                            Number(totalpower) < 0
                                              ? "white"
                                              : "black",
                                        }}
                                      >
                                        {numberFormat(totalpower, 3)}
                                      </td>
                                    </tr>
                                  );
                                })}
                              <tr>
                                <td colSpan={2}>{t("capacity.total")}</td>
                                <td>{totalObj.totalCabe.toFixed(2)}</td>
                                <td>{totalObj.totalCage.toFixed(2)}</td>
                                <td>{totalObj.totalPower.toFixed(2)}</td>
                                <td>{totalObj.installCabe.toFixed(2)}</td>
                                <td>{totalObj.installCage.toFixed(2)}</td>
                                <td>{totalObj.installPower.toFixed(2)}</td>
                                <td>{totalObj.reservedCabe.toFixed(2)}</td>
                                <td>{totalObj.reservedCage.toFixed(2)}</td>
                                <td>{totalObj.reservedPower.toFixed(2)}</td>
                                <td>{totalObj.blockedCabe.toFixed(2)}</td>
                                <td>{totalObj.blockedCage.toFixed(2)}</td>
                                <td>{totalObj.blockedPower.toFixed(2)}</td>
                                <td>{totalObj.availableCabe.toFixed(2)}</td>
                                <td>{totalObj.availableCage.toFixed(2)}</td>
                                <td>{totalObj.availablePower.toFixed(2)}</td>
                                <td>{totalObj.deltaCabe.toFixed(2)}</td>
                                <td>{totalObj.deltaCage.toFixed(2)}</td>
                                <td>{totalObj.deltaPower.toFixed(2)}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>

                        <div className="monthly_last_btn">
                          <div
                            className="toolbar toolbar-bottom d-flex gap-2"
                            role="toolbar"
                          >
                            <button
                              style={{
                                whiteSpace: "nowrap",
                                padding: "0 10px 0 10px",
                                color: "#fff",
                                backgroundColor: "#b86d45",
                              }}
                              type="button"
                              onClick={onExcelUpload}
                              className="btn btn-outline-primary"
                            >
                              {" "}
                              {t("capacity.excel_upload")}{" "}
                            </button>
                            <button
                              type="button"
                              onClick={() => {
                                getCapacity(authContext.getNetworkDataCenter);
                                setInvalidEntries([]);
                                setInvalidFields({});
                                setIsValid(true);
                              }}
                              className="btn btn-outline-primary mr_1"
                            >
                              {" "}
                              {t("common.cancel")}{" "}
                            </button>
                            <button
                              type="submit"
                              className="btn btn-primary"
                              disabled={!isSubmitEnabled}
                              onClick={handleSaveClick}
                            >
                              {" "}
                              {t("common.save")}{" "}
                            </button>
                          </div>
                        </div>

                        <div>
                          <div
                            id="test"
                            className={`modal fade bd-example-modal-lg ${
                              showModal ? "show" : ""
                            }`}
                            tabIndex="-1"
                            role="dialog"
                            aria-hidden={!showModal}
                            style={{ display: showModal ? "block" : "none" }}
                          >
                            <div className="modal-dialog modal-lg">
                              <div className="modal-content">
                                <div className="modal-header mt-59">
                                  <h3 className="modal-title">
                                    {t("capacity.confirm_edit")}
                                  </h3>
                                  <button
                                    ref={modalRef}
                                    onClick={handleClose}
                                    style={{
                                      cursor: "pointer",
                                      background: "transparent",
                                      border: "none",
                                    }}
                                    data-bs-dismiss="modal"
                                  >
                                    <CloseIcon />
                                  </button>
                                </div>
                                <div className="modal-body">
                                  <div
                                    className="card"
                                    style={{ border: "none" }}
                                  >
                                    <div className="card-body">
                                      <div className="txt_comf">
                                        <p>
                                          {t(
                                            "capacity.please_confirm_before_updating_the_database"
                                          )}
                                        </p>
                                      </div>
                                      <div
                                        className="toolbar toolbar-bottom mt-51 d-flex gap-2 justify-content-end p-2"
                                        role="toolbar"
                                      >
                                        <button
                                          type="button"
                                          onClick={onClose}
                                          className="btn btn-outline-primary mr_1"
                                        >
                                          {" "}
                                          {t("common.cancel")}{" "}
                                        </button>
                                        <button
                                          type="button"
                                          onClick={onSubmit}
                                          className="btn btn-primary"
                                        >
                                          {" "}
                                          {t("common.save")}{" "}
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {showExcelUploadModal && (
                            <ExcelUploadModal
                              setShowExcelUploadModal={setShowExcelUploadModal}
                              setExcelReportData={setExcelReportData}
                              refresh={selectDataCenterFloor}
                              dataCenter={currentDataCenter}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};
export default Capacity;
