// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ticket-input-field.form-floating > .form-control:focus,
.ticket-input-field.form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 35px !important;
}`, "",{"version":3,"sources":["webpack://./src/views/super-admin/Tickets/shared/TicketInputField/TicketInputField.scss"],"names":[],"mappings":"AAAA;;EAEE,4BAAA;AACF","sourcesContent":[".ticket-input-field.form-floating > .form-control:focus,\n.ticket-input-field.form-floating > .form-control:not(:placeholder-shown) {\n  padding-top: 35px !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
