import { axiosWithToken as axios } from 'utils/axios';

const dataOrError = async (request) => {
  const dto = await request();
  if (dto.data.message) {
    throw { message: dto.data.message };
  } else {
    return dto.data.data;
  }
};

class CreateTicket {
  /*
    |-------------------------------------------------------------------------------
    |get Companies
    |-------------------------------------------------------------------------------
  */
  async getCompanies(token, countryCode) {
    return await dataOrError(async () => {
      return await axios(token).get(`getCompaniesOdoo?countryCode=${countryCode}`);
    });
  }

  /*
    |-------------------------------------------------------------------------------
    |get Operating Sites By Company Id
    |-------------------------------------------------------------------------------
  */
  async getOperatingSites(token, country) {
    return await dataOrError(async () => {
      return await axios(token).get(`getOperatingSitesOdoo?companyId=${country && country}`);
    });
  }

  async getAllOperatingSites(token) {
    return await dataOrError(async () => {
      return await axios(token).get(`getOperatingSitesOdoo`);
    });
  }
  /*
    |-------------------------------------------------------------------------------
    |get Customers Sites By Company Id
    |-------------------------------------------------------------------------------
  */
  async getCustomers(token, companyId) {
    return await axios(token).get(`getCustomersOdoo?companyId=${companyId}`);
  }

  /*
    |-------------------------------------------------------------------------------
    |get Service Ids By Company Id and Operating Site Id
    |-------------------------------------------------------------------------------
  */
  async getServiceIds({ token, companyId, siteId,space_related }) {
    // console.log("service id",space_related)
    return await axios(token).get(`getServiceIdsOdoo?companyId=${companyId}&siteId=${siteId}&space_related=${space_related}`);
  }

  /*
    |-------------------------------------------------------------------------------
    |post Create Site Access Ticket
    |-------------------------------------------------------------------------------
  */
  async createTicket(token, data, ticketType) {
    return await dataOrError(async () => {
      return await axios(token).post(`createTicketOdoo?ticketType=${ticketType}`, data);
    });
  }

  /*
    |-------------------------------------------------------------------------------
    |get HelpDesk team
    |-------------------------------------------------------------------------------
  */
  async getHelpDeskTeam(token) {
    return await dataOrError(async () => {
      return await axios(token).get('getHelpDeskTeamODDO');
    });
  }

  /*
    |-------------------------------------------------------------------------------
    |get Ticket Types
    |-------------------------------------------------------------------------------
  */
  async getTicketTypes(token) {
    return await axios(token).get('getTicketTypes');
  }

  /*
    |-------------------------------------------------------------------------------
    |get Ns Ticket Types Sub Types For Remote Hands Form
    |-------------------------------------------------------------------------------
  */
  async getNsTicketSubtypes(token) {
    return await axios(token).get('getNsTicketSubtypes');
  }

  /*
    |-------------------------------------------------------------------------------
    |get Company Ban By Company Id
    |-------------------------------------------------------------------------------
  */
  async getComnpanyBan(token, companyId) {
    return await axios(token).get(`getCompanyBanOdoo?companyId=${companyId}`);
  }

  /*
    |-------------------------------------------------------------------------------
    |get Table Content
    |-------------------------------------------------------------------------------
  */
  async getTableContent(token, table) {
    return await dataOrError(async () => {
      return await axios(token).get(`getTableContent?table=${table}`);
    });
  }

  /*
    |-------------------------------------------------------------------------------
    |get devices data 
    |-------------------------------------------------------------------------------
  */
  async getDevices(token, siteId) {
    return await axios(token).post(`getImpactedDevices?siteId=${siteId}`);
  }

  /// get company based on country
  async getCompanieslist(token) {
    return await axios(token).get(`getCompanies`);
  }

  /// get companies contacts
  async getCompaniesContacts(token, companyId, show_reseller_contacts) {
    return await axios(token).get(
      `getCompaniesContacts?companyId=${companyId}&show_reseller_contacts=${show_reseller_contacts}`
    );
  }
}

export default new CreateTicket();
