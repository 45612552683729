import { Row, Col } from 'react-bootstrap'
import { css } from 'glamor'
import { useTranslation } from 'react-i18next'
import TicketInputField from 'views/super-admin/Tickets/shared/TicketInputField'
import PlusIcon from './PlusIcon'
import TicketSelectField from 'views/super-admin/Tickets/shared/TicketSelectField'
import { useMemo } from 'react'

const PackageDetail = ({
  isActive,
  detailsTrackingNo,
  detailsRemarks,
  detailsWeight,
  detailsWeightDimension,
  setFieldValue,
  handleAddPackage,
  detailsHeight,
  detailsWidth,
  detailsDepth,
  detailsSizeDimension,
  weightDimensions,
  lengthDimensions
}) => {
  const { t } = useTranslation()
  const active = isActive('expectedDate')
  const styles = {
    remarks: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'flex-end'
    },
    colLeft: {
      paddingLeft: '0px'
    },
    colRight: {
      paddingRight: '0px'
    },
    colRow: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'flex-end'
    },
    separator: {
      fontSize: '16px',
      position: 'relative'
    },
    weightRow: {
      display: 'flex',
      alignItems: 'flex-end'
    }
  }

  const separator = (offset) => {
    const leftOffset = {
      left: offset
    }
    return (
      <div {...css(styles.separator, leftOffset)}>X</div>
    )
  }
  return (
    <>
      <Row>
        <TicketInputField
          id='detailsTrackingNo'
          label={t('create-ticket.shipment.details.tracking-no')}
          value={detailsTrackingNo}
          active={active}
          setFieldValue={setFieldValue}
        />
      </Row>
      <Row {...css(styles.weightRow)}>
        <Col {...css(styles.colLeft)}>
          <TicketInputField
            id='detailsWeight'
            label={t('create-ticket.shipment.details.weight')}
            value={detailsWeight}
            active={active}
            setFieldValue={setFieldValue}
          />
        </Col>
        <Col {...css(styles.colRight)}>
          <TicketSelectField
            id='detailsWeightDimension'
            label={t('create-ticket.shipment.details.weight-dimension')}
            setFieldValue={setFieldValue}
            active={active}
            options={weightDimensions}
            placeholder={t('create-ticket.shipment.details.weight-dimension')}
            value={detailsWeightDimension}
            handleClear={() => {
              setFieldValue('detailsWeightDimension', '')
            }}
            noLabel
            isClearable
          />
        </Col>
      </Row>
      <Row>
        <Col {...css(styles.colLeft, styles.colRow)}>
          <TicketInputField
            id='detailsHeight'
            label={t('create-ticket.shipment.details.height')}
            value={detailsHeight}
            active={active}
            setFieldValue={setFieldValue}
          />
          {separator('7px')}
        </Col>
        <Col {...css(styles.colLeft, styles.colRow)}>
          <TicketInputField
            id='detailsWidth'
            label={t('create-ticket.shipment.details.width')}
            value={detailsWidth}
            active={active}
            setFieldValue={setFieldValue}
          />
          {separator('15px')}
        </Col>
        <Col {...css(styles.colRight)}>
          <TicketInputField
            id='detailsDepth'
            label={t('create-ticket.shipment.details.depth')}
            value={detailsDepth}
            active={active}
            setFieldValue={setFieldValue}
          />
        </Col>
      </Row>
      <Row>
        <TicketSelectField
          id='detailsSizeDimension'
          label={t('create-ticket.shipment.details.dimension-uom')}
          setFieldValue={setFieldValue}
          active={active}
          options={lengthDimensions}
          placeholder={t('create-ticket.shipment.details.dimension-uom')}
          value={detailsSizeDimension}
          handleClear={() => {
            setFieldValue('detailsSizeDimension', '')
          }}
          noLabel
          isClearable
        />
      </Row>
      <Row {...css(styles.remarks)}>
        <div style={{ width: '90%' }} className='p-0'>
          <TicketInputField
            id='detailsRemarks'
            label={t('create-ticket.shipment.details.remarks')}
            active={active}
            value={detailsRemarks}
            setFieldValue={setFieldValue}
          />
        </div>
        <div style={{ width: '20px', padding: '0px !important' }}>
          {active && <PlusIcon handleClick={handleAddPackage} />}
        </div>
      </Row>
    </>
  )
}

const PackageDetailMemo = ({
  isActive,
  detailsTrackingNo,
  detailsRemarks,
  detailsWeight,
  detailsWeightDimension,
  setFieldValue,
  handleAddPackage,
  detailsHeight,
  detailsWidth,
  detailsDepth,
  detailsSizeDimension,
  weightDimensions,
  lengthDimensions
}) => {
  return useMemo(() => {
    return (
      <PackageDetail
        isActive={isActive}
        detailsTrackingNo={detailsTrackingNo}
        detailsRemarks={detailsRemarks}
        detailsWeight={detailsWeight}
        detailsWeightDimension={detailsWeightDimension}
        setFieldValue={setFieldValue}
        handleAddPackage={handleAddPackage}
        detailsHeight={detailsHeight}
        detailsWidth={detailsWidth}
        detailsDepth={detailsDepth}
        detailsSizeDimension={detailsSizeDimension}
        weightDimensions={weightDimensions}
        lengthDimensions={lengthDimensions}
      />
    )
  }, [
    isActive,
    detailsTrackingNo,
    detailsRemarks,
    detailsWeight,
    detailsWeightDimension,
    detailsHeight,
    detailsWidth,
    detailsDepth,
    detailsSizeDimension,
    weightDimensions,
    lengthDimensions
  ])
}
export default PackageDetailMemo
