// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings-reports-layout {
  display: flex;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    justify-content: space-between;
  }

  @media only screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: flex-start;
  }
}
`, "",{"version":3,"sources":["webpack://./src/views/super-admin/Setting/Reports/Reports.css"],"names":[],"mappings":"AAAA;EACE,aAAa;;EAEb;IACE,sBAAsB;IACtB,8BAA8B;EAChC;;EAEA;IACE,mBAAmB;IACnB,2BAA2B;EAC7B;AACF","sourcesContent":[".settings-reports-layout {\n  display: flex;\n\n  @media only screen and (max-width: 768px) {\n    flex-direction: column;\n    justify-content: space-between;\n  }\n\n  @media only screen and (min-width: 768px) {\n    flex-direction: row;\n    justify-content: flex-start;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
