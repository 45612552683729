import { useEffect, useState } from 'react'
import uuid from 'react-uuid'

const usePackageDetails = ({ formik }) => {
  const { setFieldValue } = formik
  const [validationError, setValidationError] = useState()

  const cleanUpInput = (value, valueName) => {
    const notNumbers = /[^0-9\\.]/
    if (value !== '' && notNumbers.test(value)) {
      setFieldValue(valueName, value.replace(notNumbers, ''))
    }
  }
  useEffect(() => {
    const {
      detailsWeight,
      detailsHeight,
      detailsWidth,
      detailsDepth
    } = formik.values
    cleanUpInput(detailsWeight, 'detailsWeight')
    cleanUpInput(detailsHeight, 'detailsHeight')
    cleanUpInput(detailsWidth, 'detailsWidth')
    cleanUpInput(detailsDepth, 'detailsDepth')
  }, [
    formik.values.detailsWeight,
    formik.values.detailsHeight,
    formik.values.detailsWidth,
    formik.values.detailsDepth
  ])

  const validateInput = (fields) => {

    const {
      detailsTrackingNo,
      detailsWeight,
      detailsWeightDimension,
      detailsRemarks,
      detailsHeight,
      detailsWidth,
      detailsDepth,
      detailsSizeDimension
    } = fields
    const valdateWeightDimensions = () => {
      if (
        detailsWeight !== '' ||
        detailsWeightDimension !== '') {
        return (
          detailsWeight !== '' &&
          detailsWeightDimension !== ''
        )
      }
      return true
    }
    const valdateSizeDimensions = () => {
      if (
        detailsHeight !== '' ||
        detailsWidth !== '' ||
        detailsDepth !== '' ||
        detailsSizeDimension !== '') {
        return (
          detailsHeight !== '' &&
          detailsWidth !== '' &&
           detailsDepth !== '' &&
          detailsSizeDimension !== ''
        )
      }
      return true
    }
    if (!valdateSizeDimensions() || !valdateWeightDimensions()) {
      setValidationError('Please select UOM for the dimension if you fill in the dimension field')
      // console.log('validation error')
      return false
    }
    return true
  }

  const cleanFormValues = () => {
    setFieldValue('detailsTrackingNo', '')
    setFieldValue('detailsRemarks', '')
    setFieldValue('detailsWeight', '')
    setFieldValue('detailsWeightDimension', '')
    setFieldValue('detailsHeight', '')
    setFieldValue('detailsWidth', '')
    setFieldValue('detailsDepth', '')
    setFieldValue('detailsSizeDimension', '')
  }

  const handleAddPackage = () => {
    const {
      detailsTrackingNo,
      detailsRemarks,
      detailsWeight,
      detailsWeightDimension,
      detailsHeight,
      detailsWidth,
      detailsDepth,
      detailsSizeDimension,
      packageList
    } = formik.values

    if (validateInput({
      detailsTrackingNo,
      detailsRemarks,
      detailsWeight,
      detailsWeightDimension,
      detailsHeight,
      detailsWidth,
      detailsDepth,
      detailsSizeDimension
    })) {
      formik.setFieldValue('packageList', [...packageList, {
        id: uuid(),
        detailsTrackingNo,
        detailsRemarks,
        detailsWeight,
        detailsWeightDimension,
        detailsHeight,
        detailsWidth,
        detailsDepth,
        detailsSizeDimension
      }])
      cleanFormValues()
    }
  }

  const handleDeletePackage = (id) => {
    const {
      packageList
    } = formik.values

    const listCopy = packageList.slice()
    formik.setFieldValue('packageList', listCopy.filter(p => p.id !== id))
  }

  const handleChangePackage = (id) => {
    const {
      packageList
    } = formik.values
    const pack = packageList.find(p => p.id === id)

    if (pack) {
      const {
        detailsTrackingNo,
        detailsRemarks,
        detailsWeight,
        detailsWeightDimension,
        detailsHeight,
        detailsWidth,
        detailsDepth,
        detailsSizeDimension
      } = pack

      setFieldValue('detailsTrackingNo', detailsTrackingNo)
      setFieldValue('detailsRemarks', detailsRemarks)
      setFieldValue('detailsWeight', detailsWeight)
      setFieldValue('detailsWeightDimension', detailsWeightDimension)
      setFieldValue('detailsHeight', detailsHeight)
      setFieldValue('detailsWidth', detailsWidth)
      setFieldValue('detailsDepth', detailsDepth)
      setFieldValue('detailsSizeDimension', detailsSizeDimension)

      handleDeletePackage(id)
    }
  }

  const removeAllPackages = () => {
    formik.setFieldValue('packageList', [])
  }

  return {
    handleAddPackage,
    handleDeletePackage,
    handleChangePackage,
    removeAllPackages,
    validationError,
    setValidationError
  }
}

export default usePackageDetails
