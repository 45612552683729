const ShipmentOutboundPackageDetail = ({
  shipment_details,
  toggleEdit,
  istypetrue,
  delVisitor,
  setToggleLine,
  checkPermission,
}) => {
  return (
    <div class="site-table" style={{ width: "90%" }}>
      <table class="table ticket-table-td">
        <thead>
          <tr>
            <th scope="col" class="site-tr">
              Dispatched
            </th>
            <th scope="col" class="site-tr">
              Shipment Weight
            </th>
            <th scope="col" class="site-tr">
              Package Dimension
            </th>
            <th scope="col" class="site-tr">
              Tracking Number
            </th>
            <th scope="col" class="site-tr">
              Storage Location
            </th>
            <th scope="col" class="site-tr">
              Remarks
            </th>
          </tr>
        </thead>
        <tbody>
          {shipment_details &&
            shipment_details.map((data, key) => {
              return (
                <tr>
                  <td class="site-tr">
                    {data?.require_loading_dock ? (
                      <input type="checkbox" checked disabled />
                    ) : (
                      <input type="checkbox" disabled />
                    )}
                  </td>
                  <td class="site-tr">
                    {data?.weight + " " + data?.uom || "-"}
                  </td>
                  <td class="site-tr">{data?.dimension || "-"}</td>
                  <td class="site-tr">{data?.tracking_number || "-"}</td>
                  <td class="site-tr">{data?.storage_location || "-"}</td>
                  <td class="site-tr">{data?.remarks || "-"}</td>
                  {toggleEdit &&
                  istypetrue &&
                  checkPermission("Edit Shipment Item") &&
                  checkPermission("Maintenance Mode") ? (
                    <td
                      class="site-tr"
                      style={{
                        borderTop: "0.0625rem solid #EEEEEE",
                      }}
                    >
                      {" "}
                      <img
                        src="images/fclose.svg"
                        alt=""
                        width={15}
                        height={15}
                        onClick={() => delVisitor(key)}
                      />
                    </td>
                  ) : null}
                </tr>
              );
            })}
          {toggleEdit &&
            istypetrue &&
            checkPermission("Edit Shipment Item") &&
            checkPermission("Maintenance Mode") && (
              <tr>
                <div
                  style={{
                    color: "#14d6d3",
                    fontWeight: "600",
                    cursor: "pointer",
                  }}
                  onClick={() => setToggleLine(true)}
                >
                  Add Pakage Details
                </div>
              </tr>
            )}
        </tbody>
      </table>
    </div>
  );
};
export default ShipmentOutboundPackageDetail;
