import AuthContext from 'context'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CreateTicketService from 'services/createTicketService'
import Swal from 'sweetalert2'
import { LANGUAGES, getLanguageCode } from '../../../../../global-definitions'

const HANDLING_INSTRUCTIONS_TABLE = 'helpdesk_shipment_handling_instruction'

const useGetHandlingInstructions = () => {
  const { t } = useTranslation()
  const authContext = useContext(AuthContext)

  const [handlingInstructions, setHandlingInstructions] = useState([])

  const getHandlingInstructions = async () => {
    try {
      const currentLanguage = authContext.getAuth.language_id
      const getHandlingInstructionsDto = await CreateTicketService
        .getTableContent(authContext.token(), HANDLING_INSTRUCTIONS_TABLE)
      const result = getHandlingInstructionsDto
        .filter(item => item.deletedAt === null)
        .map(item => {
          const { id, value } = item
          return { ...item, label: value, value: id, name: value }
        })
      const resultEN = result.filter(item => item.lang === getLanguageCode(LANGUAGES.EN))
      const resultCurrentLanguage = result.filter(item => item.lang === getLanguageCode(currentLanguage))
      setHandlingInstructions(resultCurrentLanguage.length > 0 ? resultCurrentLanguage : resultEN)
    } catch (err) {
      await Swal.fire(t('error'), err.message)
    }
  }

  useEffect(() => {
    getHandlingInstructions()
  }, [])

  return {
    handlingInstructions
  }
}

export default useGetHandlingInstructions
