import React, { useEffect, useState } from "react";
import Pagination from "../shared/usePagination";
import SidePop from "../../sidePop";
import moment from "moment";
import { useTranslation } from "react-i18next";
import TicketService from "services/ticketService";

const Table = ({
  columns,
  data,
  setItemsPerPage,
  setPageNumber,
  total,
  pageNumber,
  userTimzone,
  readinessChange,
  newTicket,
  handleModalPopupOnClick,
  timzonePrefix,
  escalateTeams,
  checkIfIncidentTicket,
  playing,
  getStatus,
  menu,
  sidePopValDb,
  setSidePopValDb,
  setSort,
  sort,
  setSortDirection,
  sortDirection,
  SidePopVal,
  setSidePopVal,
  setIsLoading,
}) => {
  const { t } = useTranslation();
  const [isAscending, setIsAscending] = useState(true);
  const [sortedColumn, setSortedColumn] = useState(null);
  const [sidePop, setSidePop] = useState(false);
  const [ticketList, setTicketList] = useState([]);
  const [isSave, setIsSave] = useState(false);
  const [filteredSidePop, setFilteredSidePop] = useState([]);

  useEffect(() => {
    if (sidePopValDb != null) {
      setSidePopVal(sidePopValDb.column);
    }
    setTicketList(data);
  }, [data, sidePopValDb]);

  // useEffect(() => {
  //   console.log(filteredSidePop);
  // }, [filteredSidePop]);

  useEffect(() => {
    // console.log(SidePopVal);
    // console.log(menu);
    if (menu === 0 || menu === 4) {
      setFilteredSidePop(SidePopVal);
    }
    if (menu === 1) {
      const filteredSidePop = { ...SidePopVal };
      delete filteredSidePop.visit_start_date;
      delete filteredSidePop.visit_end_date;
      delete filteredSidePop.designated_company;
      delete filteredSidePop.portal_code;
      delete filteredSidePop.visiting_rooms_display;
      delete filteredSidePop.spaces_display;
      delete filteredSidePop.visitors;
      setFilteredSidePop(filteredSidePop);
    }
    if (menu === 5 || menu === 3 || menu == 2) {
      const filteredSidePop = { ...SidePopVal };
      delete filteredSidePop.visit_start_date;
      delete filteredSidePop.visit_end_date;
      delete filteredSidePop.visiting_rooms_display;
      delete filteredSidePop.visitors;
      setFilteredSidePop(filteredSidePop);
    }
  }, [menu, SidePopVal]);

  const checkIfTicketIsCreated30DayBefore = (create_date) => {
    // console.log(create_date);
    const date1 = new Date();
    const date2 = create_date;
    const parsedDate1 = moment(date1);
    const parsedDate2 = moment(date2);
    // console.log(parsedDate1);
    // console.log(parsedDate2);
    const utcDate1 = parsedDate1.utc();
    const utcDate2 = parsedDate2.utc();
    // console.log(utcDate1);
    // console.log(utcDate2);

    const differenceInDays = utcDate1.diff(utcDate2, "days");
    // console.log(differenceInDays);
    const is30DaysOlder = differenceInDays >= 30;
    return is30DaysOlder;
  };
  useEffect(() => {
    if (isSave) {
      getTicketCols();
    }
  }, [isSave]);

  const getTicketCols = async () => {
    setIsLoading(true);
    await TicketService.ticketcols(localStorage.getItem("token"), SidePopVal)
      .then((res) => {
        // console.log(res);
        setSidePopValDb(res.data.data);
        setIsSave(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const sortTable = (columnName) => {
    const checkIfItIsAllreadySelected =
      columnName === sortedColumn ? !isAscending : true;
    setSortedColumn(columnName);
    setIsAscending(checkIfItIsAllreadySelected);
    if (columnName == sort) {
      setSortDirection(sortDirection == "asc" ? "desc" : "asc");
      setPageNumber(1);
    } else {
      setSort(columnName);
      setSortDirection("asc");
      setPageNumber(1);
    }
  };

  return (
    <React.Fragment>
      <table className="table header-border table-borderless table-hover verticle-middle">
        <thead style={{ position: "relative" }}>
          <tr>
            {columns &&
              columns.map((column, i) => {
                if (SidePopVal[column.name]) {
                  return (
                    <th
                      key={i}
                      scope="col"
                      style={{ cursor: "pointer" }}
                      onClick={() => sortTable(column.name)}
                    >
                      {column.value}
                      <i
                        className={`fa fa-solid fa-sort-${
                          column.name === sortedColumn
                            ? !isAscending
                              ? "down"
                              : "up"
                            : "down"
                        }`}
                        style={{ fontSize: "1.3rem", paddingLeft: "6px" }}
                      ></i>
                    </th>
                  );
                } else {
                  return null;
                }
              })}
            <th>{t(`ticket.action`)}</th>
            <th>
              <img
                src="\images\3dots.svg"
                alt=""
                onClick={() => setSidePop(!sidePop)}
                style={{
                  cursor: "pointer",
                  right: "12px",
                  top: "4px",
                  width: "20px",
                  transform: "rotate(90deg)",
                }}
              />
              <div>
                {sidePop && (
                  <SidePop
                    setSidePopVal={setSidePopVal}
                    SidePopVal={SidePopVal}
                    setSidePop={setSidePop}
                    menu={menu}
                    isSave={isSave}
                    setIsSave={setIsSave}
                  />
                )}
              </div>
            </th>
          </tr>
        </thead>
        <tbody id="cardnew">
          {/* `${i === 0 && playing && 'blink'}` */}
          {ticketList &&
            ticketList.length > 0 &&
            ticketList.map((data, i) => {
              return (
                <tr
                  key={i}
                  className={
                    newTicket.find((newdata) => newdata.id === data.id) &&
                    playing &&
                    "blink"
                  }
                  style={{
                    backgroundColor:
                      checkIfTicketIsCreated30DayBefore(data.create_date_utc) &&
                      (data.ticket_status == "New" ||
                        data.ticket_status == "In Progress" ||
                        data.ticket_status == "Approved" ||
                        data.ticket_status == "Arrived (Shipping Area)" ||
                        data.ticket_status == "Pending Approval" ||
                        (data.ticket_status == "Completed" &&
                          data.ticket_statusid == 28) ||
                        data.ticket_status == "Pending Customer" ||
                        data.ticket_status == "Resolved" ||
                        data.ticket_status == "Free Temp Storage" ||
                        data.ticket_status == "Fix")
                        ? "#ffc0cb96"
                        : "",
                    borderBottom:
                      checkIfTicketIsCreated30DayBefore(data.create_date_utc) &&
                      (data.ticket_status == "New" ||
                        data.ticket_status == "In Progress" ||
                        data.ticket_status == "Approved" ||
                        data.ticket_status == "Arrived (Shipping Area)" ||
                        data.ticket_status == "Pending Approval" ||
                        (data.ticket_status == "Completed" &&
                          data.ticket_statusid == 28) ||
                        data.ticket_status == "Pending Customer" ||
                        data.ticket_status == "Resolved" ||
                        data.ticket_status == "Free Temp Storage" ||
                        data.ticket_status == "Fix")
                        ? "1px solid white"
                        : "",
                  }}
                >
                  {filteredSidePop.id && (
                    <td
                      className="pd-l bold-txt id-search"
                      onClick={() =>
                        handleModalPopupOnClick(
                          data?.operatingsite,
                          data?.id,
                          data?.tickettypeid,
                          data?.ticket_sub_type_name,
                          data?.tickettype
                        )
                      }
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      {data?.id || ""}
                    </td>
                  )}
                  {filteredSidePop.subject && (
                    <td
                      className="pd-l bold-txt sub-search"
                      onClick={() =>
                        handleModalPopupOnClick(
                          data?.operatingsite,
                          data?.id,
                          data?.tickettypeid,
                          data?.ticket_sub_type_name,
                          data?.tickettype
                        )
                      }
                      style={{
                        cursor: "pointer",
                        textOverflow: "ellipsis",
                        maxWidth: "300px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {data?.subject || ""}
                    </td>
                  )}

                  {filteredSidePop.designated_company && (
                    <td
                      className="pd-l bold-txt"
                      style={{
                        textOverflow: "ellipsis",
                        maxWidth: "200px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {data?.designated_company || ""}
                    </td>
                  )}
                  {filteredSidePop.tickettype && (
                    <td className="pd-l bold-txt">{data?.tickettype}</td>
                  )}
                  {filteredSidePop.operatingsite && (
                    <td className="pd-l bold-txt">{data?.operatingsite}</td>
                  )}
                  {filteredSidePop.priority && (
                    <td className="pd-l bold-txt">
                      <div className="d-flex justify-content-start align-item-center">
                        {" "}
                        <span style={{ width: "1.6rem" }}>
                          {data?.priority || ""}
                        </span>
                        <span
                          className="position-relative"
                          onClick={() => {
                            escalateTeams(data.id);
                          }}
                        >
                          {checkIfIncidentTicket(data) ? (
                            <img
                              src="\img\teams.png"
                              style={{
                                width: "1.125rem",
                                position: "absolute",
                                top: 0,
                              }}
                            ></img>
                          ) : (
                            ""
                          )}
                        </span>
                      </div>
                    </td>
                  )}
                  {filteredSidePop.create_date && (
                    <td className="pd-l bold-txt">
                      {data?.create_date ? data?.create_date : ""}
                    </td>
                  )}

                  {filteredSidePop.close_date && (
                    <td className="pd-l bold-txt">
                      {data?.close_date ? data?.close_date : ""}
                    </td>
                  )}
                  {filteredSidePop.visit_start_date && (
                    <td className="pd-l bold-txt">
                      {data?.visit_start_date ? data?.visit_start_date : ""}
                    </td>
                  )}

                  {filteredSidePop.visit_end_date && (
                    <td className="pd-l bold-txt">
                      {data?.visit_end_date ? data?.visit_end_date : ""}
                    </td>
                  )}
                  {filteredSidePop.ticket_status && (
                    <td>
                      <span
                        className="badge badge-success badge-lg light"
                        style={{
                          color: getStatus(data.ticket_status),
                          backgroundColor: getStatus(data.ticket_status) + "26",
                        }}
                      >
                        {data?.ticket_status || ""}
                      </span>
                    </td>
                  )}
                  {filteredSidePop.readiness &&
                    (data?.readiness_id !== null ? (
                      <td className="pd-l bold-txt">
                        <li className="nav-item dropdown header-profile">
                          <a
                            className="nav-link"
                            href="#"
                            role="button"
                            data-bs-toggle="dropdown"
                          >
                            {data?.readiness_id ? (
                              <div>
                                <i
                                  className="fa-solid fa-circle fa-2xl"
                                  style={{
                                    color:
                                      data?.readiness_id === "normal"
                                        ? data?.readiness_id === "done"
                                          ? "green"
                                          : "gray"
                                        : data?.readiness_id === "done"
                                        ? "green"
                                        : "red",
                                  }}
                                ></i>
                              </div>
                            ) : (
                              <div
                                style={{
                                  position: "relative",
                                  display: "inline-block",
                                }}
                              >
                                <i
                                  className="fa-solid fa-circle fa-2xl"
                                  style={{
                                    color: "orangered",
                                  }}
                                ></i>
                                <span
                                  style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    color: "white", // Ensure the text is visible on the icon
                                    fontWeight: "bold",
                                    padding: "0.9em 0.5em",
                                    fontSize: "9px",
                                  }}
                                >
                                  N/A
                                </span>
                              </div>
                            )}
                          </a>

                          <div className="dropdown-menu dropdown-menu-end ">
                            {/* <p style={{ fontSize: "14px" }}>
                              "N/A": There is an invalid value for the Readiness
                              from the BOSS.
                              <br></br>You can update the status by selecting
                              one of the options below:
                            </p> */}
                            {data?.readiness_id != "done" && (
                              <div
                                className="dropdown-item ai-icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  readinessChange("done", data.id);
                                }}
                              >
                                {" "}
                                <i
                                  className="fa-solid fa-circle fa-2xl"
                                  style={{
                                    color: "green",
                                    paddingRight: "1rem",
                                  }}
                                ></i>
                                {t(`ticket.ready`)}
                              </div>
                            )}
                            {data?.readiness_id != "normal" && (
                              <div
                                className="dropdown-item ai-icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  readinessChange("normal", data.id);
                                }}
                              >
                                {" "}
                                <i
                                  className="fa-solid fa-circle fa-2xl"
                                  style={{
                                    color: "gray",
                                    paddingRight: "1rem",
                                  }}
                                ></i>{" "}
                                {t(`ticket.in_progress`)}
                              </div>
                            )}
                            {data?.readiness_id != "blocked" && (
                              <div
                                className="dropdown-item ai-icon"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  readinessChange("blocked", data.id);
                                }}
                              >
                                {" "}
                                <i
                                  className="fa-solid fa-circle fa-2xl"
                                  style={{
                                    color: "red",
                                    paddingRight: "1rem",
                                  }}
                                ></i>{" "}
                                {t(`ticket.blocked`)}
                              </div>
                            )}
                          </div>
                        </li>
                      </td>
                    ) : (
                      <td></td>
                    ))}
                  {filteredSidePop.assignee && (
                    <td
                      className="pd-l bold-txt cus-search"
                      style={{
                        textOverflow: "ellipsis",
                        maxWidth: "200px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {data?.assignee
                        ? data.assignee === "OPS Portal Bot"
                          ? `Ops Portal Bot (${data?.activity_user})`
                          : data.assignee
                        : "N/A"}
                    </td>
                  )}
                  {filteredSidePop.company && (
                    <td
                      className="pd-l bold-txt cus-search"
                      style={{
                        textOverflow: "ellipsis",
                        maxWidth: "200px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {data?.company ? data.company : "N/A"}
                    </td>
                  )}
                  {filteredSidePop.visitors && (
                    <td
                      className="pd-l bold-txt cus-search"
                      style={{
                        textOverflow: "ellipsis",
                        maxWidth: "300px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {data?.visitors ? data.visitors : "N/A"}
                    </td>
                  )}
                  {filteredSidePop.description && (
                    <td
                      className="pd-l bold-txt cus-search"
                      style={{
                        textOverflow: "ellipsis",
                        maxWidth: "300px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {data?.description ? data.description : "N/A"}
                    </td>
                  )}
                  {filteredSidePop.spaces_display && (
                    <td
                      className="pd-l bold-txt cus-search"
                      style={{
                        textOverflow: "ellipsis",
                        maxWidth: "300px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {data?.spaces_display ? data.spaces_display : "N/A"}
                    </td>
                  )}
                  {filteredSidePop.visiting_rooms_display && (
                    <td
                      className="pd-l bold-txt cus-search"
                      style={{
                        textOverflow: "ellipsis",
                        maxWidth: "300px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {data?.visiting_rooms_display
                        ? data.visiting_rooms_display
                        : "N/A"}
                    </td>
                  )}
                  {filteredSidePop.portal_code && (
                    <td
                      className="pd-l bold-txt cus-search"
                      style={{
                        textOverflow: "ellipsis",
                        maxWidth: "300px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {data?.portal_code ? data.portal_code : "N/A"}
                    </td>
                  )}
                  <td>
                    <img
                      src="\images\eye.svg"
                      alt=""
                      onClick={() =>
                        handleModalPopupOnClick(
                          data?.operatingsite,
                          data?.id,
                          data?.tickettypeid,
                          data?.ticket_sub_type_name,
                          data?.tickettype
                        )
                      }
                      style={{
                        cursor: "pointer",
                        width: "20px",
                      }}
                    />
                  </td>
                  <td></td>
                </tr>
              );
            })}
        </tbody>
      </table>
      <div className="d-flex justify-content-end mt-3">
        {ticketList && ticketList.length > 0 && (
          <Pagination
            setItemsPerPage={setItemsPerPage}
            setPageNumber={setPageNumber}
            total={total}
            pageNumber={pageNumber}
          ></Pagination>
        )}
      </div>
    </React.Fragment>
  );
};
export default Table;
