import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js";
import { numberFormat, numberFormatToDecimalPlace } from "common/helpers";
import AuthContext from "context";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DoughnutChartCard } from "@digitaledgedc/deuikit";
import { Grid } from "@mui/material";

const DashboardCard = ({ cardData }) => {
  const contextStore = useContext(AuthContext);
  let floors = [];
  const [floorData, setFloorData] = useState(null);
  const [cabinetData, setCabinetData] = useState(null);
  const [cageData, setCageData] = useState(null);
  const [powerData, setPowerData] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    floors = contextStore.getDataCenterFloor;
    getFloorGraph();
    getCabinetGraph();
    getPowerGraph();
    getCagesGraph();
  }, [contextStore.getDataCenterFloor]);

  const getFloorGraph = () => {
    if (floors.length) {
      let floorData = cardData.filter((data) => data.title === "Floors");
      let inService = floors.filter((floor) => floor.status === 1);
      setFloorData({
        types: [
          {
            id: "1",
            title: "In Services",
            value: inService.length,
            color: "#FE8600",
          },
          {
            id: "2",
            title: "Unavailable",
            value: floors.length - inService.length,
            color: "#E0E2E5",
          },
        ],
        totalNumber: floors.length,
      });
    }
  };

  const getCabinetGraph = () => {
    let total = 0;
    let totalAvail = 0;
    let totalsold = 0;
    let totalReserved = 0;
    let totalRofr = 0;
    let totalBlocked = 0;
    let totalComplete = 0;

    if (floors.length) {
      let cabData = cardData.filter((data) => data.title === "Cabinets");
      floors.map((floor) => {
        total += floor.rooms.reduce(
          (previous, current) => (previous += Number(current.design_cabs)),
          0
        );

        totalReserved += floor.rooms.reduce(
          (previous, current) => (previous += Number(current.reserved_cabs)),
          0
        );

        totalRofr += floor.rooms.reduce(
          (previous, current) => (previous += Number(current.rofr_cabs)),
          0
        );

        totalBlocked += floor.rooms.reduce(
          (previous, current) => (previous += Number(current.blocked_cabs)),
          0
        );

        totalsold += floor.rooms.reduce(
          (previous, current) => (previous += Number(current.sold_cabs)),
          0
        );
        if (floor.rooms && floor.rooms.length > 0) {
          floor.rooms.forEach((data) => {
            if (data.cabinets.length) {
              data.cabinets.forEach((cabinet) => {
                if (cabinet.status === 2) {
                  totalComplete += 1;
                }
              });
            }
          });
        }
        // console.log(totalComplete)
      });

      totalAvail =
        total -
        (totalsold + totalReserved + totalRofr + totalBlocked + totalComplete);

      setCabinetData({
        types: [
          {
            id: "1",
            title: "In Services",
            value: totalsold,
            color: "#FE8600",
          },
          {
            id: "2",
            title: "Available",
            value: totalAvail,
            color: "#3FEB7B",
          },
          {
            id: "3",
            title: "Reserved",
            value: totalReserved,
            color: "#1b70c0",
          },
          {
            id: "4",
            title: "ROFR",
            value: totalRofr,
            color: "#595959",
          },
          {
            id: "5",
            title: "Blocked",
            value: totalBlocked,
            color: "#000000",
          },
          {
            id: "6",
            title: "Complete",
            value: totalComplete,
            color: "#c2adc2",
          },
        ],
        totalNumber: total,
      });
    }
  };

  const getPowerGraph = () => {
    let totalPower = 0;
    let totalAvail = 0;
    let totalSold = 0;
    let totalReserved = 0;
    let totalRofr = 0;
    let totalBlocked = 0;

    if (floors.length) {
      let powerData = cardData.filter((data) => data.title === "Power (kW)");

      floors.map((power) => {
        totalPower += power.rooms.reduce(
          (previous, current) => (previous += Number(current.design_power)),
          0
        );

        totalReserved += power.rooms.reduce(
          (previous, current) => (previous += Number(current.reserved_power)),
          0
        );

        totalRofr += power.rooms.reduce(
          (previous, current) => (previous += Number(current.rofr_power)),
          0
        );

        totalBlocked += power.rooms.reduce(
          (previous, current) => (previous += Number(current.blocked_power)),
          0
        );

        totalSold += power.rooms.reduce(
          (previous, current) => (previous += Number(current.sold_power)),
          0
        );
      });

      totalAvail =
        totalPower - (totalSold + totalReserved + totalRofr + totalBlocked);

      setPowerData({
        types: [
          {
            id: "1",
            title: "In Services",
            value: totalSold.toFixed(3),
            color: "#FE8600",
          },
          {
            id: "2",
            title: "Available",
            value: totalAvail.toFixed(3),
            color: "#3FEB7B",
          },
          {
            id: "3",
            title: "Reserved",
            value: totalReserved.toFixed(3),
            color: "#1b70c0",
          },
          {
            id: "4",
            title: "ROFR",
            value: totalRofr.toFixed(3),
            color: "#595959",
          },
          {
            id: "5",
            title: "Blocked",
            value: totalBlocked.toFixed(3),
            color: "#000000",
          },
        ],
        totalNumber: totalPower === 0 ? totalPower : totalPower.toFixed(3),
      });
    }
  };
  const getCagesGraph = () => {
    let totalCages = 0;
    let totalAvail = 0;
    let totalSold = 0;
    let totalReserved = 0;
    let totalRofr = 0;
    let totalBlocked = 0;

    if (floors.length) {
      let cagesData = cardData.filter((data) => data.title === "Cages");

      floors.map((cage) => {
        totalCages += cage.rooms.reduce(
          (previous, current) => (previous += Number(current.design_cages)),
          0
        );

        totalReserved += cage.rooms.reduce(
          (previous, current) => (previous += Number(current.reserved_cages)),
          0
        );

        totalRofr += cage.rooms.reduce(
          (previous, current) => (previous += Number(current.rofr_cages)),
          0
        );

        totalBlocked += cage.rooms.reduce(
          (previous, current) => (previous += Number(current.blocked_cages)),
          0
        );

        totalSold += cage.rooms.reduce(
          (previous, current) => (previous += Number(current.sold_cages)),
          0
        );
      });

      totalAvail =
        totalCages - (totalSold + totalReserved + totalRofr + totalBlocked);

      setCageData({
        types: [
          {
            id: "1",
            title: "In Services",
            value: totalSold,
            color: "#FE8600",
          },
          {
            id: "2",
            title: "Available",
            value: totalAvail,
            color: "#3FEB7B",
          },
          {
            id: "3",
            title: "Reserved",
            value: totalReserved,
            color: "#1b70c0",
          },
          {
            id: "4",
            title: "ROFR",
            value: totalRofr,
            color: "#595959",
          },
          {
            id: "5",
            title: "Blocked",
            value: totalBlocked,
            color: "#000000",
          },
        ],
        totalNumber: totalCages,
      });
    }
  };

  return (
    <Grid container spacing={1} justifyContent="center">
      {[
        { data: floorData, title: "Floors" },
        { data: cabinetData, title: "Cabinets" },
        { data: cageData, title: "Cages" },
        { data: powerData, title: "Power (kW)" },
      ].map((item, index) => (
        <Grid key={index} item xs={12} sm={6} md={3} lg={3} xl={3}>
          <DoughnutChartCard
            graphData={
              item.data != null
                ? item.data
                : {
                    totalNumber: 0,
                    types: [],
                  }
            }
            title={item.title}
            loading={false}
            size="sm"
            titleStyle={{
              fontSize: "0.813rem",
            }}
            legendTextStyle={{
              fontSize: "0.438rem",
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default DashboardCard;
