import { css } from "glamor";
import { t } from "i18next";
import { useRef, useState } from "react";
import { Row } from "react-bootstrap";
import Overlay from "react-bootstrap/Overlay";
import Popover from "react-bootstrap/Popover";
import { useTranslation } from "react-i18next";
import NewTicketIcon from "./NewTicketIcon";
import NewTicketRow from "./NewTicketRow";
import "./TicketPopup.scss";
import StorageContext from "context";
import React from "react";
const orangeColor = "rgb(254, 134, 0)";

const styles = {
  white: {
    background: "#fff",
  },
  orange: {
    background: orangeColor,
  },
  popoverHeader: {
    display: "flex",
    justifyContent: "center",
    fontWeight: 700,
    color: "#92979A",
    marginBottom: "20px",
  },
};

const TICKET_OPTIONS = [
  {
    id: "site-access",
    name: "create-ticket.site-access.title",
    active: true,
  },
  {
    id: "remote-hands",
    name: "create-ticket.remote-hands.title",
    formTitle: "create-ticket.remote-hands.form-title",
    active: true,
  },
  {
    id: "shipment",
    name: "create-ticket.shipment.title",
    active: true,
  },
  {
    id: "fault",
    name: "create-ticket.fault-ticket.title",
    formWidth: "500px",
    formTitle: "create-ticket.fault-ticket.form-title",
    active: true,
  },
  {
    id: "incident",
    name: "create-ticket.incident-ticket.title",
    formWidth: "500px",
    active: true,
  },
];

const getTicketTypeIdByName = (name) => {
  if (name == "Incidents") {
    return 1;
  }
  if (name === "Faults") {
    return 2;
  }
  if (name === "Shipment") {
    return 3;
  }
  if (name === "Site Visits") {
    return 4;
  }
  if (name === "Remote Hands") {
    return 5;
  }
};

const TicketPopup = ({ openCreateTicketModal }) => {
  const { t } = useTranslation();
  const contextData = React.useContext(StorageContext);
  const checkPermission = (data) => {
    let ticketType = data;
    if (data == "Site Access") {
      ticketType = "Site Visits";
    }
    if (data == "Incident Ticket") {
      ticketType = "Incidents";
    }
    if (data == "Fault Ticket") {
      ticketType = "Faults";
    }
    const ticketTypes = contextData.getAuth?.ticketPermission;
    if (
      ticketTypes.filter(
        (el) =>
          el?.ticket_type_id == getTicketTypeIdByName(ticketType) &&
          el?.is_allowed === 1 &&
          el?.roleTicketPermission[0]?.is_allowed === 1
      ).length > 0
    ) {
      return true;
    }
    return false;
  };
  let TICKET_OPTIONS_TRANSLATED = TICKET_OPTIONS.map((option) => {
    return { ...option, name: t(option.name) };
  });
  if (contextData?.getAuth?.role?.role_ticket) {
    if (contextData?.getAuth?.role?.role_ticket.length != 0) {
      let permittedTicketTypes = contextData?.getAuth?.role?.role_ticket;
      let x = [];
      TICKET_OPTIONS_TRANSLATED.forEach((data) => {
        if (checkPermission(data.name)) {
          x.push(data);
        }
      });
      TICKET_OPTIONS_TRANSLATED = x;
    }
  }
  const [active, setActive] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);

  const handleClick = (event) => {
    setActive(!active);
    setTarget(event.target);
  };

  return (
    <div
      ref={ref}
      className="dashboard-ticket-popup-icon-div"
      {...css(active ? styles.orange : styles.white)}
    >
      <div className="dashboard-ticket-popup" onClick={handleClick}>
        <NewTicketIcon active={active} />
      </div>

      <Overlay
        show={active}
        target={target}
        placement="bottom"
        container={ref}
        containerPadding={20}
        rootClose
        onHide={() => setActive(false)}
      >
        <Popover id="popover-contained">
          <Popover.Body>
            <Row {...css(styles.popoverHeader)}>
              {t("create-ticket.label").toUpperCase()}
            </Row>
            {TICKET_OPTIONS_TRANSLATED.map((ticketType) => (
              <NewTicketRow
                key={ticketType.id}
                props={ticketType}
                openCreateTicketModal={openCreateTicketModal}
                setActive={setActive}
              />
            ))}
          </Popover.Body>
        </Popover>
      </Overlay>
    </div>
  );
};

export default TicketPopup;
