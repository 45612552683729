import AuthContext from "context";
import { useContext, useEffect, useState } from "react";
import CreateTicketService from "services/createTicketService";
import _ from "lodash";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

const useGetOperatingSites = ({
  countryId,
  dataCenters,
  country,
  countries,
}) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const [operatingSites, setOperatingSites] = useState([]);
  const [isOperatingSitesLoading, setIsOperatingSitesLoading] = useState([]);

  const getPermittedSites = (permittedSites, dataCenter) => {
    const fristNames = permittedSites.map((item) => item.name);
    const filteredSecondArray = dataCenter.filter((item) =>
      fristNames.includes(item.name)
    );
    return filteredSecondArray;
  };

  const getOperatingSites = async () => {
    setOperatingSites([]);
    const code = countries.find((team) => team.country_code === country);
    country = code?.name === "South Korea" ? "Korea" : code?.name;
    const setValue = (list) => {
      setOperatingSites(
        _.orderBy(list, ["name"], ["asc"]).map((site) => {
          const { id, name } = site;
          return { ...site, label: name, value: id };
        })
      );
    };

    try {
      setIsOperatingSitesLoading(true);
      const data = await CreateTicketService.getOperatingSites(
        authContext.token(),
        country
      );
      const sites = data;
      if (sites && sites.length > 0) {
        setValue(getPermittedSites(authContext.getPermittedDataCenter, sites));
        setIsOperatingSitesLoading(false);
      } else if (sites.length === 0) {
        await Swal.fire("Error", "No Operating Sites for this Company.");
        setValue([]);
        setIsOperatingSitesLoading(false);
      }
    } catch (err) {
      console.log("errrrrr", err);
      await Swal.fire(t("error"), err.message);
      setIsOperatingSitesLoading(false);
    }
  };

  useEffect(() => {
    if (countryId != undefined) {
      getOperatingSites();
    }
  }, [countryId]);

  return {
    operatingSites,
    isOperatingSitesLoading,
  };
};

export default useGetOperatingSites;
