import { css } from 'glamor'
import moment from 'moment'
import { useEffect, useMemo } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { TICKET_MODAL_ORANGE_COLOR } from 'views/super-admin/Tickets/shared/constants'
import TicketDateTime from 'views/super-admin/Tickets/shared/TicketDateTime'
import * as utils from '../../../shared/utils'

const styles = {
  container: {
    padding: '20px 0 20px 0',
    backgroundColor: '#fff',
    // border: `2px solid ${TICKET_MODAL_ORANGE_COLOR}`,
    borderRadius: '5px',
    '& > *': {
      // padding: '0 20px 0 20px'
    }
  },
  title: {
    fontSize: '20px',
    marginBottom: '5px'
  },
  description: {
    fontSize: '14px'
  }
}

const VisitDetails = ({ startDateTime, endDateTime, active, setFieldValue }) => {
  // useEffect(() => {
  //   if (startDateTime === '') {
  //     setFieldValue(
  //       'startDateTime',
  //       moment(new Date())
  //         .add(1, 'day')
  //         .set({ hour: 8, minute: 0, second: 0 })
  //         // .utc()
  //     )
  //     setFieldValue(
  //       'endDateTime',
  //       moment(new Date())
  //         .add(1, 'day')
  //         .set({ hour: 9, minute: 0, second: 0 })
  //         // .utc()
  //     )
  //   }
  // }, [active])

  const { t } = useTranslation()
  return (
    <div {...css(styles.container)}>
      <Row style={{ marginBottom: '10px' }}>
        <Col>
          <TicketDateTime
            id='startDateTime'
            isTopPlacement
            value={startDateTime}
            setFieldValue={setFieldValue}
            active={active}
            placeholder={`${t('create-ticket.site-access.start-time').toUpperCase()} *`}
            // hoursConstraint={utils.getHoursConstraintStartDate()}
          />
        </Col>
        <Col>
          <TicketDateTime
            id='endDateTime'
            isTopPlacement
            value={endDateTime}
            setFieldValue={setFieldValue}
            // hoursConstraint={utils.getHoursConstraintEndDate(startDateTime, endDateTime)}
            active={active}
            placeholder={`${t('create-ticket.site-access.end-time').toUpperCase()} *`}
            compareDate={startDateTime}
          />
        </Col>
      </Row>
      {/* <Row>
        <Col {...css(styles.title)}>{t('create-ticket.site-access.visit-details-title')}</Col>
      </Row> */}
      <Row>
        <Col {...css(styles.description)}>
          {
            t('create-ticket.site-access.visit-details-text')
          }
        </Col>
      </Row>
    </div>
  )
}

const VisitDetailsMemo = ({ startDateTime, endDateTime, active, setFieldValue }) => {
  return useMemo(() => {
    return (
      <VisitDetails
        startDateTime={startDateTime}
        endDateTime={endDateTime}
        active={active}
        setFieldValue={setFieldValue}
      />
    )
  }, [startDateTime, endDateTime, active])
}

export default VisitDetailsMemo
