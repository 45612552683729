export const priority = [
  { label: "S-1", value: 3 },
  { label: "S-2", value: 2 },
  { label: "S-3", value: 1 },
  { label: "S-4", value: 0 },
];

export const NonEditable = [
  "Approved",
  "In Progress",
  "Declined",
  "Cancelled",
  "Completed",
];

export const category = [
  {
    label: "Infrastructure Incident",
    value: {
      category: "infrastructure",
      subCategory: [
        { value: "1", label: "Electrical", category: "infrastructure" },
        { value: "2", label: "Mechanical", category: "infrastructure" },
        { value: "3", label: "Fire Protection", category: "infrastructure" },
        { value: "4", label: "BMS", category: "infrastructure" },
        { value: "5", label: "Cross Connect", category: "infrastructure" },
        { value: "6", label: "Network", category: "infrastructure" },
      ],
      severity: [
        { value: "1", label: "P0", category: "infrastructure" },
        { value: "2", label: "P1", category: "infrastructure" },
        { value: "3", label: "P2", category: "infrastructure" },
        { value: "4", label: "P3", category: "infrastructure" },
      ],
    },
  },
  {
    label: "Security Incident",
    value: {
      category: "security",
      subCategory: [
        { value: "7", label: "CAS", category: "security" },
        { value: "8", label: "CCTV", category: "security" },
        { value: "9", label: "Security Breach", category: "security" },
        { value: "10", label: "Security Threat", category: "security" },
        { value: "11", label: "Data Loss", category: "security" },
        { value: "12", label: "Authorities Access", category: "security" },
      ],
      severity: [
        { value: "5", label: "I1", category: "security" },
        { value: "6", label: "I2", category: "security" },
        { value: "7", label: "I3", category: "security" },
      ],
    },
  },
  {
    label: "Environmental, Health & Safety (EHS)",
    value: {
      category: "ehs",
      subCategory: [
        { value: "13", label: "Environmental", category: "ehs" },
        { value: "14", label: "Health", category: "ehs" },
        { value: "15", label: "Safety", category: "ehs" },
      ],
      severity: [
        { value: "8", label: "E1", category: "ehs" },
        { value: "9", label: "E2", category: "ehs" },
        { value: "10", label: "E3", category: "ehs" },
      ],
    },
  },
];

export const defaultValue = {
  category: "infrastructure",
  subCategory: [
    { value: "1", label: "Electrical" },
    { value: "2", label: "Mechanical" },
    { value: "3", label: "Fire Protection" },
    { value: "4", label: "BMS" },
    { value: "5", label: "Cross Connect" },
    { value: "6", label: "Network" },
  ],
  severity: [
    { value: "1", label: "P0" },
    { value: "2", label: "P1" },
    { value: "3", label: "P2" },
    { value: "4", label: "P3" },
  ],
};

export const ticketType = [
  { value: 1, label: "Temperature Issue" },
  { value: 2, label: "Network Issue" },
  { value: 3, label: "Equipment Issue" },
  { value: 4, label: "Power Issue" },
];
