import React, { useContext, useEffect, useState, useRef } from 'react';
import moment from 'moment'; 
import Swal from 'sweetalert2';
import Loading from '../../pre-loader/loader';
import JoditEditor from 'jodit-react';
import '../../Ticket/faultpopup.css'; 
import { XError } from 'components/common';
import { validateEmail } from 'common/helpers';
import provisioningService from 'services/provisioningService';
import ErrorModel from 'views/super-admin/Ticket/components/errormodel';

const AddProvFollowers = (props) => { 
   
  const editor = useRef(null);  
  const [sendmail, setSendMail] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [errorpopup, setErrorPopup] = useState(false);
  const [errorMsg, setErrorMsg] = useState('Something went Wrong !');

  const [state,setState] = useState({
    emails:[],
    contact_ids:[],
    send_email:true,
    message:""
  })
  const [error, setError] = useState({
    emails:[],
    message:"" 
  });
  const handleInputChange = (event) => {
    const emailsArray = event.target.value.split(',').map(email => email.trim());
     // Remove the specified domain from each email
    // const emailsWithoutDomain = emailsArray.map(email => email.replace(/@digitaledgedc\.com$/, ''));
    setState(prevState => ({ ...prevState, emails: emailsArray }));
  }; 

  const inviteFollowers = async () => {  

      setIsLoading(true); 
      if (checkValidation()) {
        let currentState = { ...state }; // Create a copy of the state object

      // If send_email is false, remove the message property
      if (!currentState.send_email) {
        delete currentState.message;
      }

        await provisioningService
        .serviceInviteFollower(localStorage.getItem('token'), props.ticketId,currentState)
        .then((res) => {  
          if (res?.data?.data?.success === undefined) { 
            // console.log("error",res.data)
            setErrorMsg(res.data);
            setErrorPopup(true); 
          } else {
            closeModal();
            props.getFollowList();
          }
          
        })
        .finally(() => {
          setIsLoading(false);
        });
      }else{
        setIsLoading(false);
      }
      
  }; 

  const checkValidation = () => {
    
    let error = {
      emails: "",
      message:"" 
    };

    const {
      emails,
      message 
    } = state;
    
    let flag = true;
    
    if (emails.length === 0) { 
      error.emails = "The emails field is required.";
      flag = false;
    } 
    // else {
    //   for (let i = 0; i < emails.length; i++) {
    //     if (!validateEmail(emails[i])) {
    //       // If any email is empty, set the error and update the flag 
    //       error.emails = "The email must be a valid email address.";
    //       flag = false;
    //       break; // Exit the loop if any email is empty
    //     } 
    //   }  
     
    // }
    if(state.send_email){
      if (!message || message.trim() === '' || message.trim().split(/\s+/).length === 0) {
          error.message = "The message field is required.";
          flag = false;
      } 
    }
   
    setError({ ...error });
    return flag;
  };
    
  const closeModal = () => {
    props.setShow(false);
    if (props.handleClose) {
      props.handleClose();
    }
  };
  const handleExitbutton = async () => {
    await Swal.fire({
      title: 'Are you sure?',
      text: 'Any changes made will not be saved.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      customClass: {
        cancelButton: "cancelBtn",
        confirmButton: "confirmBtn",
      },
    }).then((res) => {
      if (res.isConfirmed) {
        closeModal();
      }
    });
  };
  return (
    <div
      class="modal show bd-example-modal-lg"
      id="main-model"
      style={{ display: 'block' }} /*onClick={()=> closeModal()}*/
      tabIndex="-1"
      role="dialog"
      aria-hidden="true"
    >
      {/* <Loading loading={isLoading} background="rgba(0, 0, 0, 0.4)" loaderColor="rgb(248, 153, 3)" /> */}
      <div class="modal-dialog modal-xl lay-2-3pop">
        <div class="modal-content fault-content" id="content-area">
          <div class="modal-header mt-59"> 
            <h1 class="display-title">
              Invite Follower 
            </h1> 
            {/* <div className="faultClose-outer">
                <div>
                  <img
                    className="faultClose"
                    width={27}
                    src="images/faultClose.png"
                    alt=""
                    onClick={() => handleExitbutton()}
                  />
                </div>
            </div> */}
          </div> 
          <div class="modal-body" style={{ paddingTop: '1rem' }}>
            <div class="" style={{ height: 'cal(100%-0%)',borderBottom:"1px solid black",borderTop:"1px solid black" }}>
              <div class="pt-5 pb-5">
                <div class="main_fault">
                  <div className="card-body" style={{ overflow: 'hidden' }}>
                    <div class="card-details">
                      <div class="row">
                        <div class="ticket-details" > 
                            <div class="mb-3 row">
                                <label for="inputPassword" class="col-sm-2">Recipients</label>
                                <div class="col-sm-10">
                                  <input type="text" class="form-control" id="inputPassword"  
                                  value={state.emails}
                                  onChange={handleInputChange}
                                  placeholder="Enter multiple emails separated by commas"
                                  />
                                  <XError message={error.emails} />
                                </div>
                                
                            </div> 
                            <div class="mb-3 row">
                                <label for="inputPassword" class="col-sm-2">Send Email</label>
                                <div class="col-sm-10">
                                <input class="form-check-input" type="checkbox" 
                                 id="flexCheckChecked" 
                                  checked={state.send_email} // Use state.send_email for the initial checked state
                                  onClick={() => setState(prevState => ({ ...prevState, send_email: !prevState.send_email }))}
                                 />
                                </div>
                            </div> 
                            {
                              state.send_email ?
                              <div className='mb-3 row'> 
                                <label className="col-sm-2">Message</label>
                                <div class="col-sm-10">
                                    <JoditEditor 
                                      ref={editor} 
                                      style={{ height: '400px !important' }}
                                      value={state.message} 
                                      onChange={(newContent) => { 
                                        if (newContent.trim() !== "") {
                                          setState({ ...state, message: newContent });
                                        }
                                      }}
                                    />  
                                     <XError message={error.message} />
                                </div>
                            </div>
                            :null
                            }
                            
                        </div> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='pt-4'>
          {isLoading ? (
            <button type="button" className="btn btnfollower">
                {" "}
                Loading ...{" "}
            </button>
            ) : (
            <button
            type="button"  
            className="btn btnfollower"
            onClick={inviteFollowers}
            >
            ADD FOLLOWERS
            </button>
            )}
          
            <button
            type="button" 
            style={{ }}
            className="btn cancelfollower"
            onClick={() => handleExitbutton()}
            >
            CANCEL
            </button>
          </div>
          {errorpopup && (
            <ErrorModel show={errorpopup} setShow={setErrorPopup} errorMsg={errorMsg} />
          )}
        </div>
      </div>
    </div>
    </div>
  );
};
export default AddProvFollowers;
