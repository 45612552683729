import { css } from "glamor";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import TicketInputField from "views/super-admin/Tickets/shared/TicketInputField";
import PlusIcon from "./PlusIcon";
import AuthContext from "context";
import { useState, useContext } from "react";
import { LANGUAGES } from "global-definitions";
import ConfirmVerifyModal from "../../../shared/VerificationModal";

const styles = {
  container: {
    padding: "0 10px 10px 10px !important",
    margin: "0 10px 0 10px !important",
    border: "1px solid #17a2b8",
    borderRadius: "5px",
  },
  row: {
    padding: "0 15px 0 15px",
  },
  col: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
};

const VisitorDetailsForm = ({
  visitor,
  active,
  setFieldValue,
  onAddVisitor,
}) => {
  const { getAuth } = useContext(AuthContext);
  const { t } = useTranslation();

  const [edit, setEdit] = useState(false);

  return (
    <div {...css(styles.container)}>
      <Row {...css(styles.row)}>
        <Col>
          <TicketInputField
            id="visitorName"
            label={t("create-ticket.site-access.visitor-name")}
            required
            value={visitor.visitorName}
            active={active}
            setFieldValue={setFieldValue}
          />
        </Col>
      </Row>
      <Row {...css(styles.row)}>
        <Col>
          <TicketInputField
            id="visitorEmail"
            label={t("create-ticket.site-access.visitor-email")}
            required
            value={visitor.visitorEmail}
            active={active}
            setFieldValue={setFieldValue}
          />
        </Col>
      </Row>
      <Row {...css(styles.row)}>
        <Col>
          <TicketInputField
            id="visitorCompanyName"
            label={t("create-ticket.site-access.visitor-company-name")}
            active={active}
            value={visitor.visitorCompanyName}
            setFieldValue={setFieldValue}
          />
        </Col>
        <Col style={{ position: "relative" }}>
          <TicketInputField
            id="visitorContactNumber"
            label={t("create-ticket.site-access.visitor-contact-number")}
            required={getAuth.language_id !== LANGUAGES.JP}
            active={active}
            value={visitor.visitorContactNumber}
            setFieldValue={setFieldValue}
          />
          <span
            data-toggle="tooltip"
            data-placement="bottom"
            title="Preferred format: +(country code)xxx... E.g. +6512345678"
            className="info_tooltip"
          >
            <i class="fa-solid fa-circle-info"></i>
          </span>
        </Col>
      </Row>
      <Row {...css(styles.row)}>
        <Col>
          <TicketInputField
            id="visitorIdNumber"
            label={t("create-ticket.site-access.visitor-id-number")}
            active={active}
            value={visitor.visitorIdNumber}
            setFieldValue={setFieldValue}
          />
        </Col>
        <Col {...css(styles.col)}>
          <div style={{ width: "85%" }}>
            <TicketInputField
              id="visitorVehicleInformation"
              label={t("create-ticket.site-access.vehicle-info")}
              active={active}
              value={visitor.visitorVehicleInformation}
              setFieldValue={setFieldValue}
            />
          </div>
          {active && <PlusIcon handleClick={onAddVisitor} />}
        </Col>
      </Row>
    </div>
  );
};

export default VisitorDetailsForm;
