import { axiosWithToken as axios } from 'utils/axios';
import { LogoutOnSessionExpire } from 'common/helpers';

class AccountService {
  /*
	|-------------------------------------------------------------------------------
	| 	Get all Account
	|-------------------------------------------------------------------------------
	*/
  async getAllAccount(token, q) {
    return await axios(token).get(`getAccount?` + q);
  }

  /*
	|-------------------------------------------------------------------------------
	| 	Get  Account Detail
	|-------------------------------------------------------------------------------
	*/
  async getAccountDetail(token, customer_code) {
    return await axios(token).get(`getAccountDetail?customer_code=` + customer_code);
  }

  /*
	|-------------------------------------------------------------------------------
	| 	Get all Account Type
	|-------------------------------------------------------------------------------
	*/
  async getAllAccountType(token) {
    return await axios(token).get(`getAccountType`);
  }
  /*
	|-------------------------------------------------------------------------------
	|  Account Create
	|-------------------------------------------------------------------------------
	*/
  async createAccount(token, data) {
    return await axios(token).post(`createAccount`, data);
  }
  /*
	|-------------------------------------------------------------------------------
	|  Account Update
	|-------------------------------------------------------------------------------
	*/
  async updateAccount(token, data) {
    return await axios(token).post(`updateAccount`, data);
  }
  /*
	|-------------------------------------------------------------------------------
	| Destroy Account
	|-------------------------------------------------------------------------------
	*/
  async destroy(token, data) {
    return await axios(token).post(`deleteAccount`, data);
  }

  /*
	|-------------------------------------------------------------------------------
	| getResellers
	|-------------------------------------------------------------------------------
	*/
  // async getResellers(token) {
  //   return await axios(token).get(`getResellers`);
  // }
}

export default new AccountService();
