import { groupBy, remove } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const GroupByBox = ({
  setIsGroupBy,
  setIsSelectedGroup,
  setGroupBy,
  groupCategory,
  setPageNumber
}) => {
  const { t } = useTranslation();
  const handleChange = (data) => {
    if (data.field !== groupBy.field) {
      setIsGroupBy(true);
      setPageNumber(1);
      setIsSelectedGroup('');
      setGroupBy({
        id: data.id,
        filterName: data.name,
        field: data.field,
        type: 'group'
      });
    }
  };

  return (
    <React.Fragment>
      <div className="tc_drop1-out d-flex align-items-center">
        <div style={{ width: '100%', cursor: 'pointer' }}>
          <strong>
            <span>
              <div
                className="btn-group"
                role="group"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span>
                  {' '}
                  <img alt="" src="\images\sliders.svg" style={{ marginRight: '0.5rem' }} />
                </span>{' '}
                <span>{t(`ticket.group_by`)}</span>
              </div>
              <div className="dropdown-menu">
                <div
                  style={{
                    overflow: 'auto'
                  }}
                >
                  {groupCategory &&
                    groupCategory.map((data, i) => {
                      return (
                        <div key={i}>
                          <div className="group_by_list" onClick={() => handleChange(data)}>
                            {data.name}
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </span>
          </strong>
        </div>
      </div>
    </React.Fragment>
  );
};

export default GroupByBox;
