// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accountDetail .company_type {
  font-size: 1.5rem;
  color: red;
  font-weight: 500;
  cursor: pointer;
}
.accountDetail .text_bold {
  font-size: 1rem;
  color: #171c3c;
  font-weight: 600;
}
.accountDetail .modal-body .row {
  margin-bottom: 1rem;
}
.accountDetail .company_name {
  color: #000;
  font-size: 2rem;
  font-weight: 900 !important;
}
.accountDetail .country_name {
  font-size: 2.5rem !important;
  padding-right: 2rem;
}
.accountDetail .exit_button {
  border-radius: 0.5rem;
}
.accountDetail .test .btnstyle a.btn.msg-button {
  color: #51b57c;
  font-weight: 600;
  background-color: white;
  border: 2px solid black;
}

.table-scroll-set3 {
  position: fixed;
  overflow: auto;
  bottom: 0;
  top: 86px;
  width: 90%;
  background: #ffffff;
}

@media (min-width: 1600px) and (max-width: 4800px) {
  .table-scroll-set3 {
    top: 95px;
  }
}`, "",{"version":3,"sources":["webpack://./src/views/super-admin/Accounts/style.scss"],"names":[],"mappings":"AACE;EACE,iBAAA;EACA,UAAA;EACA,gBAAA;EACA,eAAA;AAAJ;AAEE;EACE,eAAA;EACA,cAAA;EACA,gBAAA;AAAJ;AAEE;EACE,mBAAA;AAAJ;AAGE;EACE,WAAA;EACA,eAAA;EACA,2BAAA;AADJ;AAGE;EACE,4BAAA;EACA,mBAAA;AADJ;AAGE;EACE,qBAAA;AADJ;AAIE;EACE,cAAA;EACA,gBAAA;EACA,uBAAA;EACA,uBAAA;AAFJ;;AAMA;EACE,eAAA;EACA,cAAA;EACA,SAAA;EACA,SAAA;EACA,UAAA;EAEA,mBAAA;AAJF;;AAOA;EACE;IACE,SAAA;EAJF;AACF","sourcesContent":[".accountDetail {\n  .company_type {\n    font-size: 1.5rem;\n    color: red;\n    font-weight: 500;\n    cursor: pointer;\n  }\n  .text_bold {\n    font-size: 1rem;\n    color: #171c3c;\n    font-weight: 600;\n  }\n  .modal-body .row {\n    margin-bottom: 1rem;\n  }\n\n  .company_name {\n    color: #000;\n    font-size: 2rem;\n    font-weight: 900 !important;\n  }\n  .country_name {\n    font-size: 2.5rem !important;\n    padding-right: 2rem;\n  }\n  .exit_button {\n    border-radius: 0.5rem;\n  }\n\n  .test .btnstyle a.btn.msg-button {\n    color: #51b57c;\n    font-weight: 600;\n    background-color: white;\n    border: 2px solid black;\n  }\n}\n\n.table-scroll-set3 {\n  position: fixed;\n  overflow: auto;\n  bottom: 0;\n  top: 86px;\n  width: 90%;\n  // height: 623px;\n  background: #ffffff;\n}\n\n@media (min-width: 1600px) and (max-width: 4800px) {\n  .table-scroll-set3 {\n    top: 95px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
