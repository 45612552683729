const Checkbox = ({checked, setChecked, label}) => {
  return (
    <div>
      <div
        className="form-check d-flex align-items-center"
        style={{ marginTop: '10px' }}
      >
        <input
          className="form-check-input"
          type="checkbox"
          checked={checked}
          onClick={() => {
            setChecked(!checked);
          }}
          name="flexRadioDefault"
          id="flexRadioDefault1"
        />
        <label
          className="form-check-label"
          htmlFor="flexRadioDefault1"
          style={{ marginBottom: '0px' }}
        >
          {label}
        </label>
      </div>
    </div>
  );
};

export default Checkbox
