import React from 'react';
import { Dropdown } from '../Dropdown/Dropdown';
import "./DropdownOrButton.css"

export const DropdownOrButton = ({
  id,
  label,
  value,
  list,
  updateBreakers,
  slotValue,
  previousBreaker,
  addBreaker,
  breakers
}) => {
  const onChangeBreaker = (id, value) => {
    updateBreakers(id, value)
  }
  return (
    <div>
      {slotValue === 'dropdown' && (
        <Dropdown
          id={id}
          label={label}
          value={value}
          list={list}
          onChange={onChangeBreaker}
          breakers={breakers}  
        />
      )} 
      {slotValue === 'button' && (
        <button
          type="button"
          disabled={previousBreaker ? false : true}
          onClick={(e) => {
            addBreaker(id);
          }}
          style={{ marginRight: '1rem' }}
          className="btn btn-outline-primary settings-data-center-cabinets-dropdown-or-button"
        >
          +
        </button>
      )}
    </div>
  );
};
